import React from 'react';
import { connect } from "react-redux";
import { setPopup } from 'Actions/pageActions'

import Translations from 'Singleton/Translations'
import { Grid, Button } from '@material-ui/core';
import TextInput from '../../../Components/Commons/TextInput';
import { TextInputType } from 'Components/Commons/Const/InputTypes'
import styles from '../../../Components/style';


class container extends React.Component {
	constructor(props) {
		super(props);
		const filter = this.getFilter()
		this.state={
			value: filter ? filter.payload : ''
		}
		
	}

	onAccept = () => {
		this.props.onAcceptFunc(
			{
				from: parseInt(this.props.inputs['filtr-text-input-from']),
				to: parseInt(this.props.inputs['filtr-text-input-to']),
			}
		);
		this.props.setPopup(undefined);
	}

	getFilter = () => {
		const { filters, filtersKey } = this.props;
		let filter = undefined
		
		if (filtersKey) {
			filter = filters.find((item) => {
				if (!item) {
					return false
				}
				if (item.name.length !== filtersKey.length) {
					return false
				}
				for (let i = 0; i < item.name.length; i++) {
					if (item.name[i] !== filtersKey[i]) {
						return false
					}
				}
				return true
			})
		}
		return filter;
	}

	render() {
		return (
			<Grid
				container
				direction='column'
			>
				<Grid item>
					<TextInput
						id='filtr-text-input-from'
						type={TextInputType.NUMBER}
						label={Translations.get('date_from')}
						value={`${this.state.value.from ? this.state.value.from : '' }`}
					/>
				</Grid>
				<Grid item>
					<TextInput
					id='filtr-text-input-to'
						type={TextInputType.NUMBER}
						label={Translations.get('date_to')}
						value={`${this.state.value.to ? this.state.value.to : '' }`}
					/>
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Button
						variant='contained'
						color='primary'
						fullWidth
						onClick={this.onAccept}
					>
						{Translations.get('update_filter')}
					</Button>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		onAcceptFunc: state.repository.popupOnAccept,
		inputs: state.input.inputState,
		filters: state.repository.filters,
		filtersKey: state.repository.filtersKey
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (x) => {
			dispatch(setPopup(x));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(container);
