
export class GoodType {
	static get FOOD() {return 'food';}
	static get MILITARY() {return 'military';}
	static get WOOD() {return 'wood';}
	static get MUSIC() {return 'music';}
	static get SWIM() {return "swim";}
	static get MACHINERY() {return "machinery";}
	static get METALS() {return "metals";}
	static get ALKOHOL_FREE() {return "alkohol_free";}
	static get PAPER() {return "paper";}
	static get CAR() {return "car";}
	static get MINERAL() {return "mineral";}
	static get PRODUCTS_OF_PLANT() {return "products_of_plant";}
	static get PRODUCTS_OF_ANIMAL() {return "products_of_animal";}
	static get MEDIC() {return "medic";}
	static get ELECTRONIC() {return "electronic";}
	static get AIRCRAFT() {return "aircraft";}
	static get GLASS() {return "glass";}
	static get PLASTIC() {return "plastic";}
	static get FAT() {return "fat";}
	static get CERAMIC() {return "ceramic";}
	static get OTHER() {return "other";}
	static get STONE() {return "stone";}
	static get LEATHER() {return "leather";}
}

