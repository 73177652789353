import React from "react";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom'
import Translations from 'Singleton/Translations'


import "./Error.css";

export class Error extends React.Component {

	render(){
		return (
			<div className="main-body-error">
				<p>Trwają prace konserwacyjne</p>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
  	return {
  	};
};

const mapDispatchToProps = (dispatch) => {
  	return{
  	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Error));
