import React from 'react'
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import { toast } from 'react-toastify';
import RestRequest from 'libs/RestRequest'

import { setPopup, setPopupFunc } from 'Actions/pageActions.js'

import styles from 'Components/style';

import Translation from 'Singleton/Translations'
import { Paper, Typography, Button, Grid, IconButton } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh'
import OrderStatus from 'Components/Commons/Const/OrderStatus'

class container extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			supplierName: this.props.supplierName
		}
		this.onSelectedRadio = this.onSelectedRadio.bind(this);
		this.handleUpdateEvaluationResponse = this.handleUpdateEvaluationResponse.bind(this)
	}

	onSelectedRadio(key, value) {
		var body = {
			cost: value,
			supplier: key,
			number: this.props.number,
		};
		if (this.props.isAuto) {
			var gate = 'secured/updateEvaluationPricesAndSupplier';
		} else {
			gate = 'secured/updateEvaluationSupplier';
		}

		RestRequest(
			'post',
			gate,
			body,
			this.handleUpdateEvaluationResponse,
			this.props.token
		);


		this.setState({
			...this.state,
			supplierName: key,
		})
		this.props.setPopupFunc(undefined)
	}

	handleUpdateEvaluationResponse(res) {
		console.log("Evaluation", res);
		this.props.refreshDataFunc();
	}

	onClick() {
		this.props.setPopupFunc(this.onSelectedRadio)
		this.props.setPopup('select_supplier');
	}

	getStatusInfo = () => {
		const transportInfo = this.props.transportInfo;
		let { status, message } = transportInfo ? transportInfo : {status: '', message: ''};
		return (
			<Grid
				container
				direction='column'
			>
				<Grid item>
					<Typography variant='body1'>
						<b>{Translation.get('state')}{': '}</b>
						{status}
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant='body1' style={{
						maxWidth:322,
					}}>
						<b>{Translation.get('message')}{': '}</b>
						{message}
					</Typography>
				</Grid>
			</Grid>
		)
	}

	handleRefreshClick = () => {
		RestRequest(
			'post',
			'secured/order/transport/status',
			{number: this.props.number},
			this.handleRefreshResponse,
			this.props.token
		);
	}

	handleRefreshResponse = (res) => {
		console.log('refresh res:', res);
		if(!res.body.error){
			toast.success(Translation.get('request_to_update_status_uploaded'), {position: toast.POSITION.TOP_RIGHT} );
		}else{
			toast.error(Translation.get('something_went_wrong'), {position: toast.POSITION.TOP_RIGHT} );
		}
	}

	getStatusInfoRow = () => {
		if(this.props.type === 'evaluation'){
			return '';
		}
		return (
			<Grid
				container
			>
				<Grid item style={{
					flexGrow:1,
				}}>
					{this.getStatusInfo()}
				</Grid>
				<Grid item>
					<IconButton 
						onClick={this.handleRefreshClick}
						disabled={!this.isUpdateButton()}
					>
						<RefreshIcon />
					</IconButton>
				</Grid>
			</Grid>
		)
	}

	isUpdateButton = () => {
		console.log('X', this.props.status);
		
		if (
			this.props.status === OrderStatus.FORWARD ||
			this.props.status === OrderStatus.SENT_TO_SUPPLIER ||
			this.props.status === OrderStatus.TO_SUPPLY ||
			this.props.status === OrderStatus.IN_PROGRESS
			){
				return true;
			}
		return false;
	}

	render() {
		return (
			<Paper>
				<Grid
					container
					style={{
						...styles.infoBox,
					}}
					direction='column'
					alignItems='center'
					justify='space-between'
				>
					<Grid item>
						<Typography variant='body1'>
							{Translation.get(this.state.supplierName)}
						</Typography>
					</Grid>
					<Grid item
						style={ this.props.status === "new_eval" ? {} : {width:'100%'}}
					>
						{this.props.status === "new_eval" &&
							<Button
								variant='outlined'
								onClick={this.onClick.bind(this)}
							>
								{Translation.get('change_supplier')}
							</Button>
						}
						{
							this.getStatusInfoRow()
						}
					</Grid>
				</Grid>
			</Paper>
		);
	}
}

container.propTypes = {
	className: PropTypes.string,
	supplierName: PropTypes.string,
	status: PropTypes.string,
	transportInfo: PropTypes.shape({
		message: PropTypes.string,
		status: PropTypes.string,
	})
}

const mapStateToProps = (state) => {
	return {
		isAuto: state.input.inputState.selectSupplierIsAutomatic,
		token: state.user.token,
		number: state.page.eNumber,
		refreshDataFunc: state.repository.refreshFunc,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (x) => {
			dispatch(setPopup(x));
		},
		setPopupFunc: (x) => {
			dispatch(setPopupFunc(x));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(container);
