import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import styles from 'Components/style'
import MediaType from '../../libs/MediaType';
import ArrowIcon from '@material-ui/icons/ArrowDropDown'
import Dropdown from 'Components/Commons/Dropdown'

const classes = theme => ({
	button: {
		backgroundColor: theme.palette.common.white
	}
})

export class component extends Component {

	handleClick = () => {
		this.props.button.callback();
	}

	getButton() {
		if (!this.props.button) {
			return '';
		}
		if (MediaType.isMobile) {
			return this.getMobileButton();
		}
		return (
			<Button 
				variant='outlined' 
				onClick={this.handleClick}
				classes={{
					root: this.props.classes.button
				}}
			>
				{this.props.button.text}
			</Button>
		);
	}

	getMobileButton() {
		return (

			<Dropdown
				label={<ArrowIcon />}
				rows={[{
					label: this.props.button.text,
					onClick: this.handleClick,
				}]}
			>

			</Dropdown>
		)
	}

	getColumnStyle = () => {
		switch (MediaType.type) {
			case MediaType.TABLET: return {
				...styles.columnTablet,
				...this.props.style
			}
			case MediaType.MOBILE: return {
				...styles.columnMobile,
				...this.props.style
			}
			default: return {
				...styles.column,
				...this.props.style
			}

		}
	}

	render() {
		return (
			<Grid
				container
				direction='column'
				style={this.getColumnStyle()}
			>
				{MediaType.isMobile &&
					<Grid item style={styles.space30Vertical}>
					</Grid>
				}
				<Grid item>
					<Grid
						container
						justify='space-between'
						alignItems='center'
					>
						<Grid item>
							<Typography variant='headline'>
								{this.props.title}
							</Typography>
						</Grid>
						<Grid item>
							{this.getButton()}
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					{this.props.children}
				</Grid>
			</Grid>
		)
	}
}

component.propTypes = {
	style: PropTypes.object,
	title: PropTypes.string,
	button: PropTypes.shape({
		text: PropTypes.string.isRequired,
		callback: PropTypes.func.isRequired
	})
}

component.defaultProps = {
	style: {},
}

export default withStyles(classes)(component)
