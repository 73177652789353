import React from "react";
import { connect } from "react-redux";
import styles from 'Components/style'
import { Grid, Typography, withStyles } from "@material-ui/core";
import MediaType from "../../libs/MediaType";

import Translations from 'Singleton/Translations'

import { MetaData, handleMetaData } from '../../Components/Commons/Const/MetaData'

// const getElements = (props) => {
// 	const collection = Object.keys(props.translations.translations)
// 		.filter(item => item.startsWith('privacy_policy_rule_'));
// 	return (
// 		<Grid
// 			container
// 			justify='space-between'
// 		>
// 			{
// 				collection.map((item, i) => {
// 					return (
// 						<Grid item key={i} style={
// 							MediaType.isMobile ?
// 								{ marginTop: styles.space16.width } :
// 								{ marginTop: styles.space30.width }
// 						}>
// 							<Typography variant='body1'>
// 								{Translations.get(item)}
// 							</Typography>
// 						</Grid>
// 					);
// 				})
// 			}
// 		</Grid>
// 	);
// }

const style = theme => ({
    container: {
        margin: `30px 0`,
        '&>*': {
            fontFamily: "\"Open Sans\", \"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
        }
    },
    list: {
        marginLeft: 30,
        counterReset: "list",
        "&>li": {
            listStyle: "none",
        },
        "&>li:before": {
            content: `counter(list) ".)"`,
            counterIncrement: "list",
            marginRight: 10,
        }
    },
    list2: {
        marginLeft: 60,
        counterReset: "list",
        "&>li": {
            listStyle: "none",
            display: "block",
            counterIncrement: "item",
        },
        "&>li:before": {
            content: `counter(item,lower-alpha) ".)"`,
            // counterIncrement: "section",
            marginRight: 10,
        }
    },
    list3: {
        marginLeft: 30,
        counterReset: "list 5",
        "&>li": {
            listStyle: "none",
        },
        "&>li:before": {
            content: `counter(list) ".)"`,
            counterIncrement: "list",
            marginRight: 10,
        }
    }
})
const container = (props) => {
    document.title = MetaData.mainPage.title;
    handleMetaData();

    return (
        <Grid
            container
            direction='column'
            style={
                MediaType.isMobile ?
                    styles.mobileContainer :
                    styles.container
            }
        >
            {!MediaType.isMobile &&
                <Grid item style={styles.space30Vertical}>
                </Grid>
            }
            <Grid item>
                <Typography variant='display1'>
                    {/* {Translations.get('privacy_policy_header')} */}
                    Polityka ochrony danych osobowych
                </Typography>
            </Grid>
            {/* {getElements(props)} */}
            <Grid item className={props.classes.container}>
                {/* <p align="center"><strong>Polityka ochrony danych osobowych</strong></p> */}
                <p align="center" style={{ marginBottom: 0 }}><strong>§1</strong></p>
                <p align="center"><strong>[postanowienia ogólne]</strong></p>
                <ol>
                    <li>
                        <p align="justify">
                            Niniejszy dokument (dalej: Polityka) jest polityką ochrony danych
                            osobowych w rozumieniu RODO – rozporządzenia Parlamentu
                            Europejskiego i Rady (UE) 2016/679 z 27 kwietnia 2016 r. w sprawie
                            ochrony osób fizycznych w związku z przetwarzaniem danych osobowych
                            i w sprawie swobodnego przepływu takich danych oraz uchylenia
                            dyrektywy 95/46/WE, tj. ogólnego rozporządzenia o ochronie danych.
                    </p>
                    </li>
                </ol>
                <ol start="2">
                    <li>
                        <p align="justify">
                            Polityka zawiera:
                    </p>
                    </li>
                </ol>
                <ol className={props.classes.list}>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            opis zasad ochrony danych obowiązujących w TREO Spółka
                            z ograniczoną odpowiedzialnością z siedzibą w Bielinach,
                            ul. Kielecka 143, 26 – 004 Bieliny wpisaną do Rejestru
                            Przedsiębiorców KRS prowadzonego przez Sąd Rejonowy
                            w Kielcach, X Wydział Gospodarczy Krajowego Rejestru Sądowego pod
                            nr KRS: 0000645046, o kapitale zakładowym
                            w wysokości 5.000,00 zł (dalej: Spółka),
                    </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            odwołania do załączników uszczegóławiających,
                            a w szczególności procedur lub instrukcji dotyczących
                            poszczególnych obszarów z zakresu ochrony danych osobowych.
                    </span>
                    </li>
                </ol>
                <ol start="3">
                    <li>
                        <p align="justify">
                            Odpowiedzialny za wdrożenie i utrzymanie niniejszej Polityki jest
                            Pan Michał Borowiecki – Prezes Zarządu Spółki.
                    </p>
                    </li>
                </ol>
                <ol start="4">
                    <li>
                        <p align="justify">
                            Spółka zapewnia zgodność postępowania kontrahentów Spółki
                        <br />
                            z niniejszą Polityką w odpowiednim zakresie, gdy dochodzi do
                            przekazania im danych osobowych przez Spółkę.
                    </p>
                    </li>
                </ol>
                <p align="center" style={{ marginBottom: 0 }}><strong>§2</strong></p>
                <p align="center"> <strong>[skróty i definicje]</strong></p>
                <ol>
                    <li>
                        <p align="justify">
                            Polityka oznacza niniejszą Politykę ochrony danych osobowych, o ile
                            co innego nie wynika wyraźnie z kontekstu.
                    </p>
                    </li>
                </ol>
                <ol start="2">
                    <li>
                        <p align="justify">
                            RODO oznacza rozporządzenie Parlamentu Europejskiego i Rady (UE)
                            2016/679 z 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych
                        <br />
                            w związku z przetwarzaniem danych osobowych i w sprawie swobodnego
                            przepływu takich danych oraz uchylenia dyrektywy 95/46/WE, tj.
                            ogólnego rozporządzenia o ochronie danych.
                    </p>
                    </li>
                </ol>
                <ol start="3">
                    <li>
                        <p align="justify">
                            Dane oznaczają dane osobowe, o ile co innego nie wynika wyraźnie
                        <br />
                            z kontekstu.
                    </p>
                    </li>
                </ol>
                <ol start="4">
                    <li>
                        <p align="justify">
                            Dane szczególnych kategorii oznaczają dane wymienione w art. 9 ust.
                            1 RODO, tj. dane osobowe ujawniające pochodzenie rasowe lub
                            etniczne, poglądy polityczne, przekonania religijne lub
                            światopoglądowe, przynależność do związków zawodowych, dane
                            genetyczne, dane biometryczne w celu jednoznacznego
                            zidentyfikowania osoby fizycznej lub dane dotyczące zdrowia,
                            seksualności lub orientacji seksualnej.
                    </p>
                    </li>
                </ol>
                <ol start="5">
                    <li>
                        <p align="justify">
                            Dane karne oznaczają dane wymienione w art. 10 RODO, tj. dane
                            dotyczące wyroków skazujących i naruszeń prawa.
                    </p>
                    </li>
                </ol>
                <ol start="6">
                    <li>
                        <p align="justify">
                            Dane dzieci oznaczają dane osób poniżej 16 roku życia.
                    </p>
                    </li>
                </ol>
                <ol start="7">
                    <li>
                        <p align="justify">
                            Osoba oznacza osobę, której dane dotyczą, o ile co innego nie
                            wynika wyraźnie z kontekstu.
                    </p>
                    </li>
                </ol>
                <ol start="8">
                    <li>
                        <p align="justify">
                            Pomiot przetwarzający oznacza organizację lub osobę, której Spółka
                            powierzyła przetwarzanie danych osobowych.
                    </p>
                    </li>
                </ol>
                <ol start="9">
                    <li>
                        <p align="justify">
                            Profilowanie oznacza dowolną formę zautomatyzowanego przetwarzania
                            danych osobowych, które polega na wykorzystaniu danych osobowych do
                            oceny niektórych czynników osobowych osoby fizycznej, w
                            szczególności do analizy lub prognozy aspektów dotyczących efektów
                            pracy tej osoby fizycznej, jej sytuacji ekonomicznej, zdrowia,
                            osobistych preferencji, zainteresowań, wiarygodności, zachowania,
                            lokalizacji lub przemieszczania się.
                    </p>
                    </li>
                </ol>
                <ol start="10">
                    <li>
                        <p align="justify">
                            Eksport danych oznacza przekazanie danych do państwa trzeciego lub
                            organizacji międzynarodowej.
                    </p>
                    </li>
                </ol>
                <ol start="11">
                    <li>
                        <p align="justify">
                            RCPD lub Rejestr oznacza Rejestr Czynności Przetwarzania Danych
                            Osobowych.
                    </p>
                    </li>
                </ol>
                <ol start="12">
                    <li>
                        <p align="justify">
                            Spółka oznacza TREO Spółka z ograniczoną odpowiedzialnością
                        <br />
                            z siedzibą w Bielinach, ul. Kielecka 143, 26 – 004 Bieliny wpisaną
                            do Rejestru Przedsiębiorców KRS prowadzonego przez Sąd Rejonowy
                        <br />
                            w Kielcach, X Wydział Gospodarczy Krajowego Rejestru Sądowego pod
                            nr KRS: 0000645046, o kapitale zakładowym w wysokości 5.000,00 zł.
                    </p>
                    </li>
                </ol>
                <p align="center" style={{ marginBottom: 0 }}><strong>§3</strong></p>
                <p align="center"><strong>[zasady ochrony danych osobowych w Spółce]</strong></p>
                <ol>
                    <li>
                        <p align="justify">
                            Ochrona danych osobowych w Spółce opiera się o następujących
                            filarach:
                    </p>
                    </li>
                </ol>
                <ol className={props.classes.list}>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            Legalizm – Spółka dba o ochronę prywatności i przetwarza dane
                            zgodnie z prawem,
                    </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            Bezpieczeństwo – Spółka zapewnia odpowiedni poziom bezpieczeństwa
                            danych, podejmując stale działania w tym zakresie,
                    </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            Prawa Jednostki – Spółka umożliwia osobom, których dane przetwarza,
                            wykonywanie swoich praw i prawa te realizuje,
                    </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            Rozliczalność – Spółka dokumentuje to, w jaki sposób przetwarza
                            dane osobowe, aby w każdej chwili móc wykazać przed organami
                            kontroli zgodność procedur z RODO.
                    </span>
                    </li>
                </ol>
                <ol start="2">
                    <li>
                        <p align="justify">
                            Spółka przetwarza dane osobowe z poszanowaniem następujących zasad:
                    </p>
                    </li>
                </ol>
                <ol className={props.classes.list}>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            w oparciu o podstawę prawną i zgodnie z prawem (legalizm),
                    </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            rzetelnie i uczciwie (rzetelność),
                            </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            w sposób przejrzysty dla osoby, której dane dotyczą
                            (transparentność),
                            </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            w konkretnych celach i nie „na zapas” (minimalizacja),
                            </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            nie więcej niż potrzeba (adekwatność),
                            </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            z dbałością o prawidłowość danych (prawidłowość),
                            </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            nie dłużej niż potrzeba (czasowość),
                            </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            zapewniając odpowiednie bezpieczeństwo danych (bezpieczeństwo).
                    </span>
                    </li>
                </ol>
                <p align="center" style={{ marginBottom: 0 }}><strong>§4</strong></p>
                <p align="center"><strong>[system ochrony danych osobowych]</strong></p>
                <ol>
                    <li>
                        <p align="justify">
                            Spółka dokonuje identyfikacji zasobów danych osobowych, klas
                            danych, zależności między zasobami danych, identyfikacji sposobów
                            wykorzystania danych, w tym:
                    </p>
                    </li>
                </ol>
                <ol className={props.classes.list}>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            przypadków przetwarzania danych szczególnych kategorii i danych
                            karnych,
                    </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            przypadków przetwarzania danych osób, których spółka nie
                            identyfikuje,
                    </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            przypadków przetwarzania danych dzieci,
                    </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            profilowania,
                    </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            współadministrowania danymi.
                    </span>
                    </li>
                </ol>
                <ol start="2">
                    <li>
                        <p align="justify">
                            Spółka opracowuje, prowadzi i utrzymuje Rejestr Czynności
                            Przetwarzania Danych Osobowych. Rejestr jest narzędziem rozliczania
                            zgodności z ochroną danych w Spółce.
                    </p>
                    </li>
                </ol>
                <ol start="3">
                    <li>
                        <p align="justify">
                            Spółka zapewnia, identyfikuje, weryfikuje podstawy prawne
                            przetwarzania danych i rejestruje je w Rejestrze, w tym:
                    </p>
                    </li>
                    <ol className={props.classes.list}>
                        <li>
                            <span style={{ textAlign: "justify" }}>
                                utrzymuje system zarządzania zgodami na przetwarzanie danych
                        <br />
                                i komunikację na odległość,
                    </span>
                        </li>
                        <li>
                            <span style={{ textAlign: "justify" }}>
                                inwentaryzuje i uszczegóławia przypadki, gdy Spółka przetwarza dane
                                na podstawie prawnie uzasadnionego interesu Spółki.
                    </span>
                        </li>
                    </ol>
                </ol>
                <ol start="4">
                    <li>
                        <p align="justify">
                            Spółka spełnia obowiązki informacyjne względem osób, których dane
                            przetwarza, oraz zapewnia obsługę ich praw, realizując otrzymane
                        <br />
                            w tym zakresie żądania, a w szczególności:
                    </p>
                    </li>
                </ol>
                <ol className={props.classes.list}>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            przekazuje osobom prawem wymagane informacje przy zbieraniu danych
                            i w innych sytuacjach oraz organizuje i zapewnia udokumentowanie
                            realizacji tych obowiązków,
                   </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            weryfikuje i zapewnia możliwość efektywnego wykonania każdego typu
                            żądania przez siebie i swoich przetwarzających,
                   </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            zapewnia odpowiednie nakłady i procedury, aby żądania osób były
                            realizowane w terminach i w sposób wymagany przez RODO
                        <br />
                            i odpowiednio dokumentowane,
                   </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            stosuje procedury pozwalające na ustalenie konieczności
                            zawiadomienia osób dotkniętych zidentyfikowanym naruszeniem ochrony
                            danych.
                   </span>
                    </li>
                </ol>
                <ol start="5">
                    <li>
                        <p align="justify">
                            Spółka stosuje zasady i metody zarządzania minimalizacją (privacy
                            by deflaut), a w tym:
                    </p>
                    </li>
                </ol>
                <ol className={props.classes.list}>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            zasady zarządzania adekwatnością danych,
                    </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            zasady reglamentacji i zarządzania dostępem do danych,
                    </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            zasady zarządzania okresem przechowywania danych i weryfikacji
                            dalszej przydatności.
                    </span>
                    </li>
                </ol>
                <ol start="6">
                    <li>
                        <p align="justify">
                            Spółka zapewnia odpowiedni poziom bezpieczeństwa danych, w tym:
                    </p>
                    </li>
                </ol>
                <ol className={props.classes.list}>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            przeprowadza analizy ryzyka dla czynności przetwarzania danych lub
                            ich kategorii,
                   </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            przeprowadza oceny skutków dla ochrony danych tam, gdzie ryzyko
                            naruszenia praw i wolności osób jest wysokie,
                   </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            dostosowuje środki ochrony danych do ustalonego ryzyka,
                   </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            stosuje procedury pozwalające na identyfikację, ocenę i zgłoszenie
                            zidentyfikowanego naruszenia ochrony danych Urzędowi Ochrony Danych
                            – zarządza incydentami.
                   </span>
                    </li>
                </ol>
                <ol start="7">
                    <li>
                        <p align="justify">
                            Spółka posiada zasady doboru przetwarzających dane na rzecz Spółki,
                            wymogów co do warunków przetwarzania (umowa powierzenia), zasad
                            weryfikacji wykonania umów powierzenia.
                    </p>
                    </li>
                </ol>
                <ol start="8">
                    <li>
                        <p align="justify">
                            Spółka stosuje zasady weryfikacji, czy nie przekazuje danych do
                            państw trzecich (poza UE, Norwegię, Lichtenstein, Islandię) lub do
                            organizacji międzynarodowych oraz zapewnia zgodne z prawem warunki
                            przekazania, jeśli ma ono miejsce.
                    </p>
                    </li>
                </ol>
                <ol start="9">
                    <li>
                        <p align="justify">
                            Spółka zarządza zmianami wpływającymi na prywatność. W tym celu
                            procedury uruchamiania nowych projektów i inwestycji w Spółce
                            uwzględniają konieczność oceny wpływu zmiany na ochronę danych,
                            analizę ryzyka, zapewnienie prywatności (a w tym celów
                            przetwarzania, bezpieczeństwa danych i minimalizacji) już w fazie
                            projektowania zmiany, inwestycji czy na początku nowego projektu.
                    </p>
                    </li>
                </ol>
                <ol start="10">
                    <li>
                        <p align="justify">
                            Spółka stosuje zasady weryfikacji, kiedy zachodzą przypadki
                            przetwarzania transgranicznego oraz zasady ustalania wiodącego
                            organu nadzorczego i głównej jednostki organizacyjnej w rozumieniu
                            RODO.
                    </p>
                    </li>
                </ol>
                <p align="center" style={{ marginBottom: 0 }}><strong>§5</strong></p>
                <p align="center"><strong>[inwentaryzacja]</strong></p>
                <ol>
                    <li>
                        <p align="justify">
                            Spółka identyfikuje przypadki, w których:
                    </p>
                    </li>
                </ol>
                <ol className={props.classes.list}>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            przetwarza lub może przetwarzać dane szczególnych kategorii lub
                            dane karne, oraz utrzymuje dedykowane mechanizmy zapewnienia
                            zgodności z prawem przetwarzania takich danych. W razie
                            zidentyfikowania przypadków przetwarzania danych szczególnych
                            kategorii lub danych karnych Spółka postępuje zgodnie z przyjętymi
                            zasadami w tym zakresie,
                    </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            przetwarza lub może przetwarzać dane niezidentyfikowane
                        <br />
                            i utrzymuje mechanizmy ułatwiające realizację praw osób, których
                            dotyczą dane niezidentyfikowane,
                    </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            dokonuje profilowania przetwarzanych danych i utrzymuje mechanizmy
                            zapewniające zgodność tego procesu z prawem.
                        <br />
                            W razie zidentyfikowania przypadków profilowania
                        <br />
                            i zautomatyzowanego podejmowania decyzji Spółka postępuje zgodnie z
                            przyjętymi zasadami w tym zakresie,
                    </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            współadministruje danymi i postępuje w tym zakresie zgodnie
                        <br />
                            z przyjętymi zasadami.
                    </span>
                    </li>
                </ol>
                <p align="center" style={{ marginBottom: 0 }}><strong>§6</strong></p>
                <p align="center"><strong>[Rejestr Czynności Przetwarzania Danych]</strong></p>
                <ol>
                    <li>
                        <p align="justify">
                            Rejestr Czynności Przetwarzania Danych stanowi formę dokumentowania
                            czynności przetwarzania danych, pełni rolę mapy przetwarzania
                            danych i jest jednym z kluczowych elementów umożliwiających
                            realizację fundamentalnej zasady, na której opiera się cały system
                            ochrony danych osobowych, czyli zasady rozliczalności.
                    </p>
                    </li>
                </ol>
                <ol start="2">
                    <li>
                        <p align="justify">
                            Spółka prowadzi Rejestr, w którym inwentaryzuje i monitoruje
                            sposób,
                        <br />
                            w jaki wykorzystuje dane osobowe.
                    </p>
                    </li>
                </ol>
                <ol start="3">
                    <li>
                        <p align="justify">
                            Rejestr jest jednym z podstawowych narzędzi umożliwiających Spółce
                            rozliczanie większości obowiązków ochrony danych.
                    </p>
                    </li>
                </ol>
                <ol start="4">
                    <li>
                        <p align="justify">
                            Spółka dokumentuje w Rejestrze podstawy prawne przetwarzania danych
                            dla poszczególnych czynności przetwarzania.
                    </p>
                    </li>
                </ol>
                <ol start="5">
                    <li>
                        <p align="justify">
                            Wskazując w dokumentach ogólną podstawę prawną (zgoda, umowa,
                            obowiązek prawny, żywotne interesy, zadanie publiczne, uzasadniony
                            cel spółki), Spółka dookreśla podstawę w precyzyjny i czytelny
                            sposób.
                    </p>
                    </li>
                </ol>
                <ol start="6">
                    <li>
                        <p align="justify">
                            Spółka wdraża metody zarządzania zgodami umożliwiające rejestrację
                            i weryfikację posiadania zgody osoby na przetwarzanie jej
                            konkretnych danych w konkretnym celu, zgody na komunikację na
                            odległość oraz rejestrację odmowy zgody, cofnięcia zgody i
                            podobnych czynności.
                    </p>
                    </li>
                </ol>
                <p align="center" style={{ marginBottom: 0 }}><strong>§7</strong>
                </p>
                <p align="center">
                    <strong>
                        [sposób obsługi praw jednostki i obowiązków informacyjnych]
                </strong>
                </p>
                <ol>
                    <li>
                        <p align="justify">
                            Spółka dba o czytelność i styl przekazywanych informacji i
                            komunikacji
                        <br />
                            z osobami, których dane przetwarza.
                    </p>
                    </li>
                </ol>
                <ol start="2">
                    <li>
                        <p align="justify">
                            Spółka ułatwia osobom korzystanie z ich praw poprzez różne
                            działania, tj.: zamieszczanie na stronie internetowej Spółki
                            informacji lub linków do informacji o prawach osób, sposobie
                            skorzystania z nich w Spółce,
                        <br />
                            w tym wymaganiach dotyczących identyfikacji, metodach kontaktu ze
                            Spółką w tym celu, ewentualnym cenniku żądań „dodatkowych” itp.
                    </p>
                    </li>
                </ol>
                <ol start="3">
                    <li>
                        <p align="justify">
                            Spółka dba o dotrzymywanie prawnych terminów realizacji obowiązków
                            względem osób.
                    </p>
                    </li>
                </ol>
                <ol start="4">
                    <li>
                        <p align="justify">
                            Spółka wprowadza adekwatne metody identyfikacji i uwierzytelniania
                            osób dla potrzeb realizacji praw jednostki i obowiązków
                            informacyjnych.
                    </p>
                    </li>
                </ol>
                <ol start="5">
                    <li>
                        <p align="justify">
                            W celu realizacji praw jednostki Spółka zapewnia procedury
                        <br />
                            i mechanizmy pozwalające zidentyfikować dane konkretnych osób
                            przetwarzane przez Spółkę, zintegrować te dane, wprowadzać do nich
                            zmiany i usuwać je w sposób zintegrowany.
                    </p>
                    </li>
                </ol>
                <ol start="6">
                    <li>
                        <p align="justify">
                            Spółka dokumentuje obsługę obowiązków informacyjnych, zawiadomień i
                            żądań osób.
                    </p>
                    </li>
                </ol>
                <p align="center" style={{ marginBottom: 0 }}><strong>§8</strong></p>
                <p align="center"><strong>[obowiązki informacyjne]</strong></p>
                <ol>
                    <li>
                        <p align="justify">
                            Spółka określa zgodne z prawem i efektywne sposoby wykonywania
                            obowiązków informacyjnych.
                    </p>
                    </li>
                </ol>
                <ol start="2">
                    <li>
                        <p align="justify">
                            Spółka informuje osobę o przedłużeniu ponad jeden miesiąc terminu
                            na rozpatrzenie żądania tej osoby.
                    </p>
                    </li>
                </ol>
                <ol start="3">
                    <li>
                        <p align="justify">
                            Spółka informuje osobę o przetwarzaniu jej danych, przy
                            pozyskiwaniu danych tej osoby.
                    </p>
                    </li>
                </ol>
                <ol start="4">
                    <li>
                        <p align="justify">
                            Spółka informuje osobę o przetwarzaniu jej danych, przy
                            pozyskiwaniu danych o tej osobie niebezpośrednio od niej.
                    </p>
                    </li>
                    <li>
                        <p align="justify">
                            Spółka określa sposób informowania osób o przetwarzaniu danych
                            niezidentyfikowanych, tam, gdzie to jest możliwe.
                    </p>
                    </li>
                </ol>
                <ol start="6">
                    <li>
                        <p align="justify">
                            Spółka informuje osobę o planowanej zmianie celu przetwarzania
                            danych.
                    </p>
                    </li>
                </ol>
                <ol start="7">
                    <li>
                        <p align="justify">
                            Spółka informuje osobę przed uchyleniem ograniczenia przetwarzania.
                    </p>
                    </li>
                </ol>
                <ol start="8">
                    <li>
                        <p align="justify">
                            Spółka informuje odbiorców danych o sprostowaniu, usunięciu lub
                            ograniczeniu przetwarzania danych (chyba że będzie to wymagało
                            niewspółmiernie dużego wysiłku lub będzie niemożliwe).
                    </p>
                    </li>
                </ol>
                <ol start="9">
                    <li>
                        <p align="justify">
                            Spółka informuje osobę o prawie sprzeciwu względem przetwarzania
                            danych przy pierwszym kontakcie z tą osobą.
                    </p>
                    </li>
                </ol>
                <ol start="10">
                    <li>
                        <p align="justify">
                            Spółka bez zbędnej zwłoki zawiadamia osobę o naruszeniu ochrony
                            danych osobowych, jeżeli może ono powodować wysokie ryzyko
                            naruszenia praw lub wolności tej osoby.
                    </p>
                    </li>
                </ol>
                <p align="center" style={{ marginBottom: 0 }}><strong>§9</strong></p>
                <p align="center"><strong>[żądania osób]</strong></p>
                <ol>
                    <li>
                        <p align="justify">
                            Realizując prawa osób, których dane dotyczą, Spółka wprowadza
                            proceduralne gwarancje ochrony praw i wolności osób trzecich.
                        <br />
                            W szczególności w przypadku powzięcia wiarygodnej wiadomości
                        <br />
                            o tym, że wykonanie żądania osoby o wydanie kopii danych lub prawa
                            do przeniesienia danych może niekorzystnie wpłynąć na prawa
                        <br />
                            i wolności innych osób, Spółka może się zwrócić do osoby w celu
                            wyjaśnienia wątpliwości lub podjąć inne prawem dozwolone kroki,
                            łącznie z odmową zadośćuczynienia żądaniu.
                    </p>
                    </li>
                </ol>
                <ol start="2">
                    <li>
                        <p align="justify">
                            Spółka informuje osobę o tym, że nie przetwarza danych jej
                            dotyczących, jeśli taka osoba zgłosiła żądanie dotyczące jej praw.
                    </p>
                    </li>
                </ol>
                <ol start="3">
                    <li>
                        <p align="justify">
                            Spółka informuje osobę, w ciągu miesiąca od otrzymania żądania,
                        <br />
                            o odmowie rozpatrzenia żądania i o prawach osoby z tym związanych.
                    </p>
                    </li>
                </ol>
                <ol start="4">
                    <li>
                        <p align="justify">
                            Na żądanie osoby dotyczące dostępu do jej danych Spółka informuje
                            osobę, czy przetwarza jej dane, oraz informuje osobę o szczegółach
                            przetwarzania, zgodnie z art. 15 RODO, a także udziela osobie
                            dostępu do danych jej dotyczących. Dostęp do danych może być
                            zrealizowany przez wydanie kopii danych, z zastrzeżeniem, że kopii
                            danych wydanej w wykonaniu prawa dostępu do danych Spółka nie uzna
                            za pierwszą nieodpłatną kopię danych dla potrzeb opłat za kopie
                            danych.
                    </p>
                    </li>
                </ol>
                <ol start="5">
                    <li>
                        <p align="justify">
                            Na żądanie Spółka wydaje osobie kopię danych jej dotyczących
                        <br />
                            i odnotowuje fakt wydania pierwszej kopii danych. Spółka wprowadza
                        <br />
                            i utrzymuje cennik kopii danych, zgodnie z którym pobiera opłaty za
                            kolejne kopie danych. Cena kopii danych skalkulowana jest na
                            podstawie oszacowanego jednostkowego kosztu obsługi żądania wydania
                            kopii danych.
                    </p>
                    </li>
                </ol>
                <ol start="6">
                    <li>
                        <p align="justify">
                            Spółka dokonuje sprostowania nieprawidłowych danych na żądanie
                            osoby. Spółka ma prawo odmówić sprostowania danych, chyba że osoba
                            w rozsądny sposób wykaże nieprawidłowości danych, których
                            sprostowania się domaga. W przypadku sprostowania danych Spółka
                            informuje osobę o odbiorcach danych, na żądanie tej osoby.
                    </p>
                    </li>
                </ol>
                <ol start="7">
                    <li>
                        <p align="justify">
                            Spółka uzupełnia i aktualizuje dane na żądanie osoby. Spółka ma
                            prawo odmówić uzupełnienia danych, jeżeli uzupełnienie byłoby
                            niezgodne z celami przetwarzania danych. Spółka może polegać na
                            oświadczeniu osoby co do uzupełnianych danych, chyba że będzie to
                            niewystarczające w świetle przyjętych przez Spółkę procedur, prawa
                            lub zaistnieją podstawy, aby uznać oświadczenie za niewiarygodne.
                    </p>
                    </li>
                </ol>
                <ol start="8">
                    <li>
                        <p align="justify">
                            Na żądanie osoby Spółka usuwa dane, gdy:
                    </p>
                    </li>
                </ol>
                <ol className={props.classes.list}>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            dane nie są niezbędne do celów, dla których zostały zebrane, ani
                            przetwarzane w innych zgodnych z prawem celach,
                    </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            zgoda na ich przetwarzanie została cofnięta, a nie ma innej
                            podstawy prawnej przetwarzania,
                    </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            osoba wniosła skuteczny sprzeciw względem przetwarzania tych
                            danych,
                    </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            dane były przetwarzane niezgodnie z prawem,
                    </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            konieczność usunięcia wynika z obowiązku prawnego,
                    </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            żądanie dotyczy danych dziecka zebranych na podstawie zgody
                        <br />
                            w celu świadczenia usług społeczeństwa informacyjnego oferowanych
                            bezpośrednio dziecku.
                    </span>
                    </li>
                </ol>
                <ol start="9">
                    <li>
                        <p align="justify">
                            Spółka określa sposób obsługi prawa do usunięcia danych w taki
                            sposób, aby zapewnić efektywną realizację tego prawa przy
                            poszanowaniu wszystkich zasad ochrony danych, w tym bezpieczeństwa,
                            a także weryfikację, czy nie zachodzą wyjątki,
                        <br />
                            o których mowa w art. 17 ust. 3 RODO.
                    </p>
                    </li>
                </ol>
                <ol start="10">
                    <li>
                        <p align="justify">
                            Jeżeli dane podlegające usunięciu zostały upublicznione przez
                            Spółkę, Spółka podejmuje rozsądne działania, w tym środki
                            techniczne, by poinformować innych administratorów przetwarzających
                            te dane osobowe o potrzebie usunięcia danych i dostępu do nich.
                    </p>
                    </li>
                </ol>
                <ol start="11">
                    <li>
                        <p align="justify">
                            W przypadku usunięcia danych Spółka informuje osobę o odbiorcach
                            danych, na żądanie tej osoby.
                    </p>
                    </li>
                </ol>
                <ol start="12">
                    <li>
                        <p align="justify">
                            Spółka dokonuje ograniczenia przetwarzania danych na żądanie osoby,
                            gdy:
                    </p>
                    </li>
                </ol>
                <ol className={props.classes.list}>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            osoba kwestionuje prawidłowość danych – na okres pozwalający
                            sprawdzić ich prawidłowość,
                    </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            przetwarzanie jest niezgodne z prawem, a osoba, której dane
                            dotyczą, sprzeciwia się usunięciu danych osobowych, żądając
                        <br />
                            w zamian ograniczenia ich wykorzystania,
                    </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            Spółka nie potrzebuje już danych osobowych, ale są one potrzebne
                            osobie, której dane dotyczą, do ustalenia, dochodzenia lub obrony
                            roszczeń,
                    </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            osoba wniosła sprzeciw względem przetwarzania z przyczyn związanych
                            z jej szczególną sytuacją – do czasu stwierdzenia, czy po stronie
                            Spółki zachodzą prawnie uzasadnione podstawy nadrzędne wobec
                            podstaw sprzeciwu.
                    </span>
                    </li>
                </ol>
                <ol start="13">
                    <li>
                        <p align="justify">
                            W trakcie ograniczenia przetwarzania Spółka przechowuje dane,
                            natomiast nie przetwarza ich (nie wykorzystuje, nie przekazuje),
                            bez zgody osoby, której dane dotyczą, chyba że w celu ustalenia,
                            dochodzenia lub obrony roszczeń, lub w celu ochrony praw innej
                            osoby fizycznej lub prawnej, lub z uwagi na ważne względy interesu
                            publicznego.
                    </p>
                    </li>
                </ol>
                <ol start="14">
                    <li>
                        <p align="justify">
                            Spółka informuje osobę przed uchyleniem ograniczenia przetwarzania.
                    </p>
                    </li>
                </ol>
                <ol start="15">
                    <li>
                        <p align="justify">
                            W przypadku ograniczenia przetwarzania danych Spółka informuje
                            osobę o odbiorcach danych, na żądanie tej osoby.
                    </p>
                    </li>
                </ol>
                <ol start="16">
                    <li>
                        <p align="justify">
                            Na żądanie osoby Spółka wydaje w ustrukturyzowanym, powszechnie
                            używanym formacie nadającym się do odczytu maszynowego lub
                            przekazuje innemu podmiotowi, jeśli jest to możliwe, dane dotyczące
                            tej osoby, które dostarczyła ona Spółce, przetwarzane na podstawie
                            zgody tej osoby lub w celu zawarcia lub wykonania umowy z nią
                            zawartej w systemach informatycznych Spółki.
                    </p>
                    </li>
                </ol>
                <ol start="17">
                    <li>
                        <p align="justify">
                            Jeżeli osoba zgłosi umotywowany jej szczególną sytuacją sprzeciw
                            względem przetwarzania jej danych, a dane przetwarzane są przez
                            Spółkę w oparciu o uzasadniony interes Spółki lub o powierzone
                            Spółce zadanie w interesie publicznym, Spółka uwzględni sprzeciw, o
                            ile nie zachodzą po stronie Spółki ważne prawnie uzasadnione
                            podstawy do przetwarzania, nadrzędne wobec interesów, praw i
                            wolności osoby zgłaszającej sprzeciw, lub podstawy do ustalenia,
                            dochodzenia lub obrony roszczeń.
                    </p>
                    </li>
                </ol>
                <ol start="18">
                    <li>
                        <p align="justify">
                            Jeżeli osoba zgłosi sprzeciw względem przetwarzania jej danych
                            przez Spółkę na potrzeby marketingu bezpośredniego (w tym celu
                            ewentualnie profilowania), Spółka uwzględni sprzeciw i zaprzestanie
                            takiego przetwarzania.
                    </p>
                    </li>
                </ol>
                <ol start="19">
                    <li>
                        <p align="justify">
                            Jeżeli Spółka przetwarza dane w sposób automatyczny, w tym
                        <br />
                            w szczególności profiluje osoby i w konsekwencji podejmuje względem
                            osoby decyzje wywołujące skutki prawne lub inaczej istotnie
                            wpływające na osobę, Spółka zapewnia możliwość odwołania się do
                            interwencji i decyzji człowieka po stronie Spółki, chyba że taka
                            automatyczna decyzja:
                    </p>
                    </li>
                </ol>
                <ol>
                    <li>
                        <p align="justify">
                            jest niezbędna do zawarcia lub wykonania umowy między odwołującą
                            się osobą a Spółką, lub
                    </p>
                    </li>
                    <li>
                        <p align="justify">
                            jest wprost dozwolona przepisami prawa, lub
                    </p>
                    </li>
                    <li>
                        <p align="justify">
                            opiera się na wyraźnej zgodzie odwołującej się osoby.
                    </p>
                    </li>
                </ol>
                <p align="center" style={{ marginBottom: 0 }}><strong>§10</strong></p>
                <p align="center"> <strong>[minimalizacja]</strong></p>
                <ol>
                    <li>
                        <p align="justify">
                            Spółka dba o minimalizację przetwarzania danych pod kątem:
                    </p>
                    </li>
                </ol>
                <ol className={props.classes.list}>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            adekwatności danych do celów,
                    </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            dostępu do danych,
                    </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            czasu przechowywania danych.
                    </span>
                    </li>
                </ol>
                <ol start="2">
                    <li>
                        <p align="justify">
                            Spółka zweryfikowała zakres pozyskiwanych danych, zakres ich
                            przetwarzania i ilość przetwarzanych danych pod kątem adekwatności
                            do celów przetwarzania w ramach wdrożenia RODO.
                    </p>
                    </li>
                    <li>
                        <p align="justify">
                            Spółka dokonuje okresowego przeglądu ilości przetwarzanych danych
                        <br />
                            i zakresu ich przetwarzania nie rzadziej niż raz na rok.
                    </p>
                    </li>
                </ol>
                <ol start="4">
                    <li>
                        <p align="justify">
                            Spółka przeprowadza weryfikację zmian co do ilości i zakresu
                            przetwarzania danych w ramach procedur zarządzania zmianą (privacy
                            by design).
                    </p>
                    </li>
                </ol>
                <ol start="5">
                    <li>
                        <p align="justify">
                            Spółka stosuje ograniczenia dostępu do danych osobowych:
                    </p>
                    </li>
                </ol>
                <ol className={props.classes.list}>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            prawne ( zobowiązania do poufności, zakresy upoważnień),
                    </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            fizyczne (strefy dostępu, zamykanie pomieszczeń),
                    </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            logiczne (ograniczenia uprawnień do systemów przetwarzających dane
                            osobowe i zasobów sieciowych w których rezydują dane osobowe).
                    </span>
                    </li>
                </ol>
                <ol start="6">
                    <li>
                        <p align="justify">
                            Spółka stosuje kontrolę dostępu fizycznego.
                    </p>
                    </li>
                </ol>
                <ol start="7">
                    <li>
                        <p align="justify">
                            Spółka dokonuje aktualizacji uprawnień dostępowych przy zmianach
                        <br />
                            w składzie personelu i zmianach ról osób oraz zmianach podmiotów
                            przetwarzających.
                    </p>
                    </li>
                </ol>
                <ol start="8">
                    <li>
                        <p align="justify">
                            Spółka dokonuje okresowego przeglądu ustanowionych użytkowników
                            systemów i aktualizuje ich nie rzadziej niż raz na rok.
                    </p>
                    </li>
                </ol>
                <ol start="9">
                    <li>
                        <p align="justify">
                            Spółka wdraża mechanizmy kontroli cyklu życia danych osobowych
                        <br />
                            w Spółce, w tym weryfikacji dalszej przydatności danych względem
                            terminów i punktów kontrolnych wskazanych w Rejestrze.
                    </p>
                    </li>
                </ol>
                <ol start="10">
                    <li>
                        <p align="justify">
                            Dane, których zakres przydatności ulega ograniczeniu wraz z upływem
                            czasu, są usuwane z systemów produkcyjnych Spółki, jak też z akt
                            podręcznych i głównych. Dane takie mogą być archiwizowane oraz
                            znajdować się na kopiach zapasowych systemów i informacji
                            przetwarzanych przez Spółkę. Zasady archiwizacji i korzystania
                        <br />
                            z archiwów, tworzenia i wykorzystania kopii zapasowych uwzględniają
                            wymagania kontroli nad cyklem życia danych, a w tym wymogi usuwania
                            danych.
                    </p>
                    </li>
                </ol>
                <p align="center" style={{ marginBottom: 0 }}><strong>§11</strong></p>
                <p align="center"><strong>[bezpieczeństwo]</strong></p>
                <ol>
                    <li>
                        <p align="justify">
                            Spółka zapewnia stopień bezpieczeństwa odpowiadający ryzyku
                            naruszenia praw i wolności osób fizycznych wskutek przetwarzania
                            danych osobowych przez Spółkę.
                    </p>
                    </li>
                    <li>
                        <p align="justify">
                            Spółka przeprowadza i dokumentuje analizy adekwatności środków
                            bezpieczeństwa danych osobowych. W tym celu:
                    </p>
                    </li>
                </ol>
                <ol className={props.classes.list}>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            Spółka zapewnia odpowiedni stan wiedzy o bezpieczeństwie
                            informacji, cyberbezpieczeństwie i ciągłości działania –
                            wewnętrznie lub ze wsparciem podmiotów wyspecjalizowanych,
                    </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            Spółka kategoryzuje dane oraz czynności przetwarzania pod katem
                            ryzyka, które przedstawiają.
                    </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            Spółka przeprowadza analizy ryzyka naruszenia praw lub wolności
                            osób fizycznych dla czynności przetwarzania danych lub ich
                            kategorii. Spółka analizuje możliwe sytuacje i scenariusze
                            naruszenia ochrony danych osobowych, uwzględniając charakter,
                            zakres kontekst i cele przetwarzania, ryzyko naruszenia praw lub
                            wolności osób fizycznych o różnym prawdopodobieństwie wystąpienia
                        <br />
                            i wadze zagrożenia,
                    </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            Spółka ustala możliwe do zastosowania organizacyjne i techniczne
                            środki bezpieczeństwa i ocenia koszt ich wdrażania. W tym spółka
                            ustala przydatność i stosuje środki i podejście, jak:
                    </span>
                    </li>
                </ol>
                <ol className={props.classes.list2}>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            pseudonimizacja,
                    </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            szyfrowanie danych,
                    </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            inne środki cyberbezpieczeństwa składające się na zdolność do
                            ciągłego zapewnienia poufności, integralności, dostępności
                        <br />
                            i odporności systemów i usług przetwarzania,
                    </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            środki zapewnienia ciągłości działania i zapobiegania skutkom
                            katastrof, czyli zdolności do szybkiego przywrócenia dostępności
                            danych osobowych i dostępu do nich w razie incydentu fizycznego lub
                            technicznego.
                    </span>
                    </li>
                </ol>
                <ol start="3">
                    <li>
                        <p align="justify">
                            Spółka dokonuje oceny skutków planowanych operacji przetwarzania
                            dla ochrony danych osobowych tam, gdzie zgodnie z analizą ryzyka
                            ryzyko naruszenia praw i wolności osób jest wysokie.
                    </p>
                    </li>
                </ol>
                <ol start="4">
                    <li>
                        <p align="justify">
                            Spółka stosuje metodykę oceny skutków przyjętą w Spółce.
                    </p>
                    </li>
                </ol>
                <ol start="5">
                    <li>
                        <p align="justify">
                            Spółka stosuje środki bezpieczeństwa ustalone w ramach analizy
                            ryzyka i adekwatności środków bezpieczeństwa oraz ocen skutków dla
                            ochrony danych.
                    </p>
                    </li>
                </ol>
                <ol start="6">
                    <li>
                        <p align="justify">
                            Spółka stosuje procedury pozwalające na identyfikację, ocenę
                        <br />
                            i zgłoszenie zidentyfikowanego naruszenia ochrony danych Urzędowi
                            Ochrony Danych w terminie 72 godzin.
                    </p>
                    </li>
                </ol>
                <p align="center" style={{ marginBottom: 0 }}><strong>§12</strong></p>
                <p align="center"><strong>[eksport danych]</strong></p>
                <ol>
                    <li>
                        <p align="justify">
                            Spółka stosuje zasady doboru i weryfikacji przetwarzających dane na
                            rzecz Spółki opracowane w celu zapewnienia, aby przetwarzający
                            dawali wystarczające gwarancje wdrożenia odpowiednich środków
                            organizacyjnych i technicznych dla zapewnienia bezpieczeństwa,
                            realizacji praw jednostki i innych obowiązków ochrony danych
                            spoczywających na Spółce.
                    </p>
                    </li>
                </ol>
                <ol start="2">
                    <li>
                        <p align="justify">
                            Spółka rozlicza przetwarzających z wykorzystania
                            podprzetwarzających, jak też z innych wymagań wynikających z zasad
                            powierzenia danych osobowych.
                    </p>
                    </li>
                </ol>
                <ol start="3">
                    <li>
                        <p align="justify">
                            Spółka rejestruje w Rejestrze przypadki eksportu danych, czyli
                            przekazywania danych poza Europejski Obszar Gospodarczy.
                    </p>
                    </li>
                </ol>
                <ol start="4">
                    <li>
                        <p align="justify">
                            Spółka zarządza zmianą mającą wpływ na prywatność w taki sposób,
                            aby umożliwić zapewnienie odpowiedniego bezpieczeństwa danych
                            osobowych oraz minimalizacji ich przetwarzania. W tym celu zasady
                            prowadzenia projektów i inwestycji przez Spółkę odwołują się do
                            zasad bezpieczeństwa danych osobowych i minimalizacji, wymagając
                            oceny wpływu na prywatność i ochronę danych, uwzględnienia
                        <br />
                            i zaprojektowania bezpieczeństwa i minimalizacji przetwarzania
                            danych od początku projektu lub inwestycji.
                    </p>
                    </li>
                </ol>
                <p align="center">
                    <a name="_GoBack"></a>
                    <strong>Klauzula informacyjna o przetwarzaniu danych</strong>
                </p>
                <p>
                    Niniejszym informujemy Cię, że przetwarzamy Twoje dane osobowe. Szczegóły
                    tego dotyczące znajdziesz poniżej.
            </p>
                <ol type="I">
                    <li>
                        <p>
                            <strong>Administrator danych osobowych</strong>
                        </p>
                    </li>
                </ol>
                <p align="justify">
                    TREO Spółka z ograniczoną odpowiedzialnością z siedzibą w Bielinach,
                <br />
                    ul. Kielecka 143, 26 – 004 Bieliny wpisana do Rejestru Przedsiębiorców KRS
                    prowadzonego przez Sąd Rejonowy w Kielcach, X Wydział Gospodarczy Krajowego
                    Rejestru Sądowego pod nr KRS: 0000645046, o kapitale zakładowym w wysokości
                    5.000,00 zł, jest Administratorem Twoich danych osobowych.
            </p>
                <ol type="I" start="2">
                    <li>
                        <p>
                            <strong>Cele i podstawy przetwarzania</strong>
                        </p>
                    </li>
                </ol>
                <p align="justify">
                    W celu zawarcia umowy dostawy towaru na paletach na podstawie Twojego
                    zainteresowania naszą ofertą, (podstawa art. 6 ust. 1 lit. b RODO).
            </p>
                <p align="justify">
                    W celu wykonania i na podstawie zawartej przez Ciebie z nami umowy dostawy
                    towaru na paletach (podstawa art. 6 ust. 1 lit. b RODO).
            </p>
                <ol type="I" start="3">
                    <li>
                        <p>
                            <strong>Kategorie Twoich danych, które przetwarzamy</strong>
                        </p>
                    </li>
                </ol>
                <p>
                    Będziemy przetwarzać następujące kategorii Twoich danych:
            </p>
                <ol className={props.classes.list}>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            Podstawowe dane identyfikacyjne
                   </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            Elektroniczne dane identyfikacyjne
                   </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            Finansowe dane identyfikujące
                   </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            Dane dotyczące zamieszkania
                   </span>
                    </li>
                </ol>
                <ol type="I" start="4">
                    <li>
                        <p>
                            <strong>Odbiorcy danych</strong>
                        </p>
                    </li>
                </ol>
                <p align="justify">
                    Twoje dane osobowe możemy udostępniać następującym kategoriom podmiotów:
            </p>
                <ol className={props.classes.list}>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            Podwykonawcom, czyli podmiotom, z których korzystamy przy ich
                            przetwarzaniu, tj. firmom spedycyjnym, księgowym, prawniczym
                    </span>
                    </li>
                </ol>
                <ol type="I" start="5">
                    <li>
                        <p>
                            <strong>
                                Przekazywanie danych do państw trzecich lub organizacji
                                międzynarodowych.
                        </strong>
                        </p>
                    </li>
                </ol>
                <p>
                    Nie przekazujemy Twoich danych poza teren UE.
            </p>
                <ol type="I" start="6">
                    <li>
                        <p>
                            <strong>Okres przechowywania danych</strong>
                        </p>
                    </li>
                </ol>
                <p align="justify">
                    Twoje dane pozyskane w celu zawarcia umowy dostawy towaru na paletach
                    przechowujemy przez okres negocjowania umowy oraz do końca okresu
                    przedawnienia potencjalnych roszczeń z umowy.
            </p>
                <p align="justify">
                    Twoje podstawowe dane kontaktowe przechowujemy dla potrzeb marketingu
                    bezpośredniego naszych produktów i usług do czasu, aż zgłosisz sprzeciw
                    względem ich przetwarzania w tym celu, cofniesz zgodę, jeśli
                    przetwarzaliśmy je na podstawie tzw. zgody marketingowej, lub sami
                    ustalimy, że się zdezaktualizowały.
            </p>
                <ol type="I" start="7">
                    <li>
                        <p>
                            <strong>Twoje prawa</strong>
                        </p>
                    </li>
                </ol>
                <p>
                    Przysługuje Ci:
            </p>
                <ol className={props.classes.list}>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            prawo dostępu do swoich danych oraz otrzymania ich kopii,
                    </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            prawo do sprostowania (poprawiania) swoich danych,
                    </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            prawo do usunięcia danych – jeżeli Twoim zdaniem nie ma podstaw do
                            tego, abyśmy przetwarzali Twoje dane, możesz żądać, abyśmy je
                            usunęli,
                    </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            ograniczenia przetwarzania danych – możesz żądać, abyśmy
                            ograniczyli przetwarzanie Twoich danych osobowych wyłącznie do ich
                            przechowywania lub wykonywania uzgodnionych z Tobą działań, jeżeli
                            Twoim zdaniem mamy nieprawidłowe dane na Twój temat lub
                            przetwarzamy je bezpodstawnie, lub nie chcesz, żebyśmy je usunęli,
                            bo są Ci potrzebne do ustalenia, dochodzenia lub obrony roszczeń,
                            lub na czas wniesionego przez Ciebie sprzeciwu względem
                            przetwarzania danych,
                    </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            prawo do wniesienia sprzeciwu wobec przetwarzania danych:
                    </span>
                    </li>
                </ol>
                <ol className={props.classes.list2}>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            Sprzeciw „marketingowy”. Masz prawo sprzeciwu wobec przetwarzania
                            Twoich danych w celu prowadzenia marketingu bezpośredniego. Jeżeli
                            skorzystasz z tego prawa – zaprzestaniemy przetwarzania danych w
                            tym celu.
                    </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            Sprzeciw z uwagi na szczególną sytuację. Masz także prawo sprzeciwu
                            wobec przetwarzania Twoich danych na podstawie prawnie
                            uzasadnionego interesu w celach innych niż marketing bezpośredni, a
                            także, gdy przetwarzanie jest nam niezbędne do wykonania zadania
                            realizowanego w interesie publicznym lub do sprawowania powierzonej
                            nam władzy publicznej. Powinieneś wtedy wskazać nam Twoją
                            szczególną sytuację, która Twoim zdaniem uzasadnia zaprzestanie
                            przez nas przetwarzania objętego sprzeciwem. Przestaniemy
                            przetwarzać Twoje dane w tych celach, chyba że wykażemy, że
                            podstawy przetwarzania przez nas Twoich danych są nadrzędne wobec
                            Twoich praw lub też, że Twoje dane są nam niezbędne do ustalenia,
                            dochodzenia lub obrony roszczeń.
                    </span>
                    </li>
                </ol>
                <ol start="6" className={props.classes.list3}>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            prawo do przenoszenia danych – masz prawo otrzymać od nas
                        <br />
                            w ustrukturyzowanym, powszechnie używanym formacie nadającym się do
                            odczytu maszynowego dane osobowe Ciebie dotyczące, które nam
                            dostarczyłeś na podstawie umowy lub Twojej zgody. Możesz też zlecić
                            nam przesłanie tych danych bezpośrednio innemu podmiotowi,
                   </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            prawo do wniesienia skargi do organu nadzorczego – jeżeli uważasz,
                            że przetwarzamy Twoje dane niezgodnie z prawem, możesz złożyć w tej
                            sprawie skargę do Prezesa Urzędu Ochrony Danych Osobowych lub
                            innego właściwego organu nadzorczego,
                   </span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>
                            prawo do cofnięcia zgody na przetwarzanie danych osobowych – w
                            każdej chwili masz prawo cofnąć zgodę na przetwarzanie tych danych
                            osobowych, które przetwarzamy na podstawie Twojej zgody. Cofnięcie
                            zgody nie będzie wpływać na zgodność z prawem przetwarzania,
                            którego dokonano na podstawie Twojej zgody przed jej wycofaniem.
                   </span>
                    </li>
                </ol>
                <p>
                    W celu wykonania swoich praw skieruj żądanie pod adres e-mail:
                    kontakt@treo.com.pl lub zadzwoń pod numer 666 914 219. Przed realizacją
                    Twoich uprawnień będziemy musieli odpowiednio Cię zidentyfikować.
            </p>
                <ol type="I" start="8">
                    <li>
                        <p>
                            <strong>Informacja o wymogu/dobrowolności podania danych</strong>
                        </p>
                    </li>
                </ol>
                <p>
                    Podanie przez Ciebie danych jest warunkiem zawarcia umowy.
            </p>
                <p>
                    Jeżeli nie podasz danych, to możemy odmówić zawarcia umowy.
            </p>
            </Grid>
        </Grid>
    );

}

const mapStateToProps = (state) => {
    return {
        translations: state.translations
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(container));

