import React from 'react'
import PropTypes from 'prop-types';

import styles from 'Components/style';

import Translations from 'Singleton/Translations'
import { Paper, Grid, Typography } from '@material-ui/core';
import OrderStatus from 'Components/Commons/Const/OrderStatus'

const row = (title, value) => {
	return (
		<Grid item>
			<Typography variant='body1'>
				<b>{title}: </b>{value}
			</Typography>
		</Grid>
	)
}

const isPaidDate = (status) => {
	switch (status) {
		case OrderStatus.IN_PROGRESS: 
		case OrderStatus.SENT_TO_SUPPLIER:
		case OrderStatus.FORWARD:
		case OrderStatus.FORWARD_AUTO:
		case OrderStatus.TO_SUPPLY:
		case OrderStatus.CANCELED:
			return true;
		default: return false;
	}
}

const isUnloadingDate = (status) => {
	switch (status) {
		case OrderStatus.TO_SUPPLY:
		case OrderStatus.CANCELED:
			return false;
		default: return true;
	}
}

const isCanceledDate = (status) => {
	if(status === OrderStatus.CANCELED){
		return true;
	}
	return false;
}

const container = (props) => {
	return (
		<Paper>
			<Grid
				container
				style={styles.infoBox}
				direction='column'
			>
				{row(Translations.get('client'), props.clientName)}
				{row(Translations.get('evaluation'), props.valuationNumber)}
				{row(Translations.get('order_date'), props.orderDate)}

				{isPaidDate(props.status) && 
					row(Translations.get('paid'), props.paymentDate)
				}

				{isUnloadingDate(props.status) &&
					row(Translations.get('planned_loading_date'), props.unloadingDate)
				}

				{isCanceledDate(props.status) &&
					row(Translations.get('canceled_ord'), props.cancellationDate)
				}

				{props.status === OrderStatus.TO_SUPPLY &&
					row(Translations.get('planned_delivery_date'), props.plannedDeliveryDate)
				}

			</Grid>
		</Paper>
	);
}

container.propTypes = {
	clientName: PropTypes.string,
	valuationNumber: PropTypes.string,
	orderDate: PropTypes.string,
	unloadingDate: PropTypes.string,
	paymentDate: PropTypes.string,
	status: PropTypes.string,
	cancellationDate: PropTypes.string,
	plannedDeliveryDate: PropTypes.string,
}

export default container;
