import React from 'react'
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import PageButtons from './TablePageButtons'
import RestRequest from 'libs/RestRequest'

import { ValuationFilter } from 'Components/Commons/Const/Valuation'
import KeyTranslation from 'Components/Commons/Dictionaries/ValuationKeyTranslation'
import { FilterType } from 'Components/Commons/Const/Filter'

import { setValue } from 'Actions/repositoryActions'

import starFilledSvg from 'Resources/images/star.svg'
import starEmptySvg from 'Resources/images/star02.svg'

import Translation from 'Singleton/Translations'

import { withTheme } from '@material-ui/core/styles'
import { Typography, Grid, Button, MenuItem } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';

import TextInput from 'Components/Commons/TextInput'

import SearchIcon from '@material-ui/icons/Search'

import styles from "Components/style";
import Browser from 'libs/Browser'

class container extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			search: '',
			star: false,
			labelFilters: [],
			labels: [],
			valuationFilter: ValuationFilter.ALL,
		}
		this.getSearcher = this.getSearcher.bind(this);
		this.getButton = this.getButton.bind(this);
		this.search = this.search.bind(this);
		this.getFilters = this.getFilters.bind(this);
		this.getStar = this.getStar.bind(this);
		this.onStarClick = this.onStarClick.bind(this);
		this.getFilterLabelItems = this.getFilterLabelItems.bind(this);
		this.filterLabelChange = this.filterLabelChange.bind(this);
		this.filterValuationChange = this.filterValuationChange.bind(this);
		this.getValuationFilterDropDown = this.getValuationFilterDropDown.bind(this);
		this.setFilter = this.setFilter.bind(this);

		if (this.props.isFilter) {
			RestRequest(
				'get',
				'secured/labels',
				{},
				this.handleResponse,
				this.props.userToken
			)
		}
	}


	showError = (msg) => {
		toast.error(msg, {
			position: toast.POSITION.TOP_RIGHT,
		});
	}

	handleResponse = (res) => {
		console.log('getLabels', res);
		if (res.body.error) {
			this.showError(res.body.errorReason);
		} else {
			this.setState({
				...this.state,
				labels: res.body.list
			})
		}
	}

	styles = {
		root: {
			display: 'flex',
			justifyContent: 'space-between',
			marginTop: '1.25vw',
		},
		title: {
			fontSize: '1.112vw',
		},
		row: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'flex-end',
		},

		image: {
			width: '20px',
			height: '20px',
		},


		dropDown: {
			width: '200px',
		},
		firefoxFix:{
			position:'relative',
			top: '5.5px'
		}
	}

	getFix = () => {
		if (Browser.isChrome || Browser.isOpera){
			return {}
		}
		return this.styles.firefoxFix;
	}

	setFilter(columnName, type, payload) {
		if (this.props.setFilter) {
			this.props.setFilter(columnName, type, payload);
		}
	}

	deleteFilter(name, type) {
		if (this.props.deleteFilter) {
			this.props.deleteFilter(name, type);
		}
	}

	getSearcher() {
		if (!this.props.isSearcher) {
			return '';
		}
		return (
			<Grid
				container
				alignItems='baseline'
			>
				<Grid item>
					<TextInput
						id='searchButton'
						label={Translation.get('search')}
					/>
				</Grid>
				<Grid item>
					<IconButton
						onClick={this.search}
					>
						<SearchIcon />
					</IconButton>
				</Grid>
			</Grid>
		);
	}

	search() {
		if (this.props.inputs.searchButton === '') {
			this.deleteFilter([], FilterType.SEARCH)
			return;
		}
		this.setFilter(
			[],
			FilterType.SEARCH,
			this.props.inputs.searchButton
		);
	}

	getButton() {
		if (!this.props.button) {
			return '';
		}
		return (
			<Button
				variant='contained'
				onClick={this.props.button.onClick}
				style={styles.button1}
				color='primary'
				style={{
					marginRight: styles.space30.width,
				}}
			>
				{this.props.button.label}
			</Button>
		)
	}

	getStar(isFilled) {
		var star = isFilled ? starFilledSvg : starEmptySvg;
		return (
			<img src={star} alt='star' style={styles.image3} />
		);
	}

	getValuationFilterDropDown() {
		// <InputLabel htmlFor="select-multiple-valuation">
		// 				{Translation.get('all_valuations')}
		// 			</InputLabel>
		if (this.props.isOrder) {
			return (
				<FormControl>
					<Select
						value={this.state.valuationFilter}
						onChange={this.filterValuationChange}
						input={<Input id="select-multiple-valuation" />}
						style={styles.input1}
					>
						{this.getFilterValuationItems()}
					</Select>
				</FormControl>
			);
		}
		return '';
	}

	onStarClick() {
		console.log('click star', FilterType.STAR);
		if (this.state.star) {
			this.deleteFilter([], FilterType.STAR);
		} else {
			this.setFilter(
				[],
				FilterType.STAR,
				!this.state.star
			);
		}
		this.setState({
			...this.state,
			star: !this.state.star,
		})
	}

	getFilters() {
		if (!this.props.isFilter) {
			return '';
		}
		return (
			<Grid
				container
				alignItems='baseline'
			>
				<Grid item>
					<Typography variant='body1'>
						Filtry:
					</Typography>
				</Grid>
				<Grid item>
					<IconButton
						onClick={this.onStarClick}
					>
						{this.getStar(this.state.star)}
					</IconButton>
				</Grid>
				<Grid item style={this.getFix()}>
					<FormControl>
						<InputLabel htmlFor="select-multiple">{Translation.get('all_labels')}</InputLabel>
						<Select
							multiple
							value={this.state.labelFilters}
							onChange={this.filterLabelChange}
							input={<Input id="select-multiple" />}
							style={styles.input1}
						>
							{this.getFilterLabelItems()}
						</Select>
					</FormControl>
				</Grid>
				<Grid item style={styles.space30} />
				<Grid item style={this.getFix()}>
					{this.getValuationFilterDropDown()}
				</Grid>
				{this.props.isOrder &&
					<Grid item style={styles.space30} />
				}
			</Grid>
		);
	}

	filterValuationChange(event) {
		const value = event.target.value;
		this.setState({
			...this.state,
			valuationFilter: value,
		});

		if (value === ValuationFilter.ALL) {
			this.deleteFilter([], FilterType.VALUATION_TYPE);
			return;
		}
		this.setFilter(
			[],
			FilterType.VALUATION_TYPE,
			value
		);
	}

	filterLabelChange(event) {
		const values = event.target.value;
		this.setState({
			...this.state,
			labelFilters: values,
		});
		if (values.length <= 0) {
			this.deleteFilter([], FilterType.LABELS);
			return;
		}
		this.setFilter(
			[],
			FilterType.LABELS,
			values
		);
	}

	getFilterValuationItems() {
		var arr = ValuationFilter.GET_ALL;
		return arr.map((item, i) => {
			return (
				<MenuItem
					key={i}
					value={item}
				>
					{KeyTranslation.FilterOptions(item)}
				</MenuItem>
			);
		});
	}

	getFilterLabelItems() {
		if (!this.state.labels) {
			return '';
		}
		const values = this.state.labelFilters;
		return this.state.labels.map((item, i) => {
			return (
				<MenuItem
					key={i}
					value={item}
					style={{
						fontWeight:
							values.indexOf(item) === -1
								? this.props.theme.typography.fontWeightRegular
								: this.props.theme.typography.fontWeightMedium,
					}}
				>
					{item}
				</MenuItem>
			);
		});
	}

	render() {
		var title = this.props.title ? this.props.title : '';

		return (
			<Grid
				container
				alignItems='baseline'
			>
				<Grid item>
					<Typography variant='title'>
						{title}
					</Typography>
				</Grid>
				<Grid item style={{ flexGrow: 2 }} />
				<Grid item>
					{this.getFilters()}
				</Grid>
				<Grid item>
					{this.getButton()}
				</Grid>
				<Grid item>
					{this.getSearcher()}
				</Grid>
				<Grid item>
					<PageButtons
						nextAction={this.props.nextAction}
						previousAction={this.props.previousAction}
						length={this.props.length}
						step={this.props.step}
						page={this.props.page}
					/>
				</Grid>
			</Grid>
		);
	}
}

container.propTypes = {
	className: PropTypes.string,
	previousAction: PropTypes.func,
	nextAction: PropTypes.func,
	length: PropTypes.number.isRequired,
	step: PropTypes.number.isRequired,
	page: PropTypes.number.isRequired,	//begin from 0
	title: PropTypes.string,
	button: PropTypes.shape({
		label: PropTypes.string,
		onClick: PropTypes.func,
	}),
	isSearcher: PropTypes.bool,
	isFilter: PropTypes.bool,
	isOrder: PropTypes.bool,
	setFilter: PropTypes.func,
	deleteFilter: PropTypes.func,
}

const mapStateToProps = (state) => {
	return {
		inputs: state.input.inputState,
		userToken: state.user.token,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setRepositoryValue: (x, y) => {
			dispatch(setValue(x, y));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(container));
