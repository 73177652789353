import React from 'react'
import { connect } from "react-redux"
import { withRouter } from 'react-router-dom'
import { withTheme } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import FAQElement from '../FAQ/FAQElement'

import { setPage } from 'Actions/pageActions'
import { redirrect } from 'Actions/pageActions'


import styles from './../../style'
import Translations from 'Singleton/Translations'
import MediaType from '../../../libs/MediaType';

class FAQShortBlock extends React.Component {
  constructor(params) {
    super(params)
  }

  styles = {
    container: {
      backgroundColor: this.props.theme.palette.grey[300],
      paddingBottom: '27px',

    },
    content: {
      ...styles.container,
      paddingTop: styles.space30.width,
      maxWidth: '1280px',
      margin: '0 auto',
    },
    tabletContent: {
      ...styles.container,
      ...styles.rootTablet,
      paddingTop: styles.space30.width,
    },
    mobileContent: {
      ...styles.container,
      ...styles.rootMobile,
      paddingTop: styles.space30.width,
    },
    link: {
      paddingTop: '27px',
      ...styles.link
    },
    title: {
      marginBottom: styles.space30.width,
    }
  }

  onClick = () => {
    this.props.redirrect('/faq', this.props.history)
  }

  tab = {
    q1: {
      ask: "Jakie usługi zrealizuje dla Ciebie TREO?",
      answer: "Portal TREO.pl wyceni dla Ciebie importowy i eksportowy transport paletowy drobnicowy w UE. Dzięki Nam nie musisz czekać - wycena jest automatyczna i możesz w łatwy sposób przejść do realizacji zlecenia. Możesz również wysłać do nas zapytanie spersonalizowane, a nasz Zespół poszuka najlepszej oferty spełniającej twoje wymagania.",
    },
    q2: {
      ask: "Dokąd mogę wysłać paletę z TREO?",
      answer: "Z TREO dokonasz eksportu i importu drobnicowego palet po krajach Unii Europejskiej. Na zapytanie indywidualne zrealizujesz także transporty europejskie do krajów spoza UE (np. Szwajcaria, Norwegia, Bałkany) w atrakcyjnych cenach.",
    },
    q3: {
      ask: "Co zrobić jeśli kalkulator nie wyliczy ceny? ",
      answer: "W przypadku, gdy po wprowadzeniu danych nie pojawia się cena transportu lub nie możesz wprowadzić poprawnych parametrów, prześlij swoje zapytanie poprzez kliknięcie „ZAMÓW WYCENĘ RĘCZNĄ” (w polu „Dodatkowe uwagi” wpisz komplet danych). Po otrzymaniu takiego zapytania nasz Konsultant skontaktuje się z Tobą bezpośrednio.",
    }
  }

  
  render() {
    
    return (
      
        <div style={this.styles.container}>
          <div style={this.styles.content}>
            <Typography component="h2" variant='display1' align='left' style={this.styles.title}>
              {Translations.get('ask_and_answers')}
            </Typography>
            <Grid
              container
              justify='space-between'
            >
              <Grid item>
                <FAQElement
                  header={this.tab.q1.ask}
                  text={this.tab.q1.answer}
                  truncate
                />
              </Grid>
              <Grid item>
                <FAQElement
                  header={this.tab.q2.ask}
                  text={this.tab.q2.answer}
                  truncate
                />
              </Grid>
              <Grid item>
                <FAQElement
                  header={this.tab.q3.ask}
                  text={this.tab.q3.answer}
                  truncate
                />
              </Grid>
            </Grid>
            <Typography variant='subheading' onClick={this.onClick} style={this.styles.link}>
              {Translations.get('see_more')}
            </Typography>
          </div>
        </div>
      
    );
  }
}
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispath) => {
  return {
    setPage: (page) => { dispath(setPage(page)); },
    redirrect: (page, hist) => { dispath(redirrect(page, hist)); }
  };
};

export default withRouter(withTheme()(connect(mapStateToProps, mapDispatchToProps)(FAQShortBlock)));
