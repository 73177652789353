import React from 'react'
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { sessionService } from 'redux-react-session';
import { withRouter } from 'react-router-dom'

import { setPopup } from 'Actions/pageActions.js'
import { setLogin } from 'Actions/userActions'
import { redirrect } from 'Actions/pageActions';
import { ERROR } from 'libs/RestRequest'
import Rest from 'libs/RestRequest'

import { Paper, Grid, Button, Typography } from '@material-ui/core';
import styles from '../../../style';
import DatePicker from 'Components/Commons/DatePicker'
import Checkbox from 'Components/Commons/Checkbox'

import Translation from 'Singleton/Translations'

class container extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			dateDisable: props.date ? false : true,
		}

		this.dateChanged = this.dateChanged.bind(this);
		this.showError = this.showError.bind(this);
		this.handleErr = this.handleErr.bind(this);
		this.logout = this.logout.bind(this);
		this.dateEndlessChanged = this.dateEndlessChanged.bind(this);
	}

	styles = {
		root: {
			width: "30.743vw",
			height: "10.4166vw",
			margin: "2.5vw 0 0 0",
			textAlign: "center",
			paddingTop: "2.2vw"
		},
		text: {
			fontSize: "1.4vw",
			margin: '0 0.5vw 1rem',
		},
		timeInput: {
			fontSize: "1.4vw",
			width: "7vw",
			margin: '0 0.5vw',
		},
		row: {
			display: 'flex',
			justifyContent: 'center'
		}
	}



	dateChanged(value) {
		Rest(
			'post',
			'secured/voucher/date',
			{
				code: this.props.code,
				validTo: value
			},
			this.handleResponse,
			this.props.userToken
		);
	}

	logout() {
		sessionService.deleteSession().then(
			sessionService.deleteUser().then(() => {
				this.props.setLogin(null, null, null, null, null);
				this.props.redirrect('/panel', this.props.history);
			})
		);
	}

	showError(msg) {
		toast.error(msg, {
			position: toast.POSITION.TOP_RIGHT,
		});
	}

	handleErr(body) {
		switch (body.errorCode) {
			case ERROR.USER_TOKEN_IS_NOT_VALID:
				this.showError(Translation.get('session_expired'))
				this.logout();
				break;
			default:
				this.showError(body.errorReason)
		}
	}

	handleResponse = (res) => {
		console.log('Employee Res:', res);
		if (res.body.error) {
			this.handleErr(res.body);
			return;
		}
		if(this.props.refreshDataFunc){
			this.props.refreshDataFunc();
		}
	}

	dateEndlessChanged(value) {
		this.setState({
			...this.state,
			dateDisable: value,
		})
		if (value) {
			this.dateChanged(null);
		}else{
			this.dateChanged(this.props.inputDate);
		}
	}

	handleDateChanged = (value)=> {
		this.setState({
			...this.state,
			date: value,
		})
		this.dateChanged(value);
	}

	dateRow() {
		var date = this.props.date;
		if (!this.props.date) {
			date = new Date().getTime();
		}

		return (
			<Grid
				container
				alignItems='baseline'
			>
				<Grid item>
					<Typography variant='body1'>
						{Translation.get('valid_until')}:
					</Typography>
				</Grid>
				<Grid item style={styles.space30}>
				</Grid>
				<Grid item>
					<DatePicker
						id={'validUntilDate'}
						onChange={this.handleDateChanged}
						disabled={this.state.dateDisable}
						minDate={new Date().getTime()}
						defaultDate={date}
						blockChangeOnError
					/>
				</Grid>
			</Grid>
		);
	}

	render() {
		return (
			<Paper style={styles.infoBox}>
				<Grid
					container
					direction='column'
					alignItems='center'
				>
					<Grid item>
						{this.dateRow()}
					</Grid>
					<Grid item>
						<Checkbox
							id="validUntilDateEndless"
							label={Translation.get('lifetime')}
							defaultChecked={this.props.date ? false : true}
							onChange={this.dateEndlessChanged}
						/>
					</Grid>
				</Grid>
			</Paper>
		);
	}
}

container.propTypes = {
	date: PropTypes.number,
	code: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => {
	return {
		userToken: state.user.token,
		inputDate: state.input.inputState.validUntilDate,
		refreshDataFunc: state.repository.refreshDataFunc,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (x) => {
			dispatch(setPopup(x));
		},
		setLogin: (x, y, z, q, t, a) => {
			dispatch(setLogin(x, y, z, q, t, a));
		},
		redirrect: (x, y) => {
			dispatch(redirrect(x, y));
		}
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(container));
