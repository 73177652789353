
export class PageType {
	static get ORDER() {return 'order';}
	static get VALUATION() {return 'evaluation';}
	static get USER() {return 'user';}
}

export class CustomerType {
	static get COMPANY() {return 'company';}
	static get PRIVATE() {return 'private';}
}