import React from 'react'
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import RestRequest from 'libs/RestRequest'

import Translations from 'Singleton/Translations'
import { Paper, Grid, Button, Typography } from '@material-ui/core';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

import styles from '../../../style';

class container extends React.Component {
	constructor(props) {
		super(props);

		this.getEmptyBox = this.getEmptyBox.bind(this);
		this.getFieldBox = this.getFieldBox.bind(this);
		this.onAddChange = this.onAddChange.bind(this);
		this.add = this.add.bind(this);
		this.handleResponse = this.handleResponse.bind(this);
		this.getListItem = this.getListItem.bind(this);
		this.showError = this.showError.bind(this);
		this.onClick = this.onClick.bind(this);
	}

	styles = {
		list: {
			width:'100%',
			maxHeight: '60px',
			overflow: 'auto'
		},
		listItem:{
			paddingTop: 0,
			paddingBottom: 0,
		}
	}

	add() {
		document.getElementById('vatFile').click();
	}

	onAddChange(e) {
		console.log({
			fileName: e.target.files[0].name,
			file: e.target.files[0],
			number: this.props.number,
		});
		RestRequest(
			'file',
			'secured/invoice/file',
			{
				fileName: e.target.files[0].name,
				number: this.props.number,
			},
			this.handleResponse,
			this.props.userToken,
			e.target.files[0]
		)
	}

	showError(msg) {
		toast.error(msg, {
			position: toast.POSITION.TOP_RIGHT,
		});
	}

	handleResponse(res) {
		console.log('vat', res);
		if (res.body.error) {
			this.showError(res.body.errorReason);
		} else {
			this.props.refreshFunc();
		}
	}

	getEmptyBox() {
		return (
			<Typography variant='body1'>
				{Translations.get('lack_documents_of_invoice_vat')}
			</Typography>
		);
	}

	onDeleteClick(id) {
		console.log('delete click');
		RestRequest(
			'delete',
			'secured/invoice/file',
			{
				invoiceId: id,
			},
			this.handleResponse,
			this.props.userToken
		)
	}

	onClick(id) {
		console.log('download click');
		RestRequest(
			'get',
			'secured/invoice/file',
			{
				invoiceId: id,
			},
			this.handleFileResponse,
			this.props.userToken
		)
	}

	handleFileResponse(res) {
		console.log("download file", res);
		var url = "data:" + res.body.fileMime + ";base64," + res.body.fileData;
		var anchor = document.createElement("a");
		anchor.href = url;
		anchor.setAttribute("download", res.body.fileName);
		anchor.target = "_blank"
		document.body.appendChild(anchor);
		anchor.click();
		anchor.remove();
	}

	getListItem() {
		return this.props.documents.map((item, i) => {
			return(
				<ListItem 
					button 
					style={this.styles.listItem}
					key={i}
				>
					<ListItemText 
						primary={item.fileName} 
						onClick={() => { this.onClick(item.fileId); }}
					/>
					<IconButton
						onClick={() => { this.onDeleteClick(item.fileId); }}
					>
						<DeleteIcon />
					</IconButton>
				</ListItem>
			);
		});
	}

	getFieldBox() {
		return (
			<List style={this.styles.list}>
				{this.getListItem()}
			</List>
		);
	}



	getContent() {
		var box;
		if (this.props.documents && this.props.documents.length > 0) {
			box = this.getFieldBox();
		} else {
			box = this.getEmptyBox();
		}
		return (
			<Grid
				container
				direction='column'
				justify='space-between'
				alignItems='center'
				style={styles.infoBox}
			>
				<Grid item style={{width: '100%'}}>
					{box}
				</Grid>
				<Grid item>
					<Button
						variant='outlined'
						onClick={this.add}
					>
						{Translations.get('insert_invoice')}
					</Button>
					<input type="file"
						id="vatFile"
						style={{ display: 'none' }}
						onChange={this.onAddChange}
					/>
				</Grid>
			</Grid>
		);
	}

	render() {
		return (
			<Paper>
				{this.getContent()}
			</Paper>
		);
	}
}

container.propTypes = {
	className: PropTypes.string,
	documents: PropTypes.array.isRequired,
	number: PropTypes.string.isRequired,
	refreshFunc: PropTypes.func.isRequired,
}


const mapStateToProps = (state) => {
	return {
		userToken: state.user.token,
	};
}

const mapDispatchToProps = (dispatch) => {
	return {

	};
}

export default connect(mapStateToProps, mapDispatchToProps)(container);
