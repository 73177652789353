import React from 'react'
import { connect } from "react-redux";
import { withRouter, Link } from 'react-router-dom'
import { sessionService } from 'redux-react-session';
import { Base64 } from 'js-base64';
import { toast } from 'react-toastify';

import RestRequest from 'libs/RestRequest'
import przelewy24 from 'libs/przelewy24';

import { setPopup, redirrect, acceptStatement } from 'Actions/pageActions'

import Translations from 'Singleton/Translations'
import { Typography, Grid, Button } from '@material-ui/core';
import { ERROR } from 'libs/RestRequest'
import styles from 'Components/style'
import { setLogin } from 'Actions/userActions'

class container extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			number: undefined,
			isAcceptActive: true,
		}
		this.handleResponse = this.handleResponse.bind(this)
		this.handleErr = this.handleErr.bind(this);
		if (this.props.acceptStatement) {
			setTimeout(() => this.acceptClick(), 1);
		}
	}

	cancelClick() {
		this.props.setPopup(undefined);
	}

	acceptClick() {
		var data = this.props.inputs

		var fromModel = {
			country: this.findCountryId(data['country_from']),
			postcode: data['post_code_from'],
			companyName: data['more-info-loading-place-company'],
			street: data['more-info-loading-place-street'],
			city: data['more-info-loading-place-city'],
			senderName: data['more-info-loading-place-name'],
			senderSurname: data['more-info-loading-place-surname'],
			telNumber: data['more-info-loading-place-tel'],
			orderRefNumber: data['more-info-loading-place-nr-ref'],
			entranceLimited: data['more-info-loading-place-big-car'],
			liftCar: data['more-info-loading-place-lift-car'],
		}


		var toModel = {
			country: this.findCountryId(data['country_to']),
			postcode: data['post_code_to'],
			companyName: data['more-info-unloading-place-company'],
			street: data['more-info-unloading-place-street'],
			city: data['more-info-unloading-place-city'],
			senderName: data['more-info-unloading-place-name'],
			senderSurname: data['more-info-unloading-place-surname'],
			telNumber: data['more-info-unloading-place-tel'],
			orderRefNumber: data['more-info-unloading-place-nr-ref'],
			entranceLimited: data['more-info-unloading-place-big-car'],
			liftCar: data['more-info-unloading-place-lift-car'],
		}

		var invoiceData = {
			nip: data['more-info-billing-nip'],
		}
		if (this.props.user.isVatZero) {
			let vatData = this.props.vatZeroData;

			const item = this.props.countries.find((item) => {
				return item.value === vatData.country;
			})
			console.log('my item', item);

			invoiceData = {
				country: item.id,
				postcode: vatData.postcode,
				companyName: vatData.company,
				street: vatData.street,
				city: vatData.city,
				nip: vatData.nip,
				senderName: vatData.name,
				senderSurname: vatData.surname,
				telNumber: vatData.telNumber,
			}
		} else {
			switch (data['more-info-billing-radio-data']) {
				case 'loading':
					Object.assign(invoiceData, fromModel);
					invoiceData['isRecipient'] = true;
					break;
				case 'unloading':
					Object.assign(invoiceData, toModel);
					invoiceData['isRecipient'] = false;
					break;
				default:
					invoiceData['isRecipient'] = data['more-info-billing-radio-reciver'] === "sender";
					Object.assign(invoiceData, {
						country: this.findCountryId(data['more-info-billing-country']),
						postcode: data['more-info-billing-postcode'],
						companyName: data['more-info-billing-company'],
						street: data['more-info-billing-street'],
						city: data['more-info-billing-city'],
						senderName: data['more-info-billing-name'],
						senderSurname: data['more-info-billing-surname'],
						telNumber: data['more-info-billing-tel'],
					})
			}
		}

		const customIndex = this.props.pallets.findIndex((item) => {
			return item.isCustom;
		})

		var pallets = []
		for (var i = 0; i < this.props.palletsCount; i++) {
			let isCustom = data[`pallets_size_${i}`] === 'custom' ? true : false;
			let index = isCustom ? customIndex : data[`pallets_size_${i}`];
			pallets.push({
				paletType: this.props.pallets[index],
				height: data[`palet_height_${i}`],
				weight: data[`palet_weight_${i}`],
				fragile: data[`fragile_cargo_${i}`] ? data[`fragile_cargo_${i}`] : false,
				cargoType: data[`more-info-pallete-${i}-dropdown`] || '',
				description: data[`more-info-pallete-${i}-input`],
				length: data[`palet_length_${i}`],
				width: data[`palet_width_${i}`],
			})
		}


		var body = {
			pickupStart: (data['more-info-date-date'] + data['more-info-date-time-start']),
			pickupEnd: (data['more-info-date-date'] + data['more-info-date-time-end']),
			valuationNumber: data['valuation-number'] ? data['valuation-number'] : null, //???????
			palets: pallets,
			from: fromModel,
			to: toModel,
			priceNet: this.props.price.priceNet,
			priceGross: this.props.price.priceGross,
			cost: this.props.price.cost,

			invoiceData: invoiceData,
			paymentType: data['more-info-payment'],
			isPrivate: data['sender'] === "private",
			remarks: data['details'],

			isVatZero: this.props.user.isVatZero,
			vouchedCode: data.rabat,
		}
		var userId = this.props.userId;
		console.log(this.props);

		if (userId) {
			body['userId'] = userId;
		} else {
			body['userId'] = this.props.user.id;
		}

		if (!data['valuation-number']) {
			body['priceGrossBeforeDiscount'] = this.props.price.priceGrossBeforeDiscount;
			body['basicPrice'] = this.props.price.basicPrice;
			body['supplier'] = this.props.price.supplier === 'Kuehne-Nagel' ? 'kuehne_nagel' : this.props.price.supplier;
			body['discount'] = this.props.price.discount;
		}

		RestRequest(
			'post',
			'secured/createNewOrder',
			body,
			this.handleResponse,
			this.props.userToken
		);

		this.setState({
			...this.state,
			invoice: invoiceData,
			isAcceptActive: false,
		})
	}

	findCountryId(iso) {
		console.log(this.props.countrys);
		console.log(iso);
		var country = this.props.countrys.find((element) => {
			return element.value === iso;
		});
		return country.id;
	}

	logout() {
		sessionService.deleteSession().then(
			sessionService.deleteUser().then(() => {
				this.props.setLogin(null, null, null, null, null);
				this.props.setPopup(undefined);
				this.props.redirrect('/panel', this.props.history);
			})
		);
	}

	showError = (msg) => {
		toast.error(msg, {
			position: toast.POSITION.TOP_RIGHT,
		});
	}

	handleErr = (body) => {
		switch (body.errorCode) {
			case ERROR.USER_TOKEN_IS_NOT_VALID:
				this.showError(Translations.get('session_expired'))
				this.logout();
				break;
			default:
				this.showError(body.errorReason)
		}
	}

	handleResponse(res) {
		console.log(res.body);
		if (res.body.error) {
			this.setState({
				...this.state,
				isAcceptActive: true,
			}, () => this.handleErr(res.body))
			return;
		}

		var number = res.body.orderNumber;
		this.setState({
			...this.state,
			number: number,
		})
		this.props.setPopup(undefined);

		if (this.props.inputs['more-info-payment'] === 'online') {
			// RestRequest(
			// 	'get',
			// 	'przelewy24/getAuthData',
			// 	{},
			// 	(res) => {
			// 		var inv = this.state.invoice;
			// 		przelewy24(
			// 			number,
			// 			Math.floor(this.props.price.priceGross * 100),
			// 			"PLN",
			// 			inv.senderName + " " + inv.senderSurname,
			// 			"PL",
			// 			inv.street,
			// 			inv.postcode,
			// 			inv.city,
			// 			this.props.email,
			// 			res.body.crc,
			// 			res.body.merchantId,
			// 			res.body.address,
			// 		);
			// 	},
			// 	this.props.userToken
			// );
			// redirrect(res.body.orderNumber, this.props.history);
			window.location.href = res.body.payment.redirectUrl;
		} else {
			redirrect("order/" + Base64.encodeURI(res.body.orderNumber), this.props.history);
		}
	}


	render() {
		return (
			<Grid
				container
				direction='column'
			>
				<Grid item>
					<Typography variant='body1'>
						{Translations.get('statement_agreement_1')}{' '}
						<Link style={styles.link}
							target='_blank'
							to="/faq"
						>
							{Translations.get('statement_agreement_2')}
						</Link>
					</Typography>
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Button
						fullWidth
						onClick={this.acceptClick.bind(this)}
						variant='contained'
						color='primary'
						disabled={!this.state.isAcceptActive}
					>
						{Translations.get('confirm')}
					</Button>
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Button
						fullWidth
						variant='outlined'
						onClick={this.cancelClick.bind(this)}
					>
						{Translations.get('cancel')}
					</Button>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userToken: state.user.token,
		email: state.user.login,
		inputs: state.input.inputState,
		price: state.repository.price,
		priceSupplier: state.repository.priceSupplier,
		exchangeValue: state.translations.exchangeCurrencyAsk,
		pallets: state.translations.pallets,
		countrys: state.translations.countrys,
		palletsCount: state.imageBlockForm.paletsCount,
		userId: state.repository.userId,
		user: state.user,
		vatZeroData: state.repository.vatZeroData,
		countries: state.translations.countrys,
		acceptStatement: state.repository.acceptStatement
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (x) => {
			dispatch(setPopup(x));
		},
		redirrect: (x, y) => {
			dispatch(redirrect(x, y));
		},
		setLogin: (x, y, z, q, t, a) => {
			dispatch(setLogin(x, y, z, q, t, a));
		},
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(container));
