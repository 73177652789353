import { createMuiTheme } from '@material-ui/core/styles';

const muiTheme = createMuiTheme({
	palette: {
		primary: {
			main: '#faaf42',
			dark: '#faaf42',
		},
		secondary: {
			main: '#faaf42',
			dark: '#faaf42',
		},
	},
	typography: {
		fontFamily: "\"Open Sans\", \"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
	}
})

export default muiTheme;
