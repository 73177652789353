import React from 'react'
import {connect} from "react-redux";
import {sessionService} from 'redux-react-session';
import {toast} from 'react-toastify';
import {withRouter} from 'react-router-dom'

import RestRequest from 'libs/RestRequest'
import {ERROR} from 'libs/RestRequest'
import { Grid, Button } from '@material-ui/core';
import TextInput from 'Components/Commons/TextInput';
import styles from 'Components/style'

import {setPopup} from 'Actions/pageActions'
import {setLogin} from 'Actions/userActions'
import {redirrect} from 'Actions/pageActions'

import Translations from 'Singleton/Translations'


class container extends React.Component{
	constructor(props){
		super(props);
		this.state={
			isValidName:false,
			isValidSurname:false,
		}

		this.showError = this.showError.bind(this);
		this.handleErr = this.handleErr.bind(this);
		this.handleResponse = this.handleResponse.bind(this);
		this.logout = this.logout.bind(this);
	}

	cancelClick(){
		this.props.setPopup(undefined);
	}

	acceptClick(){
		var body={
			name: this.props.inputs.changeEmployeeName,
			surname: this.props.inputs.changeEmployeeSurname,
			userId: this.props.employeeId,
		}

		RestRequest(
			'post',
			'secured/employee/name',
			body,
			this.handleResponse,
			this.props.userToken,
		);
	}

	logout() {
		sessionService.deleteSession().then(
			sessionService.deleteUser().then(() => {
				this.props.setLogin(null,null,null,null,null);
				this.props.redirrect('/panel',this.props.history);
			})
		);
	}

	showError(msg){
		toast.error(msg,{
			position: toast.POSITION.TOP_RIGHT,
		});
	}

	handleErr(body){
		switch (body.errorCode) {
			case ERROR.USER_TOKEN_IS_NOT_VALID:
				this.showError(Translations.get('session_expired'))
				this.logout();
				break;
			default:
				this.showError(body.errorReason)
		}
	}

	handleResponse(res){
		console.log('Change name:',res);
		if(res.body.error){
			this.handleErr(res.body);
		}else{
			this.props.refreshDataFunc();
		}
		this.props.setPopup(undefined);
	}

	onNameValidChange(value){
		this.setState({
			...this.state,
			isValidName: value,
		})
	}

	onSurnameValidChange(value){
		this.setState({
			...this.state,
			isValidSurname: value,
		})
	}


	getEmployeeFullName(){
		return(
			<Grid container>
				<Grid item style={styles.popupColumn2}>
					<TextInput
						id="changeEmployeeName"
						label={Translations.get('name')}
						type="notEmpty"
						onValidChange={this.onNameValidChange.bind(this)}
						value={this.props.autoFillValues.name}
					/>
				</Grid>

				<Grid item style={styles.space30}>
				</Grid>

				<Grid item style={styles.popupColumn2}>
					<TextInput
						id="changeEmployeeSurname"
						label={Translations.get('surname')}
						type="notEmpty"
						onValidChange={this.onSurnameValidChange.bind(this)}
						value={this.props.autoFillValues.surname}
					/>
				</Grid>
			</Grid>
		);
	}

	getChoiceButtons(){
		return(
			<Grid container direction='column'>
				<Grid item>
					<Button
						onClick={this.acceptClick.bind(this)}
						disabled={!(this.state.isValidName && this.state.isValidSurname)}
						fullWidth
						variant='contained'
						color='primary'>
						{Translations.get('change_full_name')}
					</Button>
				</Grid>

				<Grid item style={styles.space16Vertical}>
				</Grid>
				
				<Grid item>
					<Button
						onClick={this.cancelClick.bind(this)}
						fullWidth
						variant='outlined'>
						{Translations.get('cancel')}
					</Button>
				</Grid>
			</Grid>
		);
	}

	render(){
		return(
			<Grid container direction='column'>
				<Grid item>
					{this.getEmployeeFullName()}
				</Grid>

				<Grid item style={styles.space16Vertical}>
				</Grid>

				<Grid item>
					{this.getChoiceButtons()}
				</Grid>
			</Grid>	
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userToken: state.user.token,
		inputs: state.input.inputState,
		employeeId: state.repository.employee,
		refreshDataFunc: state.repository.refreshDataFunc,
		autoFillValues: state.repository.autoFill,
	};
};

const mapDispatchToProps = (dispatch) => {
	return{
		setPopup: (x) => {
			dispatch(setPopup(x));
		},
		setLogin: (x,y,z,q,t,a) => {
		  dispatch(setLogin(x,y,z,q,t,a));
		},
		redirrect: (x,y) => {
		  dispatch(redirrect(x,y));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(container);
