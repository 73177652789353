import React from 'react'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'

import CircleMark from './CircleMark'
import CircleMarkHorizontal from './CircleMarkHorizontal'
import styles from './../../style'
import Translations from 'Singleton/Translations'
import MediaType from '../../../libs/MediaType';
import SwipeBlock from './SwipeBlock'

export class WhyBlock extends React.Component {

	styles = {
		container: {
			...styles.container,
			...styles.rootWeb,
			marginTop: '30px',
			marginBottom: '30px',
			backgroundColor: '#fff',
		},
		containerTablet: {
			...styles.container,
			...styles.rootTablet,
			
			marginTop: '30px',
			marginBottom: '30px'
		},
		containerMobile: {
			...styles.container,
			...styles.rootMobile,
			padding: 0,
			marginTop: '30px',
			marginBottom: '30px'
		},
		bgWhite: {
			backgroundColor: '#fff'
		},
		icon: {
			width: '50px',
			height: '50px',
			margin: '22px 0',
		},
		title: {
			marginTop: styles.space30.width,
			marginBottom: MediaType.isDesktop ? styles.space60.width : styles.space30.width,
		},
		title2: {
			marginBottom: '20px',
			fontSize: '1.3rem'

		},
		paragraph: {
			marginBottom: '40px',
			fontSize: '1rem'
		}
	}

	desktopContent() {
		return (
			<Grid
				container
				wrap="nowrap"
				spacing={0}
				justify='space-between'
			>
				
			</Grid>
		)
	}

	tabletContent = () => {
		return ''
	}

	getContent = () => {
		switch (MediaType.type) {
			case MediaType.TABLET: return this.tabletContent();
			default: return this.desktopContent();
		}
	}

	getRootStyle = () => {
		switch (MediaType.type) {
			case MediaType.MOBILE: return this.styles.containerMobile;
			case MediaType.TABLET: return this.styles.containerTablet;
			default: return this.styles.container; 
		}
	}

	render() {
	
		return (
			<div>
				<div style={this.getRootStyle()}>
					<Grid container spacing={0} style={this.styles.bgWhite}>
						<Grid item xs={12} lg={6}>
							<div style={{margin: '3rem'}}>
								<img src="/images/why.svg" style={{ width: '100%' }}/>
							</div>
						</Grid>
						<Grid item lg={6} style={{padding: '0 3rem'}}>
							<Typography component="h2" variant='display1' align='left' style={this.styles.title}>
								Dlaczego TREO?
							</Typography>
							<Typography component="h3" variant='display1' align='left' style={this.styles.title2}>
								Jakość stawiamy na pierwszym miejscu
							</Typography>
							<Typography component="span" variant='display1' align='left' style={this.styles.paragraph}>					
								Na każdym etapie współpracy dbamy o to, żeby Twoja paleta była traktowana z największą starannością. Każde zamówienie ma swojego opiekuna, który jest odpowiedzialny za opiekę nad Klientem i jego ładunkiem.	
							</Typography>
							<Typography component="h3" variant='display1' align='left' style={this.styles.title2}>
								Współpracujemy z najlepszymi przewoźnikami
							</Typography>
							<Typography component="span" variant='display1' align='left' style={this.styles.paragraph}>					
								Spośród wielu firm przewozowych wybraliśmy najlepsze. Dzięki wieloletniej współpracy możemy naszym Klientom zaoferować preferencyjne ceny, do których Klient detaliczny zwykle nie ma dostępu.
							</Typography>
						</Grid>
					</Grid>
					
				</div>
				{/*<div style={Object.assign({}, this.getRootStyle(), {backgroundColor: "transaprent"})}>
					<SwipeBlock 
						title={<span>Zadowolenie Klientów<br /> jest dla nas najważniejsze</span>} 
						icon="/images/user-circle-solid.svg" 
						itemTitle="Jan Kowalski, prezes firmy ACME" 
						itemText="Moje życie nigdy nie było tak proste odkąd odkryłem Treo!" 
					/>
				</div>*/}
			</div>
		);
	}
}
