const pageReducer = (state ={
	page: "/",
	selectedOrder: 0,
	popup: undefined,
	popupFunc: undefined,
	filtr: undefined,
}, action) => {
	switch (action.type) {
		case "SET_PAGE":
		state = {
			...state,
			page: action.page
		};
		window.scrollTo(0, 0)
		break;
		case "SET_POPUP":
		state = {
			...state,
			lastpoup: state.popup,
			popup: action.popup,
		}
		if(action.popup === undefined){
			state={
				...state,
				popupFunc: undefined,
			}
		}
		break;
		case "SET_POPUP_FUNC":
		state = {
			...state,
			popupFunc: action.func
		}
		break;
		case "SET_FILTER":
		state = {
			...state,
			popup: 'filtrs',
			filtr: action.filter
		}
		break;
		case "SET_EVAL_NUMBER":
		state = {
			...state,
			eNumber: action.eNumber
		}
		break;
		default:
		break;
	}
	return state;
};

export default pageReducer;
