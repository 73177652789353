const inputReducer = (state ={
	inputState: {},
	valid: {},
	defaultValues: {},
}, action) => {
	switch (action.type) {
		case "INPUT_SET_VALUE":
		state = {
			...state,
			inputState: {
				...state.inputState,
				[action.id]: action.value
			}
		};
		break;
		case "INPUT_SET_VALID":
		state = {
      ...state,
			valid: {
				...state.valid,
				[action.id]: action.value
			}
		}
		break;
		case "INPUT_SET_DEFAULT_VALUE":
		state = {
			...state,
			defaultValues: {
				...state.defaultValues,
				[action.id]: action.value
			}
		}
		break;
		case "INPUT_RESET_DEFAULT_VALUE":
		state = {
			...state,
			defaultValues:{}
		}
    break;
    case "INPUT_SET_HIGHLIGHT_ERRORS":
		state = {
			...state,
			highlightErrors: true
		}
    break;
    case "INPUT_RESET_HIGHLIGHT_ERRORS":
      state = {
        ...state,
        highlightErrors: false
      }
      break;
		default:
		break;
	}
	return state;
};

export default inputReducer;
