import React from "react";
import PropTypes from 'prop-types';

import { Paper, Typography } from "@material-ui/core";

import styles from 'Components/style'

import Translations from 'Singleton/Translations'
import getRootStyle from './Style'

const container = (props) => {
	return (
		<Paper style={getRootStyle()}>
			<Typography variant='title' paragraph>
				{Translations.get('additional_remarks')}
			</Typography>
			<Typography variant='body1'>
				{props.text}
			</Typography>
		</Paper>
	);
}

container.propTypes = {
	text: PropTypes.string,
}

export default container;
