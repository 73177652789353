import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { setInputValue } from "Actions/inputActions";

export class component extends Component {
	constructor(params) {
		super(params);
		this.state = {
			isChecked: params.defaultChecked ? params.defaultChecked : false,
		}
		if (this.props.getSetValueFunc) {
			this.props.getSetValueFunc(this.setValue)
		}
	}

	styles = {
		label: {
			marginBottom: 0,
		}
	}

	setValue = (value) => {
		this.setState({
			isChecked: value,
		})
		this.props.setInputValue(this.props.id, value);
		if (this.props.onChange) {
			this.props.onChange(value);
		}
	}

	componentDidMount() {
		this.props.setInputValue(this.props.id, this.state.isChecked);
	}

	handleChange = event => {
		this.setValue(event.target.checked);
	}

	render() {
		return (
			<FormControlLabel
				style={{
					...this.styles.label,
					...this.props.style
				}}
				control={
					<Checkbox
						disabled={this.props.disabled}
						checked={this.state.isChecked}
						onChange={this.handleChange}
						value={this.props.id}
						required={this.props.required}
					/>
				}
				label={this.props.label}
			/>
		)
	}
}

component.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.node,
	defaultChecked: PropTypes.bool,
	required: PropTypes.bool,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	getSetValueFunc: PropTypes.func,
	style: PropTypes.object,
}

component.defaultProps = {
	disabled: false,
	required: false
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = dispatch => {
	return {
		setInputValue: (id, value) => dispatch(setInputValue(id, value)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(component)
