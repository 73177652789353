import React, { version } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { debounce } from 'lodash';

import { Paper, Typography, Grid } from "@material-ui/core";
import FormHelperText from '@material-ui/core/FormHelperText';

import TextInput from 'Components/Commons/TextInput'
import { TextInputType } from 'Components/Commons/Const/InputTypes'
import Checkbox from 'Components/Commons/Checkbox'
import FlagSelect from "Components/Commons/FlagSelect";

import Translations from 'Singleton/Translations'
import styles from 'Components/style'
import getRootStyle from './Style'
import MediaType from "../../../libs/MediaType";
import { setInputValue } from 'Actions/inputActions'

class container extends React.Component {
	constructor(props) {
		super(props);
		this.isoToName = this.isoToName.bind(this);
		const { city, postCode, country, street } = this.props;
		this.state = {
			functions: {},
			city, postCode, country, street
		};
	}

	isoToName(iso) {
		return this.props.countries.find(
			(element) => { return iso === element.value; }
		).name;
	}

	styles = {
		rowCorrection1: {
			marginTop: '15px',
		},
		rowCorrection2: {
			marginTop: '37px'
		}
	}

	isoToPhoneCode(iso) {
		return "+" + this.props.countries.find(
			(element) => { return iso === element.value; }
		).phoneCode;
	}

	onCountryChange = (country) => {
		if (country && this.state.country !== country) {
			this.updatePostCode('');
			this.props.onCountryChange(country);
		}
	}

	postCodeChange = (postCode) => {
		if (postCode && this.state.postCode !== postCode) {
			this.props.onPostCodeChange(postCode);
		}
	}

	updatePostCode = (postCode) => {
		this.setPostCode(postCode);
		this.setState({ postCode });
	}

	updateFlag = (flag) => {
		this.state.functions.flagSelect(flag);
	}

	bindFunction = (name) => (callback) => {
		const f = this.state.functions;
		f[name] = callback;
		this.setState({
			...this.state,
			functions: f,
		});
	};

	getRequiredValidationError = () => {
		return (
			<FormHelperText style={{
				position: 'absolute', color: 'red',
				fontSize: '0.7rem', marginTop: '6px'
			}}>
				Wpisz wartość
			</FormHelperText>
		)
	}

	debouncedPostCodeChange = debounce((component, value) => {
		this.postCodeChange(component, value);
	}, 1000);

	render() {
		var companyId = `${this.props.id}-company`
		var streetId = `${this.props.id}-street`
		var cityId = `${this.props.id}-city`
		var nameId = `${this.props.id}-name`
		var surnameId = `${this.props.id}-surname`
		var telId = `${this.props.id}-tel`
		var nrRefOrder = `${this.props.id}-nr-ref`
		var bigCar = `${this.props.id}-big-car`
		var liftCar = `${this.props.id}-lift-car`
		var countryId = `${this.props.id}-country`
		var postcodeId = `${this.props.id}-postcode`
		return (
			<Paper style={getRootStyle()}>
				<Typography variant='title' paragraph>
					{this.props.title}
				</Typography>
				<Grid
					container
					direction='column'
				>
					<Grid item style={
						MediaType.isDesktop ?
							styles.moreInfoColumn3 :
							styles.mobileBox
					}>
						<TextInput
							style={{ marginBottom: '12px' }}
							required
							value=""
							label={Translations.get('company_name_or_fullname')}
							id={companyId}
							type={TextInputType.NOT_EMPTY}
							errorReason={'To pole jest wymagane'}
							isError={!!this.props.highlightErrors && !this.props.inputs[companyId]}
						/>

					</Grid>
					<Grid item style={
						MediaType.isDesktop ?
							styles.moreInfoColumn3 :
							styles.mobileBox
					}>
						<TextInput
							required
							label={Translations.get('street_and_number')}
							id={streetId}
							value={this.props.street}
							type={TextInputType.NOT_EMPTY}
							errorReason={'To pole jest wymagane'}
							isError={!!this.props.highlightErrors && !this.props.inputs[streetId]}
						/>
					</Grid>
					<Grid item>
						<Grid
							container
							justify='space-between'
							alignItems='baseline'
							style={
								MediaType.isDesktop ?
									{ ...styles.moreInfoColumn3, ...this.styles.rowCorrection1 } :
									this.styles.rowCorrection1
							}
						>
							<Grid container
								justify='space-between'
								alignItems='baseline'
								style={
									MediaType.isMobile ?
										styles.mobileBox :
										styles.moreInfoColumn1
								}>
								<Grid item>
									<FlagSelect
										bindSetValueFunc={this.bindFunction("flagSelect")}
										textValue={true}
										disabledCountries={['MT', 'CY', 'GB', 'NO', 'CH']}
										id={countryId}
										defaultValue={this.props.country}
										onChange={country => this.onCountryChange(country)}
									/>
								</Grid>
								<Grid item>
									<TextInput
										style={{ width: '120px' }}
										required
										onChange={postCode => (this.debouncedPostCodeChange(postCode))}
										label={Translations.get('postal_code')}
										id={postcodeId}
										value={this.props.postCode}
										getChangeValueFunc={(setPostCode) => {
											this.setPostCode = setPostCode;
										}}
										type={TextInputType.NOT_EMPTY}
										errorReason={'To pole jest wymagane'}
										isError={!!this.props.highlightErrors && !this.props.inputs[postcodeId]}
									/>
								</Grid>
							</Grid>
							<Grid item style={styles.space30}>
							</Grid>
							<Grid item style={
								MediaType.isDesktop ?
									styles.moreInfoColumn1 :
									MediaType.isMobile ?
										styles.mobileBox :
										styles.moreInfoColumn4
							}>
								<TextInput
									required
									label={Translations.get('city')}
									id={cityId}
									value={this.props.city}
									type={TextInputType.NOT_EMPTY}
									errorReason={'To pole jest wymagane'}
									isError={!!this.props.highlightErrors && !this.props.inputs[cityId]}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item style={styles.space30Vertical}>
					</Grid>
					<Grid item>
						<Typography variant='subheading'>
							{Translations.get('contact_person')}
						</Typography>
					</Grid>
					<Grid item>
						<Grid
							container
							justify='space-between'
							alignItems='baseline'
						>
							<Grid item style={
								MediaType.isDesktop ?
									styles.moreInfoColumn1 :
									MediaType.isMobile ?
										styles.mobileBox :
										styles.moreInfoColumn4
							}>
								<TextInput
									required
									label={Translations.get('name')}
									id={nameId}
									type={TextInputType.NOT_EMPTY}
									errorReason={'To pole jest wymagane'}
									isError={!!this.props.highlightErrors && !this.props.inputs[nameId]}
								/>
							</Grid>
							<Grid item style={styles.space30}>
							</Grid>
							<Grid item style={
								MediaType.isDesktop ?
									styles.moreInfoColumn1 :
									MediaType.isMobile ?
										styles.mobileBox :
										styles.moreInfoColumn4
							}>
								<TextInput
									required
									label={Translations.get('surname')}
									id={surnameId}
									type={TextInputType.NOT_EMPTY}
									errorReason={'To pole jest wymagane'}
									isError={!!this.props.highlightErrors && !this.props.inputs[surnameId]}
								/>
							</Grid>
							<Grid item style={styles.space60}>
								<Typography variant='body1' align='center'>
									({this.isoToPhoneCode(this.props.country)})
								</Typography>
							</Grid>
							<Grid item style={
								MediaType.isDesktop ?
									styles.moreInfoColumn2 :
									{ flexGrow: 2 }
							}>
								<TextInput
									required
									label={Translations.get('phone_number')}
									id={telId}
									type={TextInputType.NUMBER}
									limit={16}
									errorReason={'To pole jest wymagane'}
									isError={!!this.props.highlightErrors && !this.props.inputs[telId]}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item style={this.styles.rowCorrection2}>
						<Typography variant='body1'>
							{Translations.get('additional_informations')}
						</Typography>
					</Grid>
					<Grid item style={
						MediaType.isDesktop ?
							styles.moreInfoColumn3 :
							{ flexGrow: 2 }
					}>
						<TextInput
							id={nrRefOrder}
							label={Translations.get('reference_number')}
							optional
						/>
					</Grid>
					<Grid item>
						<Checkbox
							id={bigCar}
							label={Translations.get('vehicles_up_to_three_and_half_ton')}
						/>
					</Grid>
					{this.props.isCarLiftCheckbox ?
						<Grid item>
							<Checkbox
								id={liftCar}
								label={Translations.get('order_lift_car')}
								defaultChecked={this.props.inputs['lift_car']}
								disabled
							/>
						</Grid>
						: ''}
				</Grid>
			</Paper>
		)
	}
}

container.propTypes = {
	id: PropTypes.string,
	title: PropTypes.string,
	postCode: PropTypes.string,
	country: PropTypes.string,
	city: PropTypes.string,
	onPostCodeChange: PropTypes.func,
	isCarLiftCheckbox: PropTypes.bool,
}

const mapStateToProps = (state) => {
	return {
		highlightErrors: state.input.highlightErrors,
		countries: state.translations.countrys,
		translations: state.translationsm,
		inputs: state.input.inputState,
		valid: state.input.valid
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		setInputValue: (id, value) => { dispatch(setInputValue(id, value)) }
	};
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
	withRef: true
})(container);
