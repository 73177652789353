import React from 'react'
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'
import { Base64 } from 'js-base64';
import { sessionService } from 'redux-react-session';
import { toast } from 'react-toastify';

import Dropdown from 'Components/Commons/Dropdown'
import Header from 'Components/Body/OrderDetails/Header'

import { ERROR } from 'libs/RestRequest'
import Rest from 'libs/RestRequest'
import ValidUntilBox from 'Components/Body/Admin/Boxes/ValidUntil'
import CouponCodeBox from 'Components/Body/Admin/Boxes/CouponCode'
import DiscountValueBox from 'Components/Body/Admin/Boxes/DiscountValue'
import CouponUsedBox from 'Components/Body/Admin/Boxes/CouponUsed'

import { setValue } from 'Actions/repositoryActions'
import { redirrect } from 'Actions/pageActions'
import { setLogin } from 'Actions/userActions'

import Translations from 'Singleton/Translations'

import { Grid, Typography } from '@material-ui/core';
import styles from '../../../Components/style';
import CircularProgress from '@material-ui/core/CircularProgress';
import DropDownIcon from '@material-ui/icons/ArrowDropDown'


class container extends React.Component {
	constructor(props) {
		super(props);

		var code = Base64.decode(this.props.match.params.code);
		this.state = {
			isLoading: true,
			code: code,
		}
		this.getUsedCoupons = this.getUsedCoupons.bind(this);
		this.handleResponse = this.handleResponse.bind(this);
		this.getUsedCouponsDiv = this.getUsedCouponsDiv.bind(this);
		this.handleStatusResponse = this.handleStatusResponse.bind(this);
		this.refreshData = this.refreshData.bind(this);

		this.props.setRepositoryValue('couponCode', code);
		this.props.setRepositoryValue('refreshDataFunc', this.refreshData);

		if(!this.props.isAdmin){
			this.props.redirrect('/panel', this.props.history);
		}else{
			this.refreshData()
		}
	}

	refreshData() {
		Rest(
			'get',
			'secured/voucher',
			{ code: this.state.code },
			this.handleResponse,
			this.props.userToken
		)
	}

	logout = () => {
		sessionService.deleteSession().then(
			sessionService.deleteUser().then(() => {
				this.props.setLogin(null, null, null, null, null);
				this.props.redirrect('/panel', this.props.history);
			})
		);
	}

	showError(msg) {
		toast.error(msg, {
			position: toast.POSITION.TOP_RIGHT,
		});
	}

	handleErr(body) {
		switch (body.errorCode) {
			case ERROR.USER_TOKEN_IS_NOT_VALID:
				this.showError(Translations.get('session_expired'))
				this.logout();
				break;
			default:
				this.showError(body.errorReason)
		}
	}

	handleResponse(res) {
		console.log("coupon Details", res);
		if (res.body.error) {
			this.handleErr(res.body);
			return;
		}
		if (!res.body.code) {
			this.props.redirrect('/panel/coupons', this.props.history);
			return;
		}
		this.setState({
			...this.state,
			isLoading: false,
			...res.body,
		})
	}

	styles = {
		root: {
			padding: "2vw",
		},
		boxes: {
			display: "flex",
			justifyContent: "space-between",
		},
		secondTitle: {
			marginTop: '2.2vw',
			textAlign: 'left'
		},
		usedCoupons: {
			display: "flex",
			flexWrap: 'wrap',
			justifyContent: "space-between",
		},
		emptyItems: {
			width: "30.743vw",
		}
	}

	formatStatus(isUsed) {
		if (isUsed) {
			return Translations.get('coupon_used');
		}
		else {
			return Translations.get('coupon_active');
		}
	}

	deleteVoucher() {
		console.log("Delete");
		Rest(
			'delete',
			'secured/voucher/delete',
			{
				code: this.state.code,
			},
			this.handleStatusResponse,
			this.props.userToken,
		);
	}

	setStatus(status) {
		console.log("Set status ", status);
		Rest(
			'post',
			'secured/voucher/status',
			{
				code: this.state.code,
				isUsed: status === 'inactive',
			},
			this.handleStatusResponse,
			this.props.userToken,
		);
	}

	handleStatusResponse(res) {
		console.log('res status', res);
		if (res.body.error) {
			this.showError(res.body.errorReason);
		} else {
			this.refreshData();
		}
	}

	getButtonsMenu() {
		if (this.state.isUsed) {
			return [{
				label: Translations.get('activate'),
				onClick: () => { this.setStatus("active"); },
			}, {
				label: Translations.get('remove'),
				onClick: () => { this.deleteVoucher() },
			}];
		}
		else {
			return [{
				label: Translations.get('inactive'),
				onClick: () => { this.setStatus("inactive"); },
			}, {
				label: Translations.get('remove'),
				onClick: () => { this.deleteVoucher(); },
			}];
		}
	}

	getDropdownMenu() {
		const label = (
			<Typography>
				{Translations.get('actions')}
				<DropDownIcon style={{width: 20, height: 20}} />
			</Typography>
		)
		return (
			<Dropdown
				variant='outlined'
				label={label}
				rows={this.getButtonsMenu()}
			/>
		);
	}

	getUsedCoupons() {
		if (!this.state.voucherUsers.length > 0) {
			return '';
		}

		const items = this.state.voucherUsers.map((item, i) => {
			return (
				<Grid item key={i} style={{marginLeft: i%3>0 ? styles.space30.width: 0}}>
					<CouponUsedBox
						client={item.email}
						order={item.orderNumber}
						date={item.orderDate}
					/>
				</Grid>
			);
		});

		return (
			<Grid
				container
			>
				{items}
			</Grid>

		);
	}

	getUsedCouponsDiv() {
		if (this.state.voucherUsers.length <= 0) {
			return '';
		}
		return (
			<Grid
				container
				direction='column'
				style={styles.container}
			>
				<Grid item>
					<Typography variant='display1'>
						{Translations.get('coupon_used')}
					</Typography>
				</Grid>

				<Grid item>
					{this.getUsedCoupons()}
				</Grid>
			</Grid>
		)

		return (
			<div>
				<h2 style={this.styles.secondTitle}>{Translations.get('coupon_used')}</h2>
				<div style={this.styles.usedCoupons}>
					{this.getUsedCoupons()}
					<div style={styles.infoBox}></div>
					<div style={styles.infoBox}></div>
				</div>
			</div>
		);
	}

	getBoxRow() {
		return (

			<Grid container>
				<Grid item>
					<ValidUntilBox
						date={this.state.validTo}
						code={this.state.code}
					/>
				</Grid>

				<Grid item style={styles.space30}>
				</Grid>

				<Grid item>
					<CouponCodeBox
						code={this.state.code}
					/>
				</Grid>

				<Grid item style={styles.space30}>
				</Grid>

				<Grid item>
					<DiscountValueBox
						discount={this.state.discount}
						amountType={this.state.amountType}
					/>
				</Grid>
			</Grid>
		);
	}

	render() {
		if(!this.props.isAdmin){
			return '';
		}
		
		if (this.state.isLoading) {
			return (
				<CircularProgress size={80} thickness={5} />
			);
		}
		return (
			<Grid
				container
				direction='column'
			>
				<Grid item>
					<Grid
						container
						direction='column'
						style={styles.container}
					>
						<Grid item>
							<Header
								title={this.state.name}
								details={this.formatStatus(this.state.isUsed)}
								otherItems={this.getDropdownMenu()}
							/>
						</Grid>
						<Grid item style={styles.space30Vertical}>
						</Grid>
						<Grid item>
							{this.getBoxRow()}
						</Grid>
					</Grid>
				</Grid>

				<Grid item style={styles.space30Vertical}>
				</Grid>

				<Grid item>
					{this.getUsedCouponsDiv()}
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userToken: state.user.token,
		isAdmin: state.user.isAdmin,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setRepositoryValue: (x, y) => {
			dispatch(setValue(x, y));
		},
		redirrect: (x, y) => {
			dispatch(redirrect(x, y));
		},
		setLogin: (x, y, z, q, t, a) => {
			dispatch(setLogin(x, y, z, q, t, a));
		},
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(container));
