import React from 'react'
import { connect } from "react-redux";
import { sessionService } from 'redux-react-session';
import { withRouter } from 'react-router-dom'

import RestRequest from 'libs/RestRequest'

import { setPopup } from 'Actions/pageActions'

import { setLogin } from 'Actions/userActions'
import { redirrect } from 'Actions/pageActions';

import Translations from 'Singleton/Translations'
import { Grid, Typography, Button } from '@material-ui/core';
import styles from '../../Components/style';

class container extends React.Component {
	constructor(props) {
		super(props);
		this.handleResponse = this.handleResponse.bind(this);
		this.logout = this.logout.bind(this);
	}

	cancelClick() {
		this.props.setPopup(undefined);
	}

	acceptClick() {
		RestRequest('delete',
			'/secured/user',
			{ userId: this.props.userId },
			this.handleResponse,
			this.props.userToken,
		);
	}

	handleResponse(res) {
		console.log("Delete Account", res);
		if (!res.body.error) {
			if (this.props.isLogout) {
				this.logout();
			}
			if (this.props.isBack) {
				window.history.back();
			}
			if(this.props.popupFunc){
				this.props.popupFunc()
			}
			this.props.setPopup(undefined);
		}
	}

	logout() {
		sessionService.deleteSession().then(
			sessionService.deleteUser().then(() => {
				this.props.setLogin(null, null, null, null, null);
				this.props.redirrect('/', this.props.history);
			})
		);
	}

	styles = {
		root: {
			textAlign: 'center'
		},
		text: {
			fontSize: "1.2vw",
			margin: "3vw 0",
		},
	}

	render() {
		return (
			<Grid
				container
				direction='column'
			>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Typography variant='body1' align='center'>
						{Translations.get('warning_cannot_undone')}
					</Typography>
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Button
						fullWidth
						variant='contained'
						color='primary'
						onClick={this.acceptClick.bind(this)}
					>
						{Translations.get('remove')}
					</Button>
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Button
						fullWidth
						variant='outlined'
						onClick={this.cancelClick.bind(this)}
					>
						{Translations.get('cancel')}
					</Button>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userToken: state.user.token,
		userId: state.repository.userId,
		isLogout: state.repository.logout,
		isBack: state.repository.back,
		popupFunc: state.page.popupFunc,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (x) => {
			dispatch(setPopup(x));
		},
		setLogin: (x, y, z, q, t, a) => {
			dispatch(setLogin(x, y, z, q, t, a));
		},
		redirrect: (x, y) => {
			dispatch(redirrect(x, y));
		}
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(container));
