import React from "react";
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MediaType from "../../../libs/MediaType";
import { IconButton } from "@material-ui/core";
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowUpIcon from '@material-ui/icons/ArrowDropUp'

const styles = {
    container: {
        marginBottom: 15,
    },
    containerTablet: {
        marginBottom: 15,
    },
    mobileContainer: {
        marginBottom: 15,
    }
}

const getStyle = () => {
    switch (MediaType.type) {
        case MediaType.TABLET: return styles.containerTablet;
        case MediaType.MOBILE: return styles.mobileContainer;
        default: return styles.container;
    }
}

class Mobile extends React.Component {

    state = {
        isOpen: false,
    }

    onArrowClick = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    render() {
        let text = this.props.text;
        if (this.props.truncate && this.props.text.length > 400) {
            text = this.props.text.substring(0, 400) + "...";
        }

        return (
            <div style={getStyle()}>
                <Grid
                    container
                    wrap='nowrap'
                    alignItems='flex-start'
                    justify='space-between'
                >
                    <Grid item>
                        <Typography variant='subheading' paragraph>
                            {this.props.header}
                        </Typography>
                    </Grid>

                    <Grid item>
                        <IconButton
                            onClick={this.onArrowClick}
                        >
                            {this.state.isOpen ?
                                <ArrowUpIcon /> :
                                <ArrowDownIcon />
                            }
                        </IconButton>
                    </Grid>
                </Grid>
                {
                    this.state.isOpen && (
                        typeof text === "string" ?
                            <Typography
                                variant='body1'
                                align='justify'
                                style={{
                                    width: '100%',
                                }}>
                                {text}
                                {this.props.children}
                            </Typography>
                            :
                            text
                    )
                }
            </div>
        )
    }
}

const FAQElement = (props) => {
    if (MediaType.isMobile) {
        return (<Mobile {...props} />);
    }
    var text = props.text;
    if (props.truncate && props.text.length > 400) {
        text = props.text.substring(0, 400) + "...";
    }

    return (
        <div style={getStyle()} id={props.anchor}>
            <a href={'#' + props.anchor}></a>
            <Typography variant='title' paragraph>
                {props.header}
            </Typography>
            {typeof text === "string" ?
                <Typography variant='body1' align='justify'>
                    {text}
                    {props.children}
                </Typography>
                :
                text
            }
        </div>
    );
};



FAQElement.propTypes = {
    header: PropTypes.string.isRequired,
    text: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.object.isRequired,
    ]),
    truncate: PropTypes.bool
};

FAQElement.defaultProps = {
    truncate: false
};

export default FAQElement;
