
export class TextInputType {
	static get NUMBER() {return 'number';}
	static get EMAIL() {return 'email';}
	static get PASSWORD() {return 'password';}
	static get NOT_EMPTY() {return 'notEmpty';}
	static get NIP() {return 'nip';}
	static get DEFAULT() { return '' }
	static get FLOAT() { return 'float'; }

	static getAll() {
		return [
			this.NUMBER,
			this.EMAIL,
			this.PASSWORD,
			this.NOT_EMPTY,
			this.NIP,
			this.DEFAULT,
			this.FLOAT
		]
	}
}
