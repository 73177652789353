import React from "react";
import { Link } from 'react-router-dom';
import { Grid, Typography } from "@material-ui/core";
import { withTheme } from '@material-ui/core/styles'


import styles from './../style'
import Translations from 'Singleton/Translations'
import MediaType from 'libs/MediaType'

class FooterBar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			items: [
				{ text: Translations.get('regulations'), link: '/regulamin' },
				{ text: Translations.get('privacy_policy'), link: '/polityka_prywatnosci' },
				{ text: "Dofinansowanie", link: '/dofinansowanie' },
				{ text: Translations.get('contact'), link: '/kontakt' }
			]
		}
	}

	styles = {
		container: {
			borderTop: `solid 1px ${this.props.theme.palette.grey[500]}`,
			backgroundColor: this.props.theme.palette.grey[300],
		},
		content: {
			...styles.rootWeb,
			padding: '15px 45px'
		},
		contentTablet: {
			...styles.rootTablet,
			padding: '15px 45px'
		},
		contentMobile: {
			...styles.rootMobile,
			padding: '15px',
		},
		item: {
			marginRight: '16px'
		},
		text: {
			textDecoration: 'underline'
		}
	}

	gerItems() {
		return this.state.items.map((item, index) => {
			return (
				<Grid item key={index} style={this.styles.item}>
					<Link to={item.link}>
						<Typography style={this.styles.text}>
							{item.text}
						</Typography>
					</ Link>
				</Grid>
			);
		});
	}

	render() {
		return (
			<div style={this.styles.container}>

				<Grid
					container
					style={
						MediaType.isMobile ?
							this.styles.contentMobile :
							MediaType.isTablet ?
								this.styles.contentTablet :
								this.styles.content
					}
					justify='center'
				>
					<Grid item style={this.styles.item}>
						<Typography variant='body1'>
							© TREO
						</Typography>
					</Grid>

					{this.gerItems()}



				</Grid>

			</div>
		);
	}
};

export default withTheme()(FooterBar);
