import React from 'react'
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import { setInputValue } from "Actions/inputActions";
import MediaType from '../../libs/MediaType';

//TODO: show error

class component extends React.Component {
	constructor(params) {
		super(params);

		let selected = params.items[0].key
			? params.items[0].key
			: params.items[0];

		if(this.props.defaultValue){
			selected = this.props.defaultValue;
		}

		this.state = {
			selected,
			isError: false,
		}

		if(this.props.defaultValue){
			this.validateChange()
			this.props.onChange(selected);
		}
	}

	componentDidMount() {
		this.props.setInputValue(this.props.id, this.state.selected)
	}

	onValidChange = (status) => {
		const state = this.state;
		if (this.state.isError === status) {
			return;
		}
		this.setState({
			...state,
			isError: status
		})
		if (this.props.onValidChange !== undefined) {
			this.props.onValidChange(!status);
		}
	}

	validateChange = () => {
		if (this.state.selected.toString().includes("__err")) {
			this.onValidChange(true);
		} else {
			this.onValidChange(false);
		}
	}

	handleChange = () => event => {
		const state = this.state;
		this.props.setInputValue(this.props.id, event.target.value)
		this.setState({
			...state,
			selected: event.target.value,
		},
			this.validateChange);


		if (this.props.onChange) {
			this.props.onChange(event.target.value);
		}
	}

	getItems() {
		return this.props.items.map((item, i) => {
			var key = item.key ? item.key : item;
			var value = item.value ? item.value : item;
			if (item.key && item.key === "err") {
				key = `__err${i}`;
				value += '*';
			}
			return (
				<MenuItem
					key={key}
					value={key}
				>
					{value}
				</MenuItem>
			);
		});
	}

	render() {
		const helperText = this.state.isError ? this.props.errorReason :
			MediaType.isMobile ? '' : ' ';
		return (
			<TextField
				select
				style={this.props.style}
				label={this.props.label}
				onChange={this.handleChange()}
				value={this.state.selected}
				margin="dense"
				error={Boolean(this.props.isError) === this.props.isError ? this.props.isError :  this.state.isError}
				helperText={helperText}
				disabled={this.props.disabled}
			>
				{this.getItems()}
			</TextField>
		);
	}
}

component.propTypes = {
	id: PropTypes.string.isRequired,
	items: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.string),
		PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.any.isRequired,
				value: PropTypes.string.isRequired,
			})
		)
	]).isRequired,
	label: PropTypes.string,
	onChange: PropTypes.func,
	style: PropTypes.object,
	errorReason: PropTypes.any,
	onValidChange: PropTypes.func,
  disabled: PropTypes.bool,
  isError: PropTypes.bool,
	defaultValue: PropTypes.string,
}

component.defaultProps = {
	disabled: false,
}

const mapStateToProps = (state) => ({
	input: state.input

})

const mapDispatchToProps = (dispath) => {
	return {
		setInputValue: (id, value) => {
			dispath(setInputValue(id, value));
		}
	};
};


export default connect(mapStateToProps, mapDispatchToProps)(component);
