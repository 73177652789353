import React from 'react'
import PropTypes from 'prop-types';

import Translations from 'Singleton/Translations'
import { Paper, Grid, Typography } from '@material-ui/core';
import styles from '../../../style';
import {ValuationStatus} from 'Components/Commons/Const/Valuation';

const row = (titleCode, value) => {
	return (
		<Grid item>
			<Typography variant='body1'>
				<b>{Translations.get(titleCode)}: </b>{value}
			</Typography>
		</Grid>
	)
}

const isOutdated = (status) => {
	return status === ValuationStatus.OUTDATED;
}

const isRejected = (status) => {
	return status === ValuationStatus.REJECTED;
}

const isAwait = (status) => {
	return status === ValuationStatus.AWAIT
}

const container = (props) => {
	return (
		<Paper>
			<Grid
				container
				style={styles.infoBox}
				direction='column'
			>
				{row('client',props.clientName)}
				{row('order',props.orderName)}
				{row('ordered',props.ordered)}
				{
					isAwait(props.status) &&
						row('sent_for_acceptance',props.valuationDate)
				}
				{
					isOutdated(props.status) &&
						row('outdated',props.outdatedDate)
				}
				{
					isRejected(props.status) &&
						row('rejected', props.rejectionDate)
				}
			</Grid>
		</Paper>
	);
}

container.propTypes = {
	className: PropTypes.string,
	clientName: PropTypes.string,
	orderName: PropTypes.string,
	ordered: PropTypes.string,
	outdatedDate: PropTypes.string,
	rejectionDate: PropTypes.string,
	status: PropTypes.string,
	valuationDate: PropTypes.string,
}

export default container;
