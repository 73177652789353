import React from 'react'
import { connect } from "react-redux";
import { sessionService } from 'redux-react-session';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom'

import RestRequest from 'libs/RestRequest'
import { ERROR } from 'libs/RestRequest'

import Radio from 'Components/Commons/RadioGroup'
import { Typography, Grid, Button } from '@material-ui/core';
import styles from 'Components/style'

import { setPopup } from 'Actions/pageActions'
import { setLogin } from 'Actions/userActions'
import { redirrect } from 'Actions/pageActions';

import Translations from 'Singleton/Translations'

class container extends React.Component {
	constructor(props) {
		super(props);
		this.getRadioLabel = this.getRadioLabel.bind(this);
		this.showError = this.showError.bind(this);
		this.handleErr = this.handleErr.bind(this);
		this.handleResponse = this.handleResponse.bind(this);
		this.logout = this.logout.bind(this);
	}

	cancelClick() {
		this.props.setPopup(undefined);
	}

	acceptClick() {

		RestRequest(
			'post',
			'secured/employee/role',
			{
				role: this.props.inputs.changeRoleRadio,
				employeeId: this.props.employeeId,
			},
			this.handleResponse,
			this.props.userToken
		);
	}

	logout() {
		sessionService.deleteSession().then(
			sessionService.deleteUser().then(() => {
				this.props.setLogin(null, null, null, null, null);
				this.props.redirrect('/panel', this.props.history);
			})
		);
	}

	showError(msg) {
		toast.error(msg, {
			position: toast.POSITION.TOP_RIGHT,
		});
	}

	handleErr(body) {
		switch (body.errorCode) {
			case ERROR.USER_TOKEN_IS_NOT_VALID:
				this.showError(Translations.get('session_expired'))
				this.logout();
				break;
			default:
				this.showError(body.errorReason)
		}
	}

	handleResponse(res) {
		console.log('Change role:', res);
		if (res.body.error) {
			this.handleErr(res.body);
		} else {
			this.props.pageFunc(this.props.inputs.changeRoleRadio);
		}
		this.props.setPopup(undefined);
	}

	getRadioLabel(text, description) {
		return (
			<Grid container direction='column'>
				<Grid item>
					<Typography variant='body1'>
						{text}
					</Typography>
					<Typography variant='caption' color='textSecondary' paragraph>
						{description}
					</Typography>
				</Grid>
			</Grid>
		);
	}

	getChoiceButtons() {
		return (
			<Grid container direction='column'>
				<Grid item>
					<Button
						onClick={this.acceptClick.bind(this)}
						fullWidth
						variant='contained'
						color='primary'>
						{Translations.get('change_role')}
					</Button>
				</Grid>

				<Grid item style={styles.space16Vertical}>
				</Grid>

				<Grid item>
					<Button
						onClick={this.cancelClick.bind(this)}
						fullWidth
						variant='outlined'>
						{Translations.get('cancel')}
					</Button>
				</Grid>
			</Grid>
		);
	}

	render() {
		return (
			<Grid container direction='column'>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Radio
						id="changeRoleRadio"
						items={[{
							label: this.getRadioLabel(Translations.get('admin'), Translations.get('admin_role_info')),
							key: 'admin'
						}, {
							label: this.getRadioLabel(Translations.get('manager'), Translations.get('manager_role_info')),
							key: 'manager'
						}, {
							label: this.getRadioLabel(Translations.get('employee'), Translations.get('employee_role_info')),
							key: 'employee'
						}]}
						defaultKey={this.props.autoFillValues.role}
					/>
				</Grid>

				<Grid item style={styles.space16Vertical}>
				</Grid>

				<Grid item>
					{this.getChoiceButtons()}
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userToken: state.user.token,
		inputs: state.input.inputState,
		pageFunc: state.page.popupFunc,
		employeeId: state.repository.employee,
		autoFillValues: state.repository.autoFill,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (x) => {
			dispatch(setPopup(x));
		},
		setLogin: (x, y, z, q, t, a) => {
			dispatch(setLogin(x, y, z, q, t, a));
		},
		redirrect: (x, y) => {
			dispatch(redirrect(x, y));
		}
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(container));
