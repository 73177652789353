import React from 'react'
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import { sessionService } from 'redux-react-session';
import { Base64 } from 'js-base64';
import { withRouter } from 'react-router-dom'

import Rest from 'libs/RestRequest'
import Translations from 'Singleton/Translations'

import Sticker from 'Components/Commons/Sticker'
import Dropdown from 'Components/Commons/Dropdown'
import Bar from 'Components/Body/Admin/OrdersBar'
import ColumnHeader from 'Components/Body/Admin/ColumnHeader'
import { FilterType, FilterPopup } from 'Components/Commons/Const/Filter'

import OrderKeyTranslation from 'Components/Commons/Dictionaries/OrderKeyTranslation'
import OrderColumn from 'Components/Commons/Const/OrderColumn'
import OrderStatus from 'Components/Commons/Const/OrderStatus'
import { SupplierName } from 'Components/Commons/Const/Supplier'
import SupplierKeyTranslation from 'Components/Commons/Dictionaries/SupplierKeyTranslation'

import { ERROR } from 'libs/RestRequest'
import { timeFormatDots } from 'libs/dateformat'
import { setPopup } from 'Actions/pageActions'
import { setLogin } from 'Actions/userActions'
import { redirrect, setPopupFilter, setPopupFunc } from 'Actions/pageActions'
import { setValue } from 'Actions/repositoryActions'

import { Grid, Typography } from '@material-ui/core'
import { Paper } from '@material-ui/core'
import styles from "Components/style";
import { withTheme } from '@material-ui/core/styles'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress'
import StarIcon from '@material-ui/icons/Star'

import DropDownIcon from '@material-ui/icons/ArrowDropDown'

class container extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hoveredElement: -1,
			hoveredColumn: -1,
			clickedButton: false,
			tableData: [],
			isLoading: true,
			currentPage: 0,
			pageItemLimit: 10,
			lenghtData: 140,
			orderColumn: OrderColumn.NUMBER,
			orderDesc: true,
			filters: [],
		};

		this.setFilterToRepository([]);

		this.handleResponse = this.handleResponse.bind(this);
		this.logout = this.logout.bind(this);
		this.handleErr = this.handleErr.bind(this);
		this.showError = this.showError.bind(this);
		this.onPreviousButtonClick = this.onPreviousButtonClick.bind(this);
		this.onNextButtonClick = this.onNextButtonClick.bind(this);
		this.refreshData = this.refreshData.bind(this);
		this.getTextFilter = this.getTextFilter.bind(this);
		this.getCheckboxFilter = this.getCheckboxFilter.bind(this);
		this.setFilter = this.setFilter.bind(this);
		this.getLabels = this.getLabels.bind(this);
		this.deleteFilter = this.deleteFilter.bind(this);
		this.getDropdownMenu = this.getDropdownMenu.bind(this);
		this.popupOnAccept = this.popupOnAccept.bind(this);
		this.handleStatusResponse = this.handleStatusResponse.bind(this);

		this.checkIsAdmin()
	}

	checkIsAdmin = () => {
		if (!this.props.isAdmin) {
			this.props.redirrect('/panel', this.props.history);
		}
	}

	logout() {
		sessionService.deleteSession().then(
			sessionService.deleteUser().then(() => {
				this.props.setLogin(null, null, null, null, null);
				this.props.redirrect('/panel', this.props.history);
			})
		);
	}

	showError = (msg) => {
		toast.error(msg, {
			position: toast.POSITION.TOP_RIGHT,
		});
	}

	handleErr = (body) => {
		switch (body.errorCode) {
			case ERROR.USER_TOKEN_IS_NOT_VALID:
				this.showError(Translations.get('session_expired'))
				this.logout();
				break;
			default:
				this.showError(body.errorReason)
		}
	}

	handleResponse(res) {
		console.log("orders", res);
		if (res.body.error) {
			this.handleErr(res.body);
			return;
		}
		this.setState({
			...this.state,
			isLoading: false,
			tableData: res.body.list,
			lenghtData: res.body.rowsCount,
		});
	}

	getStarImg(isMarked) {
		if (isMarked) {
			return (
				<StarIcon
					color='primary'
					style={{ fontSize: 16, marginBottom: '2px', marginLeft: '2px' }}
				/>
			);
		}
		return '';
	}

	getLabels(labels) {
		return labels.map((item, i) => {
			return (
				<Grid item key={i}>
					<Sticker
						hover={false}
					>
						{item}
					</Sticker>
				</Grid>
			)
		});
	}

	styles = {
		loadingAnimBG: {
			width: "100%",
			position: "absolute",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			background: "rgba(100,100,100,0.5)",
			display: "block",
			textAlign: 'center',
			paddingTop: '40px',
		},
		lastCell: {
			position: 'relative',
			wordWrap: 'break-word',
		},
		actionButton: {
			position: 'absolute',
			top: '5px',
			right: '5px',
			backgroundColor: '#fff'
		},
		marked: {
			borderLeft: `solid ${this.props.theme.palette.primary.main}`
		}
	}

	onRowEnter(index) {
		this.setState({
			...this.state,
			hoveredElement: index
		})
	}

	onRowLeave(index) {
		if (this.state.clickedButton) {
			return;
		}
		this.setState({
			...this.state,
			hoveredElement: -1
		})
	}

	onClickDropdown() {
		this.setState({
			...this.state,
			clickedButton: true
		})
	}

	onCloseDropdown() {
		this.setState({
			...this.state,
			clickedButton: false,
			hoveredElement: -1
		})
	}

	hashNumber(number) {
		return Base64.encodeURI(number);
	}

	isStripeRow(state) {
		switch (state) {
			case OrderStatus.NEW:
			case OrderStatus.AWAIT:
			case OrderStatus.IN_PROGRESS:
			case OrderStatus.CANCELED:
				return true;
			default:
				return false;
		}
	}

	cancelOrder(number) {
		console.log('cancel order');
		Rest(
			'post',
			'secured/order/status/cancel',
			{
				number,
			},
			this.handleStatusResponse,
			this.props.userToken,
		)
	}

	retryOrder(number) {
		console.log('retry order');
		this.setAsNewOrder(number);
	}

	setAsNewOrder(number) {
		console.log('set as new order');
		Rest(
			'post',
			'secured/order/status/new',
			{
				number,
			},
			this.handleStatusResponse,
			this.props.userToken,
		)
	}

	handleStatusResponse(res) {
		if (res.body.error) {
			this.showError(res.body.errorReason)
		} else {
			this.popupOnAccept();
		}
	}

	popupOnAccept() {
		console.log('popup on accept');
		this.setState({
			isLoading: true
		})
	}

	getActionButtons(state, number) {
		switch (state) {
			case OrderStatus.NEW:
				return [{
					label: Translations.get('forward_for_payment'),
					onClick: () => {
						this.props.setRepositoryValue('number', number);
						this.props.setPopupFunc(this.popupOnAccept);
						this.props.setPopup('forward_for_payment')
					},
				}, {
					label: Translations.get('cancel_order'),
					onClick: () => { this.cancelOrder(number) }
				}, {
					label: Translations.get('retry_order'),
					onClick: () => { this.retryOrder(number) }
				}];
			case OrderStatus.AWAIT:
				return [{
					label: Translations.get('start_realizing'),
					onClick: () => {
						this.props.setRepositoryValue('number', number);
						this.props.setPopupFunc(this.popupOnAccept);
						this.props.setPopup('start_realizing')
					},
				}, {
					label: Translations.get('forward_again_for_payment'),
					onClick: () => {
						this.props.setRepositoryValue('number', number);
						this.props.setPopupFunc(this.popupOnAccept);
						this.props.setPopup('forward_for_payment')
					},
				}, {
					label: Translations.get('cancel_order'),
					onClick: () => { this.cancelOrder(number) }
				}, {
					label: Translations.get('retry_order'),
					onClick: () => { this.retryOrder(number) }
				}];
			case OrderStatus.SENT_TO_SUPPLIER:
			case OrderStatus.IN_PROGRESS:
				return [{
					label: Translations.get('forward_for_supplier'),
					onClick: () => {
						this.props.setRepositoryValue('number', number);
						this.props.setRepositoryValue('action', OrderStatus.FORWARD);
						this.props.setPopupFunc(this.popupOnAccept);
						this.props.setPopup('forward_to_supplayer');
					},
				}, {
					label: Translations.get('forward_auto_order_for_supplier'),
					onClick: () => {
						this.props.setRepositoryValue('number', number);
						this.props.setRepositoryValue('action', OrderStatus.FORWARD_AUTO);
						this.props.setPopupFunc(this.popupOnAccept);
						this.props.setPopup('forward_to_supplayer');
					},
				}, {
					label: Translations.get('cancel_order'),
					onClick: () => { this.cancelOrder(number) }
				}, {
					label: Translations.get('retry_order'),
					onClick: () => { this.retryOrder(number) }
				}];
			case OrderStatus.FORWARD:
				return [{
					label: Translations.get('order_provided'),
					onClick: () => {
						this.props.setRepositoryValue('number', number);
						this.props.setPopupFunc(this.popupOnAccept);
						this.props.setPopup('order_provided')
					},
				}, {
					label: Translations.get('cancel_order'),
					onClick: () => { this.cancelOrder(number) }
				}, {
					label: Translations.get('retry_order'),
					onClick: () => { this.retryOrder(number) }
				}];
			case OrderStatus.TO_SUPPLY:
				return [];
			case OrderStatus.CANCELED:
				return [{
					label: Translations.get('order_restore'),
					onClick: () => { this.setAsNewOrder(number) }
				}, {
					label: Translations.get('retry_order'),
					onClick: () => { this.retryOrder(number) }
				}];
			default:
				return [{
					label: "__NO_DATA__",
					onClick: () => { console.log("__NO_DATA__"); },
				}];
		}
	}

	getDropdownMenu(status, number, i) {
		if (status === OrderStatus.TO_SUPPLY) {
			return '';
		}
		if (i !== this.state.hoveredElement) {
			return '';
		}
		const label = (
			<Typography className='table-link'>
				{Translations.get('actions')}
				<DropDownIcon />
			</Typography>
		)
		return (
			<Dropdown
				label={label}
				onClick={() => { this.onClickDropdown() }}
				onClose={() => { this.onCloseDropdown() }}
				rows={this.getActionButtons(status, number)}
				style={this.styles.actionButton}
				variant='outlined'
			/>
		);
	}

	handleRowClick = number => (event) => {
		if (event.target.nodeName === 'svg'
			|| event.target.nodeName === 'path') {
			return;
		}
		const classTable = event.target.className.split(' ')
		if (classTable.includes('table-link')
			|| event.target.nodeName === 'BUTTON'
			|| event.target.nodeName === 'SPAN'
			|| event.target.nodeName === 'UL'
			|| event.target.nodeName === 'LI'
			|| event.target.nodeName === 'DIV'
		) {
			return;
		}
		const linkUri = `/panel/evaluation_details/order/${this.hashNumber(number)}`;
		this.props.redirrect(linkUri, this.props.history)
	}

	createRows(data) {
		return data.map((item, i) => {
			const customerLink = `/panel/client_details/${this.hashNumber(item.customerId)}`

			let fullname = item.email;
			if (item.name && item.surname) {
				fullname = `${item.name} ${item.surname} (${fullname})`;
			}


			var valuationUri = `/panel/evaluation_details/evaluation/${this.hashNumber(item.valuationNumber)}`;
			return (
				<TableRow
					key={i}
					onMouseEnter={() => { this.onRowEnter(i) }}
					onMouseLeave={() => { this.onRowLeave(i) }}
					style={this.isStripeRow(item.status) ? this.styles.marked : {}}
					onClick={this.handleRowClick(item.number)}
				>
					<TableCell style={styles.tableColumn1}>

						<Grid container direction='column'>
							<Grid item>
								<Grid container style={styles.tableColumn1}>
									<Grid item>
										<Typography>
											{item.number}
										</Typography>
									</Grid>

									<Grid item>
										{this.getStarImg(item.isMarked)}
									</Grid>
								</Grid>
							</Grid>
							<Grid item>
								<Grid container>
									{this.getLabels(item.labels)}
								</Grid>
							</Grid>
						</Grid>


					</TableCell>

					<TableCell style={styles.tableColumn1}>
						{OrderKeyTranslation.status(item.status)}
					</TableCell>

					<TableCell style={styles.tableColumn2}>
						<Typography
							noWrap
							onClick={() => {
								this.props.redirrect(customerLink, this.props.history)
							}}
							style={styles.link}
							className='table-link'
						>
							{fullname}
						</Typography>
					</TableCell>

					<TableCell style={styles.tableColumn1}>
						<Typography
							onClick={() => {
								this.props.redirrect(valuationUri, this.props.history)
							}}
							style={styles.link}
							className='table-link'
						>
							{item.valuationNumber}
						</Typography>
					</TableCell>

					<TableCell style={styles.tableColumn1}>
						{Translations.get(item.supplierName)}
					</TableCell>

					<TableCell style={styles.tableColumn0}>
						{item.price.toFixed(2)}
					</TableCell>

					<TableCell style={styles.tableColumn0}>
						{item.cost.toFixed(2)}
					</TableCell>

					<TableCell style={styles.tableColumn0}>
						{item.profit.toFixed(2)}
					</TableCell>

					<TableCell style={styles.tableColumn0}>
						{timeFormatDots(item.orderDate)}
					</TableCell>

					<TableCell style={styles.tableColumn0}>
						{timeFormatDots(item.paymentDate)}
					</TableCell>

					<TableCell style={{ ...styles.tableColumn1, ...this.styles.lastCell }}>
						<Typography style={{ ...styles.tableColumn1, ...this.styles.lastCell }} noWrap>
							{item.lastMessage}
						</Typography>
						{this.getDropdownMenu(item.status, item.number, i)}
					</TableCell>
				</TableRow>

			);
		});
	}


	refreshData() {
		Rest('post',
			'/secured/orders',
			{
				orderBy: this.state.orderColumn,
				limit: this.state.pageItemLimit,
				offset: this.state.currentPage * this.state.pageItemLimit,
				desc: this.state.orderDesc,
				filters: this.state.filters
			},
			this.handleResponse,
			this.props.userToken
		);
	}

	setOrderColumn(columnName) {
		var desc = this.state.orderDesc;
		console.log("columnName: ", columnName);
		if (columnName === this.state.orderColumn) {
			desc = !desc;
		} else {
			desc = false;
		}
		this.setState({
			...this.state,
			isLoading: true,
			orderColumn: columnName,
			orderDesc: desc
		})
	}

	getLoading() {
		if (this.state.isLoading) {
			return (
				<div style={this.styles.loadingAnimBG}>
					<CircularProgress size={80} thickness={5} />
				</div>
			);
		}
		return '';
	}

	onNextButtonClick() {
		var nextPage = this.state.currentPage + 1;
		this.setState({
			...this.state,
			isLoading: true,
			currentPage: nextPage,
		});
	}

	onPreviousButtonClick() {
		var nextPage = this.state.currentPage - 1;
		this.setState({
			...this.state,
			isLoading: true,
			currentPage: nextPage,
		});
	}

	setFilter(name, type, payload) {
		var filters = this.state.filters;
		var index = filters.findIndex(
			(obj) => { return (obj.name.equals(name) && obj.type === type); }
		);

		if (index >= 0) {
			var item = filters[index];
			filters.splice(index, 1);
			item.payload = payload;
		} else {
			item = {
				name: name,
				type: type,
				payload: payload,
			}
		}
		filters.push(item);
		this.setState({
			...this.state,
			isLoading: true,
			currentPage: 0,
			filters,
		})
		this.setFilterToRepository(filters)
		console.log("Set Filter", this.state);
	}

	setFilterToRepository = (filters) => {
		this.props.setRepositoryValue('filters', filters)
	}

	setFilterKeyToRepository = (key) => {
		this.props.setRepositoryValue('filtersKey', key)
	}

	deleteFilter(name, type = false) {
		var filters = this.state.filters;
		var index = filters.findIndex(
			(obj) => {
				var isEq = obj.name.equals(name);
				if (type !== false) {
					isEq = (obj.type === type) && isEq;
				}
				return isEq;
			}
		);
		if (index < 0) {
			return;
		}
		filters.splice(index, 1);
		this.setState({
			...this.state,
			isLoading: true,
			filters: filters,
			currentPage: 0,
		})
	}

	getTextFilter(key) {
		this.props.setRepositoryValue('popupOnAccept',
			(text) => { this.setFilter(key, FilterType.TEXT, text); }
		);
		this.props.setRepositoryValue('popupOnClear',
			() => { this.deleteFilter(key); }
		);
		this.setFilterKeyToRepository(key);
		this.props.setPopupFilter(FilterPopup.TEXT);
	}

	getCheckboxFilter(key, options, translations) {
		this.props.setRepositoryValue('popupOnAccept',
			(arr) => { this.setFilter(key, FilterType.CHECKBOX, arr); }
		);
		this.props.setRepositoryValue('popupOnClear',
			() => { this.deleteFilter(key); }
		);
		this.props.setRepositoryValue('checkboxFilterArray', options);
		this.props.setRepositoryValue('checkboxFilterTranslation', translations);
		this.setFilterKeyToRepository(key);
		this.props.setPopupFilter(FilterPopup.CHECKBOX);
	}

	getIntervalFilter(key, type) {
		this.props.setRepositoryValue('popupOnAccept',
			(x) => { this.setFilter(key, type, x); }
		);
		this.props.setRepositoryValue('popupOnClear',
			() => { this.deleteFilter(key); }
		);
		var popup;
		switch (type) {
			case FilterType.NUMBER_INTERVAL: popup = FilterPopup.NUMBER; break;
			case FilterType.DATE_INTERVAL: popup = FilterPopup.DATE; break;
			default: return;
		}
		this.setFilterKeyToRepository(key);
		this.props.setPopupFilter(popup);
	}

	isFilter(filter) {
		const obj = this.state.filters.find((item) => {
			return item.name.equals(filter);
		})
		if (obj) {
			return true;
		}
		return false;
	}

	getHeaders() {
		return (
			<TableRow>
				<ColumnHeader
					style={styles.tableColumn1}
					label={Translations.get('number')}
					sort={() => {
						this.setOrderColumn(
							OrderColumn.NUMBER
						)
					}}
					filter={() => {
						this.getTextFilter([OrderColumn.NUMBER])
					}}
					visible={this.state.orderColumn === OrderColumn.NUMBER}
					visibleFilter={this.isFilter([OrderColumn.NUMBER])}
				/>
				<ColumnHeader
					style={styles.tableColumn1}
					label={Translations.get('status')}
					sort={() => {
						this.setOrderColumn(
							OrderColumn.STATUS
						)
					}}
					filter={() => {
						this.getCheckboxFilter(
							[OrderColumn.STATUS],
							OrderStatus.GET_ALL,
							OrderKeyTranslation.status,
						);
					}}
					visible={this.state.orderColumn === OrderColumn.STATUS}
					visibleFilter={this.isFilter([OrderColumn.STATUS])}
				/>
				<ColumnHeader
					style={styles.tableColumn2}
					label={Translations.get('purchaser')}
					sort={() => {
						this.setOrderColumn(
							OrderColumn.EMAIL
						)
					}}
					visible={this.state.orderColumn === OrderColumn.EMAIL}
					visibleFilter={this.isFilter([
						OrderColumn.EMAIL,
						OrderColumn.NAME,
						OrderColumn.SURNAME
					])}
					filter={() => {
						this.getTextFilter([
							OrderColumn.EMAIL,
							OrderColumn.NAME,
							OrderColumn.SURNAME
						])
					}}

				/>
				<ColumnHeader
					style={styles.tableColumn1}
					label={Translations.get('evaluation')}
					sort={() => {
						this.setOrderColumn(
							OrderColumn.VALUATION_NUMBER
						)
					}}
					filter={() => {
						this.getTextFilter([OrderColumn.VALUATION_NUMBER])
					}}
					visibleFilter={this.isFilter([OrderColumn.VALUATION_NUMBER])}
					visible={this.state.orderColumn === OrderColumn.VALUATION_NUMBER}
				/>
				<ColumnHeader
					style={styles.tableColumn1}
					label={Translations.get('supplier')}
					sort={() => {
						this.setOrderColumn(
							OrderColumn.SUPPLIER_NAME
						)
					}}
					filter={() => {
						this.getCheckboxFilter(
							[OrderColumn.SUPPLIER_NAME],
							SupplierName.GET_ALL,
							SupplierKeyTranslation.name,
						);
					}}
					visibleFilter={this.isFilter([OrderColumn.SUPPLIER_NAME])}
					visible={this.state.orderColumn === OrderColumn.SUPPLIER_NAME}
				/>
				<ColumnHeader
					style={styles.tableColumn0}
					label={Translations.get('price')}
					sort={() => {
						this.setOrderColumn(
							OrderColumn.PRICE
						)
					}}
					filter={() => {
						this.getIntervalFilter(
							[OrderColumn.PRICE],
							FilterType.NUMBER_INTERVAL
						);
					}}
					visibleFilter={this.isFilter([OrderColumn.PRICE])}
					visible={this.state.orderColumn === OrderColumn.PRICE}
				/>
				<ColumnHeader
					style={styles.tableColumn0}
					label={Translations.get('cost')}
					sort={() => {
						this.setOrderColumn(
							OrderColumn.COST
						)
					}}
					filter={() => {
						this.getIntervalFilter(
							[OrderColumn.COST],
							FilterType.NUMBER_INTERVAL
						);
					}}
					visibleFilter={this.isFilter([OrderColumn.COST])}
					visible={this.state.orderColumn === OrderColumn.COST}
				/>
				<ColumnHeader
					style={styles.tableColumn0}
					label={Translations.get('profit')}
					sort={() => {
						this.setOrderColumn(
							OrderColumn.PROFIT
						)
					}}
					visible={this.state.orderColumn === OrderColumn.PROFIT}
				/>
				<ColumnHeader
					style={styles.tableColumn0}
					label={Translations.get('creation_date')}
					sort={() => {
						this.setOrderColumn(
							OrderColumn.CREATE_DATE
						)
					}}
					filter={() => {
						this.getIntervalFilter(
							[OrderColumn.CREATE_DATE],
							FilterType.DATE_INTERVAL
						);
					}}
					visibleFilter={this.isFilter([OrderColumn.CREATE_DATE])}
					visible={this.state.orderColumn === OrderColumn.CREATE_DATE}
				/>
				<ColumnHeader
					style={styles.tableColumn0}
					label={Translations.get('payment_date')}
					sort={() => {
						this.setOrderColumn(
							OrderColumn.PAYMENT_DATE
						)
					}}
					filter={() => {
						this.getIntervalFilter(
							[OrderColumn.PAYMENT_DATE],
							FilterType.DATE_INTERVAL
						);
					}}
					visibleFilter={this.isFilter([OrderColumn.PAYMENT_DATE])}
					visible={this.state.orderColumn === OrderColumn.PAYMENT_DATE}
				/>
				<ColumnHeader
					style={styles.tableColumn1}
					label={Translations.get('last_message')}
					sort={() => {
						this.setOrderColumn(
							OrderColumn.LAST_MESSAGE
						)
					}}
					filter={() => {
						this.getTextFilter([OrderColumn.LAST_MESSAGE])
					}}
					visibleFilter={this.isFilter([OrderColumn.LAST_MESSAGE])}
					visible={this.state.orderColumn === OrderColumn.LAST_MESSAGE}
				/>
			</TableRow>
		);
	}

	getTable() {
		return (
			<Table>
				<TableHead>
					{this.getHeaders()}
				</TableHead>
				<TableBody>
					{this.createRows(this.state.tableData)}
				</TableBody>
			</Table>
		)
	}

	render() {
		if (!this.props.isAdmin) {
			return '';
		}
		if (this.state.isLoading) {
			this.refreshData();
		}

		return (
			<div style={styles.tableContainer}>
				<Paper style={{ ...styles.paperBox, position: 'relative' }}>
					<Grid
						container
						direction='column'
					>
						<Grid item>
							<Bar
								title={Translations.get('orders')}
								page={this.state.currentPage}
								step={this.state.pageItemLimit}
								length={this.state.lenghtData}
								nextAction={this.onNextButtonClick}
								previousAction={this.onPreviousButtonClick}
								setFilter={this.setFilter}
								deleteFilter={this.deleteFilter}
								isOrder={true}
								isSearcher={true}
								isFilter={true}
							/>
						</Grid>
						<Grid item>
							{this.getTable()}
						</Grid>
						<Grid item style={styles.space16Vertical}>
						</Grid>
						<Grid item>
							<Bar
								page={this.state.currentPage}
								step={this.state.pageItemLimit}
								length={this.state.lenghtData}
								nextAction={this.onNextButtonClick}
								previousAction={this.onPreviousButtonClick}
							/>
						</Grid>
					</Grid>
					{this.getLoading()}
				</Paper>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		userId: state.user.id,
		userToken: state.user.token,
		isAdmin: state.user.isAdmin,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (x) => {
			dispatch(setPopup(x));
		},
		setLogin: (x, y, z, q, t, a) => {
			dispatch(setLogin(x, y, z, q, t, a));
		},
		redirrect: (x, y) => {
			dispatch(redirrect(x, y));
		},
		setPopupFilter: (x, y) => {
			dispatch(setPopupFilter(x, y));
		},
		setRepositoryValue: (x, y) => {
			dispatch(setValue(x, y));
		},
		setPopupFunc: (f) => {
			dispatch(setPopupFunc(f));
		}
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme()(container)));
