import React from 'react'
import { connect } from "react-redux";
import { sessionService } from 'redux-react-session';
import sha256 from 'sha256';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom'
import { redirrect } from 'Actions/pageActions'
import { setLogin } from 'Actions/userActions'

import Rest from 'libs/RestRequest'

import Translations from 'Singleton/Translations'
import { Grid, Paper, Typography, Button } from '@material-ui/core';
import styles from '../../../Components/style';
import TextInput from '../../../Components/Commons/TextInput';
import { TextInputType } from 'Components/Commons/Const/InputTypes'

class container extends React.Component {
	constructor(props) {
		super(props);
		this.onValidateMailChange = this.onValidateMailChange.bind(this);
		this.onValidatePassChange = this.onValidatePassChange.bind(this);
		this.state = {
			isEmailValid: false,
			isPassValid: false,
		}
		if (this.props.userId !== undefined) {
			if (this.props.isAdmin) {
				this.props.redirrect('/panel/orders', this.props.history);
			} else {
				this.props.redirrect('/', this.props.history);
			}
		}
	}

	onValidateMailChange(value) {
		this.setState({
			...this.state,
			isEmailValid: value,
		})
	}

	onValidatePassChange(value) {
		this.setState({
			...this.state,
			isPassValid: value,
		})
	}

	loginAdmin() {
		Rest('post', 'auth/loginAdmin', {
			loginType: "Email",
			login: this.props.inputs.email,
			pssw: sha256.x2(this.props.inputs.password),
			remember: true,
		}, this.catchRes.bind(this))
	}

	catchRes(res) {
		console.log(res);
		if (res.body.error) {
			toast.error(`Error ${res.body.errorCode}: ${res.body.errorReason}`, {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {
			const expDate = new Date(new Date().getDate() + 30).getTime();
			var token = { token: res.body.token, expire: expDate };
			var userObj = {
				id: res.body.userId,
				login: res.body.login,
				isAdmin: res.body.isAdmin,
				type: res.body.type,
			}
			sessionService.saveSession(token).then(
				sessionService.saveUser(userObj).then(() => {
					this.props.setLogin(
						res.body.login,
						res.body.token,
						res.body.userId,
						res.body.isAdmin,
						res.body.type,
						undefined,
					);
					
					setTimeout( () => {
						this.props.redirrect("/panel/orders", this.props.history)
					},1)
				})
			);
		}
	}

	inputRow = () => {
		return (
			<Grid
				container
				alignItems='baseline'
			>
				<Grid item style={{flexGrow: 1}}>
					<TextInput
						id="email"
						type={TextInputType.EMAIL}
						label={Translations.get('email')}
						onValidChange={this.onValidateMailChange}
					/>
				</Grid>
				<Grid item style={styles.space30}>
				</Grid>
				<Grid item style={{flexGrow: 1}}>
					<TextInput
						id="password"
						type={TextInputType.PASSWORD}
						label={Translations.get('password')}
						onValidChange={this.onValidatePassChange}
					/>
				</Grid>
				<Grid item style={styles.space30}>
				</Grid>
				<Grid item style={{flexGrow: 1}}>
					<Button
						variant='contained'
						color='primary'
						disabled={!this.state.isEmailValid || !this.state.isPassValid}
						onClick={
							this.loginAdmin.bind(this)
						}
					>
						{Translations.get('sign_in')}
					</Button>
				</Grid>
			</Grid>
		);
	}

	paperBox = () => {
		return (
			<Paper style={{
				...styles.paperBox,
				...styles.column,
				padding: styles.space30.width
			}}>
				<Grid
					container
					direction='column'
				>
					<Typography variant='title'>
						{Translations.get('sign_in')}
					</Typography>
				</Grid>
				<Grid item>
					{this.inputRow()}
				</Grid>
			</Paper>
		)
	}

	render() {
		return (
			<Grid
				container
				direction='column'
				style={styles.container}
				alignItems='center'
			>
				<Grid item style={styles.space30Vertical}>
				</Grid>
				<Grid item>
					{this.paperBox()}
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		page: state.page,
		isAdmin: state.user.isAdmin,
		userId: state.user.id,
		inputs: state.input.inputState,
		lang: state.translations.lang
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setLogin: (x, y, z, q, t, a) => {
			dispatch(setLogin(x, y, z, q, t, a));
		},
		redirrect: (x, y) => {
			dispatch(redirrect(x, y));
		}
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(container));
