import React from 'react'
import { connect } from "react-redux";
import PropTypes from 'prop-types';

import { setPopup, setPopupFunc } from 'Actions/pageActions'
import { setValue } from 'Actions/repositoryActions'

import { Paper, Grid, Button, Typography } from '@material-ui/core';
import styles from '../../../style';

import Translation from 'Singleton/Translations'

class container extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			role: props.role
		}
		this.changeRole = this.changeRole.bind(this);
		this.getRole = this.getRole.bind(this);
	}

	styles = {
		root: {
			width: "30.743vw",
			height: "10.4166vw",
			margin: "2.5vw 0 0 0",
			textAlign: "center",
			paddingTop: "2.2vw"
		},
		text: {
			fontSize: "1.4vw",
		}
	}

	getRole() {
		switch (this.state.role) {
			case 'admin': return Translation.get('admin');
			case 'manager': return Translation.get('manager');
			case 'employee': return Translation.get('employee');
			default: return '__NO_DATA__';
		}
	}


	changeRole(val) {
		this.setState({
			...this.state,
			role: val,
		})
		this.props.setPopupFunc(undefined)
	}

	render() {
		return (
			<Paper style={styles.infoBox}>
				<Grid
					container
					direction='column'
					alignItems='center'
					justify='space-between'
					style={{ height: '100%' }}
				>

					<Grid item>
						<Typography variant='body1' id="coupon">
							{this.getRole()}
						</Typography>
					</Grid>
					<Grid item>
						<Button
							variant='outlined'
							onClick={() => {
								this.props.setAutoFillValue({
									role: this.state.role
								})
								this.props.setPopupFunc(this.changeRole)
								this.props.setPopup('change_role');
							}}
						>
							{Translation.get('change_role')}
						</Button>
					</Grid>
				</Grid>
			</Paper>
		);
	}
}

container.propTypes = {
	role: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => {
	return {
		userToken: state.user.token,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (x) => {
			dispatch(setPopup(x));
		},
		setPopupFunc: (x) => {
			dispatch(setPopupFunc(x));
		},
		setAutoFillValue: x => { dispatch(setValue('autoFill', x)) }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(container);
