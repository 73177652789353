
export default class OrderStatus {
	static get REGISTERED() {return 'registered_user';}
	static get UNREGISTERED() {return 'unregistered_user';}
	static get GET_ALL(){
		return [
			this.REGISTERED,
			this.UNREGISTERED,
		];
	}
}
