import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import TextSelect from 'Components/Commons/TextSelect';
import TextInput from 'Components/Commons/TextInput';
import Checkbox from 'Components/Commons/Checkbox';
import { withTheme } from '@material-ui/core/styles';

import Translations from 'Singleton/Translations';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import MediaType from 'libs/MediaType';
import { Tooltip } from '@material-ui/core';
import FormRowLabel from './components/FormRowLabel';
import { setInputValue } from 'Actions/inputActions';
import queryString from 'query-string';

class ImageBlockFormPaletRow extends React.Component {
	constructor(params) {
		super(params);

		this.idPalets = `pallets_size_${this.props.id}`;

		this.state = {
			height: '',
			weight: '',
			width: '',
			length: '',
			maxWeight: this.props.pallets[0].maxWeight,
			maxHeight: this.props.pallets[0].maxHeight / 10,
			isCustom: params.defaultValues[this.idPalets] === 'custom',
			functions: [],
		};

		const { form } = queryString.parse(window.location.search);
		if (form === 'autofill') {
			this.props.setInputValue(this.idPalets, params.defaultValues[this.idPalets]);
		}
	}

	styles = {
		gridBox1: {
			// textAlign: 'center',
		},
		gridBox2: {
			// textAlign: 'center',
		},
		input1: {
			width: '130px',
		},
		input2: {
			width: '290px',
		},
		clearButton: {
			position: 'absolute',
			top: 23,
			left: -30,
			width: this.props.theme.typography.fontSize * 2,
			height: this.props.theme.typography.fontSize * 2,
		},
		clearIcon: {
			fontSize: this.props.theme.typography.fontSize,
		},
		mobile: {
			input1: {
				width: '110px',
			},
		},
	};

	onChange = (item) => (value) => {
		var newState = this.state;
		newState[item] = value;
		this.setState(newState, () => {
			this.props.onChange(this.props.id, this.isValid());
		});
	};

	handlePaletteChange = (item) => {
		const isCustom = item === 'custom';
		let index = item;
		if (isCustom) {
			index = this.props.pallets.findIndex((it) => {
				return it.isCustom;
			});
		}
		this.setState(
			{
				...this.state,
				maxWeight: this.props.pallets[index].maxWeight,
				maxHeight: this.props.pallets[index].maxHeight / 10,
				isCustom,
			},
			() => {
				this.props.onChange(this.props.id, this.isValid());
			}
		);
	};

	isWeightOverload = () => {
		if (!this.state.weight) {
			return false;
		}
		if (this.state.weight > this.state.maxWeight) {
			return true;
		}
		return false;
	};

	isTooHigh = () => {
		if (!this.state.height) {
			return false;
		}
		if (this.state.height > this.state.maxHeight) {
			return true;
		}
		return false;
	};

	isValid = () => {
		let { height, weight, width, length, isCustom } = this.state;
		if (isCustom) {
			var customValid = !width.isEmpty() && !length.isEmpty();
			customValid = customValid && parseInt(width) > 0;
			customValid = customValid && parseInt(length) > 0;
		} else {
			customValid = true;
		}

		return (
			!height.isEmpty() &&
			!weight.isEmpty() &&
			parseInt(weight) > 0 &&
			parseInt(height) > 0 &&
			!this.isWeightOverload() &&
			!this.isTooHigh() &&
			customValid
		);
	};

	getErrorReason = (type) => {
		switch (type) {
			case 'weight':
				return `${Translations.get('max_weight_is')} ${this.state.maxWeight} kg`;
			case 'height':
				return `${Translations.get('max_height_is')} ${this.state.maxHeight} cm`;
			default:
				return 'error';
		}
	};

	handleClearClick = () => {
		this.setState(
			{
				...this.state,
				isCustom: false,
			},
			() => {
				this.handlePaletteChange(0);
			}
		);
	};

	getPaletteControls = () => {
		const idLength = `palet_length_${this.props.id}`;
		const idWidth = `palet_width_${this.props.id}`;
		let { isCustom } = this.state;
		if (isCustom) {
			return (
				<Grid
					container
					justify="space-between"
					style={
						MediaType.isMobile
							? {
									width: '100%',
									position: 'relative',
							  }
							: {
									...this.styles.input2,
									position: 'relative',
							  }
					}
					alignItems="baseline"
				>
					<Grid item>
						<IconButton style={this.styles.clearButton} onClick={this.handleClearClick}>
							<ClearIcon style={this.styles.clearIcon} />
						</IconButton>
						<TextInput
							required
							label={Translations.get('length_in_cm')}
							type="number"
							id={idLength}
							width={MediaType.isMobile ? this.styles.mobile.input1.width : this.styles.input1.width}
							onChange={this.onChange('length')}
							limit={4}
							tooltip={Translations.get('main_form_weight_tooltip')}
							isNotZero
							value={this.props.defaultValues[idLength]}
						/>
					</Grid>
					<Grid item>
						<TextInput
							required
							label={MediaType.isMobile ? Translations.get('width_in_cm_short') : Translations.get('width_in_cm')}
							type="number"
							id={idWidth}
							width={MediaType.isMobile ? this.styles.mobile.input1.width : this.styles.input1.width}
							onChange={this.onChange('width')}
							limit={4}
							tooltip={Translations.get('main_form_width_tooltip')}
							isNotZero
							value={this.props.defaultValues[idWidth]}
						/>
					</Grid>
				</Grid>
			);
		} else {
			return (
				<TextSelect
					id={this.idPalets}
					items={this.props.palletsSize}
					onChange={this.handlePaletteChange}
					defaultValue={this.props.defaultValues[this.idPalets]}
					style={{ width: '100%' }}
				/>
			);
		}
	};

	getHeightWidthLine = () => {
		return (
			<TextInput
				endAdornment="cm"
				required
				label={
					<span style={{ whiteSpace: 'nowrap' }}>
						{MediaType.isMobile ? Translations.get('height_in_cm_short') : Translations.get('height_in_cm')}
					</span>
				}
				type="number"
				id={this.idHeight}
				width={MediaType.isMobile ? this.styles.mobile.input1.width : '100%'}
				onChange={this.onChange('height')}
				limit={4}
				isNotZero
				isError={this.isTooHigh()}
				errorReason={this.getErrorReason('height')}
				tooltip={Translations.get('main_form_height_tooltip')}
				value={this.props.defaultValues[this.idHeight]}
				style={{ whiteSpace: 'nowrap' }}
			/>
		);
	};

	getWeightLine = () => {
		return (
			<TextInput
				endAdornment="kg"
				required
				label={Translations.get('weight_in_kg')}
				type="number"
				id={this.idWeight}
				width={MediaType.isMobile ? this.styles.mobile.input1.width : '100%'}
				onChange={this.onChange('weight')}
				limit={4}
				isError={this.isWeightOverload()}
				errorReason={this.getErrorReason('weight')}
				isNotZero
				tooltip={Translations.get('main_form_weight_tooltip')}
				value={this.props.defaultValues[this.idWeight]}
			/>
		);
	};

	idFragileCargo = `fragile_cargo_${this.props.id}`;

	getCheckBox = () => {
		return (
			<Checkbox
				id={this.idFragileCargo}
				label={Translations.get('fragile_cargo')}
				onChange={this.onChange('fragile')}
				style={MediaType.isTablet ? { marginLeft: '-8px' } : {}}
				defaultChecked={this.props.defaultValues[this.idFragileCargo]}
			/>
		);
	};

	idHeight = `palet_height_${this.props.id}`;
	idWeight = `palet_weight_${this.props.id}`;

	render() {
		if (MediaType.isMobile) {
			return <FormRowLabel id={this.props.id}>{this.renderMobile()}</FormRowLabel>;
		}
		return <FormRowLabel id={this.props.id}>{this.renderRow()}</FormRowLabel>;
	}

	renderRow = () => {
		return (
			<Grid container spacing={0} alignItems="baseline" justify="space-between">
				<Grid item md={4}>
					{this.getPaletteControls()}
				</Grid>
				<Grid item style={this.styles.gridBox1}>
					<Typography variant="subheading">x</Typography>
				</Grid>
				<Grid item xs={2} style={this.styles.gridBox2}>
					{this.getHeightWidthLine()}
				</Grid>
				<Grid item xs={2} style={{ marginLeft: '8px' }}>
					{this.getWeightLine()}
				</Grid>
				<Grid item>{this.getCheckBox()}</Grid>
			</Grid>
		);
	};

	renderMobile = () => {
		return (
			<Grid container direction="column">
				<Grid item style={{ height: '12px' }}></Grid>
				<Grid item>{this.getPaletteControls()}</Grid>
				<Grid item>
					<Grid container wrap="nowrap" justify="space-between">
						{this.getHeightWidthLine()}
						<Grid item xs={1}></Grid>
						{this.getWeightLine()}
					</Grid>
				</Grid>
				<Grid item>{this.getCheckBox()}</Grid>
			</Grid>
		);
	};
}

ImageBlockFormPaletRow.propTypes = {
	id: PropTypes.number.isRequired,
	palletsSize: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	inputs: state.input.inputState,
	defaultValues: state.input.defaultValues,
	pallets: state.translations.pallets,
});

const mapDispatchToProps = (dispatch) => ({
	setInputValue: (id, value) => {
		dispatch(setInputValue(id, value));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(ImageBlockFormPaletRow));
