import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'
import { sessionService } from 'redux-react-session';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { redirrect, setPopup } from 'Actions/pageActions'
import { setLogin } from '../../Actions/userActions'
import { setValue } from 'Actions/repositoryActions'

import logo from 'Resources/images/TREO-logo.svg'
import logoM from 'Resources/images/TREO-logo-mobile.svg'
import MoreVert from '@material-ui/icons/MoreVert'

import Translations from 'Singleton/Translations'
import { Typography, Grid, ListItem, List, Divider, withStyles } from '@material-ui/core';

import styles from 'Components/style';

import MediaType from 'libs/MediaType'

import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import PropTypes from 'prop-types'


import ListItemComponent from './ListItem'

const classes = theme => ({
	tabLabel: {
		fontSize: theme.typography.body1.fontSize,
		textTransform: 'initial'

	},
	tabLabelContainer: {
		lineHeight: '100%'
	},
	tabRoot: {
		minWidth: 'auto'
	},
	tabButton: {
		flexShrink: 1
	},
	tabsFlexContainer: {
		justifyContent: 'space-evenly'
	}
})

export class HeaderBarFull extends Component {

	constructor(props) {
		super(props);

		this.state = {
			value: this.getCustomPath(),
			anchorMenu: null,
			drawerOpen: false,
		}
	}

	styles = {
		container: {
			position: 'fixed',
			zIndex: '1200',
			width: '100%',
		},
		toolbar: {
			...styles.rootWeb,
			paddingLeft: '45px',
			paddingRight: '45px',
		},
		toolbarTablet: {
			...styles.rootTablet,
			paddingLeft: '45px',
			paddingRight: '45px',
			position: 'relative',
		},
		tabs: {
			width: 'calc(100vw - 250px)',
			maxWidth: '960px',
			paddingLeft: '20px',
			paddingRight: '20px',
		},
		tab: {
			outline: 'none',
			flexShrink: 1,
      height: '64px'
		},
		image: {
			height: '30px',
		},
		imageContainerTablet: {
			position: 'absolute',
			zIndex: '0',
			maxHeight: '40px',
			width: '100%',
			textAlign: 'center',
			left: 0,
		},
		imageTablet: {
			maxHeight: '40px',
		},
		imageMobile: {
			maxHeight: '25px',
		},
		logoButton: {
			...styles.link,
			width: '120px',
		}
	}

	getCustomPath() {
		const { pathName } = this.props;
		if (
			pathName === '/more_info' ||
			pathName.startsWith('/manual_valuation/') ||
			pathName.startsWith('/order/'))
			return '/';
		else if (pathName.startsWith('/order_details/order/') ||
			pathName.startsWith('/order_details/evaluation/'))
			return '/my_orders';
		else if (
			pathName === '/dofinansowanie' ||
			pathName === '/polityka_prywatnosci' ||
			pathName === '/regulamin'
		) {
			return false;
		}
		else
			return pathName;
	}

	getSelected(btn) {
		if (btn.indexOf(this.props.page.page) > -1) {
			return "selected";
		}
		return "";
	}

	loginBtnClick() {
		this.props.setPopup('login');
	}

	logoClick() {
		this.props.redirrect('/', this.props.history)
		this.setState({
			...this.state,
			value: '/'
		})
	}


	panelRedirrect() {
		this.props.redirrect('/panel', this.props.history)
	}

	userSettingsRedirect() {
		this.props.redirrect('/user_settings', this.props.history)
	}

	logout() {
		sessionService.deleteSession().then(
			sessionService.deleteUser().then(() => {
				this.props.setLogin(null, null, null, null, null);
				this.props.redirrect('/', this.props.history);
			})
		);
	}

	openLoginPopup = () => {
		console.log(this.props);

		if (!this.props.userToken) {
			this.props.setRepositoryValue('afterLogin', () => { this.changePath('/my_orders') });
			this.props.setPopup('login');
		} else {
			this.changePath('/my_orders')
		}
	}



	handleChange = (event, value) => {
		if (value === '/my_orders') {
			this.openLoginPopup();
			return;
		}
		this.changePath(value);
	};

	changePath = (value) => {
		let state = this.state;
		state['value'] = value;

		this.setState(state);
		this.props.redirrect(value, this.props.history);
	}

	getTabs() {
		return (
			<Tabs
				indicatorColor="primary"
				textColor="primary"
				value={this.getCustomPath()}
				onChange={this.handleChange}
				style={this.styles.tabs}
				classes={{
					flexContainer: this.props.classes.tabsFlexContainer
				}}

			>
				<Tab
					label={Translations.get('order_pallet_tranport')}
					value="/"
					style={this.styles.tab}
					classes={{
						label: this.props.classes.tabLabel,
						labelContainer: this.props.classes.tabLabelContainer,
						root: this.props.classes.tabRoot
					}}
				/>
				<Tab
					label={Translations.get('my_orders_page')}
					value="/my_orders"
					style={this.styles.tab}
					classes={{
						label: this.props.classes.tabLabel,
						labelContainer: this.props.classes.tabLabelContainer,
						root: this.props.classes.tabRoot
					}}
				/>
				<Tab
					label={Translations.get('Blog')}
					value="/blog"
					style={this.styles.tab}
					classes={{
						label: this.props.classes.tabLabel,
						labelContainer: this.props.classes.tabLabelContainer,
						root: this.props.classes.tabRoot
					}}
				/>
				<Tab
					label={Translations.get('faq')}
					value="/faq"
					style={this.styles.tab}
					classes={{
						label: this.props.classes.tabLabel,
						labelContainer: this.props.classes.tabLabelContainer,
						root: this.props.classes.tabRoot
					}}
				/>
				<Tab
					label={Translations.get('contact')}
					value="/kontakt"
					style={this.styles.tab}
					classes={{
						label: this.props.classes.tabLabel,
						labelContainer: this.props.classes.tabLabelContainer,
						root: this.props.classes.tabRoot
					}}
				/>
			</ Tabs>
		);
	}

	getButtonText = (text) => {
		var nText = text;
		const len = 13;
		if (text.length > len) {
			nText = text.slice(0, len - 3);
			nText += '...';
		}
		return nText;
	}

	getButton() {
		if (this.props.user) {
			if (!MediaType.isDesktop) {
				return (
					<Button
						onClick={() => this.handleChange(undefined, '/my_orders')}
						variant='outlined'
					>
						{
							MediaType.isMobile ?
								Translations.get('orders') :
								Translations.get('my_orders')
						}
					</Button>
				)
			}
			// if (MediaType.isMobile) {
			// 	return (
			// 		<IconButton
			// 			onClick={this.handleClickMenu}
			// 			aria-owns={this.state.anchorMenu ? 'user-menu' : null}
			// 			aria-haspopup="true"
			// 		>
			// 			<MoreVert />
			// 		</IconButton>
			// 	)
			// }
			return (
				<Button
					color="inherit"
					onClick={this.handleClickMenu}
					aria-owns={this.state.anchorMenu ? 'user-menu' : null}
					aria-haspopup="true"
				>
					<Typography>
						{this.getButtonText(this.props.user)}
					</Typography>
					<ArrowDropDownIcon />
				</Button>
			);
		} else {
			return (
				<Button
					// color="inherit"
					onClick={() => this.loginBtnClick()}
					variant='outlined'
				>
					{Translations.get('login')}
				</Button>
			)
		}
	}

	handleClose = action => () => {
		this.setState({
			...this.state,
			anchorMenu: null,
		});

		switch (action) {
			case 'logout': this.logout(); break;
			case 'settings': this.userSettingsRedirect(); break;
			case 'panel': this.panelRedirrect(); break;
			default: break;
		}
	}

	handleClickMenu = event => {
		this.setState({
			...this.state,
			anchorMenu: event.currentTarget,
		});
	}

	getMenu() {
		if (this.props.user) {
			return (
				<ClickAwayListener onClickAway={this.handleClose()}>
					<Menu
						id="user-menu"
						anchorEl={this.state.anchorMenu}
						open={Boolean(this.state.anchorMenu)}
						onClose={this.handleClose()}
					>
						{
							this.props.isAdmin
								?
								<MenuItem onClick={this.handleClose('panel')}>{Translations.get('panel')}</MenuItem>
								:
								<MenuItem onClick={this.handleClose('settings')}>{Translations.get('settings')}</MenuItem>
						}
						<MenuItem onClick={this.handleClose('logout')}>{Translations.get('logout')}</MenuItem>

					</Menu>
				</ClickAwayListener>
			);
		}
		return '';
	}

	getLogo = () => {
		return (
			<a
				style={this.styles.logoButton}
				onClick={this.logoClick.bind(this)}
			>
				<img src={logo} alt={'Treo'} style={this.styles.image} />
			</a>
		)
	}

	render() {
		if (MediaType.isTablet) {
			return this.tabletRender();
		}
		if (MediaType.isMobile) {
			return this.mobileRender();
		}
		return (
			<div style={this.styles.container}>
				<AppBar
					position='static'
					color='inherit'
					style={{
						borderBottom: 'rgba(0, 0, 0, 0.2) solid 1px',
						boxShadow: 'none',
					}}
				>
					<Toolbar
						style={this.styles.toolbar}
					>
						<Grid
							container
							alignItems='center'
							justify='space-between'
							wrap="nowrap"
						>
							<Grid item>
								{this.getLogo()}
							</Grid>
							<Grid item>
								{this.getTabs()}
							</Grid>
							<Grid item>
								{this.getButton()}
								{this.getMenu()}
							</Grid>
						</Grid>

					</Toolbar>
				</AppBar>
			</div>
		);
	}

	openDrawer = () => {
		this.setState({
			...this.state,
			drawerOpen: true,
		})
	}

	handleDrawerClose = () => {
		this.setState({
			...this.state,
			drawerOpen: false,
		})
	}

	handleAppMenuClick = (path) => (event) => {
		console.log({ path })
		this.handleChange(event, path);
		this.handleDrawerClose();
	}

	handleAppMenuLogoutClick = () => {
		this.logout()
		this.handleDrawerClose();
	}

	drawerMenu = () => {

		const header =
			this.props.user ?
				MediaType.isMobile ?
					this.props.user :
					`${Translations.get('menu')}:`
				: `${Translations.get('menu')}:`

		return (
			<ClickAwayListener onClickAway={this.handleDrawerClose}>
				<Drawer
					open={this.state.drawerOpen}
					// open={this.state.drawerOpen}
					variant='persistent'
					style={{
						position: 'relative',

					}}
				>
					<List>
						<ListItem>
							<Typography
								variant='headline'
								noWrap
								style={{
									width: '220px'
								}}
							>
								{header}
							</Typography>
						</ListItem>
						<Divider />
						<ListItemComponent
							onClick={this.handleAppMenuClick('/')}
							translation='order_pallet_tranport'
							isMarked={this.props.location.pathname === '/'}
						/>
						<ListItemComponent
							onClick={this.handleAppMenuClick('/my_orders')}
							translation='my_orders_page'
							isMarked={this.props.location.pathname === '/my_orders'}
						/>
						<Divider />
						<ListItemComponent
							onClick={this.handleAppMenuClick('/blog')}
							translation='Blog'
							isMarked={this.props.location.pathname === '/blog'}
						/>
						<ListItemComponent
							onClick={this.handleAppMenuClick('/faq')}
							translation='faq'
							isMarked={this.props.location.pathname === '/faq'}
						/>
						<ListItemComponent
							onClick={this.handleAppMenuClick('/kontakt')}
							translation='contact'
							isMarked={this.props.location.pathname === '/kontakt'}
						/>
						{this.getLoginDrawerButtons()}
					</List>
				</Drawer>
			</ClickAwayListener>
		)
	}

	getLoginDrawerButtons = () => {
		if (!this.props.user) {
			return ''
		}

		return (
			<div>
				<Divider />
				<ListItemComponent
					onClick={this.handleAppMenuClick('/user_settings')}
					translation='settings'
					isMarked={this.props.location.pathname === '/user_settings'}
				/>
				<ListItemComponent
					onClick={this.handleAppMenuLogoutClick}
					translation='logout'
					isMarked={false}
				/>
			</div>
		)
	}

	tabletRender() {
		return (
			<div>
				<AppBar
					position='fixed'
					color='inherit'
					style={{
						borderBottom: 'rgba(0, 0, 0, 0.2) solid 1px',
						boxShadow: 'none',
					}}
				>
					<Toolbar>
						<div style={this.styles.imageContainerTablet}>
							<a onClick={this.logoClick.bind(this)}><img src={logo} alt={'Treo'} style={this.styles.imageTablet} /></a>
						</div>
						<Button variant='outlined' onClick={this.openDrawer} aria-label="Menu">
							{Translations.get('menu')}
						</Button>

						<div style={{ flexGrow: 1 }} />
						{this.getButton()}
						{this.getMenu()}
					</Toolbar>
				</AppBar>
				{this.drawerMenu()}
			</div>
		);
	}

	mobileRender() {
		return (
			<div>
				<AppBar
					position='fixed'
					color='inherit'
					style={{
						borderBottom: 'rgba(0, 0, 0, 0.2) solid 1px',
						boxShadow: 'none',
					}}
				>
					<Toolbar>

						<Button variant='outlined' onClick={this.openDrawer} aria-label="Menu" style={{ zIndex: 9999 }}>
							{Translations.get('menu')}
						</Button>
						<div style={this.styles.imageContainerTablet}>
							<a onClick={this.logoClick.bind(this)}><img src={logoM} alt={'Treo'} style={this.styles.imageMobile} /></a>
						</div>
						<div style={{ flexGrow: 1 }} />
						{this.getButton()}
						{this.getMenu()}
					</Toolbar>
				</AppBar>
				{this.drawerMenu()}
			</div>
		)
	}
}

HeaderBarFull.propTypes = {
	pathname: PropTypes.string,
}

const mapStateToProps = (state) => {
	return {
		page: state.page,
		user: state.user.login,
		userToken: state.user.token,
		isAdmin: state.user.isAdmin
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (popup) => {
			dispatch(setPopup(popup));
		},
		setLogin: (x, y, z, q, t, a) => {
			dispatch(setLogin(x, y, z, q, t, a));
		},
		redirrect: (x, y) => {
			dispatch(redirrect(x, y));
		},
		setRepositoryValue: (x, y) => {
			dispatch(setValue(x, y));
		},
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(classes)(HeaderBarFull)));
