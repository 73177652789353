import React from 'react'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import styles from './../../style'
import Translations from 'Singleton/Translations'
import MediaType from '../../../libs/MediaType';
import Button from '@material-ui/core/Button';

const componentStyles = {
		container: {
			...styles.container,
			...styles.rootWeb,
			marginTop: '30px',
			marginBottom: '30px'
		},
		containerTablet: {
			...styles.container,
			...styles.rootTablet,
			backgroundColor: '#fff',
			marginTop: '30px',
			marginBottom: '30px'
		},
		title: {
			marginBottom: "3rem",
		},
		title2: {
			marginBottom: '20px',
			fontSize: '1.3rem'

		},
		paragraph: {
			marginBottom: '40px',
			fontSize: '1rem',
		}
	}




export class SwipeBlock extends React.Component {

	

	desktopContent() {
		return (
			''
		)
	}

	tabletContent = () => {
		return ''
	}

	getContent = () => {
		switch (MediaType.type) {
			case MediaType.TABLET: return this.tabletContent();
			default: return this.desktopContent();
		}
	}

	getRootStyle = () => {
		switch (MediaType.type) {
			case MediaType.TABLET: return componentStyles.containerTablet;
			default: return componentStyles.container; 
		}
	}

	render() {
	
		return (
			
				<Grid container spacing={0} justify="center" style={{paddingTop: "4rem", paddingBottom: "3rem"}}>
					<Grid item lg={6}>
						<Typography component="h2" variant='display2' align='center' style={componentStyles.title}>
							{this.props.title}
						</Typography>
					</Grid>
					

						
					<Grid container 
							  direction="row"
							  justify="center"
							  alignItems="center">
						<Grid item align='center' xs={10} >

								<img src={this.props.icon} style={{ width: "90px", paddingRight: "20px", float: "left"  }} alt={this.props.iconAlt} />								
						
								<Typography component="h3" variant='display1' align='left' style={componentStyles.title2}>
									{this.props.itemTitle}
								</Typography>
								<Typography component="span" variant='display1' align='left' style={componentStyles.paragraph}>
									{this.props.itemText}
								</Typography>
								
						</Grid>
					</Grid>
				</Grid>
		
		);
	}
}

export default SwipeBlock;