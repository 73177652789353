import React from 'react'
import PropTypes from 'prop-types';

import TableCell from '@material-ui/core/TableCell';
import { Grid } from '@material-ui/core';

import ArrowIcon from '@material-ui/icons/ArrowDownward';
import FilterList from '@material-ui/icons/FilterList';
import styles from '../../style';

class container extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isHovered: false,
			isDesc: false,
		}
	}

	onColumnEnter() {
		this.setState({
			...this.state,
			isHovered: true,
		})
	}

	onColumnLeave() {
		if (this.state.clickedButton) {
			return;
		}
		this.setState({
			...this.state,
			isHovered: false,
		})
	}

	render() {
		return (
			<TableCell
				onMouseEnter={this.onColumnEnter.bind(this)}
				onMouseLeave={this.onColumnLeave.bind(this)}
				style={this.props.style}
			>
				<Grid
					container
					wrap='nowrap'
				>
					<Grid item>
						{this.props.label}
					</Grid>
					<Grid item>
						{this.props.sort 
						&& ( this.state.isHovered || this.props.visible )
						&&
							<ArrowIcon 
								style={{
									...styles.tableIcon,
									transform: this.state.isDesc ? 'rotate(180deg)' : '',
								}}
								onClick={() => {
									this.setState({ 
										...this.state,
										isDesc: !this.state.isDesc 
									});
									this.props.sort()
								}}
							/>
						}
					</Grid>
					<Grid item>
						{this.props.filter 
						&& ( this.state.isHovered || this.props.visibleFilter )
						&&
							<FilterList 
								style={styles.tableIcon} 
								onClick={this.props.filter}
							/>
						}
					</Grid>
				</Grid>
			</TableCell>
		);
	}
}

container.propTypes = {
	className: PropTypes.string,
	label: PropTypes.string,
	sort: PropTypes.func,
	filter: PropTypes.func,
	style: PropTypes.object,
	visible: PropTypes.bool,
	visibleFilter: PropTypes.bool,
}

export default container;
