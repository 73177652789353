import React, { Component } from 'react'
import { connect } from "react-redux";
import PropTypes from 'prop-types'
import Translations from 'Singleton/Translations'
import { withRouter } from 'react-router-dom'

import { Paper, Grid, Typography, Button, TableRow } from '@material-ui/core'
import styles from "../../Components/style";
import MediaType from "../../libs/MediaType";
import { setPopup, setPopupFunc, redirrect } from 'Actions/pageActions'

import { MetaData, handleMetaData } from '../../Components/Commons/Const/MetaData'

class RegisterSuccess extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isSuccess: this.props.match.params.isSuccess === 'true'
		}
	}

	componentWillMount() {
		document.title = MetaData.mainPage.title;
		handleMetaData();
	}

	getTitle = (title) => {
		return (
			<Typography variant='headline' paragraph>
				{title}
			</Typography>
		)
	}

	getMessage = (msg) => {
		return (
			<Typography variant='body1' paragraph>
				{msg}
			</Typography>
		)
	}

	loginClick = () => {
		this.props.setPopupFunc(this.loginEnded);
		this.props.setPopup('login');
	}

	loginEnded = () => {
		this.props.redirrect('/', this.props.history);
	}

	getTitleMsg = () => {
		if (this.state.isSuccess) {
			return Translations.get('registration_success')
		}
		return Translations.get('registration_failed')
	}

	getMsg = () => {
		if (this.state.isSuccess) {
			return Translations.get('registration_success_msg')
		}
		return Translations.get('registration_failed_msg')
	}

	content = () => {
		return (
			<Paper style={
				MediaType.isDesktop ?
					styles.moreInfoBox :
					MediaType.isMobile ?
						styles.moreInfoBoxMobile :
						styles.moreInfoBoxTablet
			}>
				<Grid
					container
					direction='column'
				>
					<Grid item>
						{this.getTitle(this.getTitleMsg())}
					</Grid>
					<Grid item>
						{this.getMessage(this.getMsg())}
					</Grid>
					{this.state.isSuccess &&
						<Grid item>
							{this.getMessage(Translations.get('registration_success_msg_2'))}
						</Grid>
					}
					{this.state.isSuccess &&
						<Grid item>
							<Button
								fullWidth={MediaType.isMobile}
								variant='contained'
								color='primary'
								onClick={this.loginClick}
							>
								{Translations.get('sign_in')}
							</Button>
						</Grid>
					}
				</Grid>
			</Paper>
		)
	}

	render() {
		return (
			<Grid
				container
				direction='column'
				style={
					MediaType.isMobile ?
						styles.mobileContainer :
						styles.container
				}
				alignItems='center'
			>
				<Grid item style={styles.space30Vertical}>
				</Grid>
				<Grid item>
					{this.content()}
				</Grid>
			</Grid>
		)
	}
}

RegisterSuccess.propTypes = {

}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (popup) => {
			dispatch(setPopup(popup));
		},
		redirrect: (x, y) => {
			dispatch(redirrect(x, y));
		},
		setPopupFunc: (x) => {
			dispatch(setPopupFunc(x));
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterSuccess);