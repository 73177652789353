import React from 'react'
import PropTypes from 'prop-types'
import { ListItem, ListItemText, Typography } from '@material-ui/core';
import Translations from 'Singleton/Translations'

const listItem = (props) => {
	return (
		<ListItem button onClick={props.onClick}>
			<Typography 
				variant='body1' 
				color={props.isMarked ? 'primary' : 'default'}
			>
				{Translations.get(props.translation)}
			</Typography>
		</ListItem>
	);
}

listItem.propTypes = {
	translation: PropTypes.string,
	onClick: PropTypes.func,
	isMarked: PropTypes.bool,
}

export default listItem;