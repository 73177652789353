import React from 'react'
import { connect } from "react-redux";
import { toast } from 'react-toastify';

import RestRequest from 'libs/RestRequest'
import DatePicker from 'Components/Commons/DatePicker';
import TimePicker from 'Components/Commons/TimePicker';

import { setPopup } from 'Actions/pageActions'

import Translations from 'Singleton/Translations'
import { Grid, Typography, Button } from '@material-ui/core';
import TextInput from '../../Components/Commons/TextInput';
import { TextInputType } from 'Components/Commons/Const/InputTypes'
import styles from '../../Components/style'


class container extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isDaysValid: true,
			isDateValid: false,
			isTimeFromValid: false,
			isTimeToValid: false,
		}
		this.handleResponse = this.handleResponse.bind(this);
		this.showError = this.showError.bind(this);
		this.onDaysValidChange = this.onDaysValidChange.bind(this);
		this.onTimeFromChange = this.onTimeFromChange.bind(this);
		this.onTimeToChange = this.onTimeToChange.bind(this);
	}

	cancelClick() {
		this.props.setPopup(undefined);
	}

	confirmClick() {
		RestRequest(
			'post',
			'secured/order/status/await',
			{
				number: this.props.number,
				dueWithinDays: this.props.inputs.ForwardForPaymentDays,
				pickupStart: this.props.inputs.ForwardForPaymentDate
					+ this.props.inputs.ForwardForPaymentTimeFrom,
				pickupEnd: this.props.inputs.ForwardForPaymentDate
					+ this.props.inputs.ForwardForPaymentTimeTo,
				remarks: this.props.inputs.remarks,
			},
			this.handleResponse,
			this.props.userToken,
		)

		this.props.setPopup(undefined);
	}

	showError(msg) {
		toast.error(msg, {
			position: toast.POSITION.TOP_RIGHT,
		});
	}


	handleResponse(res) {
		console.log(res);
		if (res.body.error) {
			this.showError(res.body.errorReason)
		} else {
			this.props.acceptFunc();
			this.props.setPopup();
		}
	}

	onTimeFromChange(isValid) {
		this.setState({
			...this.state,
			isTimeFromValid: isValid,
		})
	}

	onTimeToChange(isValid) {
		this.setState({
			...this.state,
			isTimeToValid: isValid,
		})
	}

	onDateValidChange = (isValid) => {
		this.setState({
			...this.state,
			isDateValid: isValid,
		})
	}

	styles = {
		row: {
			display: "flex",
			alignItems: "baseline",
		},
		input: {
			width: "10vw",
			marginRight: "1vw",
			marginTop: -15,
		},
		bigInput: {
			marginTop: -15,
			width: "100%"
		},
		textLine: {
			margin: "1vw 0"
		},
	}

	onDaysValidChange(value) {
		console.log('days valid change ', value);
		this.setState({
			...this.state,
			isDaysValid: value,
		})
	}

	payDayRow() {
		return (
			<Grid
				container
				alignItems='baseline'
			>
				<Grid item>
					<TextInput
						id='ForwardForPaymentDays'
						label={Translations.get('time_for_payment')}
						type={TextInputType.NUMBER}
						onValidChange={this.onDaysValidChange}
						value={`${3}`}
						limit={3}
					/>
				</Grid>
				<Grid item style={styles.space30}>
				</Grid>
				<Grid item>
					<Typography variant='body1'>
						{Translations.get('days')}.
					</Typography>
				</Grid>
			</Grid>
		)
	}

	dateRow() {
		let minDate = new Date()
		minDate = new Date(minDate.setHours(minDate.getHours() + 24 + 24))

		return (
			<Grid
				container
				alignItems='baseline'
				justify='space-between'
			>
				<Grid item>
					<DatePicker
						id='ForwardForPaymentDate'
						label={Translations.get('date')}
						minDate={minDate.getTime()}
						onValidChange={this.onDateValidChange}
						withoutWeekends
					/>
				</Grid>
				<Grid item>
					<TimePicker
						id='ForwardForPaymentTimeFrom'
						onValidChange={this.onTimeFromChange}
						label={Translations.get('date_from')}
					/>
				</Grid>
				<Grid item>
					<Typography variant='body1'>
						-
					</Typography>
				</Grid>
				<Grid item>
					<TimePicker
						id='ForwardForPaymentTimeTo'
						onValidChange={this.onTimeToChange}
						label={Translations.get('date_to')}
					/>
				</Grid>
			</Grid>
		)
	}

	render() {
		return (
			<Grid
				container
				direction='column'
			>
				<Grid item>
					{this.payDayRow()}
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Typography variant='subheading'>
						{Translations.get('planned_date_of_loading')}
					</Typography>
				</Grid>
				<Grid item>
					{this.dateRow()}
				</Grid>

				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Typography variant='subheading'>
						{Translations.get('additional_remarks_for_client')}
					</Typography>
				</Grid>
				<Grid item>
					<TextInput
						id='remarks'
						label={Translations.get('remarks')}
						limit={245}
					/>
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Button
						variant='contained'
						color='primary'
						fullWidth
						onClick={this.confirmClick.bind(this)}
						disabled={!(this.state.isDateValid &&
							this.state.isTimeToValid &&
							this.state.isTimeFromValid &&
							this.state.isDaysValid)
						}
					>
						{Translations.get('forward_for_payment')}
					</Button>
				</Grid>

				<Grid item style={styles.space16Vertical}>
				</Grid>

				<Grid item>
					<Button
						variant='outlined'
						fullWidth
						onClick={this.cancelClick.bind(this)}
					>
						{Translations.get('cancel')}
					</Button>
				</Grid>
			</Grid>
		);
	}
}


const mapStateToProps = (state) => {
	return {
		userToken: state.user.token,
		inputs: state.input.inputState,
		number: state.repository.number,
		acceptFunc: state.page.popupFunc,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (x) => {
			dispatch(setPopup(x));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(container);
