const reducer = (state ={
	login: undefined,
	token: undefined,
	id: undefined,
	isAdmin: false,
	type: undefined,
	accessToken: undefined,
	isVatZero: false,
}, action) => {
	switch (action.type) {
		case "USER_SET_LOGIN":
		const isVatZero = action.login ? state.isVatZero : false;
		state = {
			...state,
			login: action.login,
			token: action.token,
			id: action.id,
			isAdmin: action.isAdmin,
			type: action.loginType,
			accessToken: action.accessToken,
			isVatZero,
		};
		break;
		case "USER_SET_VAT_ZERO":
		state = {
			...state,
			isVatZero: action.payload,
		}
		default:
		break;
	}
	return state;
};

export default reducer;
