
export default class OrderColumn {
	static get NUMBER() {return 'number';}
	static get STATUS() {return 'status';}
	static get NAME() {return 'name';}
	static get SURNAME() {return 'surname';}
	static get EMAIL() {return 'email';}
	static get VALUATION_NUMBER() {return 'vnumber';}
	static get SUPPLIER_NAME() {return 'sname';}
	static get PRICE() {return 'price';}
	static get COST() {return 'cost';}
	static get PROFIT() {return 'profit';}
	static get CREATE_DATE() {return 'date';}
	static get PAYMENT_DATE() {return 'pdate';}
	static get LAST_MESSAGE() {return 'message';}
}
