import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { TextField } from '@material-ui/core';
import { setInputValue } from 'Actions/inputActions'
import Translations from 'Singleton/Translations'

const oneHour = 3600000;
const oneMinute = 60000;

const dateFormat = (ms) => {
	if (!ms) {
		return null;
	}
	const d = new Date(ms);
	var mm = d.getMonth() + 1;
	if (mm < 10) {
		mm = `0${mm}`;
	}
	let dd = d.getDate();
	if (dd < 10) {
		dd = `0${dd}`;
	}

	return `${d.getFullYear()}-${mm}-${dd}`
}

export class component extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isValid: false,
			isFirstChange: true,
		}
		this.props.setInputValue(this.props.id, this.props.defaultDate);
	}

	onChange = (event) => {
		const d = new Date(event.target.value).getTime();

		if (this.state.isFirstChange) {
			this.setState({
				...this.state,
				isFirstChange: false,
			}, () => { this.validChange(d) });
		} else {
			var valid = this.validChange(d);
		}
		if(this.props.blockChangeOnError){
			if(!valid){
				return;
			}
		}

		this.props.setInputValue(this.props.id, d);

		if (this.props.onChange) {
			this.props.onChange(d);
		}
	}

	flatDate = (date) => {
		const d = new Date(date);
		const time = d.getHours() * oneHour + d.getMinutes() * oneMinute;
		return date - time;
	}


	validChange = (value) => {
		var isValid = false;
		if (this.props.minDate && this.props.maxDate) {
			if (value >= this.flatDate(this.props.minDate) &&
				value <= this.flatDate(this.props.maxDate)) {

				isValid = true;
			}
		} else if (this.props.minDate) {
			if (value >= this.flatDate(this.props.minDate)) {
					
				isValid = true;
			}
		} else if (this.props.maxDate) {
			if (value >= this.flatDate(this.props.maxDate)) {
					
				isValid = true;
			}
		} else {
			isValid = true;
		}

		if(this.props.withoutWeekends){
			const date = new Date(value);
			const day = date.getDay();
			if (day === 6 || day === 0){
				isValid = false
			}
			
		}

		if (this.state.isValid === isValid) {
			return;
		}

		this.setState({
			...this.state,
			isValid,
		})
		if (this.props.onValidChange) {
			this.props.onValidChange(isValid);
		}
		return isValid;
	}

	isError = () => {
		if (this.props.doNotValidate) {
			return false;
		}
		if (this.state.isValid || this.state.isFirstChange) {
			return false;
		}
		return true;
	}

	render() {
		const errorText = this.props.errorText ? this.props.errorText : Translations.get('invalid_date');

		return (
			<TextField
				disabled={this.props.disabled}
				id={this.props.id}
				label={this.props.label}
				type="date"
				defaultValue={dateFormat(this.props.defaultDate)}
				InputLabelProps={{
					shrink: true,
				}}
				inputProps={{
					min: dateFormat(this.props.minDate),
					max: dateFormat(this.props.maxDate),
				}}
				margin="dense"
				onChange={this.onChange}
				style={{
					width: this.props.width
				}}
				error={this.isError()}
				helperText={this.isError() ? errorText : undefined}
			/>
		)
	}
}

component.propTypes = {
	disabled: PropTypes.bool,
	id: PropTypes.string.isRequired,
	label: PropTypes.string,
	width: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	onChange: PropTypes.func,
	defaultDate: PropTypes.number,
	minDate: PropTypes.number,
	maxDate: PropTypes.number,
	onValidChange: PropTypes.func,
	errorText: PropTypes.string,
	doNotValidate: PropTypes.bool,
	blockChangeOnError: PropTypes.bool,
	withoutWeekends: PropTypes.bool,
}

component.defaultProps = {
	disabled: false,
	withoutWeekends: false,
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = (dispath) => {
	return {
		setInputValue: (id, value) => { dispath(setInputValue(id, value)) }
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(component)
