import React from 'react';
import { connect } from "react-redux";

import LoginBox from './Popups/Login'
import FiltrsBox from './Popups/Filtr'
import RegisterFacebookBox from './Popups/RegisterFacebook'
import RegisterGoogleBox from './Popups/RegisterGoogle'
import RegisterEmailBox from './Popups/RegisterEmail'
import LoginEmailBox from './Popups/LoginEmail'
import UpdatePrices from './Popups/UpdatePrices'
import ForgotPassword from './Popups/ForgotPass'
import Footer from './../Components/Body/Popup/Footer'
import ContinueWithoutAccount from './Popups/ContinueWithoutAccount'
import StatementBox from './Popups/Statement'
import SelectSupplierBox from './Popups/SelectSupplier'
import ForwardForPaymentBox from './Popups/ForwardForPayment'
import SendForAcceptanceBox from './Popups/SendForAcceptance'
import AddCouponBox from './Popups/AddCoupon'
import AddEmployeeBox from './Popups/AddEmployee'
import ChangeEmailBox from './Popups/ChangeEmail'
import ChangePasswBox from './Popups/ChangePassword'
import ChangePasswAdminBox from './Popups/ChangePasswordAdmin'
import ChangeRoleBox from './Popups/ChangeRole'
import DeleteAccountBox from './Popups/DeleteAccount'
import ChangeEmployeeNameBox from './Popups/ChangeEmployeeName'
import InvoiceVatBox from './Popups/InvoiceVat0'
import ChangeCouponValueBox from './Popups/ChangeCouponValue'
import ShowVatInfoBox from './Popups/ShowVatInfo'
import LabelBox from './Popups/Label'
import StartRealizingBox from './Popups/StartRealizing'
import ForwardToSupplierBox from './Popups/ForwardedToSupplier'
import OrderProvidedBox from './Popups/Provided'
import RejectValuationBox from './Popups/RejectValuation'
import ClaimBox from './Popups/Claim'
import { setPopup } from './../Actions/pageActions'
import Translations from 'Singleton/Translations'
import { Paper, Grid, Typography, IconButton } from "@material-ui/core";
import { setValue } from 'Actions/repositoryActions'

import styles from 'Components/style'

import ClearIcon from '@material-ui/icons/Clear'
import MediaType from '../libs/MediaType';

class Popup extends React.Component {
	constructor(props) {
		super(props);
		this.chosePopup = this.chosePopup.bind(this);
		window.scrollTo(0, 0)
		document.addEventListener('keydown', this.handleKeyPress);
	}
	
	handleKeyPress = (event) => {
		if(event.code === 'Escape'){
			this.closePopup()
		}
	}

	componentWillUnmount(){
		document.removeEventListener('keydown', this.handleKeyPress, true);
	}



	styles = {
		exitButton: {
			position: 'absolute',
			top: 0,
			right: 0,
		}
	}

	chosePopup() {
		var c = '';
		switch (this.props.popup) {
			case 'login':
				c = <LoginBox />
				return {
					title: Translations.get('sign_in'),
					content: c
				};
			case 'login_email':
				c = <LoginEmailBox />
				return {
					title: Translations.get('sign_in'),
					content: c
				};
			case 'login_continue':
				c = <LoginBox isContinue={true} />;
				return {
					title: Translations.get('sign_in'),
					content: c
				};
			case 'continue_without_account':
				c = <ContinueWithoutAccount />;
				return {
					title: Translations.get('continue_without_acc'),
					content: c
				};
			case 'register':
				c = <LoginBox />
				return {
					title: Translations.get('register'),
					content: c
				};
			case 'register_facebook':
				c = <RegisterFacebookBox />
				return {
					title: Translations.get('register'),
					content: c
				};
			case 'register_google':
				c = <RegisterGoogleBox />
				return {
					title: Translations.get('register'),
					content: c
				};
			case 'register_email':
				c = <RegisterEmailBox />
				return {
					title: Translations.get('register'),
					content: c
				};
			case 'forgot_password':
				c = <ForgotPassword />
				return {
					title: Translations.get('reset_password'),
					content: c
				};
			case 'filtrs':
				c = <FiltrsBox />
				return {
					title: Translations.get('filter'),
					content: c
				};
			case 'update_prices':
				c = <UpdatePrices />
				return {
					title: Translations.get('update_price'),
					content: c,
					hideFooter: true
				}
			case 'statement':
				c = <StatementBox />
				return {
					title: Translations.get('statement'),
					content: c,
					hideFooter: true
				}
			case 'select_supplier':
				c = <SelectSupplierBox />
				return {
					title: Translations.get('select_supplier'),
					content: c,
					hideFooter: true
				}
			case 'forward_for_payment':
				c = <ForwardForPaymentBox />
				return {
					title: Translations.get('forward_for_payment'),
					content: c,
					hideFooter: true
				}
			case 'send_for_acceptance':
				c = <SendForAcceptanceBox />
				return {
					title: Translations.get('send_for_acceptance'),
					content: c,
					hideFooter: true
				}
			case 'add_coupon':
				c = <AddCouponBox />
				return {
					title: Translations.get('add_coupon'),
					content: c,
					hideFooter: true
				}
			case 'change_email':
				c = <ChangeEmailBox />
				return {
					title: Translations.get('change_email'),
					content: c,
					hideFooter: true
				}
			case 'change_pass':
				c = <ChangePasswBox />
				return {
					title: Translations.get('change_password'),
					content: c,
					hideFooter: true
				}
			case 'change_pass_admin':
				c = <ChangePasswAdminBox />
				return {
					title: Translations.get('change_password'),
					content: c,
					hideFooter: true
				}
			case 'change_role':
				c = <ChangeRoleBox />
				return {
					title: Translations.get('change_role'),
					content: c,
					hideFooter: true
				}
			case 'change_employee_name':
				c = <ChangeEmployeeNameBox />
				return {
					title: Translations.get('change_full_name'),
					content: c,
					hideFooter: true
				}
			case 'invoice_vat_0':
				c = <InvoiceVatBox />
				return {
					title: Translations.get('request'),
					content: c,
					hideFooter: true
				}
			case 'delete_account':
				c = <DeleteAccountBox />
				return {
					title: Translations.get('delete_account'),
					content: c,
					hideFooter: true
				}
			case 'add_employe':
				c = <AddEmployeeBox />
				return {
					title: Translations.get('add_employee'),
					content: c,
					hideFooter: true
				}
			case 'change_coupon_value':
				c = <ChangeCouponValueBox />
				return {
					title: Translations.get('change_coupon_value'),
					content: c,
					hideFooter: true
				}
			case 'show_vat_info':
				c = <ShowVatInfoBox />
				return {
					title: Translations.get('vat_0_data'),
					content: c,
					hideFooter: true
				}
			case 'label':
				c = <LabelBox />
				return {
					title: Translations.get('add_label'),
					content: c,
					hideFooter: true
				}
			case 'start_realizing':
				c = <StartRealizingBox />
				return {
					title: Translations.get('start_realizing'),
					content: c,
					hideFooter: true
				}
			case 'forward_to_supplayer':
				c = <ForwardToSupplierBox />
				return {
					title: Translations.get('forward_for_supplier'),
					content: c,
					hideFooter: true
				}
			case 'order_provided':
				c = <OrderProvidedBox />
				return {
					title: Translations.get('order_provided'),
					content: c,
					hideFooter: true
				}
			case 'valuation_reject':
				c = <RejectValuationBox />
				return {
					title: Translations.get('reject_reason'),
					content: c,
					hideFooter: true
				}
			case 'claim':
				c = <ClaimBox />
				return {
					title: Translations.get('claim'),
					content: c,
					hideFooter: true
				}
			default:
				break;
		}
	}

	closePopup() {
		this.props.setPopup(undefined);
		this.props.setRepositoryValue('afterLogin', ()=>{})
	}

	getTitleRow(state) {
		return (
			<Grid
				container
				justify='space-between'
			>
				<Grid item>
					<Typography variant='headline'>
						{state.title}
					</Typography>
				</Grid>
				<Grid item>
					<IconButton
						style={this.styles.exitButton}
						onClick={() => { this.closePopup(); }}
					>
						<ClearIcon />
					</IconButton>
				</Grid>
			</Grid>
		)
	}

	getContent = () => {
		const state = this.chosePopup();
		return (
			<Grid
				container
				direction='column'
			>
				<Grid item style={{ width: '100%' }}>
					{this.getTitleRow(state)}
				</Grid>
				<Grid item>
					{state.content}
				</Grid>
				{state.hideFooter ? "" :
					<Grid item>
						<Footer />
					</Grid>
				}
			</Grid>
		)
	}

	render() {
		if (MediaType.isMobile) {
			return this.renderMobile();
		}
		return (
			<div>
				<div style={styles.popupContainer}
				></div>
				<div style={{
					width: '100%',
					height: '100%',
					position: 'absolute',
					top: '0',
					zIndex: '1201',
					backgroundColor: 'rgba(0,0,0,0.5)'
				}}
					onClick={this.handleBackgroundClick}
					id="bg"
				>
					<Paper style={styles.popupBox}>
						{this.getContent()}
					</Paper>
				</div>
			</div>
		);
	}

	handleBackgroundClick = (event) => {
		if(event.target.id === 'bg'){
			this.closePopup()
		}
	}

	renderMobile() {
		return (
			<div>
				<div style={styles.popupContainerMobile}></div>
				<div style={{
					width: '100%',
					height: '100%',
					position: 'absolute',
					top: '0',
					zIndex: '1201',
				}}
				>
					<Paper style={styles.popupBoxMobile} elevation={0}>
						{this.getContent()}
					</Paper>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		popup: state.page.popup
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (x) => {
			dispatch(setPopup(x));
		},
		setRepositoryValue: (x, y) => {
			dispatch(setValue(x, y));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Popup);
