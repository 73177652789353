import React from "react";
import PropTypes from 'prop-types';
import { Paper, Typography } from "@material-ui/core";

import RadioGroup from 'Components/Commons/RadioGroup'

import styles from 'Components/style'
import Translations from 'Singleton/Translations'
import getRootStyle from './Style'

const container = (props) => {

	return (
		<Paper style={getRootStyle()}>
			<Typography variant='title' paragraph>
				{Translations.get('payment_method')}
			</Typography>
			<RadioGroup
				id={props.id}
				items={[{
					label: 'Płatność online (Paynow / BLIK)',
					key: 'online'
				}, {
					label: Translations.get('standard_money_transfer'),
					key: 'standard'
				}]}
			/>
		</Paper>
	);
}

container.propTypes = {
	id: PropTypes.string,
}

export default container;
