import React from "react";
import { connect } from "react-redux";
import { setPage } from "../Actions/pageActions";
import { Route, withRouter, Switch, Redirect } from "react-router-dom";
import Main from "./Pages/MainPage";
import FAQ from "./Pages/FAQPage";
import Blog from "./Pages/BlogPage";
import ManualValuation from "./Pages/ManualValuation";
import PlacedAnOrder from "./Pages/PlacedAnOrder";
import MyOrdersPage from "./Pages/MyOrders";
import ContactPage from "./Pages/Contact";
import RegulationsPage from "./Pages/Regulations";
import PrivacyPolicyPage from "./Pages/PrivacyPolicy";
import OrderDetailsPage from "./Pages/OrderDetails";
import MoreInfoPage from "./Pages/MoreInfo";
import UserSettingsPage from "./Pages/UserSettings";
import TestPage from "./Pages/test";
import RegisterSuccess from "./Pages/RegisterSuccess";
import ResetPassword from "./Pages/ResetPassword";
import NotFound from "./Pages/NotFound";

import { setInputValue } from "Actions/inputActions";
import { setValue } from "Actions/repositoryActions";
import styles from "../Components/style";

import MediaType from "libs/MediaType";
import Funding from "./Pages/Funding";
import CookiesBar from "../Components/Commons/CookiesBar";
// import RegisterSuccess from "./Pages/RegisterSuccess";
import ReactGA from "react-ga";

export class Body extends React.Component {
  constructor(props) {
    super(props);
    ReactGA.pageview(window.location.pathname + window.location.search);
    setTimeout(() => {
      ReactGA.event({
        category: "FakeBounce",
        action: "User Is Still On Page",
      });
    }, 10000);
    //MOCK: more_info
    // this.props.setInputValue('country_from', 'PL')
    // this.props.setInputValue('post_code_from', '74-400')
    // this.props.setInputValue('country_to', 'CZ')
    // this.props.setInputValue('post_code_to', '272 03')
    // this.props.setInputValue('palet_height_0', '33')
    // this.props.setInputValue('pallets', '1 paleta')
    // this.props.setInputValue('sender', 'private')
    // this.props.setInputValue('pallets_size_0', '0')
    // this.props.setInputValue('details', 'opis')
    // const p = {
    // 	priceGrossBeforeDiscount: 100.10,
    // 	supplier: 'geis',
    // 	priceNet: 100.10,
    // 	priceGross: 100.10,
    // 	exchangeRate: 4.1234,
    // 	discount: 0,
    // 	cost: 100.10,
    // 	basicPrice: 100.10,
    // }
    // this.props.setRepositoryValue('priceBoxState', 'complete')
    // this.props.setRepositoryValue('price', p)
  }

  getRootStyle = () => {
    switch (MediaType.type) {
      case MediaType.TABLET:
        return styles.rootTablet;
      case MediaType.MOBILE:
        return styles.rootMobile;
      default:
        return styles.rootWeb;
    }
  };

  render() {
    return (
      <div>
        <CookiesBar />
        <div id="body" style={this.getRootStyle()}>
          <Switch>
            <Route exact path="/" component={Main} />
            <Route exact path="/faq" component={FAQ} />
            <Route exact path="/blog" component={Blog} />
            <Route
              exact
              path="/blog/:slug"
              component={(props) => <Blog {...props} />}
            />
            <Route
              exact
              path="/manual_valuation/:number"
              component={ManualValuation}
            />
            <Route
              exact
              path="/order/:number/:payment/:variant"
              component={PlacedAnOrder}
            />
            <Route
              exact
              path="/order/:number/:payment"
              component={PlacedAnOrder}
            />
            <Route exact path="/order/:number" component={PlacedAnOrder} />
            <Route exact path="/kontakt" component={ContactPage} />
            <Route exact path="/dofinansowanie" component={Funding} />
            <Route exact path="/regulamin" component={RegulationsPage} />
            <Route
              exact
              path="/polityka_prywatnosci"
              component={PrivacyPolicyPage}
            />
            <Route exact path="/my_orders" component={MyOrdersPage} />
            <Route
              exact
              path="/order_details/:oeType/:number"
              component={OrderDetailsPage}
            />
            <Route exact path="/more_info" component={MoreInfoPage} />
            <Route exact path="/user_settings" component={UserSettingsPage} />
            <Route
              exact
              path="/auth/success/:isSuccess"
              component={RegisterSuccess}
            />
            <Route exact path="/resetpassword" component={ResetPassword} />
            <Route exact path="/test" component={TestPage} />
            <Route component={() => <NotFound />} />
            <Redirect from="*" to="/error404" />
          </Switch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    page: state.page,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPage: (page) => {
      dispatch(setPage(page));
    },
    setInputValue: (x, y) => {
      dispatch(setInputValue(x, y));
    },
    setRepositoryValue: (x, y) => {
      dispatch(setValue(x, y));
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Body));
