import React from 'react'
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Base64 } from 'js-base64';
import { sessionService } from 'redux-react-session';

import Translations from 'Singleton/Translations'

import Sticker from 'Components/Commons/Sticker'
import Dropdown from 'Components/Commons/Dropdown'
import Bar from 'Components/Body/Admin/OrdersBar'
import ColumnHeader from 'Components/Body/Admin/ColumnHeader'
import RestRequest from 'libs/RestRequest'
import { ERROR } from 'libs/RestRequest'

import { FilterType, FilterPopup } from 'Components/Commons/Const/Filter'
import ValuationKeyTranslation from 'Components/Commons/Dictionaries/ValuationKeyTranslation'
import { ValuationStatus } from 'Components/Commons/Const/Valuation'
import ValuationColumn from 'Components/Commons/Const/ValuationColumn'
import { SupplierName } from 'Components/Commons/Const/Supplier'
import SupplierKeyTranslation from 'Components/Commons/Dictionaries/SupplierKeyTranslation'


import { setInputValue } from "Actions/inputActions";
import { redirrect, setPopupFilter, setPopupFunc, setEvalNumber } from 'Actions/pageActions';
import { setLogin } from 'Actions/userActions'
import { setValue } from 'Actions/repositoryActions'
import { setPopup } from 'Actions/pageActions'
import { setPaletsCount } from 'Actions/imageBlockFormActions';

import { Grid, Typography } from '@material-ui/core'
import { Paper } from '@material-ui/core'
import styles from "Components/style";
import { withTheme } from '@material-ui/core/styles'

import DropDownIcon from '@material-ui/icons/ArrowDropDown'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress'
import StarIcon from '@material-ui/icons/Star'
import { timeFormatDots } from 'libs/dateformat'

class container extends React.Component {
	constructor(props) {
		super(props);

		this.columns = [
			"ev.number", "evs.key", "u.email", "o.number",
			"sup.name", "ev.amount_net", "ev.cost", "ev.creation_date",
			"ev.number"

		]
		this.state = {
			hoveredElement: -1,
			hoveredColumn: -1,
			clickedButton: false,
			loading: true,
			tableData: [],
			currentPage: 0,
			orderBy: ValuationColumn.NUMBER,
			refresh: false,
			orderDesc: true,
			pageItemLimit: 10,
			lenghtData: 140,
			filters: [],
		};
		this.setFilterToRepository([]);



		this.showError = this.showError.bind(this);
		this.handleErr = this.handleErr.bind(this);
		this.logout = this.logout.bind(this);
		this.onNextButtonClick = this.onNextButtonClick.bind(this);
		this.onPreviousButtonClick = this.onPreviousButtonClick.bind(this);
		this.getTextFilter = this.getTextFilter.bind(this);
		this.getCheckboxFilter = this.getCheckboxFilter.bind(this);
		this.setFilter = this.setFilter.bind(this);
		this.isStripeRow = this.isStripeRow.bind(this);
		this.getLabels = this.getLabels.bind(this);
		this.getStarImg = this.getStarImg.bind(this);
		this.deleteFilter = this.deleteFilter.bind(this);
		this.getActionButtons = this.getActionButtons.bind(this);
		this.handleStatusResponse = this.handleStatusResponse.bind(this);
		this.popupOnAccept = this.popupOnAccept.bind(this);
		this.acceptValuation = this.acceptValuation.bind(this);
		this.handleAcceptValuationResponse = this.handleAcceptValuationResponse.bind(this);
		this.checkIsAdmin()
	}

	checkIsAdmin = () => {
		if (!this.props.isAdmin) {
			this.props.redirrect('/panel', this.props.history);
		}else{
			this.requestData();
		}
	}

	styles = {
		loadingAnimBG: {
			width: "100%",
			position: "absolute",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			background: "rgba(100,100,100,0.5)",
			display: "block",
			textAlign: 'center',
			paddingTop: '40px',
		},
		lastCell: {
			position: 'relative',
			wordWrap: 'break-word',
		},
		actionButton: {
			position: 'absolute',
			top: '5px',
			right: '5px',
			backgroundColor: '#fff'
		},
		marked: {
			borderLeft: `solid ${this.props.theme.palette.primary.main}`
		}
	}

	logout() {
		sessionService.deleteSession().then(
			sessionService.deleteUser().then(() => {
				this.props.setLogin(null, null, null, null, null);
				this.props.redirrect('/panel', this.props.history);
			})
		);
	}

	showError(msg) {
		toast.error(msg, {
			position: toast.POSITION.TOP_RIGHT,
		});
	}

	handleErr(body) {
		switch (body.errorCode) {
			case ERROR.USER_TOKEN_IS_NOT_VALID:
				this.showError(Translations.get('session_expired'))
				this.logout();
				break;
			default:
				this.showError(body.errorReason)
		}
	}

	requestData(page = this.state.currentPage) {
		var body = {
			userId: this.props.userId,
			offset: page * this.state.pageItemLimit,
			limit: this.state.pageItemLimit,
			orderBy: this.state.orderBy,
			desc: this.state.orderDesc,
			filters: this.state.filters,
		};
		RestRequest("post", "secured/panel/getEvaluationsAdmin", body, (res) => {
			if (res.body.error) {
				this.handleErr(res.body);
			}
			console.log("Get valuations", res.body);
			var rows = [];
			for (var row of res.body.list) {
				var _price = row.priceNet === '0' ? 0.0 : parseFloat(row.priceNet)
				var _cost = row.cost === '0' ? 0.0 : parseFloat(row.cost);
				let purchaser = row.user[0];
				if(row.user[1] && row.user[2]){
					purchaser = `${row.user[1]} ${row.user[2]} (${purchaser})`;
				}
				rows.push({
					number: row.number,
					status: row.state,
					purchaser,
					order: row.orderNumber,
					provider: row.suplier,

					price: row.priceNet === '0' ? "-" : row.priceNet,
					cost: row.cost === '0' ? "-" : row.cost,
					profit: "" + (_price - _cost).toFixed(2),

					orderDate: timeFormatDots(row.createDate),
					lastMessage: row.lastMessage,
					isStripe: false,
					isMarked: row.isMarked,
					labels: row.labels,

					customerId: row.customerId,
				});
			}
			this.setState({
				...this.state,
				loading: false,
				tableData: rows,
				lenghtData: res.body.rowsCount,
				refresh: false
			});

			// this.props.setRepositoryValue('labels',['Vip','Promka','Oponka','Mmm'])
		}, this.props.userToken);
	}

	onRowEnter(index) {
		this.setState({
			...this.state,
			hoveredElement: index
		})
	}

	onRowLeave(index) {
		if (this.state.clickedButton) {
			return;
		}
		this.setState({
			...this.state,
			hoveredElement: -1
		})
	}

	onClickDropdown() {
		this.setState({
			...this.state,
			clickedButton: true
		})
	}

	onCloseDropdown() {
		this.setState({
			...this.state,
			clickedButton: false,
			hoveredElement: -1
		})
	}

	hashNumber(number) {
		return Base64.encodeURI(number);
	}

	isStripeRow(state) {
		switch (state) {
			case ValuationStatus.NEW:
			case ValuationStatus.OUTDATED:
			case ValuationStatus.REJECTED:
				return true;
			default:
				return false;
		}
	}

	getStarImg(isMarked) {
		if (isMarked) {
			return (
				<StarIcon
					color='primary'
					style={{ fontSize: 16, marginBottom: '2px', marginLeft: '2px' }}
				/>
			);
		}
		return '';
	}

	getLabels(labels) {
		return (
			<div>
				{labels.map((item, key) => {
					return (
						<Sticker key={key} hover={false}>{item}</Sticker>
					);
				})}
			</div>
		);
	}

	popupOnAccept() {
		console.log('popup on accept');
		this.setState({
			refresh: true
		})
	}

	setStatusValuation(value, number) {

		RestRequest(
			'post',
			'secured/updateEvaluationStatus',
			{
				statusName: value,
				number,
			},
			this.handleStatusResponse,
			this.props.userToken
		);
	}

	handleStatusResponse(res) {
		if (res.body.error) {
			this.showError(res.body.errorReason)
		} else {
			this.popupOnAccept();
		}
	}

	acceptValuation(number) {
		RestRequest(
			'post',
			'secured/getAdminEvaluation',
			{
				userId: this.props.userId,
				number,
			},
			this.handleAcceptValuationResponse,
			this.props.userToken
		)
		this.props.setInputValue('valuation-number', number);
	}

	rejectValuation(number) {
		this.props.setRepositoryValue('number', number);
		this.props.setPopupFunc(this.popupOnAccept);
		this.props.setPopup('valuation_reject');
	}

	handleAcceptValuationResponse(res) {
		console.log('accept/ get data', res);
		if (res.body.error) {
			this.showError(res.body.errorReason)
			return;
		}
		var data = res.body;
		if (data.isPrivate) {
			this.props.setInputValue('sender', 'private');
		} else {
			this.props.setInputValue('sender', 'company');
		}

		this.props.setInputValue('post_code_from', data.pickupPostcode);
		this.props.setInputValue('country_from', data.pickupCountry);

		this.props.setInputValue('post_code_to', data.deliveryPostcode);
		this.props.setInputValue('country_to', data.deliveryCountry);

		this.props.setInputValue('details', data.remarks);
		this.props.setPaletsCount(data.pallets.length);
		this.props.setRepositoryValue('pricePLN', data.amountNet);
		for (var i = 0; i < data.pallets.length; i++) {
			var pallet = data.pallets[i];
			var type = {};
			for (var typeTemp of this.props.palletsTypes) {
				if (typeTemp.type === pallet.type) {
					type = this.props.palletsTypes.indexOf(typeTemp);
				}
			}
			this.props.setInputValue(`palet_height_${i}`, pallet.height);
			this.props.setInputValue(`palet_weight_${i}`, pallet.weight);
			this.props.setInputValue(`fragile_cargo_${i}`, pallet.isFragile);
			this.props.setInputValue(`pallets_size_${i}`, type);
			this.props.setInputValue(`palet_length_${i}`, pallet.customLength);
			this.props.setInputValue(`palet_width_${i}`, pallet.customWidth);
		}
		const price = {
			cost: data.cost,
			priceNet: data.amountNet,
			priceGross: data.amountGross,
			exchangeRate: data.exchangeRate,
			supplier: data.supplier,
		}
		this.props.setRepositoryValue('userId', data.userId);
		this.props.setRepositoryValue('price', price);
		this.props.redirrect(`/more_info`, this.props.history);
	}

	getActionButtons(state, number) {
		switch (state) {
			case ValuationStatus.NEW:
				return [{
					label: Translations.get('send_for_verify'),
					onClick: () => {
						this.props.setEvalNumber(number);
						this.props.setPopup("send_for_acceptance");
						this.props.setPopupFunc(this.popupOnAccept);
					},
				}, {
					label: Translations.get('mark_as_outdated'),
					onClick: () => { this.setStatusValuation('outdated_eval', number) },
				}, {
					label: Translations.get('renew_evaluation'),
					onClick: () => { this.setStatusValuation('new_eval', number) },
				}];
			case ValuationStatus.AWAIT:
				return [{
					label: Translations.get('send_again_for_acceptance'),
					onClick: () => {
						this.props.setEvalNumber(number);
						this.props.setPopupFunc(this.popupOnAccept);
						this.props.setPopup("send_for_acceptance")
					},
				}, {
					label: Translations.get('client_accepted'),
					onClick: () => { this.acceptValuation(number) },
				}, {
					label: Translations.get('client_rejected'),
					onClick: () => { this.rejectValuation(number) },
				}, {
					label: Translations.get('mark_as_outdated'),
					onClick: () => { this.setStatusValuation('outdated_eval', number) },
				}, {
					label: Translations.get('renew_evaluation'),
					onClick: () => { this.setStatusValuation('new_eval', number) },
				}];
			case ValuationStatus.OUTDATED:
				return [{
					label: Translations.get('send_again_for_acceptance'),
					onClick: () => {
						this.props.setEvalNumber(number);
						this.props.setPopupFunc(this.popupOnAccept);
						this.props.setPopup("send_for_acceptance");
					},
				}, {
					label: Translations.get('client_accepted'),
					onClick: () => { this.acceptValuation(number) },
				}, {
					label: Translations.get('client_rejected'),
					onClick: () => { this.rejectValuation(number) },
				}, {
					label: Translations.get('renew_evaluation'),
					onClick: () => { this.setStatusValuation('new_eval', number) },
				}];
			case ValuationStatus.ACCEPTED:
				return [{
					label: Translations.get('client_rejected'),
					onClick: () => { this.rejectValuation(number) },
				}, {
					label: Translations.get('renew_evaluation'),
					onClick: () => { this.setStatusValuation('new_eval', number) },
				}];
			case ValuationStatus.REJECTED:
				return [{
					label: Translations.get('send_again_for_acceptance'),
					onClick: () => {
						this.props.setEvalNumber(number);
						this.props.setPopupFunc(this.popupOnAccept);
						this.props.setPopup("send_for_acceptance");
					},
				}, {
					label: Translations.get('client_accepted'),
					onClick: () => { this.acceptValuation(number) },
				}, {
					label: Translations.get('renew_evaluation'),
					onClick: () => { this.setStatusValuation('new_eval', number) },
				}];
			default:
				return [{
					label: "__NO_DATA__",
					onClick: () => { console.log("__NO_DATA__"); },
				}];
		}
	}

	getDropdownMenu(status, number, i) {
		if (i !== this.state.hoveredElement) {
			return '';
		}
		const label = (
			<Typography className='table-link'>
				{Translations.get('actions')}
				<DropDownIcon />
			</Typography>
		)
		return (
			<Dropdown
				label={label}
				onClick={() => { this.onClickDropdown() }}
				onClose={() => { this.onCloseDropdown() }}
				rows={this.getActionButtons(status, number)}
				style={this.styles.actionButton}
				variant='outlined'
			/>
		);
	}

	handleRowClick = number => (event) => {
		if(event.target.nodeName === 'svg'
		|| event.target.nodeName === 'path'){
			return;
		}
		const classTable = event.target.className.split(' ')
		if(classTable.includes('table-link')
		|| event.target.nodeName === 'BUTTON'
		|| event.target.nodeName === 'SPAN'
		|| event.target.nodeName === 'UL'
		|| event.target.nodeName === 'LI'
		|| event.target.nodeName === 'DIV'
		){
			return;
		}
		const linkUri = `/panel/evaluation_details/evaluation/${this.hashNumber(number)}`;
		this.props.redirrect(linkUri, this.props.history)
	}

	createRows(data) {
		return data.map((item, i) => {
			const customerLink = `/panel/client_details/${this.hashNumber(item.customerId)}`

			var c = 'online-orders-table-row';
			c += this.isStripeRow(item.status) ? " online-orders-table-row-stripe" : '';
			var dropdownClass = 'online-orders-table-row-dropdown';
			if (i !== this.state.hoveredElement) {
				dropdownClass += " hide"
			}
			var orderUri = `/panel/evaluation_details/order/${this.hashNumber(item.order)}`;
			return (
				<TableRow
					key={i}
					className={c}
					onMouseEnter={() => { this.onRowEnter(i) }}
					onMouseLeave={() => { this.onRowLeave(i) }}
					style={this.isStripeRow(item.status) ? this.styles.marked : {}}
					onClick={this.handleRowClick(item.number)}
				>
					<TableCell style={styles.tableColumn1}>
						<Grid container direction='column'>
							<Grid item>
								<Grid container style={styles.tableColumn1}>
									<Grid item>
										<Typography
											noWrap
										>
											{item.number}
										</Typography>
									</Grid>

									<Grid item>
										{this.getStarImg(item.isMarked)}
									</Grid>
								</Grid>
							</Grid>
							<Grid item>
								<Grid container>
									{this.getLabels(item.labels)}
								</Grid>
							</Grid>
						</Grid>

					</TableCell>
					<TableCell style={styles.tableColumn1}>
						{ValuationKeyTranslation.status(item.status)}
					</TableCell>
					<TableCell style={styles.tableColumn2}>
						<Typography noWrap
							onClick={() => {
								this.props.redirrect(customerLink, this.props.history)
							}}
							style={styles.link}
							className='table-link'
						>
							{item.purchaser}
						</Typography>
					</TableCell>
					<TableCell style={styles.tableColumn1} >
					<Typography
							onClick={() => {
								this.props.redirrect(orderUri, this.props.history)
							}}
							style={styles.link}
							className='table-link'
						>
							{item.order}
						</Typography>
					</TableCell>
					<TableCell style={styles.tableColumn1}>
						{Translations.get(item.provider)}
					</TableCell>
					<TableCell style={styles.tableColumn0}>
						{item.price}
					</TableCell>
					<TableCell style={styles.tableColumn0}>
						{item.cost}
					</TableCell>
					<TableCell style={styles.tableColumn0}>
						{item.profit}
					</TableCell>
					<TableCell style={styles.tableColumn1}>
						{item.orderDate}
					</TableCell>
					<TableCell style={{ ...styles.tableColumn1, ...this.styles.lastCell }}>
						<Typography style={{ ...styles.tableColumn1, ...this.styles.lastCell }} noWrap>
							{item.lastMessage}
						</Typography>
						{this.getDropdownMenu(item.status, item.number, i)}
					</TableCell>
				</TableRow>
			);
		});
	}

	setOrderColumn(id) {
		console.log("clicked column " + id)
		var desc = this.state.orderDesc;
		if (id === this.state.orderBy) {
			desc = !desc;
		} else {
			desc = false;
		}
		this.setState({
			...this.state,
			orderBy: id,
			loading: true,
			refresh: true,
			orderDesc: desc
		}
		);
	}

	onNextButtonClick() {
		var nextPage = this.state.currentPage + 1;
		this.setState({
			...this.state,
			currentPage: nextPage,
			loading: true,
		})
		this.requestData(nextPage);
	}

	onPreviousButtonClick() {
		var nextPage = this.state.currentPage - 1;
		this.setState({
			...this.state,
			currentPage: nextPage,
			loading: true,
		})
		this.requestData(nextPage);
	}

	setFilter(name, type, payload) {
		var filters = this.state.filters;
		var index = filters.findIndex(
			(obj) => { return (obj.name.equals(name) && obj.type === type); }
		);

		if (index >= 0) {
			var item = filters[index];
			filters.splice(index, 1);
			item.payload = payload;
		} else {
			item = {
				name: name,
				type: type,
				payload: payload,
			}
		}
		filters.push(item);
		this.setState({
			...this.state,
			refresh: true,
			loading: true,
			filters: filters,
			currentPage: 0,
		})
		this.setFilterToRepository(filters)
		console.log("Set Filter", this.state);
	}

	setFilterToRepository = (filters) => {
		this.props.setRepositoryValue('filters',filters)
	}

	setFilterKeyToRepository = (key) => {
		this.props.setRepositoryValue('filtersKey',key)
	}

	deleteFilter(name, type = false) {
		var filters = this.state.filters;
		var index = filters.findIndex(
			(obj) => {
				var isEq = obj.name.equals(name);
				if (type !== false) {
					isEq = (obj.type === type) && isEq;
				}
				return isEq;
			}
		);
		if (index < 0) {
			return;
		}
		filters.splice(index, 1);
		this.setState({
			...this.state,
			refresh: true,
			loading: true,
			filters: filters,
			currentPage: 0,
		})
		console.log("Delete Filter", this.state);
	}

	getTextFilter(key) {
		this.props.setRepositoryValue('popupOnAccept',
			(text) => { this.setFilter(key, FilterType.TEXT, text); }
		);
		this.props.setRepositoryValue('popupOnClear',
			() => { this.deleteFilter(key); }
		);
		this.setFilterKeyToRepository(key);
		this.props.setPopupFilter(FilterPopup.TEXT);
	}

	getCheckboxFilter(key, options, translations) {
		this.props.setRepositoryValue('popupOnAccept',
			(arr) => { this.setFilter(key, FilterType.CHECKBOX, arr); }
		);
		this.props.setRepositoryValue('popupOnClear',
			() => { this.deleteFilter(key); }
		);
		this.props.setRepositoryValue('checkboxFilterArray', options);
		this.props.setRepositoryValue('checkboxFilterTranslation', translations);
		this.setFilterKeyToRepository(key);
		this.props.setPopupFilter(FilterPopup.CHECKBOX);
	}

	getIntervalFilter(key, type) {
		this.props.setRepositoryValue('popupOnAccept',
			(x) => { this.setFilter(key, type, x); }
		);
		this.props.setRepositoryValue('popupOnClear',
			() => { this.deleteFilter(key); }
		);
		var popup;
		switch (type) {
			case FilterType.NUMBER_INTERVAL: popup = FilterPopup.NUMBER; break;
			case FilterType.DATE_INTERVAL: popup = FilterPopup.DATE; break;
			default: return;
		}
		this.setFilterKeyToRepository(key);
		this.props.setPopupFilter(popup);
	}

	getLoading() {
		if (this.state.isLoading) {
			return (
				<div style={this.styles.loadingAnimBG}>
					<CircularProgress size={80} thickness={5} />
				</div>
			);
		}
		return '';
	}

	isFilter(filter){
		const obj = this.state.filters.find((item)=>{
			return item.name.equals(filter);
		})
		if (obj){
			return true;
		}
		return false;
	}

	getHeaders() {
		return (
			<TableRow>
				<ColumnHeader
					style={styles.tableColumn1}
					label={Translations.get('number')}
					sort={() => { this.setOrderColumn(ValuationColumn.NUMBER) }}
					filter={() => {
						this.getTextFilter([ValuationColumn.NUMBER])
					}}
					visibleFilter={this.isFilter([ValuationColumn.NUMBER])}
					visible={this.state.orderBy === ValuationColumn.NUMBER}
				/>
				<ColumnHeader
					style={styles.tableColumn1}
					label={Translations.get('status')}
					visible={this.state.orderBy === ValuationColumn.STATUS}
					sort={() => { this.setOrderColumn(ValuationColumn.STATUS) }}
					visibleFilter={this.isFilter([ValuationColumn.STATUS])}
					filter={() => {
						this.getCheckboxFilter(
							[ValuationColumn.STATUS],
							ValuationStatus.GET_ALL,
							ValuationKeyTranslation.status,
						);
					}}
				/>
				<ColumnHeader
					style={styles.tableColumn2}
					label={Translations.get('purchaser')}
					visible={this.state.orderBy === ValuationColumn.EMAIL}
					sort={() => { this.setOrderColumn(ValuationColumn.EMAIL) }}
					visibleFilter={this.isFilter([
						ValuationColumn.EMAIL,
						ValuationColumn.NAME,
						ValuationColumn.SURNAME
					])}
					filter={() => {
						this.getTextFilter([
							ValuationColumn.EMAIL,
							ValuationColumn.NAME,
							ValuationColumn.SURNAME
						])
					}}
				/>
				<ColumnHeader
					style={styles.tableColumn1}
					label={Translations.get('order')}
					visible={this.state.orderBy === ValuationColumn.ORDER_NUMBER}
					sort={() => { this.setOrderColumn(ValuationColumn.ORDER_NUMBER) }}
					visibleFilter={this.isFilter([ValuationColumn.ORDER_NUMBER])}
					filter={() => {
						this.getTextFilter([ValuationColumn.ORDER_NUMBER])
					}}
				/>
				<ColumnHeader
					style={styles.tableColumn1}
					label={Translations.get('supplier')}
					visible={this.state.orderBy === ValuationColumn.SUPLLIER_NAME}
					sort={() => { this.setOrderColumn(ValuationColumn.SUPLLIER_NAME) }}
					visibleFilter={this.isFilter([ValuationColumn.SUPLLIER_NAME])}
					filter={() => {
						this.getCheckboxFilter(
							[ValuationColumn.SUPLLIER_NAME],
							SupplierName.GET_ALL,
							SupplierKeyTranslation.name,
						);
					}}
				/>
				<ColumnHeader
					style={styles.tableColumn0}
					label={Translations.get('price')}
					visible={this.state.orderBy === ValuationColumn.AMMOUNT_GROSS}
					sort={() => { this.setOrderColumn(ValuationColumn.AMMOUNT_GROSS) }}
					visibleFilter={this.isFilter([ValuationColumn.AMMOUNT_GROSS])}
					filter={() => {
						this.getIntervalFilter(
							[ValuationColumn.AMMOUNT_GROSS],
							FilterType.NUMBER_INTERVAL
						);
					}}
				/>
				<ColumnHeader
					style={styles.tableColumn0}
					label={Translations.get('cost')}
					visible={this.state.orderBy === ValuationColumn.AMMOUNT_COST}
					sort={() => { this.setOrderColumn(ValuationColumn.AMMOUNT_COST) }}
					visibleFilter={this.isFilter([ValuationColumn.AMMOUNT_COST])}
					filter={() => {
						this.getIntervalFilter(
							[ValuationColumn.AMMOUNT_COST],
							FilterType.NUMBER_INTERVAL
						);
					}}
				/>
				<ColumnHeader
					style={styles.tableColumn0}
					label={Translations.get('profit')}
				/>
				<ColumnHeader
					style={styles.tableColumn1}
					label={Translations.get('evaluation_creation_date')}
					visible={this.state.orderBy === ValuationColumn.CREATE_DATE}
					sort={() => { this.setOrderColumn(ValuationColumn.CREATE_DATE) }}
					visibleFilter={this.isFilter([ValuationColumn.CREATE_DATE])}
					filter={() => {
						this.getIntervalFilter(
							[ValuationColumn.CREATE_DATE],
							FilterType.DATE_INTERVAL
						);
					}}
				/>
				<ColumnHeader
					style={styles.tableColumn1}
					label={Translations.get('last_message')}
					visible={this.state.orderBy === ValuationColumn.LAST_MESSAGE}
					sort={() => { this.setOrderColumn(ValuationColumn.LAST_MESSAGE) }}
					visibleFilter={this.isFilter([ValuationColumn.LAST_MESSAGE])}
					filter={() => {
						this.getTextFilter([ValuationColumn.LAST_MESSAGE])
					}}
				/>
			</TableRow>
		)
	}

	getTable() {
		return (
			<Table>
				<TableHead>
					{this.getHeaders()}
				</TableHead>
				<TableBody>
					{this.createRows(this.state.tableData)}
				</TableBody>
			</Table>
		)
	}

	render() {
		if(!this.props.isAdmin){
			return '';
		}
		if (this.state.refresh) {
			this.requestData();
		}
		return (
			<div style={styles.tableContainer}>
				<Paper style={{ ...styles.paperBox, position: 'relative' }}>
					<Grid
						container
						direction='column'
					>
						<Grid item>
							<Bar
								title={Translations.get('evaluations')}
								isSearcher={true}
								isFilter={true}
								page={this.state.currentPage}
								step={this.state.pageItemLimit}
								length={this.state.lenghtData}
								nextAction={this.onNextButtonClick}
								previousAction={this.onPreviousButtonClick}
								setFilter={this.setFilter}
								deleteFilter={this.deleteFilter}
							/>
						</Grid>
						<Grid item>
							{this.getTable()}
						</Grid>
						<Grid item style={styles.space16Vertical}>
						</Grid>
						<Grid item>
							<Bar
								page={this.state.currentPage}
								step={this.state.pageItemLimit}
								length={this.state.lenghtData}
								nextAction={this.onNextButtonClick}
								previousAction={this.onPreviousButtonClick}
							/>
						</Grid>
					</Grid>
					{this.getLoading()}
				</Paper>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		isAdmin: state.user.isAdmin,
		userId: state.user.id,
		userToken: state.user.token,
		lang: state.translations.lang,
		exVal: state.translations.exchangeCurrencyBid,
		palletsTypes: state.translations.pallets,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (popup) => {
			dispatch(setPopup(popup));
		},
		redirrect: (x, y) => {
			dispatch(redirrect(x, y));
		},
		setLogin: (x, y, z, q, t, a) => {
			dispatch(setLogin(x, y, z, q, t, a));
		},
		setPopupFilter: (x, y) => {
			dispatch(setPopupFilter(x, y));
		},
		setRepositoryValue: (x, y) => {
			dispatch(setValue(x, y));
		},
		setPopupFunc: (f) => {
			dispatch(setPopupFunc(f));
		},
		setEvalNumber: (val) => {
			dispatch(setEvalNumber(val));
		},
		setInputValue: (x, y) => {
			dispatch(setInputValue(x, y));
		},
		setPaletsCount: (count) => {
			dispatch(setPaletsCount(count));
		},
	};
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme()(container)));
