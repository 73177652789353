import React from "react";
import PropTypes from 'prop-types';

import { Paper } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";

// import DatePicker from 'Components/Commons/DatePicker';
// import TimePicker from 'Components/Commons/TimePicker';
import Translations from 'Singleton/Translations'
import DatePicker from './components/DataPicker'

import styles from 'Components/style'
import MediaType from "../../../libs/MediaType";
import getRootStyle from './Style'


const days = ({
	'AT': '2-3',
	'BE': '2-4',
	'BG': '4-8',
	'HR': '4-6',
	'CY': '5-7',
	'CZ': '2',
	'DK': '3-6',
	'EE': '2-3',
	'FI': '4-6',
	'FR': '6-8',
	'GR': '3-5',
	'ES': '5-11',
	'NL': '2-3',
	'IE': '5-6',
	'LT': '2',
	'LU': '4',
	'LV': '2-3',
	'MT': '2',
	'DE': '2-3',
	'NO': '6-11',
	'PT': '6-11',
	'RO': '3-4',
	'SK': '2',
	'SI': '3-5',
	'CH': '3-6',
	'SE': '3-7',
	'HU': '2',
	'GB': '3-7',
	'IT': '3-8',
})

class container extends React.Component {
	constructor(props) {
		super(props);

		if (this.props.onValidChange) {
			this.props.onValidChange(true)
		}
	}

	// state = {
	// 	isTimeFromValid: false,
	// 	isTimeToValid: false,
	// 	isDateValid: false,
	// 	isValid: false,
	// }

	// onTimeValidChange = (id) => (value) => {
	// 	this.setState({
	// 		...this.state,
	// 		[id]: value,
	// 	}, this.checkValidChange)
	// }

	// checkValidChange = () => {
	// 	const isValid = (this.state.isTimeFromValid && this.state.isTimeToValid && this.state.isDateValid);
	// 	if (this.state.isValid === isValid) {
	// 		return;
	// 	}
	// 	this.setState({
	// 		...this.state,
	// 		isValid,
	// 	})
	// 	if (this.props.onValidChange) {
	// 		this.props.onValidChange(isValid)
	// 	}
	// }

	render() {

		return (
			<Paper style={getRootStyle()}>
				<Typography variant='title' paragraph>
					{Translations.get('loading_date')}
				</Typography>

				<Grid
					container
					justify={'space-between'}
					alignItems={MediaType.isDesktop ? 'flex-end' : 'stretch'}
					direction={MediaType.isDesktop ? 'row' : 'column'}
				>
					<Grid item style={
						MediaType.isMobile ?
							styles.mobileBox :
							styles.moreInfoColumn2
					}>
						<Grid
							container
						// direction={MediaType.isMobile ? 'column' : 'row'}
						>
							<Grid item>
								<DatePicker
									id={this.props.id}
								/>
							</Grid>
							{/* <Grid item>
								<DatePicker
									id={dateId}
									minDate={minDate}
									maxDate={maxDate}
									label={Translations.get('date')}
									onValidChange={this.onTimeValidChange('isDateValid')}
									withoutWeekends
								/>
							</Grid>
							<Grid item style={styles.space30}>
							</Grid>
							<Grid
								container
								wrap='nowrap'
								style={{width:'180px'}}
							>
								<Grid item>
									<TimePicker
										id={timeIdFrom}
										label={Translations.get('date_from')}
										onValidChange={this.onTimeValidChange('isTimeFromValid')}
									/>
								</Grid>
								<Grid item style={styles.space30}>
								</Grid>
								<Grid item>
									<TimePicker
										id={timeIdTo}
										label={Translations.get("date_to")}
										onValidChange={this.onTimeValidChange('isTimeToValid')}
									/>
								</Grid>
							</Grid> */}
						</Grid>
					</Grid>
					<Grid item style={styles.space30Vertical}></Grid>
					<Grid item style={
						MediaType.isMobile ?
							styles.mobileBox :
							styles.moreInfoColumn2
					}>
						<Typography variant='body1'>
							{Translations.get('info_about_loading_date')}
							{' '}
							{days[this.props.country]}
							{' '}
							{Translations.get('info_about_loading_date_2')}
						</Typography>
					</Grid>
				</Grid>
			</Paper>
		);
	}
}

container.propTypes = {
	id: PropTypes.string,
	onValidChange: PropTypes.func,
	country: PropTypes.string,
}

export default container;
