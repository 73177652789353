import React from 'react'
import PropTypes from 'prop-types';

import Translation from 'Singleton/Translations'
import { Grid, Button, Typography } from '@material-ui/core';
import BackArrowIcon from '@material-ui/icons/ArrowBack'
import ForwardArrowIcon from '@material-ui/icons/ArrowForward'

import styles from "Components/style";

const container = (props) => {

	//Calc items positions
	var offset= props.step * props.page;
	var nextDisabled = false;
	var previousDisabled = props.page === 0;

	var last = offset + props.step;
	if(last>props.length){
		last = props.length;
	}
	if(last === props.length){
		nextDisabled = true;
	}

	var text = `${offset} - ${last} z ${props.length}`;
	return (
		<Grid
			container
			alignItems='baseline'
		>
			<Grid item>
				<Button
					variant='outlined'
					disabled={previousDisabled}
					onClick={props.previousAction}
				>
					<BackArrowIcon />
					{Translation.get('previous')}
				</Button>
			</Grid>
			<Grid item style={styles.space30}>
			</Grid>
			<Grid item>
				<Typography variant='body1'>
					{text}
				</Typography>
			</Grid>
			<Grid item style={styles.space30}>
			</Grid>
			<Grid item>
				<Button
					variant='outlined'
					disabled={nextDisabled}
					onClick={props.nextAction}
				>
					{Translation.get('next')}
					<ForwardArrowIcon />
				</Button>
			</Grid>
		</Grid>
	);
}

container.propTypes = {
	className: PropTypes.string,
	previousAction: PropTypes.func,
	nextAction: PropTypes.func,
	length: PropTypes.number.isRequired,
	step: PropTypes.number.isRequired,
	page: PropTypes.number.isRequired,	//begin from 0
};

export default container;
