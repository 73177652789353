import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel';

import {setInputValue} from "Actions/inputActions";

class component extends Component {
	constructor(params) {
		super(params);
		const initValue = this.props.defaultKey ? this.props.defaultKey : this.props.items[0].key;
		this.state={
			value: initValue
		}
		this.props.setInputValue(this.props.id, initValue);
	}

	styles={
		label:{
			marginBottom: 0,
		}
	}

	handleChange = event => {
		this.setState({
			...this.state,
			value: event.target.value,
		})
		this.props.setInputValue(this.props.id, event.target.value);
		if(this.props.onChange){
			this.props.onChange(event.target.value);
		}
	}

	getValues = () => {
		return this.props.items.map((item,i) => {
			return (
				<FormControlLabel
					style={this.styles.label}
					key={i}
					value={item.key}
					label={item.label}
					control={<Radio color='primary' />}
				/>
			);
		});
	}

	render() {
		
		return (
			<RadioGroup
				name={this.props.id}
				value={this.state.value}
				onChange={this.handleChange}
			>
				{this.getValues()}
			</RadioGroup>
		)
	}
}

component.propTypes={
	id: PropTypes.string.isRequired,
	items: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.any.isRequired,
			key: PropTypes.string.isRequired,
		})
	).isRequired,
	onChange: PropTypes.func,
	defaultKey: PropTypes.string,
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = dispatch => {
	return {
		setInputValue: (id, value) => dispatch(setInputValue(id, value)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(component)
