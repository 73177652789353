import request from 'superagent';

export const getGoogle = (accessToken, resCallback) => {
	request
		.get('https://www.googleapis.com/oauth2/v1/userinfo')
		.query({
			alt: 'json',
			access_token: accessToken,
		})
		.end((err, res) => {
			resCallback(res);
		});
};

export const getFacebook = (accessToken, resCallback) => {
	request
		.get('https://graph.facebook.com/me/permissions')
		.query({
			access_token: accessToken,
		})
		.end((err, res) => {
			resCallback(res);
		});
};
