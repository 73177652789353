import React from 'react'
import PropTypes from 'prop-types';

import styles from 'Components/style'
import ClearIcon from '@material-ui/icons/Clear'
import { Typography } from '@material-ui/core';

class sticker extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isHover: false,
			hoverAnim: this.props.hover === false ? false : true,
		}
		this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this);
		this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this);
		this.getRootStyle = this.getRootStyle.bind(this);
		this.onClick = this.onClick.bind(this);
	}

	styles = {
		root: {
			border: `solid 1px ${styles.palette.yellow}`,
			display: 'inline-flex',
			borderRadius: '4px',
			padding: '2px',
			backgroundColor: '#fff',
			marginRight: '5px',
			position: 'relative',
		},
		rootHover: {
			backgroundColor: '#00000022',
			cursor: 'pointer',
		},
		iconStyle: {
			position: 'absolute',
			top: 0,
			width: '20px',
			height: '20px',
			marginLeft: 'auto',
			marginRight: 'auto',
			left: 0,
			right: 0,
		},
		iconHide:{
			display: 'none',
		}
	}

	getRootStyle() {
		if (this.state.isHover) {
			return Object.assign({},
				this.styles.root,
				this.styles.rootHover
			);
		}
		return this.styles.root;
	}

	getIconStyle() {
		if (this.state.isHover) {
			return this.styles.iconStyle;
		}
		return this.styles.iconHide;
	}

	onMouseEnterHandler() {
		if (this.state.hoverAnim) {
			this.setState({
				isHover: true
			});
		}
	}
	onMouseLeaveHandler() {
		if (this.state.hoverAnim) {
			this.setState({
				isHover: false
			});
		}
	}

	onClick() {
		if (this.props.onClick) {
			this.props.onClick();
		}
	}

	render() {
		return (
			<div
				className={this.props.className}
				style={this.getRootStyle()}
				onMouseEnter={this.onMouseEnterHandler}
				onMouseLeave={this.onMouseLeaveHandler}
				onClick={this.onClick}
			>
				<ClearIcon style={this.getIconStyle()} />
				<Typography variant='caption' noWrap style={styles.sticker}>
					{this.props.text}
					{this.props.children}
				</Typography>

			</div>
		);
	}
}

sticker.propTypes = {
	className: PropTypes.string,
	text: PropTypes.string,
	onClick: PropTypes.func,
	hover: PropTypes.bool,
};

export default sticker;
