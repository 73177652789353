import React from 'react'
import { connect } from "react-redux";
import sha256 from 'sha256';
import { toast } from 'react-toastify';

import Rest from 'libs/RestRequest'
import ConsentBox from 'Components/Body/Popup/ConsentBox'
import Translations from 'Singleton/Translations'

import { Grid, Typography, Button } from '@material-ui/core';
import TextInput from '../../Components/Commons/TextInput';
import { TextInputType } from 'Components/Commons/Const/InputTypes'
import styles from '../../Components/style';
import IconText from 'Components/Commons/IconText'

class container extends React.Component {
	constructor(props) {
		super(props);
		this.registerUser = this.registerUser.bind(this)
		this.state = {
			validMail: false,
			validPsswd: false,
			validSecondPsswd: false,
			isLoading: false,
			isRegisterEnd: false,
			isSamePassw: true,
		}
	}

	showError(msg) {
		toast.error(msg, {
			position: toast.POSITION.TOP_RIGHT,
		});
	}

	onValidateMailChange(value) {
		this.setState({
			...this.state,
			validMail: value
		});
	}

	onValidatePassChange(value) {
		this.setState({
			...this.state,
			validPsswd: value,
		})
	}
	onValidateSecPassChange(value) {
		this.setState({
			...this.state,
			validSecondPsswd: value,
		})
	}

	clickButton() {
		if (this.props.inputs.password !== this.props.inputs.password2) {
			this.setState({
				...this.state,
				isSamePassw: false,
			})
			return;
		}
		this.setState({
			...this.state,
			isLoading: true,
			isSamePassw: true,
		});
		setTimeout(this.registerUser, 1)
	}

	registerUser() {
		Rest('post', 'auth/register', {
			loginType: "Email",
			login: this.props.inputs.email,
			pssw: sha256.x2(this.props.inputs.password),
			language: this.props.lang
		}, this.catchRes.bind(this))
	}

	catchRes(res) {
		console.log(res);
		if (res.body.error) {
			this.showError(res.body.errorReason)
			this.setState({
				...this.state,
				isLoading: false,
			});
		} else {
			this.setState({
				...this.state,
				isRegisterEnd: true,
			});
		}
	}

	getButton() {
		var button;
		if (this.state.isLoading) {
			if (this.state.isRegisterEnd) {
				button =
					<IconText
						type={'done'}
						variant={'body1'}
						centered
					>
						{Translations.get('registered_end')}
					</IconText>
			}
			else {
				button =
				<IconText
					type={'loading'}
					variant={'body1'}
					centered
				>
					{Translations.get('registering_load')}...
				</IconText>
			}
		} else {
			button = <Button
				fullWidth
				variant='contained'
				color='primary'
				onClick={this.clickButton.bind(this)}
				disabled={
					!this.state.validMail ||
					!this.state.validPsswd ||
					!this.state.validSecondPsswd ||
					!this.props.inputs['consent-regulations'] ||
					!this.props.inputs['consent-personal-data'] ||
					!this.props.inputs['consent-invoices']
				}
			>
				{Translations.get('register')}
			</Button>
		}
		return button;
	}

	render() {
		return (
			<Grid
				container
				direction='column'
			>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Typography variant='body1'>
						{Translations.get('info_about_activation_link')}
					</Typography>
				</Grid>
				<Grid item>
					<TextInput
						id='email'
						fullWidth
						onValidChange={this.onValidateMailChange.bind(this)}
						type={TextInputType.EMAIL}
						label={Translations.get('address_email')}
						disabled={this.state.isLoading}
					/>
				</Grid>
				<Grid item>
					<TextInput
						id='password'
						fullWidth
						onValidChange={this.onValidatePassChange.bind(this)}
						type={TextInputType.PASSWORD}
						label={Translations.get('password')}
						disabled={this.state.isLoading}
					/>
				</Grid>
				<Grid item>
					<TextInput
						id='password2'
						fullWidth
						onValidChange={this.onValidateSecPassChange.bind(this)}
						type={TextInputType.PASSWORD}
						label={Translations.get('repeat_password')}
						errorReason={Translations.get('password_must_matches')}
						isError={!this.state.isSamePassw}
						disabled={this.state.isLoading}
					/>
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<ConsentBox 
						disabled={this.state.isLoading}
					/>
				</Grid>
				<Grid item>
					{this.getButton()}
				</Grid>
			</Grid>
		);
	}
}


const mapStateToProps = (state) => {
	return {
		inputs: state.input.inputState,
		func: state.page.popupFunc,
		lang: state.translations.lang,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {

	};
};

export default connect(mapStateToProps, mapDispatchToProps)(container);
