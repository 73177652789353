import React, { Component } from "react";
import PropTypes from "prop-types";

import Translations from "Singleton/Translations";
import { toast } from "react-toastify";

import { Grid, Paper, Typography, Button } from "@material-ui/core";

import styles from "Components/style";
import { ValuationStatus } from "Components/Commons/Const/Valuation";
import OrderStatus from "Components/Commons/Const/OrderStatus";
import PriceBox from "Components/Commons/PriceBox";
import { PriceBoxState } from "Components/Commons/PriceBox";
import { redirrect } from "Actions/pageActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Base64 } from "js-base64";
import RestRequest from "libs/RestRequest";
import przelewy24 from "libs/przelewy24";
import {
  timeFormatInterval,
  timeFormatDate,
  timeFormatDots,
} from "libs/dateformat";
import MediaType from "../../../libs/MediaType";
import PaymentType from "../../Commons/Const/PaymentType";
import { setValue } from "Actions/repositoryActions";

class container extends Component {
  componentDidMount() {
    this.props.setRepositoryValue("priceBoxState", PriceBoxState.COMPLETE_PLN);
  }

  state = {
    isPln: true,
  };

  changeCurrency = () => {
    this.setState({
      ...this.state,
      isPln: !this.state.isPln,
    });
    this.props.setRepositoryValue(
      "priceBoxState",
      !this.state.isPln
        ? PriceBoxState.COMPLETE_PLN
        : PriceBoxState.COMPLETE_EUR
    );
  };

  getPriceBoxStatus = () => {
    if (this.props.isCompany) {
      if (this.state.isPln) {
        return PriceBoxState.COMPLETE_PLN;
      } else {
        return PriceBoxState.COMPLETE_EUR;
      }
    }
    return PriceBoxState.COMPLETE;
  };

  detailTextRow = (title, value) => {
    return (
      <Grid item>
        <Typography variant="body1">
          <b>{title}: </b>
          {value}
        </Typography>
      </Grid>
    );
  };

  isUnloadingDate = (status) => {
    switch (status) {
      case OrderStatus.AWAIT:
      case OrderStatus.IN_PROGRESS:
      case OrderStatus.FORWARD:
      case OrderStatus.FORWARD_AUTO:
      case OrderStatus.SENT_TO_SUPPLIER:
        return true;
      default:
        return false;
    }
  };

  isPreferedUnloadingDate = (status) => {
    switch (status) {
      case OrderStatus.NEW:
        return true;
      default:
        return false;
    }
  };

  isPaidDate = (status) => {
    switch (status) {
      case OrderStatus.IN_PROGRESS:
      case OrderStatus.SENT_TO_SUPPLIER:
      case OrderStatus.FORWARD:
      case OrderStatus.FORWARD_AUTO:
      case OrderStatus.TO_SUPPLY:
      case OrderStatus.CANCELED:
        return true;
      default:
        return false;
    }
  };

  isCanceledDate = (status) => {
    if (status === OrderStatus.CANCELED) {
      return true;
    }
    return false;
  };

  getStyleInfoBox = () => {
    switch (MediaType.type) {
      case MediaType.TABLET:
        return styles.infoBoxTablet;
      case MediaType.MOBILE:
        return styles.infoBoxMobile;
      default:
        return styles.infoBox;
    }
  };

  getOrderDetailsBox() {
    const detailTranslationKey =
      this.props.type === "order" ? "ordered" : "order_date";
    const recivedTranslationKey =
      this.props.type === "order" ? "recived" : "recived_valuation";
    const paidTranslationKey =
      this.props.type === "order" ? "paid" : "paid_valuation";
    return (
      <Paper>
        <Grid style={this.getStyleInfoBox()} container direction="column">
          {this.detailTextRow(
            Translations.get(detailTranslationKey),
            timeFormatDots(this.props.orderData)
          )}

          {this.props.status === ValuationStatus.AWAIT &&
            this.detailTextRow(
              Translations.get(recivedTranslationKey),
              timeFormatDate(this.props.valuationDate)
            )}
          {this.props.status === ValuationStatus.REJECTED &&
            this.detailTextRow(
              Translations.get("rejected_eval"),
              timeFormatDots(this.props.rejectionDate)
            )}
          {this.props.status === ValuationStatus.ACCEPTED &&
            this.detailTextRow(
              Translations.get("accepted"),
              this.props.acceptData
            )}
          {this.props.status === ValuationStatus.OUTDATED &&
            this.detailTextRow(
              Translations.get("outdated"),
              timeFormatDate(this.props.outdatedDate)
            )}
          {this.isPaidDate(this.props.status) &&
            this.detailTextRow(
              Translations.get(paidTranslationKey),
              timeFormatDate(this.props.paymentDate)
            )}
          {this.isUnloadingDate(this.props.status) &&
            this.detailTextRow(
              Translations.get("planned_loading_date"),
              timeFormatInterval(this.props.pickupStart, this.props.pickupEnd)
            )}
          {this.isPreferedUnloadingDate(this.props.status) &&
            this.detailTextRow(
              Translations.get("prefered_loading_date"),
              timeFormatInterval(this.props.pickupStart, this.props.pickupEnd)
            )}
          {this.props.status === OrderStatus.TO_SUPPLY &&
            this.detailTextRow(
              Translations.get("planned_delivery_date"),
              timeFormatDate(this.props.plannedDeliveryDate)
            )}
          {this.isCanceledDate(this.props.status) &&
            this.detailTextRow(
              Translations.get("canceled_ord"),
              timeFormatDate(this.props.cancellationDate)
            )}
        </Grid>
      </Paper>
    );
  }

  getPriceBoxButtons = () => {
    return (
      <Grid
        container
        justify="space-between"
        direction={MediaType.isMobile ? "column" : "row"}
      >
        <Grid item>
          <Button
            variant="outlined"
            fullWidth={MediaType.isMobile}
            style={MediaType.isMobile ? {} : styles.button1}
            onClick={this.props.rejectFunc}
          >
            {Translations.get("resign")}
          </Button>
        </Grid>
        {MediaType.isMobile && (
          <Grid item style={styles.space16Vertical}></Grid>
        )}
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            fullWidth={MediaType.isMobile}
            style={MediaType.isMobile ? {} : styles.button1}
            onClick={this.props.acceptFunc}
          >
            {Translations.get("accept")}
          </Button>
        </Grid>
      </Grid>
    );
  };

  getPriceBox() {
    return (
      <Paper>
        <Grid
          container
          direction="column"
          style={this.getStyleInfoBox()}
          alignItems="center"
        >
          <Grid item>
            <PriceBox
              border={false}
              state={this.getPriceBoxStatus()}
              priceNet={this.props.priceNet}
              priceGross={this.props.priceGross}
              exchangeRate={this.props.exchangeRate}
              onClick={this.changeCurrency}
            />
          </Grid>
          <Grid item style={{ flexGrow: 2 }}></Grid>
          <Grid item style={{ width: "100%" }}>
            {this.getPriceBoxButtons()}
          </Grid>
        </Grid>
      </Paper>
    );
  }

  handleCmrClick = (number) => () => {
    console.log("cmr click", number);
    RestRequest(
      "get",
      "secured/documents",
      { number },
      this.handleCmrResponse,
      this.props.userToken
    );
  };

  handleCmrResponse = (res) => {
    console.log(res);
    if (res.body.error) {
      this.showError(res.body.errorReason);
      return;
    }
    var url = "data:" + res.body.fileMime + ";base64," + res.body.fileData;
    var anchor = document.createElement("a");
    anchor.href = url;
    anchor.setAttribute("download", res.body.fileName);
    anchor.target = "_blank";
    document.body.appendChild(anchor);
    anchor.click();
    anchor.remove();
  };

  getCmrBox() {
    return (
      <Paper
        style={
          MediaType.isTablet
            ? { marginBottom: styles.space30.width }
            : MediaType.isMobile
            ? { marginBottom: styles.space16.width }
            : { marginRight: styles.space30.width }
        }
      >
        <Grid
          container
          direction="column"
          style={this.getStyleInfoBox()}
          alignItems="center"
        >
          <Grid item style={MediaType.isMobile ? { width: "100%" } : {}}>
            {this.props.isDocumentExist && (
              <Button
                variant="outlined"
                fullWidth={MediaType.isMobile}
                onClick={this.handleCmrClick(this.props.number)}
              >
                {Translations.get("get_cmr_documents")}
              </Button>
            )}
          </Grid>
          {this.props.isDocumentExist && (
            <Grid
              item
              style={
                MediaType.isDesktop ? { flexGrow: 2 } : styles.space16Vertical
              }
            ></Grid>
          )}
          <Grid item>
            <Typography variant="body1">
              {Translations.get(
                this.props.isDocumentExist ? "cmr_info" : "cmr_info_2"
              )}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  onClickGoToOrder = () => {
    const number = Base64.encodeURI(this.props.orderSignature);
    const link = `/order_details/order/${number}`;
    this.props.redirrect(link, this.props.history);
  };

  getAcceptBox() {
    return (
      <Paper>
        <Grid
          container
          direction="column"
          style={this.getStyleInfoBox()}
          alignItems="center"
        >
          <Grid item>
            <span style={{ fontWeight: "bold" }}>
              {Translations.get("order")}:{" "}
            </span>
            {this.props.orderSignature}
          </Grid>
          <Grid item style={{ flexGrow: 2 }}></Grid>
          <Grid item>
            <Button variant="outlined" onClick={this.onClickGoToOrder}>
              {Translations.get("go_to_order")}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  handlePayClick = () => {
    console.log(this.props.priceGross);
    console.log(this.props.orderSignature);

    // RestRequest(
    //   "get",
    //   "przelewy24/getAuthData",
    //   {},
    //   (res) => {
    //     const inv = this.props.invoice;
    //     przelewy24(
    //       this.props.number,
    //       Math.floor(this.props.priceGross * 100),
    //       "PLN",
    //       inv.name + " " + inv.surname,
    //       inv.iso,
    //       inv.street,
    //       inv.postcode,
    //       inv.city,
    //       this.props.email,
    //       res.body.crc,
    //       res.body.merchantId,
    //       res.body.address,
    //       false
    //     );
    //   },
    //   this.props.userToken
    // );
  };

  handleDownlnoadInvoiceVat = () => {
    RestRequest(
      "get",
      "secured/invoice/files",
      { number: this.props.number },
      this.handleFileResponse,
      this.props.userToken
    );
  };

  showError = (msg) => {
    toast.error(msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  handleFileResponse = (res) => {
    if (res.body.error) {
      this.showError(res.body.errorReason);
      return;
    }
    console.log("download file", res);
    var url = "data:" + res.body.fileMime + ";base64," + res.body.fileData;
    var anchor = document.createElement("a");
    anchor.href = url;
    anchor.setAttribute("download", res.body.fileName);
    anchor.target = "_blank";
    document.body.appendChild(anchor);
    anchor.click();
    anchor.remove();
  };

  handleInvoiceClick = () => {
    console.log("link", this.props.link);

    document.getElementById("proLink").click();
  };

  getFullPriceBox = () => {
    switch (this.props.status) {
      case OrderStatus.AWAIT:
        return (
          <Paper>
            <Grid
              container
              direction="column"
              style={this.getStyleInfoBox()}
              alignItems="center"
            >
              <Grid item>
                <PriceBox
                  border={false}
                  state={PriceBoxState.COMPLETE}
                  priceNet={this.props.priceNet}
                  priceGross={this.props.priceGross}
                  exchangeRate={this.props.exchangeRate}
                />
              </Grid>

              <Grid item style={{ flexGrow: 2 }}></Grid>

              <Grid
                item
                style={
                  this.props.paymentType === PaymentType.STANDARD
                    ? {}
                    : { width: "100%" }
                }
              >
                <Grid
                  container
                  justify="space-between"
                  direction={MediaType.isMobile ? "column" : "row"}
                >
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={this.handleInvoiceClick}
                      fullWidth={MediaType.isMobile}
                    >
                      {Translations.get("get_invoice_pro")}
                    </Button>
                    <a
                      id="proLink"
                      href={this.props.link}
                      style={{ display: "none" }}
                    />
                  </Grid>
                  {MediaType.isMobile && (
                    <Grid item style={styles.space16Vertical}></Grid>
                  )}
                  {this.props.paymentType !== PaymentType.STANDARD ? (
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth={MediaType.isMobile}
                        onClick={this.handlePayClick}
                      >
                        {Translations.get("online_payment")}
                      </Button>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        );
      case OrderStatus.NEW:
        const isNew = true;
      case OrderStatus.IN_PROGRESS:
      case OrderStatus.FORWARD:
      case OrderStatus.SENT_TO_SUPPLIER:
      case OrderStatus.TO_SUPPLY:
      case OrderStatus.CANCELED:
        return (
          <Paper>
            <Grid
              container
              direction="column"
              style={this.getStyleInfoBox()}
              alignItems="center"
              justify="space-between"
            >
              <Grid item>
                <PriceBox
                  border={false}
                  state={PriceBoxState.COMPLETE}
                  priceNet={this.props.priceNet}
                  priceGross={this.props.priceGross}
                  exchangeRate={this.props.exchangeRate}
                />
              </Grid>
              {!isNew && (
                <Grid item style={MediaType.isMobile ? { width: "100%" } : {}}>
                  <Button
                    variant="outlined"
                    fullWidth={MediaType.isMobile}
                    onClick={this.handleDownlnoadInvoiceVat}
                    disabled={!this.props.isInvoiceExist}
                  >
                    {Translations.get("download_invoice_vat")}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Paper>
        );
      default:
        return "";
    }
  };

  render() {
    if (MediaType.isTablet) {
      return this.renderTablet();
    }
    if (MediaType.isMobile) {
      return this.renderTablet();
      // return '';
    }

    return (
      <Grid
        container
        style={{
          marginTop: styles.space30.width,
        }}
      >
        <Grid item>{this.getOrderDetailsBox()}</Grid>
        <Grid item style={styles.space30}></Grid>
        {this.props.status === "await_accept_eva" && (
          <Grid item>{this.getPriceBox()}</Grid>
        )}

        {this.props.status === "accepted_eval" && (
          <Grid item>{this.getAcceptBox()}</Grid>
        )}

        {(this.props.status === OrderStatus.FORWARD ||
          this.props.status === OrderStatus.TO_SUPPLY) && (
          <Grid item>{this.getCmrBox()}</Grid>
        )}

        {this.getFullPriceBox()}
      </Grid>
    );
  }

  renderTablet() {
    return (
      <Grid
        container
        direction="column"
        style={
          MediaType.isMobile
            ? {
                marginTop: styles.space16.width,
              }
            : {
                marginTop: styles.space30.width,
              }
        }
      >
        <Grid item>{this.getOrderDetailsBox()}</Grid>
        <Grid
          item
          style={
            MediaType.isMobile ? styles.space16Vertical : styles.space30Vertical
          }
        ></Grid>
        {this.props.status === "await_accept_eva" && (
          <Grid item>{this.getPriceBox()}</Grid>
        )}
        {this.props.status === "accepted_eval" && (
          <Grid item>{this.getAcceptBox()}</Grid>
        )}

        {(this.props.status === OrderStatus.FORWARD ||
          this.props.status === OrderStatus.TO_SUPPLY) && (
          <Grid item>{this.getCmrBox()}</Grid>
        )}

        {this.getFullPriceBox()}
      </Grid>
    );
  }
}

container.propTypes = {
  className: PropTypes.string,
  orderData: PropTypes.number,
  priceNet: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  priceGross: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  status: PropTypes.string,
  valuationDate: PropTypes.number,
  shipmentData: PropTypes.string,
  acceptData: PropTypes.string,
  outdatedDate: PropTypes.number,
  orderSignature: PropTypes.string,
  acceptFunc: PropTypes.func,
  rejectFunc: PropTypes.func,
  exchangeRate: PropTypes.number,
  link: PropTypes.string,
  invoice: PropTypes.object,
  rejectionDate: PropTypes.number,
  pickupStart: PropTypes.number,
  pickupEnd: PropTypes.number,
  paymentDate: PropTypes.number,
  plannedDeliveryDate: PropTypes.number,
  cancellationDate: PropTypes.number,
  paymentType: PropTypes.string,
  type: PropTypes.string.isRequired,
  isDocumentExist: PropTypes.bool,
  isInvoiceExist: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    userToken: state.user.token,
    email: state.user.login,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    redirrect: (x, y) => {
      dispatch(redirrect(x, y));
    },
    setRepositoryValue: (x, y) => {
      dispatch(setValue(x, y));
    },
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(container)
);
