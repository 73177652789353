const reducer = (state ={
}, action) => {
	switch (action.type) {
		case "SUPPLY_SET":
		state = {
			...state,
			[action.id]: action.value
		};
		break;
		default:
		break;
	}
	return state;
};

export default reducer;
