import deepForceUpdate from 'react-deep-force-update';

var __instance = null;

class MediaType {
	get DESKTOP(){ return 'desktop'; }
	get TABLET(){ return 'tablet'; }
	get MOBILE(){ return 'mobile'; }

	constructor(){
		this.__ctor = true;
		this.__setType(this.__getWidth());
		window.addEventListener("resize", this.__handleResize, false);
		window.addEventListener("orientationchange", this.__handleOrientationChange)
		this.__ctor = false;
	}

	__getWidth = () => {
		if(window.screen.width < window.innerWidth){
			return window.screen.width
		}else{
			return window.innerWidth
		};
	}

	connect = (object) => {
		this.__root = object;
	}

	static get instance() {
		if(!__instance){
			__instance = new MediaType();
		}
		return __instance;
	}

	__handleResize = () => {
		this.__setType(this.__getWidth());
	}

	__handleOrientationChange =() => {
		setTimeout( () => {
			this.__setType(this.__getWidth());
		},1)
	}

	__setType = (size) => {		
		if(size >= 960){
			this.__setNewType(this.DESKTOP);
		}else if(size >= 600){
			this.__setNewType(this.TABLET);
		}else{//320
			this.__setNewType(this.MOBILE);
		}
	}

	__setNewType = (type) => {
		if(this.__type === type){
			return;
		}
		this.__type = type;
		this.__onTypeChange();
	}

	__onTypeChange = () => {
		if(this.__ctor){
			return;
		}
		if(this.__root){
			deepForceUpdate(this.__root)
		}
	}

	get type() {return this.__type;}
	get isDesktop () {return this.__type === this.DESKTOP;}
  get notDesktop () {return this.__type !== this.DESKTOP;}
	get isTablet () {return this.__type === this.TABLET;}
	get isMobile () {return this.__type === this.MOBILE;}

}

export default MediaType.instance;