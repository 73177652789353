import fetch from 'node-fetch'

export function sendContactEmail(data){

    return {
        type: "SEND_CONTACT_EMAIL",
    };
}

export function sentEmailSuccess(){
    return {
        type: "SEND_EMAIL_SUCCESS"
    };
}

export function send(dispatch, data){

    dispatch(sendContactEmail(data))

    fetch("https://treo.pl:3030/api/send-email", {
        method: 'post',
        body:    JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.json()) // expecting a json response
    .then(json => { console.log(json); dispatch(sentEmailSuccess()) });
    
}