import Translations from 'Singleton/Translations'

class CouponsRadio {
	static useType(){
		return [
			{
				label: Translations.get('coupon_disposable'),
				key: "disposable"
			},{
				label: Translations.get('coupon_reusable'),
				key: "reusable"
			}
		];
	}

	static ammountType(){
		return [
			{
				label: Translations.get('coupon_percentage'),
				key: "percentage"
			},{
					label: Translations.get('coupon_quota'),
				key: "quota"
			}
		];
	}
}

export default CouponsRadio;
