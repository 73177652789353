import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTheme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button';
import AutorenewIcon from '@material-ui/icons/Autorenew'
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';
import Translations from 'Singleton/Translations'
import { connect } from "react-redux";
import MediaType from '../../libs/MediaType';


export class PriceBoxState {
	static get UNCOMPLETE() { return 'uncomplete' }
	static get COMPLETE() { return 'complete' }
	static get COMPLETE_PLN() { return 'complete_pln' }
	static get COMPLETE_EUR() { return 'complete_eur' }
	static get COMPLETE_VOUCHER() { return 'complete_voucher' }
	static get READY() { return 'ready' }
	static get AWAIT() { return 'await' }
	static get CUSTOM() { return 'custom' }
	static get BAD_DIRECTION() { return 'bad_direction' }
	static get MANUAL_ORDER() { return 'manual_order_valuation' }
	static get OVERLOAD_ERROR() { return 'overload_error' }
	static all() {
		return [
			this.UNCOMPLETE,
			this.COMPLETE,
			this.COMPLETE_PLN,
			this.COMPLETE_EUR,
			this.COMPLETE_VOUCHER,
			this.READY,
			this.AWAIT,
			this.CUSTOM,
			this.BAD_DIRECTION,
			this.MANUAL_ORDER,
			this.OVERLOAD_ERROR,
		]
	}
}

class component extends Component {

	styles = {
		component: {
			width: this.props.fullWidth ? '100%' : this.props.width,
			height: this.props.height,
			textAlign: 'center',
			boxShadow: this.props.theme.shadows[0],
			borderRadius: this.props.theme.shape.borderRadius,
			border: this.props.border ? `dotted 2px ${this.props.theme.palette.grey[500]}` : '0',
		},
		greyText: {
			fontSize: this.props.theme.typography.body1.fontSize,
			fontWeight: this.props.theme.typography.body1.fontWeight,
			fontFamily: this.props.theme.typography.body1.fontFamily,
			color: this.props.theme.palette.grey[500],
			lineHeight: `${this.props.height}px`,
		},
		button: {
			height: this.props.height,
		},
		circle: {
			color: this.props.theme.palette.grey[500],
			marginRight: this.props.theme.spacing.unit,
		},
		completeBox: {
			margin: this.props.theme.spacing.unit / 2,
		},
		link: {
			textDecoration: 'underline',
			cursor: 'pointer',
		},
		customBox: {
			marginTop: this.props.theme.spacing.unit / 2,
			padding: '0 50px',
		},
		customBoxMobile: {
			marginTop: this.props.theme.spacing.unit / 2,
			padding: '0 20px',
		},
		textCorrection: {
			marginTop: '-2px',
		}
	}

	onClick = () => {
		this.props.onClick();
	}

	getContent() {
		switch (this.props.state) {
			case PriceBoxState.UNCOMPLETE:
				return (
					<div style={{ ...this.styles.greyText, ...this.styles.textCorrection }}>
						<span>
							{Translations.get('price_appear_here')}...
						</span>
					</div>
				)
			case PriceBoxState.READY:
				return (
					<Button
						fullWidth
						style={this.styles.button}
						color='primary'
						variant='flat'
						onClick={this.onClick}
					>
						<span style={this.styles.textCorrection}>
							<AutorenewIcon />
							{Translations.get('calculate_price')}
						</span>
					</Button>
				)
			case PriceBoxState.AWAIT:
				return (
					<div style={{
						...this.styles.greyText,
						...this.styles.textCorrection,
						position: 'relative'
					}}>
						<CircularProgress size={20} style={{ ...this.styles.circle, position: 'relative', zIndex: 99 }} />
						<div style={{ position: 'absolute', opacity: '.5', top: '2px', left: '0', bottom: '2px', width: (this.props.progress * 100) + '%', background: '#faaf42', transition: 'width .2s ease', borderRadius: '3px' }}></div>
						<span style={{ position: 'relative', zIndex: 99 }}>
							{Translations.get('calculating_price')}...
							{Math.round(this.props.progress * 100)}%
						</span>
					</div>
				)
			case PriceBoxState.COMPLETE:
				return (
					<div style={this.styles.completeBox}>
						<Typography variant='subheading' color='primary' >
							{this.props.priceGross.toFixed(2)} PLN
						</Typography>
						<Typography variant='caption'>
							{this.props.priceNet.toFixed(2)} PLN {Translations.get('net')}, {Translations.get('rate')} {this.props.exchangeRate.toFixed(4)} EUR
						</Typography>
					</div>
				)
			case PriceBoxState.COMPLETE_PLN:
				return (
					<div style={this.styles.completeBox}>
						<Typography variant='subheading' color='primary' >
							{this.props.priceGross.toFixed(2)}
							{' '}
							<span
								style={this.styles.link}
								onClick={this.onClick}
							>PLN</span>
						</Typography>
						<Typography variant='caption'>
							{this.props.priceNet.toFixed(2)} PLN {Translations.get('net')}, {Translations.get('rate')} {this.props.exchangeRate.toFixed(4)} EUR
						</Typography>
					</div>
				)
			case PriceBoxState.COMPLETE_EUR:
				return (
					<div style={this.styles.completeBox}>
						<Typography variant='subheading' color='primary' >
							{(this.props.priceGross / this.props.exchangeRate).toFixed(2)}
							{' '}
							<span
								style={this.styles.link}
								onClick={this.onClick}
							>EUR</span>
						</Typography>
						<Typography variant='caption'>
							{(this.props.priceNet / this.props.exchangeRate).toFixed(2)} EUR {Translations.get('net')}
						</Typography>
					</div>
				)
			case PriceBoxState.COMPLETE_VOUCHER:
				return (
					<div style={this.styles.completeBox}>
						<Typography variant='subheading' color='primary' >
							{this.props.priceGrossBeforeDiscount.toFixed(2)} - {this.props.discount.toFixed(2)} = {this.props.priceGross.toFixed(2)} PLN
						</Typography>
						<Typography variant='caption'>
							{this.props.priceNet.toFixed(2)} PLN {Translations.get('net')}, {Translations.get('rate')} {this.props.exchangeRate.toFixed(4)} EUR
						</Typography>
					</div>
				)
			case PriceBoxState.MANUAL_ORDER:
				return (
					<div style={
						MediaType.isMobile ?
							this.styles.customBoxMobile :
							this.styles.customBox
					}>
						<Typography variant='body1'>
							{Translations.get('manual_order_valuation')}
						</Typography>
					</div>
				)
			case PriceBoxState.BAD_DIRECTION:
				return (
					<div style={
						MediaType.isMobile ?
							this.styles.customBoxMobile :
							this.styles.customBox
					}>
						<Typography variant='body1'>
							{Translations.get('unhandled_direction')}
						</Typography>
					</div>
				)
			case PriceBoxState.CUSTOM:
				return (
					<div style={
						MediaType.isMobile ?
							this.styles.customBoxMobile :
							this.styles.customBox
					}>
						<Typography variant='body1'>
							{Translations.get('custom_palette_info')}
						</Typography>
					</div>
				)
			case PriceBoxState.OVERLOAD_ERROR:
				return (
					<div style={
						MediaType.isMobile ?
							this.styles.customBoxMobile :
							this.styles.customBox
					}>
						<Typography variant='body1' color='error'>
							{Translations.get('overload_error')}
							{' '}{this.props.maxOrderWeight} kg
						</Typography>
					</div>
				)
			default: return '';
		}
	}

	render() {
		//
		return (
			<div style={this.styles.component}>
				{this.getContent()}
			</ div>
		)
	}
}

component.propTypes = {
	fullWidth: PropTypes.bool,
	height: PropTypes.number,
	state: PropTypes.oneOf(PriceBoxState.all()),
	onClick: PropTypes.func,
	progress: PropTypes.number,
	priceNet: PropTypes.number,
	priceGross: PropTypes.number,
	priceGrossBeforeDiscount: PropTypes.number,
	discount: PropTypes.number,
	exchangeRate: PropTypes.number,
	border: PropTypes.bool,
}

component.defaultProps = {
	border: true,
	fullWidth: false,
	height: 54,
	width: 250,
	onClick: () => { },
	priceNet: 0,
	priceGross: 0,
	priceGrossBeforeDiscount: 0,
	progress: 0,
	discount: 0,
	exchangeRate: 0,
	state: PriceBoxState.COMPLETE,
}

const mapStateToProps = (state) => ({
	maxOrderWeight: state.translations.maxOrderWeight,

})

const mapDispatchToProps = (dispatch) => {
	return {
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(component));
