import React from 'react'
import { connect } from "react-redux";
import sha256 from 'sha256';
import { toast } from 'react-toastify';
import { sessionService } from 'redux-react-session';

import RestRequest from 'libs/RestRequest'
import { Typography, Grid, Button } from '@material-ui/core';
import TextInput from 'Components/Commons/TextInput';
import styles from 'Components/style';
import { ERROR } from 'libs/RestRequest'

import { setPopup } from 'Actions/pageActions'

import Translations from 'Singleton/Translations'


class container extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isValidNewPassw: false,
			isSamePassw: false,
			isSecondPasswEmpty: true,
			isForm: true,
			isPasswordChanged: false,
		}
		this.handleResponse = this.handleResponse.bind(this);
		this.getForm = this.getForm.bind(this);
		this.getInfo = this.getInfo.bind(this);
		this.handleErr = this.handleErr.bind(this);
		this.showError = this.showError.bind(this);
		this.logout = this.logout.bind(this);
	}

	cancelClick() {
		this.props.setPopup(undefined);
	}

	acceptClick() {
		var body = {
			newPassword: sha256.x2(this.props.inputs.changePassNew),
			userId: this.props.employeeId,
		}
		RestRequest(
			'post',
			'/secured/employee/password',
			body,
			this.handleResponse,
			this.props.userToken,
		);
	}

	showError(msg) {
		toast.error(msg, {
			position: toast.POSITION.TOP_RIGHT,
		});
	}


	logout() {
		sessionService.deleteSession().then(
			sessionService.deleteUser().then(() => {
				this.props.setLogin(null, null, null, null, null);
				this.props.redirrect('/panel', this.props.history);
			})
		);
	}

	handleErr(body) {
		switch (body.errorCode) {
			case ERROR.USER_TOKEN_IS_NOT_VALID:
				this.showError(Translations.get('session_expired'))
				this.logout();
				break;
			default:
				this.showError(body.errorReason)
		}
	}

	handleResponse(res) {
		console.log('Change email:', res);
		if (res.body.error) {
			this.handleErr(res.body);
		}
		this.setState({
			...this.state,
			isForm: false,
			isPasswordChanged: !res.body.error,
		})
	}

	onNewPasswValidChange(value) {
		this.setState({
			...this.state,
			isValidNewPassw: value,
		})
	}

	onNewPasswSecValidChange(value) {
		this.setState({
			...this.state,
			isSamePassw: value === this.props.inputs.changePassNew,
			isSecondPasswEmpty: value === '',
		})
	}

	getNewPassword() {
		return (
			<Grid container direction='column'>
				<Grid item>
					<TextInput
						id="changePassNew"
						label={Translations.get('new_password')}
						type="password"
						onValidChange={this.onNewPasswValidChange.bind(this)}
					/>
				</Grid>
				<Grid item>
					<TextInput
						id="changePassSecond"
						label={Translations.get('repeat_new_password')}
						type="password"
						onChange={this.onNewPasswSecValidChange.bind(this)}
						errorReason={Translations.get('password_must_matches')}
						isError={!this.state.isSamePassw && !this.state.isSecondPasswEmpty}
					/>
				</Grid>
			</Grid>
		);
	}

	getChoiceButtons() {
		return (
			<Grid container direction='column'>
				<Grid item>
					<Button
						onClick={this.acceptClick.bind(this)}
						disabled={!(this.state.isValidNewPassw && this.state.isSamePassw)}
						fullWidth
						variant='contained'
						color='primary'>
						{Translations.get('change_password')}
					</Button>
				</Grid>

				<Grid item style={styles.space16Vertical}>
				</Grid>

				<Grid item>
					<Button
						onClick={this.cancelClick.bind(this)}
						fullWidth
						variant='outlined'>
						{Translations.get('cancel')}
					</Button>
				</Grid>
			</Grid>
		);
	}

	getForm() {
		return (<Grid container direction='column'>
			<Grid item>
				{this.getNewPassword()}
			</Grid>

			<Grid item style={styles.space16Vertical}>
			</Grid>

			<Grid item>
				{this.getChoiceButtons()}
			</Grid>
		</Grid>
		);
	}

	getInfo() {
		var info = this.state.isPasswordChanged ?
			Translations.get('password_changed')
			:
			Translations.get('something_went_wrong');
		return (
			<Grid container direction='column'>
				<Grid item style={styles.space16Vertical}>
				</Grid>

				<Grid item>
					<Typography align="center" variant="body1">
						{info}
					</Typography>
				</Grid>

				<Grid item style={styles.space16Vertical}>
				</Grid>

				<Grid item>
					<Button
						onClick={this.cancelClick.bind(this)}
						fullWidth
						variant='contained'
						color='primary'>
						OK
					</Button>
				</Grid>
			</Grid>
		);
	}

	render() {
		return (
			<Grid container direction='column'>
				<Grid item>
					{this.state.isForm ?
						this.getForm()
						:
						this.getInfo()
					}
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userToken: state.user.token,
		employeeId: state.repository.employee,
		inputs: state.input.inputState,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (x) => {
			dispatch(setPopup(x));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(container);
