
var __translation_instance = null;

class Translation {

	static get Instance() {
		if(__translation_instance === null){
			__translation_instance = new Translation()
		}
		return __translation_instance;
	}

	constructor() {
		this.redux = null;
		// console.log('private ctor');
	}

	refresh(reduxTranslation){
		this.redux = reduxTranslation;
	}

	show(){
		console.log("Translations:",this.redux);
	}

	get(key){
		if(!this.redux[key]){
			console.warn(`Translation key "${key}" not exist.`);
			return key;
		}
		return this.redux[key];
	}
}

export default Translation.Instance;
