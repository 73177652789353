import React from "react";
// import { connect } from "react-redux";
import { Redirect, Link as RouterLink } from "react-router-dom";

import FAQElement from "../../Components/Body/FAQ/FAQElement";
import Typography from "@material-ui/core/Typography";
import { Grid, Button } from "@material-ui/core";
import styles from "Components/style";
import TextInput from "Components/Commons/TextInput";

import Translations from "Singleton/Translations";
import MediaType from "../../libs/MediaType";
import Link from "Components/Commons/Link";

import {
  MetaData,
  handleMetaData,
} from "../../Components/Commons/Const/MetaData";

const componentStyles = {
  headerImage: {
    backgroundSize: "cover",
    backgroundSize: "cover",
    width: "100%",
    position: "absolute",
    left: "0",
    backgroundPosition: "center",
    marginTop: "4px",
    paddingTop: "35vh",
  },
  headerText: {
    marginTop: "6.5rem",
    marginBottom: "2.5rem",
    paddingTop: "35vh",
  },
  listHeader: {
    marginTop: "2.5rem",
    marginBottom: "1rem",
  },
  listItem: {
    marginTop: "0.5rem",
  },
  breadcrumbsWrapper: {
    display: "flex",
  },
  breadcrumbsSeparator: {
    padding: "0px 5px",
    color: "#bbb",
  },
  breadcrumbs: {
    color: "#bbb",
    textDecoration: "none",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "250px",
    "&:hover": {
      textDecoration: "none",
    },
  },
};

const items = {
  "jak-przyspieszyc-wysylke-towaru-na-palecie-do-twojego-klienta-w-ue": {
    title:
      "Jak przyspieszyć wysyłkę towaru na palecie do Twojego klienta w UE?",
    image: "/images/blog-1.jpg",
    content: `
			<p>Kiedy planujesz wylot samolotem na wakacje z rodziną, używasz porównywarek, które pozwolą Ci wyszukać najbardziej dostępne i najlepsze dla Ciebie oferty różnych linii lotniczych. <strong style="font-weight: bold;">Czy w przypadku wysyłki Twoich produktów za granicę, ten sam sposób nie będzie równie skuteczny?</strong></p>
			<p style="font-weight: bold;">Platforma Treo.pl powstała z myślą o wszystkich, którzy szukają prostego, błyskawicznego sposobu na wycenę  i zlecenie wysyłki palety z produktami do klientów w UE. Na Treo.pl wszystko możesz zrealizować w 100% online, automatycznie, bez wykonywania setek telefonów i żmudnego poszukiwania ofert u przewoźników.</p>
			<p style="font-weight: bold;">Niezależnie od tego jaki produkt eksportujesz do swoich odbiorców za granicą – ostatni krok, czyli dostarczenie przesyłki do klienta – bez zabezpieczenia błyskawicznego transportu, może spowolnić cały proces Twojej sprzedaży. </p>
			<p>Moment rozpoczęcia wysyłki ma kluczowe znaczenie zwłaszcza w branży e-commerce, w której czas realizacji dostaw to albo kolosalna przewaga konkurencyjna albo… jej brak. Zasada „kto pierwszy ten lepszy” w branży e-handlu sprawdza się bardzo wyraźnie. I często też bardzo dotkliwie w zetknięciu z szybszą i sprawniejszą konkurencją ze strony e-commercowych gigantów. </p>
			<p>Co zrobić kiedy skala działalności nie pozwala jeszcze Twojej firmie na wynajem własnego transportu, a stałe umowy z przewoźnikami byłyby dla Ciebie zwyczajnie nieopłacalne? Prawdopodobnie najbardziej optymalnym sposobem dostarczania produktów do Twoich klientów jest wysłanie przesyłki paletowej. No tak…Tyle, że tu często pojawia się problem – np. przesyłka ponadgabarytowa, której nie chce przyjąć kurier lub odmowy wysłania „od ręki” detalicznej przesyłki na palecie przez duże firmy transportowe. Za każdym razem poszukujesz więc ofert wysyłki i zlecenia towaru na dziesiątkach stron internetowych. Dzwonisz, wysyłasz zapytania, a czas biegnie nieubłaganie …</p>
			<p>Mozolne porównywanie ofert, kiedy transport palety z towarem za granicę trzeba zlecić „tu i teraz” jest uciążliwe; spowalnia działania wielu polskich e-sklepów. Zwłaszcza w sektorze małych i średnich polskich przedsiębiorstw. </p>
			<h3>Jak działa Treo.pl?</h3>
			<p>Platformę Treo stworzyliśmy bazując na doświadczeniach wynikających z naszej wieloletniej współpracy zarówno z branżą transportową, jak również z dostawcami produktów i handlowcami. <strong>Celem Treo jest przyspieszenie realizacji dostaw w handlu eksportowym i importowym z klientami z krajów UE firmom z sektora MSP, a co za tym idzie –  zwiększenie ich dynamiki sprzedaży. Platforma działa w oparciu o aktualizowane na bieżąco bazy ofertowe udostępniane przez renomowanych przewoźników – Partnerów Treo.pl. </strong>Wystarczy, że poświęcisz kilkanaście sekund na wprowadzenie w naszym kalkulatorze wymiarów swojej przesyłki paletowej, wpiszesz dane dotyczące miejsca dostarczenia, a system w kilkanaście sekund przygotuje Ci gotową wycenę najbardziej dostępnego, bezpiecznego transportu na wskazanej przez Ciebie trasie - <strong>z dowolnego miejsca w Polsce do dowolnego miejsca w UE. </strong></p>
			<p>Wprowadzenie automatycznej wyceny znacznie skraca czas rozpoczęcia realizacji wysyłek, a co za tym idzie dostarczenia towaru do odbiorcy. Stawiamy na prostotę i łatwość użytkowania Treo.pl. Transport to klucz do skutecznej realizacji dostaw – musi być realizowany szybko i pewnie. </p>
			<p><strong>Natychmiastowa wycena i zlecenie wysyłki palet w transporcie drobnicowym może wspierać Twoją firmę w codziennym funkcjonowaniu. </strong> Pozwala małym i średnim firmom e-commerce na niemal natychmiastowe rozpoczęcie dostarczenia towaru do klienta w Europie. </p>
			<ul><li>Na Treo.pl wycena realizowana jest <strong>w 100% online, w kilkanaście sekund;</strong>
			<li>Serwis nie wymaga logowania na etapie przygotowania wyceny;
			<li>Wizyta na Treo.pl kończy się zatwierdzeniem <strong>wysyłki towaru na palecie pod dowolny adres 
			na terenie wybranego kraju UE.</strong> </li></ul>
		`,
  },
  "dlaczego-warto-zautomatyzowac-transport-paletowy-w-sklepie-e-commerce": {
    title:
      "Dlaczego warto zautomatyzować transport paletowy w sklepie e-commerce?",
    image: "/images/blog-2.jpg",
    content: `
			<p><strong>Prowadzisz mały sklep internetowy, ale wysyłasz lub chcesz zacząć wysyłać swoje produkty 
			za granicę? Polski rynek e-commerce rośnie w tempie 18% rocznie. Dwucyfrowa dynamika rozwoju e-handlu wymusza na firmach ciągłe optymalizowanie i przyspieszanie procesów. Kluczowe dla przewagi konkurencyjnej są szybkość realizacji zamówień i skracanie czasu dostaw. Firmy e-commerce wygrywają tę rywalizację albo sprawnym zapleczem magazynowo-logistycznym albo skuteczną automatyzacją transportu. </strong></p>
			<p>Na to pierwsze mogą pozwolić sobie duże, często międzynarodowe sieci e-commerce. Łatwo znajdą operatorów logistycznych i wejdą w stałą współpracę magazynowo-transportową. <strong>Mniejsze serwisy e-commerce</strong> o swój kawałek rynku muszą zawalczyć <strong>zautomatyzowaniem dostaw.</strong></p>
			<p><strong>Automatyczna wycenia i zlecanie transportu</strong> to krótszy czas dostarczenia Twojego towaru 
			do klienta końcowego bez potrzeby inwestowania znacznych nakładów we własny transport czy kosztowne umowy z przewoźnikami. Nie mogą sobie na nie pozwolić przede wszystkim firmy z sektora MSP. Pomimo, że małe i średnie polskie e-sklepy z powodzeniem znajdują swoje <strong>nisze w handlu online</strong> – także na <strong>europejskim rynku e-commerce</strong> – dynamiczna obsługa wysyłek wciąż stanowi dla nich wyzwanie. </p>
			<h3>Jak zwiększyć sprzedaż w handlu online? </h3>
			<p><strong>Polski rynek e-commerce</strong> jest niezwykle dynamiczny. Rok 2018 zakończył się wynikiem powyżej 50 mld PLN, co jeszcze kilka lat temu wydawało się odległą perspektywą. Rok 2019 przyniósł dalszy skok, a według ekspertów trend wzrostowy raczej się nie zmieni i w ciągu najbliższych kilku lat <strong>wartość polskiego e-commerce</strong> może sięgnąć nawet 80 mld PLN. Prognoza ta wiąże się m.in. ze wskaźnikiem udziału sprzedaży on-line w całości handlu detalicznego w Polsce. Według danych Eurostatu - w porównaniu do innych krajów Europy Zachodniej czy choćby unijnej średniej – jest on wciąż niski – to ok. 4.3% wszystkich operacji w polskim handlu detalicznym. Odsetek jest o połowę niższy od średniej europejskiej i globalnej.</p>
			<p>Branża wciąż ma więc ogromne pole do rozwoju, a walczyć zdecydowanie jest o co. Sprzedawcy będą musieli jeszcze mocniej dostosować się do potrzeb konsumenta i szukać takich usprawnień w modelu biznesowym, które dadzą im <strong>przewagę konkurencyjną na rynku e-handlu.</strong> Dotyczy to przede wszystkim logistyki, szybkości reakcji i <strong>skracania czasu dostaw – bez zwiększania nakładów na inwestycje,</strong> na które przecież nie wszyscy mogą sobie pozwolić. </p>
			<p>Tylko w ciągu ostatniego roku w Polsce powstało ponad 1,8 tys. nowych sklepów 
			e-commerce. Mogą one rozwijać się szybciej także na rynkach Unii Europejskiej i tam zwiększać sprzedaż. Tym, co je blokuje jest brak dostępu do błyskawicznego, automatycznego zlecania transportu, który byłby dostosowany do skali ich działań. Nietrudno zlecić dziś szybko paczkę kurierską czy nawet zamówić transport całego tira towaru. Obie te kategorie nie 
			są jednak rozwiązaniem dla firm małych i średnich. Dla wielu polskich e-sklepów z sektora MSP optymalny ilościowo jest detaliczny transport paletowy. Aby mógł być skuteczny jego zlecanie powinno odbywać się w sposób dynamiczny – bez samodzielnego pozyskiwania ofert 
			od przewoźników z rynku, bo to po prostu zabiera czas. </p>
			<h3>Przedsiębiorstwa działające w e-commerce potrzebują skrócenia czasu dostaw</h3>
			<p>Szybko rosnąca popularność e-handlu i oczekiwania klientów – skupione głównie na jak najszybszym czasie dostawy – napędzają branżę logistyczną i rozwój nowych usług, które pozwoliłyby firmom wyróżnić się na coraz bardziej konkurencyjnym rynku. Zwłaszcza dla mniejszych firm, dla których poważnym wyzwaniem jest konkurowanie z dużymi 
			e-commerce’owymi platformami, inwestycje w obsługę logistyczną i skrócenie czasu realizacji transportu mogą stanowić o ich przewadze.</p>
			<h3>Na czym polega rozwiązanie wprowadzone na Treo.pl? </h3>
			<p>System dedykowany jest tym polskim e-sklepom, którym automatyczne wyceny transportu paletowego mogą pomóc zwiększyć liczbę i częstotliwość realizowanych dostaw, ułatwić wysyłkę towarów za granicę i zwiększyć ilości wysyłanych produktów. To wszystko bez wzrostu kosztów i zatrudniania nowych pracowników, którzy musieliby zadbać o całość procesu logistyczno-transportowego. </p>
			<h3>Treo.pl to natychmiastowa wycena drobnicowego transportu paletowego (importowego i eksportowego) po krajach całej UE!</h3>
			<ul><li>Dostępna w 100% online</li>
			<li>Szansa na zwiększenie dynamiki dostaw towarów wytwarzanych przez polskie firmy z MSP</li>
			<li>Działa w oparciu o aktualizowane na bieżąco bazy ofertowe udostępniane przez
			renomowanych przewoźników</li>
			<li>Umożliwia zlecenie spersonalizowanej wyceny transportu niestandardowego, ponadgabarytowego. </li></ul>
		`,
  },
  "jak-blyskawicznie-wyslac-palete-towaru-do-niemiec": {
    title: "Jak błyskawicznie wysłać paletę towaru do Niemiec?",
    image: "/images/blog-3.jpg",
    content: `
			<p><strong>Sprawdźmy na przykładzie Niemiec, jakie szanse na rozszerzenie sprzedaży na rynki 
			UE małym i średnim e-sklepom może dać automatyczne zlecanie drobnicowego transportu na paletach.</strong> </p>
			<p>W 2019 roku, według danych Polskiego Instytutu Ekonomicznego, Polska uplasowała się 
			na 4. miejscu jako największy unijny eksporter do Niemiec. Według danych GUS – 
			w ubiegłym roku wartość eksportowanych przez nas towarów za zachodnią granicę sięgnęła 264 mld złotych. <strong>Rynek niemiecki to wciąż numer jeden dla polskich przedsiębiorców eksportujących za granicę. </strong></p>
			<p><strong>Nasi sąsiedzi uznawani są także za jeden z topowych rynków e-commerce. I rzeczywiście – niemiecki rynek e-handlu to obok Wielkiej Brytanii najczęstszy kierunek zagranicznej ekspansji polskich e-sklepów. To, czy nasz produkt trafi do klienta końcowego za Odrą, czy zainteresuje się nim lokalny serwis e-commerce, aby zaoferować go swoim rodzimym odbiorcom – zależy m.in. od tempa i jakości transportu towarów sprzedawanych przez polskie e-sklepy.</strong> Czy  łatwo jednak wykorzystać potencjał współpracy z klientami z rynku niemieckiego? </p>
			<p>Niestety, małe i średnie sklepy e-commerce w sprzedaży zarówno na rynek niemiecki, jak 
			i pozostałe rynki UE są spowalniane m.in. przez utrudnioną realizację dostaw. Sięgnięcie 
			po kawałek eksportowego tortu w e-handlu choćby do Niemiec, gdy nie ma się własnego zaplecza transportowego – nie jest łatwe. Samodzielna wycena międzynarodowego transportu paletowego bywa kilkudniowym, żmudnym procesem, który pochłania czas. Poszukiwanie cenników na stronach przewoźników, czekanie na maile od konsultantów z firm przewozowych, sprawdzanie wiarygodności – to wszystko zabiera czas, cierpliwość i potencjał w starciu z konkurencją…  </p>
			<p><strong>Jeśli czujesz, że mógłbyś działać sprawniej mając do dyspozycji aktualne wyceny na transport paletowy w jednym miejscu, to pierwszy sygnał, żeby poszukać rozwiązania i zwiększyć wydajność sprzedaży. </strong></p>
			<p><strong>System Treo.pl sam porównuje i wybiera ofertę najkorzystniejszą ze względu na czas i cenę. Wszystko dzieje się w jednym miejscu na naszej platformie; w 100% online. Transport palety można zlecić w kilkanaście sekund po krajach całej UE.</strong> Treo.pl jest pierwszym w Polsce narzędziem online, dzięki któremu <strong>w kilka minut zlecimy transport towaru na palecie 
			do Niemiec, Francji czy Hiszpanii. </strong></p>
			<ul><li>Platforma Treo.pl obsługuje wszystkie kraje UE. </li>
			<li><strong>Przesyłki paletowe w handlu drobnicowym</strong> zlecimy na Treo również do europejskich krajów celnych – Szwajcarii i Norwegii. </li>
			<li><strong>W 100% online</strong>, w jednym miejscu, z użyciem funkcjonalnego kalkulatora.</li>
			<li><strong>Uzyskanie wyceny nie wymaga logowania.</strong> </li><ul>


			<p>To nowe usprawnienie dystrybucji towarów dla polskiego sektora MSP – zwłaszcza dla takich branż jak e-commerce, branża meblowa, branża budowlano-remontowa czy detalling, gdzie transport paletowy decyduje często o skuteczności sprzedaży. Właściciele serwisów 
			e-commerce, producenci czy hurtownicy nie muszą już szukać ofert transportu w kilku źródłach i zastanawiać się, która z nich najlepiej spełnia wszystkie wymagania. Nie muszą także dodatkowo weryfikować kwestii formalnych – np. czy dany przewoźnik posiada odpowiednie uprawnienia do transportu poza granicami Polski. Po wprowadzeniu podstawowych danych dotyczących przesyłki i kilku kliknięciach robi to za nich automatycznie Treo.pl. </p>
			<p>Po zaakceptowaniu wyceny przedsiębiorca zleca transport w systemie, realizuje płatność online lub tradycyjnym przelewem, a potem czeka już tylko na odbiór towaru i dostarczenie do klienta w Niemczech lub na dowolnym unijnym rynku. </p>
		`,
  },
  "eksportujesz-meble-automatyzacja-w-transporcie-paletowym-moze-zwiekszyc-twoja-sprzedaz-na-rynkach-ue": {
    title:
      "Eksportujesz meble? Automatyzacja w transporcie paletowym może zwiększyć Twoją sprzedaż na rynkach UE",
    image: "/images/blog-4.jpg",
    content: `
			<p><strong>Producenci  polskich mebli mogą znacznie uprościć sobie transport produktów za granicę. Poprzez nasz serwis Treo.pl – do natychmiastowej wyceny i automatycznego zlecania detalicznej wysyłki palet – transport w kilka minut można zlecić do wszystkich krajów UE oraz Szwajcarii i Norwegii. Cała operacja odbywa się w 100% online. Celem serwisu jest ułatwienie wysyłki towarów na paletach m.in. producentom polskich mebli z sektora MSP. Czy takie rozwiązanie przyniesie korzyści branży meblarskiej?</strong></p>
			<p>Meble to jeden z najbardziej rozpoznawalnych polskich towarów eksportowych. Z wynikiem 10,6 mld euro, <strong>Polska jest 3. eksporterem mebli na świecie.</strong> Z jednej strony dysponujemy dogodnym położeniem wobec chłonnych, rozwiniętych rynków Europy Zachodniej oraz długą tradycją relacji handlowych, z drugiej zasobami surowca naturalnego, jakim jest drewno 
			i wyspecjalizowaną kadrą specjalistów. </p>
			<p>Co zaskakujące, pomimo bardzo dobrych wyników i zadowalających prognoz – eksperci zwracają uwagę na spadek rentowności polskich producentów mebli. Wskazuje się 
			na konieczne zmiany w modelu biznesowym, które zoptymalizują procesy operacyjne oraz pozwolą w większym stopniu dostosować oferty do rosnących oczekiwań zagranicznych klientów – zwłaszcza w zakresie cen mebli i <strong>skrócenia czasu dostaw.</strong></p>
			<h3>Liczebnie dominują producenci z sektora MSP </h3>
			<p><strong>Przeważająca liczba producentów mebli w Polsce to firmy mikro, małe i średnie. </strong>Wiele z nich wciąż ma duży potencjał na zwiększenie sprzedaży na rynki UE. <strong>Przeszkodą często jest jednak… transport.</strong> Przesyłki zlecane do krajów UE producenci mebli z MSP muszą dostosować do skali i wielkości otrzymywanych zamówień. I tu pojawia się, problem… Designerskiej szafki czy rzeźbionej kołyski właściciele firm i sklepów meblowych nie wyślą przecież zwykłą przesyłką kurierską. Z drugiej strony transport całej ciężarówki załadowanej towarem i stałe umowy z przewoźnikami to dla nich wciąż za dużo. Optymalnym w tej sytuacji rozwiązaniem jest <strong>transport mebli na paletach.</strong> Pozyskanie ofert na <strong>wysyłkę towaru w drobnicowym handlu paletowym</strong> zajmuje jednak czas, a unijni odbiorcy oczekują przyspieszenia dostaw.</p>
			<h3>Automatyczna wysyłka palet z natychmiastową wyceną </h3>
			<p>Internetowa platforma Treo.pl – <strong>do natychmiastowej wyceny i automatycznego zlecania drobnicowego transportu paletowego</strong>, w kilkanaście sekund wykona za nas samodzielnie porównanie ofert, określi koszt i czas dostawy, a kliknięcie przycisku akceptacji <strong>zatwierdzi wysyłkę mebli do klienta w Europie.</strong> </p>
			<p>Branża meblarska dzięki <strong>automatycznemu zlecaniu detalicznych przesyłek paletowych</strong> może znacznie uprościć dostarczanie produktów do klientów zlokalizowanych w różnych krajach 
			i regionach Europy. <strong>Co ważne – w branży meblarskiej, na specjalne zapytania Zespół Treo wycenia także przesyłki ponadgabarytowe. Bo przecież rozmiary sprzedawanych mebli 
			są dostosowane do potrzeb i upodobań zagranicznych klientów, a nie wytycznych dla standardowych przesyłek przewoźników.</strong> </p>
			<p>Szybki i bezpieczny transport diametralnie wpływa na skrócenie czasu dostaw i optymalizację działań operacyjnych branży meblarskiej. Zwłaszcza dla małych i średnich firm, które liczebnie dominują wśród polskich producentów mebli, automatyczne zlecanie transportu na zewnątrz może być sposobem na przyspieszenie procesów sprzedażowych. </p>
			<p><strong>Na Treo.pl cały proces wyceny i wysyłki mebli polscy producenci mogą skrócić do kilku minut.</strong> A to już ma znaczenie <strong>dla szybkości dostaw, ilości realizowanych zamówień i zwiększenia sprzedaży.</strong> Automatyczne zlecanie zewnętrznego transportu ma bezpośredni wpływ na koszty operacji realizowanych przez producentów mebli – oszczędzamy nakłady czasu pracowników i inwestycje  w długoterminowe umowy z przewoźnikami. </p>
		`,
  },
  "dla-jakich-branz-automatyczne-zlecanie-przesylek-paletowych": {
    title: "Dla jakich branż automatyczne zlecanie przesyłek paletowych?",
    image: "/images/blog-5.jpg",
    content: `
			<p><strong>Wysyłka palety z meblami do klienta w Niemczech; sprowadzenie sprzętu komputerowego 
			do Polski; transport ponadgabarytowych artykułów dziecięcych do Holandii… Sprzedaż produktów przez Internet umożliwia polskim e-sklepom działanie na międzynarodowym rynku. Na zwiększenie zysku dzięki klientom z Unii mogą liczyć dziś też małe i średnie sklepy e-commerce. Te jednak często wskazują na przeszkody w transporcie towarów 
			za granicę. Stała współpraca z przewoźnikiem kosztuje, a szukanie ofert zajmuje czas….</strong>
			<h3>Komu automatyzacja zlecania transportu towarów na paletach może ułatwić dotarcie 
			do klientów w UE?</h3>
			<p>Poszukiwanie ofert transportu dostosowanych każdorazowo do innego kierunku i rodzaju przesyłek to dla firm duża strata czasu, który można przecież przeznaczyć choćby na kontakt z klientem i rozwijanie sprzedaży. <strong>Innowacyjne podejście do zlecania transportu, szybkość, 
			a jednocześnie prostota i łatwość obsługi kalkulatora Treo.pl mają ułatwić dystrybucję towarów przedsiębiorstwom z polskiego sektora MSP.</strong> Największy potencjał na usprawnianie procesów założyciele Treo.pl widzą w takich <strong>branżach jak e-commerce, branża budowlano-remontowa, meblowa, detalling czy polska branża parentingowa</strong> (akcesoria dziecięce 
			od polskich producentów cieszą się dużą popularnością za granicą).</p>
			<h3>Co zrobić kiedy nie stać Cię na stałą współpracę z przewoźnikiem? </h3>
			<p>Małe i średnie przedsiębiorstwa muszą wysyłać ładunki bezpośrednio do swoich klientów. 
			Im trudno jest znaleźć dostosowane do potrzeb i rozmiarów oferty na transport. Muszą mozolnie zbierać oferty z rynku, czyli po prostu wysyłać do firm transportowych zapytania o wycenę i terminy. Często nie uzyskują odpowiedzi w ogóle albo muszą na nią bardzo długo czekać. </p>
			<p><strong>Drobnicowy transport paletowy</strong> to w pewnym sensie trzecia droga dla tych, którzy nie mogą sobie jeszcze pozwolić na stałą współpracę z dużym przewoźnikiem, ale jednocześnie pojedyncze przesyłki kurierskie nie wystarczają już na obsługę większych zamówień czy wysyłkę całej partii towaru za granicę. </p>
			<p><strong>Automatyczne wyceny i zlecanie transportu na palecie</strong> pozwalają <strong>zdynamizować sprzedaż 
			i otworzyć się na nowe rynki.</strong> Jest to też ciekawe rozwiązanie dla e-sklepów, które mają już pomysł i zaplecze produktowe, ale <strong>dystrybucja towarów w handlu międzynarodowym</strong> zajmuje im wciąż za dużo czasu lub po prostu nie mają w swoich zespołach pracowników, którzy mogliby skupić się wyłącznie na koordynowaniu <strong>wysyłek towaru do innych krajów UE.</strong> </p>
			<p><strong>Treo.pl może więc zachęcić do rozwoju te firmy, które ze względu na trudności z szybkim znalezieniem transportu wciąż obawiają się np. udostępnienia klientom możliwości zakupu większych ilości towaru za pośrednictwem stron internetowych. </strong></p>
			<p><strong>System Treo.pl dedykowany jest tym polskim e-sklepom, którym automatyczne wyceny transportu paletowego mogą pomóc zwiększyć liczbę i częstotliwość realizowanych dostaw, ułatwić wysyłkę towarów za granicę i zwiększyć ilości wysyłanych produktów.</strong> </p>
			<p><strong>Co ważne, Treo.pl pozwala również na dynamiczną i łatwą wysyłkę produktów standardowo nieobsługiwanych przez kurierów. Dotyczy to choćby przesyłek ponadgabarytowych, 
			np. cenionych w Europie polskich mebli czy coraz bardziej znanych akcesoriów dziecięcych, zamawianych przez klientów z krajów unijnych.</strong> Zespół Treo.pl odpowiada na niestandardowe potrzeby transportowe producentów z branży parentingowej i meblowej.</p>
			<p>To innowacyjne podejście do efektywnego zlecania transportu ma stanowić wsparcie dla branż, w których transport paletowy jest głównym sposobem realizacji dostaw towarów. Stanowi realne usprawnienie i wzrost skuteczności procesów operacyjnych w sektorach  
			e-commerce, meblarskim, budowlano-remontowym, detallingu czy w branży parentingowej. <strong>Automatyzacja gwarantuje przedsiębiorcom i sprzedawcom znaczne skrócenie czasu oczekiwania na wycenę transportu i umożliwia natychmiastowe rozpoczęcie realizacji dostaw. </strong></p>
		`,
  },
};

export class Blog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      current:
        props.match.params.slug != undefined ? props.match.params.slug : null,
    };
  }

  tab = [];
  //jak-przyspieszyc-wysylke-towaru-na-palecie-do-twojego-klienta-w-ue

  componentWillMount() {
    document.title = MetaData.blog.title;
    handleMetaData();
  }

  getNextItemSlug() {
    let keys = Object.keys(items);
    let currentIndex = keys.indexOf(this.state.current);

    return keys[keys[currentIndex + 1] !== undefined ? currentIndex + 1 : 0];
  }

  getList() {
    let itemList = Object.keys(items).map(function (key, idx) {
      return (
        <Grid
          key={key}
          md={4}
          id={"blog-post-" + idx}
          item
          style={componentStyles.listItem}
        >
          <Link
            to={"/blog/" + key}
            text={
              <div>
                <div>
                  <img
                    src={items[key].image}
                    alt={items[key].title}
                    style={{
                      width: "100%",
                      height: "250px",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <Typography>{items[key].title}</Typography>
              </div>
            }
          />
        </Grid>
      );
    });

    return (
      <div style={{ paddingBottom: "50px" }}>
        <Grid
          container
          direction="row"
          spacing={32}
          style={MediaType.isMobile ? styles.mobileContainer : styles.container}
        >
          <Grid xs={12} item>
            <Typography
              component={"h1"}
              variant="display1"
              style={componentStyles.listHeader}
            >
              Blog
            </Typography>
          </Grid>
          {itemList}
        </Grid>
      </div>
    );
  }

  getItem() {
    return this.checkIfNot404() ? (
      items[this.state.current] === undefined ? null : (
        <Grid
          container
          direction="column"
          style={MediaType.isMobile ? styles.mobileContainer : styles.container}
        >
          <Grid item>
            <div
              style={Object.assign(
                { backgroundImage: `url(${items[this.state.current].image})` },
                componentStyles.headerImage
              )}
            ></div>
            <Typography
              component={"h2"}
              variant="display1"
              style={componentStyles.headerText}
            >
              <Typography
                component={"div"}
                style={componentStyles.breadcrumbsWrapper}
              >
                <Link
                  to={"/blog"}
                  text={<p style={componentStyles.breadcrumbs}>Blog</p>}
                />
                <span style={componentStyles.breadcrumbsSeparator}> / </span>
                <Link
                  to={"/blog/" + this.state.current}
                  text={
                    <p style={componentStyles.breadcrumbs}>
                      {items[this.state.current].title}
                    </p>
                  }
                />
              </Typography>
              {items[this.state.current].title}
            </Typography>
            <Typography
              component={"div"}
              dangerouslySetInnerHTML={{
                __html: items[this.state.current].content,
              }}
            ></Typography>
            <br />
            <br />
            <Grid container>
              <Grid xs={12} item align="center">
                <Button href={"/blog/" + this.getNextItemSlug()}>
                  Następny artykuł
                </Button>
              </Grid>
            </Grid>
            <br />
            <br />
          </Grid>
        </Grid>
      )
    ) : (
      <Redirect to="/error404" />
    );
  }

  checkIfNot404() {
    const path = window.location.pathname.substring(6);
    const links = [];
    Object.entries(items).forEach(([key]) => links.push(key));
    return links.includes(path);
  }

  render() {
    return <div>{this.state.current ? this.getItem() : this.getList()}</div>;
  }
}

//<Typography variant='display1'>
//							Blog
//						</Typography>

// const mapStateToProps = (state) => {
// 	return {
// 		translations: state.translations
// 	};
// };

// const mapDispatchToProps = (dispatch) => {
// 	return {
// 	};
// };

// export default connect(mapStateToProps, mapDispatchToProps)(FAQ);
export default Blog;
