
import MediaType from 'libs/MediaType'


const palette = {
	red: 'rgb(234, 67, 53)',
	green: '#8BC34A',
	yellow: '#faaf42',
	blue: 'rgb(54, 88, 153)'
}


const space30 = {
	width: '30px',
}

const paperBox = {
	padding: '30px',
	marginBottom: '30px',
	width: '100%',
}

const paperBoxMobile = {
	width: '100%',
	marginBottom: '15px',
	padding: '15px',
}

const tableColumnBase = {
	paddingLeft: '0px',
	paddingRight: '0px',
}

const space8Vertical = {
	height: '8px',
}

const space16Vertical = {
	height: '16px',
}

const space16 = {
	width: space16Vertical.height
}

const styles = {
	rootWeb: {
		maxWidth: '1280px',
		width: '100%',
		margin: '0 auto',
		boxSizing: 'border-box'
	},
	rootTablet: {
		width: '100%',
		margin: '0 auto',
		boxSizing: 'border-box'
	},
	rootMobile: {
		width: '100%',
		boxSizing: 'border-box'
	},
	mobileBox: {
		width: '100%',
	},
	palette,
	container: {
		padding: '0 45px',
	},
	tableContainer: {
		padding: '45px',
	},
	mobileContainer: {
		padding: space16.width,
	},
	link: {
		textDecoration: 'underline',
		cursor: 'pointer',
		color: 'inherit',
	},
	paperBox,
	paperBoxMobile,
	moreInfoBox: {
		...paperBox,
		width: '1080px',
	},
	settingColumn1: {
		width: '285px'
	},
	moreInfoColumn1: {
		width: '265px'
	},
	moreInfoColumn2: {
		width: '400px'
	},
	moreInfoColumn3: {
		width: '560px'
	},
	moreInfoColumn4: {
		width: '294px',
	},
	moreInfoBoxTablet: {
		...paperBox,
		width: '678px',
	},
	moreInfoBoxMobile: {
		...paperBox,
		width: '100%',
		padding: '15px',
	},
	space30Vertical: {
		height: space30.width,
	},
	space16Vertical,
	space8Vertical,
	space16,
	space30,
	space60: {
		width: '60px',
	},
	column: {
		width: '660px',
		padding: `${space30.width} 0`,
	},
	columnTablet: {
		width: '100%',
		padding: `${space30.width} 0`,
	},
	columnMobile: {
		width: '100%',
	},
	infoBox: {
		padding: space30.width,
		width: '430px',
		height: '163px',
	},
	infoBoxTablet: {
		padding: space30.width,
		width: '100%',
	},
	infoBoxMobile: {
		padding: space16.width,
		width: '100%',
	},
	button1: {
		width: '135px',
	},
	input1: {
		width: '160px',
	},
	imageColumn: {
		width: '70px',
		textAlign: 'center'
	},
	imageColumnMobile: {
		width: '30px',
		textAlign: 'center'
	},
	image1: {
		width: '30px',
		height: '30px',
	},
	image2: {
		width: '16px',
		height: '16px',
	},
	image3: {
		width: '24px',
		height: '24px',
	},
	image1Mobile: {
		width: '24px',
		height: '24px',
	},
	image2Mobile: {
		width: '10px',
		height: '10px',
	},
	tableColumnBase,
	tableColumn0: {
		...tableColumnBase,
		width: '96px',
	},
	tableColumn1: {
		...tableColumnBase,
		width: '126px',
	},
	tableColumn2: {
		...tableColumnBase,
		width: '170px',
	},
	tableColumn3: {
		...tableColumnBase,
		width: '252px',
	},
	tableColumn4: {
		...tableColumnBase,
		width: '378px',
	},

	tableIcon: {
		fontSize: '16px',
		cursor: 'pointer',
	},
	detailColumn1: {
		width: '180px'
	},
	breakWord: {
		wordWrap: 'break-word',
	},
	popupContainerMobile: {
		backgroundColor: 'rgb(255, 255, 255)',
		width: '100vw',
		height: '100%',
		position: 'fixed',
		zIndex: '1101',
		left: 0,
		top: 0,
	},
	popupContainer: {
		position: 'fixed',
		left: 0,
		top: MediaType.isDesktop ? 0 : 50,
		width: '100vw',
		height: '100%',
		zIndex: '400',
	},
	flatPopupContainer: {
		margin: '0 auto'
	},
	popupColumn1: {
		width: '214px',
	},
	popupColumn2: {
		width: '199px',
	},
	messageColumn: {
		width: '550px',
	},
	popupBox: {
		padding: space16Vertical.height,
		margin: '100px auto 0',
		width: '460px',
		position: 'relative',
	},
	popupBoxMobile: {
		padding: space16Vertical.height,
		width: '100%',
		height: '100%',
		position: 'relative',
	},
	sticker: {
		maxWidth: '85px',
	},
	loadingCenter: {
		textAlign: 'center',
	},
	priceBoxColumn: {
		width: '120px',
	},
}

export default styles;
