import React from 'react';

import Checkbox from './../../../Components/Commons/Checkbox'

import PropTypes from 'prop-types'
import Translations from 'Singleton/Translations'
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styles from '../../style';

//Akceptuję regulamin i politykę prywatności Treo.



const createConsentLabel = () => {
	return (
		<div>
			{Translations.get('consent_regulations_1')}{' '}
			<Link
				style={styles.link}
				target='_blank'
				to="/regulations"
			>
				{Translations.get('consent_regulations_2')}
			</Link>{' '}
			{Translations.get('consent_regulations_3')}{' '}
			<Link
				style={styles.link}
				target='_blank'
				to="/privacy_policy"
			>
				{Translations.get('consent_regulations_4')}
			</Link>{' '}
			{Translations.get('consent_regulations_5')}
		</div>
	)
}

const Box = (props) => {

	return (
		<Grid
			container
			direction='column'
		>
			<Grid item>
				<Checkbox
					id="consent-personal-data"
					label={Translations.get('consent_personal_data')}
					disabled={props.disabled}
				/>
			</Grid>
			<Grid item>
				<Checkbox
					id="consent-regulations"
					label={createConsentLabel()}
					disabled={props.disabled}
				/>
			</Grid>
			<Grid item>
				<Checkbox
					id="consent-invoices"
					label={Translations.get('consent-invoices')}
					disabled={props.disabled}
				/>
			</Grid>
		</Grid>
	);
}

Box.propTypes = {
	disabled: PropTypes.bool,
}

Box.defaultProps = {
	disabled: false,
}

export default Box;
