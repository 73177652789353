import React, { useCallback } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Base64 } from "js-base64";
import { debounce } from 'lodash';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { withTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormHelperText from '@material-ui/core/FormHelperText';
import * as EventEmitter from 'events';
import AutoCompletePlaces from "Components/Commons/AutoCompletePlaces";
import TextSelect from "Components/Commons/TextSelect";
import TextInput from "Components/Commons/TextInput";
import FlagSelect from "Components/Commons/FlagSelect";
import PriceBox from "Components/Commons/PriceBox";
import { PriceBoxState } from "Components/Commons/PriceBox";
import Checkbox from "Components/Commons/Checkbox";
import * as io from "libs/IO.js";

import ImageBlockFormPaletRow from "./ImageBlockFormPaletRow";
import RestRequest from "libs/RestRequest";

import { CustomerType } from "Components/Commons/Const/Type";

import { setPaletsCount } from "Actions/imageBlockFormActions";
import { redirrect, setPopup, setPopupFunc } from "Actions/pageActions";
import { setValue } from "Actions/repositoryActions";
import { setInputValue } from "Actions/inputActions";
import { calculatePrice } from "Actions/priceActions";

import Translations from "Singleton/Translations";
import Tooltip from "@material-ui/core/Tooltip";
import MediaType from "../../../libs/MediaType";
import evaluationProxy from '../../../libs/EvaluationProxy';
import styles from "../../style";

import ReactGA from "react-ga";

const getSenderKeys = (isCompany = false) => {
  if (isCompany) {
    return [
      {
        key: CustomerType.COMPANY,
        value: Translations.get("company"),
      },
    ];
  }
  return [
    {
      key: CustomerType.PRIVATE,
      value: Translations.get("private_person"),
    },
    {
      key: CustomerType.COMPANY,
      value: Translations.get("company"),
    },
  ];
};


export class ImageBlockForm extends React.Component {
  constructor(props) {
    super(props);

    var pallets = [];
    var i = 0;
    for (var pallet of props.translations.pallets) {
      const key = pallet.isCustom ? "custom" : `${i}`;
      const value = pallet.isCustom
        ? pallet.type
        : `${pallet.type} ${pallet.length / 10} x ${pallet.width / 10} cm`;

      pallets.push({
        key,
        value,
      });

      i++;
    }

    var countrys = [];
    for (var country of props.translations.countrys) {
      countrys.push({ key: country.value.trim(), value: country.name.trim() });
    }

    this.state = {
      showPickupAddressError: false,
      showDeliveryAddressError: false,
      pallets: [
        Translations.get("one_palette"),
        Translations.get("two_pallets"),
        Translations.get("three_pallets"),
        Translations.get("four_pallets"),
        Translations.get("five_pallets"),
      ],
      sender: getSenderKeys(),
      countries: countrys,
      palletsSize: pallets,
      priceBoxState: PriceBoxState.UNCOMPLETE,
      priceRes: {},
      citiesFrom: [],
      citiesTo: [],
      firstLoad: true,
      price: 0,
      functions: {},
      isFormValid: false,

      readyToOrder: false,
      validObject: {
        palletsCount: 1,
        countriesIncludePL: true,
        pickupPostcode: false,
        deliveryPostcode: false,
      },
      isOverload: false,
      voucherError: false,
      progress: {
        total: 0,
        current: 0
      },
      queuedCalculationID: null
    };

    this.props.setPaletsCount(1);
  }

  styles = {
    grow: {
      flexGrow: 2,
    },
    root: {
      margin: "0 auto",
      maxWidth: "1080px",
      padding: "25px 35px 45px 35px",
    },
    rootTablet: {
      margin: "0 auto",
      maxWidth: "860px",
      width: "100%",
      padding: "15px 45px 30px 45px",
    },
    rootMobile: {
      margin: "120px auto 45px",
      maxWidth: "290px",
      width: "100%",
      padding: "25px",
    },
    label: {
      paddingRight: "10px",
      display: "inline-block",
    },
    extraLeftPadding: {
      paddingLeft: "65px",
    },
    extraRightPadding: {
      paddingRight: "65px",
    },
    buttonLine: {
      marginTop: "10px",
    },
    gridBox1: {
      // width: '60px',
      textAlign: "center",
    },
    gridBox2: {
      // width: '202.5px'
    },
    gridBox3: {
      // width: '290px',
    },
    gridBox4: {
      // width: '640px',
    },
    input1: {
      // width: '172.5px',
    },
    input2: {
      // width: '145px',
    },
    button: {
      height: "54px",
    },
    labelSend: {
      width: "60px",
    },
    inputSend: {
      width: "170px",
    },
    labelAs: {
      width: "60px",
    },
    inputAs: {
      width: "170px",
    },
    labelAs: {
      paddingLeft: "10px",
    },
    labelFrom: {
      width: "14px",
    },
    labelTo: {
      width: "30px",
      paddingLeft: "10px",
    },
    tablet: {
      gridBox1: {
        // width: '30px',
      },
      gridBox2: {
        // width: '200px',
      },
      gridBox3: {
        // width: '100%',
      },
      gridBox4: {
        // width: '100%'
      },
      input1: {
        //width: '203px',
      },
      input2: {
        //width: '245px'
      },
      input3: {
        //width: '290px'
      },
      input4: {
        //width: '223px'
      },
      extraRightPadding: {
        paddingRight: "32px",
      },
      extraLeftPadding: {
        paddingLeft: "32px",
      },
    },
    mobile: {
      gridBox1: {
        // width: '30px',
      },
      gridBox2: {
        // width: '200px',
      },
      labelSend: {
        width: "60px",
      },
      inputSend: {
        width: "180px",
      },
      labelAs: {
        width: "60px",
        paddingLeft: "0",
      },
      inputAs: {
        width: "180px",
      },
      labelFrom: {
        width: "40px",
      },
      labelTo: {
        width: "40px",
        paddingLeft: "0px",
      },
      inputPostCode: {
        paddingBottom: "7px",
      },
    },
  };

  bindFunction = (name) => (callback) => {
    const f = this.state.functions;
    f[name] = callback;
    this.setState({
      ...this.state,
      functions: f,
    });
  };

  isAnyPaletteCustom = () => {
    for (var i = 0; i < this.state.validObject.palletsCount; i++) {
      var key = `pallets_size_${i}`;
      if (this.props.inputs[key] === "custom") {
        return true;
      }
    }
    return false;
  };

  isOverloading = () => {
    const maxWeight = this.props.translations.maxOrderWeight;
    let sum = 0;
    for (var i = 0; i < this.state.validObject.palletsCount; i++) {
      let key = `palet_weight_${i}`;
      sum += parseInt(this.props.inputs[key]);
    }
    console.log("sum", sum);

    if (sum > maxWeight) {
      return true;
    }
    return false;
  };

  debouncedCheckVoucherAndCalculatePrice = debounce(() => {
    this.checkVoucherAndCalculatePrice();
  }, 500);

  onValidChange = (id, value) => {
    var priceBoxState;
    var validObject = this.state.validObject;
    if (id) {
      validObject[id] = value;
    }
    if(this.queuedCalculationID) {
      clearTimeout(this.queuedCalculationID);
      this.queuedCalculationID = null;
    }

    var isFormValid =
      validObject.countriesIncludePL &&
      validObject.pickupPostcode &&
      validObject.deliveryPostcode;
    for (var i = 0; i < validObject.palletsCount; i++) {
      const key = `row_${i}`;
      isFormValid = isFormValid && validObject[key] ? validObject[key] : false;
    }

    if (isFormValid) {
      // priceBoxState = this.isAnyPaletteCustom()
      //   ? PriceBoxState.CUSTOM
      //   : PriceBoxState.READY;
      priceBoxState = PriceBoxState.READY;
      this.queuedCalculationID = setTimeout(this.priceBoxClick, 2000);
    } else {
      priceBoxState = PriceBoxState.UNCOMPLETE;
    }

    if (isFormValid && this.isOverloading()) {
      isFormValid = false;
      priceBoxState = PriceBoxState.OVERLOAD_ERROR;
    }

    this.setState({
      ...this.state,
      validObject,
      priceBoxState,
      readyToOrder: false,
      isFormValid,
    });
    this.props.setRepositoryValue("priceBoxState", priceBoxState);

    setTimeout(() => {
      this.skipCountries();
    }, 100)
  };

  handlePalletsChange(item) {
    var count = this.state.pallets.indexOf(item) + 1;
    this.props.setPaletsCount(count);
    this.onValidChange("palletsCount", count);
  }

  findCountryId(iso) {
    for (var country of this.props.translations.countrys) {
      if (country.value === iso) {
        return country.id;
      }
    }
  }

  orderTransport() {
    ReactGA.event({
      category: "User",
      action: "Clicked order transport",
    });
    this.props.setInputValue("valuation-number", undefined);
    this.props.redirrect(`/more_info`, this.props.history);
  }

  sendData = (email) => {
    const customIndex = this.props.translations.pallets.findIndex((item) => {
      return item.isCustom;
    });
    var body = {};
    var paletsCount = this.props.imageBlockForm.paletsCount;
    body["sender"] = this.props.inputs.sender;
    body["from"] = {
      country: this.findCountryId(this.props.inputs.country_from),
      postcode: this.props.inputs.post_code_from || 0,
      liftCar:
        this.props.inputs.country_from === "IT"
          ? this.props.inputs["lift_car"]
          : undefined,
    };
    body["to"] = {
      country: this.findCountryId(this.props.inputs.country_to),
      postcode: this.props.inputs.post_code_to || 0,
      liftCar:
        this.props.inputs.country_to === "IT"
          ? this.props.inputs["lift_car"]
          : undefined,
    };
    body["email"] = email;
    body["priv"] = this.props.inputs.sender === "private";
    var palets = [];
    for (var i = 0; i < paletsCount; i++) {
      let isCustom = this.props.inputs[`pallets_size_${i}`] === "custom";
      let index = isCustom
        ? customIndex
        : this.props.inputs[`pallets_size_${i}`];
      palets.push({
        paletType: this.props.translations.pallets[index],
        height: this.props.inputs[`palet_height_${i}`],
        weight: this.props.inputs[`palet_weight_${i}`],
        fragile: this.props.inputs[`fragile_cargo_${i}`],
        length: this.props.inputs[`palet_length_${i}`],
        width: this.props.inputs[`palet_width_${i}`],
      });
    }
    console.log({ palets })
    body["palets"] = palets;
    body["details"] = this.props.inputs.details;
    body["rabat"] = this.props.inputs.rabat;
    this.sendDataEvaluation(body);
  };

  orderManualValuation() {
    ReactGA.event({
      category: "User",
      action: "Clicked order manual valuation",
    });
    if (!this.props.userToken) {
      this.props.setPopupFunc(this.sendData);
      this.props.setPopup("login_continue");
      return;
    }

    this.sendData(this.props.userEmail);
    this.setState({
      isFormValid: false,
    });
  }

  sendDataEvaluation(data) {
    this.setState({ sending: true });
    setTimeout(
      function () {
        RestRequest(
          "post",
          "secured/saveEvaluationData",
          data,
          (res) => {
            if (res.body.error) {
              toast.error(`${res.body.errorReason}`, {
                position: toast.POSITION.TOP_RIGHT,
              });
              this.setState({ sending: false });
            } else {
              this.props.setPopup(undefined);
              var encNumber = Base64.encodeURI(res.body.id);
              const valuationToken = this.props.userName
                ? ""
                : `?token=${res.body.token}`;
              this.props.redirrect(
                `/manual_valuation/${encNumber}${valuationToken}`,
                this.props.history
              );
            }
          },
          this.props.userToken
        );
      }.bind(this),
      1000
    );
  }

  checkVoucherAndCalculatePrice = () => {
    if (this.props.inputs.rabat) {
      this.priceRequest = RestRequest(
        "get",
        "voucher/check",
        { code: this.props.inputs.rabat },
        this.checkVoucherResponse
      );
    } else {
      this.setState(
        {
          voucherError: false,
        },
        this.calculatePrice
      );
    }
  };

  checkVoucherResponse = (res) => {
    if (res.body.error) {
      this.setState({
        voucherError: true,
      });
    } else {
      this.setState(
        {
          voucherError: false,
        },
        this.calculatePrice
      );
    }
  };

  calculatePrice = () => {
    ReactGA.event({
      category: "User",
      action: "Clicked calculate price",
    });
    const sender = this.props.inputs.sender;
    this.pendingCalculations = this.pendingCalculations || 0;
    this.pendingCalculations++;

    console.log(io.default.socket)

    io.default.socket.on('message', e => {
      if (e.total) {
        this.setState({ progress: e });
      }
    })

    if (this.pendingCalculations > 1 && this.cancel) {
      this.pendingCalculations--;
      this.cancel.emit('cancel');
      io.default.dispatch({
        event: 'discard',
        uid: localStorage.getItem('uid')
      });
    }

    this.cancel = new EventEmitter();

    this.calculatePrice$ = calculatePrice({...this.props, cancel: this.cancel }, this.props.translations)
      .then(res => {
        this.pendingCalculations--;
        if (this.pendingCalculations !== 0) {
          return;
        }
        this.props.setRepositoryValue("price", res.body);
        var priceState = this.state.priceBoxState;
        if (priceState !== PriceBoxState.AWAIT) {
          return;
        }
        if (res.body.discount > 0) {
          priceState = PriceBoxState.COMPLETE_VOUCHER;
        } else if (sender === "private") {
          priceState = PriceBoxState.COMPLETE;
        } else {
          priceState = PriceBoxState.COMPLETE_PLN;
        }
        this.setState({
          price: res.body.price,
          priceRes: res.body,
          priceBoxState: priceState,
          readyToOrder: true,
        });
        this.props.setRepositoryValue("priceBoxState", priceState);
      })
      .catch(_ => {
        console.log(_);
        this.setState({
          price: 0,
          priceBoxState: PriceBoxState.MANUAL_ORDER,
        });
      });

    

    this.setState({
      ...this.state,
      priceBoxState: PriceBoxState.AWAIT,
    });
  };

  calculatePriceValidate() {
    if (this.state.firstLoad && this.validateForm()) {
      return false;
    }
    if (!this.state.firstLoad) {
      return false;
    }
    if (this.validateForm()) {
      return false;
    }
    return true;
  }

  flagPickupChange = (pickupFlag) => {
    const deliveryFlag = this.props.inputs.country_to;
    if (deliveryFlag !== "PL" && pickupFlag !== "PL") {
      setTimeout(() => {
        this.state.functions.deliveryFlag("PL");
      }, 1);
    }
    this.validateCoutries(pickupFlag, deliveryFlag);
    this.resetPickupAddress();
    this.skipCountries();
  };

  flagDeliveryChange = (deliveryFlag) => {
    const pickupFlag = this.props.inputs.country_from;
    if (deliveryFlag !== "PL" && pickupFlag !== "PL") {
      setTimeout(() => {
        this.state.functions.pickupFlag("PL");
      }, 1);
    }
    this.validateCoutries(pickupFlag, deliveryFlag);
    this.resetDeliveryAddress();
    this.skipCountries();
  };

  skipCountries() {
    const pickupFlag = this.props.inputs.country_from;
    const deliveryFlag = this.props.inputs.country_to;
    const manualCalulationOnly = { 'CY': true, 'MT': true, 'GB': true, 'NO': true, 'CH': true  };
    if ((pickupFlag in manualCalulationOnly) || (deliveryFlag in manualCalulationOnly)) {
      this.setState({
        price: 0,
        priceBoxState: PriceBoxState.MANUAL_ORDER,
      });
    }
  }

  validateCoutries = (pickupCountry, deliveryCountry) => {
    this.onValidChange(
      "countriesIncludePL",
      pickupCountry === 'PL' || deliveryCountry === 'PL'
    );
  };

  addressPickupChange = (value) => {
    const proxied = evaluationProxy(value, this.props.inputs['country_from']);

    this.onValidChange("pickupPostcode", !!proxied.postcode);
    this.setState({ showPickupAddressError: !proxied.postcode });
    this.props.inputs.raw_from = proxied.option;

    this.props.inputs.post_code_from = proxied.postcode;
    this.props.defaultValues["post_code_from"] = proxied.postcode;

    if (proxied.city) {
      this.props.inputs['city_from'] = proxied.city;
    }
    if (proxied.street) {
      this.props.inputs['street_from'] = proxied.street;
    }
    if (proxied.streetNumber) {
      this.props.inputs['streetNumber_from'] = proxied.streetNumber;
    }
    this.skipCountries();
  };

  postcodePickupChange = (value) => {
    const val = value ? true : false;
    this.onValidChange("pickupPostcode", val);
  };

  addressDeliveryChange = (value) => {
    const proxied = evaluationProxy(value, this.props.inputs['country_to']);

    this.onValidChange("deliveryPostcode", !!proxied.postcode);
    this.setState({ showDeliveryAddressError: !proxied.postcode });
    this.props.inputs.raw_to = proxied.option;

    this.props.inputs.post_code_to = proxied.postcode;
    this.props.defaultValues["post_code_to"] = proxied.postcode;

    if (proxied.city) {
      this.props.inputs['city_to'] = proxied.city;
    }
    if (proxied.street) {
      this.props.inputs['street_to'] = proxied.street;
    }
    if (proxied.streetNumber) {
      this.props.inputs['streetNumber_to'] = proxied.streetNumber;
    }
    this.skipCountries();
  };

  postcodeDeliveryChange = (value) => {
    const val = value ? true : false;
    this.onValidChange("deliveryPostcode", val);
  };

  palletRowChange = (id, isValid) => {
    this.onValidChange(`row_${id}`, isValid);
  };

  getPalettsCountComponent = () => {
    return (
      <React.Fragment>
        <Typography
          component="span"
          variant="subheading"
          align="left"
          style={Object.assign(
            {},
            this.styles.label,
            this.styles.labelSend,
            MediaType.isMobile ? this.styles.mobile.labelSend : {}
          )}
        >
          {Translations.get("send")}
        </Typography>
        <TextSelect
          id="pallets"
          items={this.state.pallets}
          onChange={this.handlePalletsChange.bind(this)}
          defaultValue={this.props.defaultValues["pallets"]}
          style={Object.assign(
            {},
            this.styles.inputSend,
            MediaType.isMobile ? this.styles.mobile.inputSend : {}
          )}
        />
      </React.Fragment>
    );
  };

  getSenderComponent = () => {
    return (
      <React.Fragment>
        <Typography
          component="span"
          variant="subheading"
          style={Object.assign(
            {},
            this.styles.label,
            this.styles.labelAs,
            MediaType.isMobile ? this.styles.mobile.labelAs : {}
          )}
        >
          {Translations.get("as")}
        </Typography>
        <TextSelect
          id="sender"
          items={
            this.props.userVatZero ? getSenderKeys(true) : this.state.sender
          }
          style={Object.assign(
            {},
            this.styles.inputAs,
            MediaType.isMobile ? this.styles.mobile.inputAs : {}
          )}
          disabled={this.props.userVatZero}
          onChange={() => {
            this.onValidChange();
          }}
        />
      </React.Fragment>
    );
  };

  getAddressValidationError() {
    return (
      <FormHelperText style={{
        position: 'absolute', color: 'red',
        fontSize: '0.7rem', marginTop: '6px'
      }}>
        Wpisz pełny adres lub kod pocztowy
      </FormHelperText>
    )
  }

  getPostcodePickupComponent() {
    return (
      <Grid
        container
        justify="flex-start"
        alignItems="flex-end"
        wrap="nowrap"
        style={{}}
      >
        <Grid
          item
          style={
            MediaType.notDesktop
              ? { marginRight: "15px" }
              : { margin: " 0 15px" }
          }
        >
          <Typography
            component="span"
            variant="subheading"
            style={Object.assign(
              {},
              this.styles.label,
              this.styles.labelFrom,
              MediaType.isMobile ? this.styles.mobile.labelFrom : {}
            )}
          >
            {Translations.get("from")}
          </Typography>
        </Grid>
        <Grid item style={{ margin: "0 5px" }}>
          <FlagSelect
            id="country_from"
            defaultValue={
              this.props.defaultValues["country_from"]
                ? this.props.defaultValues["country_from"]
                : "PL"
            }
            onChange={this.flagPickupChange}
            bindSetValueFunc={this.bindFunction("pickupFlag")}
          />
          {this.state.showPickupAddressError ? this.getAddressValidationError() : ''}
        </Grid>
        <Grid item style={{ maxHeight: "36px" }}>
          <AutoCompletePlaces
            style={{ maxWidth: '130px' }}
            inputId="autocomplete_from"
            forwardRef={c => { this.resetPickupAddress = c }}
            placeholder='Skąd'
            onChange={this.addressPickupChange}
            country={this.props.inputs.country_from}>
          </AutoCompletePlaces>
        </Grid>
      </Grid>
    );
  }

  getPostcodeDeliveryComponent() {
    return (
      <Grid container justify="flex-start" wrap="nowrap" alignItems="flex-end">
        <Grid
          item
          style={
            MediaType.notDesktop
              ? { marginRight: "15px" }
              : { margin: "0 15px" }
          }
        >
          <Typography
            component="span"
            variant="subheading"
            style={Object.assign(
              {},
              this.styles.label,
              this.styles.labelTo,
              MediaType.isMobile ? this.styles.mobile.labelTo : {}
            )}
          >
            {Translations.get("destination")}
          </Typography>
        </Grid>
        <Grid item style={{ margin: "0 5px" }}>
          <FlagSelect
            id="country_to"
            defaultValue={
              this.props.defaultValues["country_to"]
                ? this.props.defaultValues["country_to"]
                : "PL"
            }
            onChange={this.flagDeliveryChange}
            bindSetValueFunc={this.bindFunction("deliveryFlag")}
          />
          {this.state.showDeliveryAddressError ? this.getAddressValidationError() : ''}
        </Grid>
        <Grid item style={{ maxHeight: "36px" }}>
          <AutoCompletePlaces
            style={{ maxWidth: '130px' }}
            inputId="autocomplete_to"
            forwardRef={c => { this.resetDeliveryAddress = c }}
            placeholder='Dokąd'
            onChange={this.addressDeliveryChange}
            country={this.props.inputs.country_to}>
          </AutoCompletePlaces>
        </Grid>
      </Grid>
    );
  }

  getPickupLineComponent() {
    return this.getPostcodePickupComponent();
  }

  getDeliveryLineComponent() {
    return this.getPostcodeDeliveryComponent();
  }

  getAddresLine() {
    return (
      <Grid container spacing={0} alignItems="baseline" justify="space-between">
        <Grid xs={12} sm={6} md={3} item style={{ whiteSpace: "nowrap" }}>
          {this.getPalettsCountComponent()}
        </Grid>
        <Grid xs={12} sm={6} md={3} item style={{ whiteSpace: "nowrap" }}>
          {this.getSenderComponent()}
        </Grid>
        <Grid xs={12} sm={6} md={3} item style={{ whiteSpace: "nowrap" }}>
          {this.getPickupLineComponent()}
        </Grid>
        <Grid xs={12} sm={6} md={3} item style={{ whiteSpace: "nowrap" }}>
          {this.getDeliveryLineComponent()}
        </Grid>
      </Grid>
    );
  }

  getAddresLineTablet() {
    return (
      <Grid container direction="column">
        <Grid item style={{ backgroundColor: "red" }}>
          {this.getPalettsLine()}
        </Grid>
        <Grid item style={{ backgroundColor: "red" }}>
          {this.getPostcodeLine()}
        </Grid>
      </Grid>
    );
  }

  onRemarksChange = (value) => {
    setTimeout(this.onValidChange, 1);
  };

  getRemarksInput = () => {
    return (
      <TextInput
        label={Translations.get("additional_remarks")}
        limit={245}
        id="details"
        onChange={this.onRemarksChange}
      />
    );
  };

  getVoucherInput = () => {
    return (
      <Tooltip
        enterTouchDelay={0}
        title={Translations.get("available_with_auto_valuation")}
      >
        <div>
          <TextInput
            optional
            label={Translations.get("discount_coupon")}
            id="rabat"
            onChange={() => {
              this.onValidChange();
            }}
            isError={this.state.voucherError}
            errorReason={Translations.get("voucher_not_valid")}
          />
        </div>
      </Tooltip>
    );
  };

  getRemarksLine() {
    return (
      <Grid container spacing={0} alignItems="baseline" justify="space-between">
        <Grid item sm={6} md={8}>
          {this.getRemarksInput()}
        </Grid>
        <Grid item sm={6} md={4} style={this.styles.extraLeftPadding}>
          {this.getVoucherInput()}
        </Grid>
      </Grid>
    );
  }

  priceBoxClick = () => {
    this.setState({ progress: { current: 0, total: 0 }});
    switch (this.state.priceBoxState) {
      case PriceBoxState.READY:
        this.checkVoucherAndCalculatePrice();
        break;
      case PriceBoxState.COMPLETE_PLN:
        this.setState({
          ...this.state,
          priceBoxState: PriceBoxState.COMPLETE_EUR,
        });
        this.props.setRepositoryValue(
          "priceBoxState",
          PriceBoxState.COMPLETE_EUR
        );
        break;
      case PriceBoxState.COMPLETE_EUR:
        this.setState({
          ...this.state,
          priceBoxState: PriceBoxState.COMPLETE_PLN,
        });
        this.props.setRepositoryValue(
          "priceBoxState",
          PriceBoxState.COMPLETE_PLN
        );
        break;
      default:
        break;
    }
  };

  priceBoxItem = () => {
    return (
      <PriceBox
        fullWidth
        progress={this.state.progress.current / this.state.progress.total || 0}
        state={this.state.priceBoxState}
        onClick={this.priceBoxClick}
        priceNet={this.state.priceRes.priceNet}
        priceGross={this.state.priceRes.priceGross}
        priceGrossBeforeDiscount={this.state.priceRes.priceGrossBeforeDiscount}
        discount={this.state.priceRes.discount}
        exchangeRate={this.state.priceRes.exchangeRate}
      />
    );
  };

  orderButtonItem = () => {
    if (this.state.priceBoxState === PriceBoxState.MANUAL_ORDER) {
      return "";
    }
    return (
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={this.orderTransport.bind(this)}
        style={this.styles.button}
        disabled={!this.state.readyToOrder}
      >
        {Translations.get("order_transport")}
      </Button>
    );
  };

  valuationButtonItem = () => {
    const isMainButton =
      this.state.priceBoxState === PriceBoxState.MANUAL_ORDER;
    return (
      <Button
        variant={isMainButton ? "contained" : "outlined"}
        color="primary"
        fullWidth
        onClick={this.orderManualValuation.bind(this)}
        style={this.styles.button}
        disabled={!this.state.isFormValid && !this.state.showDeliveryAddressError && !this.state.showPickupAddressError}
      >
        {isMainButton
          ? Translations.get("order_valuation")
          : Translations.get("order_manual_evaluation")}
      </Button>
    );
  };

  getButtonsLine() {
    return (
      <Grid container spacing={0} alignItems="center">
        <Grid item sm={12} md={4} style={{ padding: "25px 0" }}>
          {this.priceBoxItem()}
        </Grid>
        <Grid
          item
          sm={6}
          md={4}
          style={
            MediaType.isDesktop
              ? this.styles.extraLeftPadding
              : this.styles.tablet.extraRightPadding
          }
        >
          {this.orderButtonItem()}
        </Grid>
        <Grid
          item
          sm={6}
          md={4}
          style={
            MediaType.isDesktop
              ? this.styles.extraLeftPadding
              : this.styles.tablet.extraLeftPadding
          }
        >
          {this.state.priceBoxState === PriceBoxState.MANUAL_ORDER ? (
            ""
          ) : (
              <Typography
                variant="caption"
                style={{ position: "absolute", margin: "20px 0 0 -42px" }}
              >
                {Translations.get("or")}
              </Typography>
            )}
          {this.valuationButtonItem()}
        </Grid>
      </Grid>
    );
  }

  getPaletRows() {
    const items = [];
    for (var i = 0; i < this.props.imageBlockForm.paletsCount; i++) {
      items.push(
        <ImageBlockFormPaletRow
          onChange={this.palletRowChange}
          id={i}
          key={i}
          palletsSize={this.state.palletsSize}
        />
      );
    }
    return items;
  }

  rootStyle = () => {
    switch (MediaType.type) {
      case MediaType.TABLET:
        return this.styles.rootTablet;
      case MediaType.MOBILE:
        return this.styles.rootMobile;
      default:
        return this.styles.root;
    }
  };

  getCheckboxLine = (msg) => {
    return (
      <Checkbox
        id="lift_car"
        label={msg}
        style={MediaType.isMobile ? { marginLeft: -14 } : { marginLeft: -8 }}
      />
    );
  };

  getLiftCarCheckbox = () => {
    if (this.props.inputs["country_from"] === "IT") {
      return this.getCheckboxLine(Translations.get("lift_on_pickup"));
    }
    if (this.props.inputs["country_to"] === "IT") {
      return this.getCheckboxLine(Translations.get("lift_on_delivery"));
    }
    return "";
  };

  render() {
    if (MediaType.isMobile) {
      return this.renderMobile();
    }
    return (
      <Paper style={this.rootStyle()}>
        <Grid container direction="column" spacing={0}>
          <Grid item>{this.getAddresLine()}</Grid>
          {this.getPaletRows()}
          {this.getLiftCarCheckbox()}
          <Grid item>{this.getRemarksLine()}</Grid>
          <Grid item style={this.styles.buttonLine}>
            {this.getButtonsLine()}
          </Grid>
        </Grid>
      </Paper>
    );
  }

  renderMobile() {
    return (
      <Paper style={this.styles.rootMobile}>
        <Grid container direction="column">
          <Grid item style={{ maxWidth: "100%" }}>
            {this.getPalettsCountComponent()}
          </Grid>
          <Grid item style={{ maxWidth: "100%" }}>
            {this.getSenderComponent()}
          </Grid>
          <Grid item style={{ maxWidth: "100%" }}>
            {this.getPickupLineComponent()}
          </Grid>
          <Grid item style={{ height: "12px", maxWidth: "100%" }}></Grid>
          <Grid item style={{ maxWidth: "100%" }}>
            {this.getDeliveryLineComponent()}
          </Grid>
          {this.getPaletRows()}
          {this.getLiftCarCheckbox()}
          <Grid item>{this.getRemarksInput()}</Grid>
          <Grid item>{this.getVoucherInput()}</Grid>
        </Grid>
        <Grid container direction="column">
          <Grid item style={styles.space16Vertical}></Grid>
          <Grid item>{this.priceBoxItem()}</Grid>
          <Grid item style={styles.space16Vertical}></Grid>
          <Grid item>{this.orderButtonItem()}</Grid>
          <Grid item style={styles.space16Vertical}>
            {this.state.priceBoxState === PriceBoxState.MANUAL_ORDER ? (
              ""
            ) : (
                <Typography variant="caption" align="center">
                  {Translations.get("or")}
                </Typography>
              )}
          </Grid>
          <Grid item>{this.valuationButtonItem()}</Grid>
        </Grid>
      </Paper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dropdown: state.dropdown,
    imageBlockForm: state.imageBlockForm,
    translations: state.translations,
    inputs: state.input.inputState,
    userToken: state.user.token,
    userEmail: state.user.login,
    userVatZero: state.user.isVatZero,
    defaultValues: state.input.defaultValues,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPaletsCount: (count) => {
      dispatch(setPaletsCount(count));
    },
    redirrect: (page, hist) => {
      dispatch(redirrect(page, hist));
    },
    setPopup: (x) => {
      dispatch(setPopup(x));
    },
    setPopupFunc: (x) => {
      dispatch(setPopupFunc(x));
    },
    setInputValue: (x, y) => {
      dispatch(setInputValue(x, y));
    },
    setRepositoryValue: (x, y) => {
      dispatch(setValue(x, y));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTheme()(ImageBlockForm))
);
