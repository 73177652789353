import React from 'react'
import PropTypes from 'prop-types';

import { Paper, Grid, Button, Typography } from '@material-ui/core';
import styles from '../../../style';

import Translations from 'Singleton/Translations'

class container extends React.Component{

	onClick = () => {
		var text = document.getElementById('coupon');
		var selection = window.getSelection();
		var range = document.createRange();
		range.selectNodeContents(text);
		selection.removeAllRanges();
		selection.addRange(range);
		document.execCommand('copy');
		selection.removeAllRanges();
	}

	render(){
		return (
			<Paper style={styles.infoBox}>
				<Grid
					container
					direction='column'
					alignItems='center'
					justify='space-between'
					style={{height: '100%'}}
				>
					<Grid item>
						<Typography variant='title' id="coupon">
							{this.props.code}
						</Typography>
					</Grid>
					<Grid item>
						<Button
							variant='outlined'
							onClick={this.onClick}
						>
							{Translations.get('get_coupon_code')}
						</Button>
					</Grid>
				</Grid>
			</Paper>
		);
	}
}

container.propTypes={
	code: PropTypes.string.isRequired,
}

export default container;
