import React from 'react'
import { Grid, Typography } from "@material-ui/core";

export default function NotFound() {

    const componentStyles = {
        wrapper: {
            display: 'flex',
            align: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
        },
        paragraph: {
            marginTop: '100px',
            textAlign: 'center'
        },
        href: {
            color: '#000',
            textDecoration: 'underline',
            lineHeight: '30px'
        }
    }
    return (
        <Grid container direction="row">
            <Grid item xs={12} width="100%" style={componentStyles.wrapper}>
                <Typography style={componentStyles.paragraph}>
                    Nie znaleziono podanej strony. <br />
                    <a href="https://treo.pl/" target="_self" style={componentStyles.href}>Wróć na stronę główną.</a>
                </Typography>
            </Grid>
        </Grid>
    )
}
