
export default class CustomerColumn {
	static get EMAIL() {return 'email';}
	static get NAME() {return 'name';}
	static get SURNAME() {return 'surname';}
	static get STATUS() {return 'status';}
	static get FIRST_ORDER_DATE() {return 'firstorderdate';}
	static get LAST_ORDER_DATE() {return 'lastorderdate';}
	static get AMOUNT_OF_ORDERS() {return 'amountoforders';}
	static get AMOUNT_OF_VALUATIONS() {return 'amountofvaluations';}
}
