export function setLogin(login,token,id, isAdmin, type, accessToken){
    return {
        type: "USER_SET_LOGIN",
		login: login,
        token: token,
		id: id,
		isAdmin: isAdmin,
		loginType: type,
		accessToken: accessToken,
    };
}

export function setUserVatZero(value){
	return{
		type: 'USER_SET_VAT_ZERO',
		payload: value,
	}
}
