import React from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'

import { setPage } from 'Actions/pageActions';

import Translations from 'Singleton/Translations'
import { Grid, Paper, Typography, Button } from "@material-ui/core";
import styles from "../../Components/style";
import MediaType from "../../libs/MediaType";
import { MetaData, handleMetaData } from "../../Components/Commons/Const/MetaData"


class container extends React.Component {
	componentWillMount() {
		document.title = MetaData.mainPage.title
		handleMetaData();
	}

	redirect = (path) => {
		this.props.setPage(path);
		this.props.history.push(path);
	}

	styles = {
		container: {
			...styles.moreInfoBox,
			borderLeft: `solid ${styles.palette.green}`
		},
		containerTablet: {
			...styles.moreInfoBoxTablet,
			borderLeft: `solid ${styles.palette.green}`
		},
		containerWarrning: {
			...styles.moreInfoBox,
			borderLeft: `solid ${styles.palette.yellow}`
		},
		containerMobile: {
			...styles.moreInfoBoxMobile,
			borderLeft: `solid ${styles.palette.green}`
		},
		containerError: {
			...styles.moreInfoBox,
			borderLeft: `solid ${styles.palette.red}`
		}
	}

	infoBox = () => {
		const number = this.props.match.params.number;
		console.log('My number:', number);

		return (
			<Paper style={
				MediaType.isDesktop ?
					this.styles.container :
					MediaType.isMobile ?
						this.styles.containerMobile :
						this.styles.containerTablet
			}>
				<Grid
					container
					direction='column'
				>
					<Grid item>
						<Typography variant='headline' paragraph>
							{Translations.get('order_placed')}
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant='body1' paragraph>
							{Translations.get('order_placed_info_1')}
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant='body1' paragraph>
							{Translations.get('order_placed_info_2')}
						</Typography>
					</Grid>
					<Grid item>
						<Grid
							container
							direction={MediaType.isMobile ? 'column' : 'row'}
						>
							<Grid item>
								<Button
									variant='outlined'
									fullWidth={MediaType.isMobile}
									onClick={() => {
										const path = this.props.isAdmin ?
											`/panel/evaluation_details/order/${number}` :
											`/order_details/order/${number}`;
										this.redirect(path)
									}}
								>
									{Translations.get('track_order_status')}
								</Button>
							</Grid>
							<Grid item style={
								MediaType.isMobile ?
									styles.space16Vertical :
									styles.space30

							}>
							</Grid>
							<Grid item>
								<Button
									variant='outlined'
									fullWidth={MediaType.isMobile}
									onClick={() => this.redirect('/')}
								>
									{Translations.get('new_order')}
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Paper>
		)
	}

	paymentSuccess = () => {
		return (
			<Paper style={
				MediaType.isDesktop ?
					this.styles.container :
					MediaType.isMobile ?
						this.styles.containerMobile :
						this.styles.containerTablet
			}>
				<Grid
					container
					direction='column'
				>
					<Grid item>
						<Typography variant='headline' paragraph>
							{Translations.get('payment_success')}
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant='body1' paragraph>
							{Translations.get('payment_success_info_1')}
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant='body1' paragraph>
							{Translations.get('payment_success_info_2')}
						</Typography>
					</Grid>
				</Grid>
			</Paper>
		);
	}

	paymentError = () => {
		return (
			<Paper style={this.styles.containerError}>
				<Grid
					container
					direction='column'
				>
					<Grid item>
						<Typography variant='headline' paragraph>
							{Translations.get('payment_error')}
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant='body1' paragraph>
							{Translations.get('payment_error_info_1')}
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant='body1' paragraph>
							{Translations.get('payment_error_info_2')}
						</Typography>
					</Grid>
				</Grid>
			</Paper>
		);
	}

	paymentWarning = () => {
		return (
			<Paper style={this.styles.containerWarrning}>
				<Grid
					container
					direction='column'
				>
					<Grid item>
						<Typography variant='headline' paragraph>
							{Translations.get('payment_warning')}
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant='body1' paragraph>
							{Translations.get('payment_warning_info_1')}
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant='body1' paragraph>
							{Translations.get('payment_warning_info_2')}
						</Typography>
					</Grid>
				</Grid>
			</Paper>
		);
	}

	getPaymentInfo = () => {
		switch (this.props.match.params.payment) {
			case "paymentSuccess": return this.paymentSuccess();
			case "paymentError": return this.paymentError();
			case "paymentWarning": return this.paymentWarning();
			default: return '';
		}
	}

	render() {
		return (
			<Grid
				container
				direction='column'
				style={
					MediaType.isMobile ?
						styles.mobileContainer :
						styles.container
				}
				alignItems='center'
			>
				<Grid item style={styles.space30Vertical}>
				</Grid>
				{this.props.match.params.variant === 'oldOrder' ?
					'' :
					<Grid item>
						{this.infoBox()}
					</Grid>
				}
				<Grid item>
					{this.getPaymentInfo()}
				</Grid>
			</Grid>
		);
	}
}


const mapStateToProps = (state) => {
	return {
		userName: state.user.login
	};
};

const mapDispatchToProps = (dispath) => {
	return {
		setPage: (page) => {
			dispath(setPage(page));
		}
	};
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(container));
