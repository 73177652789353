import React from 'react'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'

import CircleMark from './CircleMark'
import CircleMarkHorizontal from './CircleMarkHorizontal'
import styles from './../../style'
import Translations from 'Singleton/Translations'
import MediaType from '../../../libs/MediaType';

export class HowItWorksBlock extends React.Component {

	styles = {
		container: {
			...styles.container,
			...styles.rootWeb,
			paddingBottom: '120px',
		},
		containerTablet: {
			...styles.container,
			...styles.rootTablet,
			paddingBottom: '120px',
		},
		containerMobile: {
			...styles.container,
			...styles.rootMobile,
			paddingBottom: '120px',
		},
    item: {
      position: 'relative',
      width: '20%'
    },
		icon: {
			width: '50px',
			height: '50px',
			margin: '22px 0',
      position: 'absolute',
      top: '0px',
      right: '-25px'
		},
		title: {
			marginTop: styles.space30.width,
			marginBottom: MediaType.isDesktop ? styles.space60.width : styles.space30.width,
		}
	}

//<Grid item>
  //        <KeyboardArrowRight style={this.styles.icon} color='primary' />
    //    </Grid>

	desktopContent() {
		return (
			<Grid
				container
				wrap="nowrap"
				spacing={0}
				justify='center'

        
			>
				<Grid item style={this.styles.item}>
					<CircleMark number={1}>
						{Translations.get('how_it_works_1')}
					</CircleMark>
          { this.arrow() }
				</Grid>
				

				<Grid item style={this.styles.item}>
					<CircleMark number={2}>
						{Translations.get('how_it_works_2')}
					</CircleMark>
          { this.arrow() }
				</Grid>
				

        <Grid item style={this.styles.item}>
					<CircleMark number={3}>
						{Translations.get('how_it_works_3')}
					</CircleMark>
          { this.arrow() }
				</Grid>
        

        <Grid item style={this.styles.item}>
					<CircleMark number={4}>
						{Translations.get('how_it_works_4')}
					</CircleMark>
          { this.arrow() }
				</Grid>
				

        <Grid item style={this.styles.item}>
					<CircleMark number={5}>
						{Translations.get('how_it_works_5')}
					</CircleMark>
				</Grid>
			</Grid>
		)
	}


  arrow = () => {
    return (
        <KeyboardArrowRight style={this.styles.icon} color='primary' />
    )
  }

	circleMarkRow = (number, translation) => {
		return (
			<Grid item>
				<CircleMarkHorizontal number={number}>
					{Translations.get(translation)}
				</CircleMarkHorizontal>
			</Grid>
		);
	}

	tabletContent = () => {
		return (
			<Grid
				container
				direction='column'
				spacing={0}
        style={{ maxWidth: '700px', margin: '0 auto' }}
			>
				{this.circleMarkRow(1, 'how_it_works_1')}
				<Grid item style={styles.space30Vertical}></Grid>
				{this.circleMarkRow(2, 'how_it_works_2')}
				<Grid item style={styles.space30Vertical}></Grid>
				{this.circleMarkRow(3, 'how_it_works_3')}
				<Grid item style={styles.space30Vertical}></Grid>
				{this.circleMarkRow(4, 'how_it_works_4')}
				<Grid item style={styles.space30Vertical}></Grid>
				{this.circleMarkRow(5, 'how_it_works_5')}
			</Grid>
		);
	}

	getContent = () => {
		switch (MediaType.type) {
			case MediaType.MOBILE:
			case MediaType.TABLET: return this.tabletContent();
			default: return this.desktopContent();
		}
	}

	getRootStyle = () => {
		switch (MediaType.type) {
			case MediaType.MOBILE: return this.styles.containerMobile;
			case MediaType.TABLET: return this.styles.containerTablet;
			default: return this.styles.container; 
		}
	}

	render() {

	
		return (
			<div style={this.getRootStyle()}>
				<Typography component="h2" variant='display1' align='center' style={this.styles.title}>
					{Translations.get('how_it_works')}
				</Typography>
				{this.getContent()}
			</div>
		);
	}
}
