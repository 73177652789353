import { geocodeByAddress } from 'react-google-places-autocomplete';

let p, f;
const matchType = c => q => ([p, f] = q.split('!'), c
  .filter(({ types: t }) => -1 < t.indexOf(p) && (!f || 0 > t.indexOf(f)))
  .map(({ long_name }) => long_name).shift());

const parseAddress = res => ({
  postcode: matchType(res)('postal_code!postal_code_prefix'),
  city: matchType(res)('locality'),
  street: matchType(res)('route'),
  streetNumber: matchType(res)('street_number')
});

export const getGeocodeByAddress = (option, callback) => {
  return geocodeByAddress(option)
    .then(([result]) => parseAddress(result.address_components))
    .then(address => callback({ ...address, option }))
    .catch(error => console.error(error));
}
