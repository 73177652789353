import React from 'react'
import { connect } from "react-redux";
import PropTypes from 'prop-types';

import { setPopup } from 'Actions/pageActions.js'
import { setValue } from 'Actions/repositoryActions'

import Translations from 'Singleton/Translations'

import { Paper, Grid, Typography, Button } from '@material-ui/core';
import styles from '../../../style';

class container extends React.Component {

	styles = {
		root: {
			width: "30.743vw",
			height: "10.4166vw",
			margin: "2.5vw 0 0 0",
			textAlign: "center",
			paddingTop: "2.2vw"
		},
		text: {
			fontSize: "1.4vw",
		},
		buttonBox: {
			display: "flex",
			justifyContent: "space-around"
		},
		btn: {
			width: "11vw"
		}
	}

	render() {

		return (
			<Paper style={styles.infoBox}>
				<Grid
					container
					direction='column'
					alignItems='center'
					justify='space-between'
					style={{ height: '100%' }}
				>
					<Grid item>
						<Typography variant='body1'>
							{this.props.email}
						</Typography>
					</Grid>
					<Grid item style={{ width: '100%' }}>
						<Grid
							container
							justify='space-between'
						>
							<Grid item>
								<Button
									style={styles.button1}
									variant='outlined'
									onClick={() => { 
										this.props.setAutoFillValue({
											email: this.props.email
										})
										this.props.setPopup('change_email') 
									}}
								>
									{Translations.get('change_email')}
								</Button>
							</Grid>
							<Grid item>
								<Button
									style={styles.button1}
									variant='outlined'
									onClick={() => { this.props.setPopup('change_pass_admin') }}
								>
									{Translations.get('change_password')}
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Paper>
		);
	}
}

container.propTypes = {
	email: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => {
	return {
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (x) => {
			dispatch(setPopup(x));
		},
		setAutoFillValue: x => { dispatch(setValue('autoFill', x)) }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(container);
