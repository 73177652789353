export function setPage(page){
    return {
        type: "SET_PAGE",
        page: page
    };
}

export function redirrect(page, history){
	history.push(page);
	return {
        type: "SET_PAGE",
        page: page
    };
}

export function setPopup(popup){
	return {
		type: "SET_POPUP",
		popup: popup,
	};
}

export function acceptStatement() {
	return {
		type: "ACCEPT_STATEMENT"
	};
}

export function setEvalNumber(val){
	return {
		type: "SET_EVAL_NUMBER",
		eNumber: val
	};
}

export function setPopupFilter(filter){
	return {
		type: "SET_FILTER",
		filter: filter
	};
}

export function setPopupFunc(func=undefined){
	return {
		type: "SET_POPUP_FUNC",
		func: func
	};
}
