import {ValuationFilter} from 'Components/Commons/Const/Valuation'
import Translations from 'Singleton/Translations'

export default class ValuationKeyTranslation {
	static status(key){
		switch (key) {
			case 'new_eval': return Translations.get('new_eval');
			case 'await_accept_eva': return Translations.get('await_accept_eva');
			case 'outdated_eval': return Translations.get('outdated_eval');
			case 'accepted_eval': return Translations.get('accepted_eval');
			case 'rejected_eval': return Translations.get('rejected_eval');
			default: return "__NO_DATA__";
		}
	}

	static FilterOptions(key){
		switch (key) {
			case ValuationFilter.ALL: return Translations.get('valuation_filter_all');
			case ValuationFilter.AUTO: return Translations.get('valuation_filter_auto');
			case ValuationFilter.MANUAL: return Translations.get('valuation_filter_manual');
			default: return "__NO_DATA__";
		}
	}
}
