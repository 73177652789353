import Request from '../libs/RestRequest'

export function setTranslation(lang){
	return dispatch => {
		Request('get','getTranslationMap', {language: lang},
		{
			success: (res) => {
				dispatch({
					type: "TRANSLATION_SET_LANG",
					lang: lang,
					translations: res.body.translationsMap,
				});
			},
			error: (err) => {

			}
		});
	}
}

export function setPalletsCountrysAndLanguage(lang) {
	return dispatch => {
		Request('get', 'getMainFormData', {language: lang}, {
			success: (res) => {
				console.log("RES: ", res.body);
				dispatch({
						type: "DATA_SET_COUNTRYS_PALLETS_LANGUAGE",
						lang: lang,
						translations: res.body.translationsMap,
						countrys: res.body.countrys,
						pallets: res.body.pallets,
						maxOrderWeight: res.body.maxOrderWeight,
						exchangeCurrencyBid: res.body.exchangeBid,
						exchangeCurrencyAsk: res.body.exchangeAsk,
						error: false
				});
			},
			error: (err) => {
				dispatch({
						type: "DATA_SET_COUNTRYS_PALLETS_LANGUAGE",
						lang: lang,
						translations: [],
						countrys: [],
						pallets: [],
						maxOrderWeight: 0,
						exchangeCurrencyBid: 0,
						exchangeCurrencyAsk: 0,
						error: true
				});
			}
		});
	}
}

export function setData(id,value){
	return {
		type: "SET_DATA",
		id: id,
		value: value
	};
}
