import React from "react";
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom'
import { connect } from "react-redux";

import { Paper } from "@material-ui/core"
import { Grid } from "@material-ui/core"
import styles from 'Components/style'
import Typography from '@material-ui/core/Typography'
import FormHelperText from '@material-ui/core/FormHelperText';

import Checkbox from 'Components/Commons/Checkbox';
import TextInput from 'Components/Commons/TextInput'
import TextSelect from 'Components/Commons/TextSelect'

import Translations from 'Singleton/Translations'
import { SupplierName } from "../../Commons/Const/Supplier";
import getRootStyle from './Style'
import { GoodType } from "../../Commons/Const/GoodType";
import MediaType from "../../../libs/MediaType";


class container extends React.Component {

	itemList = [
		{ key: "empty", value: " " },
		{ key: "err", value: Translations.get('palette_arts') },
		{ key: this.props.supplier === SupplierName.RABEN ? 'err' : GoodType.FOOD, value: Translations.get('palette_food') }, //bez rabena
		{ key: "err", value: Translations.get('palette_weapons_ammunition') },
		{ key: GoodType.MILITARY, value: Translations.get('palette_military') },
		{ key: GoodType.WOOD, value: Translations.get('palette_wood') },
		{ key: GoodType.MUSIC, value: Translations.get('palette_music') },
		{ key: GoodType.SWIM, value: Translations.get('palette_swim') },
		{ key: "err", value: Translations.get('palette_precious_stones') },
		{ key: GoodType.MACHINERY, value: Translations.get('palette_machinery') },
		{ key: "err", value: Translations.get('palette_materials') },
		{ key: GoodType.METALS, value: Translations.get('palette_metals') },
		{ key: "err", value: Translations.get('palette_gems') },
		{ key: "err", value: Translations.get('palette_alcohol') },
		{ key: GoodType.ALKOHOL_FREE, value: Translations.get('palette_alkohol_free') },
		{ key: GoodType.PAPER, value: Translations.get('palette_paper') },
		{ key: GoodType.CAR, value: Translations.get('palette_car') },
		{ key: GoodType.MINERAL, value: Translations.get('palette_mineral') },
		{ key: GoodType.PRODUCTS_OF_PLANT, value: Translations.get('palette_products_of_plant') },
		{ key: GoodType.PRODUCTS_OF_ANIMAL, value: Translations.get('palette_products_of_animal') },
		{ key: "err", value: Translations.get('palette_products_of_chemical') },
		{ key: "err", value: Translations.get('palette_collectable') },
		{ key: GoodType.MEDIC, value: Translations.get('palette_medic') },
		{ key: GoodType.ELECTRONIC, value: Translations.get('palette_electronic') },
		{ key: GoodType.AIRCRAFT, value: Translations.get('palette_aircraft') },
		{ key: GoodType.GLASS, value: Translations.get('palette_glass') },
		{ key: GoodType.PLASTIC, value: Translations.get('palette_plastic') },
		{ key: "err", value: Translations.get('palette_tobacco') },
		{ key: GoodType.FAT, value: Translations.get('palette_fat') },
		{ key: GoodType.CERAMIC, value: Translations.get('palette_ceramic') },
		{ key: GoodType.OTHER, value: Translations.get('palette_other') },
		{ key: GoodType.STONE, value: Translations.get('palette_stone') },
		{ key: GoodType.LEATHER, value: Translations.get('palette_leather') },
		{ key: "err", value: Translations.get('palette_animals') },
	];

	validChange(isValid) {
		console.log("isValid = ", isValid);
	}

	categoryIsError(dropdownId) {
		const category = this.props.inputs[dropdownId]
		const isValid = category && category !== 'empty' && category.indexOf('err') < 0;
		return !!this.props.highlightErrors && !isValid;
	}

	getErrorReason() {
		return (
			<span>
				{Translations.get('error_category_not_allowed')}
				<Link style={styles.link}
					target='_blank'
					to="/contact"
				>
					{Translations.get('error_contact_with_us')}
				</Link>{' '}
				{Translations.get('error_for_more_details')}
			</span>
		)
	}

	getCargoDescriptionLabel = () => {
		return `${Translations.get('cargo_description')} ${Translations.get('max_sixty_characters')}`
	}

	render() {
		var inputId = `${this.props.id}-input`;
		var dropdownId = `${this.props.id}-dropdown`;
		var checkboxId = `${this.props.id}-checkbox`;

		return (
			<Paper style={getRootStyle()}>
				<Typography variant='title' paragraph>
					{this.props.title}
				</Typography>
				<Grid
					container
					direction='column'
				>
					<Grid item>
						<Typography variant='body1'>
							{this.props.value}
						</Typography>
					</Grid>

					<Grid item>
						<Grid
							container
							justify='space-between'
							direction='column'
						>
							<Grid item>
								<TextInput
									id={inputId}
									label={this.getCargoDescriptionLabel()}
									width={
										MediaType.isDesktop ?
											styles.moreInfoColumn3.width :
											'100%'
									}
									limit={60}
									required
									errorReason={'To pole jest wymagane'}
									isError={!!this.props.highlightErrors && !this.props.inputs[inputId]}
								/>
							</Grid>
							<Grid item>
								<Checkbox
									id={checkboxId}
									label={<span>Oświadczam, że ładunek nie zawiera <a href="https://treo.pl/faq#czy-sa-rzczy-ktorych-ni-mog-przwozic" target="_blank">towarów zabronionych</a></span>}
								/>

								{!!this.props.highlightErrors && !this.props.inputs[checkboxId] ?
									<FormHelperText error={true} style={{ marginTop: '-6px' }}>
										Potwierdź, że ładunek nie zawiera towarów zabronionych.
									</FormHelperText> : ''}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Paper>
		);
	}
}

container.propTypes = {
	id: PropTypes.string,
	value: PropTypes.string,
	title: PropTypes.string,
}

const mapStateToProps = (state) => ({
	supplier: state.repository.price.supplier,
	highlightErrors: state.input.highlightErrors,
	inputs: state.input.inputState,
})

const mapDispatchToProps = (dispatch) => {
	return {

	}
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(container));
