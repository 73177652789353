import React from 'react'
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';

import RestRequest from 'libs/RestRequest'

import Translation from 'Singleton/Translations'

import { setInputValue } from "Actions/inputActions"
import { setPopup } from 'Actions/pageActions'

import { Grid, Button, Typography } from '@material-ui/core';
import styles from 'Components/style'
import RadioGroup from '../../Components/Commons/RadioGroup';
import Checkbox from '../../Components/Commons/Checkbox'

class container extends React.Component {
	constructor(props) {
		super(props);
		this.handleSuppliersResponse = this.handleSuppliersResponse.bind(this);
		this.getSuppliers();
		this.state = {
			prices: [],
			isLoading: true,
			isCheckboxActive: false,
			functions: {},
		}
		this.createRadioItems = this.createRadioItems.bind(this)
		this.acceptChoice = this.acceptChoice.bind(this)
		this.cancelClick = this.cancelClick.bind(this)
		this.getContent = this.getContent.bind(this);
		this.props.setInputValue('selectSupplierRadio', undefined);
	}

	bindFunction = (name) => (callback) => {
		const f = this.state.functions;
		f[name] = callback;
		this.setState({
			...this.state,
			functions:f,
		})
	}

	styles = {
		anim: {
			margin: `${styles.space30.width} auto`,
			textAlign: 'center',
		}
	}



	cancelClick() {
		this.props.setPopup(undefined);
	}

	getSuppliers() {
		var data = this.props.data;
		var body = {};
		body['palets'] = [];
		for (var item of data.pallets) {
			body['palets'].push({
				paletType: item,
				height: item.height,
				weight: item.weight,
				fragile: item.isFragile,
			});
		}
		body['to'] = {
			country: data.toCountryId,
			postcode: data.toPostcode,
		}
		body['from'] = {
			country: data.fromCountryId,
			postcode: data.fromPostcode,
		}
		body['email'] = data.email
		RestRequest('post', 'secured/getAllPrices', body, this.handleSuppliersResponse, this.props.token);
	}

	handleSuppliersResponse(res) {
		console.log('Supplier res', res);
		const first = Object.keys(res.body.price)[0];
		this.setState({
			...this.state,
			prices: res.body.price,
			isLoading: false,
			isCheckboxActive: res.body.price[first] !== 0,
		})
	}

	createRadioLabel(text, description) {
		return (
			<Grid
				container
				alignItems='baseline'
			>
				<Grid item style={{ marginRight: styles.space16Vertical.height }}>
					<Typography variant='body1'>
						{text}
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant='caption'>
						{description}
					</Typography>
				</Grid>
			</Grid>
		);
	}

	createRadioItems() {
		return Object.keys(this.state.prices).map((key) => {
			var price = this.state.prices[key]
			if (price === 0) {
				price = Translation.get('valuation_not_found')
			} else {
				price = `${price.toFixed(2)} EUR`;
			}
			return {
				label: this.createRadioLabel(
					Translation.get(key),
					price
				),
				key: key
			};
		});
	}

	handleRadioChange= (value) => {
		this.setState({
			...this.state,
			isCheckboxActive: this.state.prices[value] !== 0,
		})
		if(this.state.prices[value] === 0){
			this.state.functions.checkbox(false)
		}
	}

	acceptChoice() {
		if (this.props.popupFunc !== undefined) {
			this.props.popupFunc(this.props.selectedRadio, this.state.prices[this.props.selectedRadio])
		}
		this.props.setPopup(undefined);
	}

	getContent() {
		if (this.state.isLoading) {
			return (
				<div style={this.styles.anim}>
					<CircularProgress
						size={60}
						thickness={3}
					/>
				</div>
			);
		}
		return (
			<Grid
				container
				direction='column'
			>
				<Grid item>
					<RadioGroup
						id="selectSupplierRadio"
						items={this.createRadioItems()}
						onChange={this.handleRadioChange}
					/>
				</Grid>
				<Grid item>
					<Checkbox
						label={Translation.get('valuation_auto_update_prices')}
						id="selectSupplierIsAutomatic"
						disabled={!this.state.isCheckboxActive}
						getSetValueFunc={this.bindFunction('checkbox')}
					/>
				</Grid>
			</Grid>
		);
	}

	render() {
		return (
			<Grid
				container
				direction='column'
			>
				<Grid item>
					{this.getContent()}
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Button
						fullWidth
						variant='contained'
						onClick={this.acceptChoice}
						color='primary'
						disabled={this.props.inputs.selectSupplierRadio === undefined}
					>
						{Translation.get('select_supplier')}
					</Button>
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Button
						fullWidth
						variant='outlined'
						onClick={this.cancelClick}
					>
						{Translation.get('cancel')}
					</Button>
				</Grid>
			</Grid>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		data: state.supplyData,
		token: state.user.token,
		popupFunc: state.page.popupFunc,
		inputs: state.input.inputState,
		selectedRadio: state.input.inputState.selectSupplierRadio,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (x) => {
			dispatch(setPopup(x));
		},
		setInputValue: (id, value) => {
			dispatch(setInputValue(id, value));
		},
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(container));
