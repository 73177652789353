import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';

import { setPage } from '../../Actions/pageActions'

export class ReduxLink extends Component {
	changePage(newPage) {
		this.props.setPage(newPage);
	}

	render() {
		if (this.props.disabled) {
			return (
				<Link
					to="/"
					id={this.props.id}
					className={`${this.props.className} disabled`}
					onClick={this.changePage.bind(this, this.props.to)}>
					{this.props.text}
				</Link>
			);
		} else {
			return (
				<Link
					to={this.props.to}
					id={this.props.id}
					className={this.props.className}
					onClick={this.changePage.bind(this, this.props.to)}>
					{this.props.text}
				</Link>
			);
		}
	}
};

ReduxLink.propTypes = {
	text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
	to: PropTypes.string,
	className: PropTypes.string,
	id: PropTypes.string,
	disabled: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		page: state.page
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPage: (page) => {
			dispatch(setPage(page));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReduxLink);
