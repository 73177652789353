import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, MenuItem, Tooltip } from '@material-ui/core'
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { setInputValue } from 'Actions/inputActions'
import { connect } from "react-redux";
import Browser from 'libs/Browser'

const isPadding = () => {
	return !(Browser.isChrome || Browser.isOpera)
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
	const matches = match(suggestion.label, query);
	const parts = parse(suggestion.label, matches);

	return (
		<MenuItem selected={isHighlighted} component="div">
			<div>
				{parts.map((part, index) => {
					return part.highlight ? (
						<span key={String(index)} style={{ fontWeight: 500 }}>
							{part.text}
						</span>
					) : (
							<strong key={String(index)} style={{ fontWeight: 300 }}>
								{part.text}
							</strong>
						);
				})}
			</div>
		</MenuItem>
	);
}

function getSuggestionValue(suggestion) {
	return suggestion.label;
}

const styles = theme => ({
	root: {
		height: 250,
		flexGrow: 1,
	},
	container: {
		position: 'relative',
	},
	suggestionsContainerOpen: {
		position: 'absolute',
		zIndex: 1,
		marginTop: theme.spacing.unit,
		left: 0,
		right: 0,
	},
	suggestion: {
		display: 'block',
	},
	suggestionsList: {
		margin: 0,
		padding: 0,
		listStyleType: 'none',
	},
	divider: {
		height: theme.spacing.unit * 2,
	},
	input: {
		// display: 'flex',
		// padding: 0,
		paddingBottom: isPadding() ? 7 : undefined,
	}
});

class AutoComplete2 extends React.PureComponent {

	constructor(props) {
		super(props);
		this.state = {
			value: '',
			suggestions: this.props.suggestions,
		};
	}

	componentDidMount() {
		this.props.setInputValue(this.props.id, this.props.defaultValue ? this.props.defaultValue : '')

		if (this.props.defaultValue) {
			this.props.onChange(this.props.defaultValue, this.props.defaultValue);
		}
		if (this.props.bindSetValueFunc) {
			this.props.bindSetValueFunc(this.setValue)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		for (let key in this.props.suggestions) {
			if (typeof this.props.suggestions[key] === 'string') {
				if (this.props.suggestions[key] !== prevProps.suggestions[key]) {
					this.setSuggestionsToState();
					return;
				}
			} else {
				if (this.props.suggestions[key].value !== prevProps.suggestions[key].value) {
					this.setSuggestionsToState();
					return;
				}
			}
		}
	}


	renderInputComponent = (inputProps) => {
		const { classes, inputRef = () => { }, ref, ...other } = inputProps;
		
		let textField = (
			<TextField
				fullWidth
				InputProps={{
					inputRef: node => {
						ref(node);
						inputRef(node);
					},
					classes: {
						input: classes.input,
					},
				}}
				{...other}
			/>
		);

		if (this.props.tooltip) {
			textField = (
				<Tooltip
					title={this.props.tooltip}
					placement='top'
					enterTouchDelay={0}
				>
					{textField}
				</Tooltip>
			)
		}

		return textField;
	}

	setSuggestionsToState = () => {
		this.setState({
			...this.state,
			suggestions: this.props.suggestions,
		})
	}

	setIfMatch = () => {
		const { value } = this.state
		const inList = this.createSuggestions().find(x => x.label === value)
		this.props.setInputValue(this.props.id, inList ? inList.value : '')
	}

	setValue = (value = '') => {
		this.setState({
			...this.state,
			value,
		})
		this.props.setInputValue(this.props.id, value)
	}

	createSuggestions() {
		if (this.state.suggestions.length < 1) {
			return [{
				value: this.props.defaultValue,
				label: this.props.defaultValue
			}]
		}
		return this.state.suggestions.map(item => ({
			value: item.key ? item.key : item,
			label: item.value ? item.value : item,
		}));
	}

	filteredSuggestions() {
		const list = this.createSuggestions();
		const inputValue = this.state.value.toLocaleLowerCase();
		const inputLength = inputValue.length;

		return list.filter(x => {
			const l = x.label ? x.label : '';
			return l.toLowerCase().slice(0, inputLength) === inputValue
		})
	}

	handleSuggestionsFetchRequested = ({ value }) => {

		let v = value;

		if (this.props.toUpperCase) {
			v = v.toUpperCase();
		}
		
		if (this.props.onTextChange) {
			this.props.onTextChange(v);
		}

		this.setState({
			value,
		}, this.setIfMatch);
	};

	handleSuggestionsClearRequested = () => {

	};

	handleSuggestionSelected = (event, { suggestion }) => {
		this.handleChange(suggestion)
	}

	handleTextChange = (event, { newValue }) => {
		
		// this.setState({
		// 	value: newValue,
		// }, this.setIfMatch);
	};

	handleChange = item => {
		this.setState({
			key: item.value,
			value: item.label,
		});
		this.props.setInputValue(this.props.id, item.value);
		if (this.props.onChange) {
			this.props.onChange(item.value);
		}
	}

	shouldRenderSuggestions = () => {
		return true;
	};

	storeInputReference = autosuggest => {
		if (autosuggest !== null) {
			this.input = autosuggest.input;
		}
	}

	getPlaceholder = () => {
		var text = this.props.placeholder ? this.props.placeholder : '';
		return this.props.required ? text += '*' : text;
	}

	handleKeyUp = event => {
		if( event.key === 'Enter' ){
			this.selectFirst();
		}
	}

	selectFirst = () => {
		const list = this.filteredSuggestions()
		this.handleChange(list[0])
	}

	render() {
		const { classes } = this.props;
		const autosuggestProps = {
			renderInputComponent: this.renderInputComponent,
			suggestions: this.filteredSuggestions(),
			onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
			onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
			onSuggestionSelected: this.handleSuggestionSelected,
			getSuggestionValue,
			renderSuggestion,
		};

		return (
			<Autosuggest
				shouldRenderSuggestions={this.shouldRenderSuggestions}
				ref={this.storeInputReference}

				{...autosuggestProps}
				inputProps={{
					classes,
					placeholder: this.getPlaceholder(),
					value: this.state.value,
					onChange: this.handleTextChange,
					style: {
						width: this.props.width
					},
					disabled: this.props.disabled,
					onKeyUp: this.handleKeyUp
				}}
				theme={{
					container: classes.container,
					suggestionsContainerOpen: classes.suggestionsContainerOpen,
					suggestionsList: classes.suggestionsList,
					suggestion: classes.suggestion,
				}}
				renderSuggestionsContainer={options => (
					<Paper {...options.containerProps} square>
						{options.children}
					</Paper>
				)}
			/>
		);
	}
}

AutoComplete2.propTypes = {
	id: PropTypes.string.isRequired,
	classes: PropTypes.object.isRequired,
	width: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	placeholder: PropTypes.string,
	suggestions: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.string),
		PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.any.isRequired,
				value: PropTypes.string.isRequired,
			})
		)
	]).isRequired,
	onChange: PropTypes.func,
	onTextChange: PropTypes.func,
	bindSetValueFunc: PropTypes.func,
	disabled: PropTypes.bool,
	defaultValue: PropTypes.string,
	handleOpenMenu: PropTypes.func,
	required: PropTypes.bool,
	toUpperCase: PropTypes.bool,
	tooltip: PropTypes.string,
}

AutoComplete2.defaultProps = {
	width: '100%',
	disabled: false,
	required: false,
	toUpperCase: false,
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = (dispath) => {
	return {
		setInputValue: (id, value) => {
			dispath(setInputValue(id, value));
		}
	};
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AutoComplete2));
