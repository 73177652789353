import React from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify';

import { setPage } from 'Actions/pageActions';
import sha256 from 'sha256';
import Rest from 'libs/RestRequest'

import Translations from 'Singleton/Translations'
import { Grid, Paper, Typography, Button } from "@material-ui/core";
import styles from "../../Components/style";
import TextInput from "../../Components/Commons/TextInput";
import { TextInputType } from 'Components/Commons/Const/InputTypes'
import queryString from 'query-string'
import MediaType from "../../libs/MediaType";
import { MetaData } from '../../Components/Commons/Const/MetaData'

export class ManualValuation extends React.Component {

	componentWillMount() {
		document.title = MetaData.mainPage.title
	}

	redirect = (path) => {
		this.props.setPage(path);
		this.props.history.push(path);
	}

	styles = {
		container: {
			...styles.moreInfoBox,
			borderLeft: `solid ${styles.palette.green}`
		},
		containerTablet: {
			...styles.moreInfoBoxTablet,
			borderLeft: `solid ${styles.palette.green}`
		},
		containerMobile: {
			...styles.moreInfoBoxMobile,
			borderLeft: `solid ${styles.palette.green}`
		},
		column: {
			width: '495px'
		},
		columnTablet: {
			width: '294px'
		},
		columnMobile: {
			width: '100%'
		}
	}

	showError(msg) {
		toast.error(msg, {
			position: toast.POSITION.TOP_RIGHT,
		});
	}

	showSuccess() {
		toast.success(Translations.get('registered_end'), {
			position: toast.POSITION.TOP_RIGHT,
		})
	}

	handleRegisterClick = () => {
		Rest('post', 'auth/register', {
			loginType: "Email",
			login: this.props.email,
			pssw: sha256.x2(this.props.password),
			language: this.props.lang
		}, this.handleRegisterResponse)
	}

	handleRegisterResponse = (res) => {
		console.log('register', res)
		if (res.body.error) {
			this.showError(res.body.errorReason)
		} else {
			this.showSuccess()
		}
	}

	followButton = (number) => {
		const ser = this.props.location.search;

		return (
			<Button
				variant='outlined'
				fullWidth={MediaType.isMobile}
				onClick={() => {
					let args = this.props.userName ? '' : ser;
					const path = this.props.isAdmin ?
						`/panel/evaluation_details/evaluation/${number}` :
						`/order_details/evaluation/${number}${args}`;
					this.redirect(path)
				}}
			>
				{Translations.get('track_valuation_status')}
			</Button>
		)
	}

	confirmEmailBox = () => {
		return (
			<Paper style={
				MediaType.isDesktop ?
					this.styles.container :
					MediaType.isMobile ?
						this.styles.containerMobile :
						this.styles.containerTablet
			}>
				<Grid
					container
					direction='column'
				>
					<Grid item>
						<Typography variant='headline' paragraph>
							{Translations.get('confirm_the_quote')}
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant='body1' paragraph>
							{Translations.get('confirm_the_quote_info')}
						</Typography>
					</Grid>
				</Grid>
			</Paper>
		)
	}

	infoBox = () => {
		const number = this.props.match.params.number;

		return (
			<Paper style={
				MediaType.isDesktop ?
					this.styles.container :
					MediaType.isMobile ?
						this.styles.containerMobile :
						this.styles.containerTablet
			}>
				<Grid
					container
					direction='column'
				>
					<Grid item>
						<Typography variant='headline' paragraph>
							{Translations.get('valuation_ordered')}
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant='body1' paragraph>
							{Translations.get('valuation_order_info_1')}
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant='body1' paragraph>
							{Translations.get('valuation_order_info_2')}
						</Typography>
					</Grid>
					<Grid item>
						<Grid
							container
							direction={MediaType.isMobile ? 'column' : 'row'}
						>
							<Grid item>
								{this.followButton(number)}
							</Grid>
							<Grid item style={
								MediaType.isMobile ?
									styles.space16Vertical :
									styles.space30
							}>
							</Grid>
							<Grid item>
								<Button
									variant='outlined'
									fullWidth={MediaType.isMobile}
									onClick={() => this.redirect('/')}
								>
									{Translations.get('new_evaluation')}
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Paper>
		)
	}

	passwordColumn() {
		return (
			<Grid
				container
				direction='column'
			>
				<Grid item>
					<Typography variant='body1'>
						{Translations.get('choose_password_info')}
					</Typography>
				</Grid>
				<Grid item>
					<TextInput
						id='password'
						type={TextInputType.PASSWORD}
						label={Translations.get('new_password')}
					/>
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Button
						variant='contained'
						color='primary'
						onClick={this.handleRegisterClick}
						disabled={!this.props.password}
					>
						{Translations.get('set_password')}
					</Button>
				</Grid>
			</Grid>
		)
	}

	registerBox = () => {
		return (
			<Paper style={
				MediaType.isDesktop ?
					styles.moreInfoBox :
					MediaType.isMobile ?
						styles.moreInfoBoxMobile :
						styles.moreInfoBoxTablet
			}>
				<Grid
					container
					direction='column'
				>
					<Grid item>
						<Typography variant='headline' paragraph>
							{Translations.get('register_for_free')}
						</Typography>
					</Grid>
					<Grid item>
						<Grid
							container
							direction={MediaType.isMobile ? 'column' : 'row'}
						>
							<Grid item style={
								MediaType.isDesktop ?
									this.styles.column :
									MediaType.isMobile ?
										this.styles.columnMobile :
										this.styles.columnTablet
							}>
								{this.passwordColumn()}
							</Grid>
							<Grid item style={
								MediaType.isMobile ?
									styles.space16Vertical :
									styles.space30
							}>
							</Grid>
							<Grid item style={
								MediaType.isDesktop ?
									this.styles.column :
									MediaType.isMobile ?
										this.styles.columnMobile :
										this.styles.columnTablet
							}>
								<Typography variant='body1'>
									{Translations.get('privileges_info_1')}:
								</Typography>
								<ul>
									<li><Typography variant='body1'>
										{Translations.get('privileges_info_2')}
									</Typography></li>
									<li><Typography variant='body1'>
										{Translations.get('privileges_info_3')}
									</Typography></li>
									<li><Typography variant='body1'>
										{Translations.get('privileges_info_4')}
									</Typography></li>
								</ul>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Paper>
		)
	}

	render() {
		let { isConfirm } = queryString.parse(this.props.location.search)

		return (
			<Grid
				container
				direction='column'
				style={
					MediaType.isMobile ?
						styles.mobileContainer :
						styles.container
				}
				alignItems='center'
			>
				<Grid item style={styles.space30Vertical}>
				</Grid>
				<Grid item>
					{isConfirm === 'true' ?
						this.infoBox() :
						this.confirmEmailBox()
					}
				</Grid>
				{!this.props.userName ?
					<Grid item>
						{this.registerBox()}
					</Grid> : ''
				}
			</Grid>
		);
	}
}


const mapStateToProps = (state) => {
	return {
		userName: state.user.login,
		email: state.input.inputState.email,
		password: state.input.inputState.password,
		lang: state.translations.lang,
		isAdmin: state.user.isAdmin
	};
};

const mapDispatchToProps = (dispath) => {
	return {
		setPage: (page) => {
			dispath(setPage(page));
		}
	};
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManualValuation));
