import React from 'react'
import { connect } from 'react-redux'

import { setPopup } from 'Actions/pageActions'

import Translations from 'Singleton/Translations'
import { Grid, Typography, Button } from '@material-ui/core';
import TextInput from '../../Components/Commons/TextInput';
import styles from '../../Components/style';
import RestRequest from '../../libs/RestRequest';
import { TextInputType } from '../../Components/Commons/Const/InputTypes';



class container extends React.Component {

	state={
		isEmailValid: false,
	}

	handleResponse = (res) => {
		console.log('password reset', res);
	}
	
	handleButtonClik = () => {
		this.props.setPopup()
		RestRequest('post',
			'password/reset/new',
			{"login": this.props.inputs.email},
			this.handleResponse,
		);
	}

	onValidChange = (value) => {
		this.setState({
			...this.state,
			isEmailValid: value,
		})
	}

	render() {
		return (
			<Grid
				container
				direction='column'
			>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Typography variant='body1'>
						{Translations.get('info_about_changed_password')}
					</Typography>
				</Grid>
				<Grid item>
					<TextInput
						id='email'
						type={TextInputType.EMAIL}
						label={Translations.get('address_email')}
						onValidChange={this.onValidChange}
					/>
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Button
						variant='contained'
						color='primary'
						fullWidth
						onClick={this.handleButtonClik}
						disabled={!this.state.isEmailValid}
					>
						{Translations.get('send_message')}
					</Button>
				</Grid>
			</Grid>
		);
	}

}

const mapStateToProps = (state) => {
	return {
		inputs: state.input.inputState,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (x) => {
			dispatch(setPopup(x));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(container);
