
export default class column {
	static get NAME() {return 'name';}
	static get USE_TYPE() {return 'usetype';}
	static get IS_USED() {return 'is_used';}
	static get VALID_TO() {return 'valid_to';}
	static get CODE() {return 'code';}
	static get CREATION_DATE() {return 'creationdate';}
	static get QUANTITY() {return 'quantity';}
}
