import React from "react";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux";


import { Paper, Grid } from "@material-ui/core";
import Button from '@material-ui/core/Button';

import PriceBox from 'Components/Commons/PriceBox'
import { PriceBoxState } from 'Components/Commons/PriceBox'

import { redirrect, setPopup, setPopupFunc, acceptStatement } from 'Actions/pageActions';
import { setValue } from 'Actions/repositoryActions';
import { setHighlightErrors } from 'Actions/inputActions';

import styles from 'Components/style'
import Translations from 'Singleton/Translations'
import getRootStyle from './Style'
import MediaType from "../../../libs/MediaType";

class container extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			priceBoxState: this.props.priceBoxState === PriceBoxState.MANUAL_ORDER ? PriceBoxState.COMPLETE : this.props.priceBoxState,
		}

		this.loginEnded = this.loginEnded.bind(this);

	}

	styles = {
		priceBox: {
			border: "dotted 1px #ccc",
			textAlign: "center",
			width: "25vw",
			margin: "1vw auto",
			padding: "1vw"
		},
		smallText: {
			margin: "0"
		},
		buttonBox: {
			margin: "1vw auto",
			textAlign: "center",
		},
		button: {
			width: "25vw",
			backgroundColor: "#faaf42"
		}
	}

	loginEnded() {
		this.props.setRepositoryValue('acceptStatement', true);
		this.props.setPopup('statement');
	}

	confirmPurchase() {
		if (!this.props.validation()) {
			this.props.setHighlightErrors();
		}
		else if (this.props.user) {
			this.loginEnded();
		}
		else {
			this.props.setPopupFunc(this.loginEnded);
			this.props.setPopup('login');
		}
	}

	priceBoxClick = () => {
		switch (this.state.priceBoxState) {
			case PriceBoxState.COMPLETE_PLN:
				this.setState({
					...this.state,
					priceBoxState: PriceBoxState.COMPLETE_EUR,
				})
				this.props.setRepositoryValue('priceBoxState', PriceBoxState.COMPLETE_EUR)
				break;
			case PriceBoxState.COMPLETE_EUR:
				this.setState({
					...this.state,
					priceBoxState: PriceBoxState.COMPLETE_PLN,
				})
				this.props.setRepositoryValue('priceBoxState', PriceBoxState.COMPLETE_PLN)
				break;
			default:
				break;
		}
	}

	render() {
		return (
			<Paper style={getRootStyle()}>
				<Grid
					container
					direction='column'
					alignItems='center'
				>
					<Grid item style={
						MediaType.isMobile ?
							styles.mobileBox :
							styles.moreInfoColumn2
					}>
						<PriceBox
							fullWidth
							state={this.state.priceBoxState}
							onClick={this.priceBoxClick}

							priceNet={this.props.price.priceNet}
							priceGross={this.props.price.priceGross}
							priceGrossBeforeDiscount={this.props.price.priceGrossBeforeDiscount}
							discount={this.props.price.discount}
							exchangeRate={this.props.price.exchangeRate}
						/>
					</Grid>
					<Grid item style={styles.space16Vertical}>
					</Grid>
					<Grid item style={
						MediaType.isMobile ?
							styles.mobileBox :
							styles.moreInfoColumn2
					}>
						<Button
							fullWidth
							variant='contained'
							color='primary'
							onClick={this.confirmPurchase.bind(this)}
						>
							{Translations.get('confirm_transport_purchase')}
						</Button>
					</Grid>
				</Grid>
			</Paper>
		);
	}
}

container.propTypes = {
	validation: PropTypes.func
}

const mapStateToProps = (state) => {
	return {
		currencyAsk: state.translations.exchangeCurrencyAsk,
		price: state.repository.price,
		priceBoxState: state.repository.priceBoxState,
		user: state.user.login
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopupFunc: (x) => {
			dispatch(setPopupFunc(x));
		},
		acceptStatement: () => {
			dispatch(acceptStatement());
		},
		redirrect: (page, hist) => {
			dispatch(redirrect(page, hist));
		},
		setPopup: (x) => {
			dispatch(setPopup(x));
		},
		setRepositoryValue: (x, y) => {
			dispatch(setValue(x, y));
		},
		setHighlightErrors: () => {
			dispatch(setHighlightErrors());
		},
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(container));
