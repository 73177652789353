import Translations from 'Singleton/Translations'
import OrderStatus from '../Const/OrderStatus'

export default class OrderKeyTranslation {
	static status(key){
		switch (key) {
			case OrderStatus.NEW: return Translations.get('new_ord');
			case OrderStatus.AWAIT: return Translations.get('await_ord');
			case OrderStatus.IN_PROGRESS: return Translations.get('in_progress_ord');
			case OrderStatus.FORWARD: return Translations.get('forward_ord');
			case OrderStatus.SENT_TO_SUPPLIER: return Translations.get('sent_to_supplier');
			case OrderStatus.TO_SUPPLY: return Translations.get('to_supply_ord');
			case OrderStatus.CANCELED: return Translations.get('canceled_ord');
			default: return "__NO_DATA__";
		}
	}

}
