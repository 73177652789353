import React from 'react'
import { connect } from "react-redux";
import { Base64 } from 'js-base64';
import { withRouter } from 'react-router-dom'
import { sessionService } from 'redux-react-session';
import { toast } from 'react-toastify';
import CircularProgress from '@material-ui/core/CircularProgress'
import RestRequest from 'libs/RestRequest'

import Dropdown from 'Components/Commons/Dropdown'
import Header from 'Components/Body/OrderDetails/Header'

import EmployeeBox from 'Components/Body/Admin/Boxes/Employee'
import EmailBox from 'Components/Body/Admin/Boxes/Email'
import RoleBox from 'Components/Body/Admin/Boxes/Role'

import { setValue } from 'Actions/repositoryActions'
import { setLogin } from 'Actions/userActions'
import { redirrect, setPage } from 'Actions/pageActions';
import { ERROR } from 'libs/RestRequest'
import { Grid, Typography } from '@material-ui/core';
import styles from '../../../Components/style';

import Translations from 'Singleton/Translations'
import DropDownIcon from '@material-ui/icons/ArrowDropDown'

class container extends React.Component {
	constructor(props) {
		super(props);
		const employeeId = Base64.decode(this.props.match.params.employeeId);

		this.state = {
			isLoading: true,
			id: employeeId,
		}
		props.setRepositoryValue('employee', employeeId)

		this.handleResponse = this.handleResponse.bind(this);
		this.handleDeleteResponse = this.handleDeleteResponse.bind(this);
		this.handleSetStatusResponse = this.handleSetStatusResponse.bind(this);
		this.getRole = this.getRole.bind(this);
		this.showError = this.showError.bind(this);
		this.handleErr = this.handleErr.bind(this);
		this.logout = this.logout.bind(this);
		this.refreshData = this.refreshData.bind(this);

		this.props.setRepositoryValue('refreshDataFunc', this.refreshData)

		
		if(!this.props.isAdmin){
			this.props.redirrect('/panel', this.props.history);
		}else{
			this.refreshData()
		}
	}

	refreshData() {
		RestRequest(
			'get',
			'secured/employee',
			{ userId: this.state.id },
			this.handleResponse,
			this.props.userToken
		);
	}


	logout() {
		sessionService.deleteSession().then(
			sessionService.deleteUser().then(() => {
				this.props.setLogin(null, null, null, null, null);
				this.props.redirrect('/panel', this.props.history);
			})
		);
	}

	showError(msg) {
		toast.error(msg, {
			position: toast.POSITION.TOP_RIGHT,
		});
	}

	handleErr(body) {
		switch (body.errorCode) {
			case ERROR.USER_TOKEN_IS_NOT_VALID:
				this.showError(Translations.get('session_expired'))
				this.logout();
				break;
			default:
				this.showError(body.errorReason)
		}
	}

	handleResponse(res) {
		console.log('Employee Res:', res);
		if (res.body.error) {
			this.handleErr(res.body);
			return;
		}
		this.setState({
			...this.state,
			isLoading: false,
			...res.body,
		})
	}

	styles = {
		root: {
			padding: "2vw",
		},
		boxes: {
			display: "flex",
			justifyContent: "space-between",
		},
		loadingAnim: {
			display: 'flex',
			justifyContent: 'center',
			margin: '40vh',
		}
	}

	deleteEmploee() {
		RestRequest(
			'delete',
			'secured/user',
			{ userId: this.state.id },
			this.handleDeleteResponse,
			this.props.userToken
		)
	}

	handleDeleteResponse(res) {
		console.log("Common res:", res);
		if (res.body.error) {
			this.handleErr(res.body);
		} else {
			this.props.redirrect('/panel/employees', this.props.history);
		}
	}

	setStatus(status) {
		console.log("Set status ", status === 'active');
		RestRequest(
			'post',
			'secured/employee/status',
			{
				employeeId: this.state.id,
				isActive: status === 'active',
			},
			this.handleSetStatusResponse,
			this.props.userToken
		)
	}

	handleSetStatusResponse(res) {
		console.log("Common res:", res);
		if (res.body.error) {
			this.handleErr(res.body);
		} else {
			this.refreshData();
		}
	}

	getState() {
		if (this.state.isActive) {
			return Translations.get('active');
		} else {
			return Translations.get('idle');
		}
	}

	getRole() {
		switch (this.state.role) {
			case 'admin': return Translations.get('admin');
			case 'manager': return Translations.get('manager');
			case 'employee': return Translations.get('employee');
			default: return '__NO_DATA__';
		}
	}

	getButtonsMenu() {
		if (this.state.isActive) {
			return [{
				label: Translations.get('inactive'),
				onClick: () => { this.setStatus("inactive"); },
			}, {
				label: Translations.get('remove'),
				onClick: () => { this.deleteEmploee(); },
			}];
		}
		else {
			return [{
				label: Translations.get('active'),
				onClick: () => { this.setStatus("active"); },
			}, {
				label: Translations.get('remove'),
				onClick: () => { this.deleteEmploee() },
			}];
		}
	}

	getDropdownMenu() {
		const label = (
			<Typography>
				{Translations.get('actions')}
				<DropDownIcon style={{width: 20, height: 20}} />
			</Typography>
		)
		return (
			<Dropdown
				variant='outlined'
				label={label}
				rows={this.getButtonsMenu()}
			/>
		);
	}

	getBoxRow() {
		return (
			<Grid container>
				<Grid item>
					<EmployeeBox
						name={this.state.name}
						surname={this.state.surname}
					/>
				</Grid>
				<Grid item style={styles.space30}>
				</Grid>
				<Grid item>
					<EmailBox
						email={this.state.email}
					/>
				</Grid>
				<Grid item style={styles.space30}>
				</Grid>
				<Grid item>
					<RoleBox
						role={this.state.role}
					/>
				</Grid>
			</Grid>
		);
	}

	render() {
		if(!this.props.isAdmin){
			return '';
		}
		if (this.state.isLoading) {
			return (
				<CircularProgress size={80} thickness={5} />
			);
		}
		var title = `${Translations.get('employee')} ${this.state.name} ${this.state.surname}`
		return (
			<Grid
				container
				direction='column'
				style={styles.container}
			>
				<Grid item>
					<Header
						title={title}
						details={this.getState()}
						otherItems={this.getDropdownMenu()}
					/>
				</Grid>
				<Grid item style={styles.space30Vertical}>
				</Grid>
				<Grid item>
					{this.getBoxRow()}
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userToken: state.user.token,
		isAdmin: state.user.isAdmin,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setRepositoryValue: (x, y) => {
			dispatch(setValue(x, y));
		},
		setLogin: (x, y, z, q, t, a) => {
			dispatch(setLogin(x, y, z, q, t, a));
		},
		redirrect: (x, y) => {
			dispatch(redirrect(x, y));
		}
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(container));
