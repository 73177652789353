import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Paper, Grid, Typography } from '@material-ui/core';
import AttachmentIcon from '@material-ui/icons/AttachFile';
import IconButton from '@material-ui/core/IconButton';

import RestRequest from 'libs/RestRequest'

import person1 from '../../../Resources/images/person01.svg'
import person2 from '../../../Resources/images/person02.svg'

import styles from "Components/style";
import Translations from 'Singleton/Translations'
import MediaType from '../../../libs/MediaType';
import queryString from 'query-string'

class container extends React.Component {
	constructor(props) {
		super(props);
		let { token } = queryString.parse(window.location.search)
		if(this.props.userToken){
			token = this.props.userToken;
		}
		this.state = {
			token
		}
		this.downloadFile = this.downloadFile.bind(this);
	}

	styles = {
		container: {
			...styles.paperBox,
			padding: `0px ${styles.paperBox.padding} ${styles.paperBox.padding} 0px`,
			marginTop: styles.space30.width,
		},
		containerMobile: {
			...styles.paperBoxMobile,
			padding: `0px ${styles.paperBoxMobile.padding} ${styles.paperBoxMobile.padding} 0px`,
			marginTop: styles.space16.width,
		}
	}

	downloadFile() {
		console.log('click ', this.props.chatId);
		RestRequest(
			'get',
			'secured/message/file',
			{ messageId: this.props.chatId },
			this.handleResponse,
			this.state.token
		);
	}

	handleResponse(res) {
		console.log("download file", res);
		var url = "data:" + res.body.fileMime + ";base64," + res.body.fileData;
		var anchor = document.createElement("a");
		anchor.href = url;
		anchor.setAttribute("download", res.body.fileName);
		anchor.target = "_blank"
		document.body.appendChild(anchor);
		anchor.click();
		anchor.remove();
	}

	getNameRow() {
		return (
			<Grid
				container
				justify='space-between'
			>
				<Grid item>
					<Typography variant='body2' paragraph>
						<b>
							{this.props.interlocutor}
						</b>
						{' '}
						{this.props.date}
					</Typography>
				</Grid>
				<Grid item>
					{this.props.chatId &&
						<IconButton
							onClick={this.downloadFile}
						>
							<AttachmentIcon />
						</IconButton>
					}
				</Grid>
			</Grid>
		)
	}

	getPaperStyle = () => {
		return MediaType.isMobile ?
			this.styles.containerMobile :
			this.styles.container
	}

	render() {
		var im = this.props.isIconFiled ? person1 : person2;

		return (
			<Paper style={this.getPaperStyle()}>
				<Grid
					container
					wrap='nowrap'
				>
					<Grid item style={{
						...styles.imageColumn,
						marginTop: styles.space30.width
					}}>
						<img src={im} alt={""} style={styles.image1} />
					</Grid>
					<Grid item style={{ width: '100%' }}>
						<Grid
							container
							direction='column'
						>
							<Grid item style={styles.space16Vertical}>
							</Grid>
							<Grid item>
								{this.getNameRow()}
							</Grid>
							<Grid item style={
								MediaType.isMobile ? {
									...styles.mobileBox,
									...styles.breakWord
								} :
									{
										...styles.messageColumn,
										...styles.breakWord
									}
							}>
								<Typography variant='body1'>
									{this.props.message}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Paper>
		);
	}
}

container.propTypes = {
	className: PropTypes.string,
	isIconFiled: PropTypes.bool,
	message: PropTypes.string,
	interlocutor: PropTypes.string,
	chatId: PropTypes.number,
	date: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => {
	return {
		userToken: state.user.token,
		login: state.user.login,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(container);
