import React from "react";

import Checkbox from 'Components/Commons/Checkbox'

import { Paper, Grid, Typography } from "@material-ui/core";

import Translations from 'Singleton/Translations'
import styles from 'Components/style'
import { Link } from 'react-router-dom';
import MediaType from "../../../libs/MediaType";

const createConsentLabel = () => {
	return (
		<div>
			{Translations.get('consent_regulations_1')}{' '}
			<Link
				style={styles.link}
				target='_blank'
				to="/regulations"
			>
				{Translations.get('consent_regulations_2')}
			</Link>{' '}
			{Translations.get('consent_regulations_3')}{' '}
			<Link
				style={styles.link}
				target='_blank'
				to="/privacy_policy"
			>
				{Translations.get('consent_regulations_4')}
			</Link>{' '}
			{Translations.get('consent_regulations_5')}
		</div>
	)
}

const container = () => {
	return (
		<Paper style={
			MediaType.isMobile ?
				styles.paperBoxMobile :
				styles.paperBox
		}>
			<Grid
				container
			>
				<Grid item>
					<Typography variant='title' paragraph>
						{Translations.get('confirmed_agreement')}
					</Typography>
				</Grid>
				<Grid item>
					<Checkbox
						label={Translations.get('processing_data_process_agree')}
						defaultChecked={true}
						disabled={true}
						id="undefined"
					/>
				</Grid>
				<Grid item>
					<Checkbox
						label={createConsentLabel()}
						defaultChecked={true}
						disabled={true}
						id="undefined"
					/>
				</Grid>
				<Grid item>
					<Checkbox
						label={Translations.get('consent-invoices')}
						defaultChecked={true}
						disabled={true}
						id="undefined"
					/>
				</Grid>
			</Grid>
		</Paper>
	);
}

export default container;
