import React from 'react'
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'
import { Base64 } from 'js-base64';
import { sessionService } from 'redux-react-session';
import { toast } from 'react-toastify';

import Header from 'Components/Body/OrderDetails/Header'

import { ERROR } from 'libs/RestRequest'
import Rest from 'libs/RestRequest'

import UserKeyTranslation from 'Components/Commons/Dictionaries/UserKeyTranslation'
import VatUserInfoBox from 'Components/Body/Admin/Boxes/VatUserInfo'
import { PageType } from 'Components/Commons/Const/Type'

import MyOrders from 'Containers/Pages/MyOrders'

import { setValue } from 'Actions/repositoryActions'
import { setPopup, setPopupFunc } from 'Actions/pageActions';
import { setLogin } from 'Actions/userActions'
import { redirrect } from 'Actions/pageActions'

import Translations from 'Singleton/Translations'
import styles from "Components/style";
import Dropdown from 'Components/Commons/Dropdown'

import { Grid, CircularProgress } from "@material-ui/core";
import VatConst from 'Components/Commons/Const/Vat'
import {Typography} from '@material-ui/core'
import DropDownIcon from '@material-ui/icons/ArrowDropDown'


class container extends React.Component {
	constructor(props) {
		super(props);

		var userId = Base64.decode(this.props.match.params.userId);
		this.state = {
			isLoading: true,
			userId: parseInt(userId),
		}
		this.handleResponse = this.handleResponse.bind(this);
		this.delete = this.delete.bind(this);
		this.getButtonsMenu = this.getButtonsMenu.bind(this);
		this.handleStarResponse = this.handleStarResponse.bind(this);
		this.onStarClick = this.onStarClick.bind(this);
		this.onStickerClick = this.onStickerClick.bind(this);
		this.handleStickerResponse = this.handleStickerResponse.bind(this);
		this.refreshData = this.refreshData.bind(this);

		this.props.setRepositoryValue('refreshFunc', this.refreshData)
		this.props.setRepositoryValue('labelData', { type: PageType.USER, userId });

		if(!this.props.isAdmin){
			this.props.redirrect('/panel', this.props.history);
		}else{
			this.refreshData()
		}
	}

	refreshData() {
		Rest(
			'get',
			'secured/user/status',
			{ userId: this.state.userId },
			this.handleResponse,
			this.props.userToken
		)
	}

	logout() {
		sessionService.deleteSession().then(
			sessionService.deleteUser().then(() => {
				this.props.setLogin(null, null, null, null, null);
				this.props.redirrect('/panel', this.props.history);
			})
		);
	}

	showError(msg) {
		toast.error(msg, {
			position: toast.POSITION.TOP_RIGHT,
		});
	}

	handleErr(body) {
		switch (body.errorCode) {
			case ERROR.USER_TOKEN_IS_NOT_VALID:
				this.showError(Translations.get('session_expired'))
				this.logout();
				break;
			case ERROR.USER_NOT_EXIST:
				this.showError(Translations.get('user_not_exist'))
				break;
			default:
				this.showError(body.errorReason)
		}
	}

	handleResponse(res) {
		console.log("client Details", res);
		if (res.body.error) {
			this.handleErr(res.body);
			return;
		}
		var now = new Date();

		this.setState({
			...this.state,
			isLoading: false,
			...res.body,
		})
		this.props.setRepositoryValue('selectedLabels', res.body.labels);
	}

	onStarClick() {
		var val = !this.state.isMarked;
		Rest(
			'post',
			'/secured/user/marker',
			{
				userId: this.state.userId,
				isMarked: val,
			},
			this.handleStarResponse,
			this.props.userToken
		);
		this.setState({
			...this.state,
			isMarked: val,
		});
	}

	handleStarResponse(res) {
		console.log("star res", res);
		if (res.body.error) {
			this.showError(res.body.errorReason);
			this.setState({
				...this.state,
				isMarked: !this.state.isMarked,
			});
		}
	}

	onStickerClick(name) {
		this.setState({
			...this.state,
			deleteLabel: name,
		})
		Rest(
			'post',
			'secured/label/user/cancel',
			{
				userId: this.state.userId,
				label: name,
			},
			this.handleStickerResponse,
			this.props.userToken
		);
	}

	handleStickerResponse(res) {
		console.log("label res", res);
		if (res.body.error) {
			this.showError(res.body.errorReason);
		} else {
			this.setState({
				...this.state,
				labels: this.state.labels.remove(this.state.deleteLabel),
			});
		}
	}

	styles = {
		root: {
			padding: "2vw",
		},
		boxes: {
			display: "flex",
			justifyContent: "space-between",
		},
		secondTitle: {
			marginTop: '2.2vw',
			textAlign: 'left'
		},
		usedCoupons: {
			display: "flex",
			flexWrap: 'wrap',
			justifyContent: "space-between",
		},
		emptyItems: {
			width: "30.743vw",
		}
	}

	handleDeleteSuccess = () => {
		toast.success(Translations.get('user_deleted'), {
			position: toast.POSITION.TOP_RIGHT,
		});
		this.props.redirrect('/panel/client', this.props.history);
	}

	delete() {
		this.props.setRepositoryValue('isBack', true);
		this.props.setRepositoryValue('userId', this.state.userId);
		this.props.setPopupFunc(this.handleDeleteSuccess)
		this.props.setPopup('delete_account');
	}

	setStatus(status) {
		console.log("Set status ", status);
	}

	getButtonsMenu() {
		return [{
			label: Translations.get('remove'),
			onClick: this.delete,
		}];
	}

	getDropdownMenu() {

		const label = (
			<Typography>
				{Translations.get('actions')}
				<DropDownIcon style={{width: 20, height: 20}} />
			</Typography>
		)
		return (
			<Dropdown
				variant='outlined'
				label={label}
				rows={this.getButtonsMenu()}
			/>
		);
	}

	render() {
		if(!this.props.isAdmin){
			return '';
		}

		if (this.state.isLoading) {
			return (
				<CircularProgress size={80} thickness={5} />
			);
		}
		var name = this.state.name ? this.state.name : '';
		var surname = this.state.surname ? this.state.surname : '';
		if (!(name && surname)) {
			var title = this.state.email;
		} else {
			title = `${name} ${surname} (${this.state.email})`;
		}

		return (
			<Grid
				container
				direction='column'
			>
				<Grid item>
					<Grid
						container
						direction='column'
						style={styles.container}
					>
						<Grid item>
							<Header
								title={title}
								details={UserKeyTranslation.userState(this.state.userStatus)}
								state={this.state.userState}
								otherItems={this.getDropdownMenu()}
								stickers={{
									isStarActive: this.state.isMarked,
									items: this.state.labels,
								}}
								onStarClick={this.onStarClick}
								onStickerClick={this.onStickerClick}
							/>
						</Grid>
						{this.state.vatZeroStatus !== VatConst.NONE &&
							<Grid item style={styles.space30Vertical}>
							</Grid>
						}
						<Grid item>
							<VatUserInfoBox
								userId={this.state.userId}
								state={this.state.vatZeroStatus}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<MyOrders
						otherUserId={this.state.userId}
						isAdminView={true}
					/>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		isAdmin: state.user.isAdmin,
		userToken: state.user.token,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setRepositoryValue: (x, y) => {
			dispatch(setValue(x, y));
		},
		setPopup: (x) => {
			dispatch(setPopup(x));
		},
		setLogin: (x, y, z, q, t, a) => {
			dispatch(setLogin(x, y, z, q, t, a));
		},
		redirrect: (x, y) => {
			dispatch(redirrect(x, y));
		},
		setPopupFunc: func => {dispatch(setPopupFunc(func))}
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(container));
