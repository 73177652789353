
export class SupplierName {
	static get RABEN() {return 'raben';}
	static get ROHLIC() {return 'rohlic';}
	static get GEIS() {return 'geis';}
	static get GLS() {return 'gls';}
	static get GET_ALL(){
		return [
			this.RABEN,
			this.ROHLIC,
			this.GEIS,
			this.GLS,
		];
	}
}
