import React from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import { Paper, Typography, Grid, MenuItem, CircularProgress } from "@material-ui/core"
import TextInput from 'Components/Commons/TextInput'
import RadioGroup from 'Components/Commons/RadioGroup'
import FlagSelect from 'Components/Commons/FlagSelect'
import { TextInputType } from 'Components/Commons/Const/InputTypes'
import AutoCompleteRest from 'Components/Commons/AutoCompleteRest'
import {setInputValue} from "Actions/inputActions";

import Translations from 'Singleton/Translations'
import getRootStyle from './Style'
import { setValue } from 'Actions/repositoryActions'
import Rest from 'libs/RestRequest'

import styles from 'Components/style'
import MediaType from "../../../libs/MediaType";

class container extends React.Component {
	constructor(props) {
		super(props);
		var countrys = [];
		for (var country of props.translations.countrys) {
			countrys.push({ key: country.value.trim(), value: country.name.trim() });
		}

		this.state = {
			isOther: false,
			isPostcodeOk: false,
			isVatOk: true,
			countries: countrys,
			functions: [],
			loading: this.props.isVatZero,
			vatData: {}
		}
		this.getSenderField = this.getSenderField.bind(this);

		if (this.props.isVatZero) {
			this.getVatZeroData();
		}
	}

	styles = {
		row: {
			display: "flex",
			justifyContent: "space-between",
			alignItems: "baseline",
		},
		rowRow: {
			display: "flex",
			justifyContent: "space-between",
			alignItems: "flex-end",
			width: "26vw",
		},
		item: {
			width: "30vw",
			textAlign: "left"
		},
		text: {
			width: "14vw",
			margin: "1vw 0 0 0"
		},
		inputTel: {
			width: "22vw",
		},

		hide: {
			display: "none"
		},

		inputStyle: {
			marginTop: -20
		},
		otherOptions: {
			marginTop: "1vw"
		},
		postCode: {
			width: '12vw'
		},
		nameInput: {
			width: "14vw",
		}
	}

	getVatZeroData = () => {
		const body = this.props.userId ? { userId: this.props.userId } : {};
		Rest('get',
			'/secured/vatZeroUserData',
			body,
			this.handleResponse,
			this.props.userToken,
		)
	}

	handleResponse = (res) => {
		const companyId = `${this.props.id}-company`
		const streetId = `${this.props.id}-street`
		const nameId = `${this.props.id}-name`
		const surnameId = `${this.props.id}-surname`
		const telId = `${this.props.id}-tel`
		const countryId = `${this.props.id}-country`
		const postCodeId = `${this.props.id}-postcode`
		const cityId = `${this.props.id}-city`

		const radioDataId = `${this.props.id}-radio-data`
		const nipId = `${this.props.id}-nip`

		this.setState({
			...this.state,
			loading: false,
			vatData: res.body,
			isVatOk: true,
		})
		this.props.setInputValue(radioDataId, "other")

		this.props.setInputValue(companyId, res.body.company)
		this.props.setInputValue(streetId, res.body.street)

		this.props.setInputValue(countryId, res.body.country)
		this.props.setInputValue(postCodeId, res.body.postcode)
		this.props.setInputValue(cityId, res.body.city)
		this.props.setInputValue(nipId, res.body.nip)

		this.props.setInputValue(nameId, res.body.name)
		this.props.setInputValue(surnameId, res.body.surname)
		this.props.setInputValue(telId, res.body.phone)
		this.props.setRepositoryValue('vatZeroData', res.body)
	}

	onRadioChange(value) {
		if(this.state.functions.checkVatValid){
			this.state.functions.checkVatValid();
		}
		this.setState({
			...this.state,
			isOther: value === 'other'
		})
	}


	bindFunction = (name) => (callback) => {
		const f = this.state.functions;
		f[name] = callback;
		this.setState({
			...this.state,
			functions: f,
		})
	}

	nipValidChange = (value) => {
		this.setState({
			...this.state,
			isVatOk: value,
		})
		if(this.props.onNipValidChange){
			this.props.onNipValidChange(value);
		}

	}

	getSenderField(nipId) {
		if (this.props.isCompany) {
			let country;
			switch (this.props.inputs['more-info-billing-radio-data']) {
				case 'loading': country = this.props.inputs['country_from']; break;
				case 'unloading': country = this.props.inputs['country_to']; break;
				case 'other': country = this.props.inputs['more-info-billing-country']; break;
				default:
					break;
			}

			return (
				<Grid
					container
					alignItems='baseline'
				>
					<Grid item>
						<Typography variant='body1'>
							{Translations.get('company_order')}
						</Typography>
					</Grid>
					<Grid item style={styles.space30}>
					</Grid>
					<Grid item style={
						MediaType.isDesktop ?
							styles.moreInfoColumn2 :
							styles.moreInfoColumn4
					}>
						<TextInput
							id={nipId}
							label={Translations.get('optional_nip_vat')}
							type={TextInputType.NIP}
							nipCountry={country}
							onValidChange={this.nipValidChange}
              isError={!!this.props.highlightErrors && (!this.props.inputs[nipId] || !this.state.isVatOk)}
              errorReason={'Wpisz poprawny numer NIP / EU VAT'}
              required
						/>
					</Grid>
				</Grid>
			);
		}
		else {
			return (
				<Typography variant='body1'>
					{Translations.get('order_by_private_person')}
				</Typography>
			);
		}
	}

	handleFlagChange = value => {
		this.state.functions.setPostcode();
		this.state.functions.resetPostcode();
	}

	handlePostcodeChange = value => {
		this.state.functions.setCityName();
		this.state.functions.resetCity();
	}

	receiverRadio = () => {
		const radioReceiverId = `${this.props.id}-radio-reciver`
		return (
			<RadioGroup
				id={radioReceiverId}
				items={[{
					label: Translations.get('sender'),
					key: 'sender'
				}, {
					label: Translations.get('receiver'),
					key: 'receiver'
				}]}
			/>
		)
	}

	getOtherBillingDataForm = () => {
		const companyId = `${this.props.id}-company`
		const streetId = `${this.props.id}-street`
		const nameId = `${this.props.id}-name`
		const surnameId = `${this.props.id}-surname`
		const telId = `${this.props.id}-tel`
		const countryId = `${this.props.id}-country`
		const postCodeId = `${this.props.id}-postcode`
		const cityId = `${this.props.id}-city`

		if (!this.state.isOther) {
			return '';
		}
		return (
			<Grid
				container
				direction='column'
			>
				<Grid item>
					{this.receiverRadio()}
				</Grid>
				<Grid item style={
					MediaType.isDesktop ?
						styles.moreInfoColumn3 :
						{ width: '100%' }
				}>
					<TextInput
						label={Translations.get('company_name')}
						id={companyId}
						noSpecialCharacters
						showErrorOnNotValid
					/>
				</Grid>
				<Grid item>
					<Grid
						container
						justify='space-between'
						alignItems='baseline'
					>
						<Grid item style={
							MediaType.isDesktop ?
								styles.moreInfoColumn1 :
							MediaType.isMobile ?
								styles.mobileBox :
								styles.moreInfoColumn4
						}>
							<TextInput
								required
								label={Translations.get('name')}
								id={nameId}
								noSpecialCharacters
								showErrorOnNotValid
							/>
						</Grid>
						{MediaType.isMobile &&
							<Grid item style={styles.space30}>
							</Grid>
						}
						<Grid item style={
							MediaType.isDesktop ?
								styles.moreInfoColumn1 :
							MediaType.isMobile ?
								styles.mobileBox :
								styles.moreInfoColumn4
						}>
							<TextInput
								required
								label={Translations.get('surname')}
								id={surnameId}
								noSpecialCharacters
								showErrorOnNotValid
							/>
						</Grid>
						{
							MediaType.isDesktop &&
							<Grid item style={styles.space60}>
							</Grid>
						}
						<Grid item style={
							MediaType.isDesktop ?
								styles.moreInfoColumn2 :
								{ width: '100%' }
						}>
							<TextInput
								required
								label={Translations.get('phone_number')}
								id={telId}
								type={TextInputType.NUMBER}
								limit={16}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item style={
					MediaType.isDesktop ?
						styles.moreInfoColumn3 :
						{ width: '100%' }
				}>
					<TextInput
						required
						label={Translations.get('street_and_number')}
						id={streetId}
						type={TextInputType.NOT_EMPTY}
						noSpecialCharacters
						showErrorOnNotValid
					/>
				</Grid>
				<Grid item>
					<Grid
						container
						alignItems='baseline'
					>
						<Grid item style={
							MediaType.isDesktop ?
								styles.moreInfoColumn1 :
							MediaType.isMobile ?
								styles.mobileBox :
								styles.moreInfoColumn4
						}>
							<Grid
								container
								justify='space-between'
								alignItems='baseline'
							>
								<Grid item>
									<Typography variant='body1'>
										{Translations.get('country')}:
									</Typography>
								</Grid>
								<Grid item>
									<FlagSelect
										id={countryId}
										defaultValue='PL'
										onChange={this.handleFlagChange}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item style={styles.space30}>
						</Grid>
						<Grid item style={
							MediaType.isDesktop ?
								styles.moreInfoColumn1 :
							MediaType.isMobile ?
								styles.mobileBox :
								styles.moreInfoColumn4
						}>
							<AutoCompleteRest
								required
								placeholder={`${Translations.get('postal_code')}`}
								id={postCodeId}
								onChange={this.handlePostcodeChange}
								rest={{
									door: "/postcode",
									params: {
										country: this.props.inputs[countryId]
									},
									resFieldName: "postcode",
									inputParamName: "postalcode",
								}}
								bindSetValueFunc={this.bindFunction('setPostcode')}
								bindResetSuggestions={this.bindFunction('resetPostcode')}
						/>
						</Grid>
						<Grid item style={styles.space60}>
						</Grid>
						<Grid item style={
							MediaType.isDesktop ?
								styles.moreInfoColumn2 :
								{ width: '100%' }
						}>
							<AutoCompleteRest
								required
								disabled={this.props.inputs[postCodeId] ? false : true}
								placeholder={Translations.get('city')}
								id={cityId}
								rest={{
									door: "/postcode/city",
									params: {
										country: this.props.inputs[countryId],
										postalcode: this.props.inputs[postCodeId],
										limit: 0,
									},
									resFieldName: "city",
									inputParamName: "city",
								}}
								bindSetValueFunc={this.bindFunction('setCityName')}
								bindResetSuggestions={this.bindFunction('resetCity')}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		);
	}

	getVatZeroDataComponent = () => {
		const data = this.state.vatData;
		return (
			<Grid
				container
				direction='column'
			>
				<Grid item>
					{this.receiverRadio()}
				</Grid>
				<Grid item>
					<Typography variant='body1'>
						{data.company}
					</Typography>
					<Typography variant='body1'>
						{Translations.get('optional_nip_vat')}: {data.nip}
					</Typography>
					<Typography variant='body1'>
						{data.name} {data.surname}
					</Typography>
					<Typography variant='body1'>
						{Translations.get('phone_number')}: {data.phone}
					</Typography>
					<Typography variant='body1'>
						{data.street}
					</Typography>
					<Typography variant='body1'>
						{data.postcode} {data.city}
					</Typography>
					<Typography variant='body1'>
						{data.country}
					</Typography>
				</Grid>
			</Grid>
		)
	}

	getContent = () => {

		if (this.props.isVatZero) {
			return this.getVatZeroDataComponent();
		}
		const radioDataId = `${this.props.id}-radio-data`
		const nipId = `${this.props.id}-nip`
		return (
			<div>
				{this.getSenderField(nipId)}
				<RadioGroup
					id={radioDataId}
					items={[{
						label: Translations.get('loading_place_data'),
						key: 'loading'
					}, {
						label: Translations.get('destination_place_data'),
						key: 'unloading'
					}, {
						label: Translations.get('other_data'),
						key: 'other'
					}]}
					onChange={this.onRadioChange.bind(this)}
				/>
				{this.getOtherBillingDataForm()}
			</div>
		)
	}

	render() {
		return (
			<Paper style={getRootStyle()}>
				<Typography variant='title' paragraph>
					{Translations.get('billing')}
				</Typography>

				{
					this.state.loading ?
						<CircularProgress /> :
						this.getContent()
				}
			</Paper>
		);
	}
}

container.propTypes = {
	id: PropTypes.string.isRequired,
	isCompany: PropTypes.bool.isRequired,
	onNipValidChange: PropTypes.func,
}

const mapStateToProps = (state) => {
	return {
		inputs: state.input.inputState,
		translations: state.translations,
		isVatZero: state.user.isVatZero,
		userToken: state.user.token,
		userId: state.repository.userId,
		counties: state.translations.countrys,
    highlightErrors: state.input.highlightErrors,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setRepositoryValue: (x, y) => {
			dispatch(setValue(x, y));
		},
		setInputValue: (id, value) => {
			dispatch(setInputValue(id, value));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(container);
