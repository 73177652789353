import React from "react";
import {connect} from "react-redux";
import {setPage} from '../Actions/pageActions'
import {Route, withRouter} from 'react-router-dom'
//BrowserRouter as Router,
import OrdersPage from './Pages/Admin/Orders'
import EvaluationPage from './Pages/Admin/Evaluation'
import ClientPage from './Pages/Admin/Client'
import ClientDetailsPage from './Pages/Admin/ClientDetails'
import CouponsPage from './Pages/Admin/Coupons'
import PriceListPage from './Pages/Admin/PriceList'
import EmployeesPage from './Pages/Admin/Employees'
import EmployeesDetailsPage from './Pages/Admin/EmployeeDetails'
import EvaluationDetails from './Pages/Admin/EvaluationDetails'
import CouponDetails from './Pages/Admin/CouponDetails'
import LoginPage from './Pages/Admin/Login'
import styles from "../Components/style";

// import OnlineOrderPage from './Pages/Admin/Orders'


export class BodyAdmin extends React.Component {

	render(){
		return (
			<div style={styles.rootWeb} >
				<Route exact path="/panel" component={LoginPage}/>
				<Route exact path="/panel/orders" component={OrdersPage}/>
				<Route exact path="/panel/evaluation" component={EvaluationPage}/>
				<Route exact path="/panel/evaluation_details/:oeType/:number" component={EvaluationDetails}/>
				<Route exact path="/panel/client" component={ClientPage}/>
				<Route exact path="/panel/client_details/:userId" component={ClientDetailsPage}/>
				<Route exact path="/panel/coupons" component={CouponsPage}/>
				<Route exact path="/panel/coupon_details/:code" component={CouponDetails}/>
				<Route exact path="/panel/price_list" component={PriceListPage}/>
				<Route exact path="/panel/employees" component={EmployeesPage}/>
				<Route exact path="/panel/employee_details/:employeeId" component={EmployeesDetailsPage}/>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
  	return {
	  	page: state.page
  	};
};

const mapDispatchToProps = (dispatch) => {
  	return{
    	setPage: (page) => {
      		dispatch(setPage(page));
    	}
  	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BodyAdmin));
