import React from 'react';
import { connect } from "react-redux";
import { sessionService } from 'redux-react-session';
import { toast } from 'react-toastify';
import sha256 from 'sha256';

import { setPopup } from 'Actions/pageActions'
import { setLogin, setUserVatZero } from 'Actions/userActions'

import Rest from 'libs/RestRequest'
import Checkbox from 'Components/Commons/Checkbox'
import { Grid, Typography, Button } from '@material-ui/core'
import styles from 'Components/style'

import Translations from 'Singleton/Translations'
import TextInput from '../../Components/Commons/TextInput';
import { TextInputType } from 'Components/Commons/Const/InputTypes'
import ErrorCode from '../../Components/Commons/Const/ErrorCode';

class container extends React.Component {
	constructor(props) {
		super(props);
		this.loginUser = this.loginUser.bind(this)
		this.catchRes = this.catchRes.bind(this)
		this.state = {
			showPassword: false,
			validMail: false,
			validPsswd: false,
		}
	}

	clickButton() {
		setTimeout(this.loginUser, 1)
	}

	loginUser() {
		var remember = false;
		if (this.props.inputs['remember-me']) {
			remember = true;
		}
		Rest('post', 'auth/login', {
			login: this.props.inputs.email,
			pssw: sha256.x2(this.props.inputs.password),
			loginType: 'Email',
			remember: remember,
		}, this.catchRes)
	}

	onValidateMailChange(value) {
		this.setState({
			...this.state,
			validMail: value
		});
	}

	onValidatePassChange(value) {
		this.setState({
			...this.state,
			validPsswd: value,
		})
	}

	catchRes(res) {
		console.log(res);
		if (res.body.error) {
			let message;
			switch (res.body.errorCode) {
				case ErrorCode.LOGIN_FAILED:
					message = Translations.get('wrong_email_or_password')
					break;
				default:
					message = `Error ${res.body.errorCode}: ${res.body.errorReason}`
				break;
			}
			toast.error(message, {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {
			var expDate = new Date();
			if (this.props.inputs['remember-me']) {
				expDate = new Date(expDate.getDate() + 30).getTime();
			} else {
				expDate = new Date(expDate.getDate() + 1).getTime();
			}

			var token = { token: res.body.token, expire: expDate };
			var userObj = {
				id: res.body.userId,
				login: res.body.login,
				isAdmin: res.body.isAdmin,
				type: res.body.type,
				isVatZero: res.body.isVatZero,
			}
			sessionService.saveSession(token).then(
				sessionService.saveUser(userObj).then(() => {
					this.props.setPopup(undefined)
					this.props.setLogin(
						res.body.login,
						res.body.token,
						res.body.userId,
						res.body.isAdmin,
						res.body.type,
						undefined,
					);
					this.props.setUserVatZero(res.body.isVatZero);
					if (this.props.func) {
						this.props.func(res.body.login);
					}
					if (this.props.afterLogin) {
						this.props.afterLogin()
					}
				})
			);
		}
	}

	render() {
		return (
			<Grid
				container
				direction='column'
			>
				<Grid item>
					<TextInput
						id="email"
						onValidChange={this.onValidateMailChange.bind(this)}
						type={TextInputType.EMAIL}
						label={Translations.get('address_email')}
					/>
				</Grid>
				<Grid item>
					<TextInput
						fullWidth
						id="password"
						onValidChange={this.onValidatePassChange.bind(this)}
						type={this.state.showPassword ? TextInputType.DEFAULT : TextInputType.PASSWORD}
						label={Translations.get('password')}
					/>
				</Grid>
				<Grid item>
					<Grid
						container
						justify='space-between'
					>
						<Grid item>
							<Checkbox
								id="remember-me"
								label={Translations.get('remember_me')}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<Button
						variant='contained'
						color='primary'
						disabled={!this.state.validMail || !this.state.validPsswd}
						onClick={this.clickButton.bind(this)}
						fullWidth
					>
						{Translations.get('sign_in')}
					</Button>
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Typography 
						variant='body1' 
						style={styles.link}
						align='center'
						onClick={() => { this.props.setPopup('forgot_password') }}
					>
						{Translations.get('forgot_password')}
					</Typography>
				</Grid>
			</Grid>
		);
	}		
}

const mapStateToProps = (state) => {
	return {
		popup: state.page.popup,
		inputs: state.input.inputState,
		lang: state.translations.lang,
		afterLogin: state.repository.afterLogin,
		func: state.page.popupFunc,

	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (x) => {
			dispatch(setPopup(x));
		},
		setLogin: (x, y, z, q, t, a) => {
			dispatch(setLogin(x, y, z, q, t, a));
		},
		setUserVatZero: (x) => {dispatch(setUserVatZero(x));},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(container);
