import React from 'react'
import { connect } from "react-redux";
import { sessionService } from 'redux-react-session';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom'

import RestRequest from 'libs/RestRequest'

import { ERROR } from 'libs/RestRequest'
import { setPopup } from 'Actions/pageActions';
import { setLogin } from 'Actions/userActions';
import { redirrect } from 'Actions/pageActions';

import { PageType } from 'Components/Commons/Const/Type'

import Translations from 'Singleton/Translations'
import { Grid, IconButton, List, ListItem, Typography, Button } from '@material-ui/core'
import TextInput from '../../Components/Commons/TextInput';
import { TextInputType } from '../../Components/Commons/Const/InputTypes'
import Checkbox from '../../Components/Commons/Checkbox'
import AddIcon from '@material-ui/icons/Add'
import DoneIcon from '@material-ui/icons/Done'
import EditIcon from '@material-ui/icons/Edit'
import styles from '../../Components/style'

class container extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			labels: [],
			selectedLabels: new Set(this.props.selectedLabels),
			isEdit: false,
			editLabel: '',
			validNewLabel: false,
			validEditLabel: false,
			editedLabelAlreadyExist: false,
			sendingNewLabel: false,
		}

		this.handleErr = this.handleErr.bind(this);
		this.logout = this.logout.bind(this);
		this.acceptChoice = this.acceptChoice.bind(this)
		this.cancelClick = this.cancelClick.bind(this)
		this.handleResponse = this.handleResponse.bind(this);
		this.getRows = this.getRows.bind(this);
		this.onCheck = this.onCheck.bind(this);
		this.onEditClick = this.onEditClick.bind(this);
		this.setInputChangeValueEditFunc = this.setInputChangeValueEditFunc.bind(this);
		this.setInputChangeValueAddFunc = this.setInputChangeValueAddFunc.bind(this);
		this.confirmEditValue = this.confirmEditValue.bind(this);
		this.confirmNewLabel = this.confirmNewLabel.bind(this);
		this.handleAddLabelResponse = this.handleAddLabelResponse.bind(this);
		this.handleEditLabelResponse = this.handleEditLabelResponse.bind(this);
		this.showError = this.showError.bind(this);
		this.handleAcceptResponse = this.handleAcceptResponse.bind(this);
		this.refreshData = this.refreshData.bind(this);

		this.refreshData();
	}

	refreshData() {
		RestRequest(
			'get',
			'secured/labels',
			{},
			this.handleResponse,
			this.props.token
		)
	}

	styles = {
		text: {
			margin: "0",
			fontSize: "1.3vw"
		},
		input: {
			marginBottom: "1vw",
		},
		halfInput: {
			width: "24vw"
		},
		inputRow: {
			display: "flex",
			justifyContent: "space-between",
			alignItems: 'baseline',
		},
		radioColumn: {
			marginBottom: "1vw"
		},
		icon: {
			width: '2vw',
			height: '2vw'
		},
		list: {
			maxHeight: '300px',
			overflow: 'auto'
		}
	}

	acceptChoice() {
		var gate = '';
		var body;
		switch (this.props.labelData.type) {
			case PageType.ORDER:
				gate = 'secured/labels/order';
				body = { number: this.props.labelData.number }
				break;
			case PageType.VALUATION: gate = 'secured/labels/valuation';
				body = { number: this.props.labelData.number }
				break;
			case PageType.USER: gate = 'secured/labels/user';
				body = { userId: this.props.labelData.userId }
				break;
			default: console.error('Wrong type'); return;
		}
		RestRequest(
			'post',
			gate,
			Object.assign(body, { labels: [...this.state.selectedLabels] }),
			this.handleAcceptResponse,
			this.props.token,
		);
	}

	handleAcceptResponse(res) {
		console.log(res);
		if (res.body.error) {
			this.showError(res.body.errorReason)
		} else {
			if (this.props.refreshFunc) {
				this.props.refreshFunc();
			} else {
				console.warn('No refresh fuction');
			}
			this.props.setPopup();
		}
	}

	logout() {
		sessionService.deleteSession().then(
			sessionService.deleteUser().then(() => {
				this.props.setLogin(null, null, null, null, null);
				this.props.redirrect('/panel', this.props.history);
			})
		);
	}

	showError(msg) {
		toast.error(msg, {
			position: toast.POSITION.TOP_RIGHT,
		});
	}

	handleErr(body) {
		switch (body.errorCode) {
			case ERROR.USER_TOKEN_IS_NOT_VALID:
				this.showError(Translations.get('session_expired'))
				this.logout();
				break;
			default:
				this.showError(body.errorReason)
		}
	}

	handleResponse(res) {
		console.log("Get labels", res);
		if (res.body.error) {
			this.handleErr(res.body);
		}
		this.setState({
			...this.state,
			labels: res.body.list,
		})
	}

	cancelClick() {
		this.props.setPopup(undefined);
	}

	onRowSelection(value) {
		console.log('Row selected', value);
	}

	onCheck(name, isInputChecked) {
		var labels = this.state.selectedLabels;
		if (isInputChecked) {
			labels.add(name)
		} else {
			labels.delete(name)
		}
		this.setState({
			...this.state,
			selectedLabels: labels
		})

	}

	onEditClick(name) {
		console.log('edit click', name);
		this.state.changeEditInputValue(name);
		this.setState({
			...this.state,
			isEdit: true,
			editLabel: name,
			editedLabelAlreadyExist: true,
		})
	}

	getRows() {
		return this.state.labels.map((item, i) => {
			return (
				<ListItem key={i}>
					<Grid
						container
						justify='space-between'
					>
						<Grid item>
							<Checkbox
								id={`checkbox_label_${i}`}
								label={item}
								defaultChecked={this.state.selectedLabels.has(item)}
								onChange={(value) => {
									this.onCheck(item, value);
								}}
							/>
						</Grid>
						<Grid item>
							<IconButton
								onClick={() => { this.onEditClick(item); }}
							>
								<EditIcon />
							</IconButton>
						</Grid>
					</Grid>
				</ListItem>
			);
		});
	}

	setInputChangeValueEditFunc(func) {
		this.setState({
			...this.state,
			changeEditInputValue: func,
		})
	}

	setInputChangeValueAddFunc(func) {
		this.setState({
			...this.state,
			changeAddInputValue: func,
		})
	}

	confirmEditValue() {
		var name = this.props.inputs.editLabel;

		var set = this.state.selectedLabels;
		var isSelected = set.delete(this.state.editLabel)
		if (isSelected) {
			set.add(name);
		}

		var list = this.state.labels;
		var index = list.findIndex((item) => {
			return item === this.state.editLabel;
		});
		var oldName = list[index];
		list[index] = name;

		this.setState({
			...this.state,
			labels: list,
			selectedLabels: set,
			isEdit: false,
		})
		this.state.changeEditInputValue('');
		RestRequest(
			'post',
			'secured/label/update',
			{
				oldLabel: oldName,
				label: name,
			},
			this.handleEditLabelResponse,
			this.props.token,
		);
	}

	confirmNewLabel() {
		var list = [...this.state.labels];
		list.push(this.props.inputs.newLabel)
		this.setState({
			...this.state,
			sendingNewLabel: true,
		})
		RestRequest(
			'post',
			'secured/label/add',
			{ label: this.props.inputs.newLabel },
			this.handleAddLabelResponse,
			this.props.token,
		);
	}

	handleAddLabelResponse(res) {
		console.log('Add label', res);
		if (res.body.error) {
			this.showError(res.body.errorReason)
			this.setState({
				...this.state,
				sendingNewLabel: false,
			})
		} else {
			var list = this.state.labels;
			list.push(this.props.inputs.newLabel)
			this.setState({
				...this.state,
				labels: list,
				sendingNewLabel: false,
			})
			this.state.changeAddInputValue('');
		}
	}

	handleEditLabelResponse(res) {
		console.log('Edit label', res);
		if (res.body.error) {
			this.showError(res.body.errorReason);
			this.refreshData();
		}
	}

	isLabelExist(label) {
		return this.state.labels.find((x) => {
			return x === label;
		}) !== undefined
	}

	addLabelRow() {
		return (
			<Grid
				container
				alignItems='baseline'
			>
				<Grid item style={{ flexGrow: 2 }}>
					<TextInput
						label={Translations.get('new')}
						id="newLabel"
						type={TextInputType.NOT_EMPTY}
						getChangeValueFunc={this.setInputChangeValueAddFunc}
						onValidChange={(value) => {
							this.setState({
								...this.state,
								validNewLabel: value,
							})
						}}
					/>
				</Grid>
				<Grid item>
					<IconButton
						disabled={!this.state.validNewLabel ||
							this.state.sendingNewLabel
						}
						onClick={this.confirmNewLabel}
					>
						<AddIcon />
					</IconButton>
				</Grid>
			</Grid>
		)
	}

	listRow() {
		return (
			<List style={this.styles.list}>
				{this.getRows()}
			</List>
		)
	}

	editRow() {
		return (
			<Grid
				container
				alignItems='baseline'
			>
				<Grid item style={{ flexGrow: 2 }}>
					<TextInput
						id="editLabel"
						label={Translations.get('edit')}
						disabled={!this.state.isEdit}
						value={this.state.editLabel}
						type={TextInputType.NOT_EMPTY}
						getChangeValueFunc={this.setInputChangeValueEditFunc}
						onValidChange={(value) => {
							this.setState({
								...this.state,
								validEditLabel: value,
							})
						}}
						onChange={(x) => {
							this.setState({
								...this.state,
								editedLabelAlreadyExist: this.isLabelExist(x),
							})

						}}
					/>
				</Grid>
				<Grid item>
					<IconButton
						disabled={
							!this.state.isEdit ||
							!this.state.validEditLabel ||
							this.state.editedLabelAlreadyExist
						}
						onClick={this.confirmEditValue}
					>
						<DoneIcon />
					</IconButton>
				</Grid>
			</Grid>
		);
	}

	render() {
		return (
			<Grid
				container
				direction='column'
			>
				<Grid item>
					{this.addLabelRow()}
				</Grid>
				<Grid item>
					{this.listRow()}
				</Grid>
				<Grid item>
					{this.editRow()}
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Button
						variant='contained'
						fullWidth
						color='primary'
						onClick={this.acceptChoice}
					>
						{Translations.get('add')}
					</Button>
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Button
						variant='outlined'
						fullWidth
						onClick={this.cancelClick}
					>
						{Translations.get('cancel')}
					</Button>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		inputs: state.input.inputState,
		token: state.user.token,
		selectedRadio: state.input.inputState.selectSupplierRadio,
		selectedLabels: state.repository.selectedLabels,
		labelData: state.repository.labelData,
		refreshFunc: state.repository.refreshFunc,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (x) => {
			dispatch(setPopup(x));
		},
		setLogin: (x, y, z, q, t, a) => {
			dispatch(setLogin(x, y, z, q, t, a));
		},
		redirrect: (x, y) => {
			dispatch(redirrect(x, y));
		}
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(container));
