import React from 'react'
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import { sessionService } from 'redux-react-session';
import { withRouter } from 'react-router-dom'

import RestRequest from 'libs/RestRequest'
import Radio from 'Components/Commons/RadioGroup'
import Checkbox from 'Components/Commons/Checkbox'
import DatePicker from 'Components/Commons/DatePicker';
import { Typography, Grid, Button } from '@material-ui/core';
import styles from 'Components/style'
import TextInput from 'Components/Commons/TextInput';

import { ERROR } from 'libs/RestRequest'
import { setPopup } from 'Actions/pageActions';
import { setLogin } from 'Actions/userActions';
import { redirrect } from 'Actions/pageActions';

import CouponsRadio from 'Components/Commons/Dictionaries/CouponsRadio';

import Translations from 'Singleton/Translations'

class container extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			dateDisable: true,
			validInput1: false,
			validInput2: false,
			validDate: false,
		}
		this.acceptChoice = this.acceptChoice.bind(this)
		this.cancelClick = this.cancelClick.bind(this)

		this.handleErr = this.handleErr.bind(this);
		this.logout = this.logout.bind(this);
		this.cancelClick = this.cancelClick.bind(this)
		this.handleResponse = this.handleResponse.bind(this);
	}

	styles = {
		text: {
			width: "14vw",
			marginLeft: "1vw",
		},
		radioBox: {
			display: "flex",
		},
		dateBox: {
			display: "flex",
			justifyContent: "space-between",
			alignItems: "flex-end",
			marginBottom: "1vw",
		},
		radioColumn: {
			width: "50%",
			margin: 0,
		},
		input: {
			marginBottom: "1vw",
		}
	}

	acceptChoice() {
		var isDateEndless = this.props.inputs.couponDateEndless;
		var body = {
			name: this.props.inputs.couponName,
			code: this.props.inputs.couponCode,
			useType: this.props.inputs.couponUseType,
			amountType: this.props.inputs.couponAmountType,
			discount: this.props.inputs.couponValue,
			validTo: isDateEndless ? null : this.props.inputs.couponDate,
		}

		RestRequest(
			'post',
			'secured/voucher/add',
			body,
			this.handleResponse,
			this.props.token,
		);
	}

	logout() {
		sessionService.deleteSession().then(
			sessionService.deleteUser().then(() => {
				this.props.setLogin(null, null, null, null, null);
				this.props.redirrect('/panel', this.props.history);
			})
		);
	}

	showError(msg) {
		toast.error(msg, {
			position: toast.POSITION.TOP_RIGHT,
		});
	}

	handleErr(body) {
		switch (body.errorCode) {
			case ERROR.USER_TOKEN_IS_NOT_VALID:
				this.showError(Translations.get('session_expired'))
				this.logout();
				break;
			default:
				this.showError(body.errorReason)
		}
	}

	handleResponse(res) {
		console.log("Add coupon", res);
		this.props.setPopup(undefined);
		if (res.body.error) {
			this.handleErr(res.body);
		} else {
			if (this.props.refreshFunc) {
				this.props.refreshFunc();
			}
		}
		this.props.setPopup(undefined);
	}

	cancelClick() {
		this.props.setPopup(undefined);
	}

	getCouponUseType() {
		return (
			<Grid container>
				<Grid item style={styles.popupColumn1}>
					<Radio
						id="couponUseType"
						items={CouponsRadio.useType()}
						style={this.styles.radioColumn}
					/>
				</Grid>
				<Grid item style={styles.popupColumn1}>
					<Radio
						id="couponAmountType"
						items={CouponsRadio.ammountType()}
						style={this.styles.radioColumn}
					/>
				</Grid>
			</Grid>
		);
	}

	getCouponValue() {
		return (
			<TextInput
				label={Translations.get('coupon_value')}
				id="couponValue"
				type="float"
				isNotZero
				style={this.styles.input}
				onValidChange={(value) => {
					this.setState({
						...this.state,
						validInput2: value,
					})
				}}
			/>
		);
	}

	getCouponExpirationDate() {
		return (
			<Grid container
				justify="space-between"
				alignItems="center"
			>

				<Grid item>
					<Checkbox
						id="couponDateEndless"
						label={Translations.get('lifetime')}
						defaultChecked={true}
						onChange={(value) => {
							this.setState({
								...this.state,
								dateDisable: value,
							})
						}}
					/>
				</Grid>

				<Grid item>
					<DatePicker
						defaultDate={new Date().getTime()}
						hintText={Translations.get('date')}
						id="couponDate"
						disabled={this.state.dateDisable}
						minDate={new Date().getTime()}
						onValidChange={(value) => {
							this.setState({
								...this.state,
								validDate: value,
							})
						}}
					/>
				</Grid>
			</Grid>
		);
	}

	getCouponButtons() {
		return (
			<Grid
				container
				direction="column"
			>
				<Grid item>
					{/* a{this.state.validInput1}
					s{this.state.validInput2}
					d{this.state.validDate}
					s{this.state.dateDisable} */}
					<Button
						onClick={this.acceptChoice}
						disabled={!(this.state.validInput1 && this.state.validInput2 &&
							(this.state.validDate || this.state.dateDisable)
						)}
						fullWidth
						variant='contained'
						color='primary'
					>
						{Translations.get('add_coupon')}
					</Button>
				</Grid>

				<Grid item style={styles.space16Vertical}>
				</Grid>

				<Grid item>
					<Button
						onClick={this.cancelClick}
						fullWidth
						variant='outlined'
					>
						{Translations.get('cancel')}
					</Button>
				</Grid>
			</Grid>
		);
	}

	render() {
		return (
			<Grid
				container
				direction='column'
				justify='space-between'
			>
				<Grid item>
					<TextInput
						label={Translations.get('coupon_promotional_campaign')}
						id="couponName"
						style={this.styles.input}
						type="notEmpty"
						onValidChange={(value) => {
							this.setState({
								...this.state,
								validInput1: value,
							})
						}}
					/>
					<TextInput
						label="Kod kuponu"
						id="couponCode"
						style={this.styles.input}
					/>
				</Grid>

				<Grid item style={styles.space16Vertical}>
				</Grid>

				<Grid item>
					<Typography variant='subheading' paragraph>
						{Translations.get('coupon_use_type')}:
					</Typography>
				</Grid>

				<Grid item>
					{this.getCouponUseType()}
				</Grid>

				<Grid item>
					{this.getCouponValue()}
				</Grid>

				<Grid item style={styles.space16Vertical}>
				</Grid>

				<Grid item>
					{this.getCouponExpirationDate()}
				</Grid>

				<Grid item style={styles.space16Vertical}>
				</Grid>

				<Grid item>
					{this.getCouponButtons()}
				</Grid>
			</Grid>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		inputs: state.input.inputState,
		token: state.user.token,
		refreshFunc: state.repository.refreshFunc,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (x) => {
			dispatch(setPopup(x));
		},
		setLogin: (x, y, z, q, t, a) => {
			dispatch(setLogin(x, y, z, q, t, a));
		},
		redirrect: (x, y) => {
			dispatch(redirrect(x, y));
		}
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(container));
