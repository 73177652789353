import React from 'react';
import { getGeocodeByAddress } from './../../Actions/placesActions';
import MUIPlacesAutocomplete from 'mui-places-autocomplete'

const AutoCompletePlaces = ({ country, onChange, placeholder, forwardRef, inputId, style }) => {
  const [value, setValue] = React.useState({ value: '' });

  forwardRef(() => reset());

  const handleSelect = (_value) => {
    getGeocodeByAddress(_value.description, onChange);
    setValue({value: _value.description})
  };

  const autocompletionRequest = {
    componentRestrictions: {
      country: [country]
    },
  };

  const reset = () => {
    setValue({value: ''})
  };

  const updateValue = (value) => {
    setValue({value});
    onChange(value);
  }

  return (
    <div style={style} className='autocomplete-container'>
      <MUIPlacesAutocomplete
        textFieldProps={{ placeholder, id: inputId, value: value.value, onChange: e => updateValue(e.target.value)}}
        onSuggestionSelected={e => handleSelect(e)}
        createAutocompleteRequest={(input) => Object.assign(autocompletionRequest, { input })}
        renderTarget={() => (<div />)}/>
    </div>)
};

export default AutoCompletePlaces;
