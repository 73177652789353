import React from 'react'
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import FlagIcon from 'Components/Commons/FlagIcon'
import Select from '@material-ui/core/Select';

import { setInputValue } from "Actions/inputActions";
import { Grid } from '@material-ui/core';

class component extends React.Component {
	constructor(params) {
		super(params);

		this.state = {
			selected: this.props.defaultValue,
		}

		if (this.props.bindSetValueFunc) {
			this.props.bindSetValueFunc(this.changeValue)
		}
	}

	componentDidMount() {
		this.props.setInputValue(this.props.id, this.state.selected)
	}

	handleChange = () => event => {
		this.changeValue(event.target.value);
	}

	changeValue = (value) => {
		this.props.setInputValue(this.props.id, value)
		this.setState({
			selected: value,
		})
		if (this.props.onChange) {
			this.props.onChange(value);
		}
	}

	getItems() {
		const sortedCountries = this.props.countries;
		const disabledCountries = this.props.disabledCountries || [];
		return sortedCountries.map((item, i) => {
			const value = item.value;
			return (
				<MenuItem
					key={value}
					value={value}
					disabled={disabledCountries.includes(value)}

				>
					<Grid
						container
						spacing={8}
						alignItems='center'
					>
						<Grid item>
							<FlagIcon code={value.toLowerCase()} />
						</Grid>
						<Grid item>
							<Typography
								variant='body1'
							>
								{item.name}
							</Typography>
						</Grid>
					</Grid>
				</MenuItem>
			);
		});
	}

	getValue(code, textValue) {
		if (textValue) {
			const countryName = this.props.countries.find(({ value: v }) => v === code).name;
			return (
				<span>
					<FlagIcon code={code.toLowerCase()} />
					<span style={{ marginLeft: '12px' }}>{countryName}</span>
				</span>);
		}
		else {
			return <FlagIcon code={code.toLowerCase()} />;
		}
	}

	render() {
		return (
			<Select
				onChange={this.handleChange()}
				value={this.state.selected}
				margin="dense"
				style={{ maxWidth: '120px' }}
				renderValue={(countryCode) => this.getValue(countryCode, this.props.textValue)}
				disabled={this.props.disabled}
			>
				{this.getItems()}
			</Select>
		);
	}
}

component.propTypes = {
	id: PropTypes.string.isRequired,
	defaultValue: PropTypes.string,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	textValue: PropTypes.bool,
	bindSetValueFunc: PropTypes.func,
}

component.defaultProps = {
	disabled: false,
}

const mapStateToProps = (state) => ({
	input: state.input,
	countries: state.translations.countrys,
})

const mapDispatchToProps = (dispath) => {
	return {
		setInputValue: (id, value) => {
			dispath(setInputValue(id, value));
		}
	};
};


export default connect(mapStateToProps, mapDispatchToProps)(component);