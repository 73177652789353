import React from 'react'
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify';
import { sessionService } from 'redux-react-session';

import { withTheme } from '@material-ui/core/styles'
import Dropdown from 'Components/Commons/Dropdown'
import TextSelect from 'Components/Commons/TextSelect'
import FlagSelect from 'Components/Commons/FlagSelect'
import ColumnHeader from 'Components/Body/Admin/ColumnHeader'
import FlagIcon from 'Components/Commons/FlagIcon'
import RestRequest from 'libs/RestRequest'
import { ERROR } from 'libs/RestRequest'

import { setLogin } from 'Actions/userActions'
import { redirrect } from 'Actions/pageActions';

import { Grid, Typography, Button, CircularProgress } from '@material-ui/core'
import { Paper } from '@material-ui/core'
import styles from "Components/style";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DropDownIcon from '@material-ui/icons/ArrowDropDown'


import Translations from 'Singleton/Translations'
import Browser from 'libs/Browser'


const defaultCountry = 'PL'

class container extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hoveredElement: -1,
			hoveredColumn: -1,
			clickedButton: false,
			loading: true,
			fileName: Translations.get('file_name'),
			tableData: [],
			addFileModel: {
				supplierName: "raben",
				iso: defaultCountry,
				isImport: false
			},
			file: null,
			uploadFile: false,
		};
		this.showError = this.showError.bind(this);
		this.handleErr = this.handleErr.bind(this);
		this.logout = this.logout.bind(this);
		this.checkIsAdmin()
	}

	checkIsAdmin = () => {
		if (!this.props.isAdmin) {
			this.props.redirrect('/panel', this.props.history);
		}
	}

	styles = {
		fileName: {
			borderBottom: `dotted 2px ${this.props.theme.typography.display1.color}`,
			flexGrow: 2,
		},
		lastCell: {
			position: 'relative',
		},
		actionButton: {
			position: 'absolute',
			top: '5px',
			right: '5px',
			backgroundColor: '#fff'
		},
	}

	getFileNameStyle = () => {
		if (Browser.isChrome || Browser.isOpera) {
			return {
				...this.styles.fileName,
				paddingBottom: '4px',
			}
		}
		return this.styles.fileName;
	}

	componentDidMount() {
		if (this.props.isAdmin) {
			this.postForSuppliersData()
		}
	}

	onRowEnter(index) {
		this.setState({
			...this.state,
			hoveredElement: index
		})
	}

	onRowLeave(index) {
		if (this.state.clickedButton) {
			return;
		}
		this.setState({
			...this.state,
			hoveredElement: -1
		})
	}

	onClickDropdown() {
		this.setState({
			...this.state,
			clickedButton: true
		})
	}

	onCloseDropdown() {
		this.setState({
			...this.state,
			clickedButton: false,
			hoveredElement: -1
		})
	}


	logout() {
		sessionService.deleteSession().then(
			sessionService.deleteUser().then(() => {
				this.props.setLogin(null, null, null, null, null);
				this.props.redirrect('/panel', this.props.history);
			})
		);
	}

	showError(msg) {
		toast.error(msg, {
			position: toast.POSITION.TOP_RIGHT,
		});
	}

	handleErr(body) {
		switch (body.errorCode) {
			case ERROR.USER_TOKEN_IS_NOT_VALID:
				this.showError(Translations.get('session_expired'))
				this.logout();
				break;
			default:
				this.showError(body.errorReason)
		}
	}

	postForSuppliersData() {
		this.setState({
			...this.state,
			loading: true,
		});
		setTimeout(() => {
			RestRequest('get', 'secured/panel/getSuppliersData', {}, {
				success: (res) => {
					console.log(res.body);
					if (res.body.error) {
						this.handleErr(res.body);
					}
					this.setState({
						...this.state,
						loading: false,
						tableData: res.body.suppliers != null ? res.body.suppliers : []
					})
				},
				error: (err) => {
					toast.warning(Translations.get('server_connection_error'), {
						position: toast.POSITION.TOP_RIGHT,
					});
					this.setState({
						...this.state,
						loading: false,
					});
				}
			}, this.props.token);
		}, 100);
	}

	capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	getDropdownMenu(item, i) {
		if (i !== this.state.hoveredElement) {
			return '';
		}
		const label = (
			<Typography className='table-link'>
				{Translations.get('actions')}
				<DropDownIcon />
			</Typography>
		)
		return (
			<Dropdown
				label={label}
				onClick={() => { this.onClickDropdown() }}
				onClose={() => { this.onCloseDropdown() }}
				rows={[{
					label: Translations.get('remove'),
					onClick: () => {
						RestRequest('post', 'secured/panel/removeSupplierData', item, {
							success: (res) => {
								if (res.body.error) {
									this.handleErr(res.body);
								}
								toast.success(Translations.get('price_list_deleted'), {
									position: toast.POSITION.TOP_RIGHT,
								});
								this.setState({
									...this.state,
									tableData: [],
								})
								this.postForSuppliersData();
							},
							error: (err) => {
								toast.warning(Translations.get('server_connection_error'), {
									position: toast.POSITION.TOP_RIGHT,
								});
							}
						}, this.props.token)
					}
				}]}
				style={this.styles.actionButton}
				variant='outlined'
			/>
		);
	}

	createRows(data) {
		return data.map((item, i) => {
			var name = null;
			for (var country of this.props.countrys) {
				if (country.value === item.iso) {
					name = country.name;
				}
			}

			return (
				<TableRow
					key={i}
					onMouseEnter={() => { this.onRowEnter(i) }}
					onMouseLeave={() => { this.onRowLeave(i) }}
				>
					<TableCell style={styles.tableColumnBase}>
						{this.capitalizeFirstLetter(item.supplierName)}
					</TableCell>
					<TableCell style={styles.tableColumnBase}>
						{item.import ? Translations.get('import') : Translations.get('export')}
					</TableCell>
					<TableCell style={{ ...styles.tableColumnBase, ...this.styles.lastCell }}>
						<Grid container>
							<Grid item>
								<FlagIcon code={item.iso.toLowerCase()} />
							</Grid>
							<Grid item>
								<Typography variant='body1'>
									{name}
								</Typography>
							</Grid>
						</Grid>
						{this.getDropdownMenu(item, i)}
					</TableCell>
				</TableRow>
			)
		});
	}

	setUploadFileState = (value) => {
		this.setState({
			...this.state,
			uploadFile: value,
		})
	}

	sendFile() {
		// checking model
		var body = this.state.addFileModel;
		console.log(body);
		if ([body.supplierName, body.iso, this.state.file].indexOf(null) > -1) {
			toast.warn(Translations.get('file_upload_field_error'), {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {
			this.setUploadFileState(true);
			var token = this.props.token;
			RestRequest('file', 'secured/panel/insertSuplier', body,
				{
					success: (res) => {
						console.log(res.body);
						if (res.body.error) {
							this.handleErr(res.body);
						} else {
							toast.success(`${Translations.get('price_list_added')} ${this.state.fileName}`, {
								position: toast.POSITION.TOP_RIGHT,
							});
							this.setState({
								...this.state,
								fileName: Translations.get('file_name'),
								file: null,
								tableData: [],
							})
							this.postForSuppliersData();
						}
						this.setUploadFileState(false);
					},
					error: (err) => {
						toast.error(Translations.get('server_connection_error'), {
							position: toast.POSITION.TOP_RIGHT,
						});
						this.setUploadFileState(false);
					}
				}, token, this.state.file);
		}
	}

	addButton = () => {
		return (
			<Button
				variant='outlined'
				onClick={() => {
					this.sendFile();
				}}
				disabled={this.state.uploadFile}
				style={styles.button1}
			>
				{
					this.state.uploadFile ?
					<CircularProgress size={16} /> :
					Translations.get('add')}
			</Button>
		)
	}

	getAddFileRow() {
		const suppliers = ["Raben", "Rohlic", "Geis"];
		var exim = ["Export", "Import"];

		return (
			<Grid
				container
				alignItems='baseline'
			>
				<Grid item>
					<TextSelect
						id="supplier"
						items={suppliers}
						onChange={(value) => {
							var addFileModelTemp = this.state.addFileModel;
							addFileModelTemp.supplierName = value.toLowerCase();
							this.setState({
								...this.state,
								addFileModel: addFileModelTemp,
							})
						}}
					/>
				</Grid>
				<Grid item style={styles.space30}></Grid>
				<Grid item>
					<input type="file"
						id="supplierFile"
						style={{ display: 'none' }}
						accept={'.xls, .xlsx, .xlsm'}
						onChange={(e) => {
							this.setState({
								...this.state,
								file: e.target.files[0],
								fileName: e.target.files[0].name,
							})
						}}
					>
					</input>
					<Button
						variant='outlined'
						onClick={() => {
							document.getElementById('supplierFile').click();
						}}
					>
						{Translations.get('choose_file')}
					</Button>
				</Grid>
				<Grid item style={styles.space30}></Grid>
				<Grid item style={this.getFileNameStyle()}>
					<Typography variant='body1' color='textPrimary' noWrap>
						{this.state.fileName}
					</Typography>
				</Grid>
				<Grid item style={styles.space30}></Grid>
				<Grid item>
					<TextSelect
						id="exportImport"
						items={exim}
						onChange={(value) => {
							var addFileModelTemp = this.state.addFileModel;
							addFileModelTemp.isImport = value === "Import";
							this.setState({
								...this.state,
								addFileModel: addFileModelTemp,
							})
						}}
					/>
				</Grid>
				<Grid item style={styles.space30}></Grid>
				<Grid item>
					<FlagSelect
						id="country"
						defaultValue={defaultCountry}
						onChange={(value) => {
							var addFileModelTemp = this.state.addFileModel;
							addFileModelTemp.iso = value;
							this.setState({
								...this.state,
								addFileModel: addFileModelTemp,
							})
						}}
					/>
				</Grid>
				<Grid item style={styles.space30}></Grid>
				<Grid item>
						{this.addButton()}
				</Grid>
			</Grid>
		)
	}

	getHeaders() {
		const export_import = `${Translations.get('export')}/${Translations.get('import')}`
		return (
			<TableRow>
				<ColumnHeader
					style={styles.tableColumn1}
					label={Translations.get('supplier')}
				/>
				<ColumnHeader
					style={styles.tableColumn1}
					label={export_import}
				/>
				<ColumnHeader
					style={styles.tableColumn1}
					label={Translations.get('country')}
				/>
			</TableRow>
		);
	}

	getTable() {
		return (
			<Table>
				<TableHead>
					{this.getHeaders()}
				</TableHead>
				<TableBody>
					{this.createRows(this.state.tableData)}
				</TableBody>
			</Table>
		)
	}

	getPaperBox() {
		return (
			<Paper style={{ ...styles.paperBox, position: 'relative' }}>
				<Grid
					container
					direction='column'
				>
					<Grid item>
						<Typography variant='title'>
							{Translations.get('add')}
						</Typography>
					</Grid>
					<Grid item style={styles.space16Vertical}>
					</Grid>
					<Grid item>
						{this.getAddFileRow()}
					</Grid>
					<Grid item>
						{this.getTable()}
					</Grid>
				</Grid>
			</Paper>
		);
	}


	updatePrices = () => {
		RestRequest('post',
			'secured/refreshSuppliers',
			{},
			this.handleResponse,
			this.props.token,
		)
	}

	handleResponse = (res) => {
		console.log('update prices response', res);
		const err = res.body.error;
		if (err){
			toast.error(res.body.errorReason, {
				position: toast.POSITION.TOP_RIGHT,
			});
		}else{
			toast.success(Translations.get('price_list_updated'), {
				position: toast.POSITION.TOP_RIGHT,
			});
		}
	}

	render() {
		if (!this.props.isAdmin) {
			return '';
		}
		return (
			<div style={styles.tableContainer}>
				<Grid
					container
					direction='column'
				>
					<Grid item>
						<Grid
							container
							justify='space-between'
							alignItems='baseline'
						>
							<Grid item>
								<Typography variant='display1'>
									{Translations.get('price_lists')}
								</Typography>
							</Grid>
							<Grid item>
								<Button
									variant='contained'
									color='primary'
									onClick={this.updatePrices}
									style={styles.button1}
								>
									{Translations.get('update')}
								</Button>
							</Grid>
						</Grid>
					</Grid>
					<Grid item style={styles.space30Vertical}>
					</Grid>
					<Grid item>
						{this.getPaperBox()}
					</Grid>
				</Grid>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		token: state.user.token,
		countrys: state.translations.countrys,
		isAdmin: state.user.isAdmin,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setLogin: (x, y, z, q, t, a) => {
			dispatch(setLogin(x, y, z, q, t, a));
		},
		redirrect: (x, y) => {
			dispatch(redirrect(x, y));
		}
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme()(container)))
