import React, { Component } from 'react'
import { withStyles, Grid, Button, Typography } from '@material-ui/core'
import UE from "../../Resources/images/UE.png"
import MediaType from 'libs/MediaType'


const style = theme => ({
    contener: {
        width: "100%",
        background: "#fff",
        position: "fixed",
        bottom: 0,
        alignItems: "center",
        justifyContent: "space-evenly",
        zIndex: 999
    }
})

export class component extends Component {
    constructor(props) {
        super(props);

        this.showCookiesBar = localStorage.getItem("showCookiesBar") === null ? true : false;
        this.state = {
            show: this.showCookiesBar
        }
    }

    render() {
        if (this.showCookiesBar) {
            return (
                this.state.show &&
                <Grid container className={this.props.classes.contener}>


                    {MediaType.isMobile &&
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <Grid item>
                                <Typography variant='body1' style={{ width: 300, paddingTop: 10, paddingLeft: 10}}>
                                    Stosowane na tej stronie pliki cookie pomagają w personalizacji jej działania oraz innych świadczonych przez Treo usług. Korzystając ze strony, zgadzasz się, abyśmy mogli używać plików cookie.
                            </Typography>
                                <Grid item style={{padding: "10px 0", textAlign: "center"}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        style={{ width: 280, height: 54 }}
                                        onClick={() => {
                                            localStorage.setItem("showCookiesBar", false)
                                            this.setState({
                                                show: false,
                                            })
                                        }}
                                    >
                                        Akceptuję
                             </Button>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <a href="/funding" >
                                <img src={UE} style={{ width: 300, paddingBottom: 10}} alt="European funds picture" />
                                </a>
                            </Grid>
                        </div>
                    }

                    {MediaType.isTablet &&
                        <Grid item style={{ display: "flex", flexDirection: "column", marginTop: 15 }}>
                            <Typography variant='body1' style={{ width: 550, paddingLeft: 12 }}>
                                Stosowane na tej stronie pliki cookie pomagają w personalizacji jej działania oraz innych świadczonych przez Treo usług. Korzystając ze strony, zgadzasz się, abyśmy mogli używać plików cookie.
                         </Typography>
                            <a href="/funding" ><img src={UE} style={{ width: 550 }} alt="European funds picture" /></a>
                        </Grid>
                    }

                    {MediaType.isDesktop &&
                        <>
                            <Grid item>
                                <a href="/funding" >
                                <img src={UE} style={{ width: 670, height: 100 }} alt="European funds picture" />
                                </a>
                            </Grid>
                            <Grid item>
                                <Typography variant='body1' style={{ width: 550 }}>
                                    Stosowane na tej stronie pliki cookie pomagają w personalizacji jej działania oraz innych świadczonych przez Treo usług. Korzystając ze strony, zgadzasz się, abyśmy mogli używać plików cookie.
				                    </Typography>
                            </Grid>
                        </>
                    }

                    {(MediaType.isDesktop || MediaType.isTablet) &&
                        <Grid item>
                            <Button
                                color="primary"
                                variant="contained"
                                style={{ width: 160, height: 54 }}
                                onClick={() => {
                                    localStorage.setItem("showCookiesBar", false)
                                    this.setState({
                                        show: false,
                                    })
                                }}
                            >
                                Akceptuję
                             </Button>
                        </Grid>
                    }
                </Grid>
            )
        } else return ""
    }
}

export default withStyles(style)(component);