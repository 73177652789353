import React from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { sessionService } from 'redux-react-session';
import { toast } from 'react-toastify';
import { Base64 } from 'js-base64';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid, Typography } from "@material-ui/core";

import Column from 'Components/Commons/Column'
import TextInput from 'Components/Commons/TextInput'
import DataBox from 'Components/Body/MyOrders/DataBox'

import RestRequest from 'libs/RestRequest'
import { ERROR } from 'libs/RestRequest'
import { timeFormat } from 'libs/dateformat'

import OrderKeyTranslation from 'Components/Commons/Dictionaries/OrderKeyTranslation'
import ValuationKeyTranslation from 'Components/Commons/Dictionaries/ValuationKeyTranslation'
import OrderStatus from 'Components/Commons/Const/OrderStatus'
import { ValuationStatus } from 'Components/Commons/Const/Valuation'

import { redirrect, setPopupFunc } from 'Actions/pageActions'
import { setLogin } from 'Actions/userActions'
import { setPopup } from 'Actions/pageActions'
import { setValue } from 'Actions/repositoryActions'
import { setInputValue } from "Actions/inputActions";
import { setPaletsCount } from 'Actions/imageBlockFormActions';
import { PriceBoxState } from 'Components/Commons/PriceBox'

import styles from "../../Components/style";

import Translations from 'Singleton/Translations'
import MediaType from "../../libs/MediaType";

import { MetaData } from '../../Components/Commons/Const/MetaData'


export class MyOrders extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			evaluationLoading: true,
			backupEvaluations: [],
			evaluations: [],
			ordersLoading: true,
			backupOrders: [],
			orders: [],
			userId: this.props.otherUserId ? this.props.otherUserId : this.props.userId,
		}
		this.showError = this.showError.bind(this);
		this.handleErr = this.handleErr.bind(this);
		this.logout = this.logout.bind(this);
		this.acceptValuation = this.acceptValuation.bind(this);
		this.handleAcceptValuationResponse = this.handleAcceptValuationResponse.bind(this);
		this.refresh = this.refresh.bind(this);

		if (!this.props.userToken) {
			this.props.setRepositoryValue('afterLogin', this.refresh);
			this.props.setPopup('login');
		}
	}

	componentWillMount() {
		document.title = MetaData.mainPage.title
	}

	styles = {
		searchInput: {
			marginBottom: styles.space30.width,
		}
	}

	logout() {
		sessionService.deleteSession().then(
			sessionService.deleteUser().then(() => {
				this.props.setLogin(null, null, null, null, null);
				this.props.redirrect('/', this.props.history);
			})
		);
	}

	showError(msg) {
		toast.error(msg, {
			position: toast.POSITION.TOP_RIGHT,
		});
	}

	handleErr(body) {
		switch (body.errorCode) {
			case ERROR.USER_TOKEN_IS_NOT_VALID:
				this.showError(Translations.get('session_expired'))
				this.logout();
				break;
			default:
				this.showError(body.errorReason)
		}
	}

	callForEvalData() {
		var data = { userId: this.state.userId };
		setTimeout(function () {
			RestRequest('post', '/secured/getUserEvaluations', data, (res) => {
				console.log('Get valuations', res);
				if (res.body.error) {
					this.handleErr(res.body);
					this.setState({
						...this.state,
						evaluationLoading: false,
					});
				} else {
					this.setState({
						...this.state,
						evaluationLoading: false,
						evaluations: res.body.orders,
						backupEvaluations: res.body.orders
					});
				}
			}, this.props.userToken);
		}.bind(this), 1000);
	}

	callForOrderData() {
		var data = { userId: this.state.userId };
		setTimeout(function () {
			RestRequest('post', '/secured/getUserOrders', data, (res) => {
				console.log('Get orders', res);
				if (res.body.error) {
					this.handleErr(res.body);
					this.setState({
						...this.state,
						ordersLoading: false,
					});
				} else {
					this.callForEvalData();
					this.setState({
						...this.state,
						ordersLoading: false,
						orders: res.body.orders,
						backupOrders: res.body.orders
					});
				}
			}, this.props.userToken);
		}.bind(this), 1000);
	}

	getEvaluationsList() {
		if (this.state.evaluationLoading) {
			return this.getCircleLoading();
		}

		if (this.state.evaluations.length < 1) {
			return this.getInfoText(Translations.get('no_valuations'))
		}

		return this.state.evaluations.map((item, i) => {
			return (
				<DataBox
					key={i}
					id={item.number}
					description={`${item.from} > ${item.to} (${item.palletsCount === null ? 0 : item.palletsCount} ${Translations.get('palette')})`}
					date={timeFormat(item.date)}
					state={item.state}
					status={ValuationKeyTranslation.status(item.state)}
					statusDescription={this.getTileDescription(item)}
					price={item.priceGross === null ? 0 : item.priceGross}
					currency="PLN"
					buttons={this.getEvaluationButtons(item.number, item.state)}
				/>
			);
		});
	}

	getTileDescription(item) {
		if (item.state === OrderStatus.AWAIT ||
			item.state === ValuationStatus.AWAIT) {
			return `(${Translations.get('remains')} ${item.remainedDays} ${Translations.get('days')})`;
		}
		return ' ';
	}

	getCircleLoading = () => {
		return (
			<Grid
				container
				direction='column'
				alignItems='center'
			>
				<Grid item style={styles.space30Vertical}>
				</Grid>
				<Grid item style={styles.space30Vertical}>
				</Grid>
				<Grid item>
					<CircularProgress />
				</Grid>
			</Grid>
		)
	}

	getInfoText = (text) => {
		return (
			<Typography
				variant='subheading'
				style={{
					marginTop: 10,
					color: '#bababa'
				}}
			>
				{text}
			</Typography>
		)
	}

	getOrdersList() {
		if (this.state.ordersLoading) {
			return this.getCircleLoading();
		}


		if (this.state.orders.length < 1) {
			return this.getInfoText(Translations.get('no_orders'))
		}

		return this.state.orders.map((item, i) => {
			const linkId = `proLink_${i}`;
			return (
				<div key={i}>
					<a id={linkId} href={item.proformaLink} style={{ display: 'none' }} />
					<DataBox
						id={item.number}
						description={`${item.from} > ${item.to} (${item.palletsCount} ${Translations.get('palette')})`}
						date={timeFormat(item.date)}
						state={item.state}
						status={OrderKeyTranslation.status(item.state)}
						statusDescription={this.getTileDescription(item)}
						price={item.priceGross === null ? 0 : item.priceGross}
						currency="PLN"
						buttons={this.getOrderButtons(item, linkId)}
					/>
				</div>
			);
		});
	}

	popupOnAccept = () => {
		this.callForEvalData()
	}

	getEvaluationButtons = (number, state) => {
		var btns = [];
		if (state === "new_eval" ||
			state === "outdated_eval" ||
			state === "accepted_eval" ||
			state === "rejected_eval") {
			btns = [{
				name: 'details',
				func: () => {
					var encNumber = Base64.encodeURI(number)
					var history = this.props.history;
					var link = this.props.isAdminView ?
						`/panel/evaluation_details/evaluation/${encNumber}` :
						`/order_details/evaluation/${encNumber}`;
					this.props.redirrect(link, history);
				}
			}];
		} else if (state === "await_accept_eva") {
			btns = [{
				name: 'details',
				func: () => {
					var encNumber = Base64.encodeURI(number)
					var history = this.props.history;
					var link = this.props.isAdminView ?
						`/panel/evaluation_details/evaluation/${encNumber}` :
						`/order_details/evaluation/${encNumber}`;
					this.props.redirrect(link, history);
				}
			},
			{
				name: 'cancel',
				func: () => {
					this.props.setRepositoryValue('number', number);
					this.props.setPopupFunc(this.popupOnAccept);
					this.props.setPopup('valuation_reject');
				}
			},
			{
				name: 'accept',
				func: () => { this.acceptValuation(number); }
			}];
		}
		return btns;
	}

	acceptValuation(number) {
		if (this.props.isAdminView) {
			this.props.setRepositoryValue('priceBoxState', PriceBoxState.COMPLETE)
			this.props.setRepositoryValue('userId', this.props.otherUserId);

			RestRequest(
				'post',
				'secured/getAdminEvaluation ',
				{ number },
				this.handleAcceptValuationResponse,
				this.props.userToken
			)
		} else {
			RestRequest(
				'post',
				'secured/getUserEvaluation',
				{
					userId: this.props.userId,
					number,
				},
				this.handleAcceptValuationResponse,
				this.props.userToken
			)
		}
		this.props.setInputValue('valuation-number', number);
	}

	handleAcceptValuationResponse(res) {
		console.log('accept/ get data', res);
		if (res.body.error) {
			this.showError(res.body.errorReason)
			return;
		}
		var data = res.body;
		if (data.isPrivate) {
			this.props.setInputValue('sender', 'private');
		} else {
			this.props.setInputValue('sender', 'company');
		}

		this.props.setInputValue('post_code_from', data.pickupPostcode);
		this.props.setInputValue('country_from', data.pickupCountry);

		this.props.setInputValue('post_code_to', data.deliveryPostcode);
		this.props.setInputValue('country_to', data.deliveryCountry);

		this.props.setInputValue('lift_car', data.isLiftByDelivery || data.isLiftByPickup);

		this.props.setInputValue('details', data.remarks);
		this.props.setPaletsCount(data.pallets.length);
		this.props.setRepositoryValue('pricePLN', data.amountNet);
		for (var i = 0; i < data.pallets.length; i++) {
			var pallet = data.pallets[i];
			var type = {};
			for (var typeTemp of this.props.palletsTypes) {
				if (typeTemp.type === pallet.type) {
					type = this.props.palletsTypes.indexOf(typeTemp);
				}
			}
			this.props.setInputValue(`palet_height_${i}`, pallet.height);
			this.props.setInputValue(`palet_weight_${i}`, pallet.weight);
			this.props.setInputValue(`fragile_cargo_${i}`, pallet.isFragile);
			this.props.setInputValue(`pallets_size_${i}`, type);
			this.props.setInputValue(`palet_length_${i}`, pallet.customLength);
			this.props.setInputValue(`palet_width_${i}`, pallet.customWidth);
		}
		const price = {
			cost: data.cost,
			priceNet: data.amountNet,
			priceGross: data.amountGross,
			exchangeRate: data.exchangeRate,
			supplier: data.supplier,
		}
		this.props.setRepositoryValue('price', price);
		this.props.redirrect(`/more_info`, this.props.history);
	}

	handleCmrClick = (number) => () => {
		console.log("cmr click", number);
		RestRequest(
			'get',
			'secured/documents',
			{ number },
			this.handleCmrResponse,
			this.props.userToken
		);
	}

	handleCmrResponse = (res) => {
		console.log(res);
		if (res.body.error) {
			this.showError(res.body.errorReason)
			return;
		}
		var url = "data:" + res.body.fileMime + ";base64," + res.body.fileData;
		var anchor = document.createElement("a");
		anchor.href = url;
		anchor.setAttribute("download", res.body.fileName);
		anchor.target = "_blank"
		document.body.appendChild(anchor);
		anchor.click();
		anchor.remove();
	}

	getOrderButtons(item, linkId) {
		const { number, state } = item;

		var btns = [{
			name: 'details',
			func: () => {
				var encNumber = Base64.encodeURI(number)
				const link = this.props.isAdminView ?
					`/panel/evaluation_details/order/${encNumber}` :
					`/order_details/order/${encNumber}`;
				var history = this.props.history;
				this.props.redirrect(link, history);
			}
		}];

		switch (state) {
			case OrderStatus.IN_PROGRESS:
			case OrderStatus.SENT_TO_SUPPLIER:
			case OrderStatus.NEW:
				break;
			case OrderStatus.AWAIT:
				btns.push({
					name: 'invoice',
					func: () => {
						console.log("invoice click");
						document.getElementById(linkId).click();
					}
				});
				break;
			case OrderStatus.FORWARD:
			case OrderStatus.TO_SUPPLY:
				btns.push({
					name: 'cmr',
					func: this.handleCmrClick(number),
				});
				break;
			default:
		}
		return btns;
	}

	componentDidMount() {
		this.refresh();
	}

	refresh() {
		if (this.props.userToken) {
			this.setState({
				...this.state,
				userId: this.props.otherUserId ? this.props.otherUserId : this.props.userId,
				isAdminView: this.props.isAdminView ? this.props.isAdminView : false,
			})

			this.callForOrderData();
		}
	}

	filterStringFields = [
		'from',
		'to',
		'number',
	]

	filterCheck(text, match) {
		const t = text.toLowerCase();
		const x = match.toLowerCase();
		return t.indexOf(x) > -1;
	}

	filter = (item, matching) => {
		const matchTab = matching.split(' ')

		let matchCount = 0
		for (const value of matchTab) {
			let isMatch = false;
			for (const key of this.filterStringFields) {
				if (this.filterCheck(item[key], value)) {
					isMatch = true;
				}
			}
			let k = item['state']
			k = Translations.get(k)
			if (this.filterCheck(k, value)) {
				isMatch = true;
			}

			if (isMatch) {
				matchCount += 1;
			}
		}
		if (matchTab.length === matchCount) {
			return true;
		}
		return false;
	}

	filterEvals = (event) => {
		var val = event;
		if (val === "") {
			this.setState({
				...this.state,
				evaluations: this.state.backupEvaluations
			})
		} else {
			var resultList = this.state.backupEvaluations.filter((value) => {
				return this.filter(value, val)
			})
			this.setState({
				...this.state,
				evaluations: resultList
			})
		}
	}

	filterOrders = (event) => {
		var val = event;
		if (val === "") {
			this.setState({
				...this.state,
				orders: this.state.backupOrders
			})
		} else {
			var resultList = this.state.backupOrders.filter((value) => {
				return this.filter(value, val)
			})
			this.setState({
				...this.state,
				orders: resultList
			})
		}
	}

	render() {
		if (!this.props.userToken) {
			return '';
		}
		const orderTitle = this.props.isAdminView ? Translations.get('orders') : Translations.get('my_orders');
		const valuationTitle = this.props.isAdminView ? Translations.get('evaluations') : Translations.get('my_evaluations');

		return (
			<Grid
				container
				justify='space-between'
				style={
					MediaType.isMobile ?
						styles.mobileContainer :
						styles.container
				}
				direction={
					MediaType.isMobile ?
						'column' : 'row'
				}
			>
				<Grid item>
					<Column
						title={orderTitle}
						button={this.props.isAdminView ? undefined : {
							text: Translations.get('new_order'),
							callback: () => { this.props.redirrect("/", this.props.history) }
						}}
					>
						{(!this.state.ordersLoading
							&& this.state.backupOrders.length > 0
						) &&
							<TextInput
								id='search-my-orders'
								fullWidth
								label={Translations.get('search')}
								onChange={this.filterOrders}
								style={this.styles.searchInput}
							/>
						}
						{this.getOrdersList()}
					</Column>
				</Grid>
				<Grid item>
					<Column
						title={valuationTitle}
						button={this.props.isAdminView ? undefined : {
							text: Translations.get('new_evaluation'),
							callback: () => { this.props.redirrect("/", this.props.history) }
						}}
					>
						{(!this.state.evaluationLoading
							&& this.state.backupEvaluations.length > 0
						) &&
							<TextInput
								id='search-my-valuations'
								fullWidth
								label={Translations.get('search')}
								onChange={this.filterEvals}
								style={this.styles.searchInput}
							/>
						}
						{this.getEvaluationsList()}
					</Column>
				</Grid>
			</Grid>
		);
	}
}

MyOrders.propTypes = {
	otherUserId: PropTypes.number,
	isAdminView: PropTypes.bool,
}

MyOrders.defaultProps = {
	isAdminView: false,
}

const mapStateToProps = (state) => {
	return {
		userToken: state.user.token,
		userEmail: state.user.login,
		userId: state.user.id,
		palletsTypes: state.translations.pallets,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		redirrect: (page, history) => {
			dispatch(redirrect(page, history));
		},
		setLogin: (x, y, z, q, t, a) => {
			dispatch(setLogin(x, y, z, q, t, a));
		},
		setRepositoryValue: (x, y) => {
			dispatch(setValue(x, y));
		},
		setPopup: (x) => {
			dispatch(setPopup(x));
		},
		setInputValue: (x, y) => {
			dispatch(setInputValue(x, y));
		},
		setPaletsCount: (count) => {
			dispatch(setPaletsCount(count));
		},
		setPopupFunc: (f) => {
			dispatch(setPopupFunc(f));
		}
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyOrders));
