import {createStore, combineReducers, applyMiddleware} from "redux";
import thunk from 'redux-thunk';
import initSubscriber from 'redux-subscriber';
import {sessionReducer, sessionService} from 'redux-react-session';
import dropdown from "./Reducers/dropdownReducer";
import translations from "./Reducers/translationReducer";
import page from "./Reducers/pageReducer";
import input from "./Reducers/inputReducer";
import user from "./Reducers/userReducer";
import imageBlockForm from "./Reducers/imageBlockFormReducer";
import supplyData from "./Reducers/supplyData";
import repository from "Reducers/repositoryReducer"
import { composeWithDevTools } from 'redux-devtools-extension';

var middleware = {}
var isLog = true;

if (isLog && (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')) {
	middleware = composeWithDevTools(applyMiddleware(thunk))
} else {
	middleware = applyMiddleware(thunk)
}

const store = createStore(
	combineReducers({dropdown, repository, supplyData, input, translations, page, imageBlockForm, user, session: sessionReducer}),
	{},
	middleware
)

sessionService.initSessionService(store);
initSubscriber(store);


export default store;
