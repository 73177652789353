import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { Paper, Grid, Typography, Button, IconButton } from '@material-ui/core';
import AttachmentIcon from '@material-ui/icons/AttachFile';

import ChatMessageBox from './ChatMessage'

import Column from 'Components/Commons/Column'
import TextInput from 'Components/Commons/TextInput'
import { TextInputType } from 'Components/Commons/Const/InputTypes'

import RestRequest from 'libs/RestRequest'
import { ERROR } from 'libs/RestRequest'

import { redirrect } from 'Actions/pageActions'
import { setLogin } from 'Actions/userActions'
import { setValue } from 'Actions/repositoryActions'

import styles from "Components/style";

import Translations from 'Singleton/Translations'
import MediaType from '../../../libs/MediaType';
import CancelIcon from '@material-ui/icons/Cancel'
import queryString from 'query-string'

import { timeFormatDots } from 'libs/dateformat'

const fileExtension = /(?:\.([^.]+))?$/;

class Chat extends React.Component {
	constructor(props) {
		super(props);
		let { token } = queryString.parse(this.props.location.search)
		if(this.props.userToken){
			token = this.props.userToken;
		}

		this.state = {
			token,
			isLoading: true,
			isFile: false,
			lastIdMessage: -1,
			isInputChatValid: false,
		}
		this.getMessages = this.getMessages.bind(this);
		this.handleMsgResponse = this.handleMsgResponse.bind(this);
		this.loadFile = this.loadFile.bind(this);
		this.sendFile = this.sendFile.bind(this);
		this.sendMsg = this.sendMsg.bind(this);
		this.handleFileResponse = this.handleFileResponse.bind(this);
		this.handleResponse = this.handleResponse.bind(this);
		this.askForMessages = this.askForMessages.bind(this);
		this.setMessageField = null;

		this.props.setRefreshFunc(this.refreshMessages)
		this.askForMessages();
	}

	styles = {
		container: {
			...styles.paperBox,
			padding: `0px ${styles.paperBox.padding}`,
			paddingBottom: styles.space16Vertical.height,
			marginTop: styles.space30.width,
		},
		containerMobile: {
			...styles.paperBoxMobile,
			padding: `0px ${styles.paperBoxMobile.padding}`,
			marginTop: styles.space16.width,
			paddingBottom: styles.space16Vertical.height,
			minHeight: '180px'
		},
		chatFileName: {
			maxWidth: '400px'
		}
	}

	refreshMessages = () => {
		this.setState({
			...this.state,
			isLoading: true,
		})
		this.askForMessages();
	}

	askForMessages() {
		RestRequest(
			'get',
			'secured/messages',
			{ orderNumber: this.props.number },
			this.handleResponse,
			this.state.token
		);
	}

	handleResponse(res) {
		console.log('Get messages', res);
		if (res.body.error) {
			return;
		}

		this.setState({
			...this.state,
			list: res.body.list,
			isLoading: false,
		})
	}

	getMessages() {
		return this.state.list.map((item, i) => {
			var interlocutor;
			const date = `(${timeFormatDots(item.timestamp)})`;
			if (item.name || item.surname) {
				interlocutor = `${item.name} ${item.surname}`;
			} else {
				interlocutor = `${item.email}`;
			}
			return (
				<ChatMessageBox
					key={i}
					isIconFiled={item.isUser}
					interlocutor={interlocutor}
					message={item.message}
					chatId={item.chatId}
					date={date}
				/>
			);
		});
	}

	sendMsg() {
		RestRequest(
			'post',
			'secured/message',
			{
				message: this.props.chatInput,
				number: this.props.number,
			},
			this.handleMsgResponse,
			this.state.token
		)
	}

	handleMsgResponse(res) {
		console.log('Msg response', res);
		this.setMessageField("");
		if (res.body.error) {
			return;
		}
		this.setState({
			...this.state,
			loading: true,
			lastIdMessage: res.body.chatId,
		})
		this.sendFile();
	}

	//send file for lastIdMessage
	sendFile() {
		if (!this.state.isFile) {
			this.askForMessages();
			return;
		}
		console.log("Send file:", this.state.fileName);
		RestRequest(
			'file',
			'secured/message/file',
			{
				messageId: parseInt(this.state.lastIdMessage),
				fileName: this.state.fileName,
			},
			this.handleFileResponse,
			this.state.token,
			this.state.file
		)

		this.setState({
			...this.setState,
			isFile: false,
		});
	}

	handleChatValidChange = (value) => {
		this.setState({
			...this.state,
			isInputChatValid: value
		});
	}

	handleFileResponse(res) {
		console.log('Send file response', res);
		this.askForMessages();
	}

	loadFile() {
		document.getElementById('chatFile').click();
	}

	getColumnStyle = () => {
		switch (MediaType.type) {
			case MediaType.TABLET: return {
				paddingTop: 0,
			}
			case MediaType.MOBILE: return {
				width: '100%',
			}
			default: return {

			};
		}
	}

	buttonAddFile = () => {
		return (
			<Button
				variant='outlined'
				style={
					MediaType.isMobile ?
						{} : styles.button1
				}
				onClick={this.loadFile}
				fullWidth={MediaType.isMobile}
			>
				{Translations.get('add_file')}
			</Button>
		)
	}

	cancelFile = () => {
		this.setState({
			...this.state,
			isFile: false,
			file: null,
			fileName: null,
		})
	}

	fileNameRow = () => {
		return (
			<Grid
				container
				alignItems='baseline'
				wrap='nowrap'
			>
				<Grid item>
					<IconButton
						style={{
							width: '30px',
							height: '30px',
						}}
						onClick={this.cancelFile}
					>
						<CancelIcon
							style={{
								fontSize: '20px'
							}}
						/>
					</IconButton>
				</Grid>
				<Grid item 
					style={
						MediaType.isMobile ?
						{width: '90%'} :
						{}
					}
				>
					<Typography
						noWrap
						variant='body1'
						style={
							MediaType.isMobile ?
							{width: '90%'} :
							this.styles.chatFileName
						}
					>
						{this.state.fileName}
					</Typography>
				</Grid>
			</Grid>
		);
	}

	render() {
		if (this.state.isLoading) {
			return '';
		}
		return (
			<Column
				title={Translations.get('messages')}
				style={this.getColumnStyle()}
			>
				{this.getMessages()}

				<Paper style={
					MediaType.isMobile ?
						this.styles.containerMobile :
						this.styles.container
				}>
					<Grid
						container
						direction='column'
					>
						<Grid item>
							<Grid item style={styles.space16Vertical}></Grid>
							<TextInput
								id="chat"
								limit={5000}
								type={TextInputType.NOT_EMPTY}
								onValidChange={this.handleChatValidChange}
								label={Translations.get('send_message_to_treo')}
								getChangeValueFunc={(changeFunc) => {
									this.setMessageField = changeFunc;
								}}
							/>
						</Grid>
						<Grid item style={styles.space16Vertical}>
						</Grid>
						<Grid item>
							<Grid
								container
								direction={MediaType.isMobile ? 'column' : 'row'}
								alignItems='baseline'
							>
								{MediaType.isMobile &&
									<Grid item style={{ flexGrow: 2 }}>
									</Grid>
								}

								<Grid item
									style={MediaType.isMobile ?
										{ width: '100%' } : {}
									}>
									<Button
										variant='outlined'
										style={
											MediaType.isMobile ?
												{} : styles.button1
										}
										fullWidth={MediaType.isMobile}
										onClick={this.sendMsg}
										disabled={!this.state.isInputChatValid}
									>
										{Translations.get('send')}
									</Button>
								</Grid>
								<Grid item style={
									MediaType.isMobile ?
										styles.space16Vertical :
										styles.space30
								}>
								</Grid>
								<Grid item
									style={MediaType.isMobile ?
										{ width: '100%' } : {}
									}
								>
									{
										this.state.isFile ?
											this.fileNameRow() :
											this.buttonAddFile()
									}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Paper>
				<input type="file"
					id="chatFile"
					style={{ display: 'none' }}
					onChange={(e) => {
						const fileCut = 120;
						var fn = e.target.files[0].name;
						if (fn.length > fileCut) {
							const ext = fileExtension.exec(fn)[1];
							fn = fn.slice(0, fileCut);
							if (ext) {
								fn += `.${ext}`;
							}
						}

						this.setState({
							...this.state,
							isFile: true,
							file: e.target.files[0],
							fileName: fn,
						})
						
						e.target.value=''

					}}
				>
				</input>
			</Column>
		);
	}
}

Chat.propTypes = {
	className: PropTypes.string,
	number: PropTypes.string,
	type: PropTypes.string,
};

const mapStateToProps = (state) => {
	return {
		userToken: state.user.token,
		userId: state.user.id,
		chatInput: state.input.inputState.chat,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		redirrect: (page, history) => {
			dispatch(redirrect(page, history));
		},
		setLogin: (x, y, z, q, t, a) => {
			dispatch(setLogin(x, y, z, q, t, a));
		},
		setRefreshFunc: (func) => dispatch(setValue('chatRefreshFunc',func)),
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Chat));
