import React from 'react'
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify';
import { Base64 } from 'js-base64';
import { sessionService } from 'redux-react-session';

import Rest from 'libs/RestRequest'
import Dropdown from 'Components/Commons/Dropdown'
import Bar from 'Components/Body/Admin/OrdersBar'
import ColumnHeader from 'Components/Body/Admin/ColumnHeader'
import { timeFormatDate } from 'libs/dateformat'
import VoucherColumn from 'Components/Commons/Const/VoucherColumn'

import { ERROR } from 'libs/RestRequest'
import { setPopup } from 'Actions/pageActions'
import { setLogin } from 'Actions/userActions'
import { setValue } from 'Actions/repositoryActions'
import { redirrect } from 'Actions/pageActions'
import UserKeyTranslation from 'Components/Commons/Dictionaries/UserKeyTranslation'

import { Grid, Typography } from '@material-ui/core'
import { Paper } from '@material-ui/core'
import styles from "Components/style";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress'
import DropDownIcon from '@material-ui/icons/ArrowDropDown'

import Translations from 'Singleton/Translations'

class container extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hoveredElement: -1,
			hoveredColumn: -1,
			clickedButton: false,
			tableData: [],
			isLoading: true,
			currentPage: 0,
			pageItemLimit: 10,
			lenghtData: 140,
			orderColumn: VoucherColumn.NAME,
			orderDesc: true,
			filters: [],
		};

		this.formatTermOfValidity = this.formatTermOfValidity.bind(this);
		this.formatStatus = this.formatStatus.bind(this);
		this.formatType = this.formatType.bind(this);

		this.handleResponse = this.handleResponse.bind(this);
		this.logout = this.logout.bind(this);
		this.handleErr = this.handleErr.bind(this);
		this.showError = this.showError.bind(this);
		this.getActionButtons = this.getActionButtons.bind(this);
		this.refreshData = this.refreshData.bind(this);
		this.onNextButtonClick = this.onNextButtonClick.bind(this);
		this.onPreviousButtonClick = this.onPreviousButtonClick.bind(this);
		this.setFilter = this.setFilter.bind(this);
		this.deleteFilter = this.deleteFilter.bind(this);
		this.handleStatusResponse = this.handleStatusResponse.bind(this);

		this.checkIsAdmin()
	}

	checkIsAdmin = () => {
		if(!this.props.isAdmin){
			this.props.redirrect('/panel', this.props.history);
		}
	}
	
	refreshData() {
		Rest('post',
			'secured/vouchers',
			{
				orderBy: this.state.orderColumn,
				limit: this.state.pageItemLimit,
				offset: this.state.currentPage * this.state.pageItemLimit,
				desc: this.state.orderDesc,
				filters: this.state.filters
			},
			this.handleResponse,
			this.props.userToken
		);
	}

	logout() {
		sessionService.deleteSession().then(
			sessionService.deleteUser().then(() => {
				this.props.setLogin(null, null, null, null, null);
				this.props.redirrect('/panel', this.props.history);
			})
		);
	}

	showError(msg) {
		toast.error(msg, {
			position: toast.POSITION.TOP_RIGHT,
		});
	}

	handleErr(body) {
		switch (body.errorCode) {
			case ERROR.USER_TOKEN_IS_NOT_VALID:
				this.showError(Translations.get('session_expired'))
				this.logout();
				break;
			default:
				this.showError(body.errorReason)
		}
	}

	handleResponse(res) {
		console.log("coupons", res);
		if (res.body.error) {
			this.handleErr(res.body);
			return;
		}
		this.setState({
			...this.state,
			isLoading: false,
			tableData: res.body.list,
			lenghtData: res.body.rowsCount,
		})
	}


	styles = {
		loadingAnimBG: {
			width: "100%",
			position: "absolute",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			background: "rgba(100,100,100,0.5)",
			display: "block",
			textAlign: 'center',
			paddingTop: '40px',
		},
		lastCell: {
			position: 'relative',
		},
		breakWord: {
			wordWrap: 'break-word'
		},
		actionButton: {
			position: 'absolute',
			top: '5px',
			right: '5px',
			backgroundColor: '#fff'
		},
	}

	onRowEnter(index) {
		this.setState({
			...this.state,
			hoveredElement: index
		})
	}

	onRowLeave(index) {
		if (this.state.clickedButton) {
			return;
		}
		this.setState({
			...this.state,
			hoveredElement: -1
		})
	}

	onClickDropdown() {
		this.setState({
			...this.state,
			clickedButton: true
		})
	}

	onCloseDropdown() {
		this.setState({
			...this.state,
			clickedButton: false,
			hoveredElement: -1
		})
	}

	formatTermOfValidity(date) {
		if (!date) {
			return Translations.get('lifetime');
		}
		return timeFormatDate(date);
	}

	formatStatus(status) {
		if (status) {
			return Translations.get('coupon_used');
		}
		else {
			return Translations.get('coupon_active');
		}
	}

	formatType(type) {
		return UserKeyTranslation.useType(type);
	}

	hashNumber(number) {
		return Base64.encodeURI(number);
	}

	deleteVoucher(code) {
		console.log("Delete");
		Rest(
			'delete',
			'secured/voucher/delete',
			{
				code,
			},
			this.handleStatusResponse,
			this.props.userToken,
		);
	}

	setStatus(status, code) {
		console.log("Set status ", status);
		Rest(
			'post',
			'secured/voucher/status',
			{
				code,
				isUsed: status === 'inactive',
			},
			this.handleStatusResponse,
			this.props.userToken,
		);
	}

	handleStatusResponse(res) {
		console.log('res status', res);
		if (res.body.error) {
			this.showError(res.body.errorReason);
		} else {
			this.refreshData();
		}
	}

	getActionButtons(isUsed, code) {
		if (isUsed) {
			return [{
				label: Translations.get('activate'),
				onClick: () => { this.setStatus("active", code); },
			}, {
				label: Translations.get('remove'),
				onClick: () => { this.deleteVoucher(code) },
			}];
		}
		else {
			return [{
				label: Translations.get('inactive'),
				onClick: () => { this.setStatus("inactive", code); },
			}, {
				label: Translations.get('remove'),
				onClick: () => { this.deleteVoucher(code); },
			}];
		}
	}

	getDropdownMenu(isUsed, code, i) {
		if (i !== this.state.hoveredElement) {
			return '';
		}
		const label = (
			<Typography className='table-link'>
				{Translations.get('actions')}
				<DropDownIcon />
			</Typography>
		)
		return (
			<Dropdown
				label={label}
				onClick={() => { this.onClickDropdown() }}
				onClose={() => { this.onCloseDropdown() }}
				rows={this.getActionButtons(isUsed, code)}
				style={this.styles.actionButton}
				variant='outlined'
			/>
		);
	}

	handleRowClick = number => (event) => {
		if(event.target.nodeName === 'svg'
		|| event.target.nodeName === 'path'){
			return;
		}
		const classTable = event.target.className.split(' ')
		if(classTable.includes('table-link')
		|| event.target.nodeName === 'BUTTON'
		|| event.target.nodeName === 'SPAN'
		|| event.target.nodeName === 'UL'
		|| event.target.nodeName === 'LI'
		|| event.target.nodeName === 'DIV'
		){
			return;
		}
		const linkUri = `/panel/coupon_details/${this.hashNumber(number)}`;
		this.props.redirrect(linkUri, this.props.history)
	}

	createRows(data) {
		return data.map((item, i) => {

			var description = `${Translations.get('amount')}: ${item.amountOfUse}`
			return (
				<TableRow
					key={i}
					onMouseEnter={() => { this.onRowEnter(i) }}
					onMouseLeave={() => { this.onRowLeave(i) }}
					onClick={this.handleRowClick(item.code)}
				>
					<TableCell style={styles.tableColumn1}>
						<Typography style={{...this.styles.breakWord, ...styles.tableColumn1}}>
							{item.name}	
						</Typography>
					</TableCell>

					<TableCell style={styles.tableColumn2}>
						<Typography
						>
							{item.code}
						</Typography>
					</TableCell>

					<TableCell style={styles.tableColumn1}>
						{this.formatStatus(item.isUsed)}
					</TableCell>

					<TableCell style={styles.tableColumn1}>
						{this.formatType(item.useType)}
					</TableCell>

					<TableCell style={styles.tableColumn1}>
						{timeFormatDate(item.creationDate)}
					</TableCell>

					<TableCell style={styles.tableColumn1}>
						{this.formatTermOfValidity(item.validTo)}
					</TableCell>

					<TableCell style={{...styles.tableColumn1, ...this.styles.lastCell}}>
						{description}
						{this.getDropdownMenu(item.isUsed, item.code, i)}
					</TableCell>
				</TableRow>
			);
		});
	}

	onNextButtonClick() {
		var nextPage = this.state.currentPage + 1;
		this.setState({
			...this.state,
			isLoading: true,
			currentPage: nextPage,
		});
	}

	onPreviousButtonClick() {
		var nextPage = this.state.currentPage - 1;
		this.setState({
			...this.state,
			isLoading: true,
			currentPage: nextPage,
		});
	}

	setFilter(name, type, payload) {
		var filters = this.state.filters;
		var index = filters.findIndex(
			(obj) => { return (obj.name.equals(name) && obj.type === type); }
		);

		if (index >= 0) {
			var item = filters[index];
			filters.splice(index, 1);
			item.payload = payload;
		} else {
			item = {
				name: name,
				type: type,
				payload: payload,
			}
		}
		filters.push(item);
		this.setState({
			...this.state,
			isLoading: true,
			filters: filters,
			currentPage: 0,
		})
		console.log("Set Filter", this.state);
	}

	deleteFilter(name, type = false) {
		var filters = this.state.filters;
		var index = filters.findIndex(
			(obj) => {
				var isEq = obj.name.equals(name);
				if (type !== false) {
					isEq = (obj.type === type) && isEq;
				}
				return isEq;
			}
		);
		if (index < 0) {
			return;
		}
		filters.splice(index, 1);
		this.setState({
			...this.state,
			isLoading: true,
			filters: filters,
			currentPage: 0,
		})
	}

	setOrderColumn(columnName) {
		var desc = this.state.orderDesc;
		console.log("columnName: ", columnName);
		if (columnName === this.state.orderColumn) {
			desc = !desc;
		} else {
			desc = false;
		}
		this.setState({
			...this.state,
			isLoading: true,
			orderColumn: columnName,
			orderDesc: desc
		})
	}

	getLoading() {
		if (this.state.isLoading) {
			return (
				<div style={this.styles.loadingAnimBG}>
					<CircularProgress size={80} thickness={5} />
				</div>
			);
		}
		return '';
	}

	getHeaders() {
		return (
			<TableRow>
				<ColumnHeader
					style={styles.tableColumn1}
					label={Translations.get('coupon_promotional_campaign')}
					visible={this.state.orderColumn === VoucherColumn.NAME}
					sort={() => {
						this.setOrderColumn(
							VoucherColumn.NAME
						)
					}}
				/>
				<ColumnHeader
					style={styles.tableColumn2}
					label={Translations.get('coupon_code')}
					visible={this.state.orderColumn === VoucherColumn.CODE}
					sort={() => {
						this.setOrderColumn(
							VoucherColumn.CODE
						)
					}}
				/>
				<ColumnHeader
					style={styles.tableColumn1}
					label={Translations.get('status')}
				/>
				<ColumnHeader
					style={styles.tableColumn1}
					label={Translations.get('type')}
					visible={this.state.orderColumn === VoucherColumn.USE_TYPE}
					sort={() => {
						this.setOrderColumn(
							VoucherColumn.USE_TYPE
						)
					}}
				/>
				<ColumnHeader
					style={styles.tableColumn1}
					label={Translations.get('creation_date')}
					visible={this.state.orderColumn === VoucherColumn.CREATION_DATE}
					sort={() => {
						this.setOrderColumn(
							VoucherColumn.CREATION_DATE
						)
					}}
				/>
				<ColumnHeader
					style={styles.tableColumn1}
					label={Translations.get('valid_to')}
					visible={this.state.orderColumn === VoucherColumn.VALID_TO}
					sort={() => {
						this.setOrderColumn(
							VoucherColumn.VALID_TO
						)
					}}
				/>
				<ColumnHeader
					style={styles.tableColumn1}
					label={Translations.get('coupon_used')}
					visible={this.state.orderColumn === VoucherColumn.QUANTITY}
					sort={() => {
						this.setOrderColumn(
							VoucherColumn.QUANTITY
						)
					}}
				/>
			</TableRow>
		);
	}

	getTable() {
		return (
			<Table>
				<TableHead>
					{this.getHeaders()}
				</TableHead>
				<TableBody>
					{this.createRows(this.state.tableData)}
				</TableBody>
			</Table>
		)
	}

	render() {
		if(!this.props.isAdmin){
			return '';
		}
		if (this.state.isLoading) {
			this.refreshData();
		}

		return (
			<div style={styles.tableContainer}>
				<Paper style={{ ...styles.paperBox, position: 'relative' }}>
					<Grid
						container
						direction='column'
					>
						<Grid item>
							<Bar
								title={Translations.get('discount_coupons')}
								page={this.state.currentPage}
								step={this.state.pageItemLimit}
								length={this.state.lenghtData}
								nextAction={this.onNextButtonClick}
								previousAction={this.onPreviousButtonClick}
								setFilter={this.setFilter}
								deleteFilter={this.deleteFilter}
								isSearcher={true}
								button={{
									label: Translations.get('add_coupon'),
									onClick: () => {
										this.props.setRepositoryValue('refreshFunc', this.refreshData)
										this.props.setPopup('add_coupon')
									}
								}}
							/>
						</Grid>
						<Grid item>
							{this.getTable()}
						</Grid>
						<Grid item style={styles.space16Vertical}>
						</Grid>
						<Grid item>
							<Bar
								page={this.state.currentPage}
								step={this.state.pageItemLimit}
								length={this.state.lenghtData}
								nextAction={this.onNextButtonClick}
								previousAction={this.onPreviousButtonClick}
							/>
						</Grid>
					</Grid>
					{this.getLoading()}
				</Paper>
			</div>
		);
	}
}


const mapStateToProps = (state) => {
	return {
		userToken: state.user.token,
		isAdmin: state.user.isAdmin,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (x) => {
			dispatch(setPopup(x));
		},
		setLogin: (x, y, z, q, t, a) => {
			dispatch(setLogin(x, y, z, q, t, a));
		},
		redirrect: (x, y) => {
			dispatch(redirrect(x, y));
		},
		setRepositoryValue: (x, y) => {
			dispatch(setValue(x, y));
		},
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(container));
