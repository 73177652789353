import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import TextSelect from '../../../Commons/TextSelect'
import { timeFormatDate, timeFormatDots } from '../../../../libs/dateformat'
import { setInputValue } from 'Actions/inputActions'

const oneHour = 3600000;

export class DataPicker extends Component {

	constructor(props) {
		super(props);
		this.dateId = `${this.props.id}-date`;
		this.timeIdFrom = `${this.props.id}-time-start`;
		this.timeIdTo = `${this.props.id}-time-end`;

		const items = this.getItems(new Date());
		this.state = {
			items
		}

		let offset = new Date().getTimezoneOffset() / 60
		offset *= -oneHour;
		this.props.setInputValue(this.timeIdFrom, 36000000 + offset); //10:00
		this.props.setInputValue(this.timeIdTo, 57600000 + offset); // 16:00

		// this.onChange(items[0].key)
	}

	dayTranslation = {
		1: 'poniedziałek',
		2: 'wtorek',
		3: 'środa',
		4: 'czwartek',
		5: 'piątek'
	}

	isWeekend = (date) => {
		const day = date.getDay();
		return (day === 6 || day === 0);
	}

	getItems = (date) => {
		let items = [];
		let todaysDay = date.getDay();

		// weekdays after 13
		if (todaysDay >= 1 && todaysDay < 5 && date.getHours() >= 13) {
			date = new Date(date.setHours(date.getHours() + 24));
		}

		// friday after 13
		if (date.getHours() >= 13 && todaysDay === 5) {
			date = new Date(date.setHours(date.getHours() + 48));
		}

		// saturday
		if (todaysDay === 6) {
			date = new Date(date.setHours(date.getHours() + 48));
		}

		// sunday
		if (todaysDay === 0) {
			date = new Date(date.setHours(date.getHours() + 24));
		}

		date.setHours(0, 0, 0, 0);

		for (let i = 0; i < 10; i++) {
			date = new Date(date.setHours(date.getHours() + 24));

			while (this.isWeekend(date)) {
				date = new Date(date.setHours(date.getHours() + 24));
			}

			items.push({
				key: date.getTime(),
				value: `${timeFormatDate(date)} (${this.dayTranslation[date.getDay()]}), g.10:00 - 16:00`
			})
		}
		return items;
	}

	// onChange = (value) => {
	// 	this.props.setInputValue(this.dateId, value);
	// }

	render() {
		return (
			<TextSelect
				id={this.dateId}
				items={this.state.items}
				onChange={this.onChange}
			/>
		)
	}
}

DataPicker.propTypes = {
	id: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = dispath => ({
	setInputValue: (id, value) => { dispath(setInputValue(id, value)) }
})

export default connect(mapStateToProps, mapDispatchToProps)(DataPicker)
