import React from 'react'
import { connect } from "react-redux";
import { sessionService } from 'redux-react-session';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom'
import sha256 from 'sha256';

import Radio from 'Components/Commons/RadioGroup'
import RestRequest from 'libs/RestRequest'
import { Typography, Grid, Button } from '@material-ui/core';
import TextInput from 'Components/Commons/TextInput';
import { TextInputType } from 'Components/Commons/Const/InputTypes'
import styles from 'Components/style'

import { ERROR } from 'libs/RestRequest'
import { setPopup } from 'Actions/pageActions';
import { setLogin } from 'Actions/userActions';
import { redirrect } from 'Actions/pageActions';
import Translations from 'Singleton/Translations'

class container extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			validName: false,
			validSurname: false,
			validEmail: false,
			isValidNewPassw: false,
			isSamePassw: false,
			isSecondPasswEmpty: true,
			isPasswordChanged: false,
		}
		this.createRadioItemsRole = this.createRadioItemsRole.bind(this);

		this.handleErr = this.handleErr.bind(this);
		this.logout = this.logout.bind(this);
		this.acceptChoice = this.acceptChoice.bind(this)
		this.cancelClick = this.cancelClick.bind(this)
		this.handleResponse = this.handleResponse.bind(this);
	}

	styles = {
		text: {
			margin: "0",
			fontSize: "1.3vw"
		},
		input: {
			marginBottom: "1vw",
		},
		halfInput: {
			width: "13vw"
		},
		inputRow: {
			display: "flex",
			justifyContent: "space-between",
		},
		radioColumn: {
			marginBottom: "1vw"
		}
	}

	createRadioItemsRole() {
		return [
			{
				label: Translations.get('admin'),
				key: "admin"
			}, {
				label: Translations.get('manager'),
				key: "manager"
			}, {
				label: Translations.get('employee'),
				key: "employee"
			}
		]
	}

	acceptChoice() {
		var body = {
			name: this.props.inputs.employeeName,
			surname: this.props.inputs.employeeSurname,
			password: sha256.x2(this.props.inputs.employeePassword),
			email: this.props.inputs.employeeEmail,
			role: this.props.inputs.employeeRole,
		}

		RestRequest(
			'post',
			'secured/employee/add',
			body,
			this.handleResponse,
			this.props.token,
		);
	}

	logout() {
		sessionService.deleteSession().then(
			sessionService.deleteUser().then(() => {
				this.props.setLogin(null, null, null, null, null);
				this.props.redirrect('/panel', this.props.history);
			})
		);
	}

	showError(msg) {
		toast.error(msg, {
			position: toast.POSITION.TOP_RIGHT,
		});
	}

	handleErr(body) {
		switch (body.errorCode) {
			case ERROR.USER_TOKEN_IS_NOT_VALID:
				this.showError(Translations.get('session_expired'))
				this.logout();
				break;
			default:
				this.showError(body.errorReason)
		}
	}

	handleResponse(res) {
		console.log("Add employee", res);
		if (res.body.error) {
			this.handleErr(res.body);
		} else {
			this.props.refreshDataFunc()
		}
		this.props.setPopup(undefined);
	}

	cancelClick() {
		this.props.setPopup(undefined);
	}

	getFullName() {
		return (
			<Grid container>
				<Grid item style={styles.popupColumn2}>
					<TextInput
						label={Translations.get('name')}
						id="employeeName"
						type={TextInputType.NOT_EMPTY}
						onValidChange={(value) => {
							this.setState({
								...this.state,
								validName: value,
							})
						}}
					/>
				</Grid>
				<Grid item style={styles.space30}></Grid>
				<Grid item style={styles.popupColumn2}>
					<TextInput
						label={Translations.get('surname')}
						id="employeeSurname"
						type={TextInputType.NOT_EMPTY}
						onValidChange={(value) => {
							this.setState({
								...this.state,
								validSurname: value,
							})
						}}
					/>
				</Grid>
			</Grid>
		);
	}

	getOtherData() {
		return (
			<Grid container direction='column'>
				<Grid item>
					<TextInput
						label={Translations.get('email')}
						id="employeeEmail"
						type="email"
						onValidChange={(value) => {
							this.setState({
								...this.state,
								validEmail: value,
							})
						}}
					/>
				</Grid>
				<Grid item>
					<TextInput
						label={Translations.get('password')}
						id="employeePassword"
						type="password"
						onValidChange={(value) => {
							this.setState({
								...this.state,
								isValidNewPassw: value,
							})
						}}
					/>
				</Grid>
				<Grid item>
					<TextInput
						label={Translations.get('repeat_password')}
						id="employeePasswordAgain"
						type="password"
						onChange={(value) => {
							this.setState({
								...this.state,
								isSamePassw: value === this.props.inputs.employeePassword,
								isSecondPasswEmpty: value === '',
							})
						}}
						errorReason={Translations.get('password_must_matches')}
						isError={!this.state.isSamePassw && !this.state.isSecondPasswEmpty}
					/>
				</Grid>
			</Grid>
		)
	}

	getButtons() {
		return (
			<Grid container direction="column">

				<Grid item style={styles.space16Vertical}>
				</Grid>
				
				<Grid item>
					<Button
						onClick={this.acceptChoice}
						disabled={!(
							this.state.validName &&
							this.state.validSurname &&
							this.state.validEmail &&
							this.state.isValidNewPassw &&
							this.state.isSamePassw
						)}
						fullWidth
						variant='contained'
						color='primary'>
						{Translations.get('add_employee')}
					</Button>
				</Grid>

				<Grid item style={styles.space16Vertical}>
				</Grid>

				<Grid item>
					<Button
						onClick={this.cancelClick}
						fullWidth
						variant='outlined'>
						{Translations.get('cancel')}
					</Button>
				</Grid>
			</Grid>
		);
	}

	render() {
		return (
			<Grid container direction='column'>
				<Grid item>
					{this.getFullName()}
				</Grid>

				<Grid item>
					{this.getOtherData()}
				</Grid>

				<Grid item style={styles.space16Vertical}>
				</Grid>

				<Grid item>
					<Typography variant="title">
						{Translations.get('role')}:
					</Typography>
				</Grid>

				<Grid item>
					<Radio
						id="employeeRole"
						items={this.createRadioItemsRole()}
						style={this.styles.radioColumn}
					/>
				</Grid>

				<Grid item>
					{this.getButtons()}
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		inputs: state.input.inputState,
		token: state.user.token,
		selectedRadio: state.input.inputState.selectSupplierRadio,
		refreshDataFunc: state.repository.refreshDataFunc,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (x) => {
			dispatch(setPopup(x));
		},
		setLogin: (x, y, z, q, t, a) => {
			dispatch(setLogin(x, y, z, q, t, a));
		},
		redirrect: (x, y) => {
			dispatch(redirrect(x, y));
		}
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(container));
