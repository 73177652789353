
export class ValuationStatus {
	static get NEW() {return 'new_eval';}
	static get AWAIT() {return 'await_accept_eva';}
	static get OUTDATED() {return 'outdated_eval';}
	static get ACCEPTED() {return 'accepted_eval';}
	static get REJECTED() {return 'rejected_eval';}
	static get GET_ALL(){
		return [
			this.NEW,
			this.AWAIT,
			this.OUTDATED,
			this.ACCEPTED,
			this.REJECTED
		];
	}
}

export class ValuationFilter {
	static get ALL() {return 'all';}
	static get AUTO() {return 'auto';}
	static get MANUAL() {return 'manual';}
	static get GET_ALL() {
		return [
		 this.ALL,
		 this.AUTO,
		 this.MANUAL
		];
	}
}
