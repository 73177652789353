import React from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import GoogleLogin from 'react-google-login';
import sha256 from 'sha256';

import Rest from 'libs/RestRequest';

import ConsentBox from 'Components/Body/Popup/ConsentBox';
import { setPopup } from 'Actions/pageActions';
import Translations from 'Singleton/Translations';
import { Grid, Button } from '@material-ui/core';
import IconText from 'Components/Commons/IconText';
import styles from 'Components/style';

class container extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isRegisterEnd: false,
		};
		this.responseGoogle = this.responseGoogle.bind(this);
		this.handleResponseRegister = this.handleResponseRegister.bind(this);
		this.getButton = this.getButton.bind(this);
	}

	styles = {
		button: {
			backgroundColor: styles.palette.red,
		},
	};

	responseGoogle(res) {
		if (res.profileObj === undefined) {
			console.log('google err');
			return;
		}
		var body = {
			firstname: res.profileObj.givenName,
			surname: res.profileObj.familyName,
			login: res.profileObj.email,
			pssw: sha256.x2('wef98hg7' + res.googleId),
			loginType: 'Google',
			remember: true,
		};
		Rest('post', 'auth/register', body, this.handleResponseRegister);
	}

	handleResponseRegister(res) {
		if (res.body.error) {
			toast.error(`${res.body.errorReason}`, {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {
			this.setState({
				...this.state,
				isRegisterEnd: true,
			});
		}
	}

	isDisabled() {
		return (
			!this.props.inputs['consent-regulations'] ||
			!this.props.inputs['consent-personal-data'] ||
			!this.props.inputs['consent-invoices']
		);
	}

	renderButton = (props) => {
		const isDisabled = this.isDisabled();
		return (
			<Button
				fullWidth
				variant="contained"
				color="primary"
				onClick={props.onClick}
				style={isDisabled ? {} : this.styles.button}
				disabled={isDisabled}
			>
				{Translations.get('register')}
			</Button>
		);
	};

	getButton() {
		if (this.state.isRegisterEnd) {
			return (
				<IconText type={'done'} variant={'body1'} centered>
					{Translations.get('registered_end')}
				</IconText>
			);
		} else {
			return (
				<GoogleLogin
					clientId="684786943673-u6lr2n1cio8ls10sjnt9t3cu414hldhk.apps.googleusercontent.com"
					buttonText={Translations.get('register')}
					onSuccess={this.responseGoogle}
					onFailure={this.responseGoogle}
					render={this.renderButton}
				/>
			);
		}
	}

	render() {
		return (
			<Grid container direction="column">
				<Grid item style={styles.space16Vertical}></Grid>
				<Grid item>
					<ConsentBox />
				</Grid>
				<Grid item style={styles.space16Vertical}></Grid>
				<Grid item>{this.getButton()}</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		popup: state.page.popup,
		func: state.page.popupFunc,
		inputs: state.input.inputState,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (x) => {
			dispatch(setPopup(x));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(container);
