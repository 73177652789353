import React from 'react'
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import { Base64 } from 'js-base64';
import { subscribe } from 'redux-subscriber';
import { sessionService } from 'redux-react-session';

import RestRequest from 'libs/RestRequest'
import { ERROR } from 'libs/RestRequest'

import Header from 'Components/Body/OrderDetails/Header'
import ClientBox from 'Components/Body/Admin/Boxes/Client'
import ClientOrderBox from 'Components/Body/Admin/Boxes/ClientDataOrder'
import PricesBox from 'Components/Body/Admin/Boxes/Prices'
import ProviderBox from 'Components/Body/Admin/Boxes/Supplier'
import InvoiceProBox from 'Components/Body/Admin/Boxes/InvoicePro'
import InvoiceVatBox from 'Components/Body/Admin/Boxes/InvoiceVat'
import DocumentCmrBox from 'Components/Body/Admin/Boxes/DocumentCmr'
import DropDownIcon from '@material-ui/icons/ArrowDropDown'

import DetailsColumn from 'Components/Body/OrderDetails/DetailsColumn'
import ChatColumn from 'Components/Body/OrderDetails/ChatColumn'
import Dropdown from 'Components/Commons/Dropdown'
import HistoryColumn from 'Components/Body/OrderDetails/HistoryColumn'

import { ValuationStatus } from 'Components/Commons/Const/Valuation'
import OrderStatus from 'Components/Commons/Const/OrderStatus'
import { PageType } from 'Components/Commons/Const/Type'
import OrderKeyTranslation from 'Components/Commons/Dictionaries/OrderKeyTranslation'
import ValuationKeyTranslation from 'Components/Commons/Dictionaries/ValuationKeyTranslation'

import { redirrect, setPopup, setEvalNumber, setPopupFunc } from 'Actions/pageActions';
import { setData as setSupplyData } from 'Actions/supplyDataActions';
import { setValue } from 'Actions/repositoryActions'
import { setInputValue } from "Actions/inputActions";
import { setPaletsCount } from 'Actions/imageBlockFormActions';
import { setLogin } from 'Actions/userActions'

import { Grid } from '@material-ui/core';
import styles from '../../../Components/style';
import Translations from 'Singleton/Translations'
import CircularProgress from '@material-ui/core/CircularProgress';
import { timeFormat, timeFormatDate, timeFormatInterval } from 'libs/dateformat'
import { PriceBoxState } from 'Components/Commons/PriceBox'
import {Typography} from '@material-ui/core'

class container extends React.Component {

	constructor(props) {
		super(props);
		var type = this.props.match.params.oeType;
		var number = Base64.decode(this.props.match.params.number);
		props.setEvalNumber(number);
		this.state = {
			type: type,
			number: number,
			loading: true,
			error: false,
			deleteLabel: '',
			data: null,
			statuses: {
				"new_eval": 1,
				"await_accept_eva": 2,
				"outdated_eval": 3,
				"accepted_eval": 4,
				"rejected_eval": 5
			}
		}

		subscribe('user.id', state => {
			if (this.props.userId === undefined) {
				this.getData(this.state.type, this.state.number, state.user.id, state.user.token);
			}
		});

		this.getSupplier = this.getSupplier.bind(this);
		this.getButtonsMenu = this.getButtonsMenu.bind(this);
		this.handleStatusRespons = this.handleStatusResponse.bind(this);
		this.setStatusValuation = this.setStatusValuation.bind(this);
		this.showError = this.showError.bind(this);
		this.handleErr = this.handleErr.bind(this);
		this.logout = this.logout.bind(this);
		this.handleResponse = this.handleResponse.bind(this);
		this.getClientValuationBox = this.getClientValuationBox.bind(this);
		this.getClientOrderBox = this.getClientOrderBox.bind(this);
		this.cancelOrder = this.cancelOrder.bind(this);
		this.retryOrder = this.retryOrder.bind(this);
		this.handleStatusResponse = this.handleStatusResponse.bind(this);
		this.getInvoiceData = this.getInvoiceData.bind(this);
		this.onStarClick = this.onStarClick.bind(this);
		this.onStickerClick = this.onStickerClick.bind(this);
		this.handleStickerResponse = this.handleStickerResponse.bind(this);
		this.getHeaderDetailsText = this.getHeaderDetailsText.bind(this);
		this.popupOnAccept = this.popupOnAccept.bind(this);
		this.refreshPage = this.refreshPage.bind(this);

		this.props.setRepositoryValue('refreshFunc', this.refreshPage)
		this.props.setRepositoryValue('labelData', { type, number });
		this.props.setRepositoryValue('number', number);

		console.log(this.state);

		if (!this.props.isAdmin) {
			this.props.redirrect('/panel', this.props.history);
		} else {
			this.refreshPage();
		}
	}

	refreshPage() {
		if (!this.state.loading) {
			this.setState({
				...this.state,
				loading: true,
			})
		}
		this.getData(this.state.type, this.state.number);
	}

	getData(type, number, userId = null, token = null) {
		if (type === PageType.VALUATION) {
			this.getEvaluationData(number, userId, token)
		} else if (type === PageType.ORDER) {
			this.getOrderData(number, token);
		}
	}

	logout() {
		sessionService.deleteSession().then(
			sessionService.deleteUser().then(() => {
				this.props.setLogin(null, null, null, null, null);
				this.props.redirrect('/panel', this.props.history);
			})
		);
	}

	showError(msg) {
		toast.error(msg, {
			position: toast.POSITION.TOP_RIGHT,
		});
	}

	handleErr(body) {
		switch (body.errorCode) {
			case ERROR.USER_TOKEN_IS_NOT_VALID:
				this.showError(Translations.get('session_expired'))
				this.logout();
				break;
			default:
				this.showError(body.errorReason)
		}
	}

	getOrderData(number, token) {
		if (!token) { token = this.props.userToken }

		RestRequest(
			'get',
			'/secured/order',
			{ orderNumber: number },
			this.handleResponse,
			token
		);
	}

	getEvaluationData(number, userId, token) {
		if (userId === null) {
			userId = this.props.userId
		}
		if (token === null) {
			token = this.props.userToken
		}
		if (userId === undefined ||
			userId === null ||
			token === undefined ||
			token === null) {
			return;
		}
		var data = { userId: userId, number: number };
		RestRequest(
			'post',
			'/secured/getAdminEvaluation',
			data,
			this.handleResponse,
			token
		);
	}

	handleResponse(res) {
		console.log('Eva details', res);
		if ((res.body && res.body.error) || res.body === null) {
			if (res.body === null) {
				this.setState({
					...this.state,
					loading: false,
					error: true,
				});
			} else {
				this.handleErr(res.body);
				this.setState({
					...this.state,
					loading: false,
					error: true,
				});
			}
		} else {

			this.setState({
				...this.state,
				loading: false,
				data: res.body,
			});
			this.props.setRepositoryValue('selectedLabels', res.body.labels);
			this.props.setSupplyData('fromCountryId', res.body.pickupCountryId);
			this.props.setSupplyData('fromCountry', res.body.pickupCountry);
			this.props.setSupplyData('fromPostcode', res.body.pickupPostcode);
			this.props.setSupplyData('fromCity', res.body.pickupCity);

			this.props.setSupplyData('toCountryId', res.body.deliveryCountryId);
			this.props.setSupplyData('toCountry', res.body.deliveryCountryName);
			this.props.setSupplyData('toPostcode', res.body.deliveryPostcode);
			this.props.setSupplyData('toCity', res.body.deliveryCity);

			this.props.setSupplyData('email', res.body.email);
			this.props.setSupplyData('pallets', res.body.pallets);
		}
	}

	getSupplier() {
		// if(this.state.data.supplier === "no_suplier"){
		// 	return "Brak dostawcy";
		// }
		return this.state.data.supplier;
	}

	setStatusValuation(value) {
		var body = {};
		body['statusName'] = value;
		body['number'] = this.props.eNumber;
		RestRequest(
			'post',
			'secured/updateEvaluationStatus',
			body,
			this.handleStatusResponse,
			this.props.userToken
		);
	}

	acceptValuation() {
		var data = this.state.data;
		this.props.setInputValue('valuation-number', this.state.number);
		if (data.isPrivate) {
			this.props.setInputValue('sender', 'private');
		} else {
			this.props.setInputValue('sender', 'company');
		}
		for (var i = 0; i < data.pallets.length; i++) {
			var pallet = data.pallets[i];
			var type = {};
			for (var typeTemp of this.props.palletsTypes) {
				if (typeTemp.type === pallet.type) {
					type = this.props.palletsTypes.indexOf(typeTemp);
				}
			}
			this.props.setInputValue(`palet_height_${i}`, pallet.height);
			this.props.setInputValue(`palet_weight_${i}`, pallet.weight);
			this.props.setInputValue(`fragile_cargo_${i}`, pallet.isFragile);
			this.props.setInputValue(`pallets_size_${i}`, type);
		}
		this.props.setInputValue('post_code_from', data.pickupPostcode);
		this.props.setInputValue('post_code_to', data.deliveryPostcode);
		this.props.setInputValue('country_from', data.pickupCountry);
		this.props.setInputValue('country_to', data.deliveryCountry);
		this.props.setInputValue('details', data.remarks);
		this.props.setInputValue('lift_car', data.isLiftByDelivery || data.isLiftByPickup)
		this.props.setPaletsCount(data.pallets.length);
		this.props.setRepositoryValue('pricePLN', data.amountNet);
		this.props.setRepositoryValue('userId', data.userId);
		this.props.setRepositoryValue('priceBoxState', PriceBoxState.COMPLETE)


		const price = {
			cost: data.cost,
			priceNet: data.amountNet,
			priceGross: data.amountGross,
			exchangeRate: data.exchangeRate,
			supplier: data.supplier,
		}
		this.props.setRepositoryValue('price', price);

		this.props.redirrect(`/more_info`, this.props.history);
	}

	rejectValuation() {
		this.props.setRepositoryValue('number', this.state.number);
		this.props.setPopupFunc(this.popupOnAccept);
		this.props.setPopup('valuation_reject');
	}

	translateCountry(iso) {
		var country = this.props.countrys.find((element) => {
			return element.value === iso;
		});
		return country.name;
	}

	cancelOrder() {
		console.log('cancel order');
		RestRequest(
			'post',
			'secured/order/status/cancel',
			{
				number: this.state.number,
			},
			this.handleStatusResponse,
			this.props.userToken,
		)
	}

	retryOrder() {
		console.log('retry order');
		this.setAsNewOrder();
	}

	setAsNewOrder() {
		console.log('set as new order');
		RestRequest(
			'post',
			'secured/order/status/new',
			{
				number: this.state.number,
			},
			this.handleStatusResponse,
			this.props.userToken,
		)
	}

	handleStatusResponse(res) {
		console.log(res);
		if (res.body.error) {
			this.showError(res.body.errorReason)
		} else {
			this.popupOnAccept();
		}
	}

	popupOnAccept(isOk) {
		console.log('popup on accept', isOk);
		this.refreshPage();
	}

	getButtonsMenu() {
		switch (this.state.data.status) {
			case ValuationStatus.NEW:
				return [{
					label: Translations.get('send_for_verify'),
					disabled: !this.state.data.amountNet,
					onClick: () => {
						this.props.setPopupFunc(this.popupOnAccept);
						this.props.setPopup("send_for_acceptance");
					},
				}, {
					label: Translations.get('mark_as_outdated'),
					onClick: () => { this.setStatusValuation('outdated_eval') },
				}, {
					label: Translations.get('renew_evaluation'),
					onClick: () => { this.setStatusValuation('new_eval') },
				}];
			case ValuationStatus.AWAIT:
				return [{
					label: Translations.get('send_again_for_acceptance'),
					onClick: () => {
						this.props.setPopupFunc(this.popupOnAccept);
						this.props.setPopup("send_for_acceptance");
					},
				}, {
					label: Translations.get('client_accepted'),
					onClick: () => { this.acceptValuation() },
				}, {
					label: Translations.get('client_rejected'),
					onClick: () => { this.rejectValuation() },
				}, {
					label: Translations.get('mark_as_outdated'),
					onClick: () => { this.setStatusValuation('outdated_eval') },
				}, {
					label: Translations.get('renew_evaluation'),
					onClick: () => { this.setStatusValuation('new_eval') },
				}];
			case ValuationStatus.OUTDATED:
				return [{
					label: Translations.get('send_again_for_acceptance'),
					onClick: () => {
						this.props.setPopupFunc(this.popupOnAccept);
						this.props.setPopup("send_for_acceptance");
					},
				}, {
					label: Translations.get('client_accepted'),
					onClick: () => { this.acceptValuation() },
				}, {
					label: Translations.get('client_rejected'),
					onClick: () => { this.rejectValuation() },
				}, {
					label: Translations.get('renew_evaluation'),
					onClick: () => { this.setStatusValuation('new_eval') },
				}];
			case ValuationStatus.ACCEPTED:
				return [{
					label: Translations.get('client_rejected'),
					onClick: () => { this.rejectValuation() },
				}, {
					label: Translations.get('renew_evaluation'),
					onClick: () => { this.setStatusValuation('new_eval') },
				}];
			case ValuationStatus.REJECTED:
				return [{
					label: Translations.get('send_again_for_acceptance'),
					onClick: () => {
						this.props.setPopupFunc(this.popupOnAccept);
						this.props.setPopup("send_for_acceptance")
					},
				}, {
					label: Translations.get('client_accepted'),
					onClick: () => { this.acceptValuation() },
				}, {
					label: Translations.get('renew_evaluation'),
					onClick: () => { this.setStatusValuation('new_eval') },
				}];
			case OrderStatus.NEW:
				return [{
					label: Translations.get('forward_for_payment'),
					onClick: () => {
						this.props.setPopupFunc(this.popupOnAccept);
						this.props.setPopup('forward_for_payment');
					},
				}, {
					label: Translations.get('cancel_order'),
					onClick: () => { this.cancelOrder() }
				}, {
					label: Translations.get('retry_order'),
					onClick: () => { this.retryOrder() }
				}];
			case OrderStatus.AWAIT:
				return [{
					label: Translations.get('start_realizing'),
					onClick: () => {
						this.props.setPopupFunc(this.popupOnAccept);
						this.props.setPopup('start_realizing');
					},
				}, {
					label: Translations.get('forward_again_for_payment'),
					onClick: () => {
						this.props.setPopupFunc(this.popupOnAccept);
						this.props.setPopup('forward_for_payment');
					},
				}, {
					label: Translations.get('cancel_order'),
					onClick: () => { this.cancelOrder() }
				}, {
					label: Translations.get('retry_order'),
					onClick: () => { this.retryOrder() }
				}];
			case OrderStatus.SENT_TO_SUPPLIER:
			case OrderStatus.IN_PROGRESS:
				return [{
					label: Translations.get('forward_for_supplier'),
					onClick: () => {
						this.props.setRepositoryValue('action', OrderStatus.FORWARD);
						this.props.setPopupFunc(this.popupOnAccept);
						this.props.setPopup('forward_to_supplayer');
					},
				}, {
					label: Translations.get('forward_auto_order_for_supplier'),
					onClick: () => {
						this.props.setRepositoryValue('action', OrderStatus.FORWARD_AUTO);
						this.props.setPopupFunc(this.popupOnAccept);
						this.props.setPopup('forward_to_supplayer');
					},
				}, {
					label: Translations.get('cancel_order'),
					onClick: () => { this.cancelOrder() }
				}, {
					label: Translations.get('retry_order'),
					onClick: () => { this.retryOrder() }
				}];
			case OrderStatus.FORWARD:
				return [{
					label: Translations.get('order_provided'),
					onClick: () => {
						this.props.setPopupFunc(this.popupOnAccept);
						this.props.setPopup('order_provided')
					},
				}, {
					label: Translations.get('cancel_order'),
					onClick: () => { this.cancelOrder() }
				}, {
					label: Translations.get('retry_order'),
					onClick: () => { this.retryOrder() }
				}];
			case OrderStatus.TO_SUPPLY:
				return [];
			case OrderStatus.CANCELED:
				return [{
					label: Translations.get('order_restore'),
					onClick: () => { this.setAsNewOrder() }
				}, {
					label: Translations.get('retry_order'),
					onClick: () => { this.retryOrder() }
				}];
			default:
				return [{
					label: "__NO_DATA__",
					onClick: () => { console.log("__NO_DATA__"); },
				}];
		}
	}


	getDropdownMenu() {
		if (this.state.data.status === OrderStatus.TO_SUPPLY) {
			return '';
		}
		const label = (
			<Typography>
				{Translations.get('actions')}
				<DropDownIcon style={{width: 20, height: 20}} />
			</Typography>
		)
		return (
			<Dropdown
				variant='outlined'
				label={label}
				rows={this.getButtonsMenu()}
			/>
		);
	}


	getTrEval(type) {
		if (type === PageType.VALUATION) {
			return Translations.get('evaluation');
		} else if (type === PageType.ORDER) {
			return Translations.get('order');
		} else {
			return "Undefined";
		}
	}

	getOrderBoxes(type) {
		if (type === "order") {
			return (
				<Grid
					container
				>
					<Grid item>
						<InvoiceProBox
							link={this.state.data.proformaUrl}
							editLink={this.state.data.proInvoiceEditLink}
							orderNumber={this.state.number}
						/>
					</Grid>
					<Grid item style={styles.space30}>
					</Grid>
					<Grid item>
						<InvoiceVatBox
							number={this.state.number}
							refreshFunc={this.refreshPage}
							documents={this.state.data.invoices}
						/>
					</Grid>
					<Grid item style={styles.space30}>
					</Grid>
					<Grid item>
						<DocumentCmrBox
							number={this.state.number}
							documents={this.state.data.documents}
							refreshFunc={this.refreshPage}
							status={this.state.data.status}
						/>
					</Grid>
				</Grid>
			);
		}
		return "";
	}

	getClientValuationBox() {
		var data = this.state.data;
		return (
			<ClientBox
				clientName={data.name && data.surname ?
					`${data.name} ${data.surname}` : data.email}
				orderName={data.orderNumber ? data.orderNumber : Translations.get('none')}
				ordered={timeFormat(data.creationDate)}
				outdatedDate={timeFormatDate(data.validTo)}
				rejectionDate={timeFormat(data.rejectionDate)}
				valuationDate={timeFormatDate(data.valuationDate)}
				status={data.status}
			/>
		);
	}

	getClientOrderBox() {
		var data = this.state.data;
		return (
			<ClientOrderBox
				clientName={data.name && data.surname ?
					`${data.name} ${data.surname}` : data.email}
				valuationNumber={data.valuationNumber ? data.valuationNumber : Translations.get('automatic_valuation')}
				orderDate={timeFormat(data.creationDate)}
				unloadingDate={timeFormatInterval(data.pickupStart, data.pickupEnd)}
				paymentDate={timeFormatDate(data.paymentDate)}
				status={data.status}
				cancellationDate={timeFormat(data.cancellationDate)}
				plannedDeliveryDate={timeFormatDate(data.plannedDeliveryDate)}

			/>
		)
	}

	getInvoiceData() {
		return {
			city: this.state.data.proInvoiceCity,
			company: this.state.data.proInvoiceCompany,
			country: this.state.data.proInvoiceIso,
			name: this.state.data.proInvoiceName,
			phone: this.state.data.proInvoicePhone,
			postcode: this.state.data.proInvoicePostcode,
			street: this.state.data.proInvoiceStreet,
			surname: this.state.data.proInvoiceSurname,
			nip: this.state.data.proInvoiceVat,
		};
	}

	onStarClick() {
		var val = !this.state.data.isMarked;
		var gate = this.state.type === PageType.ORDER ?
			'/secured/order/marker' :
			'/secured/valuation/marker';
		RestRequest(
			'post',
			gate,
			{
				number: this.state.number,
				isMarked: val,
			},
			this.handleStarResponse,
			this.props.userToken
		);
		this.setState({
			...this.state,
			data: {
				...this.state.data,
				isMarked: val,
			}
		});
	}

	handleStarResponse(res) {
		console.log("star res", res);
		if (res.body.error) {
			this.showError(res.body.errorReason);
			this.setState({
				...this.state,
				data: {
					...this.state.data,
					isMarked: !this.state.data.isMarked,
				}
			});
		}
	}

	onStickerClick(name) {
		var gate = this.state.type === PageType.ORDER ?
			'secured/label/order/cancel' :
			'secured/label/valuation/cancel';
		this.setState({
			...this.state,
			deleteLabel: name,
		})
		RestRequest(
			'post',
			gate,
			{
				number: this.state.number,
				label: name,
			},
			this.handleStickerResponse,
			this.props.userToken
		);
	}

	handleStickerResponse(res) {
		console.log("label res", res);
		if (res.body.error) {
			this.showError(res.body.errorReason);
		} else {
			this.setState({
				...this.state,
				data: {
					...this.state.data,
					labels: this.state.data.labels.remove(this.state.deleteLabel),
				}
			});
		}
	}

	getHeaderDetailsText(status) {
		var translationStatusFunc = this.state.type === PageType.ORDER ?
			OrderKeyTranslation.status :
			ValuationKeyTranslation.status;
		var msg = `${translationStatusFunc(status)}`;
		console.log(status);
		if (status === OrderStatus.AWAIT ||
			status === ValuationStatus.AWAIT) {
			msg += ` (${Translations.get('remains')} ${this.state.data.remainedDays}  ${Translations.get('days')})`;
		}
		return msg;
	}

	getTransportInfo = () => {
		const { transportMessage, transportStatus } = this.state.data;
		return {
			message: transportMessage,
			status: transportStatus,
		}
	}

	getBoxRow() {
		const data = this.state.data;
		return (
			<Grid
				container
			>
				<Grid item>
					{this.state.type === PageType.ORDER ?
						this.getClientOrderBox() :
						this.getClientValuationBox()
					}
				</Grid>
				<Grid item style={styles.space30}>
				</Grid>

				<Grid item>
					<PricesBox
						profit={data.amountNet}
						profitB={data.amountGross}

						loss={data.cost}
						lossB={data.cost * 1.23}

						difference={data.amountNet - (data.cost)}
						differenceB={data.amountGross - data.cost * 1.23}

						currency="PLN"
						status={data.status}

						exRate={data.exchangeRate ? `${data.exchangeRate.toFixed(4)}` : Translations.get('none')}
						update={() => {
							this.props.setInputValue('price_pln', data.amountNet && data.cost ?
								parseFloat(((data.amountNet - (data.cost * data.exchangeRate)) / data.exchangeRate)).toFixed(2) :
								undefined);

							this.props.setInputValue('cost_net', data.cost ? data.cost : 0);
							this.props.setInputValue('price_net', data.amountNet ? data.amountNet : 0);
							this.props.setPopup("update_prices");
						}}
					/>
				</Grid>
				<Grid item style={styles.space30}>
				</Grid>
				<Grid item>
					<ProviderBox
						supplierName={this.getSupplier()}
						transportInfo={this.getTransportInfo()}
						status={data.status}
						type={this.state.type}
					/>
				</Grid>
			</Grid>
		);
	}

	getMessageColumn() {
		return (
			<Grid
				container
				direction='column'
			>
				<Grid item>
					<ChatColumn
						number={this.state.number}
					/>
				</Grid>
				<Grid item>
					<HistoryColumn
						number={this.state.number}
						type={this.state.type}
					/>
				</Grid>
			</Grid>
		);
	}

	getContent() {
		var data = this.state.data;
		return (
			<Grid
				container
				justify='space-between'
			>
				<Grid item>
					<DetailsColumn
						pallets={data.pallets}

						fromPost={data.pickupPostcode}
						from={data.pickupCity}
						fromCountry={this.translateCountry(data.pickupCountry)}
						fromPhone={data.pickupPhone}
						fromRefNumber={data.pickupRefNumber}
						fromStreet={data.pickupStreet}
						fromCompany={data.pickupCompany}
						fromName={data.puckupFirstName}
						fromSurname={data.pickupSurname}
						isLiftByPickup={data.isLiftByPickup}
						isPickupEntryLimited={data.isPickupEntryLimited}

						toPost={data.deliveryPostcode}
						to={data.deliveryCity}
						toCountry={this.translateCountry(data.deliveryCountry)}
						toPhone={data.deliveryPhone}
						toRefNumber={data.deliveryRefNumber}
						toStreet={data.deliveryStreet}
						toCompany={data.deliveryCompany}
						toName={data.deliveryFirstName}
						toSurname={data.deliverySurname}
						isLiftByDelivery={data.isLiftByDelivery}
						isDeliveryEntryLimited={data.isDeliveryEntryLimited}

						isPrivate={data.isPrivate}
						remarks={data.remarks}
						type={this.state.type}
						invoiceData={this.getInvoiceData()}
					/>
				</Grid>
				<Grid item>
					{this.getMessageColumn()}
				</Grid>
			</Grid>
		);
	}

	render() {
		if (!this.props.isAdmin) {
			return '';
		}
		if (this.state.loading) {
			return (
				<CircularProgress />
			);
		} else if (this.state.error) {
			return (Translations.get('no_data'));
		} else {
			var data = this.state.data;

			return (
				<Grid
					container
					direction='column'
					style={styles.container}
				>
					<Grid item>
						<Header
							title={`${this.getTrEval(this.state.type)} ${this.state.number}`}
							details={this.getHeaderDetailsText(data.status)}
							stickers={{
								isStarActive: this.state.data.isMarked,
								items: this.state.data.labels
							}}
							otherItems={this.getDropdownMenu()}
							onStarClick={this.onStarClick}
							onStickerClick={this.onStickerClick}
							state={data.status}
						/>
					</Grid>
					<Grid item style={styles.space30Vertical}>
					</Grid>
					<Grid item>
						{this.getBoxRow()}
					</Grid>
					{this.state.type === 'order' &&
						<Grid item style={styles.space30Vertical}>
						</Grid>
					}
					<Grid item>
						{this.getOrderBoxes(this.state.type)}
					</Grid>
					<Grid item>
						{this.getContent()}
					</Grid>
				</Grid>
			);
		}
	}
}





const mapStateToProps = (state) => {
	return {
		isAdmin: state.user.isAdmin,
		userId: state.user.id,
		userToken: state.user.token,
		lang: state.translations.lang,
		eNumber: state.page.eNumber,
		palletsTypes: state.translations.pallets,
		countrys: state.translations.countrys,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (popup) => {
			dispatch(setPopup(popup));
		},
		redirrect: (x, y) => {
			dispatch(redirrect(x, y));
		},
		setEvalNumber: (val) => {
			dispatch(setEvalNumber(val));
		},
		setSupplyData: (x, y) => {
			dispatch(setSupplyData(x, y));
		},
		setRepositoryValue: (x, y) => {
			dispatch(setValue(x, y));
		},
		setInputValue: (x, y) => {
			dispatch(setInputValue(x, y));
		},
		setPaletsCount: (count) => {
			dispatch(setPaletsCount(count));
		},
		setLogin: (x, y, z, q, t, a) => {
			dispatch(setLogin(x, y, z, q, t, a));
		},
		setPopupFunc: (f) => {
			dispatch(setPopupFunc(f));
		}
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(container));
