import React from 'react'
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import {setPopup} from 'Actions/pageActions.js'
import { redirrect } from 'Actions/pageActions';
import {timeFormat} from 'libs/dateformat'
import { Paper, Grid, Typography } from '@material-ui/core';
import { Base64 } from 'js-base64';

import Translations from 'Singleton/Translations'
import styles from '../../../style';

class container extends React.Component{

	styles={
		container:{
			...styles.infoBox,
			marginTop: styles.space30.width,
		}
	}

	hashNumber(number) {
		return Base64.encodeURI(number);
	}

	render(){
		var date = '';
		if(this.props.date){
			date = timeFormat(this.props.date);
		}
		const uri = `/panel/evaluation_details/order/${this.hashNumber(this.props.order)}`
		return(
			<Paper style={this.styles.container}>
				<Grid
					container
					direction='column'
				>
					<Grid item>
						<Typography variant='body1'>
							<b>{Translations.get('client')}: </b>
							{this.props.client}
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant='body1'>
							<b>{Translations.get('order')}: </b>
							<span 
								onClick={() => {
									this.props.redirrect(uri, this.props.history)
								}}
								style={styles.link}
							>
								{this.props.order}
							</span>
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant='body1'>
							<b>{Translations.get('date')}: </b>
							{date}
						</Typography>
					</Grid>
				</Grid>
			</Paper>			
		);
	}
}

container.propTypes={
	client: PropTypes.string.isRequired,
	order: PropTypes.string.isRequired,
	date: PropTypes.number,
}

const mapStateToProps=(state) => {
  return{
  };
};

const mapDispatchToProps=(dispatch) => {
  return{
	  setPopup: (x) => {
		  dispatch(setPopup(x));
	  },
	  redirrect: (x, y) => {
		  dispatch(redirrect(x, y));
	  },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(container));
