const imageBlockFormReducer = (state ={
	paletsCount: 1
}, action) => {
	switch (action.type) {
		case "SET_PALLETS_COUNT":
		state = {
			...state,
			paletsCount: action.paletsCount
		};
		break;
		default:
		break;
	}
	return state;
};

export default imageBlockFormReducer;
