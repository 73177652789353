import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Paper, Grid, Typography } from '@material-ui/core';


import CustomerKeyTranslation from 'Components/Commons/Dictionaries/CustomerTypeDBKeyTranslation'
import Column from 'Components/Commons/Column'

import Translations from 'Singleton/Translations'

import styles from "Components/style";

import placeholder1Image from 'Resources/images/placeholder01.svg'
import placeholder2Image from 'Resources/images/placeholder02.svg'
import lineImage from 'Resources/images/dashed-line.svg'
import MediaType from '../../../libs/MediaType';

class Details extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {
			isVatLoading: true,
			isOrder: this.props.type === 'order',
		}
		this.getSender = this.getSender.bind(this);
		this.getRemarks = this.getRemarks.bind(this);
		this.getInvoiceData = this.getInvoiceData.bind(this);
		this.setVatData = this.setVatData.bind(this);

		if (this.props.type === 'order') {
			this.state = this.setVatData(this.props.invoiceData, this.state);
		}
	}

	__lineImageBase = {
		backgroundImage: `url(${lineImage})`,
		backgroundPosition: 'center',
		backgroundRepeat: 'repeat-y round',
		flexGrow: 2,
	}

	styles = {
		lineImage: {
			...this.__lineImageBase,
			width: '26px',
		},
		lineImageMobile: {
			...this.__lineImageBase,
			width: '20px',
		},
		space: {
			marginBottom: styles.space30.width,
		},
	}

	setVatData(body, state) {
		var country = this.props.countries.find(
			(element) => {
				return body.country === element.value;
			}
		)
		if (!country) {
			return state;
		}
		body.country = country.name;

		state['isVatLoading'] = false;
		return {
			...state,
			...body,
		}
	}

	getSender() {
		const key = this.props.isPrivate ? 'private_suplier' : 'company_suplier'
		return (
			<Paper style={
				MediaType.isMobile ?
					styles.paperBoxMobile :
					styles.paperBox
			}>
				<Typography variant='title' paragraph>
					{Translations.get('purchaser')}:
				</Typography>
				<Typography variant='body1'>
					{CustomerKeyTranslation.Type(key)}
				</Typography>
			</Paper>
		);
	}

	getRemarks() {
		if (!this.props.remarks) {
			return '';
		}

		return (
			<Paper style={
				MediaType.isMobile ?
					styles.paperBoxMobile :
					styles.paperBox
			}>
				<Typography variant='title' paragraph>
					{Translations.get('additional_remarks')}:
				</Typography>
				<Typography variant='body1'>
					{this.props.remarks}
				</Typography>
			</Paper>
		);
	}

	getInvoiceData() {
		if (!this.state.isVatLoading) {
			return (
				<Paper style={
					MediaType.isMobile ?
						styles.paperBoxMobile :
						styles.paperBox
				}>
					<Typography variant='title' paragraph>
						{Translations.get('billing')}:
					</Typography>
					<Typography variant='body1'>
						{this.state.name} {this.state.surname}
					</Typography>
					{this.state.nip &&
						<Typography variant='body1'>
							{Translations.get('optional_nip_vat')}: {this.state.nip}
						</Typography>
					}
					<Typography variant='body1'>
						{this.state.company}
					</Typography>
					<Typography variant='body1'>
						{this.state.street}
					</Typography>
					<Typography variant='body1'>
						{this.state.postcode} {this.state.city}
					</Typography>
					<Typography variant='body1'>
						{this.state.country}
					</Typography>
				</Paper>
			);
		}
		return '';
	}

	getLineImage() {
		return (
			<Grid
				container
				direction='column'
				style={
					MediaType.isMobile ?
						{ ...styles.imageColumnMobile, height: '100%' } :
						{ ...styles.imageColumn, height: '100%' }
				}
				alignItems='center'
			>
				<Grid item>
					<img
						src={placeholder1Image}
						alt='start'
						style={
							MediaType.isMobile ?
								styles.image1Mobile :
								styles.image1
						}
					/>
				</Grid>
				<Grid item style={
					MediaType.isMobile ?
						this.styles.lineImageMobile :
						this.styles.lineImage
				}>
				</Grid>
			</Grid>
		);
	}

	getEndLineImage() {
		return (
			<Grid
				container
				direction='column'
				style={
					MediaType.isMobile ?
						{ ...styles.imageColumnMobile, height: '100%' } :
						{ ...styles.imageColumn, height: '100%' }
				}
				alignItems='center'
			>
				<Grid item>
					<img
						src={placeholder2Image}
						alt='end'
						style={
							MediaType.isMobile ?
								styles.image1Mobile :
								styles.image1
						}
					/>
				</Grid>
			</Grid>
		);
	}

	getInfoColumn(phone, refNumber, liftCar, entryLimited) {
		return (
			<Grid item style={styles.detailColumn1}>
				{phone ?
					<Typography variant='body1'>
						{phone}
					</Typography> : ''
				}
				{refNumber ?
					<Typography variant='body1'>
						{refNumber}
					</Typography> : ''
				}
				{liftCar ?
					<Typography variant='body1'>
						{Translations.get('lift_car')}
					</Typography> : ''
				}
				{entryLimited ?
					<Typography variant='body1'>
						{Translations.get('entry_limited')}
					</Typography> : ''
				}
			</Grid>
		)
	}

	getPickupAddress = () => {
		const phone = `tel. ${this.props.fromPhone}`
		const refNumber = `nr ref. ${this.props.fromRefNumber}`

		return (
			<Grid
				container
				justify='space-between'
				direction={MediaType.isMobile ? 'column' : 'row'}
			>
				<Grid item>
					<Typography variant='body1'>
						{this.props.fromName}
						{' '}
						{this.props.fromSurname}
					</Typography>
					{this.props.fromCompany ? 
						<Typography variant='body1'>
							{this.props.fromCompany}
						</Typography>
					: ''}
					<Typography variant='body1'>
						{this.props.fromStreet}
					</Typography>
					<Typography variant='body1'>
						{this.props.fromPost} {this.state.isOrder && this.props.from}
					</Typography>
					<Typography variant='body1'>
						{this.props.fromCountry}
					</Typography>
				</Grid>
				{this.getInfoColumn(
					this.props.fromPhone ? phone : '',
					this.props.fromRefNumber ? refNumber : '',
					this.props.isLiftByPickup,
					this.props.isPickupEntryLimited
				)}
			</Grid>
		)
	}

	getDeliveryAddress() {
		const phone = `tel. ${this.props.toPhone}`
		const refNumber = `nr ref. ${this.props.toRefNumber}`

		return (
			<Grid
				container
				justify='space-between'
				direction={MediaType.isMobile ? 'column' : 'row'}
			>
				<Grid item>
					<Typography variant='body1'>
						{this.props.toName}
						{' '}
						{this.props.toSurname}
					</Typography>
					{this.props.toCompany ? 
						<Typography variant='body1'>
							{this.props.toCompany}
						</Typography>
					: ''}
					<Typography variant='body1'>
						{this.props.toStreet}
					</Typography>
					<Typography variant='body1'>
						{this.props.toPost} {this.state.isOrder && this.props.to}
					</Typography>
					<Typography variant='body1'>
						{this.props.toCountry}
					</Typography>
				</Grid>
				{this.getInfoColumn(
					this.props.toPhone ? phone : '',
					this.props.toRefNumber ? refNumber : '',
					this.props.isLiftByDelivery,
					this.props.isDeliveryEntryLimited
				)}
			</Grid>
		)
	}

	getPallets() {
		const len = this.props.pallets.length;
		return this.props.pallets.map((item, i) => {
			const width = item.isCustom ? item.customWidth : item.width / 10;
			const length = item.isCustom ? item.customLength : item.length / 10;
			const goodTypeKey = `palette_${item.goodTypeKey}`;

			return (
				<Grid item key={i} style={this.styles.space}>
					{len > 1 ?
						<Typography variant='body1'>
							{Translations.get('palette')} {i + 1}:
						</Typography>
						: ''
					}
					<Typography variant='caption'>
						{item.type} {length}
						x{width}
						x{item.height} cm {' '}
						{item.weight} kg, {' '}
						{item.isFragile && Translations.get('fragile_cargo')}
					</Typography>
					<Typography variant='caption'>
						{Translations.get(goodTypeKey)}
						{item.description &&
							' (' + item.description + ')'
						}
					</Typography>
				</Grid>
			);
		})
	}

	getColumnStyle = () => {
		switch (MediaType.type) {
			case MediaType.TABLET: return {
				paddingBottom: 0,
			}
			case MediaType.MOBILE: return {
				width: '100%',
			}
			default: return {};
		}
	}

	render() {
		return (
			<Column
				title={Translations.get('details')}
				style={this.getColumnStyle()}
			>
				<Paper style={
					MediaType.isMobile ?
						{
							...styles.paperBoxMobile,
							paddingLeft: 0,
							marginTop: styles.space16.width
						} :
						{
							...styles.paperBox,
							paddingLeft: 0,
							marginTop: styles.space30.width
						}
				}>
					<Grid
						container
						direction='column'
					>
						<Grid item>
							<Grid
								container
							>
								<Grid item>
									{this.getLineImage()}
								</Grid>
								<Grid item style={
									MediaType.isMobile ?
										{
											flexGrow: 2,
											width: '80%'
										} :
										{ flexGrow: 2 }
								}>
									<Grid
										container
										direction='column'
									>
										<Grid item style={this.styles.space}>
											{this.getPickupAddress()}
										</Grid>
										{this.getPallets()}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							<Grid container>
								<Grid item>
									{this.getEndLineImage()}
								</Grid>
								<Grid item style={{ flexGrow: 2 }}>
									{this.getDeliveryAddress()}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Paper>

				{this.getSender()}
				{this.getRemarks()}
				{this.getInvoiceData()}
			</Column>
		);
	}
}

Details.propTypes = {
	pallets: PropTypes.arrayOf(PropTypes.object),
	fromPost: PropTypes.string,
	from: PropTypes.string,
	fromCountry: PropTypes.string,
	fromPhone: PropTypes.string,
	fromRefNumber: PropTypes.string,
	fromStreet: PropTypes.string,
	fromName:PropTypes.string,
	fromSurname: PropTypes.string,
	fromCompany: PropTypes.string,
	isLiftByPickup: PropTypes.bool,
	isPickupEntryLimited: PropTypes.bool,

	toPost: PropTypes.string,
	to: PropTypes.string,
	toCountry: PropTypes.string,
	toPhone: PropTypes.string,
	toRefNumber: PropTypes.string,
	toStreet: PropTypes.string,
	toName:PropTypes.string,
	toSurname: PropTypes.string,
	toCompany: PropTypes.string,
	isLiftByDelivery: PropTypes.bool,
	isDeliveryEntryLimited: PropTypes.bool,

	isPrivate: PropTypes.bool,
	remarks: PropTypes.string,
	type: PropTypes.string.isRequired,
	number: PropTypes.string,
	invoiceData: PropTypes.object,
}

const mapStateToProps = (state) => {
	return {
		token: state.user.token,
		countries: state.translations.countrys,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Details);
