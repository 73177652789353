import MediaType from "../../../libs/MediaType";
import styles from "../../style";

export const getRootStyle = () => {
	switch (MediaType.type) {
		case MediaType.TABLET: return styles.moreInfoBoxTablet;
		case MediaType.MOBILE: return styles.moreInfoBoxMobile;
		default: return styles.moreInfoBox;
	}
}

export default getRootStyle;