
export class FilterType {
	static get TEXT() {return 'text';}
	static get CHECKBOX() {return 'checkbox';}
	static get DATE_INTERVAL() {return 'dateInterval';}
	static get NUMBER_INTERVAL() {return 'numInterval';}
	static get VALUATION_TYPE() {return 'evaluationType';}
	static get SEARCH() {return 'search';}
	static get LABELS() {return 'labels';}
	static get STAR() {return 'star';}
}

export class FilterPopup {
	static get TEXT() {return 'text';}
	static get CHECKBOX() {return 'checkbox';}
	static get NUMBER() {return 'numbers';}
	static get DATE() {return 'date';}
	static get VALUATION() {return 'valuation';}
}
