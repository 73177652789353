import React from 'react'
import { connect } from "react-redux";
import RestRequest from 'libs/RestRequest'
import { toast } from 'react-toastify';

import { setPopup } from 'Actions/pageActions'
import Translations from 'Singleton/Translations'
import { Grid, Typography, Button } from '@material-ui/core';
import TextInput from '../../Components/Commons/TextInput';
import { TextInputType } from 'Components/Commons/Const/InputTypes'
import styles from 'Components/style'


class container extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			daysValid: true,
			timeValid: true,
		}

		this.showError = this.showError.bind(this);
		this.handleStatusResponse = this.handleStatusResponse.bind(this);
	}

	cancelClick() {
		this.props.setPopup(undefined);
	}

	showError(msg) {
		toast.error(msg, {
			position: toast.POSITION.TOP_RIGHT,
		});
	}

	handleStatusResponse(res) {
		console.log('status response', res);
		if (res.body.error) {
			// this.showError(res.body.errorReason)
			this.props.acceptFunc(false);
			this.props.setPopup();
		} else {
			this.props.acceptFunc(true);
			this.props.setPopup();
		}
	}

	confirmClick() {
		this.props.setPopup(undefined);
		var body = {
			timeToAccept: this.props.inputs["SendForAcceptance-acceptance-days"],
			timeToDelivery: this.props.inputs['SendForAcceptance-estimated-times'],
			remarks: this.props.inputs["SendForAcceptance-remarks"],
			number: this.props.eNumber,
		}

		RestRequest(
			'post',
			'secured/setEvaluationToAwaitAccept',
			body,
			this.handleStatusResponse,
			this.props.userToken
		);
	}

	isValid() {
		return this.state.daysValid && this.state.timeValid;
	}

	onDaysValidChange = (value) => {
		this.setState({
			...this.state,
			daysValid: value,
		})
	}

	onTimeValidChange = (value) => {
		this.setState({
			...this.state,
			timeValid: value,
		})
	}

	render() {
		return (
			<Grid
				container
				direction='column'
			>
				<Grid item>
					<Grid
						container
						alignItems='baseline'
					>
						<Grid item style={{ flexGrow: 2 }}>
							<TextInput
								fullWidth
								id='SendForAcceptance-acceptance-days'
								label={Translations.get('acceptance_days')}
								type={TextInputType.NUMBER}
								onValidChange={this.onDaysValidChange}
								value={`${14}`}
								limit={3}
							/>
						</Grid>
						<Grid item style={styles.space30}>
						</Grid>
						<Grid item>
							<Typography variant='body1'>
								{Translations.get('days')}.
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<Grid
						container
						alignItems='baseline'
						justify='space-between'
					>
						<Grid item style={{ flexGrow: 2 }}>
							<TextInput
								fullWidth
								id='SendForAcceptance-estimated-times'
								label={Translations.get('estimated_time')}
								type={TextInputType.NUMBER}
								onValidChange={this.onTimeValidChange}
								value={`${3}`}
								limit={3}
							/>
						</Grid>
						<Grid item style={styles.space30}>
						</Grid>
						<Grid item>
							<Typography variant='body1'>
								{Translations.get('days')}.
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<TextInput
						fullWidth
						id='SendForAcceptance-remarks'
						label={Translations.get('remarks')}
						limit={245}
					/>
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Button
						fullWidth
						variant='contained'
						onClick={this.confirmClick.bind(this)}
						color='primary'
						disabled={!this.isValid()}
					>
						{Translations.get('send_for_acceptance')}
					</Button>
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Button
						fullWidth
						variant='outlined'
						onClick={this.cancelClick.bind(this)}
					>
						{Translations.get('cancel')}
					</Button>
				</Grid>
			</Grid>
		);
	}
}


const mapStateToProps = (state) => {
	return {
		userToken: state.user.token,
		eNumber: state.page.eNumber,
		inputs: state.input.inputState,
		acceptFunc: state.page.popupFunc,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (x) => {
			dispatch(setPopup(x));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(container);
