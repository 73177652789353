import React from 'react'
import { connect } from "react-redux";

import { setPopup, setPopupFilter } from 'Actions/pageActions'

import RestRequest from 'libs/RestRequest'
import { Button } from '@material-ui/core';

class test extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			page: ""
		}
		// props.setPopup('forgot_password');
		// props.setPopupFilter(FilterPopup.CHECKBOX)
		this.onClick = this.onClick.bind(this);
	}

	onClick() {
		RestRequest(
			'get',
			'secured/documents',
			// {messageId:31},
			{ number: 'Z/2018/00237' },
			this.handleResponse,
			this.props.userToken
		);
	}

	handleResponse(res) {
		console.log("RESPONSE:", res);

		var url = "data:" + res.body.fileMime + ";base64," + res.body.fileData;

		var anchor = document.createElement("a");
		anchor.href = url;
		anchor.setAttribute("download", res.body.fileName);
		anchor.target = "_blank"
		document.body.appendChild(anchor);
		anchor.click();
		anchor.remove();
	}

	render() {
		return (
			<div>
				<Button
					onClick={this.onClick}
					variant='raised'
				>
					File
				</Button>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userId: state.user.id,
		userToken: state.user.token,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (x) => {
			dispatch(setPopup(x));
		},
		setPopupFilter: (x) => {
			dispatch(setPopupFilter(x));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(test);
