import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done'
import WarningIcon from '@material-ui/icons/Warning'
import ErrorIcon from '@material-ui/icons/Error'

import styles from 'Components/style'

const getColor = (variant) => {
	switch (variant) {
		case 'warning': return styles.palette.yellow;
		case 'error': return styles.palette.red;
		case 'done': return styles.palette.green;
		default: return '#000';
	}
}

const getStyle = (variant) => {
	return {
		color: getColor(variant)
	}
}

const getIcon = (variant) => {
	switch (variant) {
		case 'warning': return (<WarningIcon style={getStyle(variant)} />);
		case 'error': return (<ErrorIcon style={getStyle(variant)} />);
		case 'done': return (<DoneIcon style={getStyle(variant)} />);
		case 'loading': return (<CircularProgress size={18} />);
		default: return '';
	}
}

const component = (props) => {
	return (
		<Grid
			container
			style={ props.centered ? { justifyContent: 'center' } : {}}
			alignItems='baseline'
			wrap={'nowrap'}
		>
			<Grid item>
				{getIcon(props.type)}
			</Grid>
			<Grid item style={styles.space16}>
			</Grid>
			<Grid item>
				<Typography 
					style={getStyle(props.type)} 
					variant={props.variant}
				>
					{props.children}
				</Typography>
			</Grid>
		</Grid>
	)
}

component.propTypes = {
	type: PropTypes.oneOf([
		'warning', 'error', 'done', 'loading',
	]),
	variant: PropTypes.oneOf([
		'body1', 'caption', 'display1', 'headline', 'title'
	]),
	centered: PropTypes.bool,
}

component.defaultProps = {
	type: 'done',
	variant: 'body1',
	centered: false,
}

export default component;