import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import Sticker from 'Components/Commons/Sticker'
import StatusBox from 'Components/Body/OrderDetails/StatusBox'

import { setPopup } from 'Actions/pageActions.js'

import { Grid, Button, IconButton, withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import ArrowIcon from '@material-ui/icons/ArrowDropDown'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import AddIcon from '@material-ui/icons/Add'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import styles from "Components/style";
import MediaType from '../../../libs/MediaType';

const classes = theme => ({
	button: {
		backgroundColor: theme.palette.common.white
	}
})

class Header extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			anchorEl: null,
		}

		this.getStickers = this.getStickers.bind(this);
		this.getStarButton = this.getStarButton.bind(this);
		this.onStarClick = this.onStarClick.bind(this);
		this.getButtons = this.getButtons.bind(this);
		this.getCrossButton = this.getCrossButton.bind(this);
		this.onCrossClick = this.onCrossClick.bind(this);
	}

	styles = {
		labels: {
			display: 'flex',
			alignItems: 'center',
		},
		icon: {
			width: '3vw',
			height: '3vw',
		},
	}

	getStarButton() {
		var star = this.props.stickers.isStarActive ?
			<StarIcon color='primary' /> :
			<StarBorderIcon />;
		return (
			<IconButton
				onClick={this.onStarClick}
			>
				{star}
			</IconButton>
		);
	}

	getCrossButton() {
		return (
			<IconButton
				onClick={this.onCrossClick}
			>
				<AddIcon />
			</IconButton>
		);
	}

	onStarClick() {
		if (this.props.onStarClick) {
			this.props.onStarClick();
		}
	}

	onCrossClick() {
		this.props.setPopup('label');
	}

	onStickerClick(name) {
		console.log("Label click: ", name);
		if (this.props.onStickerClick) {
			this.props.onStickerClick(name);
		}
	}

	getStickers() {
		if (this.props.stickers) {

			var labels = this.props.stickers.items.map((item, i) => {
				return (
					<Sticker
						key={i}
						onClick={() => { this.onStickerClick(item) }}
					>
						{item}
					</Sticker>
				);
			})

			return (
				<div style={this.styles.labels}>
					{this.getStarButton()}
					{labels}
					{this.getCrossButton()}
				</div>
			);
		}
		return '';
	}

	getButtons() {
		if (this.props.buttons) {
			return this.props.buttons.map((item, i) => {
				return (
					<Button
						key={i}
						variant='outlined'
						onClick={item.callback}
						style={i > 0 ? { marginLeft: styles.space30.width } : {}}
						classes={{
							root: this.props.classes.button
						}}
					>
						{item.text}
					</Button>
				);
			});
		}
		return '';
	}

	title = () => {
		return (
			<Typography variant={
				MediaType.isMobile ?
					'title' :
					'display1'
			}>
				{this.props.title}
			</Typography>
		)
	}

	render() {
		if (!this.props.pagePath.includes('panel')) {
			if (MediaType.isMobile) {
				return this.renderMobile();
			}
			if (MediaType.isTablet) {
				return this.renderTbalet();
			}
		}
		return (
			<Grid
				container
				justify='space-between'
				style={{
					paddingTop: styles.space30.width,
				}}
			>
				<Grid item>
					{this.title()}
				</Grid>
				<Grid item>
					{this.getStickers()}
				</Grid>
				<Grid item style={{ flexGrow: 2 }}>
				</Grid>
				<Grid item>
					<StatusBox
						label={this.props.details}
						state={this.props.state}
					/>
				</Grid>
				<Grid item style={styles.space30}>
				</Grid>
				<Grid item>
					{this.getButtons()}
				</Grid>
				<Grid item>
					{this.props.otherItems}
				</Grid>
			</Grid>
		);
	}

	renderTbalet() {
		return (
			<Grid
				container
				direction='column'
				style={{
					paddingTop: styles.space30.width,
				}}
			>
				<Grid item>
					{this.title()}
				</Grid>
				<Grid style={styles.space30Vertical}>
				</Grid>
				<Grid item>
					<Grid container>
						<Grid item>
							<StatusBox
								label={this.props.details}
								state={this.props.state}
							/>
						</Grid>
						<Grid item style={{ flexGrow: 2 }}>
						</Grid>
						<Grid item style={styles.space30}>
						</Grid>
						<Grid item>
							{this.getButtons()}
						</Grid>
						<Grid item>
							{this.props.otherItems}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		);
	}

	handleArrowClick = (event) => {
		this.setState({
			...this.state,
			anchorEl: event.currentTarget,
		})
	}

	handleArrowClose = () => {
		this.setState({
			...this.state,
			anchorEl: null
		})
	}

	getMenuItems = () => {
		if (this.props.buttons) {
			return this.props.buttons.map((item, i) => {
				return (
					<MenuItem
						key={i}
						onClick={() => {
							item.callback();
							this.handleArrowClose();
						}}
					>
						{item.text}
					</MenuItem>
				);
			});
		}
		return '';
	}

	renderMobile() {
		const { anchorEl } = this.state;

		return (
			<Grid
				container
				direction='column'
			>
				<Grid item>
					<Grid
						container
						alignItems='center'
						justify='space-between'
						wrap='nowrap'
					>
						<Grid item>
							{this.title()}
						</Grid>
						<Grid item>
							<IconButton
								aria-owns={anchorEl ? 'arrow-menu' : null}
								aria-haspopup="true"
								onClick={this.handleArrowClick}
							>
								<ArrowIcon />
							</IconButton>
							<Menu
								id="arrow-menu"
								anchorEl={anchorEl}
								open={Boolean(anchorEl)}
								onClose={this.handleArrowClose}
							>
								{this.getMenuItems()}
							</Menu>
						</Grid>
					</Grid>
				</Grid>
				<Grid style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<StatusBox
						label={this.props.details}
						state={this.props.state}
					/>
				</Grid>
			</Grid>
		)
	}
}

Header.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string.isRequired,
	details: PropTypes.string.isRequired,
	buttons: PropTypes.arrayOf(PropTypes.shape({
		text: PropTypes.string.isRequired,
		callback: PropTypes.func.isRequired
	})),
	otherItems: PropTypes.any,
	stickers: PropTypes.shape({
		isStarActive: PropTypes.bool.isRequired,
		items: PropTypes.arrayOf(
			PropTypes.string
		).isRequired,
		onStarClick: PropTypes.func,
		onStickerClick: PropTypes.func,
	}),
	state: PropTypes.string,
};

const mapStateToProps = (state) => {
	return {
		pagePath: state.page.page,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (popup) => {
			dispatch(setPopup(popup));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(classes)(Header));
