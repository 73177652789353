import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Menu, MenuItem } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';

export class component extends Component {
	constructor(props) {
		super(props);
		this.state = {
			anchorEl: null,
		}
	}

	handleClick = event => {
		this.setState({ anchorEl: event.currentTarget });
		if(this.props.onClick){
			this.props.onClick();
		}
		
	};

	handleClose = () => {
		this.setState({ anchorEl: null });
		if(this.props.onClick){
			this.props.onClose();
		}
		
	};

	getMenuItems = () => {
		return this.props.rows.map((item,i) => {
			return (
				<MenuItem 
					key={i}	
					disabled={item.disabled}
					onClick={()=>{
						this.handleClose();
						item.onClick();
					}}>
					{item.label}
				</MenuItem>
			);
		})
	}

	render() {
		const { anchorEl } = this.state;
		const open = Boolean(anchorEl);

		return (
			<div>
				<Button
					aria-owns={open ? 'fade-menu' : null}
					aria-haspopup="true"
					onClick={this.handleClick}
					variant={this.props.variant}
					color={this.props.color}
					style={this.props.style}
				>
					{this.props.label}
				</Button>
				<Menu
					id="fade-menu"
					anchorEl={anchorEl}
					open={open}
					onClose={this.handleClose}
					TransitionComponent={Fade}
				>
					{this.getMenuItems()}
				</Menu>
			</div>
		)
	}
}

component.propTypes = {
	style: PropTypes.object,
	variant: PropTypes.oneOf([
		'contained', 'outlined', 'flat'
	]),
	color: PropTypes.oneOf([
		'default', 'inherit', 'primary', 'secondary'
	]),
	label: PropTypes.any,
	onClick: PropTypes.func,
	onClose: PropTypes.func,
	rows: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			onClick: PropTypes.func,
			disabled: PropTypes.bool,
		}).isRequired
	),
	backgroundColor: PropTypes.string,
}

component.defaultProps = {
	variant: 'flat',
	color: 'default',
}

export default component;