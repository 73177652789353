import React, { Component } from 'react';
import { connect } from "react-redux";
import { sessionService } from 'redux-react-session';
import { withRouter } from 'react-router-dom'

import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { setPopup, redirrect } from '../../Actions/pageActions'
import { setLogin } from '../../Actions/userActions'

import logo from '../../Resources/images/TREO-logo.svg'

import Translations from 'Singleton/Translations'
import { Grid } from '@material-ui/core';
import styles from 'Components/style'

export class HeaderBarFull extends Component {

	constructor(props) {
		super(props);

		this.state = {
			//value: this.getCustomPath(),
			anchorMenu: null,
		}
	}


	styles = {
		container: {
			position: 'fixed',
			zIndex: '1',
			width: '100%',
		},
		appbar: {
			// width: '1440px',
			// backgroundColor: 'rgba(0,0,0,0)'

			borderBottom: 'rgba(0, 0, 0, 0.2) solid 1px',
			boxShadow: 'none',
		},
		toolbar: {
			...styles.rootWeb,
			paddingLeft: '45px',
			paddingRight: '45px',
		},
		tabs: {
			width: '100%',
		},
		tab: {
			outline: 'none',
		},
		image: {
			height: '30px',
		},
		buttonText: {
			fontSize: '9px',
			position: 'relative',
		},
		logoButton: {
			...styles.link,
			width: '120px',
			textDecoration: 'none',
		}
	}

	getCustomPath() {
		const path = this.props.location.pathname;

		if (path.startsWith('/panel/evaluation_details/evaluation/'))
			return '/panel/evaluation';
		else if (path.startsWith('/panel/client_details/'))
			return '/panel/client';
		else if (path.startsWith('/panel/coupon_details/'))
			return '/panel/coupons';
		else if (path.startsWith('/panel/employee_details/'))
			return '/panel/employees';
		else if (path.startsWith('/panel/evaluation_details/order/'))
			return '/panel/orders';
		else if (path === '/panel')
			return '/panel/orders';
		else
			return path;
	}

	getSelected(btn) {
		if (btn.indexOf(this.props.page.page) > -1) {
			return "selected";
		}
		return "";
	}

	loginBtnClick() {
		this.props.setPopup('login');
	}

	logoClick() {
		this.props.redirrect('/', this.props.history)
	}

	panelRedirrect() {
		this.props.redirrect('/panel', this.props.history)
	}


	userSettingsRedirect() {
		this.props.redirrect('/user_settings', this.props.history)
	}

	logout() {
		sessionService.deleteSession().then(
			sessionService.deleteUser().then(() => {
				this.props.setLogin(null, null, null, null, null);
				this.props.redirrect('/', this.props.history);
			})
		);
	}

	handleChange = (event, value) => {
		//this.setState({ ...this.state, value });
		this.props.redirrect(value, this.props.history);
	};

	getTabs() {
		const path = this.getCustomPath();
		return (
			<Tabs
				indicatorColor="primary"
				textColor="primary"
				value={path}
				onChange={this.handleChange}
				style={this.styles.tabs}
				centered
			>
				<Tab
					label={Translations.get('orders')}
					value="/panel/orders"
					style={this.styles.tab}
					disabled={!this.props.isAdmin}
				/>
				<Tab
					label={Translations.get('evaluations')}
					value="/panel/evaluation"
					style={this.styles.tab}
					disabled={!this.props.isAdmin}
				/>
				<Tab
					label={Translations.get('clients')}
					value="/panel/client"
					style={this.styles.tab}
					disabled={!this.props.isAdmin}
				/>
				<Tab
					label={Translations.get('coupons')}
					value="/panel/coupons"
					style={this.styles.tab}
					disabled={!this.props.isAdmin}
				/>
				<Tab
					label={Translations.get('price_list')}
					value="/panel/price_list"
					style={this.styles.tab}
					disabled={!this.props.isAdmin}
				/>
				<Tab
					label={Translations.get('employees')}
					value="/panel/employees"
					style={this.styles.tab}
					disabled={!this.props.isAdmin}
				/>
			</ Tabs>
		);
	}

	getButtonText = (text) => {
		var nText = text;
		const len = 13;
		if (text.length > len) {
			nText = text.slice(0, len - 3);
			nText += '...';
		}
		return nText;
	}

	getButton() {
		if (this.props.user) {
			return (
				<Button
					color="inherit"
					onClick={this.handleClickMenu}
					aria-owns={this.state.anchorMenu ? 'admin-menu' : null}
					aria-haspopup="true"
				>
					<Typography>
						{this.getButtonText(this.props.user)}
					</Typography>
					<ArrowDropDownIcon />
				</Button>
			);
		} else {
			return (
				<Button
					color="inherit"
					onClick={() => this.loginBtnClick()}
				>
					{Translations.get('login')}
				</Button>
			)
		}
	}

	handleClose = action => () => {
		this.setState({
			...this.state,
			anchorMenu: null,
		});

		switch (action) {
			case 'logout': this.logout(); break;
			case 'settings': this.userSettingsRedirect(); break;
			case 'panel': this.panelRedirrect(); break;
			default: break;
		}
	}

	handleClickMenu = event => {
		this.setState({
			...this.state,
			anchorMenu: event.currentTarget,
		});
	}

	getMenu() {
		if (this.props.user) {
			return (
				<ClickAwayListener onClickAway={this.handleClose()}>
					<Menu
						id="admin-menu"
						anchorEl={this.state.anchorMenu}
						open={Boolean(this.state.anchorMenu)}
						onClose={this.handleClose()}
					>
						{
							this.props.isAdmin
								?
								<MenuItem onClick={this.handleClose('panel')}>{Translations.get('panel')}</MenuItem>
								:
								<MenuItem onClick={this.handleClose('settings')}>{Translations.get('settings')}</MenuItem>
						}
						<MenuItem onClick={this.handleClose('logout')}>{Translations.get('logout')}</MenuItem>

					</Menu>
				</ClickAwayListener>
			);
		}
		return '';
	}

	getLogo = () => {

		return (
			<a
				style={this.styles.logoButton}
				onClick={this.logoClick.bind(this)}
			>
				<img src={logo} alt={'Treo'} style={this.styles.image} />
				<Typography variant='caption' style={this.styles.buttonText}>
					{Translations.get('management_panel')}
				</Typography>
			</a>
		)
	}

	render() {
		return (
			<div style={this.styles.container}>
				<AppBar
					position='static'
					elevation={0}
					color='inherit'
					style={this.styles.appbar}
				>
					<Toolbar variant='dense' style={this.styles.toolbar}>
						<Grid
							container
							alignItems='baseline'
						>
							<Grid item>
								{this.getLogo()}
							</Grid>

							<Grid item style={{ flexGrow: 2 }}>
								{this.getTabs()}
							</Grid>
							<Grid item>
								{this.getButton()}
								{this.getMenu()}
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		page: state.page,
		user: state.user.login,
		isAdmin: state.user.isAdmin
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (popup) => {
			dispatch(setPopup(popup));
		},
		setLogin: (x, y, z, q, t, a) => {
			dispatch(setLogin(x, y, z, q, t, a));
		},
		redirrect: (x, y) => {
			dispatch(redirrect(x, y));
		}
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderBarFull));

