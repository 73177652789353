import { COUNTRY_ADDRESS_POSTALS } from './countriesAddressPostal';

const evaluationProxy = (value, country) => {
  const postalRegexp = _findPostalRegexp(country);
  if (typeof value === 'string') {
    //  manual input
    const matched = value.match(postalRegexp);
    if (matched) {
      return {
        option: value,
        postcode: matched[0],
        city: '',
        street: '',
        streetNumber: ''
      }
    }
  }
  else if (value.option) {
    //  autocomplete response
    const matched = value.option.match(postalRegexp);
    if (matched) {
      return {
        ...value,
        postcode: matched[0]
      }
    }
  }
  return value;
};

const _findPostalRegexp = (abbrev) => (COUNTRY_ADDRESS_POSTALS.find(i => i.abbrev === abbrev) || {}).postal;

export default evaluationProxy;