import React from "react";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import { sessionService } from 'redux-react-session';
import { withRouter } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'

import Rest from 'libs/RestRequest'

import Makeid from 'libs/GUID'
import FlagIcon from 'Components/Commons/FlagIcon'
import Checkbox from 'Components/Commons/Checkbox'
import TextInput from 'Components/Commons/TextInput'

import { ERROR } from 'libs/RestRequest'
import { setLogin } from 'Actions/userActions'
import { redirrect } from 'Actions/pageActions';

import Translations from 'Singleton/Translations'
import { Paper, Grid, Typography, Button, MenuItem } from "@material-ui/core";
import styles from 'Components/style'
import { TextInputType } from 'Components/Commons/Const/InputTypes'
import FlagSelect from 'Components/Commons/FlagSelect'

import AutoCompleteRest from 'Components/Commons/AutoCompleteRest'
import MediaType from "../../../libs/MediaType";


class container extends React.Component {
	constructor(props) {
		super(props);
		var countrys = [];
		for (var country of props.translations.countrys) {
			countrys.push({ key: country.value.trim(), value: country.name.trim() });
		}

		this.state = {
			countries: countrys,
			isPostcodeOk: false,
			isCityValid: false,
			isCompany: false,
			isLoading: true,
			data: undefined,
			functions: {}
		}

		this.isAllValid = this.isAllValid.bind(this);
		this.onConfirmClick = this.onConfirmClick.bind(this);
		this.handleDataUser = this.handleDataUser.bind(this);
		this.getContent = this.getContent.bind(this);

		this.showError = this.showError.bind(this);
		this.handleErr = this.handleErr.bind(this);
		this.logout = this.logout.bind(this);

		Rest('get',
			'/secured/userData',
			{},
			this.handleDataUser,
			props.userToken,
		)
	}


	bindFunction = (name) => (callback) => {
		const f = this.state.functions;
		f[name] = callback;
		this.setState({
			...this.state,
			functions: f,
		})
	}

	ownOptionFunctionForCountrys(id, row) {
		var style = {
			display: "inline",
			marginLeft: '1vw'
		}

		return <MenuItem
			key={this.props.id + Makeid(5)}
			value={row.key}
			label={<FlagIcon code={row.key.toLowerCase()} />}
			primaryText={
				<div>
					<FlagIcon code={row.key.toLowerCase()} />
					<p style={style}>{row.value}</p>
				</div>
			}
		/>
	}


	logout() {
		sessionService.deleteSession().then(
			sessionService.deleteUser().then(() => {
				this.props.setLogin(null, null, null, null, null);
				this.props.redirrect('/', this.props.history);
			})
		);
	}

	showError(msg) {
		toast.error(msg, {
			position: toast.POSITION.TOP_RIGHT,
		});
	}

	handleErr(body) {
		switch (body.errorCode) {
			case ERROR.USER_TOKEN_IS_NOT_VALID:
				this.showError(Translations.get('session_expired'))
				this.logout();
				break;
			default:
				this.showError(body.errorReason)
		}
	}

	handleDataUser(res) {
		console.log("Get user data", res);
		if (res.body.error) {
			this.handleErr(res.body);
		}

		this.setState({
			...this.state,
			isLoading: false,
			data: res.body,
			isPostcodeOk: res.body.postcode ? true : false,
			isCityValid: res.body.city ? true : false,
			isNameValid: res.body.name ? true : false,
			isStreetValid: res.body.street ? true : false,
			isSurnameValid: res.body.surname ? true : false,
			isCompany: res.body.company ? true : false,
			isCompanyValid: res.body.company ? true : false,
		})
	}

	onConfirmClick() {
		var data = this.props.inputs;
		var body = {
			name: data.userSettingName,
			surname: data.userSettingSurname,
			country: data.userSettingCountry,
			postcode: data.userSettingsPostcode,
			city: data.userSettingsCity,
			street: data.userSettingStreet,
			company: this.state.isCompany ? data.userSettingCompanyName : '',
			language: data.userSettingLanguage,
		}
		Rest('post',
			'/secured/userData',
			body,
			this.handlePostData,
			this.props.userToken,
		);
	}

	handlePostData(res) {
		console.log("Update user data", res)
	}

	onValidChange(key, value) {
		console.log(key, ": ", value);
		this.setState({
			...this.state,
			[key]: value,
		});
	}

	isAllValid() {
		var value =
			this.state.isCityValid &&
			this.state.isNameValid &&
			this.state.isStreetValid &&
			this.state.isSurnameValid;
		if (this.state.isCompany) {
			value = value && this.state.isCompanyValid;
		}
		return value;
	}

	styles = {
		halfInput: {
			width: "48%"
		},
		loadingAnim: {
			display: 'flex',
			justifyContent: 'center',
			margin: '5vw',
		}
	}

	getNameRow() {
		return (
			<Grid
				container
				direction={MediaType.isMobile ? 'column' : 'row'}
			>
				<Grid item style={
					MediaType.isMobile ?
						styles.mobileBox :
						styles.settingColumn1
				}>
					<TextInput
						fullWidth
						id="userSettingName"
						label={Translations.get('name')}
						type={TextInputType.NOT_EMPTY}
						onValidChange={(val) => { this.onValidChange("isNameValid", val); }}
						value={this.state.data.name}
					/>
				</Grid>
				<Grid item style={styles.space30}>
				</Grid>
				<Grid item style={
					MediaType.isMobile ?
						styles.mobileBox :
						styles.settingColumn1
				}>
					<TextInput
						fullWidth
						id="userSettingSurname"
						label={Translations.get('surname')}
						type={TextInputType.NOT_EMPTY}
						onValidChange={(val) => { this.onValidChange("isSurnameValid", val); }}
						value={this.state.data.surname}
					/>
				</Grid>
			</Grid>
		)
	}

	getCoutryComponent(id, label, defaultValue, disabled, onChange) {
		return (
			<Grid
				container
				justify='space-between'
			>
				<Grid item>
					<Typography variant='body1'>
						{label}
					</Typography>
				</Grid>
				<Grid item style={styles.space30}>
				</Grid>
				<Grid item>
					<FlagSelect
						id={id}
						defaultValue={defaultValue}
						disabled={disabled}
						onChange={onChange}
					/>
				</Grid>
			</Grid>
		)
	}

	handleCountryChange = () => {
		this.state.functions.postcode();
	}

	handlePostcodeChange = (value) => {
		console.log('setState postcode', this.state);
		this.setState({
			...this.state,
			isPostcodeOk: value ? true : false,
		}, this.state.functions.city);
	}

	handleCityChange = (value) => {
		this.state.functions.street('');
		console.log('setState city', this.state);
		const state = this.state;
		state['isCityValid'] = value ? true : false;
		this.setState(state);
	}

	getCountryRow() {
		return (
			<Grid
				container
				alignItems='baseline'
				direction={MediaType.isMobile ? 'column' : 'row'}
			>
				<Grid item style={
					MediaType.isMobile ?
						styles.mobileBox :
						styles.settingColumn1
				}>
					{this.getCoutryComponent(
						'userSettingCountry',
						'Kraj: ',
						this.state.data.country ? this.state.data.country : 'PL',
						false,
						this.handleCountryChange
					)}
				</Grid>

				<Grid item style={
					MediaType.isMobile ?
						styles.space16Vertical :
						styles.space30
				}>
				</Grid>

				<Grid item style={
					MediaType.isMobile ?
						styles.mobileBox :
						styles.settingColumn1
				}>
					{this.getCoutryComponent(
						'userSettingLanguage',
						'Język: ',
						this.state.data.language ? this.state.data.language : 'PL',
						true,
						() => { }
					)}
				</Grid>
			</Grid>
		)
	}

	getPostCodeRow() {
		return (
			<Grid
				container
			>
				<Grid item style={
					MediaType.isMobile ?
						styles.mobileBox :
						styles.settingColumn1
				}>
					<AutoCompleteRest
						placeholder={Translations.get('postcode')}
						id='userSettingsPostcode'
						rest={{
							door: "postcode",
							params: {
								country: this.props.inputs.userSettingCountry
							},
							resFieldName: "postcode",
							inputParamName: "postalcode",
						}}
						defaultValue={this.state.data.postcode}
						bindSetValueFunc={this.bindFunction('postcode')}
						onChange={this.handlePostcodeChange}
					/>
				</Grid>
				<Grid item style={styles.space30}>
				</Grid>

				<Grid item style={
					MediaType.isMobile ?
						styles.mobileBox :
						styles.settingColumn1
				}>
					<AutoCompleteRest
						placeholder={Translations.get('city')}
						required
						id='userSettingsCity'
						rest={{
							door: "postcode/city",
							params: {
								country: this.props.inputs.userSettingCountry,
								postalcode: this.props.inputs.userSettingsPostcode,
							},
							resFieldName: "city",
							inputParamName: "city",
						}}
						disabled={!this.state.isPostcodeOk}
						defaultValue={this.state.data.city}
						bindSetValueFunc={this.bindFunction('city')}
						onChange={this.handleCityChange}
					/>
				</Grid>
			</Grid>
		);
	}

	getStreetRow() {
		return (
			<TextInput
				fullWidth
				label={Translations.get('street_and_number')}
				id="userSettingStreet"
				type={TextInputType.NOT_EMPTY}
				onValidChange={(val) => { this.onValidChange("isStreetValid", val); }}
				value={this.state.data.street}
				getChangeValueFunc={this.bindFunction('street')}
			/>
		);
	}

	getContent() {
		return (
			<Grid
				container
				direction='column'
			>
				<Grid item>
					{this.getNameRow()}
				</Grid>
				<Grid item style={styles.space30Vertical}>
				</Grid>
				<Grid item>
					{this.getCountryRow()}
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					{this.getPostCodeRow()}
				</Grid>
				<Grid item>
					{this.getStreetRow()}
				</Grid>
				<Grid item>
					<Checkbox
						label={Translations.get('company_account')}
						id="isCompany"
						onChange={(value) => {
							this.setState({
								...this.state,
								isCompany: value,
							})
						}}
						defaultChecked={this.state.data.company ? true : false}
					/>
				</Grid>
				{this.state.isCompany &&
					<Grid item style={{ marginBottom: styles.space16Vertical.height }}>
						<TextInput
							fullWidth
							label={Translations.get('company_name')}
							id="userSettingCompanyName"
							type={TextInputType.NOT_EMPTY}
							onValidChange={(val) => { this.onValidChange("isCompanyValid", val); }}
							value={this.state.data.company}
						/>
					</Grid>
				}

				<Grid item>
					<Button
						variant='outlined'
						onClick={this.onConfirmClick}
						disabled={!this.isAllValid()}
					>
						{Translations.get('update')}
					</Button>
				</Grid>
			</Grid>
		);
	}

	render() {
		return (
			<Paper style={
				MediaType.isMobile ?
					styles.paperBoxMobile :
					styles.paperBox
			}>
				<Grid
					container
					direction='column'
				>
					<Grid item>
						<Typography variant='title' paragraph>
							{Translations.get('data')}
						</Typography>
					</Grid>
					<Grid item>
						{
							this.state.isLoading ?
								<CircularProgress size={80} thickness={5} /> :
								this.getContent()
						}
					</Grid>
				</Grid>
			</Paper>
		);
	}
}

container.propTypes = {
}

const mapStateToProps = (state) => {
	return {
		userToken: state.user.token,
		userEmail: state.user.login,
		userId: state.user.id,
		translations: state.translations,
		inputs: state.input.inputState,

	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setLogin: (x, y, z, q, t, a) => {
			dispatch(setLogin(x, y, z, q, t, a));
		},
		redirrect: (x, y) => {
			dispatch(redirrect(x, y));
		}
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(container));
