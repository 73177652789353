import React from 'react'
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress'
import { sessionService } from 'redux-react-session';
import { toast } from 'react-toastify';

import { Paper, Grid, Button } from '@material-ui/core';
import IconText from 'Components/Commons/IconText'
import VatConst from 'Components/Commons/Const/Vat'

import { setPopup } from 'Actions/pageActions'
import { setValue } from 'Actions/repositoryActions'

import RestRequest from 'libs/RestRequest'
import { ERROR } from 'libs/RestRequest'

import styles from '../../../style';

import Translation from 'Singleton/Translations'

class container extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			state: this.props.state,
			nextState: '',
		}

		this.getContent = this.getContent.bind(this);
		this.getDuringBox = this.getDuringBox.bind(this);
		this.getApprovedBox = this.getApprovedBox.bind(this);
		this.showMoreInfoPopup = this.showMoreInfoPopup.bind(this);
		this.handleResponse = this.handleResponse.bind(this);
		this.handleErr = this.handleErr.bind(this);
		this.showError = this.showError.bind(this);
		this.logout = this.logout.bind(this);

		this.props.setRepositoryValue('userId', this.props.userId);
	}

	showMoreInfoPopup() {
		this.props.setPopup('show_vat_info');
	}

	setVatState(state) {
		RestRequest(
			'post',
			'secured/vat/status',
			{
				userId: this.props.userId,
				status: state,
			},
			this.handleResponse,
			this.props.userToken
		);
		this.setState({
			...this.state,
			isLoading: true,
			nextState: state,
		})
	}

	logout() {
		sessionService.deleteSession().then(
			sessionService.deleteUser().then(() => {
				this.props.setLogin(null, null, null, null, null);
				this.props.redirrect('/', this.props.history);
			})
		);
	}

	showError(msg) {
		toast.error(msg, {
			position: toast.POSITION.TOP_RIGHT,
		});
	}

	handleErr(body) {
		switch (body.errorCode) {
			case ERROR.USER_TOKEN_IS_NOT_VALID:
				this.showError(Translation.get('session_expired'))
				this.logout();
				break;
			default:
				this.showError(body.errorReason)
		}
	}

	handleResponse(res) {
		console.log('Vat0 setStatus', res);
		var nextState = this.state.nextState;
		if (res.body.error) {
			nextState = this.state.state;
			this.handleErr(res.body);
		}
		this.setState({
			...this.state,
			isLoading: false,
			state: nextState,
		})
	}

	getDuringBox() {
		return (
			<Grid
				container
				direction='column'
				alignItems='center'
				justify='space-between'
				style={{ height: '100%' }}
			>
				<Grid item>
					<IconText type='warning' variant='body1'>
						{Translation.get('for_verification_zero_percent_vat')}
						{' '}
						<a
							onClick={this.showMoreInfoPopup}
							style={styles.link}
						>
							{Translation.get('show_data')}
						</a>
					</IconText>
				</Grid>
				<Grid item style={{ width: '100%' }}>
					<Grid
						container
						justify='space-between'
					>
						<Grid item>
							<Button
								style={styles.button1}
								variant='outlined'
								onClick={() => { this.setVatState(VatConst.APPROVED) }}
							>
								{Translation.get('accept')}
							</Button>
						</Grid>
						<Grid item>
							<Button
								style={styles.button1}
								variant='outlined'
								onClick={() => { this.setVatState(VatConst.REJECTED) }}
							>
								{Translation.get('discard')}
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		);
	}

	getApprovedBox() {
		return (
			<Grid
				container
				direction='column'
				alignItems='center'
				justify='space-between'
				style={{ height: '100%' }}
			>
				<Grid item>
					<IconText type='done' variant='body1'>
						{Translation.get('verified_zero_percent_vat')}
						{' '}
						<a
							onClick={this.showMoreInfoPopup}
							style={styles.link}
						>
							{Translation.get('show_data')}
						</a>
					</IconText>
				</Grid>
				<Grid item>
					<Button
						variant='outlined'
						onClick={() => { this.setVatState(VatConst.NONE) }}
					>
						{Translation.get('restore_standard_vat')}
					</Button>
				</Grid>
			</Grid>
		);
	}

	getContent(status) {
		if (this.state.isLoading) {
			return (
				<CircularProgress
					size={80}
					thickness={5}
				/>
			);
		}
		switch (status) {
			case VatConst.DURING: return this.getDuringBox();
			case VatConst.APPROVED: return this.getApprovedBox();
			default: return '__NO_DATA__';
		}
	}

	render() {
		if (this.state.state === VatConst.NONE ||
			this.state.state === VatConst.REJECTED) {
			return '';
		}

		return (
			<Paper style={styles.infoBox}>
				{this.getContent(this.state.state)}
			</Paper>
		);
	}
}

container.propTypes = {
	state: PropTypes.string.isRequired,
	userId: PropTypes.number.isRequired,
}

const mapStateToProps = (state) => {
	return {
		userToken: state.user.token,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (x) => {
			dispatch(setPopup(x));
		},
		setRepositoryValue: (x, y) => {
			dispatch(setValue(x, y));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(container);
