import React from "react";
import PropTypes from 'prop-types';

import { withTheme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const __styles = (theme) => {
	return ({
		circleBox:{
			display: 'table',
			margin: '0 auto',
		},
		circle: {
			border: `solid 4px ${theme.palette.primary.main}`,
			borderRadius: '50%',
			width: '95px',
			height: '95px',
			display: 'table-cell',
			verticalAlign: 'middle',
		},
		grid: {
		},
		description:{
			marginTop: '45px',
      padding: '0 25px'
		}
	});
}

const CircleMark = (props) => {
	const theme = props.theme;
	const styles = __styles(theme);

	return (
		<Grid
			container
			direction='column'
			style={styles.grid}
		>
			<Grid item>
				<div style={styles.circleBox}>
					<div style={styles.circle}>
						<Typography component="span" variant='display2' align='center' color='primary'>
							{props.number}
						</Typography>
					</div>
				</div>
			</Grid>
			<Grid item>
				<Typography component="span" variant='subheading' align='center' style={styles.description}>
					{props.children}
				</Typography>
			</Grid>
		</Grid>
	);
};

CircleMark.propTypes = {
	number: PropTypes.number.isRequired,
	text: PropTypes.string,
};


export default withTheme()(CircleMark);
