export function setInputValue(id,value){
	return {
		type: "INPUT_SET_VALUE",
		id: id,
		value: value
	};
}

export const setInputValid = (id, value) => (
	{
		type: "INPUT_SET_VALID",
		id, value
	}
)

export const setInputDefaultValue = (id, value) => (
	{
		type: "INPUT_SET_DEFAULT_VALUE",
		id, value
	}
)

export const clearDefaultValues = () => ({
	type: "INPUT_RESET_DEFAULT_VALUE"
})

export const setHighlightErrors = () => ({
	type: "INPUT_SET_HIGHLIGHT_ERRORS"
})

export const resetHighlightErrors = () => ({
	type: "INPUT_RESET_HIGHLIGHT_ERRORS"
})
