import {SupplierName} from 'Components/Commons/Const/Supplier'
import Translations from 'Singleton/Translations'

export default class SupplierKeyTranslation {
	static name(key){
		switch (key) {
			case SupplierName.RABEN: return Translations.get('raben');
			case SupplierName.ROHLIC: return Translations.get('rohlic');
			case SupplierName.GEIS: return Translations.get('geis');
			case SupplierName.GLS: return Translations.get('gls');
			default: return "__NO_DATA__";
		}
	}
}
