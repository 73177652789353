import React from 'react';
import { connect } from "react-redux";

import { setPopup } from './../../../Actions/pageActions'

import Translations from 'Singleton/Translations'
import FooterContainer from './FooterContainer'

class container extends React.Component {
	

	render() {
		switch (this.props.popup) {
			case 'filtrs':
				return (
					<div></div>
				);
			case 'register':
				return (
					<FooterContainer
						text={Translations.get('ask_have_already_acc')}
						link={{
							text: Translations.get('sign_in'),
							onClick: () => this.props.setPopup('login')
						}}
					/>
				);
			case 'continue_without_account':
			case 'register_facebook':
			case 'register_google':
			case 'register_external':
			case 'register_email':
				return (
					<FooterContainer
						link={{
							text: Translations.get('back_to_register'),
							onClick: () => this.props.setPopup(this.props.lastpoup)
						}}
					/>
				);
			case 'login_email':
				return (
					<FooterContainer
						link={{
							text: Translations.get('back_to_login'),
							onClick: () => this.props.setPopup(this.props.lastpoup)
						}}
					/>
				);
			default:
				return (
					<FooterContainer
						text={Translations.get('ask_havent_already_acc')}
						link={{
							text: Translations.get('register'),
							onClick: () => this.props.setPopup('register')
						}}
					/>
				);
		}
	}
}

const mapStateToProps = (state) => {
	return {
		popup: state.page.popup,
		lastpoup: state.page.lastpoup
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (x) => {
			dispatch(setPopup(x));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(container);
