import React from 'react'
import {connect} from "react-redux";
import {setPopup} from 'Actions/pageActions'

import NumberBox from './Filtrs/Numbers'
import DateBox from './Filtrs/Date'
import CheckboxBox from './Filtrs/Checkbox'
import TextBox from './Filtrs/Text'

import {FilterPopup} from 'Components/Commons/Const/Filter'

import Translations from 'Singleton/Translations'
import { Grid, Button } from '@material-ui/core';
import styles from '../../Components/style';

class container extends React.Component{
	constructor(props){
		super(props)
		this.cancelClick = this.cancelClick.bind(this);
		this.clearClick = this.clearClick.bind(this);
	}

	cancelClick(){
		this.props.setPopup(undefined);
	}

	clearClick(){
		this.props.setPopup(undefined);
		this.props.onClear();
	}

	render(){
		var c;

		switch (this.props.filtr) {
			case FilterPopup.CHECKBOX:
				c = <CheckboxBox/>
				break;
			case FilterPopup.DATE:
				c = <DateBox />
				break;
			case FilterPopup.NUMBER:
				c = <NumberBox />
				break;
			case FilterPopup.TEXT:
				c= <TextBox />
				break;
			default:

		}

		return (
			<Grid
				container
				direction='column'
			>
				<Grid item>
					{c}
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Button
						variant='outlined'
						fullWidth
						onClick={this.clearClick}
					>
						{Translations.get('clear_filter')}
					</Button>
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Button
						fullWidth
						variant='outlined'
						onClick={this.cancelClick}
					>
						{Translations.get('cancel')}
					</Button>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		filtr: state.page.filtr,
		onClear: state.repository.popupOnClear,
	};
};

const mapDispatchToProps = (dispatch) => {
	return{
		setPopup: (x) => {
			dispatch(setPopup(x));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(container);
