import React from "react";
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress'

import Rest from 'libs/RestRequest'

import { setPopup } from 'Actions/pageActions'
import { setValue } from 'Actions/repositoryActions'
import VatConst from 'Components/Commons/Const/Vat'
import { Paper, Grid, Typography, Button } from "@material-ui/core";

import Translations from 'Singleton/Translations'
import styles from 'Components/style'
import IconText from 'Components/Commons/IconText';
import MediaType from "../../../libs/MediaType";


class container extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
		}

		this.handleResponse = this.handleResponse.bind(this);
		this.refreshData = this.refreshData.bind(this);

		this.props.setRepositoryValue('invoiceRefreshData', this.refreshData)

		this.refreshData();
	}

	refreshData() {
		Rest('get',
			'/secured/vatZeroUserData',
			{},
			this.handleResponse,
			this.props.userToken,
		);
	}

	styles = {
		loadingAnim: {
			display: 'flex',
			justifyContent: 'center',
			margin: '2vw',
		}
	}

	handleResponse(res) {
		console.log("Get vat0 data", res);
		var country = this.props.countries.find(
			(element) => {
				return res.body.country === element.value;
			}
		)
		if (country) {
			res.body.country = country.name;
		}
		this.setState({
			isLoading: false,
			status: res.body.status,
			invoiceData: res.body,
		})
	}

	getContentsInvoice() {
		var data = this.state.invoiceData;
		return (
			<Grid
				container
				direction='column'
			>
				<Grid item>
					<Typography variant='body1' paragraph>
						{Translations.get('requested_vat_zero')}
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant='body1'>
						{data.company}
					</Typography>
					<Typography variant='body1'>
						{Translations.get('optional_nip_vat')}: {data.nip}
					</Typography>
					<Typography variant='body1'>
						{Translations.get('phone_number')}: {data.phone}
					</Typography>
					<Typography variant='body1'>
						{data.name} {data.surname}
					</Typography>
					<Typography variant='body1'>
						{data.street}
					</Typography>
					<Typography variant='body1'>
						{data.postcode} {data.city}
					</Typography>
					<Typography variant='body1'>
						{data.country}
					</Typography>
				</Grid>
			</Grid>
		);
	}

	getContentsNew() {
		return (
			<Grid
				container
				direction='column'
			>
				<Grid item>
					<Typography variant='body1' paragraph>
						{Translations.get('invoice_zero_info')}
					</Typography>
				</Grid>
				<Grid item>
					<Button
						variant='outlined'
						onClick={() => {
							this.props.setPopup("invoice_vat_0");
						}}
					>
						{Translations.get('request_vat_zero_percent')}
					</Button>
				</Grid>
			</Grid>
		);
	}

	getContentsDuring() {
		return (
			<Grid
				container
				direction='column'
			>
				<Grid item>
					{this.getContentsInvoice()}
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<IconText
						type='warning'
						variant='body1'
						centered
					>
						{Translations.get('request_is_under_verifying')}
					</IconText>
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Typography variant='body1'>
						{Translations.get('zero_percent_invoice_info')}
					</Typography>
				</Grid>
			</Grid>
		);
	}

	getContentsApproved() {
		return (
			<Grid
				container
				direction='column'
			>
				<Grid item>
					{this.getContentsInvoice()}
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<IconText
						type='done'
						variant='body1'
						centered
					>
						{Translations.get('accepted_request')}
					</IconText>
				</Grid>
			</Grid>
		);
	}

	getContentsRejected() {
		return (
			<Grid
				container
				direction='column'
			>
				<Grid item>
					<IconText
						type='warning'
						variant='body1'
						centered
					>
						{Translations.get('rejected_request')}
					</IconText>
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Typography variant='body1' paragraph>
						{Translations.get('request_vat_zero_info')}
					</Typography>
				</Grid>
				<Grid item>
					<Button 
						variant='outlined'
						onClick={() => {
							this.props.setPopup("invoice_vat_0");
						}}
					>
						{Translations.get('request_vat_zero')}
					</Button>
				</Grid>

			</Grid>
		);
	}

	getcontents() {
		if (this.state.isLoading) {
			return (
				<CircularProgress size={80} thickness={5} />
			)
		}
		if (this.state.status === VatConst.DURING) {
			return this.getContentsDuring();
		}
		if (this.state.status === VatConst.APPROVED) {
			return this.getContentsApproved();
		}
		if (this.state.status === VatConst.REJECTED) {
			return this.getContentsRejected();
		}

		return this.getContentsNew();
	}

	render() {
		return (
			<Paper style={
				MediaType.isMobile ?
					styles.paperBoxMobile :
					styles.paperBox
			}>
				<Grid
					container
					direction='column'
				>
					<Grid item>
						<Typography variant='title' paragraph>
							{Translations.get('invoicing')}
						</Typography>
					</Grid>
					<Grid item>
						{this.getcontents()}
					</Grid>
				</Grid>
			</Paper>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		countries: state.translations.countrys,
		userId: state.user.id,
		userToken: state.user.token,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (x) => {
			dispatch(setPopup(x));
		},
		setRepositoryValue: (x, y) => {
			dispatch(setValue(x, y));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(container);
