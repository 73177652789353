import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Paper, Grid, Typography, Divider } from '@material-ui/core'
import { withTheme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

import styles from 'Components/style'
import Translations from 'Singleton/Translations'
import MediaType from '../../../libs/MediaType';


import OrderStatus from '../../Commons/Const/OrderStatus'
import { ValuationStatus } from '../../Commons/Const/Valuation'

export class container extends Component {

	styles = {
		grey: {
			borderLeft: `solid ${this.props.theme.palette.grey[400]}`,
		},
		orange: {
			borderLeft: `solid ${this.props.theme.palette.primary.main}`,
		},
		green: {
			borderLeft: `solid ${styles.palette.green}`,
		},
		button: {
			marginRight: styles.space30.width,
		},
		divider: {
			margin: '10px 0',
		},
		textGrey: {
			color: this.props.theme.palette.grey[400]
		}
	}

	getButtons = () => {
		if (!this.props.buttons) return '';

		return this.props.buttons.map((item, i) => {
			var text = '__NO_DATA__';
			var color = 'default';
			var variant = 'outlined';
			switch (item.name) {
				case 'details':
					text = Translations.get('show_details')
					break;
				case 'invoice':
					text = Translations.get('get_invoice_pro')
					break;
				case 'cmr':
					text = Translations.get('get_cmr_documents')
					break;
				case 'cancel':
					text = Translations.get('resign')
					break;
				case 'accept':
					color = 'primary';
					variant = 'contained';
					text = Translations.get('accept');
				default:
					break;
			}

			return (
				<Grid item key={i} style={
					MediaType.isMobile ?
						{
							width: '100%',
							marginTop: '15px',
						} :
						this.styles.button
				}>
					<Button
						color={color}
						onClick={item.func}
						variant={variant}
						fullWidth={MediaType.isMobile}
					>
						{text}
					</Button>
				</Grid>
			);
		})
	}

	getTitleStyle = () => {
		switch (this.props.state) {
			case OrderStatus.CANCELED:
			case ValuationStatus.REJECTED:
			case ValuationStatus.OUTDATED:
				return {
					textDecoration: 'line-through',
					...this.styles.textGrey,
				};
			default:
				break;
		}
		return {}
	}

	getGreyStyle = () => {
		switch (this.props.state) {
			case OrderStatus.CANCELED:
			case ValuationStatus.REJECTED:
			case ValuationStatus.OUTDATED:
				return this.styles.textGrey
			default:
				break;
		}
		return {}
	}

	getContent = () => {
		return (
			<Grid
				container
				direction='column'
			>
				<Grid item>
					<Grid
						container
						alignItems='baseline'
						wrap='nowrap'
					>
						<Grid item>
							<Typography
								variant='title'
								paragraph
								style={this.getTitleStyle()}
							>
								{this.props.id}
							</Typography>
						</Grid>
						<Grid item style={styles.space30}>
						</Grid>
						<Grid item>
							<Typography variant='body1'
								style={{
									...this.getGreyStyle(),
									width: 250
								}}
							>
								{this.props.description}
							</Typography>
						</Grid>
						<Grid item style={styles.space30}>
						</Grid>
						<Grid item style={{ flexGrow: 2 }}>
						</Grid>
						<Grid item>
							<Typography
								variant='body1'
								style={{
									...this.getGreyStyle(),
									width: 135
								}}
							>
								{this.props.date}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<Grid
						container
						alignItems='baseline'
					>
						<Grid item>
							<Typography
								variant='title'
								paragraph
							>
								{this.props.status}
							</Typography>
						</Grid>
						<Grid item style={styles.space30}>
						</Grid>
						<Grid item>
							<Typography
								variant='body1'
								style={this.getGreyStyle()}
							>
								{this.props.statusDescription}
							</Typography>
						</Grid>
						<Grid item style={{ flexGrow: 2 }}>
						</Grid>

						<Grid item>
							{this.props.price ?

								<Typography
									variant='title'
									color='primary'
									style={this.getGreyStyle()}
								>
									{this.props.price.toFixed(2)}
								</Typography>
								: ''}
						</Grid>
						<Grid item>
							{this.props.price ?
								<Typography
									variant='title' color='primary'
									style={this.getGreyStyle()}
								>
									{this.props.currency}
								</Typography>
								: ''}
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<Grid
						container

					>
						{this.getButtons()}
					</Grid>
				</Grid>
			</Grid>
		)
	}

	getMobileContent = () => {
		return (
			<Grid
				container
				direction='column'
			>
				<Grid item>
					<Grid
						container
						alignItems='baseline'
						wrap='nowrap'
					>
						<Grid item>
							<Typography
								variant='title'
								paragraph
								style={this.getTitleStyle()}
							>
								{this.props.id}
							</Typography>
						</Grid>
						<Grid item style={{ flexGrow: 2 }}>
						</Grid>
						<Grid item>
							<Typography variant='body1'
								style={{
									...this.getGreyStyle(),
									width: 135
								}}
							>
								{this.props.date}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<Typography variant='body1'
						style={{
							...this.getGreyStyle(),
							width: 250
						}}>
						{this.props.description}
					</Typography>
				</Grid>
				<Grid item>
					<Divider style={this.styles.divider} />
				</Grid>
				<Grid
					container
					direction='column'
					alignItems='center'
				>
					<Grid item>
						<Typography variant='title'>
							{this.props.status}
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant='body1'
							style={this.getGreyStyle()}
						>
							{this.props.statusDescription}
						</Typography>
					</Grid>
				</Grid>
				{this.props.price ?
					<Grid item>
						<Divider style={this.styles.divider} />
					</Grid>
					: ''}
				<Grid item>
					{this.props.price ?
						<Typography variant='title' color='primary' align='center'
							style={this.getGreyStyle()}
						>
							{this.props.price.toFixed(2)} {this.props.currency}
						</Typography>
						: ''
					}
				</Grid>
				<Grid item>
					<Grid
						container

					>
						{this.getButtons()}
					</Grid>
				</Grid>
			</Grid>
		);
	}

	getMarkedStyle = () => {
		switch (this.props.state) {
			case OrderStatus.NEW:
			case OrderStatus.IN_PROGRESS:
			case OrderStatus.FORWARD:
			case OrderStatus.FORWARD_AUTO:
			case OrderStatus.SENT_TO_SUPPLIER:
			case OrderStatus.CANCELED:
			case ValuationStatus.NEW:
			case ValuationStatus.REJECTED:
			case ValuationStatus.OUTDATED:
				return {};
			// return this.styles.grey;
			case OrderStatus.TO_SUPPLY:
			case ValuationStatus.ACCEPTED:
				return this.styles.green;
			case OrderStatus.AWAIT:
			case ValuationStatus.AWAIT:
				return this.styles.orange;
			default:
				break;
		}
		return {}
	}

	render() {
		const markStyle = this.getMarkedStyle();
		const paperBoxStyle = MediaType.isMobile ? styles.paperBoxMobile : styles.paperBox;
		return (
			<Paper style={{
				...paperBoxStyle,
				...markStyle,
			}}>
				{
					MediaType.isMobile ?
						this.getMobileContent() :
						this.getContent()
				}
			</Paper>
		)
	}
}

container.propTypes = {
	id: PropTypes.string.isRequired,
	description: PropTypes.string,
	date: PropTypes.string,
	status: PropTypes.string,
	statusDescription: PropTypes.string,
	price: PropTypes.number,
	currency: PropTypes.string,
	buttons: PropTypes.arrayOf(PropTypes.shape({
		name: PropTypes.string.isRequired,
		func: PropTypes.func.isRequired,
	})),
	state: PropTypes.string.isRequired,
}

export default withTheme()(container);
