import RestRequest from "libs/RestRequest";
import { toast } from 'react-toastify';

export const calculatePrice = (props, translations) => new Promise((resolve, reject) => {
  const body = {
    uid: localStorage.getItem('uid')
  };
  const paletsCount = props.imageBlockForm ? props.imageBlockForm.paletsCount : props.paletsCount;
  body["from"] = {
    countryName: findCountryName(props.inputs.country_from, translations.countrys),
    city: props.inputs.city_from,
    country: findCountryId(props.inputs.country_from, translations.countrys),
    postcode: props.inputs.post_code_from,
    liftCar:
      props.inputs.country_from === "IT"
        ? props.inputs["lift_car"]
        : undefined,
  };
  body["to"] = {
    countryName: findCountryName(props.inputs.country_to, translations.countrys),
    city: props.inputs.city_to,
    country: findCountryId(props.inputs.country_to, translations.countrys),
    postcode: props.inputs.post_code_to,
    liftCar:
      props.inputs.country_to === "IT"
        ? props.inputs["lift_car"]
        : undefined,
  };
  body["email"] = props.userEmail;
  body["details"] = props.inputs.details;
  body["rabat"] = props.inputs.rabat;
  const palets = [];
  for (let i = 0; i < paletsCount; i++) {
    let customIndex = translations.pallets.findIndex((item) => {
      return item.isCustom;
    });
    let isCustom = props.inputs[`pallets_size_${i}`] === "custom";
    let index = isCustom ? customIndex : props.inputs[`pallets_size_${i}`];
    let paletType = translations.pallets[index];
    if (isCustom) {
      paletType = {
        ...paletType,
        length: props.inputs[`palet_length_${i}`] * 10,
        width: props.inputs[`palet_width_${i}`] * 10
      };
    }
    palets.push({
      paletType,
      height: props.inputs[`palet_height_${i}`],
      weight: props.inputs[`palet_weight_${i}`],
      fragile: props.inputs[`fragile_cargo_${i}`],
      width: props.inputs[`palet_width_${i}`],
      length: props.inputs[`palet_length_${i}`],
    });
  }
  body["sender"] = props.inputs.sender;
  body["palets"] = palets;
  const req = RestRequest("post", "getPrice", body, (res) => {
    return res.body.error ? reject() : resolve(res);
  }, undefined, undefined, (err) => {
      toast.error(`Niewłaściwe parametry wyceny, możliwe przekroczenie wagi.\
        Zamów wycenę ręczną`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      reject(err);
  });

  if (props.cancel && props.cancel.on) {
    props.cancel.on('cancel', e => req.abort())
  }
});

const findCountryId = (iso, countries) => {
  for (var country of countries) {
    if (country.value === iso) {
      return country.id;
    }
  }
}

const findCountryName = (iso, countries) => {
  for (var country of countries) {
    if (country.value === iso) {
      return country.name;
    }
  }
}
