import React from 'react'
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import TextField from '@material-ui/core/TextField';

import { setInputValue, setInputValid } from "Actions/inputActions";
import { TextInputType } from 'Components/Commons/Const/InputTypes'
import InputAdornment from '@material-ui/core/InputAdornment';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import { Tooltip } from '@material-ui/core';
import Jsvat from '../../libs/jsvat';

import Translations from '../../Singleton/Translations'

const mailRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
const euVatRegex = new RegExp(/^[A-Za-z]{2,4}(?=.{2,12}$)[0-9]*(?:[a-zA-Z][0-9]*){0,2}$/);
// const noSpecialCharacters = new RegExp(/^[_A-z0-9e]*((-|\s)*[ _A-z0-9])*$/)
// const noSpecialCharacters = new RegExp(/^[_A-zżźćńółęąśŻŹĆĄŚĘŁÓŃ0-9]*((-|\s|)*[ _A-zżźćńółęąśŻŹĆĄŚĘŁÓŃ0-9])*$/)

// allow sign 0-9 a-z A-Z . / \ , " "
const noSpecialCharacters = new RegExp(/^[A-zżźćńółęąśŻŹĆĄŚĘŁÓŃ0-9_.,\\/ ]+$/)

const PasswordAdornment = (props) => {
	let { showPassword, onClick, disabled } = props;
	return (
		<InputAdornment position="end">
			<IconButton
				aria-label="Toggle password visibility"
				onClick={onClick}
				disabled={disabled}
			>
				{showPassword ? <VisibilityOff /> : <Visibility />}
			</IconButton>
		</InputAdornment>
	);
}


class component extends React.Component {
	constructor(params) {
		super(params);

		this.state = {
			value: params.value ? params.value : '',
			isValid: true,
			firstChange: true,
			showPassword: false,
		}

		this.handleChange = this.handleChange.bind(this);
		if (this.props.getChangeValueFunc) {
			this.props.getChangeValueFunc(this.changeValue)
		}
		if (this.props.getCheckValidFunc) {
			this.props.getCheckValidFunc(this.checkValid)
		}
	}

	checkValid = () => {
		setTimeout(
			this.onValidChange(this.state.value),
			1
		);
	}

	changeValue = (value) => {
		console.log(value)
		if (this.props.id) {
			this.props.setInputValue(this.props.id, value)
		}

		if (this.props.onChange) {
			this.props.onChange(value);
		}

		this.setState({
			...this.state,
			value: value,
		}, this.onValidChange(value))
	}

	componentDidMount() {
		if (this.state.value) {
			this.changeValue(this.state.value);
		} else {
			this.props.setInputValue(this.props.id, this.state.value)
			this.props.setInputValid(this.props.id, this.state.isValid)
		}
	}

	isNumber(n) {
		return !isNaN(parseFloat(n)) && isFinite(n);
	}

	handleChange(event) {
		var value = event.target.value;

		if (this.props.type !== TextInputType.PASSWORD) {

		}

		if (!this.props.unlimited) {
			let limit = this.props.limit;
			if (this.props.type === TextInputType.EMAIL ||
				this.props.type === TextInputType.PASSWORD
			) {
				limit = 254
			}
			if (value.length > limit) return;
		}

		if (this.props.type === TextInputType.NUMBER) {
			if ((/\D/).exec(value) !== null) return;
		}

		if (this.props.type === TextInputType.FLOAT) {
			if (!this.isNumber(value)) return;
		}

		if (this.props.type === TextInputType.EMAIL) {
			value = value.toLowerCase();
		}

		if (this.props.type === TextInputType.NIP) {
			value = value.replace(/[-–_. ]/g, "");
		}

		if (this.props.type === TextInputType.FLOAT) {
			this.props.setInputValue(this.props.id, parseFloat(value))
		}
		else {
			this.props.setInputValue(this.props.id, value)
		}

		this.setState({
			value: value,
		}, this.onValidChange(value))

		if (this.props.onChange) {
			this.props.onChange(value);
		}
	}

	validateEUVat = (country, euVat) => {
		return Jsvat[country + 'VATCheckDigit'](euVat);
	}

	onValidChange = value => () => {
		switch (this.props.type) {
			case TextInputType.EMAIL:
				this.setValid(mailRegex.test(String(value).toLowerCase()) && value !== '');
				break;
			case TextInputType.PASSWORD:
			case TextInputType.NOT_EMPTY:

			case TextInputType.NUMBER:
				if (this.props.isNotZero) {
					let num = parseInt(value);
					if (num <= 0) {
						this.setValid(false);
						break;
					}
				}
				if (this.props.noSpecialCharacters) {
					if (value.length === 0 || !value.trim()) {
						this.setValid(false)
						break;
					}
					this.setValid(noSpecialCharacters.test(value))
					break;
				}
				this.setValid(!(value.length === 0 || !value.trim()));
				break;

			case TextInputType.FLOAT:
				console.log(!(value.length === 0 || !parseFloat(value)))
				this.setValid(!(value.length === 0 || !parseFloat(value)));
				break;

			default:
				if (this.props.noSpecialCharacters) {
					if (value.length === 0 || !value.trim()) {
						this.setValid(false)
						break;
					}
					this.setValid(noSpecialCharacters.test(value))
					break;
				}
				break;
		}
	}

	setValid(value) {
		if (this.state.firstChange || this.state.isValid !== value) {
			this.setState({
				...this.state,
				isValid: value
			});
			if (this.props.onValidChange) {
				this.props.onValidChange(value);
			}
			this.props.setInputValid(this.props.id, value)
		}
	}

	isError() {
		if (!this.props.isError && !this.props.showErrorOnNotValid) {
			return !this.state.isValid
		}
		return this.props.isError;
	}

	getLabel = () => {
		let label = this.props.label
		if (this.props.optional) {
			label = `${label} (${Translations.get('optional')})`
		}
		return label
	}

	textInputPure = () => {
		let inputType = this.props.type === TextInputType.PASSWORD ? TextInputType.PASSWORD : undefined;
		if (this.props.type === TextInputType.PASSWORD && this.state.showPassword) {
			inputType = undefined;
		}

		return (
			<TextField
				fullWidth
				required={this.props.required}
				id={this.props.id}
				label={this.getLabel()}
				value={this.state.value}
				onChange={this.handleChange}

				placeholder={this.props.placeholder}
				type={inputType}
				style={{
					width: this.props.width,
					...this.props.style,
				}}
				error={this.isError()}
				helperText={!this.isError() ? undefined : this.props.errorReason}
				disabled={this.props.disabled}
				multiline={this.props.multiline}
				InputProps={{
					//this.props.endAdornment,
					endAdornment: this.props.type === TextInputType.PASSWORD ?
						<PasswordAdornment
							showPassword={this.state.showPassword}
							onClick={() => {
								this.setState({
									...this.state,
									showPassword: !this.state.showPassword,
								})
							}}
							disabled={this.props.disabled}
						/>
						: undefined
				}}
			/>
		);
	}

	render() {
		if (this.props.tooltip) {
			return (
				<Tooltip
					enterTouchDelay={0}
					title={this.props.tooltip}
				>
					{this.textInputPure()}
				</Tooltip>
			)
		}
		return this.textInputPure();
	}
}

component.propTypes = {
	style: PropTypes.object,
	id: PropTypes.string.isRequired,
	label: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object
	]),
	value: PropTypes.string,
	placeholder: PropTypes.string,
	width: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	required: PropTypes.bool,
	onChange: PropTypes.func,
	limit: PropTypes.number,
	unlimited: PropTypes.bool,
	type: PropTypes.oneOf(TextInputType.getAll()),
	onValidChange: PropTypes.func,
	errorReason: PropTypes.string,
	fullWidth: PropTypes.bool,
	getChangeValueFunc: PropTypes.func,
	getCheckValidFunc: PropTypes.func,
	isError: PropTypes.bool,
	isNotZero: PropTypes.bool,
	nipCountry: PropTypes.string,
	disabled: PropTypes.bool,
	tooltip: PropTypes.string,
	noSpecialCharacters: PropTypes.bool,
	showErrorOnNotValid: PropTypes.bool,
	optional: PropTypes.bool,
}

component.defaultProps = {
	style: {},
	limit: 30,
	unlimited: false,
	type: TextInputType.DEFAULT,
	isError: false,
	disabled: false,
	noSpecialCharacters: false,
	optional: false,
}


const mapStateToProps = (state) => {
	return {

	};
};

const mapDispatchToProps = (dispath) => {
	return {
		setInputValue: (id, value) => {
			dispath(setInputValue(id, value));
		},
		setInputValid: (id, value) => { dispath(setInputValid(id, value)) }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
