import React from "react";
import { connect } from "react-redux";

import { setPopup } from 'Actions/pageActions';
import { setValue } from 'Actions/repositoryActions'

import Translations from 'Singleton/Translations'
import { Paper, Button, Grid, Typography } from "@material-ui/core";
import styles from 'Components/style'
import MediaType from "../../../libs/MediaType";


class container extends React.Component {
	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);

		this.props.setRepositoryValue('userId', this.props.userId);
	}

	onClick() {
		this.props.setRepositoryValue('logout', true);
		this.props.setPopup('delete_account');
	}


	render() {
		return (
			<Paper style={
				MediaType.isMobile ?
					styles.paperBoxMobile :
					styles.paperBox
			}>
				<Grid
					container
					direction='column'
				>
					<Grid item>
						<Typography variant='title' paragraph>
							{Translations.get('delete_account')}
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant='body1' paragraph>
							{Translations.get('delete_account_warning')}
						</Typography>
					</Grid>
					<Grid item>
						<Button
							variant='outlined'
							onClick={this.onClick}
							fullWidth={MediaType.isMobile}
						>
							{Translations.get('delete_account_from_treo')}
						</Button>
					</Grid>
				</Grid>
			</Paper>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userId: state.user.id,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (x) => {
			dispatch(setPopup(x));
		},
		setRepositoryValue: (x, y) => {
			dispatch(setValue(x, y));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(container);
