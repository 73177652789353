/* eslint-disable react/no-multi-comp */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';

const styles = {
  
};

class SimpleDialog extends React.Component {
  handleCancel = () => {
    this.props.onClose(false);
  };
  
  handleAgree = () => {
    this.props.onClose(true);
  };

  handleListItemClick = value => {
    this.props.onClose(value);
  };

  getCancelButton(hide) {
    console.log('getCancelBtn', this)
    if (!!hide) {
      return ('')
    }
    return (
      <Button onClick={this.handleCancel} color="default">
        Anuluj
      </Button>
    )
  }

  render() {
    const { classes, onClose, title, text, hideCancel, ...other } = this.props;
    return (
      <Dialog aria-labelledby="simple-dialog-title" {...other}>
        <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {this.getCancelButton(hideCancel)}
          <Button onClick={this.handleAgree} color="primary" autoFocus>
            Kontynuuj
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

SimpleDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  hideCancel: PropTypes.bool
};

const ConfirmationDialog = withStyles(styles)(SimpleDialog);

export default ConfirmationDialog;