import Translations from 'Singleton/Translations'

const renameCountries = (countries, translations) => {
	return countries.map((item)=>{
		const val = item.value.toLowerCase();
		const key = `country_${val}`;
		return {
			...item,
			name: translations[key],
		}
	}).sort( (a,b) => {
		return a.name.localeCompare(b.name);
	})
}

const translationReducer = (state ={
	lang: "xx",
	translations: {}
}, action) => {
	switch (action.type) {
		case "TRANSLATION_SET_LANG":
		state = {
			...state,
			lang: action.lang,
			translations: action.translations
		};
		break;
		case "DATA_SET_COUNTRYS_PALLETS_LANGUAGE":
		state = {
			...state,
			lang: action.lang,
			translations: action.translations,
			countrys: renameCountries(action.countrys,action.translations),
			pallets: action.pallets,
			maxOrderWeight: action.maxOrderWeight,
			exchangeCurrencyBid: action.exchangeCurrencyBid,
			exchangeCurrencyAsk: action.exchangeCurrencyAsk,
			error: action.error
		}
		break;
		case "SET_DATA":
		state = {
			...state,
			[action.id]: action.value
		};
		break;
		default:
		break;
	}
	Translations.refresh(state.translations);
	return state;
};

export default translationReducer;
