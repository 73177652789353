import React from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import RestRequest from 'libs/RestRequest'

import { setPopup } from 'Actions/pageActions'
import Translation from 'Singleton/Translations'
import { Grid, Typography, Button } from '@material-ui/core'
import styles from 'Components/style'
import Checkbox from '../../Components/Commons/Checkbox'
import TextInput from '../../Components/Commons/TextInput'


class CHECKBOX {
	static get TOO_EXPENSIVE() { return 'too_expensive'; }
	static get WAITING_TIME() { return 'waiting_time'; }
	static get TRANSPORT_TIME() { return 'transport_time'; }
}

class container extends React.Component {
	constructor(props) {
		super(props);
		this.showError = this.showError.bind(this);
		this.handleResponse = this.handleResponse.bind(this);
		this.cancelClick = this.cancelClick.bind(this);
		this.acceptClick = this.acceptClick.bind(this);
	}

	showError(msg) {
		toast.error(msg, {
			position: toast.POSITION.TOP_RIGHT,
		});
	}

	showSuccess(msg) {
		toast.success(msg, {
			position: toast.POSITION.TOP_RIGHT
		})
	}

	cancelClick() {
		this.props.setPopup(undefined);
	}

	acceptClick = () => {
		var reasons = [];
		if (this.props.inputs[CHECKBOX.TOO_EXPENSIVE]) {
			reasons.push(CHECKBOX.TOO_EXPENSIVE);
		}
		if (this.props.inputs[CHECKBOX.WAITING_TIME]) {
			reasons.push(CHECKBOX.WAITING_TIME);
		}
		if (this.props.inputs[CHECKBOX.TRANSPORT_TIME]) {
			reasons.push(CHECKBOX.TRANSPORT_TIME);
		}
		var body = {
			number: this.props.number,
			reasons,
		}
		if (this.props.inputs.remarks) {
			body = {
				...body,
				description: this.props.inputs.remarks,
			}
		}
		RestRequest(
			'post',
			'secured/valuation/reject',
			body,
			this.handleResponse,
			this.props.userToken
		)
	}

	handleResponse(res) {
		console.log('reject valuation ', res);
		if (res.body.error) {
			this.showError(res.body.errorReason)
		} else {
			this.showSuccess(Translation.get('reject_valuation'))
			if(this.props.acceptFunc){
				this.props.acceptFunc();
			}
			this.props.setPopup();
		}
	}

	render() {
		return (
			<Grid
				container
				direction='column'
			>
				<Grid item>
					<Checkbox
						id={CHECKBOX.TOO_EXPENSIVE}
						label={Translation.get(CHECKBOX.TOO_EXPENSIVE)}
					/>
				</Grid>
				<Grid item>
					<Checkbox
						id={CHECKBOX.WAITING_TIME}
						label={Translation.get(CHECKBOX.WAITING_TIME)}
					/>
				</Grid>
				<Grid item>
					<Checkbox
						id={CHECKBOX.TRANSPORT_TIME}
						label={Translation.get(CHECKBOX.TRANSPORT_TIME)}
					/>
				</Grid>
				<Grid item>
					<TextInput
						fullWidth
						id={'remarks'}
						limit={245}
						label={Translation.get('additional_remarks')}
					/>
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Button
						fullWidth
						variant='contained'
						onClick={this.acceptClick}
						color='primary'
					>
						{Translation.get('confirm_resignation')}
					</Button>
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Button
						fullWidth
						variant='outlined'
						onClick={this.cancelClick}
					>
						{Translation.get('cancel')}
					</Button>
				</Grid>
			</Grid>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		userToken: state.user.token,
		inputs: state.input.inputState,
		number: state.repository.number,
		acceptFunc: state.page.popupFunc,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (x) => {
			dispatch(setPopup(x));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(container);
