import React from 'react';
import {connect} from "react-redux";
import {setPopup} from 'Actions/pageActions'

import CheckBox from 'Components/Commons/Checkbox'
import { Grid, Button } from '@material-ui/core'

import Translations from 'Singleton/Translations'
import styles from '../../../Components/style';

class container extends React.Component{
	constructor(props){
		super(props);
		const filter = this.getFilter()
		this.state={
			value: filter ? filter.payload : ''
		}
	}

	getFilter = () => {
		const { filters, filtersKey } = this.props;
		let filter = undefined
		
		if (filtersKey) {
			filter = filters.find((item) => {
				if (!item) {
					return false
				}
				if (item.name.length !== filtersKey.length) {
					return false
				}
				for (let i = 0; i < item.name.length; i++) {
					if (item.name[i] !== filtersKey[i]) {
						return false
					}
				}
				return true
			})
		}
		return filter;
	}
	
	getItems = () => {
		return this.props.array.map((item,i) =>{
			const isChecked = this.state.value.includes(item)
			return(
				<Grid item key={i}> 
					<CheckBox 
						label={this.props.translation(item)} 
						id={item} 
						defaultChecked={isChecked}
					/>
				</Grid>
			);
		});
	}

	onAccept = () => {
		const arr = this.props.array.filter((item) => {
			return this.props.inputs[item];
		})
		if(arr.length > 0){
			this.props.onAcceptFunc(arr);
		}else{
			this.props.onClear();
		}
		this.props.setPopup(undefined);
	}

	render(){
		return(
			<Grid
				container
				direction='column'
			>
				{this.getItems()}
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Button
						variant='contained'
						color='primary'
						onClick={this.onAccept}
						fullWidth
					>
						{Translations.get('update_filter')}
					</Button>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		array: state.repository.checkboxFilterArray,
		translation: state.repository.checkboxFilterTranslation,
		onAcceptFunc: state.repository.popupOnAccept,
		onClear: state.repository.popupOnClear,
		inputs: state.input.inputState,
		filters: state.repository.filters,
		filtersKey: state.repository.filtersKey
	};
};

const mapDispatchToProps = (dispatch) => {
	return{
		setPopup: (x) => {
			dispatch(setPopup(x));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(container);
