import React from 'react'
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import RestRequest from 'libs/RestRequest'

import Translations from 'Singleton/Translations'
import { Paper, Grid, Button, Typography } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import styles from '../../../style';
import OrderStatus from 'Components/Commons/Const/OrderStatus'

class container extends React.Component {
	constructor(props) {
		super(props);

		this.getEmptyBox = this.getEmptyBox.bind(this);
		this.getFieldBox = this.getFieldBox.bind(this);
		this.onAddChange = this.onAddChange.bind(this);
		this.add = this.add.bind(this);
		this.handleResponse = this.handleResponse.bind(this);
		this.getListItem = this.getListItem.bind(this);
		this.showError = this.showError.bind(this);
		this.onClick = this.onClick.bind(this);
	}

	styles = {
		list: {
			width: '100%',
			maxHeight: '60px',
			overflow: 'auto'
		},
		listItem: {
			paddingTop: 0,
			paddingBottom: 0,
		}
	}

	add() {
		document.getElementById('cmrFile').click();
	}

	onAddChange(e) {
		console.log({
			fileName: e.target.files[0].name,
			file: e.target.files[0],
			number: this.props.number,
		});
		RestRequest(
			'file',
			'secured/order/file',
			{
				fileName: e.target.files[0].name,
				number: this.props.number,
			},
			this.handleResponse,
			this.props.userToken,
			e.target.files[0]
		)
	}

	showError(msg) {
		toast.error(msg, {
			position: toast.POSITION.TOP_RIGHT,
		});
	}

	handleResponse(res) {
		console.log('cmr', res);
		if (res.body.error) {
			this.showError(res.body.errorReason);
		} else {
			this.props.refreshFunc();
		}
	}

	getEmptyBox() {
		return (
			<div style={this.styles.linkStyle} >
				<Typography variant='body1'>
					{Translations.get('lack_of_cmr_document')}
				</Typography>
			</div>
		);
	}

	onDeleteClick(id) {
		console.log('delete click');
		RestRequest(
			'delete',
			'secured/order/file',
			{
				documentId: id,
			},
			this.handleResponse,
			this.props.userToken
		)
	}

	onClick(id) {
		console.log('download click');
		RestRequest(
			'get',
			'secured/order/file',
			{
				documentId: id,
			},
			this.handleFileResponse,
			this.props.userToken
		)
	}

	handleFileResponse(res) {
		var url = "data:" + res.body.fileMime + ";base64," + res.body.fileData;
		var anchor = document.createElement("a");
		anchor.href = url;
		anchor.setAttribute("download", res.body.fileName);
		anchor.target = "_blank"
		document.body.appendChild(anchor);
		anchor.click();
		anchor.remove();
	}

	getListItem() {
		return this.props.documents.map((item, i) => {
			return (
				<ListItem
					button
					style={this.styles.listItem}
					key={i}
				>
					<ListItemText
						primary={item.fileName}
						onClick={() => { this.onClick(item.fileId); }}
					/>
					<IconButton
						onClick={() => { this.onDeleteClick(item.fileId); }}
					>
						<DeleteIcon />
					</IconButton>
				</ListItem>
			);
		});
	}

	getFieldBox() {
		return (
			<List style={this.styles.list}>
				{this.getListItem()}
			</List>
		);
	}

	updateDocuments = () => {
		RestRequest('post',
			'secured/order/transport/document',
			{ number: this.props.number },
			this.handleUpdateDocumentsResponse,
			this.props.userToken
		);
	}

	handleUpdateDocumentsResponse = (res) => {
		console.log('update document', res)
		if (!res.body.error) {
			toast.success(Translations.get('request_to_update_cmr_uploaded'), { position: toast.POSITION.TOP_RIGHT });
		} else {
			toast.error(Translations.get('something_went_wrong'), { position: toast.POSITION.TOP_RIGHT });
		}
	}

	isUpdateButton = () => {
		console.log(this.props.status);
		
		if (
			this.props.status === OrderStatus.FORWARD ||
			this.props.status === OrderStatus.SENT_TO_SUPPLIER ||
			this.props.status === OrderStatus.TO_SUPPLY
			){
				return true;
			}
		return false;
	}


	getContent() {
		var box;
		if (this.props.documents && this.props.documents.length > 0) {
			box = this.getFieldBox();
		} else {
			box = this.getEmptyBox();
		}

		return (
			<Grid
				container
				direction='column'
				justify='space-between'
				alignItems='center'
				style={styles.infoBox}
			>
				<Grid item style={{ width: '100%' }}>
					{box}
				</Grid>
				<Grid item>
					<Grid
						container
					>
						{
							this.isUpdateButton() &&
							<Grid item>
								<Button
									variant='outlined'
									onClick={this.updateDocuments}
								>
									{Translations.get('update')}
								</Button>
							</Grid>
						}
						{
							this.isUpdateButton() &&
							<Grid item style={
								styles.space30
							}></Grid>
						}

						<Grid item>
							<Button
								variant='outlined'
								onClick={this.add}
							>
								{Translations.get('insert_cmr')}
							</Button>
							<input type="file"
								id="cmrFile"
								style={{ display: 'none' }}
								onChange={this.onAddChange}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		);
	}

	render() {
		return (
			<Paper>
				{this.getContent()}
			</Paper>
		);
	}
}

container.propTypes = {
	className: PropTypes.string,
	documents: PropTypes.array.isRequired,
	number: PropTypes.string.isRequired,
	refreshFunc: PropTypes.func.isRequired,
	status: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => {
	return {
		userToken: state.user.token,
	};
}

const mapDispatchToProps = (dispatch) => {
	return {

	};
}

export default connect(mapStateToProps, mapDispatchToProps)(container);
