import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { TextField } from '@material-ui/core';
import { setInputValue } from 'Actions/inputActions'
import Translations from 'Singleton/Translations'
import { timeFormatTimeUTC } from 'libs/dateformat'

const oneHour = 3600000;
const oneMinute = 60000;

export class component extends Component {
	
	constructor(props){
		super(props);
		this.state={
			isValid: false,
			isFirstChange: true,
		}
	}
	
	onChange = (event) => {
		var d = event.target.value;

		d=d.split(':');
		const time = parseInt(d[0],10) * oneHour + parseInt(d[1]) * oneMinute;

		if(this.state.isFirstChange){
			this.setState({
				...this.state,
				isFirstChange: false,
			},()=>{this.validChange(time)});
		}else{
			this.validChange(time);
		}

		this.props.setInputValue(this.props.id, time);
		if (this.props.onChange) {
			this.props.onChange(time);
		}
	}

	validChange = (value) => {
		if( value >= this.props.minTime &&
			value <= this.props.maxTime ) {
				var isValid = true;
			}
		else{
			isValid = false;
		}

		if(this.state.isValid === isValid){
			return;
		}

		this.setState({
			...this.state,
			isValid,
		})
		if(this.props.onValidChange){
			this.props.onValidChange(isValid);
		}
	}

	parseTime = (ms) => {
		const date = new Date(ms);
		if(!ms) return null;
		var min = date.getUTCMinutes();
		if (min < 10) min = `0${min}`;
		console.log('tim',`${date.getUTCHours()}:${min}`);
		
		return `${date.getUTCHours()}:${min}`;
	}

	isError = () => {
		if(this.props.doNotValidate){
			return false;
		}
		if( this.state.isValid || this.state.isFirstChange ){
			return false;
		}
		return true;
	}

	acceptableHoursTranslation = () => {
		if (this.isError()) {
			const d1 = new Date(this.props.minTime);
			const h1 = d1.getUTCHours();
			let m1 = d1.getUTCMinutes();
			if (m1 < 10 ) m1 = `0${m1}`;

			const d2 = new Date(this.props.maxTime);
			const h2 = d2.getUTCHours();
			let m2 = d2.getUTCMinutes();
			if (m2 < 10 ) m2 = `0${m2}`;

			return `${Translations.get('acceptable_hours')} ${h1}:${m1} - ${h2}:${m2}`;
		}
		return undefined
	}

	render() {
		return (
			<TextField
				id={this.props.id}
				label={this.props.label}
				type="time"
				defaultValue={timeFormatTimeUTC(this.props.defaultDate)}
				InputLabelProps={{
					shrink: true,
				}}
				margin="dense"
				onChange={this.onChange}
				style={{
					width: this.props.width
				}}
				
				error={this.isError()}
				helperText={ this.acceptableHoursTranslation() }
			/>
		)
	}
}

component.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string,
	onChange: PropTypes.func,
	width: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	minTime: PropTypes.number,
	maxTime: PropTypes.number,
	defaultDate: PropTypes.number,
	onValidChange: PropTypes.func,
	errorText: PropTypes.string,
	doNotValidate: PropTypes.bool,
}

component.defaultProps ={
	minTime: 36000000, // 10:00
	maxTime: 57600000, // 16:00
	width: 75,
	// defaultDate: 36000000,
	errorText: 'error'
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispath) => {
	return {
		setInputValue: (id, value) => {
			dispath(setInputValue(id, value));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(component)
