import React from 'react'
import { sessionService } from 'redux-react-session';
import { toast } from 'react-toastify';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'
import { Base64 } from 'js-base64';

import Rest from 'libs/RestRequest'
import Dropdown from 'Components/Commons/Dropdown'
import Bar from 'Components/Body/Admin/OrdersBar'
import ColumnHeader from 'Components/Body/Admin/ColumnHeader'
import { setPopup } from 'Actions/pageActions'
import { setLogin } from 'Actions/userActions'
import { redirrect } from 'Actions/pageActions';
import { ERROR } from 'libs/RestRequest'
import EmployeeColumn from 'Components/Commons/Const/EmployeeColumn'

import { Grid, Typography } from '@material-ui/core'
import { Paper } from '@material-ui/core'
import styles from "Components/style";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress'

import { setValue } from 'Actions/repositoryActions'
import DropDownIcon from '@material-ui/icons/ArrowDropDown'

import Translations from 'Singleton/Translations'


class container extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hoveredElement: -1,
			hoveredColumn: -1,
			clickedButton: false,
			tableData: [],
			isLoading: true,
			currentPage: 0,
			pageItemLimit: 10,
			lenghtData: 140,
			orderColumn: EmployeeColumn.EMAIL,
			orderDesc: true,
			filters: [],
		};
		this.handleResponse = this.handleResponse.bind(this);
		this.deleteEmploee = this.deleteEmploee.bind(this);
		this.setStatus = this.setStatus.bind(this);
		this.showError = this.showError.bind(this);
		this.handleErr = this.handleErr.bind(this);
		this.logout = this.logout.bind(this);
		this.getRole = this.getRole.bind(this);
		this.refreshData = this.refreshData.bind(this);
		this.onNextButtonClick = this.onNextButtonClick.bind(this);
		this.onPreviousButtonClick = this.onPreviousButtonClick.bind(this);
		this.setFilter = this.setFilter.bind(this);
		this.deleteFilter = this.deleteFilter.bind(this);

		this.props.setRepositoryValue('refreshDataFunc', this.refreshData);
	
		this.checkIsAdmin()
	}

	checkIsAdmin = () => {
		if(!this.props.isAdmin){
			this.props.redirrect('/panel', this.props.history);
		}
	}

	refreshData() {
		Rest('post',
			'secured/employees',
			{
				orderBy: this.state.orderColumn,
				limit: this.state.pageItemLimit,
				offset: this.state.currentPage * this.state.pageItemLimit,
				desc: this.state.orderDesc,
				filters: this.state.filters
			},
			this.handleResponse,
			this.props.userToken
		);
	}

	styles = {
		loadingAnimBG: {
			width: "100%",
			position: "absolute",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			background: "rgba(100,100,100,0.5)",
			display: "block",
			textAlign: 'center',
			paddingTop: '40px',
		},
		lastCell: {
			position: 'relative',
		},
		actionButton: {
			position: 'absolute',
			top: '5px',
			right: '5px',
			backgroundColor: '#fff'
		},
	}

	logout() {
		sessionService.deleteSession().then(
			sessionService.deleteUser().then(() => {
				this.props.setLogin(null, null, null, null, null);
				this.props.redirrect('/panel', this.props.history);
			})
		);
	}

	showError(msg) {
		toast.error(msg, {
			position: toast.POSITION.TOP_RIGHT,
		});
	}

	handleErr(body) {
		switch (body.errorCode) {
			case ERROR.USER_TOKEN_IS_NOT_VALID:
				this.showError(Translations.get('session_expired'))
				this.logout();
				break;
			default:
				this.showError(body.errorReason)
		}
	}

	handleResponse(res) {
		console.log('Get employess', res);
		if (res.body.error) {
			this.handleErr(res.body);
			return;
		}
		this.setState({
			...this.state,
			isLoading: false,
			tableData: res.body.list,
			lenghtData: res.body.rowsCount,
		})
	}

	onRowEnter(index) {
		this.setState({
			...this.state,
			hoveredElement: index
		})
	}

	onRowLeave(index) {
		if (this.state.clickedButton) {
			return;
		}
		this.setState({
			...this.state,
			hoveredElement: -1
		})
	}

	onClickDropdown() {
		this.setState({
			...this.state,
			clickedButton: true
		})
	}

	onCloseDropdown() {
		this.setState({
			...this.state,
			clickedButton: false,
			hoveredElement: -1
		})
	}

	deleteEmploee(id) {
		Rest(
			'delete',
			'secured/user',
			{ userId: id },
			this.handleCommonResponse,
			this.props.userToken
		)
	}

	handleCommonResponse = (res) => {
		console.log("Common res:", res);
		if (res.body.error) {
			this.handleErr(res.body);
		} else {
			this.refreshData();
		}
	}

	setStatus(status, id) {
		console.log("Set status ", status === 'active');
		Rest(
			'post',
			'secured/employee/status',
			{
				employeeId: id,
				isActive: status === 'active',
			},
			this.handleCommonResponse,
			this.props.userToken
		)
	}

	hashNumber(number) {
		return Base64.encodeURI(number);
	}

	getButtonsMenu(isActive, userId) {
		if (isActive) {
			return [{
				label: Translations.get('inactive'),
				onClick: () => { this.setStatus("inactive", userId); },
			}, {
				label: Translations.get('remove'),
				onClick: () => { this.deleteEmploee(userId); },
			}];
		}
		else {
			return [{
				label: Translations.get('active'),
				onClick: () => { this.setStatus("active", userId); },
			}, {
				label: Translations.get('remove'),
				onClick: () => { this.deleteEmploee(userId) },
			}];
		}
	}

	getState(isActive) {
		if (isActive) {
			return Translations.get('active');
		} else {
			return Translations.get('idle')
		}
	}


	getRole(role) {
		switch (role) {
			case 'admin': return Translations.get('admin');
			case 'manager': return Translations.get('manager');
			case 'employee': return Translations.get('employee');
			default: return '__NO_DATA__';
		}
	}

	onNextButtonClick() {
		var nextPage = this.state.currentPage + 1;
		this.setState({
			...this.state,
			isLoading: true,
			currentPage: nextPage,
		});
	}

	onPreviousButtonClick() {
		var nextPage = this.state.currentPage - 1;
		this.setState({
			...this.state,
			isLoading: true,
			currentPage: nextPage,
		});
	}

	setFilter(name, type, payload) {
		var filters = this.state.filters;
		var index = filters.findIndex(
			(obj) => { return (obj.name.equals(name) && obj.type === type); }
		);

		if (index >= 0) {
			var item = filters[index];
			filters.splice(index, 1);
			item.payload = payload;
		} else {
			item = {
				name: name,
				type: type,
				payload: payload,
			}
		}
		filters.push(item);
		this.setState({
			...this.state,
			isLoading: true,
			filters: filters,
			currentPage: 0,
		})
		console.log("Set Filter", this.state);
	}

	deleteFilter(name, type = false) {
		var filters = this.state.filters;
		var index = filters.findIndex(
			(obj) => {
				var isEq = obj.name.equals(name);
				if (type !== false) {
					isEq = (obj.type === type) && isEq;
				}
				return isEq;
			}
		);
		if (index < 0) {
			return;
		}
		filters.splice(index, 1);
		this.setState({
			...this.state,
			isLoading: true,
			filters: filters,
			currentPage: 0,
		})
	}

	getLoading() {
		if (this.state.isLoading) {
			return (
				<div style={this.styles.loadingAnimBG}>
					<CircularProgress size={80} thickness={5} />
				</div>
			);
		}
		return '';
	}

	setOrderColumn(columnName) {
		var desc = this.state.orderDesc;
		console.log("columnName: ", columnName);
		if (columnName === this.state.orderColumn) {
			desc = !desc;
		} else {
			desc = false;
		}
		this.setState({
			...this.state,
			isLoading: true,
			orderColumn: columnName,
			orderDesc: desc
		})
	}

	getDropdownMenu(isActive, userId, i) {
		if (i !== this.state.hoveredElement) {
			return '';
		}
		const label = (
			<Typography className='table-link'>
				{Translations.get('actions')}
				<DropDownIcon />
			</Typography>
		)
		return (
			<Dropdown
				label={label}
				onClick={() => { this.onClickDropdown() }}
				onClose={() => { this.onCloseDropdown() }}
				rows={this.getButtonsMenu(isActive, userId)}
				style={this.styles.actionButton}
				variant='outlined'
			/>
		);
	}

	handleRowClick = number => (event) => {
		if(event.target.nodeName === 'svg'
		|| event.target.nodeName === 'path'){
			return;
		}
		const classTable = event.target.className.split(' ')
		if(classTable.includes('table-link')
		|| event.target.nodeName === 'BUTTON'
		|| event.target.nodeName === 'SPAN'
		|| event.target.nodeName === 'UL'
		|| event.target.nodeName === 'LI'
		|| event.target.nodeName === 'DIV'
		){
			return;
		}
		const linkUri = `/panel/employee_details/${this.hashNumber(number)}`;
		this.props.redirrect(linkUri, this.props.history)
	}

	createRows(data) {
		return data.map((item, i) => {

			var linkText = `${item.name} ${item.surname}`
			return (
				<TableRow
					key={i}
					onMouseEnter={() => { this.onRowEnter(i) }}
					onMouseLeave={() => { this.onRowLeave(i) }}
					onClick={this.handleRowClick(item.userId)}
				>
					<TableCell style={styles.tableColumn4}>
						{linkText}
					</TableCell>
					<TableCell style={styles.tableColumn4}>
						{item.email}
					</TableCell>
					<TableCell style={styles.tableColumn2}>
						{this.getRole(item.role)}
					</TableCell>
					<TableCell style={{ ...styles.tableColumn2, ...this.styles.lastCell }}>
						{this.getState(item.isActive)}
						{this.getDropdownMenu(item.isActive, item.userId, i)}
					</TableCell>
				</TableRow>
			)
		});
	}

	getHeaders() {
		return (
			<TableRow>
				<ColumnHeader
					style={styles.tableColumn4}
					label={Translations.get('full_name')}
					visible={this.state.orderColumn === EmployeeColumn.NAME}
					sort={() => {
						this.setOrderColumn(
							EmployeeColumn.NAME
						)
					}}
				/>
				<ColumnHeader
					style={styles.tableColumn4}
					label={Translations.get('email')}
					visible={this.state.orderColumn === EmployeeColumn.EMAIL}
					sort={() => {
						this.setOrderColumn(
							EmployeeColumn.EMAIL
						)
					}}
				/>
				<ColumnHeader
					style={styles.tableColumn2}
					label={Translations.get('role')}
					visible={this.state.orderColumn === EmployeeColumn.ROLE}
					sort={() => {
						this.setOrderColumn(
							EmployeeColumn.ROLE
						)
					}}
				/>
				<ColumnHeader
					style={styles.tableColumn2}
					label={Translations.get('status')}
					visible={this.state.orderColumn === EmployeeColumn.IS_ACTIVE}
					sort={() => {
						this.setOrderColumn(
							EmployeeColumn.IS_ACTIVE
						)
					}}
				/>
			</TableRow>
		);
	}

	getTable() {
		return (
			<Table>
				<TableHead>
					{this.getHeaders()}
				</TableHead>
				<TableBody>
					{this.createRows(this.state.tableData)}
				</TableBody>
			</Table>
		);
	}

	render() {
		if(!this.props.isAdmin){
			return '';
		}

		if (this.state.isLoading) {
			this.refreshData();
		}

		return (
			<div style={styles.tableContainer}>
				<Paper style={{ ...styles.paperBox, position: 'relative' }}>
					<Grid
						container
						direction='column'
					>
						<Grid item>
							<Bar
								title={Translations.get('employees')}
								page={this.state.currentPage}
								step={this.state.pageItemLimit}
								length={this.state.lenghtData}
								nextAction={this.onNextButtonClick}
								previousAction={this.onPreviousButtonClick}
								setFilter={this.setFilter}
								deleteFilter={this.deleteFilter}
								isSearcher={true}
								button={{
									label: Translations.get('add_employee'),
									onClick: () => { this.props.setPopup('add_employe'); }
								}}
							/>
						</Grid>
						<Grid item>
							{this.getTable()}
						</Grid>
						<Grid item style={styles.space16Vertical}>
						</Grid>
						<Grid item>
							<Bar
								page={this.state.currentPage}
								step={this.state.pageItemLimit}
								length={this.state.lenghtData}
								nextAction={this.onNextButtonClick}
								previousAction={this.onPreviousButtonClick}
							/>
						</Grid>
					</Grid>
					{this.getLoading()}
				</Paper>
			</div>
		);
	}
}


const mapStateToProps = (state) => {
	return {
		userToken: state.user.token,
		userId: state.user.id,
		isAdmin: state.user.isAdmin,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (x) => {
			dispatch(setPopup(x));
		},
		setLogin: (x, y, z, q, t, a) => {
			dispatch(setLogin(x, y, z, q, t, a));
		},
		redirrect: (x, y) => {
			dispatch(redirrect(x, y));
		},
		setRepositoryValue: (x, y) => {
			dispatch(setValue(x, y));
		},
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(container));
