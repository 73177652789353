import React from "react";
// import { connect } from "react-redux";

import FAQElement from "../../Components/Body/FAQ/FAQElement";
import Typography from '@material-ui/core/Typography';
import { Grid } from "@material-ui/core";
import styles from 'Components/style'
import TextInput from 'Components/Commons/TextInput'

import Translations from 'Singleton/Translations'
import MediaType from "../../libs/MediaType";

import ItemList from "../../Resources/lista-przedmiotow-zakazanych.pdf";

import { MetaData, handleMetaData } from '../../Components/Commons/Const/MetaData'

export class FAQ extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			searchValue: "",
			faqs: [],
		}
	}

	tab = [
		{
			ask: "Jakie usługi zrealizuje dla Ciebie TREO?",
			answer: "Portal TREO.pl wyceni dla Ciebie importowy i eksportowy transport paletowy drobnicowy w UE. Dzięki Nam nie musisz czekać - wycena jest automatyczna i możesz w łatwy sposób przejść do realizacji zlecenia. Możesz również wysłać do nas zapytanie spersonalizowane, a nasz Zespół poszuka najlepszej oferty spełniającej twoje wymagania.",
		},
		{
			ask: "Dokąd mogę wysłać paletę z TREO?",
			answer: "Z TREO dokonasz eksportu i importu drobnicowego palet po krajach Unii Europejskiej. Na zapytanie indywidualne zrealizujesz także transporty europejskie do krajów spoza UE (np. Szwajcaria, Norwegia, Bałkany) w atrakcyjnych cenach.",
		},
		{
			ask: "Jakie wymiary i wagę może mieć paleta?",
			answer:
				<Typography component="div" variant="body1" align="justify" style={MediaType.isMobile ? { width: '100%' } : {}}>
					<ol style={{ listStyleType: "none", margin: 0, padding: 0, textAlign: "initial" }}>
						<li>Z TREO automatycznie wycenisz transport palet o ustandaryzowanych podstawach [cm]:</li>
						<li>- 120 x 80</li>
						<li>- 120 x 100</li>
						<li>- 80 x 60</li>
						<li>- 80 x 40</li>
						<li>- 60 x 40</li>
						<li>dla maksymalnej wysokości 220 cm oraz wagi 1200 kg.</li>
						<li>Zrealizujesz z TREO również transport palet nietypowych - po otrzymaniu zapytania prześlemy Ci wycenę indywidualną.</li>
					</ol>
				</Typography>
		},
		{
			ask: "Jakie informacje należy wprowadzić, aby uzyskać wycenę transportu? ",
			answer:
				<Typography component="div" variant="body1" align="justify" style={MediaType.isMobile ? { width: '100%' } : {}}>
					<ol style={{ listStyleType: "none", margin: 0, padding: 0, textAlign: "initial" }}>
						<li>Wyceń koszt transportu podając:</li>
						<li>- informację o statusie prawnym zamawiającego (os. prywatna / firma)</li>
						<li>- kraj wysyłki oraz kraj dostawy wraz z kodami pocztowymi</li>
						<li>- ilość palet</li>
						<li>- wymiary podstawy palety</li>
						<li>- wysokość palety</li>
						<li>- wagę brutto</li>
						<li>Następnie wystarczy kliknąć „OBLICZ CENĘ”. Po chwili w kalkulatorze pojawi się cena brutto oraz możliwość realizacji transportu poprzez kliknięcie „ZAMÓW TRANSPORT”.</li>
					</ol>
				</Typography>
		},
		{
			ask: "Jak otrzymam wycenę i poznam czas transportu?",
			answer:
				<Typography component="div" variant="body1" align="justify" style={MediaType.isMobile ? { width: '100%' } : {}}>
					<ol style={{ listStyleType: "none", margin: 0, padding: 0, textAlign: "initial" }}>
						<li>Dokładną cenę transportu, uwzględniającą indywidualne parametry, sprawdzisz samodzielnie wprowadzając na stronie TREO.pl kilka informacji.</li>
						<li>Do automatycznej wyceny będzie Ci potrzebna:</li>
						<li>- ilość palet</li>
						<li>- informację o statusie prawnym (os. Prywatna / firma)</li>
						<li>- kraj wysyłki oraz kraj dostawy wraz z kodami pocztowymi</li>
						<li>- wymiary podstawy palety</li>
						<li>- wysokość palety</li>
						<li>- wagę brutto</li>
						<li>Po kliknięciu „OBLICZ CENĘ” wyświetli się dla Ciebie koszt transportu. Aby zrealizować ofertę kliknij „ZAMÓW TRANSPORT” i postępuj zgodnie z kolejnymi wytycznymi. W kolejnym kroku zobaczysz również standardowy czas realizacji transportu na wybranym kierunku.</li>
					</ol>
				</Typography>
		},
		{
			ask: "Co zrobić jeśli kalkulator nie wyliczy ceny? ",
			answer: "W przypadku, gdy po wprowadzeniu danych nie pojawia się cena transportu lub nie możesz wprowadzić poprawnych parametrów, prześlij swoje zapytanie poprzez kliknięcie „ZAMÓW WYCENĘ RĘCZNĄ” (w polu „Dodatkowe uwagi” wpisz komplet danych). Po otrzymaniu takiego zapytania nasz Konsultant skontaktuje się z Tobą bezpośrednio.",
		},
		{
			ask: "Co wchodzi w skład wyliczonej ceny za przesyłkę?",
			answer:
				<Typography component="div" variant="body1" align="justify" style={MediaType.isMobile ? { width: '100%' } : {}}>
					<ol style={{ listStyleType: "none", margin: 0, padding: 0, textAlign: "initial" }}>
						<li>Podana stawka jest cena „all in” co oznacza, że zawiera:</li>
						<li>- cenę bazową</li>
						<li>- korektę paliwową</li>
						<li>- korektę walutową</li>
						<li>- dodatek drogowy</li>
						<li>- VAT 23% lub VAT 0% dla zarejestrowanych płatników NIP UE, zaakceptowanych w naszym systemie.</li>
					</ol>
				</Typography>
		},
		{
			ask: "Czy są rzeczy których nie mogę przewozić?",
			answer:
				<Typography component="div" variant="body1" align="justify" style={MediaType.isMobile ? { width: '100%' } : {}}>
					Przewóz paletowy jest wygodną formą nadania ładunku, jednak tak samo jak w przypadku paczek, jest grupa towarów wyłączona z możliwości transportu. Przed dokonaniem zlecenia musisz zaakceptować oświadczenie, że nie przewozisz towarów zabronionych. Lista dostępna jest <strong><a href={ItemList} download="Lista przedmiotów zakazanych.pdf">TUTAJ</a></strong>.
            </Typography>
		},
		{
			ask: "W jaki sposób mogę ubezpieczyć przesyłkę?",
			answer:
				<Typography component="div" variant="body1" align="justify" style={MediaType.isMobile ? { width: '100%' } : {}}>
					Każda przesyłka jest objęta standardowym ubezpieczeniem wynikającym z konwencji CMR na kwotę 8,33 SDR/kg. Jeśli chcesz ubezpieczyć paletę dodatkowo na wartość towaru (ubezpieczenie CARGO) skontaktuj się z Działem Obsługi Klienta, pod adresem e-mail: {this.mail}.
                </Typography>
		},
		{
			ask: "W jaki sposób zapłacę za zlecenie?",
			answer: "Usługę opłacisz poprzez platformę pośrednictwa płatności Przelewy24 lub standardowym przelewem bankowym.",
		},
		{
			ask: "Czy mogę zapłacić po zrealizowaniu transportu lub przy dostawie?",
			answer: "Nie oferujemy usług z płatnością odroczoną.",
		},
		{
			ask: "Czy mogę nadać przesyłkę „za pobraniem”?",
			answer: "Na chwilę obecną TREO.pl nie może zaoferować Ci usługi ‘za pobraniem’.",
		},
		{
			ask: "Czy kurier może dostarczyć paletę w konkretny dzień?",
			answer: "Abyśmy mogli zagwarantować Ci najwyższą jakość usług oraz najkorzystniejsze stawki, transporty realizujemy w systemie drobnicowym. Odbiory i dostawy odbywają się od poniedziałku do piątku w godzinach 8-17.",
		},
		{
			ask: "Czy dostawy realizowane są w soboty?",
			answer: "Dostawy jak i odbiory odbywają się od poniedziałku do piątku w godzinach 8-17.",
		},
		{
			ask: "Gdzie znajdę rachunek za transport?",
			answer: "TREO.pl, po zaksięgowaniu wpłaty, wyśle ci mailowo fakturę VAT. W każdej chwili możesz ją również pobrać samodzielnie wchodząc do zakładki „MOJE ZAMÓWIENIA”, wybierając z listy interesujące Cię zlecenie.",
		},
		{
			ask: "Gdzie znajdę dokumenty przewozowe ?",
			answer: "Po dokonaniu zamówienia transportu wszystkie dokumenty potrzebne do realizacji odbioru znajdziesz w zakładce „MOJE ZAMÓWIENIA”. Wyszukaj na stronie interesujące Cię zlecenie, a następnie kliknij „POBIERZ DOKUMENTY PRZEWOZOWE”.",
		},
		{
			ask: "Mam pytanie do zamówienia, jak się z Państwem skontaktować?",
			answer:
				<Typography component="div" variant="body1" align="justify" style={MediaType.isMobile ? { width: '100%' } : {}}>
					Jeśli złożyłeś już zlecenie transportu i pojawiły się pytania napisz do nas wchodząc w zakładkę „MOJE ZAMÓWIENIA”. Poprzez wybór interesującego Cię zlecenia możesz wysłać do nas wiadomość, na którą odpowiemy najszybciej jak będzie to możliwe.
            Gdybyś miał dodatkowe pytania zapraszamy do zakładki „PYTANIA I ODPOWIEDZI” oraz do kontaktu z Działem Obsługi Klienta, pod nr: 730 914 218 lub adresem mailowy: {this.mail}.
            </Typography>
		},
		{
			ask: "W jaki sposób mogę śledzić moją przesyłkę?",
			answer:
				<Typography component="div" variant="body1" align="justify" style={MediaType.isMobile ? { width: '100%' } : {}}>
					<ol style={{ listStyleType: "none", margin: 0, padding: 0, textAlign: "initial" }}>
						<li>Aby śledzić realizowane transporty wystarczy, że wejdziesz w zakładkę „MOJE ZAMÓWIENIA” a następnie wybierzesz z listy interesujący Cię transport. W panelu będzie widoczny jeden z możliwych statusów zlecenia:</li>
						<li>-NOWE</li>
						<li>-OCZEKUJE NA WPŁATĘ</li>
						<li>-ANULOWANE</li>
						<li>-W TRAKCIE REALIZACJI</li>
						<li>-W TRANSPORCIE (Etapy transportu: Oczekuje na załadunek, Magazyn początkowy, W drodze między magazynami, Magazyn końcowy)</li>
						<li>-SYTUACJA WYJĄTKOWA</li>
						<li>-DOSTARCZONE</li>
					</ol>
				</Typography>
		},
		{
			ask: "Co oznacza mój status zlecenia?",
			answer:
				<Typography component="div" variant="body1" align="justify" style={MediaType.isMobile ? { width: '100%' } : {}}>
					<ol style={{ listStyleType: "none", margin: 0, padding: 0, textAlign: "initial" }}>
						<li>Sprawdź poniżej, co oznacza wybrany status Twojej przesyłki:</li>
						<li></li>
						<li>- NOWE / OCZEKUJE NA WPŁATĘ - zlecenie utworzone w systemie, ale jeszcze nie opłacone. </li>
						<li></li>
						<li>- ANULOWANE - zamówienie zyskuje status „anulowane”, po zgłoszeniu rezygnacji ze zlecenia do Działu Obsługi Klienta </li>
						<li></li>
						<li>-W TRAKCIE REALIZACJI- na tym etapie zamówienie przekazane jest do przewoźnika w celu realizacji transportu. Należy wtedy pobrać dokumenty przewozowe z zakładki „MOJE ZAMÓWIENIA”</li>
						<li></li>
						<li>- W TRANSPORCIE (Etapy transportu: Oczekuje na załadunek, Magazyn początkowy, W drodze między magazynami, Magazyn końcowy) przy tym statusie przesyłka jest w trakcie realizacji. Paleta jest podejmowana przez kuriera, zawożona na magazyn przeładunkowy, a następnie samochodem liniowym jest przewożona do kraju docelowego. Tam w magazynie jest przeładowywana na mniejszy pojazd i dostarczana do odbiorcy.</li>
						<li></li>
						<li>- SYTUACJA WYJĄTKOWA* - skontaktuj się z nami poprzez wiadomość tekstową w zleceniu wybranym w zakładce „MOJE ZAMÓWIENIA”.</li>
						<li></li>
						<li>- DOSTARCZONE- zlecenie zostało zrealizowane, paleta została przekazana odbiorcy.</li>
					</ol></Typography>
		},
		{
			ask: "Jak anulować zlecenia w serwisie TREO.pl?",
			answer: <Typography component="div" variant="body1" align="justify" style={MediaType.isMobile ? { width: '100%' } : {}}>
				Jeśli chcesz anulować zlecenie skontaktuj się z Działem Obsługi Klienta, pod nr: 730 914 218 lub pod adresem e-mail: {this.mail}. Po przyjęciu dyspozycji zlecenie zmieni status na „ANULOWANE”."
            </Typography>
		},
		{
			ask: "Czemu moje zamówienie nie zostało odebrane?",
			answer: "Standardowo odbiory dokonywane są we wskazany przez Ciebie dzień. Może się jednak zdarzyć przesunięcie wynikające z dużego wolumenu na dany dzień lub sytuacji losowej, której nie można było przewidzieć. W niektórych regionach Europy kurierzy mają czas do 3 dni na podjęcie ładunku. Jeśli jesteś zaniepokojony brakiem kontaktu ze strony przewoźnika wyślij do nas wiadomość wchodząc do zakładki „MOJE ZAMÓWIENIA” i wybierając interesujące Cię zlecenie.",
		},
		{
			ask: "Czemu moje zamówienie jeszcze nie dojechało?",
			answer: `Abyśmy mogli zagwarantować Ci najwyższą jakość usług oraz najkorzystniejsze stawki, transporty realizujemy w systemie drobnicowym. Podawany czas transportu jest uśredniony dla danego kierunku, może się wydłużyć jeśli odbiory/ dostawy odbywają się w okolicach świąt i dni wolnych od pracy. Odległości od magazynów liniowych również mają wpływ na czas realizacji. Jeśli czas transportu przekroczył średni czas dla danego kierunku skontaktuj się z nami poprzez wiadomość wchodząc do zakładki „MOJE ZAMÓWIENIA” i wybierając interesujące Cię zlecenie.`,
		},
		{
			ask: "Zamawiam z zagranicy, czy dostanę fakturę VAT 0%?",
			answer:
				<Typography component="div" variant="body1" align="justify" style={MediaType.isMobile ? { width: '100%' } : {}}>
					<ol style={{ listStyleType: "none", margin: 0, padding: 0, textAlign: "initial" }}>
						<li>Aby TREO.pl mogło wystawiać dla Ciebie Faktury VAT 0% musisz:</li>
						<li>- posiadać NIP firmy zagranicznej</li>
						<li>- być zarejestrowany do VAT-UE</li>
						<li>- wysłać zgłoszenie poprzez zakładkę „USTAWIENIA KONTA” o możliwość korzystania z VAT 0%.</li>
					</ol>
				</Typography>
		},
		{
			ask: "Kto i jak może uzyskać fakturę VAT 0%?",
			answer:
				<Typography component="div" variant="body1" align="justify" style={MediaType.isMobile ? { width: '100%' } : {}}>
					<ol style={{ listStyleType: "none", margin: 0, padding: 0, textAlign: "initial" }}>
						<li>Aby TREO.pl mogło wystawiać dla ciebie Faktury VAT 0% musisz:</li>
						<li>- posiadać konto jako firma zarejestrowana w innym Państwie UE</li>
						<li>- być zarejestrowany do VAT-UE</li>
						<li>- wysłać zgłoszenie poprzez zakładkę „USTAWIENIA KONTA” o możliwość korzystania z VAT 0%.</li>
					</ol>
				</Typography>
		},
		{
			ask: "Kurier dostarczył uszkodzony ładunek, co teraz?",
			answer: <Typography component="div" variant="body1" align="justify" style={MediaType.isMobile ? { width: '100%' } : {}}>
				Kiedy zauważysz przy dostawie, że paleta/ ładunek został uszkodzony koniecznie spisz z Kurierem protokół szkody oraz wykonaj zdjęcie uwidaczniające uszkodzenie. Będzie to podstawą możliwych roszczeń reklamacyjnych. Reklamację możesz złożyć wchodząc do zakładki „MOJE ZAMÓWIENIA” i wybierając interesujące Cię zlecenie. Możesz również skontaktować się z Działem Obsługi Klienta, pod adresem e-mail: {this.mail}, który pomoże Ci przejść proces reklamacyjny.
            </Typography>
		},
		{
			ask: "W jaki sposób mogę złożyć reklamację?",
			answer: <Typography component="div" variant="body1" align="justify" style={MediaType.isMobile ? { width: '100%' } : {}}>
				Reklamację możesz złożyć wchodząc do zakładki „MOJE ZAMÓWIENIA” i wybierając interesujące Cię zlecenie. Możesz również skontaktować się z Działem Obsługi Klienta, pod adresem e-mail: {this.mail}, który pomoże Ci przejść proces reklamacyjny.
            </Typography>
		},
		{
			ask: "Kiedy reklamacja zostanie rozpatrzona?",
			answer: "Postaramy się jak najszybciej rozpatrzyć przesłaną reklamację. Jeśli zakupu usługi dokonałeś jako osoba prywatna odpowiedzi udzielimy do 30 dni, licząc od dnia otrzymania kompletnej reklamacji. W pozostałych przypadkach czas na odpowiedź wynosi 4 miesiące, licząc od dnia otrzymania kompletnej reklamacji.",
		}
	]

	componentWillMount() {
		document.title = MetaData.faq.title
		handleMetaData();
		this.tab.map((el) => {
			let hash = el.ask.toLowerCase().replace(/\s+/g, "-").normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ł/g, "l").replace(/[\„\”,\.\?\%]/g, "");
			if (hash.slice(-1) === '-') hash = hash.substring(0, hash.length - 1)
			el.atag = `${hash}`;
		})
	}

	componentDidMount() {
		this.setState({ faqs: this.getFAQFromTranslations() });
		setTimeout(() => this.scrollToAnchor(), 0);
	}

	scrollToAnchor() {
		if (window.location.hash !== '') {
			const hash = window.location.hash;
			const yOffset = -90;
			const element = document.querySelector(hash);
			const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
			window.scrollTo({ top: y, behavior: 'smooth' })
		}
	}

	filterList(e) {
		this.setState({
			searchValue: e,
			faqs: this.getFAQFromTranslations(e)
		});
	}

	getFAQFromTranslations(text) {
		const collectionTab = this.tab
		if (!text) return collectionTab;
		return collectionTab.filter(item => {
			const ask = item.ask.includes(text);
			if (ask) return true;
			const answer = JSON.stringify(item.answer).includes(text);
			return answer;
		});
	}

	mail = <a href="mailto:start@treo.pl">start@treo.pl</a>


	getElements() {
		return (
			<Grid
				container
				justify='space-between'
				direction={MediaType.isMobile ? 'column' : 'row'}
			>
				{
					this.state.faqs.map((item, i) => {
						return (
							<Grid item xs={12} key={i} style={{ marginTop: styles.space30.width }}>
								<FAQElement
									header={item.ask}
									text={item.answer}
									anchor={item.atag}
								/>
							</Grid>
						);
					})
				}
			</Grid>
		);
	}
	render() {
		return (
			<Grid
				container
				direction='column'
				style={
					MediaType.isMobile ?
						styles.mobileContainer :
						styles.container
				}
			>
				{MediaType.isMobile ? '' :
					<Grid item style={
						styles.space30Vertical
					}></Grid>
				}
				<Grid item>
					<Typography variant='display1'>
						{Translations.get('faq')}
					</Typography>
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item style={styles.rootMobile}>
					<TextInput
						id="faq-search-box"
						label={Translations.get('search')}
						onChange={this.filterList.bind(this)}
					/>
				</Grid>
				<Grid item>
					{this.getElements()}
				</Grid>
			</Grid>
		)
	}
}

// const mapStateToProps = (state) => {
// 	return {
// 		translations: state.translations
// 	};
// };

// const mapDispatchToProps = (dispatch) => {
// 	return {
// 	};
// };

// export default connect(mapStateToProps, mapDispatchToProps)(FAQ);
export default FAQ;
