import React from "react";
import PropTypes from 'prop-types'

import Paper from '@material-ui/core/Paper'

import styles from 'Components/style'
import { Typography } from "@material-ui/core";

import Translations from 'Singleton/Translations'


import getRootStyle from './Style'

const container = (props) => {
	return(
		<Paper style={getRootStyle()}>
			<Typography variant='body1'>
				<span style={styles.link} onClick={props.onClick}>
					{Translations.get('sign_in')}
				</span>
				{Translations.get('under_sign_in')}
			</Typography>
		</Paper>
	);
}

container.propTypes={
	style: PropTypes.object,
	onClick: PropTypes.func,
}

export default container;
