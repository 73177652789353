import React from 'react'
import { connect } from "react-redux";
import { sessionService } from 'redux-react-session';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';

import RestRequest from 'libs/RestRequest'
import { ERROR } from 'libs/RestRequest'

import { setPopup } from 'Actions/pageActions'
import { setLogin } from 'Actions/userActions'
import { redirrect } from 'Actions/pageActions'

import Translations from 'Singleton/Translations'
import { Typography, Grid, Button } from '@material-ui/core';
import styles from 'Components/style'

class container extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			couponValueValid: false,
			isLoading: true
		}

		this.showError = this.showError.bind(this);
		this.handleErr = this.handleErr.bind(this);
		this.handleResponse = this.handleResponse.bind(this);
		this.logout = this.logout.bind(this);

		this.cancelClick = this.cancelClick.bind(this);

		RestRequest(
			'get',
			'secured/vatZeroUserData',
			{ userId: this.props.userId },
			this.handleResponse,
			this.props.token
		);
	}

	cancelClick() {
		this.props.setPopup(undefined);
	}


	logout() {
		sessionService.deleteSession().then(
			sessionService.deleteUser().then(() => {
				this.props.setLogin(null, null, null, null, null);
				this.props.redirrect('/panel', this.props.history);
			})
		);
	}

	showError(msg) {
		toast.error(msg, {
			position: toast.POSITION.TOP_RIGHT,
		});
	}

	handleErr(body) {
		switch (body.errorCode) {
			case ERROR.USER_TOKEN_IS_NOT_VALID:
				this.showError(Translations.get('session_expired'))
				this.logout();
				break;
			default:
				this.showError(body.errorReason)
		}
	}

	handleResponse(res) {
		console.log("Show vat info", res);
		if (res.body.error) {
			this.handleErr(res.body);
			return;
		}
		var country = this.props.countries.find(
			(element) => {
				return res.body.country === element.value;
			}
		)
		res.body.country = country.name;
		this.setState({
			isLoading: false,
			status: res.body.status,
			invoiceData: res.body,
		})
	}



	render() {
		if (this.state.isLoading) {
			return (
				<div style={styles.loadingCenter}>
					<CircularProgress />
				</div>
			);
		}
		var data = this.state.invoiceData;

		return (
			<Grid
				container
				direction='column'
			>
				<Grid item>
					<Typography variant='body1'>
						<b>{Translations.get('optional_nip_vat')}: </b>
						{data.nip}
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant='body1'>
						<b>{Translations.get('company_name')}: </b>
						{data.company}
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant='body1'>
						<b>{Translations.get('street_and_number')}: </b>
						{data.street}
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant='body1'>
						<b>{Translations.get('full_name')}: </b>
						{data.name} {data.surname}
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant='body1'>
						<b>{Translations.get('phone_number')}: </b>
						{data.phone}
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant='body1'>
						<b>{Translations.get('postcode')}: </b>
						{data.postcode} {data.city}
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant='body1'>
						<b>{Translations.get('country')}: </b>
						{data.country}
					</Typography>
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Button
						variant='outlined'
						onClick={this.cancelClick}
						fullWidth
					>
						Ok
					</Button>
				</Grid>
			</Grid>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		userId: state.repository.userId,
		token: state.user.token,
		inputs: state.input.inputState,
		countries: state.translations.countrys,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (x) => {
			dispatch(setPopup(x));
		},
		setLogin: (x, y, z, q, t, a) => {
			dispatch(setLogin(x, y, z, q, t, a));
		},
		redirrect: (x, y) => {
			dispatch(redirrect(x, y));
		}
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(container));
