export const MetaData = {
    mainPage: {
        title: 'TREO.PL - Natychmiastowa wycena i zlecanie wysyłki palet w transporcie drobnicowym.',
    },
    contact: {
        title: 'Kontakt z TREO.PL - serwisem do wyceny i wysyłki palet w UE',
    },
    blog: {
        title: 'Jak wysyłać paletę online? - z TREO.PL to niezwykle proste.',
    },
    faq: {
        title: 'TREO.PL - najczęstsze pytania dotyczące wysyłek paletowych',
    }
}

export const handleMetaData = () => {
    const pathName = document.location.pathname;

    const fbTittle = document.querySelector('meta[property="og:title"]');
    const twitterTittle = document.querySelector('meta[name="twitter:title"]');
    const googleName = document.querySelector('meta[itemprop="name"]');

    const docDesc = document.querySelector('meta[name="description"]');
    const fbDesc = document.querySelector('meta[property="og:description"]');
    const twitterDesc = document.querySelector('meta[name="twitter:description"]');
    const googleDesc = document.querySelector('meta[itemprop="description"]');

    const tittles = [fbTittle, twitterTittle, googleName];
    const descs = [docDesc, fbDesc, twitterDesc, googleDesc];

    switch (pathName) {
        case '/blog':
            document.title = 'Jak wysyłać paletę online? - z TREO.PL to niezwykle proste.'
            descs.map(el => {
                el.setAttribute('content', 'Wysyłka palet do krajów UE nigdy nie była tak prosta. Wyceń transport i zamów odbiór palet z dowolnego miejsca.')
            })
            tittles.map(el => {
                el.setAttribute('content', 'Jak wysyłać paletę online? - z TREO.PL to niezwykle proste.')
            })
            break;
        case '/faq':
            document.title = 'TREO.PL - najczęstsze pytania dotyczące wysyłek paletowych'
            descs.map(el => {
                el.setAttribute('content', 'Jak zaplanować wysyłkę palet przez internet? Czy mogę nadać paletę bez umowy z przewoźnikiem? Z TREO.PL zrobisz to online.')
            })
            tittles.map(el => {
                el.setAttribute('content', 'TREO.PL - najczęstsze pytania dotyczące wysyłek paletowych.')
            })
            break;
        case '/contact':
            document.title = 'Kontakt z TREO.PL - serwisem do wyceny i wysyłki palet w UE.'
            descs.map(el => {
                el.setAttribute('content', 'Wysyłka palet do krajów UE nigdy nie była tak prosta. Wyceń transport i zamów odbiór palet z dowolnego miejsca.')
            })
            tittles.map(el => {
                el.setAttribute('content', 'Kontakt z TREO.PL - serwisem do wyceny i wysyłki palet w UE.')
            })
            break;
        default:
            break;
    }
}
