import React from "react";
import { connect } from "react-redux";

import { setPopup } from 'Actions/pageActions'
import Translations from 'Singleton/Translations'
import { Paper, Grid, Typography, Button } from "@material-ui/core";
import IconText from 'Components/Commons/IconText';
import styles from 'Components/style'
import MediaType from "../../../libs/MediaType";

class container extends React.Component {
	constructor(props) {
		super(props);

		this.changePassword = this.changePassword.bind(this);
	}

	changePassword() {
		this.props.setPopup('change_pass');
	}

	render() {
		var isExternalAccount = this.props.user.type != "Email";

		return (
			<Paper style={
				MediaType.isMobile ?
					styles.paperBoxMobile :
					styles.paperBox
			}>
				<Grid
					container
					direction='column'
				>
					<Grid item>
						<Typography variant='title' paragraph>
							{Translations.get('login')}
						</Typography>
					</Grid>
					<Grid item>
						<Grid
							container
							justify='space-between'
						>
							<Grid item>
								<Typography variant='body1'>
									E-mail: {this.props.user.login}
								</Typography>
							</Grid>
							{isExternalAccount &&
								<Grid item>
									<IconText
										type='done'
										variant='body1'
									>
										{Translations.get('connected_with_account')} {this.props.user.type}
									</IconText>
								</Grid>
							}
						</Grid>
					</Grid>
					<Grid item style={styles.space16Vertical}>
					</Grid>
					<Grid item>
						<Button
							variant='outlined'
							onClick={this.changePassword}
							disabled={isExternalAccount}
							fullWidth={MediaType.isMobile}
						>
							{Translations.get('change_password')}
						</Button>
					</Grid>
				</Grid>
			</Paper>
		);
	}
}


const mapStateToProps = (state) => {
	return {
		user: state.user
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (x) => {
			dispatch(setPopup(x));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(container);
