
import { io } from 'socket.io-client';
import { uuid } from 'uuidv4';

let __io_instance = null;

class IO {
  
  static get Instance() {
    if (__io_instance === null) {
      const uid = localStorage.getItem('uid') || uuid();
      __io_instance = new IO(uid)
    }
    return __io_instance;
  }

  constructor(uid) {
    this.uid = uid;
    // this.socket = io('http://localhost:3003', {
    //   query: { uid }
    // });
    this.socket = io('https://treo.pl', {
      query: { uid },
      path: '/socket.io'
    });
    localStorage.setItem('uid', uid);
  }

  dispatch(message) {
    this.socket.send(message);
  }
}

export default IO.Instance;
