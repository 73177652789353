import React from 'react'
import { connect } from "react-redux";
import { toast } from 'react-toastify';

import RestRequest from 'libs/RestRequest'

import { setPopup } from 'Actions/pageActions'
import { Grid, Typography, Button } from '@material-ui/core'
import DatePicker from 'Components/Commons/DatePicker'
import TextInput from '../../Components/Commons/TextInput';
import Translations from 'Singleton/Translations'
import styles from 'Components/style'


class container extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isDateValid: true,
		}
		this.handleResponse = this.handleResponse.bind(this);
		this.showError = this.showError.bind(this);
	}

	cancelClick() {
		this.props.setPopup(undefined);
	}

	confirmClick() {
		RestRequest(
			'post',
			'secured/order/status/supply',
			{
				number: this.props.number,
				deliveryDate: this.props.inputs.date,
				remarks: this.props.inputs.remarks,
			},
			this.handleResponse,
			this.props.userToken,
		)

		this.props.setPopup(undefined);
	}

	showError(msg) {
		toast.error(msg, {
			position: toast.POSITION.TOP_RIGHT,
		});
	}

	handleResponse(res) {
		console.log(res);
		if (res.body.error) {
			this.showError(res.body.errorReason)
		} else {
			this.props.acceptFunc();
			this.props.setPopup();
		}
	}

	onDateValidChange = (isValid) => {
		this.setState({
			...this.state,
			isDateValid: isValid,
		})
	}

	render() {
		return (
			<Grid
				container
				direction='column'
			>
				<Grid item>
					<Typography variant='body1'>
						{Translations.get('loading_date_text')}
					</Typography>
				</Grid>
				<Grid item>
					<DatePicker
						id='date'
						label={Translations.get('date')}
						onValidChange={this.onDateValidChange}
						doNotValidate
						defaultDate={new Date().getTime()}
					/>
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Typography variant='body1'>
						{Translations.get('additional_remarks_for_client')}
					</Typography>
				</Grid>
				<Grid item>
					<TextInput
						id='remarks'
						label={Translations.get('remarks')}
						fullWidth
						limit={245}
					/>
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Button
						fullWidth
						variant='contained'
						onClick={this.confirmClick.bind(this)}
						color='primary'
						disabled={!this.state.isDateValid}
					>
						{Translations.get('change_status_provided')}
					</Button>
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Button
						fullWidth
						variant='outlined'
						onClick={this.cancelClick.bind(this)}
					>
						{Translations.get('cancel')}
					</Button>
				</Grid>
			</Grid>
		);
	}
}


const mapStateToProps = (state) => {
	return {
		userToken: state.user.token,
		inputs: state.input.inputState,
		number: state.repository.number,
		acceptFunc: state.page.popupFunc,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (x) => {
			dispatch(setPopup(x));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(container);
