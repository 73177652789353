import React from 'react'
import { Button, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types'
import styles from '../../../style';

const PopupButton = (props) => {
	return (
		<Button
			variant='outlined'
			onClick={props.onClick}
			fullWidth
		>
			<Grid
				container
				justify='center'
				alignItems='center'
			>
				<Grid item style={styles.space30}>
					<img
						src={props.icon}
						alt=''
						style={{
							height: 24
						}}
					/>
				</Grid>
				<Grid item style={styles.space16}>
				</Grid>
				<Grid item style={{
					width: 100
				}}>
					<Typography align='left'>
						{props.children}
					</Typography>
				</Grid>
			</Grid>
		</Button>
	)
}

PopupButton.propTypes = {
	onClick: PropTypes.func.isRequired,
	children: PropTypes.string.isRequired,
	icon: PropTypes.node.isRequired,
}

export default PopupButton
