import React from "react";
import ImageBlock from '../../Components/Body/Main/ImageBlock'
import {HowItWorksBlock} from '../../Components/Body/Main/HowItWorksBlock'
import {WhyBlock} from '../../Components/Body/Main/WhyBlock'
import {BlogBlock} from '../../Components/Body/Main/BlogBlock'
import FAQShortBlock from '../../Components/Body/Main/FAQShortBlock'

const MainPage = () => {
	return (
		<div>
			<ImageBlock />
			<HowItWorksBlock />
			<FAQShortBlock />
			<WhyBlock />
			<BlogBlock />
		</div>
	);
}

export default MainPage;
