import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import styles from 'Components/style'

const container = (props) => {
	return (
		<Grid
			container
			direction='column'
		>
			<Grid item style={styles.space16Vertical}>
			</Grid>
			<Grid item>
				<Divider />
			</Grid>
			<Grid item style={styles.space16Vertical}>
			</Grid>
			<Grid item>
				<Typography 
					variant='body1'
					align='center'
				>
					{props.text}
				</Typography>
			</Grid>
			<Grid item>
				<Typography
					onClick={props.link.onClick}
					style={styles.link}
					align='center'
				>
					{props.link.text}
				</Typography>
			</Grid>
		</Grid>
	)
}

container.propTypes = {
	text: PropTypes.string,
	link: PropTypes.shape({
		text: PropTypes.string.isRequired,
		onClick: PropTypes.func.isRequired,
	}).isRequired
}

export default container;
