import React from 'react'
import { Grid, Typography } from '@material-ui/core';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Translations from '../../../../Singleton/Translations'


const FormRowLabel = (props) => {
	return (
		<Grid
			container
			direction='column'
		>
			{props.paletsCount > 1 ?
				<Grid item>
					<Typography
						style={{
							paddingLeft: 8
						}}
					><b>
							{Translations.get('pallet')}
							{' '}
							{props.id+1}
						</b></Typography>
				</Grid>
				: ''}
			<Grid item>
				{props.children}
			</Grid>
		</Grid>
	)
}

FormRowLabel.propTypes = {
	id: PropTypes.number,
}

const mapStateToProps = (state) => ({
	paletsCount: state.imageBlockForm.paletsCount
})

const mapDispatchToProps = {

}


export default connect(mapStateToProps, mapDispatchToProps)(FormRowLabel)
