import React from 'react'
import { connect } from "react-redux";

import RestRequest from 'libs/RestRequest'

import { setPopup } from 'Actions/pageActions'
import Translations from 'Singleton/Translations'

import { Grid, Typography, Button } from '@material-ui/core'
import TextInput from '../../Components/Commons/TextInput';
import AutoCompleteRest from '../../Components/Commons/AutoCompleteRest';
import { TextInputType } from 'Components/Commons/Const/InputTypes'
import styles from '../../Components/style';
import FlagSelect from '../../Components/Commons/FlagSelect'

class container extends React.Component {
	constructor(props) {
		super(props);
		var countries = [];
		for (var country of props.translations.countrys) {
			countries.push({ key: country.value.trim(), value: country.name.trim() });
		}

		this.handleResponse = this.handleResponse.bind(this);

		this.state = {
			countries: countries,
			isPostcodeOk: false,
			isCompany: false,
			functions: {},
			isNipFirst: true,
		}
	}

	styles = {
		text: {
			textAlign: "justify",
			fontSize: "1.0vw",
		}
	}

	bindFunction = (name) => (callback) => {
		const f = this.state.functions;
		f[name] = callback;
		this.setState({
			...this.state,
			functions: f,
		})
	}

	cancelClick() {
		this.props.setPopup(undefined);
	}

	acceptClick() {
		var data = this.props.inputs;
		var body = {
			nip: data.invoiceVatNIP,
			company: data.invoiceVatCompanyName,
			country: data.invoiceVatCountry,
			postcode: data.invoiceVatPostcode,
			city: data.invoiceVatCity,
			street: data.invoiceVatStreet,
			name: data.invoiceVatName,
			surname: data.invoiceVatSurname,
			phone: data.invoiceVatPhone,
		}

		RestRequest(
			'post',
			'/secured/vatZeroUserData',
			body,
			this.handleResponse,
			this.props.userToken,
		);
	}

	handleResponse(res) {
		console.log('Post Vat0', res);
		this.props.invoiceRefreshData();
		this.props.setPopup(undefined);
	}

	onNIPValidChange(value) {
		this.setState({
			...this.state,
			isValidNip: value,
			isNipFirst: false,
		})
	}

	onCompanyValidChange(value) {
		this.setState({
			...this.state,
			isValidCompany: value,
		})
	}

	onValidChange = (id) => (value) => {
		this.setState({
			...this.state,
			[id]: value,
		})
	}

	onStreetValidChange(value) {
		this.setState({
			...this.state,
			isValidStreet: value,
		})
	}

	resetPostcode = () => {
		console.log('reset postcode');
		this.state.functions.postcode()
		this.state.functions.resetPostcode()
	}

	resetCity = () => {
		console.log('reset city');
		this.state.functions.city()
		this.state.functions.resetCity()
	}

	handleFlagChange = () => {
		this.resetPostcode();
		this.state.functions.checkNip()
	}

	getCoutnryRow() {
		return (
			<Grid
				container
				alignItems='baseline'
			>
				<Grid item style={styles.popupColumn2}>
					<Grid
						container
						alignItems='baseline'
					>
						<Grid item>
							<Typography variant='body1'>
								{Translations.get('country')}:
							</Typography>
						</Grid>
						<Grid item style={styles.space30}>
						</Grid>
						<Grid item>
							<FlagSelect
								id="invoiceVatCountry"
								defaultValue='PL'
								onChange={this.handleFlagChange}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item style={styles.space30}>
				</Grid>
				<Grid item style={styles.popupColumn2}>
					<AutoCompleteRest
						required
						placeholder={Translations.get('postcode')}
						id="invoiceVatPostcode"
						rest={{
							door: "/postcode",
							params: {
								country: this.props.inputs.invoiceVatCountry
							},
							resFieldName: "postcode",
							inputParamName: "postalcode",
						}}
						onChange={(value) => {
							console.log('postcode', value);
							this.resetCity();
							this.setState({
								...this.state,
								isPostcodeOk: value? true: false,
							})
						}}
						bindSetValueFunc={this.bindFunction('postcode')}
						bindResetSuggestions={this.bindFunction('resetPostcode')}
					/>
				</Grid>
			</Grid>
		)
	}

	render() {
		return (
			<Grid
				container
				direction='column'
			>
				<Grid item>
					<Typography variant='body1' paragraph>
						{Translations.get('request_vat_zero_percent')}
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant='body1' paragraph>
						{Translations.get('data_for_invoices')}
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant='body1' paragraph>
						{Translations.get('zero_percent_invoice_info')}
					</Typography>
				</Grid>
				<Grid item>
					<TextInput
						id="invoiceVatNIP"
						type={TextInputType.NIP}
						onValidChange={this.onNIPValidChange.bind(this)}
						label={Translations.get('optional_nip_vat')}
						nipCountry={this.props.inputs.invoiceVatCountry}
						isError={!this.state.isValidNip && !this.state.isNipFirst}
						getCheckValidFunc={this.bindFunction('checkNip')}
						required
					/>
				</Grid>
				<Grid item>
					<TextInput
						id="invoiceVatCompanyName"
						type={TextInputType.NOT_EMPTY}
						onValidChange={this.onCompanyValidChange.bind(this)}
						label={Translations.get('company_name')}
						required
					/>
				</Grid>
				<Grid item>
					<TextInput
						id="invoiceVatName"
						type={TextInputType.NOT_EMPTY}
						onValidChange={this.onValidChange('isValidName')}
						label={Translations.get('name')}
						required
					/>
				</Grid>
				<Grid item>
					<TextInput
						id="invoiceVatSurname"
						type={TextInputType.NOT_EMPTY}
						onValidChange={this.onValidChange('isValidSurame')}
						label={Translations.get('surname')}
						required
					/>
				</Grid>
				<Grid item>
					<TextInput
						id="invoiceVatPhone"
						type={TextInputType.NUMBER}
						onValidChange={this.onValidChange('isValidPhone')}
						label={Translations.get('phone_number')}
						required
					/>
				</Grid>
				<Grid item style={styles.space8Vertical}>
				</Grid>
				<Grid item>
					{this.getCoutnryRow()}
				</Grid>
				<Grid item style={styles.space8Vertical}>
				</Grid>
				<Grid item>
					<AutoCompleteRest
						required
						placeholder={Translations.get('city')}
						id="invoiceVatCity"
						rest={{
							door: "/postcode/city",
							params: {
								country: this.props.inputs.invoiceVatCountry,
								postalcode: this.props.inputs.invoiceVatPostcode,
								limit: 0,
							},
							resFieldName: "city",
							inputParamName: "city",
						}}
						disabled={!this.state.isPostcodeOk}
						bindSetValueFunc={this.bindFunction('city')}
						bindResetSuggestions={this.bindFunction('resetCity')}
						onChange={(value) => {
							console.log('city', value);
							this.setState({
								...this.state,
								isValidCity: value? true: false,
								isPostcodeOk: value? true: false,
							})
						}}
					/>
				</Grid>
				<Grid item>
					<TextInput
						id="invoiceVatStreet"
						onValidChange={this.onStreetValidChange.bind(this)}
						type={TextInputType.NOT_EMPTY}
						label={Translations.get('street_and_number')}
						required
					/>
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Button
						fullWidth
						variant='contained'
						color='primary'
						onClick={this.acceptClick.bind(this)}
						disabled={!(
							this.state.isValidNip &&
							this.state.isValidCompany &&
							this.state.isValidStreet &&
							this.state.isValidCity &&
							this.state.isValidName &&
							this.state.isValidSurame &&
							this.state.isValidPhone
						)}
					>
						{Translations.get('send_request')}
					</Button>
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Button
						fullWidth
						variant='outlined'
						onClick={this.cancelClick.bind(this)}
					>
						{Translations.get('cancel')}
					</Button>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userToken: state.user.token,
		userId: state.user.id,
		inputs: state.input.inputState,
		translations: state.translations,
		invoiceRefreshData: state.repository.invoiceRefreshData
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (x) => {
			dispatch(setPopup(x));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(container);
