
export const dateToString = (date) => {

}

export const datetimeToString = (date) => {

}

export const timeFormat = (ms) => {
	if(!ms) {return '';}
	var date = new Date(ms);
	//return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;

	var mm = date.getMonth() + 1; // getMonth() is zero-based
	var dd = date.getDate();
	var hh = date.getHours();
	var min = date.getMinutes();
	var ss = date.getSeconds();

	return [date.getFullYear(),'-',
		(mm>9 ? '' : '0') + mm,'-',
		(dd>9 ? '' : '0') + dd,' ',
		(hh>9 ? '' : '0') + hh,':',
		(min>9 ? '' : '0') + min,':',
		(ss>9 ? '' : '0') + ss
	].join('');
}

export const timeFormatDots = (ms) => {
	if(!ms) {return '';}
	var date = new Date(ms);
	//return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;

	var mm = date.getMonth() + 1; // getMonth() is zero-based
	var dd = date.getDate();
	var hh = date.getHours();
	var min = date.getMinutes();

	return [
		(dd>9 ? '' : '0') + dd,'.',	
		(mm>9 ? '' : '0') + mm,'.',
		date.getFullYear(),' ',
		(hh>9 ? '' : '0') + hh,':',
		(min>9 ? '' : '0') + min,
	].join('');
}

export const timeFormatDate = (ms) => {
	if(!ms) {return '';}
	var date = new Date(ms);

	var mm = date.getMonth() + 1; // getMonth() is zero-based
	var dd = date.getDate();

	return [
		(dd>9 ? '' : '0') + dd,'.',	
		(mm>9 ? '' : '0') + mm,'.',
		date.getFullYear(),' ',
	].join('');
}

export const timeFormatTime = (ms) => {
	if(!ms) {return '';}
	var date = new Date(ms);

	var hh = date.getHours();
	var min = date.getMinutes();

	return [
		(hh>9 ? '' : '0') + hh,':',
		(min>9 ? '' : '0') + min,
	].join('');
}

export const timeFormatTimeUTC  = (ms) => {
	if(!ms) {return '';}
	var date = new Date(ms);

	var hh = date.getUTCHours();
	var min = date.getUTCMinutes();

	return [
		(hh>9 ? '' : '0') + hh,':',
		(min>9 ? '' : '0') + min,
	].join('');
}

const showErrInterval = () => {
	console.error('Time interval is incorrect');
}

export const timeFormatInterval = (ms1, ms2) => {
	if (!ms1 || !ms2) {return ''}
	var date1 = new Date(ms1);
	var date2 = new Date(ms2);

	const year = date1.getFullYear();
	if(year !== date2.getFullYear()){
		showErrInterval();
		return '';
	}

	const month = date1.getMonth() + 1;
	if(month !== date2.getMonth() +1 ){
		showErrInterval();
		return '';
	}

	const day = date1.getDate();
	const h1 = date1.getUTCHours();
	const h2 = date2.getUTCHours();
	const m1 = date1.getUTCMinutes();
	const m2 = date2.getUTCMinutes();

	return [
		(day>9 ? '' : '0') + day,'.',
		(month>9 ? '' : '0') + month,'.',
		year%100,' ',

		(h1>9 ? '' : '0') + h1,':',
		(m1>9 ? '' : '0') + m1,'-',

		(h2>9 ? '' : '0') + h2,':',
		(m2>9 ? '' : '0') + m2
	].join('');
}
