import React from "react";
import { connect } from "react-redux";
import { send } from 'Actions/sendEmailActions'

import Translations from 'Singleton/Translations'
import { Grid, Typography, Button } from "@material-ui/core";
import styles from 'Components/style'
import MediaType from "../../libs/MediaType";

import TextInput from 'Components/Commons/TextInput'
import { TextInputType } from 'Components/Commons/Const/InputTypes'
import Checkbox from 'Components/Commons/Checkbox'
import FormHelperText from '@material-ui/core/FormHelperText';
import { MetaData, handleMetaData } from '../../Components/Commons/Const/MetaData'

const componentStyle = {
	link: {
		...styles.link,
	},
	linkIcon: {
		width: "30px",
		height: "30px",
		display: "inline-block",
		border: "1px solid #494949",
		borderRadius: "20px",
		padding: "5px",
		position: "relative",
		verticalAlign: "middle",
		marginRight: "0.5rem"
	},
	linkIconImage: {
		maxWidth: "20px",
		maxHeight: "16px",
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
	}
}

const form = {}



function formChange(value) {
	form[this.name] = value
	this.form.validate(this.name)
}

function validateEmail(email) {
	var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}


class Contact extends React.Component {

	constructor(props) {
		super(props)
		this.sendForm = this.sendForm.bind(this)
		this.validate = this.validate.bind(this)
		this.state = { 'formErrors': {}, 'sendSuccess': false }
	}

	componentWillMount() {
		document.title = MetaData.contact.title
		handleMetaData();
	}

	validate(name = null) {
		let prevState = this.state.formErrors;
		let errors = name ? Object.assign({}, prevState) : {}

		if (name && errors[name] !== undefined) delete errors[name]

		let required = name ? [name] : ['email', 'message', 'terms']


		for (let item of required) {
			if (form[item] == undefined || form[item] == '') errors[item] = 'Pole jest wymagane'
		}

		if (errors.email == undefined && !validateEmail(form.email)) errors['email'] = 'Email jest niepoprawny'



		if (Object.keys(prevState).join(',') !== Object.keys(errors).join(',')) this.setState({ 'formErrors': errors })

		return !Object.keys(errors).length
	}

	sendForm() {

		if (this.validate()) {
			this.props.sendContactEmail(form);
			this.setState({ sendSuccess: true })
		}
	}

	renderForm() {
		return (
			<div>
				<div>
					<Typography variant='title' paragraph>
						{Translations.get('Napisz do nas!')}
					</Typography>
					<TextInput id="form_email" name="email" type={TextInputType.EMAIL} placeholder={Translations.get('Twój email')} form={this} onChange={formChange} isError={this.state.formErrors.email != undefined} errorReason={this.state.formErrors.email} />
					<TextInput id="form_messages" name="message" type={TextInputType.DEFAULT} multiline={true} unlimited={true} placeholder={Translations.get('Napisz wiadomość')} form={this} onChange={formChange} isError={this.state.formErrors.message != undefined} errorReason={this.state.formErrors.message} />
					<Checkbox id="form_terms" name="terms" label={<span>{Translations.get('Akceptuję')} <a href="">{Translations.get('politykę prywatności')}</a></span>} form={this} onChange={formChange} />
					{this.state.formErrors.terms !== undefined ? <FormHelperText style={{ color: '#f44336', marginTop: '-10px', marginBottom: '20px' }}>{this.state.formErrors.terms}</FormHelperText> : ''}
				</div>
				<Button
					onClick={this.sendForm}
					variant="contained"
					color="primary"
					style={styles.button}
				>
					{Translations.get('send')}
				</Button>
			</div>
		)
	}



	renderMessage() {



		return <Typography paragraph>
			{Translations.get('Dziękujemy za wysłanie zapytania, postaramy skontaktować się w jak najkrótszym czasie.')}
		</Typography>
	}

	render() {
		return (
			<Grid
				container
				style={
					MediaType.isMobile ?
						styles.mobileContainer :
						styles.container
				}

			>
				<Grid item xs={12}>
					<br />
					<Typography component="h1" variant='display1' paragraph>
						{Translations.get('contact')}
					</Typography>
				</Grid>
				<Grid item xs={12} lg={3}>
					<Grid container>
						<Grid item xs={6} lg={12}>
							<Typography variant='body1' paragraph>
								<a href="mailto:start@treo.pl" target="_top" style={componentStyle.link}>
									<span style={componentStyle.linkIcon}><img src="/images/envelope-regular.svg" style={componentStyle.linkIconImage} /></span>start@treo.pl
								</a>
							</Typography>
							<Typography variant='body1' paragraph>
								<a href="tel:+48730914218" style={componentStyle.link}>
									<span style={componentStyle.linkIcon}><img src="/images/mobile-alt-solid.svg" style={componentStyle.linkIconImage} /></span>+48 730 914 218
								</a>
							</Typography>
							<Typography variant='body1' paragraph>
								<a href="tel:+48666914219" style={componentStyle.link}>
									<span style={componentStyle.linkIcon}><img src="/images/mobile-alt-solid.svg" style={componentStyle.linkIconImage} /></span>+48 666 914 219
								</a>
							</Typography>
							<Typography variant='body1' paragraph>
								<a href="https://www.facebook.com/wwwtreopl/" target="_blank" rel="nofollow" style={componentStyle.link}>
									<span style={componentStyle.linkIcon}><img src="/images/facebook-f-brands.svg" style={componentStyle.linkIconImage} /></span>Facebook
								</a>
							</Typography>
							<Typography variant='body1' paragraph>
								<a href="https://pl.linkedin.com/company/wwwtreopl" target="_blank" rel="nofollow" style={componentStyle.link}>
									<span style={componentStyle.linkIcon}><img src="/images/linkedin-in-brands.svg" style={componentStyle.linkIconImage} /></span>LinkedIn
								</a>
							</Typography>
							<br />
						</Grid>
						<Grid item xs={6} lg={12}>
							<Typography variant='title'>
								{Translations.get('company_data')}:
							</Typography>
							<br />
							<Typography variant='body1'>
								Treo Sp. z o.o.
							</Typography>
							<Typography variant='body1'>
								ul. Kielecka 143
							</Typography>
							<Typography variant='body1'>
								26-004 Bieliny
							</Typography>
							<Typography variant='body1'>
								NIP: 6572926285
							</Typography>
							<Typography variant='body1'>
								REGON: 365776367
							</Typography>
							<br /><br />
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} lg={8}>

					{this.state.sendSuccess ? this.renderMessage() : this.renderForm()}

				</Grid>


			</Grid>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		sendContactEmail: (form) => {
			send(dispatch, form)
		}
	};
};

export default connect(null, mapDispatchToProps)(Contact);
