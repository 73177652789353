import React, { Component } from "react";
import { connect } from "react-redux";
import { subscribe } from "redux-subscriber";
import { withTheme } from "@material-ui/core/styles";
import { ToastContainer } from "react-toastify";
import { Route, withRouter } from "react-router-dom";
import { sessionService } from "redux-react-session";

import HeaderBarFull from "Components/HeaderBar/HeaderBarFull";
import HeaderBarFullAdmin from "Components/HeaderBar/HeaderBarFullAdmin";
import FooterBar from "Components/FooterBar/FooterBar";
import CircularProgress from "@material-ui/core/CircularProgress";

import { setPalletsCountrysAndLanguage } from "Actions/translationActions";
import { setLogin, setUserVatZero } from "Actions/userActions";
import { setPage } from "Actions/pageActions";
import { getFacebook, getGoogle } from "libs/socialAuth";

import Body from "./Body";
import BodyAdmin from "./BodyAdmin";
import Error from "./Error.jsx";
import Popup from "./Popup";

import styles from "../Components/style";
import Browser from "libs/Browser";

import mt from "libs/MediaType";
import RestRequest from "../libs/RestRequest";
import pjson from "../../package.json";

class App extends Component {
  constructor(props) {
    super(props);

    mt.connect(this);

    this.UserSite = this.UserSite.bind(this);
    this.AdminSite = this.AdminSite.bind(this);
    this.loginExternal = this.loginExternal.bind(this);
    this.facebookResponse = this.facebookResponse.bind(this);
    this.googleResponse = this.googleResponse.bind(this);
    this.logout = this.logout.bind(this);
    this.login = this.login.bind(this);

    this.state = {
      loading: true,
      error: false,
      lang: "PL",
      user: {},
    };

    console.table({
      ver: pjson["app-ver"],
      env: process.env.NODE_ENV,
      node_path: process.env.NODE_PATH,
    });

    subscribe("translations.lang", (state) => {
      if (state.translations.error) {
        this.setState({ loading: false, error: true });
      } else {
        this.setState({ loading: false, error: false });
      }
    });

    setTimeout(() => {
      sessionService
        .loadSession()
        .then((currentSession) => {
          sessionService.loadUser().then((currentUser) => {
            console.debug(currentSession, currentUser);
            if (window.dataLayer) {
              window.dataLayer.push({ user_id: currentUser.id });
            }
            if (currentUser.type === "Email") {
              this.props.setLogin(
                currentUser.login,
                currentSession.token,
                currentUser.id,
                currentUser.isAdmin,
                currentUser.type,
                currentUser.accessToken
              );
              this.checkUserVatZero(currentSession.token);
              this.props.setPalletsCountrysAndLanguage(this.state.lang);
              return;
            }

            this.setState({
              ...this.state,
              user: {
                login: currentUser.login,
                token: currentSession.token,
                id: currentUser.id,
                isAdmin: currentUser.isAdmin,
                type: currentUser.type,
                accessToken: currentUser.accessToken,
                isVatZero: currentUser.isVatZero,
              },
            });
            this.loginExternal(currentUser.type, currentUser.accessToken);
          });
        })
        .catch((err) => {
          console.log("app ctor", err);
          this.props.setPalletsCountrysAndLanguage(this.state.lang);
        });
    }, 1000);
  }

  styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
    },
    content: {
      flexGrow: 1,
      marginTop: "47px",
    },
    contentTablet: {
      flexGrow: 1,
      marginTop: "56px",
    },
    footer: {},
    loading: {
      textAlign: "center",
      marginTop: "100px",
    },
  };

  checkUserVatZero = (token) => {
    RestRequest("get", "secured/vatZero", {}, this.handleVatZeroStatus, token);
  };

  handleVatZeroStatus = (res) => {
    console.log("vat zero status", res);
    this.props.setUserVatZero(res.body.isVatZero);
  };

  loginExternal(type, token) {
    console.log(type, token);
    if (type === "Facebook") {
      getFacebook(token, this.facebookResponse);
    } else if (type === "Google") {
      getGoogle(token, this.googleResponse);
    } else {
      this.props.setPalletsCountrysAndLanguage(this.state.lang);
    }
  }

  facebookResponse(res) {
    console.log("Facebook res", res);

    if (res.error) {
      this.logout();
      return;
    }
    var emailPermision = res.body.data.find((x) => {
      return x.permission === "email";
    });
    if (emailPermision.status !== "granted") {
      this.logout();
      return;
    }
    this.login();
  }

  googleResponse(res) {
    console.log("Google res", res);
    if (res.error) {
      this.logout();
      return;
    }
    this.login();
  }

  logout() {
    console.log("logout");
    this.props.setLogin(null, null, null, null, null, null);
    this.props.setPalletsCountrysAndLanguage(this.state.lang);
  }

  login() {
    this.props.setLogin(
      this.state.user.login,
      this.state.user.token,
      this.state.user.id,
      this.state.user.isAdmin,
      this.state.user.type,
      this.state.user.accessToken
    );
    this.checkUserVatZero(this.state.user.token);
    this.props.setPalletsCountrysAndLanguage(this.state.lang);
  }

  UserSite() {
    return (
      <div style={this.styles.container}>
        <div>
          <HeaderBarFull pathName={this.props.location.pathname} />
        </div>
        <div
          style={mt.isDesktop ? this.styles.content : this.styles.contentTablet}
        >
          <Body />
        </div>
        <div style={this.styles.footer}>
          <FooterBar />
        </div>
      </div>
    );
  }

  AdminSite() {
    return (
      <div style={this.styles.container}>
        <div>
          <HeaderBarFullAdmin />
        </div>
        <div style={this.styles.content}>
          <BodyAdmin />
        </div>
      </div>
    );
  }

  getPopup() {
    if (this.props.popup) {
      return <Popup />;
    }
    return "";
  }

  getContent() {
    // if (this.state.error) {
    //   return <Error />;
    // }
    // if (this.state.loading) {
    //   return (
    //     <div style={this.styles.loading}>
    //       <CircularProgress color="primary" />
    //     </div>
    //   );
    // }

    const isPanel = this.props.location.pathname.startsWith("/panel");
    if (!this.state.loading) {
      return (
        <div>
          {isPanel ? this.AdminSite() : this.UserSite()}
          {this.getPopup()}
        </div>
      );
    }
  }

  render() {
    console.log("render app", mt.type);

    document.body.style.backgroundColor = this.props.theme.palette.background.default;
    return (
      <div>
        {/* <div style={{
					position: 'fixed',
					zIndex:'100000',
					right: 100,
					top: 0,
				}}>
					{mt.type}
				</div> */}
        <ToastContainer autoClose={10000} />
        {this.getContent()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang,
    countrys: state.countrys,
    pallets: state.pallets,
    popup: state.page.popup,
    error: state.error,
    translations: state.translations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPalletsCountrysAndLanguage: (lang) => {
      dispatch(setPalletsCountrysAndLanguage(lang));
    },
    setLogin: (x, y, z, q, t, a) => {
      dispatch(setLogin(x, y, z, q, t, a));
    },
    setPage: (page) => {
      dispatch(setPage(page));
    },
    setUserVatZero: (x) => {
      dispatch(setUserVatZero(x));
    },
  };
};

export default withTheme()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
);
