import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from '../../Components/style';
import MediaType from '../../libs/MediaType';
import { Paper, Grid, Typography, Button, CircularProgress } from '@material-ui/core';
import TextInput from '../../Components/Commons/TextInput';
import { TextInputType } from '../../Components/Commons/Const/InputTypes';
import Translations from 'Singleton/Translations'
import queryString from 'query-string'
import RestRequest from '../../libs/RestRequest';
import sha256 from 'sha256';
import IconText from '../../Components/Commons/IconText'
import { MetaData, handleMetaData } from '../../Components/Commons/Const/MetaData'


export default class ResetPassword extends Component {

	state = {
		password: '',
		password2: '',
		isRequestSended: false,
		isResponse: false,
		responseError: false,
	}

	componentWillMount() {
		document.title = MetaData.mainPage.title;
		handleMetaData();
	}

	onChange = (id) => (value) => {
		this.setState({
			...this.state,
			[id]: value,
		})
	}

	isSamePassw = () => {
		if (!this.state.password2) {
			return true;
		}
		return this.state.password === this.state.password2
	}

	getTitleRow(title) {
		return (
			<Typography variant='headline'>
				{title}
			</Typography>
		)
	}

	handleClick = () => {
		let { token } = queryString.parse(this.props.location.search)
		console.log('token reset', token);

		this.setState({
			...this.state,
			isRequestSended: true,
		})

		RestRequest('post',
			'/password/reset',
			{ 'newPassword': sha256.x2(this.state.password) },
			this.handelResponse,
			token
		)
	}

	handelResponse = (res) => {
		console.log(res);
		this.setState({
			...this.state,
			isResponse: true,
			responseError: res.body.error
		})
	}

	getButtonText = () => {
		if (this.state.isRequestSended) {
			return <CircularProgress size={16} />
		}
		return Translations.get('set_password');
	}

	getEndProcessText = () => {
		return (
			<IconText
				type={this.state.responseError ? 'error' : 'done'}
				centered
			>
				{
					this.state.responseError ?
						Translations.get('something_went_wrong') :
						Translations.get('password_changed')
				}
			</IconText>
		)
	}

	getButtonComponent = () => {
		if (this.state.isResponse) {
			return this.getEndProcessText();
		}
		return (
			<Button
				variant='raised'
				color='primary'
				fullWidth
				onClick={this.handleClick}
				disabled={!this.isSamePassw() || !(this.state.passwordValid && this.state.password2Valid) || this.state.isRequestSended}
			>
				{this.getButtonText()}
			</Button>
		)
	}

	getContent = () => {
		return (
			<Grid
				container
				direction='column'
			>
				<Grid item style={{ width: '100%' }}>
					{this.getTitleRow('Ustaw nowe hasło Treo')}
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Typography variant='body1'>
						Wybierz nowe hasło do konta Treo, które będzie bezpieczne, a jednocześnie łatwe do zapamiętania.
					</Typography>
				</Grid>
				<Grid item>
					<TextInput
						id='password'
						type={TextInputType.PASSWORD}
						label={Translations.get('password')}
						onValidChange={this.onChange('passwordValid')}
						onChange={this.onChange('password')}
						disabled={this.state.isRequestSended}
					/>
				</Grid>
				<Grid item>
					<TextInput
						id='password2'
						onValidChange={this.onChange('password2Valid')}
						onChange={this.onChange('password2')}
						type={TextInputType.PASSWORD}
						label={Translations.get('repeat_password')}
						errorReason={Translations.get('password_must_matches')}
						isError={!this.isSamePassw()}
						disabled={this.state.isRequestSended}
					/>
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					{this.getButtonComponent()}
				</Grid>
			</Grid>
		)
	}

	render() {

		return (
			<div style={
				styles.flatPopupContainer
			}>
				<Paper
					style={styles.popupBox}
				>
					{this.getContent()}
				</Paper>
			</div>
		)
	}
}

ResetPassword.propTypes = {
}
