
export default class OrderStatus {
	static get NEW() {return 'new_ord';}
	static get AWAIT() {return 'await_ord';}
	static get IN_PROGRESS() {return 'in_progress_ord';}
	static get FORWARD() {return 'forward_ord';}
	static get FORWARD_AUTO() {return 'forward_auto_ord';}
	static get SENT_TO_SUPPLIER() {return 'sent_to_supplier';}
	static get TO_SUPPLY() {return 'to_supply_ord';}
	static get CANCELED() {return 'canceled_ord';}
	static get GET_ALL(){
		return [
			this.NEW,
			this.AWAIT,
			this.IN_PROGRESS,
			this.SENT_TO_SUPPLIER,
			this.FORWARD,
			this.TO_SUPPLY,
			this.CANCELED
		];
	}
}
