import React from 'react'
import { Grid, Typography } from "@material-ui/core";
import styles from 'Components/style'
import MediaType from "../../libs/MediaType";
import UE from "../../Resources/images/UE.png"
import { MetaData, handleMetaData } from '../../Components/Commons/Const/MetaData'
function Funding() {
    document.title = MetaData.mainPage.title
    handleMetaData();
    return (
        <Grid
            container
            style={
                MediaType.isMobile ?
                    styles.mobileContainer :
                    styles.container
            }
            direction='column'
        >
            {MediaType.isMobile ?
                '' :
                <Grid item style={styles.space30Vertical}>
                </Grid>
            }
            <Grid item>
                <Typography component="h1" variant='display1' paragraph>
                    {/* Dofinansowanie */}
                    Dofinansowanie i zamówienia publiczne
				</Typography>
            </Grid>
            <Grid item>
                <Typography variant='body1' paragraph>
                    Projekt współfinansowany ze środków Unii Europejskiej w ramach Europejskiego Funduszu Rozwoju Regionalnego, Program Operacyjny Polska Wschodnia na lata 2014-2020, oś priorytetowa I: Przedsiębiorcza Polska Wschodnia 2014-2020, działanie 1.1: Platformy startowe dla nowych pomysłów, poddziałanie 1.1.2: Rozwój startupów w Polsce Wschodniej.
            </Typography>
            </Grid>
            <Grid item>
                <Typography variant='body1' paragraph>
                    {/* Celem projektu jest wsparcie w początkowej fazie rozwoju w makroregionie Polski Wschodniej startupu Treo Sp. z o.o. związane z pierwszą sprzedażą produktu Treo w oparciu o przygotowany w ramach „Platformy Startowej TechnoParkBiznesHub” produkt o minimalnej koniecznej funkcjonalności (MVP) i przetestowany model biznesowy. */}
                    Celem projektu jest stworzenie portalu TREO, który będzie umożliwiał szybkie, korzystne cenowo i ogólnodostępne zlecenia transportu paletowego.
            </Typography>
            </Grid>
            <Grid item>
                <Typography variant='body1' paragraph>
                    {/* W rezultacie projektu wprowadzona zostanie do pierwszej sprzedaży innowacyjna usługa [UZUPEŁNIĆ]. */}
                    Efektem projektu ma być masowa zautomatyzowana sprzedaż usług logistycznych na terenie Polski i Unii Europejskiej.
            </Typography>
            </Grid>
            <Grid item>
                <Typography variant='body1' >
                    Wartość projektu: 746 875,00 PLN
			</Typography>
            </Grid>
            <Grid item>
                <Typography variant='body1' >
                    Wkład Europejskiego Funduszu Rozwoju Regionalnego: 560 065,00 PLN
			</Typography>
            </Grid>
            <Grid item>
                <a href="https://www.funduszeeuropejskie.gov.pl/" rel="nofollow" target="_blank">
                    {MediaType.isMobile ?
                        <img src={UE} style={{ width: "90vw", marginTop: 15 }} alt="European funds picture" />
                        :
                        <img src={UE} style={{ width: 670, height: 100, marginTop: 15 }} alt="European funds picture" />
                    }
                </a>
            </Grid>
        </Grid>
    )
}

export default Funding;