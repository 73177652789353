import React from 'react'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import styles from './../../style'
import Translations from 'Singleton/Translations'
import MediaType from '../../../libs/MediaType';
import Button from '@material-ui/core/Button';
import SwipeBlock from './SwipeBlock'
import Link from 'Components/Commons/Link';


const componentStyles = {
	container: {
		...styles.container,
		...styles.rootWeb,
		marginTop: '30px',
		marginBottom: '30px',
		padding: 0
	},
	containerTablet: {
		...styles.container,
		...styles.rootTablet,
		backgroundColor: '#fff',
		marginTop: '30px',
		marginBottom: '30px',
		padding: 0
	},
	containerMobile: {
		...styles.container,
		...styles.rootMobile,
		padding: 0
	},
	bgWhite: {
		backgroundColor: '#fff'
	},
	btnWhite: {
		backgroundColor: '#fff',
		marginBottom: "1.5rem",
	},
	icon: {
		width: '50px',
		height: '50px',
		margin: '22px 0',
	},
	title: {
		marginBottom: "3rem",
		color: "#fff"
	},
	title2: {
		marginBottom: '20px',
		fontSize: '1.3rem'

	},
	paragraph: {
		marginBottom: '40px',
		fontSize: '1rem',
		color: "#fff"
	},
	tile: {
		height: "100%",
		minHeight: "200px",
		backgroundPosition: "center",
		backgroundSize: "cover",
		padding: "1rem",
		display: "flex",
		alignItems: "flex-end"
	},
	tileLink: {
		color: "#fff",
		textDecoration: "underline",
		fontSize: "1.2rem",
		backgroundColor: "rgba(0,0,0,0.5)"
	},
	link: {
		color: "#fff",
		textDecoration: "underline",
	}
}


const Tile = (props) => (
	<Grid item xs={12} md={6} style={Object.assign({ height: "50%" }, componentStyles.tile, { backgroundImage: "url(" + props.bg + ")" })}>
		<Typography>
			<a href={props.link} style={componentStyles.tileLink} title={props.title}>{props.title}</a>
		</Typography>
	</Grid>
)


export class BlogBlock extends React.Component {



	getRootStyle = () => {
		switch (MediaType.type) {
			case MediaType.MOBILE: return componentStyles.containerMobile;
			case MediaType.TABLET: return componentStyles.containerTablet;
			default: return componentStyles.container;
		}
	}

	render() {

		return (
			<div>
				<div style={this.getRootStyle()}>
					<Grid container spacing={0} >
						<Grid item lg={6} style={Object.assign({}, componentStyles.tile, { alignItems: "stretch", backgroundImage: "url(/images/C_Users_micha_AppData_Local_Packages_Microsoft.SkypeApp_kzf8qxf38zg5c_LocalState_be6af32e-16e8-4aed-9aab-0e7075a21bfe-4be612.jpeg)" })}>
							<div style={{ padding: "1.5rem" }}>
								<Typography component="h2" variant='display1' align='left' style={componentStyles.title}>
									Wiedza, którą dzielimy się z Tobą
								</Typography>
								<Typography style={componentStyles.paragraph}>
									Nasza firma ma wieloletnie doświadczenie w handlu usługami przewozu palet dla klientów detalicznych. Dzięki współpracy z wieloma firmami działającymi w branży transportu możemy zapewnić wysoką jakość i szybkość realizacji naszych usług, przy zachowaniu ich niskiej ceny.
								</Typography>
								<Typography style={componentStyles.paragraph}>
									O tym jak pracujemy, czego się nauczyliśmy i jak dbamy o wysoką jakość i niską cenę naszych usług piszemy na naszym blogu.
								</Typography>
								<Button variant="contained" href={'/blog'} style={componentStyles.btnWhite}>Czytaj więcej na Blogu Treo</Button>
								<Typography style={componentStyles.paragraph}>
									Odwiedź nas też na <a href="https://www.facebook.com/wwwtreopl/" target="_blank" style={componentStyles.link} rel="nofollow">Facebook</a> i <a href="https://pl.linkedin.com/company/wwwtreopl" target="_blank" style={componentStyles.link} rel="nofollow">LinkedIn</a>.
								</Typography>
							</div>
						</Grid>

						<Grid item xs={12} lg={6}>
							<Grid container spacing={0} style={MediaType.type == MediaType.DESKTOP ? { height: "50%" } : {}}>
								<Tile key={'tile-1'} bg="/images/blog-2.jpg" link="/blog/dlaczego-warto-zautomatyzowac-transport-paletowy-w-sklepie-e-commerce" title="Dlaczego warto zautomatyzować transport paletowy w sklepie e-commerce?" />
								<Tile key={'tile-2'} bg="/images/blog-3.jpg" link="/blog/jak-blyskawicznie-wyslac-palete-towaru-do-niemiec" title="Jak błyskawicznie wysłać paletę towaru do Niemiec?" />
								<Tile key={'tile-3'} bg="/images/blog-4.jpg" link="/blog/eksportujesz-meble-automatyzacja-w-transporcie-paletowym-moze-zwiekszyc-twoja-sprzedaz-na-rynkach-ue" title="Eksportujesz meble? Automatyzacja w transporcie paletowym może zwiększyć Twoją sprzedaż na rynkach UE" />
								<Tile key={'tile-4'} bg="/images/blog-5.jpg" link="/blog/dla-jakich-branz-automatyczne-zlecanie-przesylek-paletowych" title="Dla jakich branż automatyczne zlecanie przesyłek paletowych?" />
							</Grid>
						</Grid>
					</Grid>
				</div>
				<div style={Object.assign({}, this.getRootStyle(), { backgroundColor: "transaprent" })}>
					<SwipeBlock
						title="Napisali o nas"
						icon="/images/envelope-open-text-solid.svg"
						itemTitle="Newseria Biznes"
						itemText={<p>Polskie startupy nie zawsze odnoszą sukces. Ale temu się udało. Treo zachwyca swoją usługą... <a href="">Czytaj więcej</a></p>}
					/>
				</div>
			</div>
		);
	}
}
