import React from "react";
import { connect } from "react-redux";
import styles from 'Components/style'
import { Grid, Typography, withStyles } from "@material-ui/core";

import Translations from 'Singleton/Translations'
import MediaType from "../../libs/MediaType";
import Zalnr1 from "../../Resources/Załącznik_nr1.pdf"
import { MetaData, handleMetaData } from '../../Components/Commons/Const/MetaData'
// const getElements = (props) => {
//     const collection = Object.keys(props.translations.translations)
//         .filter(item => item.startsWith('regulations_rule_'));
//     return (
//         <Grid
//             container
//             justify='space-between'
//         >
//             {
//                 collection.map((item, i) => {
//                     return (
//                         <Grid item
//                             key={i}
//                             style={
//                                 MediaType.isMobile ?
//                                     { marginTop: styles.space16.width } :
//                                     { marginTop: styles.space30.width }
//                             }>
//                             <Typography variant='body1'>
//                                 {Translations.get(item)}
//                             </Typography>
//                         </Grid>
//                     );
//                 })
//             }
//         </Grid>
//     );
// }
const style = theme => ({
    regulationsBox: {
        margin: `30px 0`,
        "&>ol": {
            marginBottom: 8,
        },
        '&>*': {
            fontFamily: "\"Open Sans\", \"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
        }
    },
    list: {
        marginLeft: 30,
        counterReset: "list",
        "&>li": {
            listStyle: "none",
        },
        "&>li:before": {
            content: `counter(list) ".)"`,
            counterIncrement: "list",
            marginRight: 10,
        }
    },
    list2: {
        marginLeft: 60,
        counterReset: "list",
        "&>li": {
            listStyle: "none",
            display: "block",
            counterIncrement: "item",
        },
        "&>li:before": {
            content: `counter(item,lower-alpha) ".)"`,
            // counterIncrement: "section",
            marginRight: 10,
        }
    }
})

const container = (props) => {
    document.title = MetaData.mainPage.title;
    handleMetaData();

    return (
        <Grid
            container
            direction='column'
            style={
                MediaType.isMobile ?
                    styles.mobileContainer :
                    styles.container
            }
        >
            {!MediaType.isMobile &&
                <Grid item style={
                    styles.space30Vertical
                }>
                </Grid>
            }
            <Grid item>
                <Typography variant='display1'>
                    {Translations.get('regulations_header') + " Treo"}
                </Typography>
            </Grid>
            {/* {getElements(props)} */}
            <Grid item className={props.classes.regulationsBox}>
                {/* <p align="center"><span><strong>Regulamin TREO</strong></span></p> */}
                <p align="center" style={{ marginBottom: 0 }}><span style={{}}><strong>&sect;1</strong></span></p>
                <p align="center"><span><strong>[postanowienia og&oacute;lne]</strong></span></p>
                <ol>
                    <li>
                        <p align="justify"><span>Niniejszy Regulamin, określa zasady świadczenia usług przez TREO Sp. z o.o. z siedzibą w Bielinach w ramach Serwisu internetowego prowadzonego pod adresem www.treo.pl.</span></p>
                    </li>
                </ol>
                <ol start="2">
                    <li>
                        <p align="justify"><span>Użytym w Regulaminie określeniom nadaje się następujące znaczenie:</span></p>
                    </li>
                </ol>
                <ol className={props.classes.list}>
                    <li>
                        <span style={{ textAlign: "justify" }}>Serwis &ndash; portal internetowy dostępny pod adresem www.treo.pl wraz z jego funkcjonalnością, w ramach kt&oacute;rego Użytkownicy mogą składać Usługodawcy drogą elektroniczną Zam&oacute;wienia.</span>
                    </li>
                    <li>
                        <span>Usługodawca lub Administrator - TREO Sp. z o.o. z siedzibą w Bielinach, ul. Kielecka 143, 26 &ndash; 004 Bieliny wpisana do Rejestru Przedsiębiorc&oacute;w KRS prowadzonego przez Sąd Rejonowy w Kielcach, X Wydział Gospodarczy Krajowego Rejestru Sądowego pod nr KRS: 0000645046, o kapitale zakładowym w wysokości 5.000,00 zł, posiadająca licencje na wykonywanie transportu drogowego w zakresie pośrednictwa przy przewozie rzeczy nr LS 0012804, </span><span>działająca jako Spedytor</span> <span>w rozumieniu ustawy z dnia 23 kwietnia 1964 r. Kodeks Cywilny (Dz.U.2017, poz. 459, z p&oacute;źn. zm.).</span>
                    </li>
                    <li>
                        <span>Użytkownik - osoba fizyczna posiadająca pełną zdolność do czynności prawnych, osoba prawna lub jednostka organizacyjna nie posiadająca osobowości prawnej, kt&oacute;rej ustawa przyznaje zdolność prawną, kt&oacute;ra zarejestrowała się w Serwisie.</span>
                    </li>
                    <li>
                        <span>Przewoźnik - przedsiębiorca, kt&oacute;ry w ramach prowadzonej działalności gospodarczej świadczy usługi przewozowe. </span><span>Pełna lista Przewoźnik&oacute;w stanowi </span><span><strong><a href={Zalnr1} download="Załącznik nr 1 do Regulaminu.pdf">Załącznik nr 1 do Regulaminu.</a></strong></span>
                    </li>
                    <li>
                        <span style={{ color: "#00000" }}><span>Nadawca &ndash; osoba fizyczna posiadająca pełną zdolność do czynności prawnych, osoba prawna, lub jednostka organizacyjna nieposiadająca osobowości prawnej, kt&oacute;rej ustawa przyznaje zdolność prawną, kt&oacute;ra przekazuje Przesyłkę Przewoźnikowi w celu wykonania Usługi.</span></span>
                    </li>
                    <li>
                        <span>Odbiorca &ndash; każdy podmiot, kt&oacute;ry przyjmuje przesyłkę doręczoną przez Przewoźnika na podstawie dokument&oacute;w przewozowych.</span>
                    </li>
                    <li>
                        <span>Umowa o świadczenie usług drogą elektroniczną (Umowa) &ndash; umowa zawierana z Użytkownikiem na podstawie, kt&oacute;rej Usługodawca świadczy na rzecz Użytkownika Usługi.</span>
                    </li>
                    <li>
                        <span>Usługa &ndash; wszelkie czynności faktyczne i prawne związane ze spedycją.</span>
                    </li>
                    <li>
                        <span>Zlecenie transportowe - wydanie Przewoźnikowi przez Usługodawcę polecenia wykonania odbioru, przemieszczenia i doręczenia Przesyłki zgodnie z treścią Umowy spedycji oraz w oparciu o regulaminu Przewoźnika. </span><span style={{ color: "#000000" }}><span>Zlecenie transportowe wykonywane jest w oparciu o postanowienia konwencji o umowie międzynarodowego przewozu drogowego towar&oacute;w (CMR) ) sporządzoną w Genewie dnia 19 maja 1956 r. (Dz.U. 1962 r. Nr 49, poz. 238, sprost. Dz. U z 1995 r. Nr 69, poz. 352)</span></span>
                    </li>
                    <li>
                        <span>Zam&oacute;wienie &ndash; zlecenie odbioru, przemieszczenia i doręczenia Przesyłki złożone Usługodawcy przez Użytkownika za pośrednictwem Serwisu.</span>
                    </li>
                    <li>
                        <span>Umowa spedycji &ndash; zobowiązanie Usługodawcy do wykonania odbioru, przemieszczenia i doręczenia Przesyłki zgodnie z Zam&oacute;wieniem i Użytkownika do zapłaty ceny.</span>
                    </li>
                    <li>
                        <span>Przesyłka &ndash; wszelkie rzeczy oddane do realizacji Usługi od jednego Użytkownika do jednego Odbiorcy, na podstawie dokumentu przewozowego.</span>
                    </li>
                    <li>
                        <span>Konto Użytkownika &ndash; udostępnione Użytkownikowi po dokonaniu rejestracji miejsce na serwerze, za pośrednictwem kt&oacute;rego Użytkownik, z zastrzeżeniem postanowień Regulaminu, uzyskuje możliwość składania Zam&oacute;wień oraz administrowania swoimi danymi osobowymi.</span>
                    </li>
                    <li>
                        <span>Panel Użytkownika &ndash; funkcja Serwisu umożliwiająca Użytkownikowi logowanie do Konta.</span>
                    </li>
                    <li>
                        <span>Dokumenty przewozowe &ndash; dokumenty zawierające szczeg&oacute;łowe dane dotyczące Nadawcy, Odbiorcy oraz Przesyłki, w szczeg&oacute;lności List przewozowy lub Etykieta.</span>
                    </li>
                    <li>
                        <span>Dzień roboczy &ndash; każdy dzień od poniedziałku do piątku, za wyjątkiem dni ustawowo wolnych od pracy. Do czasu transportu wyrażonego w dniach roboczych nie zalicza się dnia podjęcia Przesyłki.</span>
                    </li>
                    <li>
                        <span>Przyjęcie do realizacji &ndash; podjęcie czynności przez Usługodawcę, w celu wykonania Zam&oacute;wienia. Przyjęcie do realizacji nie jest r&oacute;wnoznaczne z odbiorem, przemieszczeniem lub doręczeniem Przesyłki.</span>
                    </li>
                    <li>
                        <span>Potwierdzenie przyjęcia Zam&oacute;wienia &ndash; informacja w formie elektronicznej wysyłana przez Usługodawcę do Użytkownika, potwierdzająca zawarcie Umowy spedycji.</span>
                    </li>
                    <li>
                        <span>OC Usługodawcy &ndash; ubezpieczenie odpowiedzialności cywilnej spedytora.</span>
                    </li>
                </ol>
                <p align="center" style={{ marginBottom: 0 }}><span style={{}}><strong>&sect;2</strong></span></p>
                <p align="center"><span><strong>[przedmiot świadczonych usług]</strong></span></p>
                <ol>
                    <li value="1">
                        <p align="justify"><span>Usługodawca w zakresie prowadzonej działalności gospodarczej świadczy za wynagrodzeniem w ramach Serwisu Usługi polegające na pośrednictwie w przewozie Przesyłek,</span> <span>umożliwiając Użytkownikom składanie drogą elektroniczną Zam&oacute;wień. </span></p>
                    </li>
                </ol>
                <ol start="2">
                    <li>
                        <p align="justify"><span>Wykonanie Zlecenia transportowego odbywa się na podstawie regulaminu Przewoźnika, kt&oacute;rego wyboru dokonał Usługodawca.</span></p>
                    </li>
                </ol>
                <ol start="3">
                    <li>
                        <p align="justify"><span>Usługodawca nie wykonuje przewozu Przesyłek.</span></p>
                    </li>
                </ol>
                <ol start="4">
                    <li>
                        <p align="justify"><span style={{ color: "#000000" }}><span>Po ustaleniu za pośrednictwem Serwisu wszystkich parametr&oacute;w Zam&oacute;wienia, Użytkownik może zlecić Usługodawcy odbi&oacute;r, przemieszczenie i doręczenie przesyłki, akceptując uprzednio regulaminy wszystkich Przewoźnik&oacute;w, o kt&oacute;rych mowa w załączniku nr 1 do Regulaminu. Usługodawca wydaje Zlecenie transportowe.</span></span></p>
                    </li>
                </ol>
                <ol start="5">
                    <li>
                        <p align="justify"><span style={{ color: "#000000" }}><span>W przypadku braku możliwości zrealizowania Zlecenia transportowego przez wybranego przez Usługodawcę Przewoźnika, Usługodawca wskazuje innego Przewoźnika, przy czym cena Zlecenia transportowego nie ulega zmianie. Użytkownik nie może się sprzeciwić wyborowi Usługodawcy. </span></span></p>
                    </li>
                </ol>
                <p align="center" style={{ marginBottom: 0 }}><span><strong>&sect;3</strong></span></p>
                <p align="center"><span><strong>[rejestracja Użytkownika i Panel Użytkownika]</strong></span></p>
                <ol>
                    <li value="1">
                        <p align="justify"><span>Warunkiem korzystania z Usług świadczonych za pośrednictwem Serwisu jest uprzednia rejestracja Użytkownika na stronie internetowej www.treo.pl. Do rejestracji dochodzi po wypełnieniu przez Użytkownika formularza rejestracyjnego.</span></p>
                    </li>
                </ol>
                <ol start="2">
                    <li>
                        <p align="justify"><span>Rejestracja w Serwisie wymaga zapoznania się i akceptacji przez Użytkownika postanowień Regulaminu. Poprzez akceptację Regulaminu Użytkownik wyraża zgodę na wszystkie postanowienia zawarte </span><span>w Regulaminie</span> <span>oraz zobowiązuje się do ich przestrzegania.</span></p>
                    </li>
                </ol>
                <ol start="3">
                    <li>
                        <p align="justify"><span>Rejestracja jest r&oacute;wnoznaczna z zawarciem przez Użytkownika umowy o świadczenie usług drogą elektroniczną z Usługodawcą w zakresie możliwości korzystania z funkcjonalności Serwisu. Umowa ta zawarta zostaje na czas nieokreślony.</span></p>
                    </li>
                </ol>
                <ol start="4">
                    <li>
                        <p align="justify"><span>Po zarejestrowaniu w Serwisie tworzone jest indywidualne Konto Użytkownika i udostępniany Panel Użytkownika, za pośrednictwem kt&oacute;rego Użytkownik uzyskuje możliwość składania </span><span>Zam&oacute;wień</span><span> oraz administrowania swoimi danymi osobowymi.</span></p>
                    </li>
                </ol>
                <ol start="5">
                    <li>
                        <p align="justify"><span>Użytkownik otrzymuje wiadomość potwierdzającą założenia Konta na adres e-mail wskazany w formularzu rejestracyjnym oraz indywidualny login i hasło umożliwiające zalogowanie się do Konta w Panelu Użytkownika.</span></p>
                    </li>
                </ol>
                <ol start="6">
                    <li>
                        <p align="justify"><span>Przy tworzeniu Konta w Serwisie Użytkownik może wyrazić zgodę na przesyłanie mu informacji handlowych dotyczących oferowanych przez Usługodawcę usług na adres e-mail wskazany w formularzu rejestracyjnym. Użytkownik w każdej chwili może cofnąć zgodę na przesyłanie mu przez Usługodawcę informacji handlowych. Cofnięcie zgody jest możliwe po zalogowaniu do indywidualnego konta w Panelu Użytkownika.</span></p>
                    </li>
                </ol>
                <ol start="7">
                    <li>
                        <p align="justify"><span>Użytkownik zobowiązany jest chronić przed osobami trzecimi dane dostępowe (login i hasło umożliwiające dostęp do Konta). Wszelkie Zam&oacute;wienia składane przy użyciu loginu i hasła Użytkownika traktuje się jak Zam&oacute;wienia Użytkownika.</span></p>
                    </li>
                </ol>
                <ol start="8">
                    <li>
                        <p align="justify"><span>Po zalogowaniu się na Koncie Użytkownik może zmienić swoje dane osobowe wskazane przy tworzeniu Konta. Użytkownik ponosi pełną odpowiedzialność za prawidłowość danych przekazanych Usługodawcy w procesie rejestracji i skutki podania nieprawidłowych danych oraz jest zobowiązany do ich każdorazowej aktualizacji w przypadku ich zmiany.</span></p>
                    </li>
                </ol>
                <ol start="9">
                    <li>
                        <p align="justify"><span>Użytkownik może posiadać tylko jedno Konto.</span></p>
                    </li>
                </ol>
                <ol start="10">
                    <li>
                        <p align="justify"><span>Bez dokonania rejestracji nie jest możliwe korzystanie z Serwisu.</span></p>
                    </li>
                </ol>
                <ol start="11">
                    <li>
                        <p align="justify"><span>Rejestracja w Serwisie jest bezpłatna.</span></p>
                    </li>
                </ol>
                <ol start="12">
                    <li>
                        <p align="justify"><span>Usługodawca udostępnia Użytkownikowi w ramach Konta wszystkie dodatkowe mechanizmy aktualnie dostępne w Serwisie, obejmujące m.in.: śledzenie nadanych Przesyłek, powiadamianie o zmianie statusu Przesyłki itp.</span></p>
                    </li>
                </ol>
                <ol start="13">
                    <li>
                        <p align="justify"><span>Usługodawca zastrzega sobie możliwość zablokowania dla danego Konta możliwości składania Zam&oacute;wień. Konto Użytkownika może zostać zamknięte, jeżeli Użytkownik nie przestrzega postanowień Regulaminu bądź regulamin&oacute;w Przewoźnik&oacute;w, albo istnieje uzasadnione podejrzenie, że bezpieczeństwo danego Konta zostało zagrożone. Użytkownikowi przysługuje prawo odwołania się od </span><span>decyzji o zablokowaniu albo o zamknięciu Konta.</span></p>
                    </li>
                </ol>
                <ol start="14">
                    <li>
                        <p align="justify"><span>Do czasu odblokowania Konta, Użytkownik nie może korzystać z funkcjonalności Serwisu, za wyjątkiem administrowania swoimi danymi osobowymi.</span></p>
                    </li>
                </ol>
                <p align="center" style={{ marginBottom: 0 }}><span><strong>&sect;4</strong></span></p>
                <p align="center"><span><strong>[zam&oacute;wienia]</strong></span></p>
                <ol>
                    <li value="1">
                        <p align="justify"><span style={{ color: "#000000" }}><span>W celu złożenia Zam&oacute;wienia Użytkownik powinien zalogować się do Konta w Panelu Użytkownika, wypełnić wszystkie pola formularza zam&oacute;wienia i przesłać go do Usługodawcy poprzez wyb&oacute;r opcji &bdquo;wyślij&rdquo;. Usługodawca wskazuje Użytkownikowi cenę za wykonanie Zam&oacute;wienia wraz z informacją o cenie ubezpieczenia Przesyłki. Użytkownik zatwierdza cenę za wykonanie Zam&oacute;wienia lub cenę za ubezpieczenie Przesyłki, poprzez wyb&oacute;r odpowiedniej opcji &bdquo;zam&oacute;w i zapłać&rdquo;.</span></span></p>
                    </li>
                </ol>
                <ol start="2">
                    <li>
                        <p align="justify"><span style={{ color: "#000000" }}><span>Złożenie Zam&oacute;wienia wymaga zapoznania się i akceptacji przez Użytkownika wszystkich regulamin&oacute;w Przewoźnik&oacute;w, wymienionych w załączniku nr 1 do Regulaminu. Akceptując regulaminy Przewoźnik&oacute;w, Użytkownik wyraża zgodę na wszystkie ich postanowienia i zobowiązuje się do przestrzegania regulaminu tego Przewoźnika, kt&oacute;ry wykonywał będzie Zlecenie transportowe. </span></span></p>
                    </li>
                </ol>
                <ol start="3">
                    <li>
                        <p align="justify"><span style={{ color: "#000000" }}><span>Z chwilą wciśnięcia opcji &bdquo;zam&oacute;w i zapłać&rdquo; Zam&oacute;wienie zostaje zatwierdzone i dochodzi między Użytkownikiem a Usługodawcą do zawarcia Umowy spedycji. Przyjmuje się, że zatwierdzenie Zam&oacute;wienia jest r&oacute;wnoznaczne z potwierdzeniem przez Użytkownika, że zapoznał się i akceptuje regulaminy Przewoźnik&oacute;w, wskazanych w załączniku nr 1 do Regulaminu.</span></span></p>
                    </li>
                </ol>
                <ol start="4">
                    <li>
                        <p align="justify"><span>Wykonanie Zam&oacute;wienia uzależnione jest od zapłaty przez Użytkownika ceny. Użytkownik dokonuje zapłaty za pośrednictwem systemu Przelewy24.</span></p>
                    </li>
                </ol>
                <ol start="5">
                    <li>
                        <p align="justify"><span style={{ color: "#000000" }}><span>Po zapłacie ceny, Użytkownik powiadomiony zostaje, poprzez Konto w Panelu Użytkownika o Przewoźniku, kt&oacute;ry wykonywał będzie Zlecenie transportowe.</span></span></p>
                    </li>
                </ol>
                <ol start="6">
                    <li>
                        <p align="justify"><span style={{ color: "#000000" }}><span>Po złożeniu Zam&oacute;wienia, Użytkownik powiadomiony zostaje, poprzez Konto w Panelu Użytkownika o zawarciu Umowy spedycji.</span></span></p>
                    </li>
                </ol>
                <ol start="7">
                    <li>
                        <p align="justify"><span>Wraz z potwierdzeniem, o kt&oacute;rym mowa w ust. 6 Użytkownikowi może zostać wygenerowany przez Serwis dokument przewozowy. Użytkownik jest zobowiązany do wydrukowania dokument&oacute;w przewozowych przekazanych za pośrednictwem Serwisu i postępowania zgodnie z wysłanymi Użytkownikowi instrukcjami. Jeden egzemplarz wydruku podpisany przez pracownika Przewoźnika Użytkownik zobowiązany jest zachować przez okres 1 roku od daty nadania Przesyłki, na potrzeby ewentualnego postępowania reklamacyjnego.</span></p>
                    </li>
                </ol>
                <ol start="8">
                    <li>
                        <p align="justify"><span>Użytkownik zobowiązany jest do sprawdzenia poprawności danych na otrzymanym dokumencie przewozowym. Użytkownik ponosi wyłączną odpowiedzialność za poprawność danych podanych przez niego na dokumencie przewozowym.</span></p>
                    </li>
                </ol>
                <ol start="9">
                    <li>
                        <p align="justify"><span>Zam&oacute;wienia można składać</span> <span>24 godziny na dobę, 7 dni w tygodniu, przy czym zam&oacute;wienia składane w soboty, niedziele oraz dni ustawowo wolne od pracy będą przyjmowane do realizacji w najbliższym następującym Dniu roboczym.</span></p>
                    </li>
                </ol>
                <ol start="10">
                    <li>
                        <p align="justify"><span>Użytkownik odpowiada za prawidłowość danych wskazanych w formularzu zam&oacute;wienia, a w szczeg&oacute;lności za informacje dotyczące wagi, wymiar&oacute;w, wartości oraz zawartości Przesyłki. Przewoźnikowi przysługuje prawo weryfikacji każdej Przesyłki pod względem wagi oraz rozmiar&oacute;w. W przypadku stwierdzenia, że zadeklarowane przez Użytkownika </span><span>parametry </span><span>Przesyłki są niezgodne ze stanem rzeczywistym, Zlecenie transportowe zostanie wykonane, jednakże Użytkownik zostanie obciążony dodatkowymi opłatami, kt&oacute;re zgodnie z regulaminem Przewoźnika zostaną nałożone na Usługodawcę.</span></p>
                    </li>
                </ol>
                <ol start="11">
                    <li>
                        <p align="justify"><span>Jeżeli parametry Przesyłki okażą się niezgodne ze wskazanymi w formularzu zam&oacute;wienia w stopniu uniemożliwiającym wykonanie Zlecenia transportowego przez wskazanego przez Usługodawcę Przewoźnika, Użytkownikowi nie będzie przysługiwał zwrot ceny od Usługodawcy za niewykonanie Zam&oacute;wienia pomniejszony o koszty związane z pr&oacute;bą odbioru Przesyłki.</span></p>
                    </li>
                </ol>
                <ol start="12">
                    <li>
                        <p align="justify"><span>Użytkownik ponosi wyłączną odpowiedzialność za ubezpieczenie Przesyłki. W przypadku zadeklarowania wartości przesyłki Użytkownik zleca Usługodawcy ubezpieczenie przesyłki do wysokości podanej w formularzu zam&oacute;wienia. Wypłata odszkodowania uzależniona jest od wartości faktur lub rachunk&oacute;w na ubezpieczoną Przesyłkę.</span></p>
                    </li>
                </ol>
                <ol start="13">
                    <li>
                        <p align="justify"><span style={{ color: "#000000" }}><span>W przypadku, gdy Przewoźnik z jakiegokolwiek powodu leżącego po stronie Nadawcy, podjął bezskuteczną pr&oacute;bę odbioru Przesyłki i w oparciu o to zdarzenie, na podstawie regulaminu Przewoźnika, nałożył opłatę dodatkową na Usługodawcę, Nadawca zobowiązany będzie do zwrotu Usługodawcy kwoty odpowiadającej wysokości opłaty dodatkowej.</span></span></p>
                    </li>
                </ol>
                <ol start="14">
                    <li>
                        <p align="justify"><span style={{ color: "#000000" }}><span>Podstawę do zwrotu Usługodawcy kwoty odpowiadającej wysokości należności finansowej, stanowi informacja przesłana przez Przewoźnika o nałożeniu ww. należności, na kt&oacute;rą składają się w szczeg&oacute;lności kary, opłaty dodatkowe i dopłaty, w związku z wykonywaniem określonego Zlecenia transportowego.</span></span></p>
                    </li>
                </ol>
                <ol start="15">
                    <li>
                        <p align="justify"><span>Przesyłka powinna być zapakowana i zabezpieczona w taki spos&oacute;b, aby uniemożliwić uszkodzenie jej zawartości, jak i innych Przesyłek przewożonych przez Przewoźnika. Zasady pakowania Przesyłek znajdują się w poradnikach lub regulaminach Przewoźnik&oacute;w.</span></p>
                    </li>
                </ol>
                <ol start="16">
                    <li>
                        <p align="justify"><span>W przypadku braku możliwości wykonania Zam&oacute;wienia z powodu niedostępności usług lub nieobsługiwanych lokalizacji, Usługodawca po poinformowaniu o tym Użytkownika ma prawo odm&oacute;wić realizacji Zam&oacute;wienia.</span></p>
                    </li>
                </ol>
                <ol start="17">
                    <li>
                        <p align="justify"><span>Użytkownik zobowiązuje się przekazać, na każde uzasadnione żądanie Usługodawcy, wszelkie informacje niezbędne do realizacji Zam&oacute;wienia.</span></p>
                    </li>
                </ol>
                <ol start="18">
                    <li>
                        <p align="justify"><span>Odpowiedzialność Usługodawcy z tytułu niewykonania lub nienależytego wykonania Usługi lub realizacji Zam&oacute;wienia w zakresie czynności dodatkowych, poza organizacją transportu Przesyłki, nie może być wyższa niż dwukrotna kwota wynagrodzenia pobierana za Usługę, w związku z kt&oacute;rą powstała szkoda.</span></p>
                    </li>
                </ol>
                <ol start="19">
                    <li>
                        <p align="justify"><span>Przyjęcie Zam&oacute;wienia nie wiąże Usługodawcy w sytuacji, gdy z przyczyn od niego niezależnych, w szczeg&oacute;lności siły wyższej, albo zachowania Użytkownika lub os&oacute;b trzecich, realizacja Zam&oacute;wienia nie jest możliwa lub nadmiernie utrudniona.</span></p>
                    </li>
                </ol>
                <p align="center" style={{ marginBottom: 0 }}><span><strong>&sect;5</strong></span></p>
                <p align="center"><span><strong>[ceny i warunki płatności]</strong></span></p>
                <ol>
                    <li value="1">
                        <p align="justify"><span>Prowadzenie Konta Użytkownika w Serwisie jest bezpłatne.</span></p>
                    </li>
                </ol>
                <ol start="2">
                    <li>
                        <p align="justify"><span>Za wykonanie Zam&oacute;wienia Usługodawca pobiera wynagrodzenie. Wynagrodzenie jest ustalanie na podstawie dokonanej wyceny, kt&oacute;rej kwota widnieje w potwierdzeniu Zam&oacute;wienia na Koncie Użytkownika.</span></p>
                    </li>
                </ol>
                <ol start="3">
                    <li>
                        <p align="justify"><span>Zapłata za wykonanie Zam&oacute;wienia pobierana jest przed przystąpieniem do realizacji Zam&oacute;wienia za pośrednictwem bezpiecznej platformy płatności internetowych, kt&oacute;rej operatorem jest PayPro SA, 60-327 Poznań, ul. Kanclerska 15, NIP 779-236-98-87, REGON 301345068.</span></p>
                    </li>
                </ol>
                <ol start="4">
                    <li>
                        <p align="justify"><span>Cena za wykonanie Zam&oacute;wienia obliczana jest indywidualnie na podstawie warunk&oacute;w handlowych obowiązujących pomiędzy Usługodawcą i Przewoźnikami, z kt&oacute;rymi wsp&oacute;łpracuje.</span></p>
                    </li>
                </ol>
                <ol start="5">
                    <li>
                        <p align="justify"><span>Użytkownik zobowiązuje się zwr&oacute;cić Usługodawcy wszelkie koszty i wydatki, jakie Usługodawca poni&oacute;sł w związku z realizacją Zam&oacute;wienia, w oparciu o dokumenty potwierdzające ich poniesienie, jak r&oacute;wnież do zapłaty wynagrodzenia, o kt&oacute;rym mowa w ust. 2 tego paragrafu.</span></p>
                    </li>
                </ol>
                <ol start="6">
                    <li>
                        <p align="justify"><span>Zapłata wynagrodzenia nastąpi przelewem na rachunek Usługodawcy wskazany na fakturze VAT w terminie 7 dni.</span></p>
                    </li>
                </ol>
                <ol start="7">
                    <li>
                        <p align="justify"><span>Za dzień zapłaty przyjmuje się dzień uznania rachunku bankowego Usługodawcy.</span></p>
                    </li>
                </ol>
                <ol start="8">
                    <li>
                        <p align="justify"><span style={{ color: "#000000" }}><span>Wynagrodzenie wyrażone jest w walucie obcej przeliczonej na PLN. Jako przelicznik stosuje się kurs NBP (kurs kupna i sprzedaży - tabela C) obowiązujący na dzień zawarcia Umowy Spedycji.</span></span></p>
                    </li>
                </ol>
                <ol start="9">
                    <li>
                        <p align="justify"><span>Użytkownik może wybrać opcję doręczenia faktury VAT za wykonane Zam&oacute;wienie. Użytkownik wyraża zgodę na wystawienie faktury w formie elektronicznej oraz przesłanie jej na adres e-mail Użytkownika podany w formularzu rejestracyjnym. Faktura wystawiana jest po dokonaniu zapłaty za złożone Zam&oacute;wienie.</span></p>
                    </li>
                </ol>
                <ol start="10">
                    <li>
                        <p align="justify"><span>W przypadku rezygnacji przez Użytkownika z przesyłania mu faktur VAT drogą elektroniczną, faktury VAT będą wysyłane drogą pocztową na adres wskazany przez Użytkownika podczas rejestracji w Serwisie. Otrzymywanie faktur VAT drogą pocztową związane jest z dodatkową opłatą, kt&oacute;rej wysokość podawana jest Użytkownikowi podczas wyboru tej opcji w Serwisie.</span></p>
                    </li>
                </ol>
                <ol start="11">
                    <li>
                        <p align="justify"><span>W przypadku, gdy wykonanie Zlecenia transportowego przez Przewoźnika wymaga dopłaty, w związku z wprowadzeniem błędnych parametr&oacute;w do formularza zam&oacute;wienia, Użytkownik zostanie obciążony dodatkową opłatą, zgodnie z algorytmem stosowanym do obliczenia ceny za wykonanie Zam&oacute;wienia. Płatność następuje na postawie faktury VAT wygenerowanej przez Serwis.</span></p>
                    </li>
                </ol>
                <ol start="12">
                    <li>
                        <p align="justify"><span>Zwrot wpłaconej ceny stosownie do okoliczności odbywa się na podstawie korekty faktury VAT.</span></p>
                    </li>
                </ol>
                <ol start="13">
                    <li>
                        <p align="justify"><span>Usługodawca zastrzega sobie prawo do kompensaty przysługujących mu należności ze środkami pieniężnymi Użytkownika będących w posiadaniu Usługodawcy.</span></p>
                    </li>
                </ol>
                <p align="center" style={{ marginBottom: 0 }}><span><strong>&sect;6</strong></span></p>
                <p align="center"><span><strong>[odbi&oacute;r i doręczanie przesyłek]</strong></span></p>
                <ol>
                    <li value="1">
                        <p align="justify"><span>Odbiory Przesyłek dokonywane są w Dni robocze, w godzinach określonych w potwierdzeniu Zam&oacute;wienia. Godzina odbioru Przesyłki podana w potwierdzeniu Zam&oacute;wienia wskazuje przybliżony termin przyjazdu Przewoźnika i może ulec zmianie.</span></p>
                    </li>
                </ol>
                <ol start="2">
                    <li>
                        <p align="justify"><span>List przewozowy i Etykieta generowane są Użytkownikowi w formie elektronicznej w Serwisie na Koncie Użytkownika lub dostarczane przez Przewoźnika. Pozostałe dokumenty niezbędne do realizacji złożonego Zam&oacute;wienia, Użytkownik zobowiązany jest zapewnić we własnym zakresie.</span></p>
                    </li>
                </ol>
                <ol start="3">
                    <li>
                        <p align="justify"><span>Podczas odbioru Przesyłki Użytkownik zobowiązany jest do przekazania Przewoźnikowi wydrukowanych dokument&oacute;w, kt&oacute;re zostały mu przekazane za pośrednictwem Serwisu.</span></p>
                    </li>
                </ol>
                <ol start="4">
                    <li>
                        <p align="justify"><span>Doręczanie przesyłek odbywa się na zasadach określonych odpowiednio w regulaminie Przewoźnika wybranego przez Usługodawcę do wykonania Zlecenia transportowego.</span></p>
                    </li>
                </ol>
                <ol start="5">
                    <li>
                        <p align="justify"><span style={{ color: "#000000" }}><span>W przypadku braku możliwości doręczenia Przesyłki do Odbiorcy zwracana ona będzie do Użytkownika. W&oacute;wczas Użytkownik będzie zobowiązany do uiszczenia dodatkowej opłaty, w oparciu o informację, o kt&oacuterej mowa w &sect; 4 ust. 14.</span></span></p>
                    </li>
                </ol>
                <ol start="6">
                    <li>
                        <p align="justify"><span>Przewoźnik może odm&oacute;wić przyjęcia do przewozu rzeczy, kt&oacute;rych stan jest wadliwy lub opakowanie niedostateczne albo niemających wymaganego opakowania. Jeżeli cechy Przesyłki uniemożliwiające wykonanie Zlecenia transportowego ujawniły się po jej odbiorze od Użytkownika, Przewoźnik może usunąć stan uniemożliwiający nadanie Przesyłki, w szczeg&oacute;lności poprzez jej właściwe opakowanie lub zabezpieczenie na koszt Użytkownika.</span></p>
                    </li>
                </ol>
                <ol start="7">
                    <li>
                        <p align="justify"><span>Odpowiednie przygotowanie Przesyłki do transportu, w szczeg&oacute;lności prawidłowe opakowanie jest obowiązkiem Użytkownika i Usługodawca nie ponosi odpowiedzialności za należyte wykonanie tych czynności.</span></p>
                    </li>
                </ol>
                <ol start="8">
                    <li>
                        <p align="justify"><span style={{ color: "#000000" }}><span>Użytkownik zobowiązany jest do zapłaty opłat dodatkowych za magazynowanie Przesyłki w związku z nieodebraniem Przesyłki. </span></span><span ></span></p>
                    </li>
                </ol>
                <p align="center" style={{ marginBottom: 0 }}><span><strong>&sect;7</strong></span></p>
                <p align="center"><span><strong>[reklamacje i postępowanie reklamacyjne]</strong></span></p>
                <ol>
                    <li value="1">
                        <p align="justify"><span>Reklamacje związane z nieprawidłowym wykonaniem Usługi lub Zam&oacute;wienia, Użytkownik zgłasza Usługodawcy.</span></p>
                    </li>
                </ol>
                <ol start="2">
                    <li>
                        <p align="justify"><span>Podstawą dla określenia wartości Przesyłki, w związku z postępowaniem reklamacyjnym, jest posiadanie przez Użytkownika rachunku lub faktury Vat.</span></p>
                    </li>
                </ol>
                <ol start="3">
                    <li>
                        <p align="justify"><span>Wniesienie reklamacji następuje za pośrednictwem:</span></p>
                    </li>
                </ol>
                <ol className={props.classes.list}>
                    <li>
                        <span style={{ textAlign: "justify" }}>poczty elektronicznej na adres e-mail: start@treo.pl,</span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>pisemnie na adres siedziby Usługodawcy.</span>
                    </li>
                </ol>
                <ol>
                    <li>
                        <p align="justify"><span>Usługodawca udziela odpowiedzi na reklamacje Użytkownika będącego konsumentem w terminie 30 dni, licząc od dnia otrzymania kompletnej reklamacji. W pozostałych przypadkach termin na udzielenie odpowiedzi na reklamację wynosi 4 miesiące, licząc od dnia otrzymania kompletnej reklamacji.</span></p>
                    </li>
                </ol>
                <ol start="2">
                    <li>
                        <p align="justify"><span>Jeżeli reklamacja nie spełnia warunk&oacute;w określonych w ust. 6, Usługodawca wzywa Użytkownika, aby usunął braki w terminie 14 dni od dnia otrzymania wezwania, z pouczeniem, że nieuzupełnienie brak&oacute;w w tym terminie spowoduje pozostawienie reklamacji bez rozpoznania. W&oacute;wczas za datę wniesienia reklamacji przyjmuje się datę otrzymania przez Usługodawcę uzupełnionej reklamacji.</span></p>
                    </li>
                </ol>
                <ol start="3">
                    <li>
                        <p align="justify"><span>Reklamacja jest uważana za kompletną w przypadku, gdy zawiera, co najmniej:</span></p>
                    </li>
                </ol>
                <ol className={props.classes.list}>
                    <li value="1">
                        <span style={{ textAlign: "justify" }}>dane identyfikacyjne Użytkownika,</span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>numer Zam&oacute;wienia lub inne dane określające działanie Usługodawcy pozwalające na identyfikację reklamowanego Zam&oacute;wienia,</span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>określenie, jakiego naruszenia dotyczy reklamacja,</span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>roszczenie Użytkownika.</span>
                    </li>
                </ol>
                <ol>
                    <li>
                        <p align="justify"><span>W przypadku zgłoszenia reklamacji na Zam&oacute;wienie, w związku z wykonywaniem Zlecenia transportowego, Usługodawca podejmie czynności potrzebne do zabezpieczenia praw Użytkownika.</span></p>
                    </li>
                </ol>
                <ol start="2">
                    <li>
                        <p align="justify"><span>Usługodawca niezwłocznie przekazuje reklamacje oraz uwagi Przewoźnikowi do rozpoznania. Reklamacje oraz uwagi dotyczące Zlecenia transportowego przez Przewoźnika, będą rozpoznawane zgodnie z regulaminem wybranego do wykonania Zlecenia transportowego Przewoźnika.</span></p>
                    </li>
                </ol>

                <p align="center" style={{ marginBottom: 0 }}><span><strong>&sect;8</strong></span></p>
                <p align="center"><span><strong>[odpowiedzialność]</strong></span></p>
                <ol>
                    <li value="1">
                        <p align="justify"><span>Usługodawca ponosi wobec Użytkownika odpowiedzialność za prawidłowe wykonanie Usługi, tj. prawidłowe działanie Serwisu oraz Zam&oacute;wienia. Usługodawca ponosi odpowiedzialność, o ile bezwzględnie obowiązujące przepisy prawa nie stanowią inaczej, tylko za normalne następstwa działania lub zaniechania, z kt&oacute;rego szkoda wynikła.</span></p>
                    </li>
                </ol>
                <ol start="2">
                    <li>
                        <p align="justify"><span>Usługodawca ponosi wobec Użytkownika odpowiedzialności za nienależyte wykonanie Zlecenia transportowego przez Przewoźnika.</span></p>
                    </li>
                </ol>
                <ol start="3">
                    <li>
                        <p align="justify"><span>Umowa o wykonanie Zlecenia transportowego, na warunkach wynikających ze złożonego przez Użytkownika Zam&oacute;wienia wiąże wyłącznie Usługodawcę i Przewoźnika.</span></p>
                    </li>
                </ol>
                <ol start="4">
                    <li>
                        <p align="justify"><span>Usługodawca nie ponosi odpowiedzialności za utratę, ubytek lub uszkodzenie albo op&oacute;źnienie w przewozie Przesyłki jeżeli powstały one z przyczyn występujących po stronie Nadawcy lub Odbiorcy, z właściwości towaru albo wskutek siły wyższej.</span></p>
                    </li>
                </ol>
                <ol start="5">
                    <li>
                        <p align="justify"><span>Usługodawca nie ponosi odpowiedzialności za działania i skutki działań Użytkownika podjętych w związku z Zam&oacute;wieniem, w szczeg&oacute;lności Usługodawca nie ponosi odpowiedzialności za:</span></p>
                    </li>
                </ol>
                <ol className={props.classes.list}>
                    <li value="1">
                        <span style={{ textAlign: "justify" }}>podanie przez Użytkownika nieprawidłowych lub nieprawdziwych danych, w tym danych związanych z parametrami Przesyłki,</span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>nieprzekazanie przesyłki Przewoźnikowi,</span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>naruszenie przez Użytkownika regulaminu Przewoźnika.</span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>naruszenie przez Użytkownika Regulaminu.</span>
                    </li>
                </ol>
                <ol>
                    <li>
                        <p align="justify"><span>Usługodawca nie ponosi odpowiedzialności za działania Odbiorcy, w szczeg&oacute;lności za przyjęcie kierowanej do </span><span>Odbiorcy</span><span> Przesyłki.</span></p>
                    </li>
                </ol>
                <p align="center" style={{ marginBottom: 0 }}><span style={{}}><strong>&sect;9</strong></span></p>
                <p align="center"><span><strong>[wyłączenia z przewozu]</strong></span></p>
                <ol>
                    <li value="1">
                        <p align="justify"><span>Usługodawca nie przyjmuje Zam&oacute;wień dotyczących następujących towar&oacute;w lub przedmiot&oacute;w:</span></p>
                    </li>
                </ol>
                <ol className={props.classes.list}>
                    <li value="1">
                        <span style={{ textAlign: "justify" }}>uzbrojenia, broni, amunicji</span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>towar&oacute;w o znaczeniu strategicznym i podw&oacute;jnego zastosowania w rozumieniu przepis&oacute;w ustawy z dnia 29 listopada 2000 r. o obrocie z zagranicą towarami, technologiami i usługami o znaczeniu strategicznym dla bezpieczeństwa państwa, a także dla utrzymania międzynarodowego pokoju i bezpieczeństwa (tekst jednolity: Dz.U.2017, poz. 1050, z p&oacute;źn. zm.),</span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>towar&oacute;w niebezpiecznych (ADR):</span>
                    </li>
                </ol>
                <ol type="a" className={props.classes.list2}>
                    <li value="1">
                        <span style={{ textAlign: "justify" }}>Klasa 1 Materiały i przedmioty wybuchowe,</span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>Klasa 2 Gazy i aerozole trujące z nalepkami 2.3, Gazy i aerozole palne z nalepkami 2.1 w opakowaniach powyżej 500 ml.,</span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>Klasa 3 Materiały wybuchowe ciekłe odczulone (kod klasyfikacyjny D),</span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>Klasa 4.1 Materiały samoreaktywne wymagające temperatury kontrolowanej (kod klasyfikacyjny SR2), Materiały samoreaktywne niewymagające temperatury, kontrolowanej o numerach UN: 3221, 3222, 3223, 3224,</span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>Materiały wybuchowe stałe odczulone ( kod klasyfikacyjny D i DT),</span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>Klasa 4.2 Materiały I grupy pakowania,</span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>Klasa 5.2 Nadtlenki organiczne wymagające temperatury kontrolowanej P2, Nadtlenki organiczne niewymagające temperatury kontrolowanej o nr UN: 3101, 3102 3103, 3104,</span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>Klasa 6.1 Materiały I grupy pakowania,</span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>Klasa 6.2 Materiały zakaźne,</span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>Klasa 7 Materiały promieniotw&oacute;rcze,</span>
                    </li>
                </ol>
                <ol className={props.classes.list}>
                    <li>
                        <span style={{ textAlign: "justify" }}>materiał&oacute;w sypkich (luzem),</span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>materiał&oacute;w płynnych (w cysternach),</span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>przesyłek ponadgabarytowych,</span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>mienia przesiedleńczego,</span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>alkoholi wysokoprocentowych,</span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>wyrob&oacute;w tytoniowych,</span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>dzieł sztuki,</span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>kamieni szlachetnych i biżuterii,</span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>metali szlachetnych,</span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>roślin i zwierząt,</span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>papier&oacute;w wartościowych,</span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>zwłok i szczątk&oacute;w zwłok ludzkich,</span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>innych rzeczy wyłączonych z przewozu, określonych w art. 36 ustawy z dnia 15 listopada 1984 r. Prawo przewozowe (tekst jednolity: Dz.U. 2017, poz. 1983 z p&oacute;źn. zm.).,</span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>przesyłek, kt&oacute;re wymagają specjalistycznego taboru i przeładunku.</span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>produkt&oacute;w szybko psujących się,</span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>produkt&oacute;w mrożonych</span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>odpad&oacute;w,</span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>towar&oacute;w i przedmiot&oacute;w szczeg&oacute;lnie podatnych na uszkodzenie w transporcie drogowym.</span>
                    </li>
                </ol>
                <p align="center" style={{ marginBottom: 0 }}><span style={{}}><strong>&sect;10</strong></span></p>
                <p align="center"><span><strong>[rozwiązanie Umowy]</strong></span></p>
                <ol>
                    <li value="1">
                        <p align="justify"><span>Umowa o świadczenie usług drogą elektroniczną może być rozwiązana przez każdą ze stron z zachowaniem 30 &ndash; dniowego okresu wypowiedzenia.</span></p>
                    </li>
                </ol>
                <ol start="2">
                    <li>
                        <p align="justify"><span>Wypowiedzenie następuje przez przesłanie elektronicznie oświadczenia o wypowiedzeniu Umowy za pośrednictwem Konta lub pisemnie na adres siedziby Usługodawcy.</span></p>
                    </li>
                </ol>
                <ol start="3">
                    <li>
                        <p align="justify"><span>Umowa zostaje rozwiązana z upływem okresu wypowiedzenia, jednak nie wcześniej niż do czasu wykonania Zam&oacute;wienia oraz całkowitego rozliczenia go.</span></p>
                    </li>
                </ol>
                <ol start="4">
                    <li>
                        <p align="justify"><span>Z chwilą upływu okresu wypowiedzenia Konto Użytkownika zostaje usunięte z Serwisu.</span></p>
                    </li>
                </ol>
                <ol start="5">
                    <li>
                        <p align="justify"><span>Użytkownik będący konsumentem w rozumieniu kodeksu cywilnego, może odstąpić od Umowy bez podania przyczyny, składając w terminie 10 dni od dnia jej zawarcia oświadczenie o odstąpieniu w formie elektronicznej za pośrednictwem Konta lub na piśmie i przesyłając je na adres siedziby Usługodawcy. Prawo odstąpienia od Umowy zawartej na odległość nie przysługuje Użytkownikowi będącego konsumentem w przypadku, gdy wykonanie Zam&oacute;wienia rozpoczęto za zgodą Użytkownika, przed upływem 10 dni od dnia zawarcia Umowy.</span></p>
                    </li>
                </ol>
                <ol start="6">
                    <li>
                        <p align="justify"><span>Usługodawca jest uprawniony do rozwiązania Umowy z Użytkownikiem ze skutkiem natychmiastowym w przypadku rażącego naruszenia postanowień Regulaminu przez Użytkownika.</span></p>
                    </li>
                </ol>
                <ol start="7">
                    <li>
                        <p align="justify"><span>W przypadku rozwiązania Umowy przez Użytkownika lub Usługodawcę, jak r&oacute;wnież w przypadku innego wygaśnięcia Umowy, Konto podlega usunięciu wraz ze zgromadzonymi na nim danymi. Użytkownik przyjmuje do wiadomości i zgadza się na to, że usunięcie Konta r&oacute;wnoznaczne jest dla tego Użytkownika z bezpowrotną utratą wszystkich zgromadzonych w ramach tego konta danych.</span></p>
                    </li>
                </ol>
                <ol start="8">
                    <li>
                        <p align="justify"><span>Usunięcie danych zgodnie z ust. 7 nie oznacza automatycznego usunięcia danych osobowych Użytkownika z bazy Usługodawcy.</span></p>
                    </li>
                </ol>
                <ol start="9">
                    <li>
                        <p align="justify"><span>Po usunięciu lub zablokowaniu Konta Administrator przetwarza dane osobowe Użytkownik&oacute;w Serwisu w zakresie określonym w rozporządzeniu Parlamentu Europejskiego i Rady (UE) 2016/679 z 27 kwietnia 2016 r. w sprawie ochrony os&oacute;b fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE </span><span>(og&oacute;lne rozporządzenie o ochronie danych)</span><span>, w celu:</span></p>
                    </li>
                </ol>
                <ol className={props.classes.list}>
                    <li value="1">
                        <span style={{ textAlign: "justify" }}>należytego zakończenia wykonywania Zam&oacute;wienia na rzecz Użytkownika,</span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>rozpatrzenia reklamacji,</span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>własnej sprawozdawczości finansowej i podatkowej.</span>
                    </li>
                </ol>
                <ol>
                    <li>
                        <p align="justify"><span>Usunięcie Konta nie zwalnia Użytkownika z uregulowania należności wobec Usługodawcy.</span></p>
                    </li>
                </ol>
                <p align="center" style={{ marginBottom: 0 }}><span style={{}}><strong>&sect;11</strong></span></p>
                <p align="center"><span><strong>[dane osobowe]</strong></span></p>
                <ol>
                    <li value="1">
                        <p align="justify"><span>TREO Sp&oacute;łka z ograniczoną odpowiedzialnością z siedzibą w Bielinach, ul. Kielecka 143, 26 &ndash; 004 Bieliny wpisana do Rejestru Przedsiębiorc&oacute;w KRS prowadzonego przez Sąd Rejonowy w Kielcach, X Wydział Gospodarczy Krajowego Rejestru Sądowego pod nr KRS: 0000645046, o kapitale zakładowym w wysokości 5.000,00 zł, jest Administratorem danych osobowych.</span></p>
                    </li>
                </ol>
                <ol start="2">
                    <li>
                        <p align="justify"><span>Dane Użytkownik&oacute;w są przechowywane i przetwarzane z zastosowaniem wymaganych przepisami prawa środk&oacute;w technicznych i organizacyjnych, zapewniających ich ochronę. Dane osobowe są chronione przed ich pozyskiwaniem i modyfikacją przez osoby nieuprawnione, zgodnie z </span><span>Polityką Ochrony Danych Osobowych Administratora</span><span>.</span></p>
                    </li>
                </ol>
                <ol start="3">
                    <li>
                        <p align="justify"><span>Administrator dokonuje przetwarzania danych osobowych Użytkownik&oacute;w zgodnie z przepisami prawa, w tym w szczeg&oacute;lności z przepisami rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z 27 kwietnia 2016 r. w sprawie ochrony os&oacute;b fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE </span><span>(og&oacute;lne rozporządzenie o ochronie danych)</span><span> oraz ustawą z dnia 29 sierpnia 1997 r. o ochronie danych osobowych (Dz. U. 2016, poz. 922 z p&oacute;źn. zm.).</span></p>
                    </li>
                </ol>
                <ol start="4">
                    <li>
                        <p align="justify"><span>Administrator przetwarza dane osobowe Użytkownika w zakresie niezbędnym do nawiązania, ukształtowania treści, zmiany lub rozwiązania stosunku prawnego oraz do prawidłowej realizacji Usług świadczonych drogą elektroniczną, dokonania rozliczeń z Użytkownikiem i </span><span>marketingu usług własnych.</span></p>
                    </li>
                </ol>
                <ol start="5">
                    <li>
                        <p align="justify"><span>Użytkownik ma prawo do kontroli przetwarzania danych osobowych, kt&oacute;re go dotyczą za pośrednictwem Konta, w szczeg&oacute;lności ma prawo wglądu do przetwarzanych danych osobowych w każdym czasie, prawo do ich sprostowania (poprawienia), prawo do ograniczenia przetwarzania, prawo wniesienia sprzeciwu w przypadkach określonych w przepisach prawa, prawo do przenoszenia danych, prawo do wniesienia skargi do organu nadzorczego, jak r&oacute;wnież żądania ich usunięcia z bazy Usługodawcy.</span></p>
                    </li>
                </ol>
                <ol start="6">
                    <li>
                        <p align="justify"><span>Użytkownik ma obowiązek aktualizować dane osobowe podane w formularzu rejestracyjnym niezwłocznie po każdej zmianie tych danych.</span></p>
                    </li>
                </ol>
                <ol start="7">
                    <li>
                        <p align="justify"><span>W przypadku powzięcia przez Administratora wątpliwości, co do zgodności z prawdą bądź aktualności podanych przez Użytkownika w formularzu rejestracyjnym danych osobowych(r&oacute;wnież na skutek zgłoszenia dokonanego przez innego Użytkownika) Administrator (niezależnie od postanowień powyższych) uzyskuje uprawnienie do podjęcia następujących czynności:</span></p>
                    </li>
                </ol>
                <ol className={props.classes.list}>
                    <li>
                        <span style={{ textAlign: "justify" }}>wezwania Użytkownika do niezwłocznego usunięcia nieprawdziwych danych osobowych bądź ich aktualizacji,</span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>zablokowania dostępności dla Użytkownika wybranych funkcjonalności w Serwisie,</span>
                    </li>
                    <li>
                        <span style={{ textAlign: "justify" }}>usunięcia Konta.</span>
                    </li>
                </ol>
                <ol>
                    <li>
                        <p align="justify"><span>Administrator uprawniony jest do udostępniania danych osobowych wyłącznie podmiotom upoważnionym na podstawie właściwych przepis&oacute;w prawa i zawartych um&oacute;w powierzenia danych do przetwarzana. Administrator nie udostępnia wbrew prawu danych osobowych innym podmiotom, ani nieuprawnionym osobom.</span></p>
                    </li>
                    <li>
                        <p align="justify"><span>Usunięcie danych osobowych następuje na wniosek Użytkownika zgłoszony za pośrednictwem Konta lub w formie pisemnej na adres siedziby Usługodawcy i jest realizowane niezwłocznie, nie dłużej niż do 7 dni od wpływu wniosku.</span></p>
                    </li>
                </ol>
                <p align="center" style={{ marginBottom: 0 }}><span style={{}}><strong>&sect;12</strong></span></p>
                <p align="center"><span><strong>[postanowienia końcowe]</strong></span></p>
                <ol>
                    <li>
                        <p align="justify"><span>Regulamin w aktualnej wersji dostępny jest w Serwisie. Na żądanie Użytkownika Regulamin zostanie przesłany na adres e-mail Użytkownika podany w formularzu rejestracyjnym.</span></p>
                    </li>
                </ol>
                <ol start="2">
                    <li>
                        <p align="justify"><span>Usługodawca uprawniony jest do zmiany Regulaminu w każdym czasie.</span></p>
                    </li>
                </ol>
                <ol start="3">
                    <li>
                        <p align="justify"><span>O każdej zmianie Regulaminu Usługodawca informuje, umieszczając informację w Serwisie oraz zawiadamiając Użytkownika przez wysłanie informacji na adres e-mail podany przez Użytkownika w formularzu rejestracyjnym. Zmiany w Regulaminie wejdą w życie po upływie 14 dni od dnia ich udostępnienia w Serwisie i zawiadomieniu Użytkownika.</span></p>
                    </li>
                </ol>
                <ol start="4">
                    <li>
                        <p align="justify"><span>Jeżeli w terminie 14 dni od dnia powiadomienia o zmianie Regulaminu Użytkownik nie złoży oświadczenia o wypowiedzeniu Umowy, przyjmuje się, że zmiany zostały przez niego zaakceptowane.</span></p>
                    </li>
                </ol>
                <ol start="5">
                    <li>
                        <p align="justify"><span>Zmiana Regulaminu przez Usługodawcę nie wpływa na treść Zam&oacute;wień złożonych przed zmianą Regulaminu, chyba że Użytkownik wyrazi zgodę na stosowanie do tych Zam&oacute;wień aktualnie obowiązującej wersji Regulaminu.</span></p>
                    </li>
                </ol>
                <ol start="6">
                    <li>
                        <p align="justify"><span>Wszelkie sprawy sporne strony będą pr&oacute;bowały rozwiązać na drodze polubownej, a w przypadku braku porozumienia spory będą rozpatrywane przez sąd powszechny właściwy miejscowo dla siedziby Usługodawcy.</span></p>
                    </li>
                </ol>
                <ol start="7">
                    <li>
                        <p align="justify"><span>W sprawach nieuregulowanych w Regulaminie zastosowanie mają</span> <span>powszechnie obowiązujące przepisy prawa, w szczeg&oacute;lności ustawy z dnia 15 listopada 1984 r. prawo transportowe (Dz.U. z 2017, poz. 1983 z p&oacute;źn. zm.), konwencji o umowie międzynarodowego przewozu drogowego towar&oacute;w (CMR) sporządzoną w Genewie dnia 19 maja 1956 r. (Dz.U. 1962 r. Nr 49, poz. 238, sprost. Dz. U z 1995 r. Nr 69, poz. 352), ustawy z dnia 23 kwietnia 1964 r. Kodeks cywilny (Dz. U. 2017, poz. 459 z p&oacute;źn. zm.), ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (Dz. U. 2017, poz. 1219 z p&oacute;źn. zm.), ustawy z dnia 29 sierpnia 1997 r. o ochronie danych osobowych (Dz. U. 2016 poz. 922 z p&oacute;źn. zm.), rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z 27 kwietnia 2016 r. w sprawie ochrony os&oacute;b fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE, tj. og&oacute;lnego rozporządzenia o ochronie danych.</span></p>
                    </li>
                </ol>
            </Grid>
        </Grid>
    );

}

const mapStateToProps = (state) => {
    return {
        translations: state.translations
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(container));

