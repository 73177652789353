import React from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import OrderStatus from '../../Commons/Const/OrderStatus'
import { ValuationStatus } from '../../Commons/Const/Valuation'
import styles from '../../style';

const style = theme => {
	return (
		{
			root: {
				padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
			},
			gray: {
				borderLeft: `solid ${theme.palette.grey[400]}`,
			},
			orange: {
				borderLeft: `solid ${theme.palette.primary.main}`,
			},
			green: {
				borderLeft: `solid ${styles.palette.green}`,
			}
		}
	)
}

const getClassName = (state, classes) => {
	let arr = [classes.root];
	switch (state) {
		case OrderStatus.NEW:
		case OrderStatus.IN_PROGRESS:
		case OrderStatus.FORWARD:
		case OrderStatus.FORWARD_AUTO:
		case OrderStatus.SENT_TO_SUPPLIER:
		case OrderStatus.CANCELED:
		case ValuationStatus.NEW:
		case ValuationStatus.REJECTED:
		case ValuationStatus.OUTDATED:
			arr.push(classes.gray)
			break;
		case OrderStatus.TO_SUPPLY:
		case ValuationStatus.ACCEPTED:
			arr.push(classes.green)
			break;
		case OrderStatus.AWAIT:
		case ValuationStatus.AWAIT:
			arr.push(classes.orange)
			break;
		default:
			break;
	}
	return arr.join(' ')
}

const container = (props) => {
	const { classes, state } = props;
	return (
		<Paper className={getClassName(state, classes)}>
			<Typography variant='body1'>
				{props.label}
			</Typography>
		</Paper>
	);
}

container.propTypes = {
	style: PropTypes.object,
	className: PropTypes.string,
	label: PropTypes.string.isRequired,
	state: PropTypes.string,
}

export default withStyles(style)(container);
