import request from 'superagent';
import { toast } from 'react-toastify';


const devIP = 'https://treo.pl';
// const devIP = 'http://localhost:8080';
// const devIP = 'http://54.74.56.55:8080';

export const ERROR = {
	USER_TOKEN_IS_NOT_VALID: 14,
	USER_NOT_EXIST: 13,
	UNSUPORTED: 999,
};

const Err = (err, code, callback) => {
	var error = {
		message: `${err}`,
		name: `${code}`,
	};
	toast.error(`${err}\ncode: ${code}`, {
		position: toast.POSITION.TOP_RIGHT,
	});
	callback.error(error);
};

const PostMethode = (url, callback, body, token, errCallback) => {
	token = token ? token : '';
	return request
		.post(url)
		.send(body)
		.set('Content-Type', 'application/json')
		.set('Access-Control-Allow-Origin', '*')
		.set('X-AUTH-TOKEN', token)
		.end((err, res) => {
			var code = 500;
			if (res) {
				code = res.statusCode;
			}
			if (err) {
				if (errCallback) {
					errCallback();
				}
				else {
					Err(err, code, callback);

				}
			} else {
				callback.success(res);
			}
		});
};

const DeleteMethode = (url, callback, params, token) => {
	token = token ? token : '';

	request
		.del(url)
		.query(params)
		.set('Content-Type', 'application/json')
		.set('Access-Control-Allow-Origin', '*')
		.set('X-AUTH-TOKEN', token)
		.end((err, res) => {
			var code = 500;
			if (res) {
				code = res.statusCode;
			}
			if (err) {
				Err(err, code, callback);
			} else {
				callback.success(res);
			}
		});
};

const FileMethode = (url, callback, params, token, file) => {
	token = token ? token : '';
	file = file ? file : '';

	request
		.post(url)
		.set('Access-Control-Allow-Origin', '*')
		.set('X-AUTH-TOKEN', token)
		.query(params)
		.attach('file', file)
		.end((err, res) => {
			var code = 500;
			if (res) {
				code = res.statusCode;
			}
			if (err) {
				Err(err, code, callback);
			} else {
				callback.success(res);
			}
		});
};

const GetMethode = (url, callback, params, token) => {
	token = token ? token : '';

	request
		.get(url)
		.set('Access-Control-Allow-Origin', '*')
		.set('X-AUTH-TOKEN', token)
		.query(params)
		.end((err, res) => {
			var code = 500;
			if (res) {
				code = res.statusCode;
			}
			if (err) {
				Err(err, code, callback);
			} else {
				callback.success(res);
			}
		});
};

const GetCustomMethode = (url, callback, params, token) => {
	token = token ? token : '';

	request
		.get(url)
		.set('Access-Control-Allow-Origin', '*')
		.set('X-AUTH-TOKEN', token)
		.query(params)
		.end((err, res) => {
			var code = 500;
			if (res) {
				code = res.statusCode;
			}
			if (err) {
				Err(err, code, callback);
			} else {
				callback.success(res);
			}
		});
};

const RestRequest = (methode, urlSufix, body, callback, token = undefined, file = undefined, errCallback = undefined) => {
	if (typeof callback === 'function') {
		callback = {
			success: callback,
			error: (err) => {},
		};
	}
	var url = `/api/${urlSufix}`;
	// if (
	//   process.env.NODE_ENV === "development" ||
	//   window.location.hostname == "treo.aliso.usermd.net" ||
	//   window.location.hostname == "treo.local"
	// ) {
	url = devIP + url;
	// }

	console.info('Rest url:', url, '\nType:', methode, '\nBody:', body);

	if (methode === 'get') {
		return GetMethode(url, callback, body, token);
	} else if (methode === 'getCustom') {
		return GetCustomMethode(urlSufix, callback, body, token);
	} else if (methode === 'delete') {
		return DeleteMethode(url, callback, body, token);
	} else if (methode === 'post') {
		return PostMethode(url, callback, body, token, errCallback);
	} else if (methode === 'file') {
		return FileMethode(url, callback, body, token, file);
	} else {
		var error = {
			message: `Undefined method: ${methode}`,
			name: 'RestRequestExepction',
		};
		callback.error(error);
	}
};

export default RestRequest;
