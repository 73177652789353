import React from 'react';
import { connect } from 'react-redux';
import { setPopup } from 'Actions/pageActions'

import DatePicker from 'Components/Commons/DatePicker';
import TimePicker from 'Components/Commons/TimePicker';

import Translations from 'Singleton/Translations'
import { Grid, Typography, Button } from '@material-ui/core';
import styles from '../../../Components/style';


class container extends React.Component {
	constructor(props) {
		super(props);
		const filter = this.getFilter()
		this.state={
			value: filter ? filter.payload : ''
		}
	}

	onAccept = () => {
		var fromDate = this.props.inputs['filtr-text-input-from-date']
			+ this.props.inputs['filtr-text-input-from-time'];
		var toDate = this.props.inputs['filtr-text-input-to-date']
			+ this.props.inputs['filtr-text-input-to-time'];
		this.props.onAcceptFunc(
			{
				from: fromDate,
				to: toDate,
			}
		);
		this.props.setPopup(undefined);
	}

	getFilter = () => {
		const { filters, filtersKey } = this.props;
		let filter = undefined
		
		if (filtersKey) {
			filter = filters.find((item) => {
				if (!item) {
					return false
				}
				if (item.name.length !== filtersKey.length) {
					return false
				}
				for (let i = 0; i < item.name.length; i++) {
					if (item.name[i] !== filtersKey[i]) {
						return false
					}
				}
				return true
			})
		}
		return filter;
	}

	getDateRow = (id, defaultDateKey) => {
		const dateId = `${id}-date`;
		const timeId = `${id}-time`;
		return (
			<Grid
				container
			>
				<Grid item>
					<DatePicker
						label={Translations.get('date')}
						id={dateId}
						defaultDate={this.state.value[defaultDateKey]}
						doNotValidate
					/>
				</Grid>
				<Grid item style={styles.space30}>
				</Grid>
				<Grid item>
					<TimePicker
						doNotValidate
						label={Translations.get('hour')}
						id={timeId}
						defaultDate={this.state.value[defaultDateKey]}
					/>
				</Grid>
			</Grid>
		)
	}

	render() {
		return (
			<Grid
				container
				direction='column'
			>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Typography variant='body1'>
						Od
				</Typography>
				</Grid>
				<Grid item>
					{this.getDateRow('filtr-text-input-from','from')}
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Typography variant='body1'>
						Do
					</Typography>
				</Grid>
				<Grid item>
					{this.getDateRow('filtr-text-input-to', 'to')}
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Button
						variant='contained'
						color='primary'
						onClick={this.onAccept}
						fullWidth
					>
						{Translations.get('update_filter')}
					</Button>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		onAcceptFunc: state.repository.popupOnAccept,
		inputs: state.input.inputState,
		filters: state.repository.filters,
		filtersKey: state.repository.filtersKey
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (x) => {
			dispatch(setPopup(x));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(container);
