import React from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress'

import Column from 'Components/Commons/Column'
import {PageType} from 'Components/Commons/Const/Type'

import HistoryMessage from 'Components/Body/OrderDetails/HistoryMessage'

import RestRequest from 'libs/RestRequest'

import Translations from 'Singleton/Translations'


class container extends React.Component{
	constructor(props){
		super(props);
		this.state={
			isLoading: true,
			list: [],
		}

		this.handleResponse = this.handleResponse.bind(this);
		this.getLoading = this.getLoading.bind(this);

		var type = this.props.type === PageType.ORDER? 'order' : 'valuation';
		RestRequest(
			'get',
			`secured/${type}/history`,
			{number:this.props.number},
			this.handleResponse,
			this.props.userToken
		)
	}

	styles={
		loadingAnim: {
			display: 'flex',
			justifyContent: 'center',
			margin: '1vw',
		}
	}

	handleResponse(res){
		console.log('Get history', res);
		if (res.body.error) {
			return;
		}

		this.setState({
			...this.state,
			list: res.body.list,
			isLoading: false,
		})
	}

	getMessages(){
		if(this.state.list.length <= 0){
			return '';
		}
		return this.state.list.map((item,i)=>{
			return (
				<HistoryMessage
					key={i}
					keyValue={item.key}
					oldValue={item.oldValue?item.oldValue:''}
					newValue={item.newValue?item.newValue:' '}
					timestamp={item.timestamp}
					type={item.type}
				/>
			);
		});
	}

	getLoading(){
		return (
			<CircularProgress
				size={80}
				thickness={5}
			/>
		);
	}

	getContent(){
		if(this.state.isLoading){
			return this.getLoading()
		}
		return this.getMessages();
	}

	render(){
		return(
			<Column 
				title={Translations.get('history')}
				style={{paddingTop: 0}}
			>
				{this.getContent()}
			</Column>
		);
	}
}

container.propTypes ={
	number: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired
}

const mapStateToProps = (state) => {
	return {
		userToken: state.user.token,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(container);
