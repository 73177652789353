import React from 'react'
import PropTypes from 'prop-types';

import Translations from 'Singleton/Translations'
import { Paper, Grid, Typography, Button } from '@material-ui/core';

import styles from 'Components/style'

const container = (props) => {
	return (
		<Paper>
			<Grid
				container
				direction='column'
				style={styles.infoBox}
				alignItems='center'
				justify='space-between'
			>
				<Grid item>
					<Typography
						variant='body1'
						onClick={() => {
							document.getElementById('proLink').click();
						}}
						style={styles.link}
					>
						{props.orderNumber} pro-forma.pdf
					</Typography>
					<a id="proLink" href={props.link} style={{display:'none'}}/>
				</Grid>
				<Grid item>
					<Button
						variant='outlined'
						onClick={()=>{
							window.open(props.editLink,'_blank');
						}}
					>
						{Translations.get('update_pro_forma')}
					</Button>
				</Grid>
			</Grid>
		</Paper>
	)
}

container.propTypes = {
	className: PropTypes.string,
	link: PropTypes.string.isRequired,
	editLink: PropTypes.string.isRequired,
	orderNumber: PropTypes.string.isRequired,
}

export default container;
