import React from 'react'
import { connect } from "react-redux";
import PropTypes from 'prop-types';

import { Paper, Grid, Button, Typography } from '@material-ui/core';
import styles from '../../../style';
import { setPopup } from 'Actions/pageActions.js'
import Translations from 'Singleton/Translations'

class container extends React.Component {

	styles = {
		root: {
			width: "30.743vw",
			height: "10.4166vw",
			margin: "2.5vw 0 0 0",
			textAlign: "center",
			paddingTop: "2.2vw"
		},
		text: {
			fontSize: "1.4vw",
		}
	}

	getAmountTypeChar(type) {
		switch (type) {
			case 'percentage': return '%';
			case 'quota': return ' PLN';
			default: return ''
		}
	}

	changeValue = () => {
		this.props.setPopup('change_coupon_value');
	}

	render() {
		var text = `${Translations.get('discount')} ${this.props.discount}${this.getAmountTypeChar(this.props.amountType)}`;
		
		return (
			<Paper style={styles.infoBox}>
				<Grid
					container
					direction='column'
					alignItems='center'
					justify='space-between'
					style={{height: '100%'}}
				>
					<Grid item>
						<Typography variant='title'>
							{text}
						</Typography>
					</Grid>
					<Grid item>
						<Button
							variant='outlined'
							onClick={this.changeValue}
						>
							{Translations.get('change_coupon_value')}
						</Button>
					</Grid>
				</Grid>
			</Paper>
		);
	}
}

container.propTypes = {
	discount: PropTypes.number.isRequired,
	amountType: PropTypes.string.isRequired
}


const mapStateToProps = (state) => {
	return {
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (x) => {
			dispatch(setPopup(x));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(container);
