import React from 'react'
import { connect } from "react-redux";
import { sessionService } from 'redux-react-session';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom'

import RestRequest from 'libs/RestRequest'
import { ERROR } from 'libs/RestRequest'

import Radio from 'Components/Commons/RadioGroup'
import { Typography, Grid, Button } from '@material-ui/core';
import styles from 'Components/style'
import TextInput from 'Components/Commons/TextInput';

import { setPopup } from 'Actions/pageActions'
import { setLogin } from 'Actions/userActions'
import { redirrect } from 'Actions/pageActions'

import CouponsRadio from 'Components/Commons/Dictionaries/CouponsRadio';
import Translations from 'Singleton/Translations'

class container extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			couponValueValid: false
		}

		this.showError = this.showError.bind(this);
		this.handleErr = this.handleErr.bind(this);
		this.handleResponse = this.handleResponse.bind(this);
		this.logout = this.logout.bind(this);

		this.acceptClick = this.acceptClick.bind(this);
		this.cancelClick = this.cancelClick.bind(this);
	}

	cancelClick() {
		this.props.setPopup(undefined);
	}

	acceptClick() {
		var body = {
			useType: this.props.inputs.couponUseType,
			amountType: this.props.inputs.couponAmountType,
			discount: this.props.inputs.couponValue,
			code: this.props.couponCode
		}
		RestRequest(
			'post',
			'secured/voucher/amount',
			body,
			this.handleResponse,
			this.props.token,
		);
	}

	logout() {
		sessionService.deleteSession().then(
			sessionService.deleteUser().then(() => {
				this.props.setLogin(null, null, null, null, null);
				this.props.redirrect('/panel', this.props.history);
			})
		);
	}

	showError(msg) {
		toast.error(msg, {
			position: toast.POSITION.TOP_RIGHT,
		});
	}

	handleErr(body) {
		switch (body.errorCode) {
			case ERROR.USER_TOKEN_IS_NOT_VALID:
				this.showError(Translations.get('session_expired'))
				this.logout();
				break;
			default:
				this.showError(body.errorReason)
		}
	}

	handleResponse(res) {
		console.log("change coupon value", res);
		this.props.setPopup(undefined);
		if (res.body.error) {
			this.handleErr(res.body);
		} else {
			this.props.refreshDataFunc();
		}
	}

	getCouponUseType() {
		return (
			<Grid container>
				<Grid
					item
					style={styles.popupColumn1}
				>
					<Radio
						id="couponUseType"
						items={CouponsRadio.useType()}
					/>
				</Grid>
				<Grid
					item
					style={styles.popupColumn1}
				>
					<Radio
						id="couponAmountType"
						items={CouponsRadio.ammountType()}
					/>
				</Grid>
			</Grid>
		);
	}

	getCouponValue() {
		return (
			<TextInput
				label={Translations.get('coupon_value')}
				id="couponValue"
				type="number"
				onValidChange={(value) => {
					this.setState({
						...this.state,
						couponValueValid: value,
					})
				}}
			/>
		);
	}

	getCouponButtons() {
		return (
			<Grid
				container
				direction="column"
			>
				<Grid item>
					<Button
						onClick={this.acceptClick}
						label={Translations.get('add_coupon')}
						disabled={!this.state.couponValueValid}
						variant='contained'
						fullWidth
						color='primary'>
						{Translations.get('confirm')}
					</Button>
				</Grid>

				<Grid item style={styles.space16Vertical}>
				</Grid>

				<Grid item>
					<Button
						onClick={this.cancelClick}
						fullWidth
						variant='outlined'
					>
						{Translations.get('cancel')}
					</Button>
				</Grid>
			</Grid>
		);
	}

	render() {
		return (
			<Grid
				container
				direction='column'>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Typography variant='title'>
						{Translations.get('coupon_use_type')}
					</Typography>
				</Grid>

				<Grid item>
					{this.getCouponUseType()}
				</Grid>

				<Grid item>
					{this.getCouponValue()}
				</Grid>

				<Grid item style={styles.space16Vertical}>
				</Grid>

				<Grid item>
					{this.getCouponButtons()}
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		couponCode: state.repository.couponCode,
		refreshDataFunc: state.repository.refreshDataFunc,
		token: state.user.token,
		inputs: state.input.inputState,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (x) => {
			dispatch(setPopup(x));
		},
		setLogin: (x, y, z, q, t, a) => {
			dispatch(setLogin(x, y, z, q, t, a));
		},
		redirrect: (x, y) => {
			dispatch(redirrect(x, y));
		}
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(container));
