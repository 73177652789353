import React from 'react';
import {connect} from "react-redux";

import ConsentBox from 'Components/Body/Popup/ConsentBox'

import Translations from 'Singleton/Translations'
import { Grid, Typography, Button } from '@material-ui/core';
import styles from '../../Components/style';
import TextInput from '../../Components/Commons/TextInput';
import {TextInputType} from 'Components/Commons/Const/InputTypes'

class container extends React.Component {
	constructor(props){
		super(props);
		this.state={
			isEmailValid:false,
			isClicked: false,
		}

		this.onEmailValidChange=this.onEmailValidChange.bind(this);
	}

	onEmailValidChange(val){
		console.log(val);
		this.setState({
			...this.state,
			isEmailValid:val
		});
	}

	render(){
		return(
			<Grid
				container
				direction='column'
			>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Typography variant='body1'>
						{Translations.get('info_about_email_confirmation')}
					</Typography>
				</Grid>
				<Grid item>
					<TextInput
						id="email"
						type={TextInputType.EMAIL}
						label={Translations.get('address_email')}
						onValidChange={this.onEmailValidChange}
					/>
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<ConsentBox/>
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Button
						variant='contained'
						color='primary'
						fullWidth
						onClick={
							() => {
								console.log(this.props);
								this.setState({
									...this.state,
									isClicked: true,
								})
								if(this.props.endFunc){
									this.props.endFunc(this.props.inputs.email)
								}
							}
						}
						disabled={ 
							this.state.isClicked ||
							!this.state.isEmailValid ||
							!this.props.inputs['consent-regulations'] ||
							!this.props.inputs['consent-personal-data'] ||
							!this.props.inputs['consent-invoices'] 
						}
					>
						{Translations.get('send_message')}
					</Button>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		endFunc: state.page.popupFunc,
		inputs: state.input.inputState,
	};
};

const mapDispatchToProps = (dispatch) => {
	return{

	};
};

export default connect(mapStateToProps, mapDispatchToProps)(container);
