import React from 'react'
import { sessionService } from 'redux-react-session';
import { toast } from 'react-toastify';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'
import { Base64 } from 'js-base64';

import Rest from 'libs/RestRequest'
import Dropdown from 'Components/Commons/Dropdown'
import Bar from '../../../Components/Body/Admin/OrdersBar'
import ColumnHeader from '../../../Components/Body/Admin/ColumnHeader'
import CustomerColumn from 'Components/Commons/Const/CustomerColumn'
import CustomerStatus from 'Components/Commons/Const/CustomerStatus'
import { FilterType, FilterPopup } from 'Components/Commons/Const/Filter'
import UserKeyTranslation from 'Components/Commons/Dictionaries/UserKeyTranslation'
import Sticker from 'Components/Commons/Sticker'

import { timeFormatDate } from 'libs/dateformat'
import { ERROR } from 'libs/RestRequest'
import { setLogin } from 'Actions/userActions'
import { redirrect } from 'Actions/pageActions';
import { setPopup, setPopupFilter, setPopupFunc } from 'Actions/pageActions'
import { setValue } from 'Actions/repositoryActions'

import starImg from 'Resources/images/star.svg'

import Translations from 'Singleton/Translations'

import { Grid, Typography } from '@material-ui/core'
import { Paper } from '@material-ui/core'
import styles from "Components/style";
import { withTheme } from '@material-ui/core/styles'
import DropDownIcon from '@material-ui/icons/ArrowDropDown'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress'
import StarIcon from '@material-ui/icons/Star'

class container extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hoveredElement: -1,
			hoveredColumn: -1,
			clickedButton: false,
			tableData: [],
			isLoading: true,
			orderBy: "",
			orderDesc: false,
			currentPage: 0,
			pageItemLimit: 10,
			lenghtData: 140,
			orderColumn: CustomerColumn.EMAIL,
			filters: [],
		};

		this.setFilterToRepository([]);

		this.handleResponse = this.handleResponse.bind(this);
		this.logout = this.logout.bind(this);
		this.handleErr = this.handleErr.bind(this);
		this.showError = this.showError.bind(this);
		this.onNextButtonClick = this.onNextButtonClick.bind(this);
		this.onPreviousButtonClick = this.onPreviousButtonClick.bind(this);
		this.setFilter = this.setFilter.bind(this);
		this.deleteFilter = this.deleteFilter.bind(this);
		this.getTextFilter = this.getTextFilter.bind(this);
		this.getCheckboxFilter = this.getCheckboxFilter.bind(this);

	}

	checkIsAdmin = () => {
		if(!this.props.isAdmin){
			this.props.redirrect('/panel', this.props.history);
		}
	}

	componentDidMount = () => {
		this.checkIsAdmin()
	}

	refreshData() {
		Rest('post',
			'secured/customers',
			{
				orderBy: this.state.orderColumn,
				limit: this.state.pageItemLimit,
				offset: this.state.currentPage * this.state.pageItemLimit,
				desc: this.state.orderDesc,
				filters: this.state.filters
			},
			this.handleResponse,
			this.props.userToken
		);
	}

	logout() {
		sessionService.deleteSession().then(
			sessionService.deleteUser().then(() => {
				this.props.setLogin(null, null, null, null, null);
				this.props.redirrect('/panel', this.props.history);
			})
		);
	}

	showError(msg) {
		toast.error(msg, {
			position: toast.POSITION.TOP_RIGHT,
		});
	}

	handleErr(body) {
		switch (body.errorCode) {
			case ERROR.USER_TOKEN_IS_NOT_VALID:
				this.showError(Translations.get('session_expired'))
				this.logout();
				break;
			default:
				this.showError(body.errorReason)
		}
	}

	handleResponse(res) {
		console.log('Get clients', res);
		if (res.body.error) {
			this.handleErr(res.body);
			return;
		}
		this.setState({
			...this.state,
			isLoading: false,
			tableData: res.body.list,
			lenghtData: res.body.rowsCount,
		})
	}

	styles = {
		loadingAnimBG: {
			width: "100%",
			position: "absolute",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			background: "rgba(100,100,100,0.5)",
			display: "block",
			textAlign: 'center',
			paddingTop: '40px',
		},
		lastCell: {
			position: 'relative',
			wordWrap: 'break-word',
		},
		actionButton: {
			position: 'absolute',
			top: '5px',
			right: '5px',
			backgroundColor: '#fff'
		},
		marked: {
			borderLeft: `solid ${this.props.theme.palette.primary.main}`
		}
	}

	onRowEnter(index) {
		this.setState({
			...this.state,
			hoveredElement: index
		})
	}

	onRowLeave(index) {
		if (this.state.clickedButton) {
			return;
		}
		this.setState({
			...this.state,
			hoveredElement: -1
		})
	}

	onClickDropdown() {
		this.setState({
			...this.state,
			clickedButton: true
		})
	}

	onCloseDropdown() {
		this.setState({
			...this.state,
			clickedButton: false,
			hoveredElement: -1
		})
	}

	deleteUser(userId) {
		this.props.setPopupFunc(this.handleDeleteSuccess)
		this.props.setRepositoryValue('userId', userId);
		this.props.setPopup('delete_account');
	}

	handleDeleteSuccess = () => {
		toast.success(Translations.get('user_deleted'), {
			position: toast.POSITION.TOP_RIGHT,
		});
		this.setState({
			...this.state,
			isLoading: true,
		})
	}

	getStatus(state) {
		switch (state) {
			case "registered_user": return Translations.get('registered_user');
			case "unregistered_user": return Translations.get('unregistered_user');
			default: return "__NO_DATA__";
		}
	}

	hashNumber(number) {
		return Base64.encodeURI(number);
	}

	onNextButtonClick() {
		var nextPage = this.state.currentPage + 1;
		this.setState({
			...this.state,
			isLoading: true,
			currentPage: nextPage,
		});
	}

	onPreviousButtonClick() {
		var nextPage = this.state.currentPage - 1;
		this.setState({
			...this.state,
			isLoading: true,
			currentPage: nextPage,
		});
	}

	setFilter(name, type, payload) {
		var filters = this.state.filters;
		var index = filters.findIndex(
			(obj) => { return (obj.name.equals(name) && obj.type === type); }
		);

		if (index >= 0) {
			var item = filters[index];
			filters.splice(index, 1);
			item.payload = payload;
		} else {
			item = {
				name: name,
				type: type,
				payload: payload,
			}
		}
		filters.push(item);
		this.setState({
			...this.state,
			isLoading: true,
			filters: filters,
			currentPage: 0,
		})
		this.setFilterToRepository(filters)
		console.log("Set Filter", this.state);
	}

	setFilterToRepository = (filters) => {
		this.props.setRepositoryValue('filters',filters)
	}

	setFilterKeyToRepository = (key) => {
		this.props.setRepositoryValue('filtersKey',key)
	}

	deleteFilter(name, type = false) {
		var filters = this.state.filters;
		var index = filters.findIndex(
			(obj) => {
				var isEq = obj.name.equals(name);
				if (type !== false) {
					isEq = (obj.type === type) && isEq;
				}
				return isEq;
			}
		);
		if (index < 0) {
			return;
		}
		filters.splice(index, 1);
		this.setState({
			...this.state,
			isLoading: true,
			filters: filters,
			currentPage: 0,
		})
	}

	getTextFilter(key) {
		this.props.setRepositoryValue('popupOnAccept',
			(text) => { this.setFilter(key, FilterType.TEXT, text); }
		);
		this.props.setRepositoryValue('popupOnClear',
			() => { this.deleteFilter(key); }
		);
		this.setFilterKeyToRepository(key);
		this.props.setPopupFilter(FilterPopup.TEXT);
	}

	getCheckboxFilter(key, options, translations) {
		this.props.setRepositoryValue('popupOnAccept',
			(arr) => { this.setFilter(key, FilterType.CHECKBOX, arr); }
		);
		this.props.setRepositoryValue('popupOnClear',
			() => { this.deleteFilter(key); }
		);
		this.props.setRepositoryValue('checkboxFilterArray', options);
		this.props.setRepositoryValue('checkboxFilterTranslation', translations);
		this.setFilterKeyToRepository(key);
		this.props.setPopupFilter(FilterPopup.CHECKBOX);
	}

	getStarImg(isMarked) {
		if (isMarked) {
			return (
				<StarIcon
					color='primary'
					style={{ fontSize: 16, marginBottom: '2px', marginLeft: '2px' }}
				/>
			);
		}
		return '';
	}

	getLabels(labels) {
		return (
			<div>
				{labels.map((item, key) => {
					return (
						<Sticker key={key} hover={false}>{item}</Sticker>
					);
				})}
			</div>
		);
	}

	formatOrderColumn(date, number) {
		if (!date || !number) {
			return '';
		}
		var uri;
		if (number[0] === 'W') {
			uri = `/panel/evaluation_details/evaluation/${this.hashNumber(number)}`;
		} else {
			uri = `/panel/evaluation_details/order/${this.hashNumber(number)}`;
		}
		return (
			<div>
				{timeFormatDate(date)}
				{' ('}
				<Typography
					onClick={() => {
						this.props.redirrect(uri, this.props.history)
					}}
					style={{ ...styles.link, display: 'inline-block' }}
					className='table-link'
				>
					{number}
				</Typography>
				{') '}
			</div>
		);
	}

	getDropdownMenu(userId, i) {
		if (i !== this.state.hoveredElement) {
			return '';
		}
		const label = (
			<Typography className='table-link'>
				{Translations.get('actions')}
				<DropDownIcon />
			</Typography>
		)
		return (
			<Dropdown
				label={label}
				onClick={() => { this.onClickDropdown() }}
				onClose={() => { this.onCloseDropdown() }}
				rows={[{
					label: Translations.get('delete_account'),
					onClick: () => { this.deleteUser(userId) }
				}]}
				style={this.styles.actionButton}
				variant='outlined'
			/>
		);
	}

	handleRowClick = number => (event) => {
		if(event.target.nodeName === 'svg'
		|| event.target.nodeName === 'path'){
			return;
		}
		const classTable = event.target.className.split(' ')
		if(classTable.includes('table-link')
		|| event.target.nodeName === 'BUTTON'
		|| event.target.nodeName === 'SPAN'
		|| event.target.nodeName === 'UL'
		|| event.target.nodeName === 'LI'
		|| event.target.nodeName === 'DIV'
		){
			return;
		}

		var linkUri = `/panel/client_details/${this.hashNumber(number)}`;
		this.props.redirrect(linkUri, this.props.history)
	}

	createRows(data) {
		return data.map((item, i) => {
			var linkText = `${item.name} ${item.surname}`
			if (!(item.name && item.surname)) {
				linkText = ' '
			}


			var firstOrder = this.formatOrderColumn(item.firstOrderDate, item.firstOrderNumber);
			var lastOrder = this.formatOrderColumn(item.lastOrderDate, item.lastOrderNumber);
			return (
				<TableRow
					key={i}
					onMouseEnter={() => { this.onRowEnter(i) }}
					onMouseLeave={() => { this.onRowLeave(i) }}
					onClick={this.handleRowClick(item.userId)}
				>
					<TableCell style={styles.tableColumn2}>
						<Grid container direction='column'>
							<Grid item>
								<Grid container style={styles.tableColumn2}>
									<Grid item>
										<Typography noWrap
										>
											{item.email}
										</Typography>
									</Grid>

									<Grid item>
										{this.getStarImg(item.isMarked)}
									</Grid>
								</Grid>
							</Grid>
							<Grid item>
								<Grid container>
									{this.getLabels(item.labels)}
								</Grid>
							</Grid>
						</Grid>
					</TableCell>
					<TableCell style={styles.tableColumn2}>
						{linkText}
					</TableCell>
					<TableCell style={styles.tableColumn1}>
						{this.getStatus(item.status)}
					</TableCell>
					<TableCell style={styles.tableColumn2}>
						{firstOrder}
					</TableCell>
					<TableCell style={styles.tableColumn2}>
						{lastOrder}
					</TableCell>
					<TableCell style={styles.tableColumn0}>
						{item.amountOfOrders}
					</TableCell>
					<TableCell style={{ ...styles.tableColumn0, ...this.styles.lastCell }}>
						{item.amountOfValuation}
						{this.getDropdownMenu(item.userId, i)}
					</TableCell>
				</TableRow>
			);
		});
	}

	setOrderColumn(id) {
		console.log("clicked column " + id)
		var desc = this.state.orderDesc;
		if (id === this.state.orderBy) {
			desc = !desc;
		} else {
			desc = false;
		}
		this.setState({
			...this.state,
			orderBy: id,
			orderColumn: id,
			isLoading: true,
			orderDesc: desc
		}
		);
	}

	getLoading() {
		if (this.state.isLoading) {
			return (
				<div style={this.styles.loadingAnimBG}>
					<CircularProgress size={80} thickness={5} />
				</div>
			);
		}
		return '';
	}

	isFilter(filter){
		const obj = this.state.filters.find((item)=>{
			return item.name.equals(filter);
		})
		if (obj){
			return true;
		}
		return false;
	}

	getHeaders() {
		return (
			<TableRow>
				<ColumnHeader
					style={styles.tableColumn2}
					label={Translations.get('email')}
					visible={this.state.orderColumn === CustomerColumn.EMAIL}
					sort={() => { this.setOrderColumn(CustomerColumn.EMAIL) }}
					visibleFilter={this.isFilter([CustomerColumn.EMAIL])}
					filter={() => {
						this.getTextFilter([CustomerColumn.EMAIL])
					}}
				/>
				<ColumnHeader
					style={styles.tableColumn2}
					label={Translations.get('appellative')}
					visible={this.state.orderColumn === CustomerColumn.SURNAME}
					sort={() => { this.setOrderColumn(CustomerColumn.SURNAME) }}
					visibleFilter={this.isFilter([
						CustomerColumn.SURNAME,
						CustomerColumn.NAME,
						CustomerColumn.EMAIL
					])}
					filter={() => {
						this.getTextFilter([
							CustomerColumn.SURNAME,
							CustomerColumn.NAME,
							CustomerColumn.EMAIL
						])
					}}
				/>
				<ColumnHeader
					style={styles.tableColumn1}
					label={Translations.get('status')}
					visible={this.state.orderColumn === CustomerColumn.STATUS}
					sort={() => { this.setOrderColumn(CustomerColumn.STATUS) }}
					visibleFilter={this.isFilter([CustomerColumn.STATUS])}
					filter={() => {
						this.getCheckboxFilter(
							[CustomerColumn.STATUS],
							CustomerStatus.GET_ALL,
							UserKeyTranslation.userState,
						);
					}}
				/>
				<ColumnHeader
					style={styles.tableColumn2}
					label={Translations.get('first_order_date')}
					visible={this.state.orderColumn === CustomerColumn.FIRST_ORDER_DATE}
					sort={() => { this.setOrderColumn(CustomerColumn.FIRST_ORDER_DATE) }}
				/>
				<ColumnHeader
					style={styles.tableColumn2}
					label={Translations.get('last_order_date')}
					visible={this.state.orderColumn === CustomerColumn.LAST_ORDER_DATE}
					sort={() => { this.setOrderColumn(CustomerColumn.LAST_ORDER_DATE) }}
				/>
				<ColumnHeader
					style={styles.tableColumn0}
					label={Translations.get('orders')}
					visible={this.state.orderColumn === CustomerColumn.AMOUNT_OF_ORDERS}
					sort={() => { this.setOrderColumn(CustomerColumn.AMOUNT_OF_ORDERS) }}
				/>
				<ColumnHeader
					style={styles.tableColumn0}
					label={Translations.get('evaluations')}
					visible={this.state.orderColumn === CustomerColumn.AMOUNT_OF_VALUATIONS}
					sort={() => { this.setOrderColumn(CustomerColumn.AMOUNT_OF_VALUATIONS) }}
				/>
			</TableRow>
		);
	}

	getTable() {
		return (
			<Table>
				<TableHead>
					{this.getHeaders()}
				</TableHead>
				<TableBody>
					{this.createRows(this.state.tableData)}
				</TableBody>
			</Table>
		)
	}

	render() {
		if(!this.props.isAdmin){
			return '';
		}

		if (this.state.isLoading) {
			this.refreshData();
		}

		return (
			<div style={styles.tableContainer}>
				<Paper style={{ ...styles.paperBox, position: 'relative' }}>
					<Grid
						container
						direction='column'
					>
						<Grid item>
							<Bar
								title={Translations.get('clients')}
								page={this.state.currentPage}
								step={this.state.pageItemLimit}
								length={this.state.lenghtData}
								nextAction={this.onNextButtonClick}
								previousAction={this.onPreviousButtonClick}
								setFilter={this.setFilter}
								deleteFilter={this.deleteFilter}
								isSearcher={true}
								isFilter={true}
							/>
						</Grid>
						<Grid item>
							{this.getTable()}
						</Grid>
						<Grid item style={styles.space16Vertical}>
						</Grid>
						<Grid item>
							<Bar
								page={this.state.currentPage}
								step={this.state.pageItemLimit}
								length={this.state.lenghtData}
								nextAction={this.onNextButtonClick}
								previousAction={this.onPreviousButtonClick}
							/>
						</Grid>
					</Grid>
					{this.getLoading()}
				</Paper>
			</div>
		);
	}
}



const mapStateToProps = (state) => {
	return {
		userId: state.user.id,
		userToken: state.user.token,
		isAdmin: state.user.isAdmin,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setLogin: (x, y, z, q, t, a) => {
			dispatch(setLogin(x, y, z, q, t, a));
		},
		setPopup: (x) => {
			dispatch(setPopup(x));
		},
		redirrect: (x, y) => {
			dispatch(redirrect(x, y));
		},
		setRepositoryValue: (x, y) => {
			dispatch(setValue(x, y));
		},
		setPopupFilter: (x, y) => {
			dispatch(setPopupFilter(x, y));
		},
		setPopupFunc: func => {dispatch(setPopupFunc(func))}
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme()(container)));
