
export default class ValuationColumn {
	static get NUMBER() {return 'number';}
	static get STATUS() {return 'key';}
	static get NAME() {return 'first_name';}
	static get SURNAME() {return 'surname';}
	static get EMAIL() {return 'email';}
	static get ORDER_NUMBER() {return 'order_number';}
	static get SUPLLIER_NAME() {return 'name';}
	static get AMMOUNT_GROSS() {return 'amount_gross';}
	static get AMMOUNT_COST() {return 'cost';}
	static get CREATE_DATE() {return 'creation_date';}
	static get LAST_MESSAGE() {return 'lastmessage';}
}
