import React from 'react'
import { toast } from 'react-toastify';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { setPopup } from 'Actions/pageActions'

import RestRequest from 'libs/RestRequest'

import Translations from 'Singleton/Translations'
import { Grid, Typography, Button } from '@material-ui/core';
import styles from 'Components/style'
import TextInput from 'Components/Commons/TextInput';
import { TextInputType } from 'Components/Commons/Const/InputTypes'

class container extends React.Component {

	constructor(props) {
		super(props);
		// console.log(props.eNumber);
		this.state = {
			cost: props.inputs['cost_net'] ? props.inputs['cost_net'] : 0,
			priceNet: props.inputs['price_net'] ? props.inputs['price_net'] : 0,

			number: props.eNumber,
			isPricePlnValid: true,
		}
		this.styles = {
			flexbox: {
				display: "flex",
				width: "100%",
				flexDirection: "row",
				justifyContent: "space-around",
				alignItems: "flex-end",
			},
			inputs: {
				width: "7vw",
				marginTop: "-1.8vw",
			},
			inputHeaderBlocked: {
				width: "7vw",
				fontSize: "0.6vw",
				color: "#888",
				margin: "0",
			},
			inputHeader: {
				width: "7vw",
				fontSize: "0.8vw",
				color: "#faaf42",
				margin: 0,
			},
			hintStyle: {
				width: "7vw",
				fontSize: "0.9vw",
			},
			staticText: {
				width: "7vw",
				margin: "0 0 0 0",
				fontSize: "0.9vw",
				paddingBottom: "0.6vw",
				color: "black"
			}

		}
	}

	sendCostData() {
		var body = {
			cost: this.state.cost.toFixed(2),
			priceNet: this.state.priceNet.toFixed(2),
			number: this.state.number
		}
		RestRequest("post", "secured/updateEvaluationPrices", body, (res) => {
			// console.log(res.body);
			if (res.body.error) {
				toast.error(`${res.body.errorString}`, {
					position: toast.POSITION.TOP_RIGHT,
				});
			} else {
				this.props.refreshDataFunc();
				this.props.setPopup(undefined);
			}
		}, this.props.userToken);
	}

	cancel() {
		this.props.setPopup(undefined);
	}

	handleCostNetChange = (value) => {
		var cost = parseFloat(value) * this.props.exValue;
		cost = cost ? cost : 0
		const priceNet = this.props.inputs['tarde_margin'] * this.props.exValue + cost;
		
		this.setState({
			...this.state,
			cost,
			priceNet: priceNet ? priceNet : 0,
		})
	}

	handleTardeMarginChannge = value => {
		const margin = parseFloat(value) * this.props.exValue;
		const priceNet = margin + this.state.cost;
		console.log('margin change',this.state);
		
		this.setState({
			...this.state,
			priceNet: priceNet ? priceNet : 0,
		})
	}

	getCostHeaderRow() {
		return (
			<Grid
				container
				justify='space-between'
			>
				<Grid item style={styles.button1}>
					<Typography variant='caption'>
						{Translations.get('cost_net')} (EUR)
					</Typography>
				</Grid>
				<Grid item style={styles.button1}>
					<Typography variant='caption'>
						{Translations.get('rate')} PLN : EUR
					</Typography>
				</Grid>
				<Grid item style={styles.button1}>
					<Typography variant='caption'>
						{Translations.get('cost')} (PLN)
					</Typography>
				</Grid>
			</Grid>
		)
	}

	getCostRow() {
		return (
			<Grid
				container
				justify='space-between'
				alignItems='baseline'
			>
				<Grid item style={styles.button1}>
					<TextInput
						value={ (this.state.cost / this.props.exValue).toFixed(2)}
						id="cost_net"
						fullWidth
						onChange={this.handleCostNetChange}
						type={TextInputType.NOT_EMPTY}
						limit={9}
					/>
				</Grid>
				<Grid item style={styles.button1}>
					<Typography variant='body1'>
						{this.props.exValue}
					</Typography>
				</Grid>
				<Grid item style={styles.button1}>
					<Typography variant='body1'>
						{this.state.cost.toFixed(2)}
					</Typography>
				</Grid>
			</Grid>
		)
	}

	getPriceHeaderRow() {
		return (
			<Grid
				container
				justify='space-between'
			>
				<Grid item style={styles.button1}>
					<Typography variant='caption'>
						{Translations.get('amount_net')} (PLN)
					</Typography>
				</Grid>
				<Grid item style={styles.button1}>
					<Typography variant='caption'>
						{Translations.get('rate')} PLN : EUR
					</Typography>
				</Grid>
				<Grid item style={styles.button1}>
					<Typography variant='caption'>
						{Translations.get('trading_margin')} (EUR)
					</Typography>
				</Grid>
			</Grid>
		)
	}

	onPricePlnValidChange = (valid) => {
		this.setState({
			...this.state,
			isPricePlnValid: valid,
		})
	}

	getPriceRow() {
		return (
			<Grid
				container
				justify='space-between'
				alignItems='baseline'
			>
				<Grid item style={styles.button1}>
					<Typography variant='body1'>
						{this.state.priceNet.toFixed(2)}
					</Typography>

				</Grid>
				<Grid item style={styles.button1}>
					<Typography variant='body1'>
						{this.props.exValue}
					</Typography>
				</Grid>
				<Grid item style={styles.button1}>
					<TextInput
						value={ (( this.props.inputs['price_net'] - this.state.cost ) / this.props.exValue).toFixed(2) }
						id="tarde_margin"
						fullWidth
						onChange={this.handleTardeMarginChannge}
						type={TextInputType.NOT_EMPTY}
						onValidChange={this.onPricePlnValidChange}
						limit={9}
					/>
				</Grid>
			</Grid>
		)
	}

	render() {
		// console.log("RENDER:",this.state);
		return (
			<Grid
				container
				direction='column'
			>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Typography variant='title' paragraph>
						{Translations.get('cost')}
					</Typography>
				</Grid>
				<Grid item>
					{this.getCostHeaderRow()}
				</Grid>
				<Grid item>
					{this.getCostRow()}
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Typography variant='title' paragraph>
						{Translations.get('price')}
					</Typography>
				</Grid>
				<Grid item>
					{this.getPriceHeaderRow()}
				</Grid>
				<Grid item>
					{this.getPriceRow()}
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Button
						variant='contained'
						onClick={this.sendCostData.bind(this)}
						color='primary'
						fullWidth
						disabled={!this.state.isPricePlnValid}
					>
						{Translations.get('update_price')}
					</Button>
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Button
						fullWidth
						variant='outlined'
						onClick={this.cancel.bind(this)}
					>
						{Translations.get('cancel')}
					</Button>
				</Grid>
			</Grid>
		)
	}
}

container.propTypes = {
}

const mapStateToProps = (state) => {
	return {
		inputs: state.input.inputState,
		userToken: state.user.token,
		eNumber: state.page.eNumber,
		exValue: state.translations.exchangeCurrencyAsk,
		refreshDataFunc: state.repository.refreshFunc
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (x) => {
			dispatch(setPopup(x));
		}
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(container));
