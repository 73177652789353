import React from 'react'
import { connect } from "react-redux";
import { toast } from 'react-toastify';

import RestRequest from 'libs/RestRequest'
import OrderStatus from 'Components/Commons/Const/OrderStatus'

import { setPopup } from 'Actions/pageActions'

import Translations from 'Singleton/Translations'
import { Grid, Typography, Button } from '@material-ui/core';
import TextInput from '../../Components/Commons/TextInput';
import styles from '../../Components/style';

class container extends React.Component {
	constructor(props) {
		super(props);
		this.handleResponse = this.handleResponse.bind(this);
		this.showError = this.showError.bind(this);
	}

	cancelClick() {
		this.props.setPopup(undefined);
	}

	confirmClick() {
		var isAuto = this.props.action === OrderStatus.FORWARD_AUTO;
		RestRequest(
			'post',
			`secured/order/status/forward/${isAuto}`,
			{
				number: this.props.number,
				remarks: this.props.inputs.remarks,
			},
			this.handleResponse,
			this.props.userToken,
		)

		this.props.setPopup(undefined);
	}

	showError(msg) {
		toast.error(msg, {
			position: toast.POSITION.TOP_RIGHT,
		});
	}


	handleResponse(res) {
		console.log(res);
		if (res.body.error) {
			this.showError(res.body.errorReason)
		} else {
			this.props.acceptFunc();
			this.props.setPopup();
		}
	}

	render() {
		return (
			<Grid
				container
				direction='column'
			>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Typography variant='body1'>
						{Translations.get('additional_remarks_for_client')}
					</Typography>
				</Grid>
				<Grid item>
					<TextInput
						id='remarks'
						label={Translations.get('remarks')}
						limit={245}
					/>
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Button
						variant='contained'
						color='primary'
						fullWidth
						onClick={this.confirmClick.bind(this)}
					>
						{Translations.get('changed_status_forwarded_for_provider')}
					</Button>
				</Grid>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<Button
						variant='outlined'
						onClick={this.cancelClick.bind(this)}
						fullWidth
					>
						{Translations.get('cancel')}
					</Button>
				</Grid>
			</Grid>
		);
	}
}


const mapStateToProps = (state) => {
	return {
		userToken: state.user.token,
		inputs: state.input.inputState,
		number: state.repository.number,
		action: state.repository.action,
		acceptFunc: state.page.popupFunc,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (x) => {
			dispatch(setPopup(x));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(container);
