const dropdownReducer = (state ={
	dropdownState: {}
}, action) => {
	switch (action.type) {
		case "DROPDOWN_SET_STATE":
		state = {
			...state,
			dropdownState: {
				...state.dropdownState,
				[action.id]: action.value
			}
		};
		break;
		default:
		break;
	}
	return state;
};

export default dropdownReducer;
