import React from 'react'
import PropTypes from 'prop-types';

import Translations from 'Singleton/Translations'
import { Paper, Grid, Typography, Button } from '@material-ui/core';

import styles from 'Components/style';

const priceBox = (netto, brutto, currency, description, color) => {
	return (
		<Grid
			container
			direction='column'
			alignItems='center'
		>
			<Grid item>
				<Typography variant='body2' style={{ color }}>
					{netto} {currency}
				</Typography>
			</Grid>
			<Grid item>
				<Typography variant='caption' style={styles.priceBoxColumn} align='center'>
					{brutto} {currency} {description}
				</Typography>
			</Grid>
		</Grid>
	)
}

const priceUnknownBox = (text) => {
	return (
		<Typography
			variant='body1'
			color='textSecondary'
			style={styles.priceBoxColumn}
			align='center'
		>
			{text}
		</Typography>
	)
}

const getPriceRow = (props) => {
	return (
		<Grid
			container
			justify='space-between'
		>
			<Grid item>
				{
					(props.profit || props.profitB) ?
						priceBox(
							parseFloat(props.profit).toFixed(2),
							parseFloat(props.profitB).toFixed(2),
							props.currency,
							Translations.get('gross'),
							styles.palette.green
						)
						:
						priceUnknownBox(Translations.get('undetermined_income'))


				}
			</Grid>

			<Grid item>
				{
					(props.loss || props.lossB) ?
						priceBox(
							'-' + parseFloat(props.loss).toFixed(2),
							parseFloat(props.lossB).toFixed(2),
							props.currency,
							Translations.get('gross'),
							styles.palette.red)
						:
						priceUnknownBox(Translations.get('undetermined_cost'))
				}
			</Grid>

			<Grid item>
				{
					(props.difference || props.differenceB) ?
						priceBox(
							'=' + parseFloat(props.difference).toFixed(2),
							parseFloat(props.differenceB).toFixed(2),
							props.currency,
							`${Translations.get('gross')} ${Translations.get('rate')} ${props.exRate} EUR`)
						:
						priceUnknownBox(Translations.get('profit_unknown'))
				}
			</Grid>
		</Grid>
	)
}

const container = (props) => {
	return (
		<Paper>
			<Grid
				container
				style={styles.infoBox}
				direction='column'
				alignItems='center'
				justify='space-between'
			>
				<Grid item>
					{getPriceRow(props)}
				</Grid>
				<Grid item>
					{props.status === "new_eval" &&
						<Button
							variant='outlined'
							onClick={props.update ? props.update : () => { }}
						>
							{Translations.get('update_price')}
						</Button>
					}
				</Grid>
			</Grid>
		</Paper>
	);
}

container.propTypes = {
	className: PropTypes.string,
	profit: PropTypes.number,
	profitB: PropTypes.number,

	loss: PropTypes.number,
	lossB: PropTypes.number,

	difference: PropTypes.number,
	differenceB: PropTypes.number,

	currency: PropTypes.string,
	exRate: PropTypes.string,
	update: PropTypes.func,
	status: PropTypes.string,
}

export default container;
