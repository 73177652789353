import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import sha256 from "sha256";
import { sessionService } from "redux-react-session";

import GoogleIcon from "../../../Resources/images/G.svg";
import FacebookIcon from "../../../Resources/images/FB.svg";
import MailIcon from "../../../Resources/images/mail.svg";

import PopupButton from "./button/Button";

import Rest from "libs/RestRequest";
import { setPopup } from "Actions/pageActions";
import { setLogin, setUserVatZero } from "Actions/userActions";

import Translations from "Singleton/Translations";

import { Grid, Button } from "@material-ui/core";
import styles from "Components/style";

class Box extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accessToken: undefined,
    };
    this.responseGoogle = this.responseGoogle.bind(this);
    this.responseFacebook = this.responseFacebook.bind(this);
    this.handleResponseLogin = this.handleResponseLogin.bind(this);
    this.handleResponseRegister = this.handleResponseRegister.bind(this);
    this.getLoginButtons = this.getLoginButtons.bind(this);
  }

  styles = {
    facebookButton: {
      backgroundColor: styles.palette.blue,
    },
    googleButton: {
      backgroundColor: styles.palette.red,
    },
    emailButton: {
      backgroundColor: styles.palette.yellow,
    },
  };

  responseGoogle(res) {
    console.log("GOOGLE RESPONSE", res);
    if (res.profileObj === undefined) {
      console.log("google err");
      return;
    }
    this.setState({
      ...this.state,
      accessToken: res.accessToken,
    });
    var body = {
      firstname: res.profileObj.givenName,
      surname: res.profileObj.familyName,
      login: res.profileObj.email,
      pssw: sha256.x2("wef98hg7" + res.googleId),
      loginType: "Google",
      remember: true,
    };
    Rest("post", "auth/login", body, this.handleResponseLogin);
  }

  responseFacebook(res) {
    console.log(res);
    if (res.email === undefined) {
      console.log("facebook err");
      return;
    }
    this.setState({
      ...this.state,
      accessToken: res.accessToken,
    });
    var name = res.name.split(" ");
    var body = {
      firstname: name[0],
      surname: name[1],
      login: res.email,
      pssw: sha256.x2("765rtjio" + res.userID),
      loginType: "Facebook",
      remember: true,
    };
    Rest("post", "auth/login", body, this.handleResponseLogin);
  }

  handleResponseLogin(res) {
    if (res.body.error) {
      toast.error(`${res.body.errorReason}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    console.log("login", res);
    var expDate = new Date();
    expDate = new Date(expDate.getDate() + 30).getTime();
    var token = { token: res.body.token, expire: expDate };
    var userObj = {
      id: res.body.userId,
      login: res.body.login,
      isAdmin: res.body.isAdmin,
      type: res.body.type,
      accessToken: this.state.accessToken,
      isVatZero: res.body.isVatZero,
    };
    sessionService.saveSession(token).then(
      sessionService.saveUser(userObj).then(() => {
        this.props.setPopup(undefined);
        this.props.setLogin(
          res.body.login,
          res.body.token,
          res.body.userId,
          res.body.isAdmin,
          res.body.type,
          this.state.accessToken
        );
        this.props.setUserVatZero(res.body.isVatZero);
        if (this.props.func) {
          this.props.func(res.body.login);
        }
        if (this.props.afterLogin) {
          this.props.afterLogin();
        }
      })
    );
  }

  handleResponseRegister(res) {
    console.log(res);
    if (res.body.error) {
      toast.error(`${res.body.errorReason}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    console.log(res);
    this.props.setPopup(undefined);
  }

  renderButton = (icon, text) => (props) => {
    return (
      <PopupButton onClick={props.onClick} icon={icon}>
        {text}
      </PopupButton>
    );
  };

  getLoginButtons() {
    return (
      <Grid container direction="column">
        <Grid item>
          <GoogleLogin
            clientId="684786943673-u6lr2n1cio8ls10sjnt9t3cu414hldhk.apps.googleusercontent.com"
            onSuccess={this.responseGoogle}
            onFailure={this.responseGoogle}
            render={this.renderButton(GoogleIcon, "Google")}
          />
        </Grid>
        <Grid item style={styles.space16Vertical}></Grid>
        <Grid item>
          <FacebookLogin
            appId="251808888891243"
            fields="name,email"
            callback={this.responseFacebook}
            autoLoad={false}
            render={this.renderButton(FacebookIcon, "Facebook")}
          />
        </Grid>
        <Grid item style={styles.space16Vertical}></Grid>
      </Grid>
    );
  }

  getRegisterButtons() {
    return (
      <Grid container direction="column">
        <Grid item>
          {this.renderButton(
            GoogleIcon,
            "Google"
          )({
            onClick: () => {
              if (this.props.func) {
                this.props.func();
              }
              this.props.setPopup("register_google");
            },
          })}
        </Grid>
        <Grid item style={styles.space16Vertical}></Grid>
        <Grid item>
          {this.renderButton(
            FacebookIcon,
            "Facebook"
          )({
            onClick: () => {
              if (this.props.func) {
                this.props.func();
              }
              this.props.setPopup("register_facebook");
            },
          })}
        </Grid>
        <Grid item style={styles.space16Vertical}></Grid>
      </Grid>
    );
  }

  render() {
    var emailPage = {
      email: "login_email",
      face: undefined,
      google: undefined,
    };
    if (this.props.popup === "register") {
      emailPage = {
        email: "register_email",
        face: "register_facebook",
        google: "register_external",
      };
    }
    return (
      <Grid container direction="column">
        <Grid item>
          {this.props.popup === "register"
            ? this.getRegisterButtons()
            : this.getLoginButtons()}
        </Grid>
        <Grid item>
          {this.renderButton(
            MailIcon,
            Translations.get("address_email")
          )({
            onClick: () => {
              this.props.setPopup(emailPage.email);
            },
          })}
          {/* <Button
						variant='contained'
						color='primary'
						style={this.styles.emailButton}
						fullWidth
						onClick={() => {
							this.props.setPopup(emailPage.email)
						}}
					>
						{Translations.get('address_email')}
					</Button> */}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    func: state.page.popupFunc,
    popup: state.page.popup,
    afterLogin: state.repository.afterLogin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPopup: (x) => {
      dispatch(setPopup(x));
    },
    setLogin: (x, y, z, q, t, a) => {
      dispatch(setLogin(x, y, z, q, t, a));
    },
    setUserVatZero: (x) => {
      dispatch(setUserVatZero(x));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Box);
