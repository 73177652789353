import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import { setPopup } from 'Actions/pageActions'
import ButtonBox from 'Components/Body/Popup/ButtonBox'

import Translations from 'Singleton/Translations'
import { Grid, Divider, Typography, Button } from '@material-ui/core'
import styles from 'Components/style'

class LoginBox extends React.Component {
	getContinueBox() {
		var continueBox = ''
		if (this.props.isContinue !== undefined && this.props.isContinue) {
			continueBox = (
				<Grid
					container
					direction='column'
				>
					<Grid item style={styles.space16Vertical}>
					</Grid>
					<Grid item>
						<Grid
							container
							alignItems='center'
						>
							<Grid item style={styles.popupColumn2}>
								<Divider />
							</Grid>
							<Grid item style={styles.space30}>
								<Typography variant='caption' align="center">
									{Translations.get('or')}
								</Typography>
							</Grid>
							<Grid item style={styles.popupColumn2}>
								<Divider />
							</Grid>
						</Grid>
					</Grid>
					<Grid item style={styles.space16Vertical}>
					</Grid>
					<Grid item>
						<Button
							variant='outlined'
							onClick={() => this.props.setPopup('continue_without_account')}
							fullWidth
						>
							{Translations.get('continue_without_account')}
						</Button>
					</Grid>
				</Grid>
			);
		}
		return continueBox;
	}

	render() {
		return (
			<Grid
				container
				direction='column'
			>
				<Grid item style={styles.space16Vertical}>
				</Grid>
				<Grid item>
					<ButtonBox />
				</Grid>
				<Grid item>
					{this.getContinueBox()}
				</Grid>
			</Grid>
		);
	}
}


LoginBox.propTypes = {
	isContinue: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		popup: state.page.popup
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (x) => {
			dispatch(setPopup(x));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginBox);
