import React from "react";
import Typography from '@material-ui/core/Typography';

import ImageBlockForm from './ImageBlockForm'
import Translations from 'Singleton/Translations'
import Image from 'Resources/images/background.png'
import ImageMobile from 'Resources/images/background768.png'
import ImageTablet from 'Resources/images/background1440.png'
import BackgroundPixel from 'Resources/images/backgroundPixel.png'


import { clearDefaultValues } from "Actions/inputActions";
import queryString from 'query-string'

import { Grid } from "@material-ui/core";
import MediaType from '../../../libs/MediaType';
import logo from 'Resources/images/TREO-logo.svg'
import { connect } from 'react-redux'


const __style_image = {
	backgroundColor: '#6396B3',
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center',
	position: 'absolute',
	width: '100%',
	zIndex: '-1',
	left: '0',
}

const styles = {
	root: {
		minHeight: '750px',
	},
	rootTablet: {
		minHeight: '500px',
		marginBottom: '6rem'
	},
	rootMobile: {
		minHeight: '300px',
		// position: 'relative',
		// backgroundColor: '#6396B3',
		// zIndex: -1,
	},
	image: {
		...__style_image,
		backgroundImage: `url(${Image})`,
		height: '750px',
	},
	imageTablet: {
		...__style_image,
		backgroundImage: `url(${ImageTablet})`,
		height: '563px',
	},
	imageMobile: {
		...__style_image,
		backgroundImage: `url(${ImageMobile})`,
		height: '300px',
	},
	logo: {
		marginTop: '120px',
		marginLeft: '15px',
		maxHeight: '30px',
		width: '100px'
	},
	title: {
		padding: '200px 0'
	},
	tabletTitle: {
		padding: '40px 0'
	},
	mobileTitle: {
		padding: '30px 0'
	},
	textColor: {
		color: '#fff',
		fontWeight: 100,
	}

}

const getTitleStyle = () => {
	switch (MediaType.type) {
		case MediaType.TABLET: return styles.tabletTitle;
		case MediaType.MOBILE: return styles.mobileTitle;
		default: return styles.title; //MediaType.DESKTOP;
	}
}

const getTitle = () => {
	return (
		<Grid
			container
			direction='column'
			style={getTitleStyle()}
		>
			<Grid item>
				{!MediaType.isDesktop &&
					<Typography
						variant="display1"
						align='center'
						style={
							MediaType.isMobile ?
								{
									...styles.textColor,
									fontSize: '18px'
								} :
								{
									...styles.textColor,
								}}
					>
						{Translations.get('main_form_center_title_1').capitalize()}
					</Typography>
				}
			</Grid>
			<Grid item>
				<Typography
					variant="display1"
					align='center'
					style={
						MediaType.isMobile ?
							{
								...styles.textColor,
								fontSize: '18px'
							} :
							{
								...styles.textColor,
							}
					}
				>
					{MediaType.isDesktop &&
						Translations.get('main_form_center_title_1').capitalize()
					}
					{' '}
					{Translations.get('main_form_center_title_2').toLowerCase()}
				</Typography>
			</Grid>
		</Grid>
	);
}

const ImageBlock = (props) => {
	const { form } = queryString.parse(window.location.search);
	if (form !== 'autofill') {
		props.clearDefaultValues();
	}

	return (
		<div style={
			MediaType.isMobile ?
				styles.rootMobile :
				MediaType.isTablet ?
					styles.rootTablet :
					styles.root
		}>
			<div style={
				MediaType.isMobile ?
					styles.imageMobile :
					MediaType.isTablet ?
						styles.imageTablet :
						styles.image
			}></div>
			<Grid
				container
				direction='column'
			>
				{
					MediaType.isMobile &&
					<Grid item style={{ width: '100%' }}>
						<div style={{
							width: '290px',
							margin: '0 auto',
							textAlign: 'left'
						}}>
							<img src={logo} alt={'Treo'} style={styles.logo} />
						</div>
					</Grid>
				}
				<Grid item>
					{getTitle()}
				</Grid>
				<Grid item
					style={
						MediaType.isMobile ?
							{
								backgroundImage: `url(${BackgroundPixel})`,
							} : {}}
				>
					<ImageBlockForm />
				</Grid>
			</Grid>
		</div>
	);
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = dispatch => ({
	clearDefaultValues: () => { dispatch(clearDefaultValues()) }
})

export default connect(mapStateToProps, mapDispatchToProps)(ImageBlock);