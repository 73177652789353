import React from 'react'
import PropTypes from 'prop-types'
import Translation from 'Singleton/Translations'
import {timeFormat} from 'libs/dateformat'

import HistoryType from 'Components/Commons/Const/History'
import Translations from 'Singleton/Translations'
import { Paper, Grid, Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';

import styles from "Components/style";

class container extends React.Component{

	styles={
		container:{
			...styles.paperBox, 
			marginTop: styles.space30.width,
		}
	}

	formatValue(value){
		switch (this.props.type) {
			case HistoryType.NUMBER:
			case HistoryType.STRING: return `"${value}"`;
			case HistoryType.REAL: return parseFloat(value).toFixed(2) + " PLN";
			case HistoryType.TIME: return timeFormat(parseInt(value));
			case HistoryType.BOOLEAN: return value==='true' ? Translations.get('true') : Translations.get('false');
			case HistoryType.KEY: return `"${Translation.get(value)}"`;
			default: return '__NO_TYPE__';
		}
	}

	render(){
		return(
			<Paper style={this.styles.container}>
				<Grid 
					container
					direction='column'
				>
					<Grid item>
						<Typography variant ='body1'>
							{timeFormat(this.props.timestamp)}
						</Typography>
						<Divider />
					</Grid>
					<Grid item style={styles.space16Vertical}>
					</Grid>
					<Grid item>
						<Typography variant='body1'>
							{Translation.get(this.props.keyValue)}{' '}
							<b>{this.formatValue(this.props.oldValue)}</b>{' '}
							{Translations.get('change_value_history_separator')}{' '}
							<b>{this.formatValue(this.props.newValue)}</b>
						</Typography>
					</Grid>
				</Grid>
			</Paper>
		);
	}
}

container.propTypes ={
	keyValue: PropTypes.string.isRequired,
	oldValue: PropTypes.string.isRequired,
	newValue: PropTypes.string.isRequired,
	timestamp: PropTypes.number.isRequired,
	type: PropTypes.string.isRequired,
}

export default container;
