import React from "react";
import PropTypes from 'prop-types';

import { withTheme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import globalStyles from 'Components/style'

const __styles = (theme) => {
	return ({
		circleBox:{
			display: 'table',
			margin: '0 auto',
			width: '70px',
			marginRight: '20px'
		},
		circle: {
			border: `solid 4px ${theme.palette.primary.main}`,
			borderRadius: '50%',
			width: '70px',
			height: '70px',
			display: 'table-cell',
			verticalAlign: 'middle',
		},
		description:{
			lineHeight: '100%'
		}
	});
}

const CircleMark = (props) => {
	const theme = props.theme;
	const styles = __styles(theme);

	return (
		<Grid
			container
			direction="row"
			justify="flex-start"
			alignItems="center"
			wrap="nowrap"
		>
			<Grid item>
				<div style={styles.circleBox}>
					<div style={styles.circle}>
						<Typography component="span" variant='display2' align='center' color='primary'>
							{props.number}
						</Typography>
					</div>
				</div>
			</Grid>
			<Grid item>
				<Typography 
					component="span"
					variant='subheading' 
					style={styles.description}
				>
					{props.children}
				</Typography>
			</Grid>
		</Grid>
	)
};

CircleMark.propTypes = {
	number: PropTypes.number.isRequired,
	text: PropTypes.string,
};


export default withTheme()(CircleMark);
