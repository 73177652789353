
export class Browser{
	// Chrome 1+
	static get isChrome() { return (!!window.chrome && !!window.chrome.webstore) || navigator.userAgent.indexOf("Chrome") != -1;
	}
	
	// Opera 8.0+
	static get isOpera() { return (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0; }

    // Firefox 1.0+
	static get isFirefox() { return typeof InstallTrigger !== 'undefined';}

    // Safari 3.0+ "[object HTMLElementConstructor]" 
	static get isSafari() { return /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || window.safari.pushNotification); }

    // Internet Explorer 6-11
	static get isIE() { return /*@cc_on!@*/false || !!document.documentMode; }
	
}

export default Browser;