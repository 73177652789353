
import Translations from 'Singleton/Translations'

export default class CustomerKeyTranslation {
	static Type(key){
		switch (key) {
			case 'private_suplier': return Translations.get('private_person');
			case 'company_suplier': return Translations.get('company');
			default: return "__NO_DATA__";
		}
	}

}
