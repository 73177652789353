import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";



import { MuiThemeProvider } from "@material-ui/core/styles";
import muiTheme from "./muiTheme";

import App from "./Containers/App";
import store from "./store";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

import "libs/IO.js";
import "libs/ArrayExtensions.js";
import "libs/StringExtensions.js";

import ReactGA from "react-ga";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

// console.log({ io });

history.listen((location, action) => {
  ReactGA.pageview(location.pathname + location.search);
});

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <MuiThemeProvider theme={muiTheme}>
        <App />
      </MuiThemeProvider>
    </Router>
  </Provider>,
  document.getElementById("root")
);
