import React from "react";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import { Base64 } from 'js-base64';
import { subscribe } from 'redux-subscriber';
import { withRouter } from 'react-router-dom'

import CircularProgress from '@material-ui/core/CircularProgress';


import { redirrect, setPopupFunc } from 'Actions/pageActions';
import { setValue } from 'Actions/repositoryActions'
import { setInputValue, setInputDefaultValue } from "Actions/inputActions";
import { setPaletsCount } from 'Actions/imageBlockFormActions';
import { setPopup } from 'Actions/pageActions'

import RestRequest from 'libs/RestRequest'

import OrderKeyTranslation from 'Components/Commons/Dictionaries/OrderKeyTranslation'
import ValuationKeyTranslation from 'Components/Commons/Dictionaries/ValuationKeyTranslation'
import { ValuationStatus } from 'Components/Commons/Const/Valuation'
import OrderStatus from 'Components/Commons/Const/OrderStatus'
import { PageType } from 'Components/Commons/Const/Type'

import Header from 'Components/Body/OrderDetails/Header'
import Boxs from 'Components/Body/OrderDetails/Boxs'
import DetailsColumn from 'Components/Body/OrderDetails/DetailsColumn'
import ChatColumn from 'Components/Body/OrderDetails/ChatColumn'
import { PriceBoxState } from 'Components/Commons/PriceBox'

import styles from "../../Components/style";

import { Grid } from "@material-ui/core";
import Translations from 'Singleton/Translations'
import queryString from 'query-string'
import MediaType from "../../libs/MediaType";

import { MetaData, handleMetaData } from '../../Components/Commons/Const/MetaData'

export class OrderDetails extends React.Component {
	constructor(props) {
		super(props);
		var type = this.props.match.params.oeType;
		var number = Base64.decode(this.props.match.params.number);
		this.state = {
			type: type,
			number: number,
			loading: true,
			error: false,
			data: null,
			statusesEval: {
				"new_eval": 1,
				"await_accept_eva": 2,
				"outdated_eval": 3,
				"accepted_eval": 4,
				"rejected_eval": 5
			},
			statuserOrd: {
				"new_ord": 1,
				"await_pay_ord": 2,
				"realisation_ord": 3,
				"cancelled_ord": 4
			}
		}
		subscribe('user.id', state => {
			if (this.props.userId === undefined) {
				this.getData(this.state.type, this.state.number, state.user.id);
			}
		});


		this.getInvoiceData = this.getInvoiceData.bind(this);
	}

	componentWillMount() {
		document.title = MetaData.mainPage.title;
		handleMetaData();
	}


	componentDidMount() {
		this.props.setPopupFunc(() => { this.getData(this.state.type, this.state.number) })
		this.getData(this.state.type, this.state.number);
	}

	componentWillUpdate(nextProps, nextState) {
		if (nextState['type'] !== nextProps.match.params.oeType) {
			nextState['type'] = nextProps.match.params.oeType;
			const number = Base64.decode(nextProps.match.params.number);
			nextState['number'] = number;
			this.getData(nextProps.match.params.oeType, number);
			this.props.setPopupFunc(() => { this.getData(nextProps.match.params.oeType, number) })
		}
	}

	componentWillUnmount() {
		this.props.setPopupFunc();
	}

	getData(type, number, userId = null) {
		if (type === "evaluation") {
			this.getEvaluationData(number, userId)
		} else if (type === "order") {
			this.getOrderData(number, userId);
		}
	}

	getEvaluationData(number, userId) {
		if (userId === null) {
			userId = this.props.userId
		}

		let { token } = queryString.parse(this.props.location.search)
		if (this.props.userToken) {
			token = this.props.userToken;
		}

		var data = { userId: userId, number: number };
		console.log(data);
		RestRequest('post', '/secured/getUserEvaluation', data, (res) => {
			console.log("res:", res);
			if (res.body.error) {
				toast.error(`${res.body.errorReason}`, {
					position: toast.POSITION.TOP_RIGHT,
				});
				this.setState({
					...this.state,
					loading: false,
					error: true,
				});
			} else {
				this.setState({
					...this.state,
					loading: false,
					data: res.body,
				});
			}
		}, token);
	}

	getOrderData(number, userId = null) {
		if (userId === null) {
			userId = this.props.userId
		}
		var data = { userId: userId, number: number };

		let { token } = queryString.parse(this.props.location.search)
		if (this.props.userToken) {
			token = this.props.userToken;
		}

		RestRequest('post', '/secured/getUserOrder', data, (res) => {
			console.log("res:", res);
			if (res.body.error) {
				toast.error(`${res.body.errorReason}`, {
					position: toast.POSITION.TOP_RIGHT,
				});
				this.setState({
					...this.state,
					loading: false,
					error: true,
				});
			} else {
				this.setState({
					...this.state,
					loading: false,
					data: res.body,
				});
			}
		}, token);

	}

	localFormat = function (date) {
		var mm = date.getMonth() + 1; // getMonth() is zero-based
		var dd = date.getDate();
		var hh = date.getHours();
		var min = date.getMinutes();
		var ss = date.getSeconds();

		return [date.getFullYear(), '-',
		(mm > 9 ? '' : '0') + mm, '-',
		(dd > 9 ? '' : '0') + dd, ' ',
		(hh > 9 ? '' : '0') + hh, ':',
		(min > 9 ? '' : '0') + min, ':',
		(ss > 9 ? '' : '0') + ss
		].join('');
	}

	acceptValuation = (valuationNumber = undefined) => {
		var data = this.state.data;
		const vn = valuationNumber
		this.props.setInputValue('valuation-number', vn);
		if (data.isPrivate) {
			this.props.setInputValue('sender', 'private');
			this.props.setRepositoryValue('priceBoxState', PriceBoxState.COMPLETE)
		} else {
			this.props.setInputValue('sender', 'company');
			// this.props.setRepositoryValue('priceBoxState', PriceBoxState.COMPLETE_PLN)
		}
		for (var i = 0; i < data.pallets.length; i++) {
			var pallet = data.pallets[i];
			var type = {};
			for (var typeTemp of this.props.palletsTypes) {
				if (typeTemp.type === pallet.type) {
					type = this.props.palletsTypes.indexOf(typeTemp);
				}
			}
			this.props.setInputValue(`palet_height_${i}`, pallet.height);
			this.props.setInputValue(`palet_weight_${i}`, pallet.weight);
			this.props.setInputValue(`fragile_cargo_${i}`, pallet.isFragile);
			this.props.setInputValue(`pallets_size_${i}`, type);
			this.props.setInputValue(`palet_length_${i}`, pallet.customLength);
			this.props.setInputValue(`palet_width_${i}`, pallet.customWidth);
		}
		this.props.setInputValue('post_code_from', data.pickupPostcode);
		this.props.setInputValue('post_code_to', data.deliveryPostcode);
		this.props.setInputValue('country_from', data.pickupCountry);
		this.props.setInputValue('country_to', data.deliveryCountry);
		this.props.setInputValue('details', data.remarks);
		this.props.setInputValue('lift_car', data.isLiftByDelivery || data.isLiftByPickup);
		this.props.setPaletsCount(data.pallets.length);
		const price = {
			cost: data.cost,
			priceNet: data.amountNet,
			priceGross: data.amountGross,
			exchangeRate: data.exchangeRate,
			supplier: data.supplier,
		}
		this.props.setRepositoryValue('price', price);
		this.props.setRepositoryValue('pricePLN', data.amountNet);
		// console.log(this.state.data);
		this.props.redirrect(`/more_info`, this.props.history);



	}

	rejectValuation() {

		this.props.setRepositoryValue('number', this.state.number);
		this.props.setPopup('valuation_reject');
	}

	translateCountry(iso) {
		var country = this.props.countrys.find((element) => {
			return element.value === iso;
		});
		return country.name;
	}

	palletsTranslations = [
		Translations.get('one_palette'),
		Translations.get('two_pallets'),
		Translations.get('three_pallets'),
		Translations.get('four_pallets'),
		Translations.get('five_pallets')
	]

	getPalletSize = name => {
		const item = this.props.palletsTypes.find(it => {
			return it.type === name;
		})
		if (item.isCustom) {
			return 'custom'
		}
		const index = this.props.palletsTypes.indexOf(item);

		return `${index}`;
	}

	setValuationFields = () => {
		const { data } = this.state;
		console.log(this.state);

		this.props.setInputDefaultValue('pallets', this.palletsTranslations[data.pallets.length - 1])
		this.props.setInputDefaultValue('sender', data.isPrivate ? 'private' : 'company')
		this.props.setInputDefaultValue('country_from', data.pickupCountry)
		this.props.setInputDefaultValue('post_code_from', data.pickupPostcode)
		this.props.setInputDefaultValue('country_to', data.deliveryCountry)
		this.props.setInputDefaultValue('post_code_to', data.deliveryPostcode)

		for (let i = 0; i < data.pallets.length; i++) {
			const element = data.pallets[i];

			this.props.setInputDefaultValue('pallets_size_' + i, this.getPalletSize(element.type))
			this.props.setInputDefaultValue('palet_height_' + i, `${element.height}`)
			this.props.setInputDefaultValue('palet_weight_' + i, `${element.weight}`)
			this.props.setInputDefaultValue('fragile_cargo_' + i, !!element.isFragile)
			this.props.setInputDefaultValue('palet_length_' + i, `${element.customLength}`)
			this.props.setInputDefaultValue('palet_width_' + i, `${element.customWidth}`)
		}
	}

	getTopButtons() {
		var btns = [];
		var type = this.state.type;
		if (type === "evaluation") {
			btns = [{
				text: Translations.get('renew_evaluation'),
				callback: () => {
					this.setValuationFields();
					this.props.redirrect(`/?form=autofill`, this.props.history);
				}
			}]
		} else if (type === "order") {
			btns = [{
				text: Translations.get('report_complaint'),
				callback: () => {
					this.props.setRepositoryValue('number', this.state.number);
					this.props.setPopup('claim')
				}
			}, {
				text: Translations.get('renew_order'),
				callback: () => {
					this.acceptValuation(this.state.data.orderNumber)
					//this.props.redirrect(`/`,this.props.history);
				}
			}]
		}
		return btns;
	}

	getTrEval(type) {
		if (type === "evaluation") {
			return Translations.get('evaluation');
		} else if (type === "order") {
			return Translations.get('order');
		} else {
			return "Undefined";
		}
	}

	getInvoiceData() {
		return {
			city: this.state.data.proInvoiceCity,
			company: this.state.data.proInvoiceCompany,
			country: this.state.data.proInvoiceIso,
			name: this.state.data.proInvoiceName,
			phone: this.state.data.proInvoicePhone,
			postcode: this.state.data.proInvoicePostcode,
			street: this.state.data.proInvoiceStreet,
			surname: this.state.data.proInvoiceSurname,
			nip: this.state.data.proInvoiceVat,
		};
	}

	getHeaderDetailsText(status) {
		var translationStatusFunc = this.state.type === PageType.ORDER ?
			OrderKeyTranslation.status :
			ValuationKeyTranslation.status;
		var msg = `${translationStatusFunc(status)}`;
		if (status === OrderStatus.AWAIT ||
			status === ValuationStatus.AWAIT) {
			msg += ` (${Translations.get('remains')} ${this.state.data.remainedDays} ${Translations.get('days')})`;
		}
		return msg;
	}

	render() {
		console.log('bbbbbbbbbbbb', this)
		if (this.state.loading) {
			return (
				<CircularProgress />
			);
		} else if (this.state.error) {
			return (Translations.get('no_data'));
		} else {
			var data = this.state.data;

			return (
				<Grid
					container
					direction='column'
					style={
						MediaType.isMobile ?
							styles.mobileContainer :
							styles.container
					}
				>
					<Grid item>
						<Header
							title={`${this.getTrEval(this.state.type)} ${this.state.number}`}
							details={this.getHeaderDetailsText(data.status)}
							buttons={this.getTopButtons()}
							state={data.status}
						/>
					</Grid>
					<Grid item>
						<Boxs
							number={this.state.number}
							orderData={data.creationDate}
							orderSignature={data.orderNumber}
							priceNet={data.amountNet}
							priceGross={data.amountGross}
							exchangeRate={data.exchangeRate}
							status={data.status}
							acceptFunc={() => { this.acceptValuation(this.state.number) }}
							rejectFunc={this.rejectValuation.bind(this)}
							link={data.proformaLink}
							paymentDate={data.paymentDate}
							pickupStart={data.pickupStart}
							pickupEnd={data.pickupEnd}
							plannedDeliveryDate={data.plannedDeliveryDate}
							cancellationDate={data.cancellationDate}
							outdatedDate={data.validTo}
							rejectionDate={data.rejectionDate}
							invoice={{
								city: data.proInvoiceCity,
								company: data.proInvoiceCompany,
								iso: data.proInvoiceIso,
								name: data.proInvoiceName,
								phone: data.proInvoicePhone,
								postcode: data.proInvoicePostcode,
								street: data.proInvoiceStreet,
								surname: data.proInvoiceSurname,
								vat: data.proInvoiceVat,
							}}
							isCompany={!data.isPrivate}
							paymentType={data.paymentType}
							type={this.state.type}
							valuationDate={data.valuationDate}
							isDocumentExist={data.isDocumentExist}
							isInvoiceExist={data.isInvoiceExist}
						/>
					</Grid>
					<Grid item>
						<Grid
							container
							justify='space-between'
							direction={MediaType.isMobile ? 'column' : 'row'}
						>
							<Grid item>
								<DetailsColumn
									pallets={data.pallets}

									fromPost={data.pickupPostcode}
									from={data.pickupCity}
									fromCountry={this.translateCountry(data.pickupCountry)}
									fromPhone={data.pickupPhone}
									fromRefNumber={data.pickupRefNumber}
									fromStreet={data.pickupStreet}
									fromName={data.pickupName}
									fromSurname={data.pickupSurname}
									fromCompany={data.pickupCompany}
									isLiftByPickup={data.isLiftByPickup}
									isPickupEntryLimited={data.isPickupEntryLimited}

									toPost={data.deliveryPostcode}
									to={data.deliveryCity}
									toCountry={this.translateCountry(data.deliveryCountry)}
									toPhone={data.deliveryPhone}
									toRefNumber={data.deliveryRefNumber}
									toStreet={data.deliveryStreet}
									toName={data.deliveryName}
									toSurname={data.deliverySurname}
									toCompany={data.deliveryCompany}
									isLiftByDelivery={data.isLiftByDelivery}
									isDeliveryEntryLimited={data.isDeliveryEntryLimited}

									isPrivate={data.isPrivate}
									remarks={data.remarks}
									type={this.state.type}
									invoiceData={this.getInvoiceData()}
								/>
							</Grid>
							<Grid item>
								<ChatColumn
									number={this.state.number}
									type={this.state.type}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			);
		}
	}
}

const mapStateToProps = (state) => {
	return {
		selectedOrder: state.page.selectedOrder,
		palletsTypes: state.translations.pallets,
		countrys: state.translations.countrys,
		userToken: state.user.token,
		userEmail: state.user.login,
		userId: state.user.id
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		redirrect: (page, hist) => {
			dispatch(redirrect(page, hist));
		},
		setRepositoryValue: (x, y) => {
			dispatch(setValue(x, y));
		},
		setInputValue: (x, y) => {
			dispatch(setInputValue(x, y));
		},
		setPaletsCount: (count) => {
			dispatch(setPaletsCount(count));
		},
		setPopup: (x) => {
			dispatch(setPopup(x));
		},
		setPopupFunc: (f) => {
			dispatch(setPopupFunc(f));
		},
		setInputDefaultValue: (id, value) => { dispatch(setInputDefaultValue(id, value)) }
	};
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderDetails));
