import React from 'react'
import { connect } from "react-redux";
import PropTypes from 'prop-types';

import { setPopup } from 'Actions/pageActions.js'
import { setValue } from 'Actions/repositoryActions'

import Translations from 'Singleton/Translations'
import { Paper, Grid, Typography, Button } from '@material-ui/core';
import styles from '../../../style';


class container extends React.Component {

	render() {
		var fullName = `${this.props.name} ${this.props.surname}`

		return (
			<Paper style={styles.infoBox}>
				<Grid
					container
					direction='column'
					alignItems='center'
					justify='space-between'
					style={{height: '100%'}}
				>
					<Grid item>
						<Typography variant='body1'>
							{fullName}
						</Typography>
					</Grid>
					<Grid item>
						<Button 
							variant='outlined'
							onClick={() => { 
								this.props.setAutoFillValue({
									name: this.props.name,
									surname: this.props.surname
								})
								this.props.setPopup('change_employee_name') 
							}}
						>
							{Translations.get('change_full_name')}
						</Button>
					</Grid>
				</Grid>
			</Paper>
		);
	}
}

container.propTypes = {
	name: PropTypes.string.isRequired,
	surname: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => {
	return {
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (x) => {
			dispatch(setPopup(x));
		},
		setAutoFillValue: x => {dispatch(setValue('autoFill', x))}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(container);
