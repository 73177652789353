import React from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify';

import RestRequest from 'libs/RestRequest'
import { setPopup } from 'Actions/pageActions'

import { Grid, Button } from '@material-ui/core';
import styles from 'Components/style'
import TextInput from 'Components/Commons/TextInput';

import Translations from 'Singleton/Translations'

class container extends React.Component {
	constructor(props) {
		super(props);
		this.handleResponse = this.handleResponse.bind(this);
		this.showError = this.showError.bind(this);
	}

	showError(msg) {
		toast.error(msg, {
			position: toast.POSITION.TOP_RIGHT,
		});
	}

	confirmClick() {
		RestRequest(
			'post',
			'secured/order/claim',
			{
				description: this.props.inputs.reason,
				number: this.props.number,
			},
			this.handleResponse,
			this.props.userToken
		);
	}

	cancelClick() {
		this.props.setPopup(undefined);
	}

	handleResponse(res) {
		console.log('claim res', res);
		if (res.body.error) {
			this.showError(res.body.errorReason);
		}else{
			if(this.props.chatRefreshFunc){
				this.props.chatRefreshFunc();
			}
		}
		
		this.props.setPopup(undefined);
	}

	getChoiceButtons() {
		return (
			<Grid container direction='column'>
				<Grid item>
					<Button
						onClick={this.confirmClick.bind(this)}
						fullWidth
						variant='contained'
						color='primary'>
						{Translations.get('report_complaint')}
					</Button>
				</Grid>

				<Grid item style={styles.space16Vertical}>
				</Grid>

				<Grid item>
					<Button
						onClick={this.cancelClick.bind(this)}
						fullWidth
						variant='outlined'>
						{Translations.get('cancel')}
					</Button>
				</Grid>
			</Grid>
		);
	}

	render() {
		return (
			<Grid container direction='column'>
				<Grid item>
					<TextInput
						label={Translations.get('complaint_reason')}
						id='reason'
						limit={245}
					/>
				</Grid>

				<Grid item style={styles.space16Vertical}>
				</Grid>

				<Grid item>
					{this.getChoiceButtons()}
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userToken: state.user.token,
		inputs: state.input.inputState,
		number: state.repository.number,
		chatRefreshFunc: state.repository.chatRefreshFunc
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPopup: (x) => {
			dispatch(setPopup(x));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(container);
