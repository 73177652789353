import Translations from 'Singleton/Translations'

export default class UserKeyTranslation {
	static useType(key){
		switch (key) {
			case 'disposable': return Translations.get('coupon_disposable');
			case 'reusable': return Translations.get('coupon_reusable');
			default: return "__NO_DATA__";
		}
	}

	static userState(state){
		switch (state) {
			case 'registered_user': return Translations.get('registered_user');
			case 'unregistered_user': return Translations.get('unregistered_user');
			default: return "__NO_DATA__";
		}
	}
}
