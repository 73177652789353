import React from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'

import LoginBox from 'Components/Body/UserSettings/Login'
import DataBox from 'Components/Body/UserSettings/Data'
import ConsentsBox from 'Components/Body/UserSettings/Consents'
import InvoiceBox from 'Components/Body/UserSettings/Invoice'
import DeleteBox from 'Components/Body/UserSettings/Delete'

import { redirrect } from 'Actions/pageActions'
import Translations from 'Singleton/Translations'

import { Typography, Grid } from "@material-ui/core";
import styles from 'Components/style'
import MediaType from "../../libs/MediaType";

import { MetaData, handleMetaData } from '../../Components/Commons/Const/MetaData'

class container extends React.Component {

	componentWillMount() {
		document.title = MetaData.mainPage.title;
		handleMetaData();
	}

	getLeftColumn() {
		return (
			<Grid
				container
				direction='column'
			>
				<Grid item>
					<LoginBox />
				</Grid>
				<Grid item>
					<ConsentsBox />
				</Grid>

			</Grid>
		);
	}

	getRightColumn() {
		return (
			<Grid
				container
				direction='column'
			>
				{/* <Grid item>
					<DataBox />
				</Grid> */}
				<Grid item>
					<InvoiceBox />
				</Grid>
				<Grid item>
					<DeleteBox />
				</Grid>
			</Grid>
		);
	}

	getColumnStyle = () => {
		switch (MediaType.type) {
			case MediaType.TABLET: return {
				...styles.columnTablet,
				paddingTop: 0,
				paddingBottom: 0,
				...this.props.style
			}
			case MediaType.MOBILE: return {
				...styles.columnMobile,
				paddingTop: 0,
				paddingBottom: 0,
				...this.props.style
			}
			default: return {
				...styles.column,
				paddingTop: 0,
				paddingBottom: 0,
				...this.props.style,
			}

		}
	}

	getColumns() {
		return (
			<Grid
				container
				justify='space-between'
				direction={MediaType.isMobile ? 'column' : 'row'}
			>
				<Grid item style={this.getColumnStyle()}>
					{this.getLeftColumn()}
				</Grid>

				<Grid item style={this.getColumnStyle()}>
					{this.getRightColumn()}
				</Grid>
			</Grid>
		);
	}

	render() {
		return (
			<Grid
				container
				direction='column'
				style={
					MediaType.isMobile ?
						styles.mobileContainer :
						styles.container
				}
			>
				{!MediaType.isMobile &&
					<Grid item style={styles.space30Vertical}>
					</Grid>
				}
				<Grid item>
					<Typography variant='display1'>
						{Translations.get('account_settings')}
					</Typography>
				</Grid>
				<Grid item style={
					MediaType.isMobile ?
						styles.space16Vertical :
						styles.space30Vertical
				}>
				</Grid>
				<Grid item>
					{this.getColumns()}
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		inputs: state.input.inputState,
		userToken: state.user.token,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		redirrect: (page, hist) => {
			dispatch(redirrect(page, hist));
		}
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(container));
