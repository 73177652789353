import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Request from 'libs/RestRequest'

// import AutoComplete from './AutoComplete'
import AutoComplete2 from './AutoComplete2'

export class component extends Component {
	constructor(props) {
		super(props);
		this.state = {
			source: [],
			value: null,
		}
		if (this.props.bindResetSuggestions) {
			this.props.bindResetSuggestions(this.resetSuggestions)
		}
	}

	componentDidMount() {
		this.getFields('', 'mount')
	}

	componentDidUpdate(prevProps, prevState){
		
		for(let key in this.props.rest.params){
			if(this.props.rest.params[key] !== prevProps.rest.params[key]){
				this.getFields('', 'update')
				return;
			}
		}
	}

	resetSuggestions = () => {
		this.setState({
			source: [],
			value: null,
		})
	}

	onTextChange = (value) => {
		if(this.state.value === value) return;
		this.setState({
			...this.state,
			value,
		}, this.getFields(value,'textchange'))
	}

	onChange = (value) => {
		this.getFields(value, 'onchange');
		if (this.props.onChange) {
			this.props.onChange(value);
		}
	}

	getFields = (value, log) => {
		if (value === this.state.value) {
			return;
		}
		

		var params = {}
		Object.assign(
			params,
			this.props.rest.params,
			{
				[this.props.rest.inputParamName]: value,
			});
		Request(
			'get',
			this.props.rest.door,
			params,
			this.handleResponse
		);
	}

	handleResponse = (res) => {
		// console.log("RestAutoComplete",res);
		var list = res.body.list.map((item) => {
			return item[this.props.rest.resFieldName];
		})

		this.setState({
			...this.state,
			source: list,
		})
	}

	handleOpenMenu = () => {
		// const value = (this.state.value ? this.state.value : '');
		// this.setState({
		// 	...this.state,
		// 	value,
		// }, this.getFields(value, 'open menu'))
		if(!this.state.source) {
			this.getFields(this.state.value, 'open menu')
		}
	}

	render() {
		return (
			<AutoComplete2
				disabled={this.props.disabled}
				id={this.props.id}
				width={this.props.width}
				placeholder={this.props.placeholder}
				onChange={this.onChange}
				onTextChange={this.onTextChange}
				suggestions={this.state.source}
				bindSetValueFunc={this.props.bindSetValueFunc}
				defaultValue={this.props.defaultValue}
				handleOpenMenu={this.handleOpenMenu}
				required={this.props.required}
				toUpperCase={this.props.toUpperCase}
				tooltip={this.props.tooltip}
        classes={this.props.classes}
			/>
		)
	}
}

component.propTypes = {
	id: PropTypes.string.isRequired,
  // classes: PropTypes.object.isRequired,
	width: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	rest: PropTypes.shape({
		door: PropTypes.string.isRequired,
		params: PropTypes.object.isRequired,
		inputParamName: PropTypes.string.isRequired,
		resFieldName: PropTypes.string.isRequired,
	}).isRequired,
	bindSetValueFunc: PropTypes.func,
	bindResetSuggestions: PropTypes.func,
	disabled: PropTypes.bool,
	defaultValue: PropTypes.string,
	required: PropTypes.bool,
	toUpperCase: PropTypes.bool,
	tooltip: PropTypes.string,
}


export default component;
